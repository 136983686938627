import React from 'react';

import {
  Grid,
  Breadcrumbs,
  Link,
  Box,
  Toolbar,
  AppBar,
  Typography,
} from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';

function BreadCrumb(props) {
  const navigateTo = useNavigate();
  const location = useLocation();

  const pathNames = location.pathname.split('/').filter((x) => x);
  const url = location.pathname;
  return (
    <div>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item>
          <Breadcrumbs
            aria-label='breadCrumb'
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'start',
                sm: 'end',
                md: 'center',
              },
            }}
          >
            {
              <Link
                sx={{
                  fontSize: { xs: '13px', sm: '18px', md: '22px' },
                  color: '#3B78C9',
                  textDecoration: 'none',
                }}
                onClick={() => navigateTo('/categories')}
              >
                Home
              </Link>
            }
            {pathNames.map((name, index) => {
              // const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
              const routeTo = url;

              const isLast = index === pathNames.length - 1;
              return isLast ? (
                <Typography
                  key={name}
                  sx={{ fontSize: { xs: '12px', sm: '18px', md: '22px' } }}
                >
                  {name.charAt(0).toUpperCase() +
                    name.slice(1).replace('-', ' ')}
                </Typography>
              ) : (
                <Link
                  sx={{ fontSize: { xs: '12px', sm: '18px', md: '22px' } }}
                  key={name}
                  onClick={() => {
                    navigateTo(routeTo);
                  }}
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
}

export default BreadCrumb;
