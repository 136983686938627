import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {
  Box,
  Typography,
  Divider,
  Skeleton,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Customer from '../../modules/Customer';
import Vendor from '../../modules/Vendor';
import Auth from '../../modules/Auth';
import { Link } from 'react-router-dom';
import { debounce } from '../../utils';
const page_size = 18;
function CheckBalanceDashboard({ userType }) {
  React.useEffect(() => {
    if (window.location.hash.endsWith('/customer')) {
      getCustomerData(pageNo);
    } else {
      getVendorData(pageNo);
    }
  }, []);

  const [customerList, setCustomerList] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(0);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  // const [customerListiii, setCustomerListiii] = React.useState([]);
  const getVendorData = pageNo => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), pageNo, true, response => {
      if (response.status === 'success') {
        let vendorList = response.data.map(vendor => {
          vendor.customerName = vendor.name;
          vendor.contact = vendor.contactNo;
          return vendor;
        });
        if (vendorList.length < page_size) {
          setHasMoreData(false);
        }
        if (vendorList.length > 0) {
          setCustomerList(vendorList);
          // setCustomerListiii(vendorList);
          let ids = { customerIds: [] };
          ids.customerIds = vendorList.map(vendor => {
            return vendor.id;
          });
          getCustomerBalance(vendorList, ids);
        }
      } else {
        setCustomerList([]);
      }
      setSkeletonLoading(false);
    });
  };
  const getCustomerData = pageNo => {
    Customer.getAllCustomers(Auth.getStoreId(), pageNo, true, response => {
      if (response.status === 'success') {
        let filteredAnonymous = response.data.filter(item => {
          return item.customerName?.toUpperCase() !== 'ANONYMOUS';
        });

        if (filteredAnonymous.length < page_size) {
          setHasMoreData(false);
        }
        if (filteredAnonymous.length > 0) {
          // setCustomerList([...customerList, ...filteredAnonymous]);
          // setCustomerListiii(filteredAnonymous);
          let ids = { customerIds: [] };
          ids.customerIds = filteredAnonymous.map(customer => {
            return customer.id;
          });

          getCustomerBalance(filteredAnonymous, ids);
        }
      } else {
        setCustomerList([]);
      }
      setSkeletonLoading(false);
    });
  };

  const getCustomerBalance = (customerData, ids, calledAt) => {
    Customer.getCustomerBalance(ids, response => {
      if (response.status === 'success') {
        let updatedSlice = customerData.map(customer => {
          let ii = response.data.find(item => {
            return item.customerId === customer.id;
          });

          customer.balanceAmount = ii?.balanceAmount;
          customer.lastModifiedDate = ii?.lastModifiedDate;
          return customer;
        });

        // let arr = customerList;

        // arr = arr.concat(updatedSlice);
        let filterData = updatedSlice.filter(item => {
          return item.balanceAmount !== undefined;
        });
        let sortarra = filterData.sort(function (a, b) {
          return b.balanceAmount - a.balanceAmount;
        });

        if (calledAt === 'search') {
          setCustomerList(sortarra);
        } else {
          setCustomerList([...customerList, ...sortarra]);
        }
      }
    });
  };

  const handleFilterCustomerChange = event => {
    setPageNo(0);
    if (userType === 'customer') {
      let search = event.target.value;
      if (event.target.value) {
        Customer.getCustomerSearchName(search, Auth.getStoreId(), response => {
          if (response.status === 'success') {
            let filteredAnonymous = response.data.filter(item => {
              return item.customerName?.toUpperCase() !== 'ANONYMOUS';
            });
            setCustomerList(filteredAnonymous);
            // setCustomerListiii(filteredAnonymous);
            let ids = { customerIds: [] };
            ids.customerIds = filteredAnonymous.map(customer => {
              return customer.id;
            });
            if (filteredAnonymous.length > 0 && ids) {
              getCustomerBalance(filteredAnonymous, ids, 'search');
            }
          }
        });
      } else {
        getCustomerData(pageNo);
      }
    } else if (userType === 'vendor') {
      let search = event.target.value;
      if (event.target.value) {
        Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
          if (response.status === 'success') {
            let vendorList = response.data.map(vendor => {
              vendor.customerName = vendor.name;
              vendor.contact = vendor.contactNo;
              return vendor;
            });
            setCustomerList(vendorList);
            // setCustomerListiii(vendorList);
            let ids = { customerIds: [] };
            ids.customerIds = vendorList.map(vendor => {
              return vendor.id;
            });

            if (vendorList.length > 0 && ids) {
              getCustomerBalance(vendorList, ids, 'search');
            }
          }
        });
      } else {
        getVendorData(pageNo);
      }
    }
  };

  const handlecustomerChange = React.useCallback(
    debounce(handleFilterCustomerChange, 500),
    []
  );

  const handleScroll = e => {
    let element = e.target;
    if (
      Math.abs(
        element.scrollHeight - (element.scrollTop + element.clientHeight) <=
          1 && hasMoreData
      )
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;
      setPageNo(pageNum);
      if (window.location.hash.endsWith('/customer')) {
        getCustomerData(pageNum);
      } else {
        getVendorData(pageNum);
      }
    } else {
      setSkeletonLoading(false);
    }
  };

  // const handleFilterCustomerChange = event => {
  //   let search = event.target.value;

  //   let filteredArray = customerList.filter(customer => {
  //     return (
  //       customer.customerName?.toUpperCase().includes(search.toUpperCase()) ||
  //       customer.email?.toUpperCase().includes(search.toUpperCase()) ||
  //       customer.contact?.toString().includes(search)
  //     );
  //   });

  //   let array = filteredArray.filter(customer => {
  //     return customer.deleted === false;
  //   });
  //   if (array.length <= 0 || event.target.value === '') {
  //     userType === 'customer'
  //       ? setCustomerList(customerListiii)
  //       : setCustomerList(customerListiii);
  //   } else {
  //     setCustomerList(array);
  //   }
  // };

  const TotaldaysFromLastModified = (startDate, EndDate) => {
    if (startDate) {
      startDate = new Date(startDate);
      let difference = EndDate.getTime() - startDate.getTime();
      let totalDays = Math.floor(difference / (1000 * 3600 * 24));
      let noOFdays =
        totalDays === 0
          ? 'Last Activity Today'
          : `Last Activity ${totalDays}  days Ago`;
      return noOFdays;
    } else {
      return '-';
    }
  };

  return (
    <>
      <Box
        item
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: '#fff', pt: 7.5 }}
      >
        <Box
          sx={{
            pt: 2,
            mt: 2,
            pb: 2,
            ml: { md: 1, sm: 1, xs: 0 },
            mr: { md: 1, sm: 1, xs: 0 },

            alignItems: 'center',
            backgroundColor: '#3B78C9 ',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              textAlign: 'center',
              fontSize: '18px',
              color: '#fff',

              pb: 1,
            }}
          >
            <ReceiptOutlinedIcon sx={{ color: 'white' }} />
            {userType === 'customer' ? 'Customer Balance' : 'Vendor Balance'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                pr: 1,
                pl: 1,
                color: '#fff',
                display: { md: 'block', sm: 'none', xs: 'none' },
                fontSize: { md: '20px', sm: '13px', xs: '15px' },
              }}
            >
              Search
            </Typography>
            <input
              className='input'
              type='text'
              placeholder='Search by Name Email or Phone'
              style={{
                borderRadius: '50px',
                width: '80%',

                border: 'none',
                fontSize: '14px',
                paddingLeft: '1rem',
                mr: 10,
                height: '40px',
              }}
              onChange={handlecustomerChange}
            />
          </Box>
          {/* <SearchIcon
            className="icon"
            sx={{
              backgroundColor: '#fff',

              height: '40px',
              mr: 1,
              position: 'absolute',

              right: 80,
            }}
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          height: { md: '500px', sm: '400px', xs: '350px' },
          ml: { md: 1, sm: 0, xs: 0 },
          mt: { md: 1, sm: 0, xs: 0.5 },
          overflowY: 'scroll',
        }}
        onScroll={handleScroll}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: ' #E1EEF5' }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {userType === 'customer' ? 'Customer Name' : 'Vendor Name'}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                    {/* Total Sales */}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                    Balance
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {customerList.length > 0 &&
                  customerList.map((customer, index) => (
                    <TableRow
                      sx={
                        index % 2
                          ? { background: '#E1EEF5' }
                          : { background: 'white' }
                      }
                    >
                      <TableCell
                        key={customer.customerName}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={
                            userType === 'customer'
                              ? '/customer-details'
                              : '/vendor-details'
                          }
                          state={customer}
                        >
                          <Typography
                            variant='subtitle1'
                            color='text.primary'
                            sx={{
                              ':hover': {
                                color: '#3B78C9',
                              },
                            }}
                          >
                            {`${customer.customerName}`}
                          </Typography>
                        </Link>
                        <Typography
                          sx={{
                            display: 'inline',

                            fontSize: '13px',
                          }}
                          component='span'
                        >
                          {customer.contact}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: 'center' }}
                        component='th'
                        scope='row'
                      >
                        {/* {customer.balanceAmount} */}
                        {/* <Typography sx={{ pl: { md: 3 } }}> 46789</Typography> */}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: 'right' }}
                        component='th'
                        scope='row'
                      >
                        {/* {customer.balanceAmount} */}

                        <Typography sx={{ float: 'right' }}>
                          {customer?.balanceAmount
                            ? customer?.balanceAmount
                            : 0}
                          <Typography variant='body1' sx={{ fontSize: '10px' }}>
                            <>
                              {customer?.lastModifiedDate
                                ? TotaldaysFromLastModified(
                                    customer?.lastModifiedDate,
                                    new Date()
                                  )
                                : 'last Activity Long time ago'}
                            </>
                          </Typography>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                {hasMoreData ? (
                  <Box>
                    <Skeleton
                      width='100%'
                      height={50}
                      style={{ textAlign: 'center' }}
                    >
                      Scroll down to Load more ...
                    </Skeleton>
                  </Box>
                ) : (
                  <Box>
                    <Skeleton
                      width='100%'
                      height={50}
                      style={{ textAlign: 'center' }}
                    >
                      {userType === 'customer'
                        ? ' All Customers found'
                        : ' All Vendors found'}
                    </Skeleton>
                  </Box>
                )}
              </TableBody>
            </Table>
          </Box>
          <Divider />
        </>
      </Box>
    </>
  );
}

export default CheckBalanceDashboard;
