import hotkeys from 'hotkeys-js';

// Key Combination
hotkeys(
  'alt+D,alt+C,alt+O,alt+N,alt+P,alt+V,alt+B,alt+M,alt+L,alt+E,alt+U,ctrl+alt+c,ctrl+alt+p,alt+W,alt+S,alt+F',
  function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();
    event.stopPropagation();

    switch (handler.key) {
      case 'alt+D':
        window.location.hash = '#/dashboard/customer';
        break;
      case 'alt+C':
        window.location.hash = '#/customers';
        break;
      case 'alt+O':
        window.location.hash = '#/orders';
        break;
      case 'alt+P':
        window.location.hash = '#/dashboard/vendor';
        break;
      case 'alt+V':
        window.location.hash = '#/vendors';
        break;
      case 'alt+B':
        window.location.hash = '#/purchase-bill';
        break;

      case 'alt+N':
        window.location.hash = '#/neworders';

        break;
      case 'alt+L':
        window.location.hash = '#/purchase-ledger';
        break;
      case 'alt+E':
        window.location.hash = '#/expenses';
        break;
      case 'alt+U':
        window.location.hash = '#/user-setup';
        break;
      case 'ctrl+alt+c':
        window.location.hash = '#/categories';
        break;
      case 'ctrl+alt+p':
        window.location.hash = '#/products';
        break;
      case 'alt+W':
        window.location.hash = '#/purchase-order';
        break;
      case 'alt+S':
        window.location.hash = '#/payments/vendor';
        break;
      case 'alt+F':
        window.location.hash = '#/payments/customer';
        break;
      default:
        alert('shortcut not available');
    }
  }
);
