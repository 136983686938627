import UpstoreApi from '../UpstoreApi';

class PurchaseOrders {
  // getAllPurchaseOrderByStoreId = (storeId, deleted, pageNum, callBack) => {
  //   UpstoreApi({
  //     method: 'GET',
  //     url: `/purchaseOrder/getAllPurchaseOrderByStoreId/${storeId}?deleted=${deleted}&pageNumber=${pageNum}`,
  //   })
  //     .then(response => {
  //       if (response.data) {
  //         callBack({ status: 'success', data: response.data });
  //       }
  //     })
  //     .catch(error => {
  //       console.log(
  //         'Error occure while getting purchase orders',
  //         error.message
  //       );
  //       callBack({ status: 'error' });
  //     });
  // };

  getAllPurchaseOrderByStoreId = (storeId, deleted, pageNum, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseOrder/getPurchaseOrderByStoreId/${storeId}?deleted=${deleted}&startingIndex=${pageNum}&maximumLoadingSize=${20}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting purchase orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getAllPurchaseOrderDetailsById = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseOrderDetails/get-details/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting purchase orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getPurchaseOrderById = (purchaseOrderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseOrder/getPurchaseOrderById/${purchaseOrderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting purchase orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getCommission = (commission, amount) => {
    if (commission === undefined || commission === 0) {
      return 0;
    } else {
      commission = (amount * parseFloat(commission)) / 100;
    }
    return commission;
  };
  calculateSubgroverChalanDetails = ChalanItems => {
    let subGrowerSelfAmount = 0;
    let subGrowerFreight = 0;
    let subGrowerTotalQuantity = 0;
    let subGrowerActualQuantity = 0;
    let subGrowerActualWeight = 0;
    let subGrowerTotalWeight = 0;
    let totalAmount = 0;
    let commission = 0;
    let price = 0;
    let subGrowLabour = 0;
    let totalExpenses = 0;
    let selfAmount = 0;
    ChalanItems.forEach(item => {
      subGrowerSelfAmount += parseFloat(item?.selfAmount || 0);
      selfAmount += parseFloat(item?.selfAmount || 0);
      subGrowerFreight += parseFloat(item?.freight || 0);
      subGrowerTotalQuantity += parseFloat(item.quantity || 0);
      subGrowerActualQuantity += parseFloat(item.actualQuantity || 0);
      subGrowerActualWeight += parseFloat(item.actualWeight || 0);
      subGrowerTotalWeight += parseFloat(item.weight || 0);
      subGrowLabour += parseFloat(item.labour || 0);
      price += parseFloat(item.price || 0);
      totalAmount += parseFloat(item.amount || 0);
      commission +=
        this.getCommission(
          item.actualWeight
            ? item.actualWeight * item?.price
            : item?.price * item?.actualQuantity,
          item?.commission
        ) || 0;
    });

    return {
      subGrowerSelfAmount: subGrowerSelfAmount,
      selfAmount: selfAmount,
      subGrowerFreight: subGrowerFreight,
      subGrowerTotalQuantity: subGrowerTotalQuantity,
      subGrowerActualQuantity: subGrowerActualQuantity,
      subGrowerActualWeight: subGrowerActualWeight,
      subGrowerTotalWeight: subGrowerTotalWeight,
      totalAmount: totalAmount,
      commission: commission,
      labour: subGrowLabour,
      price: price,
      totalExpenses: parseFloat(
        commission + subGrowerFreight + subGrowerSelfAmount
      ),
      grossTotal: parseFloat(totalAmount - totalExpenses),
    };
  };
  calculateTotalValues = ChalanItems => {
    let totalSelfAmount = 0;
    let totalFreight = 0;
    let totalQuantity = 0;
    let totalActualQuantity = 0;
    let totalActualWeight = 0;
    let totalWeight = 0;
    let grandTotal = 0;
    let totalLabour = 0;
    let totalPrice = 0;
    let totalCommission = 0;

    ChalanItems.forEach(item => {
      totalSelfAmount += parseFloat(item?.subGrowerSelfAmount || 0);
      totalFreight += parseFloat(item?.subGrowerFreight || 0);
      totalQuantity += parseFloat(item?.subGrowerTotalQuantity || 0);
      totalActualQuantity += parseFloat(item?.subGrowerActualQuantity || 0);
      totalActualWeight += parseFloat(item?.subGrowerActualWeight || 0);
      totalWeight += parseFloat(item?.subGrowerTotalWeight || 0);
      totalLabour += parseFloat(item?.labour || 0);
      totalPrice += parseFloat(item?.price || 0);
      totalCommission += parseFloat(item?.commission || 0);
      grandTotal += parseFloat(item?.grossTotal || 0);
    });
    return {
      totalSelfAmount: totalSelfAmount,
      totalFreight: totalFreight,
      totalQuantity: totalQuantity,
      totalActualQuantity: totalActualQuantity,
      totalActualWeight: totalActualWeight,
      totalWeight: totalWeight,
      totalLabour: totalLabour,
      totalPrice: totalPrice,
      totalCommission: totalCommission,
      grandTotal: parseFloat(grandTotal - totalSelfAmount),
    };
  };

  updatePurchaseOrderById = (updatedObj, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/purchaseOrder/updatePurchaseOrderById/${id}`,
      data: updatedObj,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });

          //   Store.getStoreByStoreId(Auth.getStoreId(), () => {});
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting purchase orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  addVariatyByStoreId = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/variety/addVariety`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });

          //   Store.getStoreByStoreId(Auth.getStoreId(), () => {});
        }
      })
      .catch(error => {
        console.log('Error occure while adding variaty ', error.message);
        callBack({ status: 'error' });
      });
  };
  getAllVariatiyByType = (storeId, type, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/variety/getAllVarietyByStoreId/${storeId}?type=${type}`,
    })
      .then(response => {
        //sending response according to the  type
        if (response.data && type === 'packing') {
          callBack({
            status: 'success',
            data: { stringArrayData: response.data, type: 'packing' },
          });
        }
        if (response.data && type === 'quality') {
          callBack({
            status: 'success',
            data: { stringArrayData: response.data, type: 'quality' },
          });
        }
        if (response.data && type === 'variety') {
          callBack({
            status: 'success',
            data: { stringArrayData: response.data, type: 'variety' },
          });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting purchase orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  deleteVendorStoreOrder = (storeOrderId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/purchaseOrder/deletePurchaseOrderById/' + storeOrderId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting order', error.message);
        callBack({ status: 'error' });
      });
  };

  getDiscountedAmount = (discount, amount) => {
    // let commissionCal = 0;
    // if (commission != undefined && commission.endsWith('%')) {
    //   let trimmedCommission = parseFloat(commission.slice(0, -1));
    //   if (trimmedCommission > 100) {
    //     this.props.snackbar('% must be less or equal to 100', 'error');
    //     trimmedCommission = 100;
    //   }
    //   commissionCal = (amount * trimmedCommission) / 100;
    // } else if (commission != undefined) {
    //   commissionCal = commission;
    // }

    let discountCal = 0;
    if (discount != undefined && discount.endsWith('%')) {
      let trimmedDiscount = parseFloat(discount.slice(0, -1));
      if (trimmedDiscount > 100) {
        this.props.snackbar('% must be less or equal to 100', 'error');
        trimmedDiscount = 100;
      }
      discountCal = (amount * trimmedDiscount) / 100;
    } else if (discount != undefined) {
      discountCal = discount;
    }

    return amount - parseFloat(discountCal);
  };

  deletePurchaseOrderDetails = (purchaseOrderDetailsId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url:
        'purchaseOrderDetails/deletePurchaseOrderDetailsById/' +
        purchaseOrderDetailsId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while deleting purchase order details',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  searchByNameOrPurchaseOrderNumber = (storeId, searchVal, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseOrder/search/${storeId}?search=${searchVal}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while searching Purchase Order ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}
export default new PurchaseOrders();
