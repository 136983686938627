import React, { Component } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { Loading } from '../../component/loading/Loading';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import Auth from '../../modules/Auth';
import { Box } from '@mui/system';
import Payments from '../../modules/Payments';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import DownloadIcon from '@mui/icons-material/Download';
import PaymentDialog from '../../component/paymentDialog/PaymentDialog';
import EditIcon from '@mui/icons-material/Edit';
import PaymentEditDialog from '../../component/paymentDateEditDialog/PaymentEditDialog';
import * as moment from 'moment';
import PaymentChart from '../../component/paymentChart/PaymentChart';
import MakePaymentDialog from '../../component/makePaymentDialog/MakePaymentDialog';
import HTMLRenderer from 'react-html-renderer';
import Store from '../../modules/Store';
import DeleteIcon from '@mui/icons-material/Delete';

import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import './style.css';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { debounce } from '../../utils';
export default class PaymentsList extends Component {
  state = {
    currency: null,
    paymentsList: [],
    loader: true,
    upstoreSnackBar: { open: false },
    paymentDateDialog: { paymentEditDialog: false },
    paymentDetails: { payementListDialog: false },
    paymentOverView: false,
    paymentType: '',
    makePaymentDialog: false,
    openDeleteDialog: { open: false, row: {} },
    pageNumb: 0,
    loadDataGrid: false,

    value: 0,
  };

  componentDidMount() {
    if (window.location.hash.endsWith('/customer')) {
      this.setState({ paymentType: 'customer' }, () => {
        this.getPaymentlist(false, 0);
      });
    } else {
      this.setState({ paymentType: 'vendor' }, () => {
        this.getPaymentlist(false, 0);
      });
    }
    this.getCurrency();
  }

  getPaymentlist = (cnd, pageNum) => {
    this.setState({ loader: true });

    Payments.getPaymentListByStoreId(
      Auth.getStoreId(),
      this.state.paymentType,
      pageNum,
      cnd,

      response => {
        if (response.status === 'success') {
          let filteredPayments = [];
          if (
            this.state.paymentType === 'vendor' ||
            this.state.paymentType === 'party'
          ) {
            filteredPayments = response.data.filter(item => {
              return (
                item.type !== null && item.type.toLowerCase() !== 'customer'
              );
            });
          } else if (this.state.paymentType === 'customer') {
            filteredPayments = response.data.filter(item => {
              return (
                item.type !== null &&
                item.type.toLowerCase() !== 'vendor' &&
                item.type.toLowerCase() !== 'party'
              );
            });
          }

          filteredPayments.forEach(payments => {
            if (payments.paymentDate != null) {
              payments.paymentDate = new Date(payments.paymentDate + 'Z');
            }
            if (payments.checkDate != null) {
              payments.checkDate = new Date(payments.checkDate + 'Z');
            }
          });
          this.setState({ paymentsList: [...filteredPayments], loader: false });
        } else {
          console.log('error');
          this.setState({ paymentsList: [], loader: false });
        }
      }
    );
  };
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };
  // for snackbar
  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };
  closePaymentEditDateDialog = () => {
    let closeEditDateDialog = {
      paymentEditDialog: false,
    };
    this.setState({ paymentDateDialog: closeEditDateDialog });
  };
  //handle edit payment date
  handlePaymentDate = paymentObj => {
    let paymentDateObj = {
      paymentDa: paymentObj,
      paymentEditDialog: true,
    };
    this.setState({ paymentDateDialog: paymentDateObj }, () => {});
  };
  //downloard payment recipt
  downloadPayment = p => {
    console.log(p, 'pp');
    let paymentObj = {
      payment: p,
      payementListDialog: true,
    };
    this.setState({ paymentDetails: paymentObj });
  };
  closePaymentDialog = () => {
    let closePaymentDialog = {
      payementListDialog: false,
    };
    this.setState({ paymentDetails: closePaymentDialog });
  };

  paymentChange = debounce(e => this.handleSearchOrderByPaymentId(e), 300);

  handleSearchOrderByPaymentId = SearchVl => {
    Payments.searchPaymentByPaymentId(
      Auth.getStoreId(),
      SearchVl,
      this.state.paymentType,
      response => {
        if (response.status === 'success') {
          if (this.state.paymentType === 'customer') {
            let searchFilteredPayments = response.data.filter(item => {
              if (this.state.value === 0) {
                return item.type.toLowerCase() !== 'vendor' && !item.deleted;
              } else {
                return item.type.toLowerCase() !== 'vendor' && item.deleted;
              }
            });
            this.setState({ paymentsList: searchFilteredPayments });
          } else {
            let searchFilteredPayments = response.data.filter(item => {
              if (this.state.value === 0) {
                return item.type.toLowerCase() !== 'customer' && !item.deleted;
              } else {
                return item.type.toLowerCase() !== 'customer' && item.deleted;
              }
            });

            this.setState({ paymentsList: searchFilteredPayments });
          }
        }
        if (response.status === 'error') {
          this.getPaymentlist(this.state.value, 0);
        }
      }
    );
  };

  handlePreviousPage = deletedBtn => {
    if (!deletedBtn) {
      let pageNum = this.state.pageNumb - 1;
      this.setState({ pageNumb: pageNum }, () => {
        this.getPaymentlist(false, this.state.pageNumb);
      });
    } else {
      let pageNum = this.state.pageNumb - 1;
      this.setState({ pageNumb: pageNum }, () => {
        this.getPaymentlist(true, this.state.pageNumb);
      });
    }
  };

  handleNextPage = deletedBtn => {
    if (!deletedBtn) {
      let pageNum = this.state.pageNumb + 1;
      this.setState({ pageNumb: pageNum }, () => {
        this.getPaymentlist(false, this.state.pageNumb);
      });
    } else {
      let pageNum = this.state.pageNumb + 1;
      this.setState({ pageNumb: pageNum }, () => {
        this.getPaymentlist(true, this.state.pageNumb);
      });
    }
  };
  handleDeletePaymentCallback = (deleted, remarks) => {
    let data = {
      deleteComment: remarks,
    };
    let paymentId = this.state.openDeleteDialog?.row.id;
    Payments.deletePayment(paymentId, data, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackBar('payment deleted successfully', 'success');
        this.getPaymentlist(false, this.state.pageNumb);
        this.setState({
          openDeleteDialog: { row: {}, open: false },
        });
      } else {
        this.openUpstoreSnackBar(
          'Error occured while deleting payments',
          'error'
        );
      }
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    if (newValue === 0) {
      this.getPaymentlist(false, 0);
    } else {
      this.getPaymentlist(true, 0);
    }
  };

  columns = [
    {
      field: 'Payment No',
      headerName: 'Payment No',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      type: 'text',
      renderCell: params => (
        <>
          <Box sx={{ pr: 2 }}>
            {params.row.deleted ? (
              <Tooltip title='Payment Has Been Deleted'>
                <DeleteIcon
                  style={{
                    fill: 'red',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
          <Typography> {params.row.paymentNumber}</Typography>
        </>
      ),
    },

    {
      field: 'customerName',
      headerName: 'Name',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'left',
      type: 'string',
      renderCell: params => (
        <Tooltip title={params.row.customerName} placement='top'>
          <Typography variant='h6' fontSize='15px'>
            {params.row.customerName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      renderCell: params => (
        <Typography variant='h6' fontSize='15px'>
          {params.row.paymentDate === null
            ? 'N / A'
            : moment(params.row.paymentDate).format('DD-MM-YYYY ')}
        </Typography>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      fontSize: '15px',
      align: 'center',
      type: 'string',
      renderCell: params => (
        <>
          <Typography>
            {params.row.amount}{' '}
            {this.state.currency ? (
              <HTMLRenderer html={this.state.currency} />
            ) : null}
          </Typography>
        </>
      ),
    },

    {
      field: 'checkDate',
      headerName: 'Cheque Date',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      renderCell: params => (
        <Typography variant='h6' fontSize='15px'>
          {params.row.checkDate === null
            ? '-'
            : moment(params.row.checkDate).format('DD-MM-YYYY')}
        </Typography>
      ),
    },
    {
      field: 'paymentType',
      headerName: 'Type',
      type: 'string',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      type: 'string',
      width: 245,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Tooltip title={params.row.remark} placement='bottom'>
          <Typography variant='h6' fontSize='15px'>
            {params.row.remark}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'deleteComment',
      headerName: 'Deleted Comment',
      width: 100,
      type: 'reciept',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',

      renderCell: params => (
        <>
          <Tooltip title={params.row.deleteComment} placement='bottom'>
            <Typography>{params.row.deleteComment}</Typography>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'reciept',
      headerName: 'Receipt',
      width: 100,
      type: 'reciept',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <DownloadIcon
          sx={{ color: '#3B78C9', cursor: 'pointer' }}
          onClick={event => this.downloadPayment(params.row)}
          // onClick={(event) => {

          //  // params.row, this.setState({ payementListDialog: true });
          // }}
        />
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'action',
      width: 110,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => (
        <>
          {!params.row.deleted ? (
            <EditIcon
              sx={{ pr: 1, fontSize: '30px' }}
              onClick={event => this.handlePaymentDate(params.row)}
            />
          ) : (
            <EditIcon sx={{ pr: 1, fontSize: '30px', fill: 'gray', ml: 1 }} />
          )}
          {params.row.customerName === 'Anonymous' && !params.row.deleted ? (
            <DeleteIcon
              onClick={() =>
                this.setState({
                  openDeleteDialog: { row: params.row, open: true },
                })
              }
            />
          ) : null}
        </>
      ),
    },
  ];

  render() {
    return (
      <>
        <Header pageName='Customers' />
        <Box
          sx={{
            width: '95%',
            height: 600,
            mt: 7,
            ml: 8,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {this.state.loader ? (
            <Loading />
          ) : (
            <>
              <Grid container columnSpacing={2}>
                <Grid item lg={4.5} md={4} sm={12}>
                  <BreadCrumb pageName='Payment List' />
                </Grid>
                <Grid item md={3.5} sm={6} xs={11}>
                  <input
                    type='text'
                    className='expandedPaymentSearch'
                    name='search'
                    placeholder='Search Payments ...'
                    onChange={event => {
                      this.paymentChange(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {' '}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      mt: { xs: 1 },
                    }}
                  >
                    <Button
                      variant='contained'
                      sx={{
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                        p: 0,
                        backgroundColor: '#3B78C9',
                        float: 'right',
                      }}
                      onClick={() => this.setState({ paymentOverView: true })}
                    >
                      <Typography variant='h5' sx={{ fontSize: '13px' }}>
                        Payment Overview
                      </Typography>
                    </Button>
                    <Button
                      variant='contained'
                      sx={{
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',

                        backgroundColor: '#3B78C9',
                        p: 0,
                        float: 'right',
                      }}
                      onClick={() => this.setState({ makePaymentDialog: true })}
                    >
                      <Typography variant='h5' sx={{ fontSize: '13px' }}>
                        Make Payment
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  aria-label='Payment Tabs'
                >
                  <Tab
                    label='Confirmed Payments'
                    // onClick={() => confirmePaymentsCallback(false)}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label='Deleted Payments'
                    // onClick={() => confirmePaymentsCallback(true)}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>

              {this.state.paymentsList.length > 0 ? (
                <Grid item md={12} sm={12} xs={12}>
                  <PaymentTab
                    key={this.state.value}
                    columns={this.columns}
                    data={this.state.paymentsList}
                    value={this.state.value}
                  />
                  {this.state.loader ? null : (
                    <Grid
                      style={{
                        padding: '1.6vh',
                        float: 'right',

                        bottom: '0px',
                      }}
                    >
                      <Button
                        disabled={this.state.pageNumb <= 0}
                        onClick={() => this.handlePreviousPage(false)}
                        sx={{ color: '#3B78C9' }}
                      >
                        Prev
                      </Button>
                      <Button
                        disabled={this.state.paymentsList.length < 15}
                        sx={{ margin: '1vh', color: '#3B78C9' }}
                        onClick={() => this.handleNextPage(false)}
                      >
                        Next
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography sx={{ mt: 2 }}>No payments Found</Typography>
              )}
            </>
          )}
        </Box>

        {this.state.paymentDetails.payementListDialog === true ? (
          <PaymentDialog
            openDialog={this.state.paymentDetails.payementListDialog}
            closeDialog={this.closePaymentDialog}
            list={this.state.paymentDetails.payment}
            type={this.state.paymentType}
          />
        ) : null}

        {this.state.paymentOverView !== undefined ? (
          <PaymentOverViewDialog
            openDialog={this.state.paymentOverView}
            closeDialog={() => this.setState({ paymentOverView: false })}
            type={this.state.paymentType}
          />
        ) : null}
        {this.state.paymentDateDialog !== undefined ? (
          <PaymentEditDialog
            openDialog={this.state.paymentDateDialog.paymentEditDialog}
            paymentEditD={this.state.paymentDateDialog}
            closeDateDialog={this.closePaymentEditDateDialog}
            reloadPaymentList={this.getPaymentlist}
          />
        ) : null}
        {this.state.makePaymentDialog === true ? (
          <MakePaymentDialog
            openDialog={this.state.makePaymentDialog}
            closeDialog={() => this.setState({ makePaymentDialog: false })}
            snackbar={this.openUpstoreSnackBar}
            type={this.state.paymentType}
            reloadPayments={this.getPaymentlist}
          />
        ) : null}
        {this.state.upstoreSnackBar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={this.state.upstoreSnackBar}
            onSnackbarClose={this.onSnackbarClose}
          />
        ) : null}

        {this.state.openDeleteDialog.open ? (
          <DeleteDialog
            reason={true}
            snackbar={this.openUpstoreSnackBar}
            openDialog={this.state.openDeleteDialog.open}
            closeDialogCallback={() =>
              this.setState({
                openDeleteDialog: {
                  ...this.state.openDeleteDialog,
                  open: false,
                },
              })
            }
            deleteCallback={this.handleDeletePaymentCallback}
            dialogContent=' Are you sure you want to delete the payment of Anonymous Customer ?'
          />
        ) : null}

        {this.state.paymentType === 'customer' ? (
          <>
            {AllPermissions.getUserAccessiblePermissions('Payments') !==
              undefined &&
            AllPermissions.getUserAccessiblePermissions('Payments').read ===
              false ? (
              <ErrorDialog></ErrorDialog>
            ) : null}
          </>
        ) : null}

        {this.state.paymentType === 'vendor' ? (
          <>
            {AllPermissions.getUserAccessiblePermissions('VendorPayment') !==
              undefined &&
            AllPermissions.getUserAccessiblePermissions('VendorPayment')
              .read === false ? (
              <ErrorDialog></ErrorDialog>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

function PaymentOverViewDialog({ openDialog, closeDialog, type }) {
  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} maxWidth='sm' fullWidth>
        <DialogTitle textAlign='center'></DialogTitle>
        <DialogContent>
          <Box>
            <PaymentChart type={type} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ width: '126px', height: '27px' }} onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `payment-tab-${index}`,
    'aria-controls': `payment-tabpanel-${index}`,
  };
}

function PaymentTab({ columns, data, value }) {
  return (
    <Box sx={{ width: '100%' }}>
      <TabPanel value={value} index={value}>
        <Box
          sx={{
            width: '100%',
            height: 600,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          <DataGrid
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              boxShadow: 2,
              border: 6,
              backgroundColor: '#fff',
              borderColor: '#eee',
              color: 'black',
              '& .MuiDataGrid-cell': {
                outline: 'none',
                fontSize: '16px',
                fontWeight: '25px',
              },
              '& .MuiDataGrid-cell:hover': {
                outline: 'none',
                color: '#21211f',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& svg': {
                color: '#3b78c9',
              },
              '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                color: 'black',
              },
            }}
            density='compact'
            rows={data}
            columns={
              value === 1
                ? columns
                : columns.filter(item => {
                    return item.field !== 'deleteComment';
                  })
            }
            pageSize={20}
            hideFooter={true}
            rowsPerPageOptions={[20, 40, 60]}
            aria-label='payment list'
          />
        </Box>
      </TabPanel>
    </Box>
  );
}
