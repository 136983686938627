import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './banners.scss';
import BannersXS from './Banners-xs';
import UpstoreProperties from '../../modules/UpstoreProperties';
import Validation from '../../modules/Validation';
import { serverConfig } from '../../config/server-config';
import Auth from '../../modules/Auth';

function launchLink(link) {
  window.location.href = link;
}

function Banner(props) {
  const contentPosition = props.contentPosition
    ? props.contentPosition
    : 'left';
  const totalItems = props.length ? props.length : 3;
  const mediaLength = totalItems - 1;

  let items = [];
  const content = (
    <Grid item xs={12 / totalItems} key='content'>
      <CardContent className='Content'>
        <Typography className='Title'>{props.item.Name}</Typography>

        <Typography className='Caption'>{props.item.Caption}</Typography>

        <Button
          variant='outlined'
          className='ViewButton'
          onClick={event => launchLink(props.item.Link)}
        >
          View More
        </Button>
      </CardContent>
    </Grid>
  );

  for (let i = 0; i < mediaLength; i++) {
    const imageObj = props.item.Images[i];

    const media = (
      <Grid item xs={12 / totalItems} key={props.item.Name}>
        <CardMedia
          className='Media'
          onClick={event => launchLink(imageObj.Link)}
          image={imageObj.image}
          title={props.item.Name}
        />
      </Grid>
    );

    items.push(media);
  }

  if (contentPosition === 'left') {
    items.unshift(content);
  } else if (contentPosition === 'right') {
    items.push(content);
  } else if (contentPosition === 'middle') {
    items.splice(items.length / 2, 0, content);
  }

  return (
    <Card raised className='Banner'>
      <Grid container spacing={0} className='BannerGrid'>
        {items}
      </Grid>
    </Card>
  );
}

class Banners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      timer: 500,
      animation: 'fade',
      indicators: true,
      timeout: 500,
      bannerItems: [],
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false,
      windowHeight: undefined,
      windowWidth: undefined,
    };
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  componentDidMount() {
    this.createBannerItems();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  createBannerItems = () => {
    let bannerImageLinks = UpstoreProperties.getProperty('BANNER_IMAGE_LINKS');

    if (Validation.isBlankNot(bannerImageLinks)) {
      bannerImageLinks = JSON.parse(bannerImageLinks);
    }
    let bannerNames = UpstoreProperties.getProperty('BANNER_NAMES');
    if (Validation.isBlankNot(bannerNames)) {
      bannerNames = JSON.parse(bannerNames);
    }
    let bannerText = UpstoreProperties.getProperty('BANNER_TEXT');
    if (Validation.isBlankNot(bannerText)) {
      bannerText = JSON.parse(bannerText);
    }
    let bannerLinks = UpstoreProperties.getProperty('BANNER_LINKS');
    if (Validation.isBlankNot(bannerLinks)) {
      bannerLinks = JSON.parse(bannerLinks);
    }

    let items = [];
    let bannerImageIndex = 1;

    for (var i = 0; i < bannerText.length; i++) {
      let bannerObj = {
        Name: bannerNames != null ? bannerNames[i] : '',
        Caption: bannerText != null ? bannerText[i] : '',
        Image:
          serverConfig.cmsSource +
          'store_' +
          Auth.getStoreId() +
          '/images' +
          '/Banners/banner_images-BannerImageIndex-' +
          bannerImageIndex,
        Link: bannerLinks != null ? bannerLinks[i] : '',
        contentPosition:
          bannerImageIndex > 0
            ? 'middle'
            : bannerImageIndex > 2
            ? 'left'
            : bannerImageIndex > 4
            ? 'right'
            : 'middle',
        Images: [
          {
            image:
              serverConfig.cmsSource +
              'store_' +
              Auth.getStoreId() +
              '/images' +
              '/Banners/banner_images-BannerImageIndex-' +
              bannerImageIndex++,
            link: bannerImageLinks[bannerImageIndex - 1],
          },
          {
            image:
              serverConfig.cmsSource +
              'store_' +
              Auth.getStoreId() +
              '/images' +
              '/Banners/banner_images-BannerImageIndex-' +
              bannerImageIndex++,
            link: bannerImageLinks[bannerImageIndex - 1],
          },
        ],
      };
      items.push(bannerObj);
    }

    this.setState({ bannerItems: items });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getCarousalView = () => {
    if (window.matchMedia('xl', this.props.width)) {
      return 5;
    }
    if (window.matchMedia('lg', this.props.width)) {
      return 4;
    }
    if (window.matchMedia('md', this.props.width)) {
      return 3;
    }
    if (window.matchMedia('sm', this.props.width)) {
      return 2;
    }
    return 1;
  };

  render() {
    return (
      <div style={{ color: '#494949' }}>
        {this.getCarousalView() > 2 ? (
          <Carousel
            key={this.state.bannerItems}
            className='Example'
            infiniteLoop={true}
            autoPlay={this.state.autoPlay}
            timer={this.state.timer}
            animation={this.state.animation}
            indicators={this.state.indicators}
            timeout={this.state.timeout}
            navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
            navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
            showThumbs={false}
            // next={(now, previous) => {}}
            // prev={(now, previous) => {}}
            // onChange={(now, previous) => {}}
          >
            {this.state.bannerItems.map((item, index) => {
              return (
                <Banner
                  item={item}
                  key={index}
                  contentPosition={item.contentPosition}
                />
              );
            })}
          </Carousel>
        ) : (
          <BannersXS bannerItems={this.state.bannerItems} />
        )}
      </div>
    );
  }
}

export default Banners;
