import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { Typography, Button } from '@mui/material';
import StoreAccount from '../../modules/Store/index';
import Logo from '../../modules/Logo';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import Store from '../../modules/Store';
import * as moment from 'moment';
import HTMLRenderer from 'react-html-renderer';
import Invoice from '../../modules/Invoice/invoice';
import '.././orderInvoice/styles.css';
import Vendor from '../../modules/Vendor';
import { serverConfig } from '../../config/server-config';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';

const ref = React.createRef();
let amount = 0;
let prevBal;

class DraftedPurchaseOrderInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoUrl: null,
      signatureUrl: null,
      currency: null,
      orderDetails: this.props.orderDetails,
      options: {
        unit: 'pt',
        format: [280, 180],
        storeAccount: [],
      },
      bal: 0,
      selectedTemplate: {},
      customerBalance: {},
      lastPayment: {},
      customer: {},
      storeDetails: {},
      payments: [],
      returnOrder: [],
      paymentAdjustment: [],
      generalPayment: [],
      invoiceData: {},
      prevInvoiceData: false,
      totalOldBalance:
        this.props?.orderDetails?.lastBalance +
        this.props?.orderDetails?.totalAmount,
    };
  }

  componentDidMount() {
    this.storeAccount();
    this.getStoreById();
    this.getLogoUrl(Auth.getStoreId());
    this.getSignatureUrl();
    this.getCurrency();
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });

    amount = 0;
    // prevBal = 0;
  }

  storeAccount = () => {
    StoreAccount.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        this.getPDFHeightAndWidth();
        let selctedBank = response.data.find(item => item.active);
        this.setState({ storeAccount: { selctedBank } });
      } else {
        this.setState({ storeAccount: [] });
      }
    });
  };
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getStoreById = () => {
    Store.getStoreByStoreId(Auth?.getStoreId(), response => {
      if (response.status === 'success') {
        let parsed = JSON.parse(response?.data?.invoice);

        if (parsed) {
          this.setState({ selectedTemplate: parsed });
        }
      } else {
        console.log('error');
      }
    });
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint')?.clientHeight;
      let maxWidth = document.getElementById('divToPrint')?.clientWidth;
      opt.format = [maxHeight, maxWidth];
      this.setState({
        options: opt,
      });
    }, 5);
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        signatureUrl: Logo.getSignatureUrl(),
      });
    } else {
      this.setState({
        signatureUrl: signature,
      });
    }
  };

  getInvoiceForOrder = () => {
    Invoice.getInvoiceForOrder(this.state.orderDetails.id, response => {
      let invoice;
      if (response.status === 'success') {
        invoice = response.data;
        if (invoice !== null) {
          this.setState({ invoiceData: response.data });
          prevBal =
            invoice.balance -
            invoice.orderAmount +
            (invoice.payments +
              //   !this.state.returnOrder.deleted &&
              // this.state.generalPayment?.length <= 0
              //   ? 0
              //   :
              invoice.returnOrder +
              invoice.adjust);
        }
        this.setState({ bal: prevBal });
      } else if (response.status === 'Empty') {
        this.setState({ prevInvoiceData: true });
      }
    });
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            filename={`receipt__${this.state.orderDetails?.purchaseOrderNo}.pdf`}
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <div>
                  <Button
                    variant='contained'
                    sx={{
                      textTransform: 'capitalize',
                      color: 'white',
                      bgcolor: '#3B78C9',
                      fontSize: '14px',

                      width: '126px',
                      height: '27px',
                    }}
                  >
                    <PrintIcon
                      sx={{ color: 'white', fontSize: '16px', mr: '5px' }}
                    />{' '}
                    Print
                  </Button>
                </div>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
        </Typography>
        <br />
        <div className='divToDownload ' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100% ' }}
          >
            {Validation.isBlankNot(this.state.logoUrl) ? (
              <img
                className='invoice-bg'
                style={{ width: '100%', height: '100%' }}
                src={this.state.logoUrl}
                alt='Logo'
              ></img>
            ) : (
              `Logo`
            )}

            <div className='invoice-content row d-flex justify-content-center'>
              <h5 style={{ textAlign: 'center', color: '#3B78C9' }}>
                Estimate Bill
              </h5>
              <div className='col-12'>
                <div className='card'>
                  <header
                    style={{
                      backgroundImage: `url(${this.state.selectedTemplate?.bgImageHeader})`,
                      // backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  >
                    <div className='d-flex flex-column ml-auto p-2'>
                      <div className='align-self-end'>
                        <strong>Email : </strong>
                        {this.state.storeDetails.email
                          ? this.state.storeDetails.email
                          : ''}
                        <br />
                        <strong> Contact No : </strong>{' '}
                        {this.state.storeDetails.phoneNumber}
                      </div>
                    </div>
                  </header>

                  <hr />
                  <div class='container'>
                    <div class='row'>
                      <div class='col-3 p-1'>
                        {Validation.isBlankNot(this.state.logoUrl) ? (
                          <img
                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                            src={this.state.logoUrl}
                            alt='Logo'
                          ></img>
                        ) : (
                          `Logo`
                        )}
                      </div>
                      <div class='col-9'>
                        <Typography
                          variant='h3'
                          style={{
                            color: this.state.selectedTemplate?.bgColor,
                          }}
                          fontSize='40px'
                          noWrap
                        >
                          <strong>{`${this.state.storeDetails.name}`}</strong>
                        </Typography>

                        {this.state.storeDetails.description ? (
                          <span className='text_font'>
                            <HTMLRenderer
                              html={this.state.storeDetails.description}
                            />
                          </span>
                        ) : null}
                        <div className='text_font'>
                          <strong> {this.state.storeDetails.address}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='table-responsive p-1'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        <tr className='invoice_Hed'>
                          <th></th>
                          <th
                            style={{
                              color: this.state.selectedTemplate?.bgColor,
                            }}
                          >
                            Vendor{' '}
                          </th>
                        </tr>
                        <tr className='content'>
                          <td className='font-weight-bold'>
                            {`${this.state.storeDetails.name}`}
                            <br />
                            {`${this.state.storeDetails.address}`}
                          </td>
                          {`${
                            Vendor.getVendorByVendorId(
                              this.state.orderDetails.vendor.id,
                              () => {}
                            ).name
                          }`}
                          <br />
                          {`${
                            Vendor.getVendorByVendorId(
                              this.state.orderDetails.vendor.id,
                              () => {}
                            ).address
                          }`}
                        </tr>
                      </tbody>
                    </table>
                  </div> */}

                  <hr />
                  <div class='container'>
                    <div class='row'>
                      <div class='col p-1'>
                        <h5 style={{ fontSize: '15px' }}>
                          Order No :
                          <span style={{ fontSize: '12px' }}>
                            {' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.orderDetails,
                              'purchaseOrderNo'
                            )
                              ? this?.state?.orderDetails?.purchaseOrderNo
                              : 'N/A'}{' '}
                          </span>
                        </h5>
                        <h5 style={{ fontSize: '15px' }}>
                          Invoice Date :&nbsp;&nbsp;
                          <span style={{ fontSize: '12px' }}>
                            {this.props.invoice
                              ? this.props.invoice &&
                                moment
                                  .utc(this.props.invoice)
                                  .local()
                                  .format('DD-MM-yyyy')
                              : this.state?.orderDetails?.billDate &&
                                moment
                                  .utc(this.state?.orderDetails?.billDate)
                                  .local()
                                  .format('DD-MM-yyyy')}
                          </span>
                        </h5>
                      </div>
                      <div class='col-8 p-1'>
                        <div className='d-flex justify-content-around'>
                          <div>
                            <div
                              style={{
                                color: this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <b> Vendor </b>
                            </div>
                            {`${
                              Vendor.getVendorByVendorId(
                                this.state?.orderDetails?.vendorId,
                                () => {}
                              )?.name
                            }`}
                            <br />
                            {`${
                              Vendor.getVendorByVendorId(
                                this.state?.orderDetails?.vendorId,
                                () => {}
                              )?.address
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='products p-2'>
                    <table className='table '>
                      <tbody>
                        <tr
                          className='add'
                          style={{
                            backgroundColor:
                              this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          <th>Item Name</th>

                          <th> Base Price</th>
                          <th>Quantity</th>
                          <th>Unit</th>

                          <th> Base Price Discount</th>

                          <th className='text-center'>Amount</th>
                        </tr>
                        {this.state.orderDetails !== undefined &&
                        this.state.orderDetails !== null
                          ? this.state.orderDetails.purchaseOrderDetails.map(
                              item => {
                                return (
                                  <tr className='content ' key={item.id}>
                                    <td>
                                      {Store?.companyDetails?.businessType !==
                                      'STORE' ? (
                                        <>
                                          {item.itemName}
                                          <br />
                                          <span
                                            style={{
                                              fontSize: '9px',
                                            }}
                                            className='description'
                                          >
                                            {item.itemDescription !== null &&
                                            item.itemDescription !== undefined
                                              ? item.itemDescription &&
                                                item.itemDescription
                                              : null}
                                          </span>
                                        </>
                                      ) : (
                                        item.itemName
                                      )}
                                    </td>

                                    <td>
                                      {item.basePrice}
                                      {this.state.currency !== undefined &&
                                      this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </td>
                                    <td>{item.noOfItems}</td>
                                    <td>{item.quantity + item.unit}</td>
                                    <td>
                                      {item.basePriceDiscount !== null &&
                                      item.basePriceDiscount !== '0'
                                        ? item.basePriceDiscount
                                        : '-'}
                                    </td>
                                    {/* <td className='text-center'>
                                    {item?.taxAmount !== null &&
                                    item?.taxAmount !== '0'
                                      ? item.taxAmount
                                      : '-'}
                                  </td> */}
                                    <td className='text-center'>
                                      {item.amount}
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table className='table table-borderless'>
                      <tbody style={{ float: 'right' }}>
                        <tr>
                          <td>
                            {' '}
                            <strong
                              style={{
                                color: this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              Order Total Amount :
                            </strong>
                          </td>
                          <td>
                            {this.state.orderDetails?.totalAmount &&
                              this.state.orderDetails?.totalAmount.toFixed(3)}
                            &nbsp;
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </td>
                        </tr>

                        {this.state.orderDetails.totalDiscount !== 0 ? (
                          <>
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Discount :
                                </strong>
                              </td>

                              <td>
                                {this.state.orderDetails.totalDiscount &&
                                  this.state.orderDetails.totalDiscount}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {this.state.orderDetails?.tax !== 0 &&
                        this.state.orderDetails?.tax !== null ? (
                          <>
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Tax :
                                </strong>
                              </td>

                              <td>
                                {this.state.orderDetails?.tax &&
                                  this.state.orderDetails?.tax}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  <hr />

                  <hr />

                  <div
                    style={{
                      // margin: '5px',
                      paddingBottom: '1rem',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <h6
                      style={{
                        marginRight: '18px',
                        color: this.state.selectedTemplate?.bgColor,
                      }}
                    >
                      Signature
                    </h6>

                    {Validation.isBlankNot(this.state.signatureUrl) ? (
                      <img
                        style={{ maxWidth: '100px', maxHeight: '80px' }}
                        src={this.state.signatureUrl}
                        alt='Signature'
                      ></img>
                    ) : (
                      `Signature`
                    )}
                  </div>

                  {this.state?.storeDetails?.termsAndCondition !== '' ? (
                    <>
                      <div
                        className='footer_class'
                        style={{
                          backgroundImage: `url(${this.state.selectedTemplate?.bgImageFooter})`,
                          backgroundSize: 'cover',
                          border: '2px solid gray',
                          padding: '5px',
                        }}
                      >
                        <div>
                          <strong> {`Terms & Conditions:`}</strong>
                        </div>
                        <div
                          sx={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <h1 style={{ fontSize: '14px', display: 'flex' }}>
                            {this.state.storeDetails.termsAndCondition ? (
                              <HTMLRenderer
                                html={this.state.storeDetails.termsAndCondition}
                              />
                            ) : (
                              'Terms And Condition'
                            )}
                          </h1>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.invoiceData ? (
                    <span style={{ fontSize: '10px', textAlign: 'center' }}>
                      {`Invoice Date : `}
                      {moment
                        .utc(this.state.invoiceData.revisionDate)
                        .local()
                        .format('DD-MM-yyyy HH:mm:ss')}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DraftedPurchaseOrderInvoice;
