import React from 'react';
import { Button, ButtonGroup, FormGroup, InputLabel } from '@mui/material';

import CameraAltIcon from '@mui/icons-material/CameraAlt';

import imageCompression from 'browser-image-compression';

import './style.css';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';

const classes = theme => ({
  root: {
    padding: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 1200,
    height: 700,
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});

const UploadImages = props => {
  const [imageIndex, setImageIndex] = React.useState(null);

  const handleCapture = target => {
    if (target === 0 || target === 1 || target === 2) {
      setImageIndex(target);
    }
    if (target.files) {
      if (target.files.length !== 0) {
        uploadImages(target.files, imageIndex);
      }
    }
  };

  const uploadImages = (images, imageIndex) => {
    Array.from(images).map(imageFile => {
      if (imageIndex < 3) {
        props.onStartUpload();
        handleImageCompression(imageFile, imageIndex);
      } else {
        this.props.onUploadImage(
          'Only three images are allowed, image rejected.',
          'error'
        );
      }
    });
  };

  const handleImageCompression = async (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await uploadToServer(compressedFile, index);
      } catch (error) {
        console.log('Image upload failed ' + error);
      }
    } else {
      await uploadToServer(imageFile, index);
    }
  };

  const uploadToServer = (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size <= maxFileSize) {
      let formData = new FormData();
      let BannnerIndex = props.index;
      let path = props.path;
      const imageName = `Banners/${path}-BannerImageIndex-${BannnerIndex}`;
      formData.append('name', imageName);
      formData.append('image', imageFile);

      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          props.snackbar(`${path}  Image Uploaded successfully`, 'success');
        } else {
          console.log('error');
          this.props.snackbar(
            'Error while uploading bannerImage image ',
            'error'
          );
        }
      });
    }
  };

  return (
    <FormGroup style={{ textAlign: 'center', margin: '10px' }}>
      <InputLabel
        htmlFor='addImages'
        shrink={true}
        style={{ paddingLeft: '200px' }}
      >
        Add Images (.jpg)
      </InputLabel>
      <input
        accept='image/*'
        className={classes.input}
        style={{ display: 'none' }}
        id='raised-button-file'
        multiple
        type='file'
        onChange={e => handleCapture(e.target)}
      />
      <ButtonGroup variant='contained' color='primary' justify='center'>
        <Button id='buttons' onClick={() => handleCapture(0)}>
          Image 1
        </Button>
        <Button id='buttons' onClick={() => handleCapture(1)}>
          Image 2
        </Button>
        <Button id='buttons' onClick={() => handleCapture(2)}>
          Image 3
        </Button>
      </ButtonGroup>

      <label htmlFor='raised-button-file'>
        <Button
          id='upload'
          variant='contained'
          component='span'
          startIcon={<CameraAltIcon />}
        >
          Upload
        </Button>
      </label>
    </FormGroup>
  );
};

export default UploadImages;
