import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { Typography, Button } from '@mui/material';
import './styles.css';
import StoreAccount from '../../modules/Store/index';
import Logo from '../../modules/Logo';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import Payments from '../../modules/Payments';
import Store from '../../modules/Store';
import * as moment from 'moment';
import HTMLRenderer from 'react-html-renderer';
import Invoice from '../../modules/Invoice/invoice';
import CancelledImG from '../../assets/images/cancelled.png';
import Vendor from '../../modules/Vendor';
import ReturnOrder from '../../modules/ReturnOrder';
import Configuration from '../../modules/Configuration';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';
import { serverConfig } from '../../config/server-config';
import { gstCheckDetails } from '../../utils';
const ref = React.createRef();
let amount = 0;
let prevBal;

class PurchaseOrderInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: null,
      signatureUrl: null,
      currency: null,
      orderDetails: this.props.orderDetails,
      options: {
        unit: 'pt',
        format: [280, 180],
        storeAccount: [],
      },
      bal: 0,
      selectedTemplate: {},
      customerBalance: {},
      lastPayment: {},
      customer: {},
      defaultConfigBalance: {},
      defaultConfigBankDetails: {},
      storeDetails: {},
      payments: [],
      returnOrder: [],
      paymentAdjustment: [],
      generalPayment: [],
      invoiceData: {},
      prevInvoiceData: false,
      totalOldBalance:
        this.props?.orderDetails?.lastBalance +
        this.props?.orderDetails?.totalAmount,
    };
  }

  componentDidMount() {
    this.storeAccount();
    // this.customerBalance();
    this.lastPayment();
    this.getLogoUrl(Auth.getStoreId());
    this.getSignatureUrl();
    this.getCurrency();
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });
    this.getInvoiceForOrder();
    this.getStoreById();
    this.getPaymentsForOrder();
    this.getReturnOrders();
    this.getDefaultConfigForPrevBalance();
    this.getDefaultConfigForBankDetails();
    amount = 0;
    // prevBal = 0;
  }

  storeAccount = () => {
    StoreAccount.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        this.getPDFHeightAndWidth();
        let selctedBank = response.data.find(item => item.active);
        this.setState({ storeAccount: { selctedBank } });
      } else {
        this.setState({ storeAccount: [] });
      }
    });
  };
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getStoreById = () => {
    Store.getStoreByStoreId(Auth?.getStoreId(), response => {
      if (response.status === 'success') {
        let parsed = JSON.parse(response?.data?.invoice);

        if (parsed) {
          this.setState({ selectedTemplate: parsed });
        }
      } else {
        console.log('error');
      }
    });
  };
  // customerBalance = () => {
  //   let customerId = { customerIds: [this.state.orderDetails.customerId] };
  //   Customer.getCustomerBalance(customerId, response => {
  //     if (response.status === 'success') {
  //       this.setState({ customerBalance: { ...response.data[0] } });
  //     } else {
  //       this.setState({ customerBalance: {} });
  //     }
  //   });
  // };

  getDefaultConfigForPrevBalance = () => {
    Configuration.getDefaultConfigurationByBusinessTypeAndSettingNumber(
      Store?.companyDetails?.businessType,
      7,
      response => {
        if (response.status === 'success') {
          this.setState({
            defaultConfigBalance: response.data,
          });
        }
      }
    );
  };

  getDefaultConfigForBankDetails = () => {
    Configuration.getDefaultConfigurationByBusinessTypeAndSettingNumber(
      Store?.companyDetails?.businessType,
      8,
      response => {
        if (response.status === 'success') {
          this.setState({
            defaultConfigBankDetails: response.data,
          });
        }
      }
    );
  };

  lastPayment = () => {
    Payments.getLastPaymentByCustomerId(
      this.state?.orderDetails?.vendorId,
      response => {
        if (response.status === 'success') {
          this.setState({ lastPayment: response.data });
        } else {
          this.setState({ lastPayment: {} });
        }
      }
    );
  };

  getPaymentsForOrder = () => {
    Payments.getPaymentByOrderIdForAnonymous(
      this.state.orderDetails.id,
      response => {
        if (response.status === 'success') {
          this.setState({ payments: response.data }, () =>
            this.getPaymentsWithoutAdjustment()
          );
        } else {
          this.setState({ payments: [] });
        }
      }
    );
  };

  getReturnOrders = () => {
    ReturnOrder.getReturnOrderByOrder(this.state.orderDetails.id, response => {
      if (response.status === 'success') {
        let deletedFalseItem = response?.data?.filter(item => {
          let newDetails = item.orderDetails.filter(item => {
            return !item.deleted;
          });
          item.orderDetails = newDetails;
          return !item.deleted;
        });

        this.setState({ returnOrder: deletedFalseItem[0] });
      } else {
        this.setState({ returnOrder: {} });
      }
    });
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint')?.clientHeight;
      let maxWidth = document.getElementById('divToPrint')?.clientWidth;
      opt.format = [maxHeight, maxWidth];
      this.setState({
        options: opt,
      });
    }, 5);
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        signatureUrl: Logo.getSignatureUrl(),
      });
    } else {
      this.setState({
        signatureUrl: signature,
      });
    }
  };

  getPaymentsWithoutAdjustment = () => {
    let withAdjustment;
    if (this.state.payments !== null) {
      let withOutAdjustment = this.state.payments.filter(
        payments => payments.paymentType !== 'Adjustment'
      );
      withAdjustment = this.state.payments.filter(
        payments => payments.paymentType === 'Adjustment'
      );
      this.setState({
        paymentAdjustment: withAdjustment,
        generalPayment: withOutAdjustment,
      });

      if (withAdjustment.length > 0) {
        withAdjustment?.map(item => {
          amount = amount += item.amount;
        });
      }
    }
  };

  getInvoiceForOrder = () => {
    Invoice.getInvoiceForOrder(this.state.orderDetails.id, response => {
      let invoice;
      if (response.status === 'success') {
        invoice = response.data;
        if (invoice !== null) {
          this.setState({ invoiceData: response.data });
          prevBal =
            invoice.balance -
            invoice.orderAmount +
            (invoice.payments +
              //   !this.state.returnOrder.deleted &&
              // this.state.generalPayment?.length <= 0
              //   ? 0
              //   :
              invoice.returnOrder +
              invoice.adjust);
        }
        this.setState({ bal: prevBal });
      } else if (response.status === 'Empty') {
        this.setState({ prevInvoiceData: true });
      }
    });
  };
  showCess = () => {
    return this.props?.orderDetails?.purchaseOrderDetails?.some(
      item => item?.cess > 0 && item?.cess !== null
    );
  };
  showOtherCharges = () => {
    return this.props?.orderDetails?.purchaseOrderDetails?.some(
      item => item?.otherCharges > 0 && item?.otherCharges !== null
    );
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            filename={`receipt__${this.state.orderDetails?.purchaseOrderNo}.pdf`}
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',
                    fontSize: '14px',

                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon
                    sx={{ color: 'white', fontSize: '16px', mr: '5px' }}
                  />{' '}
                  Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
        </Typography>
        <br />
        <div className='divToDownload ' ref={ref}>
          {this.state.orderDetails.deleted ? (
            <img
              src={CancelledImG}
              style={{
                position: 'absolute',
                left: '100px',
                top: '280px',
                zIndex: 1,
                opacity: '0.9',
              }}
            />
          ) : null}
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100% ' }}
          >
            {Validation.isBlankNot(this.state.logoUrl) ? (
              <img
                className='invoice-bg'
                style={{ width: '100%', height: '100%' }}
                src={this.state.logoUrl}
                alt='Logo'
              ></img>
            ) : (
              `Logo`
            )}

            <div className='invoice-content row d-flex justify-content-center'>
              <h5 style={{ textAlign: 'center', color: '#3B78C9' }}>
                Purchase Order Invoice
              </h5>
              <div className='col-12'>
                <div className='card'>
                  <header
                    style={{
                      backgroundImage: `url(${this.state.selectedTemplate?.bgImageHeader})`,
                      // backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  >
                    <div className='d-flex flex-column ml-auto p-2'>
                      <div className='align-self-end'>
                        <strong>Email : </strong>
                        {this.state.storeDetails.email
                          ? this.state.storeDetails.email
                          : ''}
                        <br />
                        <strong> Contact No : </strong>{' '}
                        {this.state.storeDetails.phoneNumber}
                      </div>
                    </div>
                  </header>
                  <div class='container'>
                    <div class='row'>
                      <div class='col-3 p-1'>
                        {Validation.isBlankNot(this.state.logoUrl) ? (
                          <img
                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                            src={this.state.logoUrl}
                            alt='Logo'
                          ></img>
                        ) : (
                          `Logo`
                        )}
                      </div>
                      <div class='col-9'>
                        <Typography
                          variant='h3'
                          style={{
                            color: this.state.selectedTemplate?.bgColor,
                          }}
                          fontSize='40px'
                          noWrap
                        >
                          <strong>{`${this.state.storeDetails.name}`}</strong>
                        </Typography>

                        {this.state.storeDetails.description ? (
                          <span className='text_font'>
                            <HTMLRenderer
                              html={this.state.storeDetails.description}
                            />
                          </span>
                        ) : null}
                        <div className='text_font'>
                          <strong> {this.state.storeDetails.address}</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='d-flex flex-row p-2'>
                      <div className='d-flex flex-column'>
                        {Validation.isBlankNot(this.state.logoUrl) ? (
                          <img
                            style={{ maxWidth: '100px', maxHeight: '50px' }}
                            src={this.state.logoUrl}
                            alt='Logo'
                          ></img>
                        ) : (
                          `Logo`
                        )}
                      </div>
                    </div>
                  </header>

                  <hr />
                  <div className='table-responsive p-1'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        <tr className='invoice_Hed'>
                          <th></th>
                          <th
                            style={{
                              color: this.state.selectedTemplate?.bgColor,
                            }}
                          >
                            Vendor{' '}
                          </th>
                        </tr>
                        <tr className='content'>
                          <td className='font-weight-bold'>
                            {`${this.state?.storeDetails?.name}`}
                            <br />
                            {`${this.state.storeDetails.address}`}
                          </td>
                          <td className='font-weight-bold'>
                            {`${
                              Vendor.getVendorByVendorId(
                                this.state?.orderDetails?.vendor?.id,
                                () => {}
                              )?.name
                            }`}
                            <br />
                            {`${
                              Vendor.getVendorByVendorId(
                                this.state?.orderDetails?.vendor?.id,
                                () => {}
                              )?.address
                            }`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}

                  <hr />
                  <div class='container'>
                    <div class='row'>
                      <div class='col p-1'>
                        <h5 style={{ fontSize: '15px' }}>
                          Order No :
                          <span style={{ fontSize: '12px' }}>
                            {' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.orderDetails,
                              'purchaseOrderNo'
                            )
                              ? this?.state?.orderDetails?.purchaseOrderNo
                              : 'N/A'}{' '}
                          </span>
                        </h5>
                        <h5 style={{ fontSize: '15px' }}>
                          Invoice Date :&nbsp;&nbsp;
                          <span style={{ fontSize: '12px' }}>
                            {this.props.invoice
                              ? this.props.invoice &&
                                moment
                                  .utc(this.props.invoice)
                                  .local()
                                  .format('DD-MM-yyyy')
                              : this.state?.orderDetails?.billDate &&
                                moment
                                  .utc(this.state?.orderDetails?.billDate)
                                  .local()
                                  .format('DD-MM-yyyy')}
                          </span>
                        </h5>
                      </div>
                      <div class='col-8 p-1'>
                        <div className='d-flex justify-content-around'>
                          <div>
                            <div
                              style={{
                                color: this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <b> Vendor </b>
                            </div>
                            {`${this.state?.orderDetails.vendorName}`}
                            <br />
                            {`${this.state?.orderDetails?.address}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='products p-2'>
                    <table className='table '>
                      <tbody>
                        <tr
                          className='add'
                          style={{
                            backgroundColor:
                              this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          <th>Item Name</th>
                          <th>Unit</th>

                          <th>Qty</th>
                          <th>Base Price</th>
                          <th> Base Price Discount</th>
                          {Store?.companyDetails?.businessType === 'OPTICAL' ||
                          !this.showOtherCharges() ? null : (
                            <th>O.Charges</th>
                          )}
                          {Store?.companyDetails?.businessType === 'MANDI' ||
                          Store?.companyDetails?.businessType === 'OPTICAL' ||
                          Store.getSelectedTaxType() === null ? null : (
                            <th>T.Value</th>
                          )}
                          {Store?.companyDetails?.businessType === 'MANDI' ||
                          (Store.getSelectedTaxType() === 'N/A' &&
                            this.state.orderDetails.tax === 0) ||
                          Store.getSelectedTaxType() === null ? null : (
                            <>
                              {gstCheckDetails(this.state.orderDetails) ? (
                                <>
                                  <th>SGST</th>
                                  <th>CGST</th>
                                </>
                              ) : Store.getSelectedTaxType() === 'VAT' ? (
                                <th>VAT</th>
                              ) : (
                                <th>IGST</th>
                              )}
                            </>
                          )}
                          {Store?.companyDetails?.businessType === 'MANDI' ||
                          Store?.companyDetails?.businessType === 'OPTICAL' ||
                          !this.showCess() ? null : (
                            <th>Cess</th>
                          )}
                          <th className='text-center'>Amount</th>
                        </tr>
                        {this.state.orderDetails !== undefined &&
                        this.state.orderDetails !== null
                          ? this.state?.orderDetails?.purchaseOrderDetails?.map(
                              item => {
                                return (
                                  <tr className='content ' key={item.id}>
                                    <td
                                      style={{
                                        fontSize:
                                          item.itemName.length > 30 && '10px',
                                        width:
                                          item.itemName.length > 30
                                            ? ' 100px'
                                            : 'auto',
                                      }}
                                    >
                                      {Store?.companyDetails?.businessType !==
                                      'STORE' ? (
                                        <>
                                          {item.itemName}
                                          <br />
                                          <span
                                            style={{
                                              fontSize: '9px',
                                            }}
                                            className='description'
                                          >
                                            {item.itemDescription !== null &&
                                            item.itemDescription !== undefined
                                              ? item.itemDescription &&
                                                item.itemDescription
                                              : null}
                                          </span>
                                        </>
                                      ) : (
                                        item.itemName
                                      )}
                                    </td>
                                    <td>{item.quantity + item.unit}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      {item.noOfItems}
                                    </td>
                                    <td>
                                      {item.basePrice}
                                      {this.state.currency !== undefined &&
                                      this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </td>

                                    <td style={{ textAlign: 'center' }}>
                                      {item.basePriceDiscount !== null &&
                                      item.basePriceDiscount !== '0'
                                        ? item.basePriceDiscount
                                        : '-'}
                                    </td>
                                    {Store.getSelectedTaxType() === null ||
                                    Store?.companyDetails?.businessType ===
                                      'OPTICAL' ||
                                    !this.showOtherCharges() ? null : (
                                      <td>
                                        {item.otherCharges !== null &&
                                        item.otherCharges !== '0'
                                          ? item.otherCharges
                                          : '-'}
                                      </td>
                                    )}
                                    {Store?.companyDetails?.businessType ===
                                      'MANDI' ||
                                    Store?.companyDetails?.businessType ===
                                      'OPTICAL' ||
                                    Store.getSelectedTaxType() ===
                                      null ? null : (
                                      <td>
                                        {item.taxableAmt !== null &&
                                        item.taxableAmt !== '0'
                                          ? item.taxableAmt
                                          : '-'}
                                      </td>
                                    )}

                                    {Store?.companyDetails?.businessType ===
                                      'MANDI' ||
                                    (Store.getSelectedTaxType() === 'N/A' &&
                                      this.state.orderDetails.tax === 0) ||
                                    Store.getSelectedTaxType() ===
                                      null ? null : (
                                      <>
                                        {gstCheckDetails(
                                          this.state.orderDetails
                                        ) ? (
                                          <>
                                            <td className='text-center'>
                                              {item?.taxAmount !== null &&
                                              item?.taxAmount !== '0'
                                                ? item?.taxAmount?.toFixed(2) /
                                                  2
                                                : '-'}
                                            </td>

                                            <td className='text-center'>
                                              {item?.taxAmount !== null &&
                                              item?.taxAmount !== '0'
                                                ? item?.taxAmount?.toFixed(2) /
                                                  2
                                                : '-'}
                                            </td>
                                          </>
                                        ) : (
                                          <td className='text-center'>
                                            {item?.taxAmount !== null &&
                                            item?.taxAmount !== '0'
                                              ? item?.taxAmount?.toFixed(2)
                                              : '-'}
                                          </td>
                                        )}
                                      </>
                                    )}
                                    {Store?.companyDetails?.businessType ===
                                      'MANDI' ||
                                    Store?.companyDetails?.businessType ===
                                      'OPTICAL' ||
                                    !this.showCess() ? null : (
                                      <td>
                                        {item.cess !== null && item.cess !== '0'
                                          ? item.cess
                                          : '-'}
                                      </td>
                                    )}

                                    <td className='text-center'>
                                      {item.amount.toFixed(2)}
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table className='table table-borderless'>
                      <tbody style={{ float: 'right' }}>
                        <tr>
                          <td>
                            {' '}
                            <strong
                              style={{
                                color: this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              Order Total Amount :
                            </strong>
                          </td>
                          <td>
                            {this.state.orderDetails?.totalAmount &&
                              this.state.orderDetails?.totalAmount.toFixed(2)}
                            &nbsp;
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </td>
                        </tr>

                        {this.state.orderDetails.totalDiscount !== 0 ? (
                          <>
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Discount :
                                </strong>
                              </td>

                              <td>
                                {this.state.orderDetails.totalDiscount &&
                                  this.state.orderDetails.totalDiscount}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </>
                        ) : null}
                        {this.state.orderDetails?.tax !== 0 &&
                        this.state.orderDetails?.tax !== null ? (
                          <>
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Tax :
                                </strong>
                              </td>

                              <td>
                                {this.state.orderDetails?.tax &&
                                  this.state.orderDetails?.tax.toFixed(2)}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  <hr />

                  {this.state.generalPayment?.length > 0 ? (
                    <div className='products p-2'>
                      {this.state.generalPayment?.map(item => {
                        if (!item.deleted)
                          return (
                            <>
                              <h5
                                style={{
                                  fontSize: '15px',
                                  textAlign: 'center',
                                  color: this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                Payments
                              </h5>
                              <table className='table '>
                                <tbody>
                                  <tr
                                    className='add'
                                    style={{
                                      color:
                                        this.state.selectedTemplate?.bgColor,
                                    }}
                                  >
                                    <th>Payment No.</th>
                                    <th>Payment Date</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                  </tr>

                                  <tr className='content ' key={item.id}>
                                    <td>{item.paymentNumber}</td>
                                    <td>
                                      {new Date(item.paymentDate + 'Z')
                                        .toString()
                                        .substring(0, 15)}
                                    </td>

                                    <td>
                                      {item.paymentType === 'Cheque' ? (
                                        <>
                                          {item.paymentType}
                                          <br />
                                          <span style={{ fontSize: '10px' }}>
                                            {item.checkDate !== null &&
                                            item.checkDate !== undefined
                                              ? new Date(item.checkDate + 'Z')
                                                  .toString()
                                                  .substring(0, 24) &&
                                                new Date(item.checkDate + 'Z')
                                                  .toString()
                                                  .substring(0, 24)
                                              : null}
                                          </span>
                                        </>
                                      ) : (
                                        item.paymentType
                                      )}
                                    </td>
                                    <td className='text-center'>
                                      {item.amount.toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          );
                      })}
                    </div>
                  ) : null}

                  <hr />
                  {this.state.paymentAdjustment?.length > 0 ? (
                    <div className='products p-2'>
                      <h5
                        style={{
                          fontSize: '15px',
                          textAlign: 'center',

                          color: this.state.selectedTemplate?.bgColor,
                        }}
                      >
                        Adjustment Payments
                      </h5>
                      <br />

                      <table className='table '>
                        <tbody>
                          <tr
                            className='add'
                            style={{
                              color: this.state.selectedTemplate?.bgColor,
                            }}
                          >
                            <th>Payment No.</th>
                            <th>Payment Date</th>
                            <th>Type</th>
                            <th>Amount</th>
                          </tr>
                          {this.state.paymentAdjustment?.map(item => {
                            return (
                              <tr className='content ' key={item.id}>
                                <td>{item.paymentNumber}</td>
                                <td>
                                  {' '}
                                  {new Date(item.paymentDate + 'Z')
                                    .toString()
                                    .substring(0, 15)}
                                </td>

                                <td>
                                  {item.paymentType === 'Adjustment' ? (
                                    <>
                                      {item.paymentType}
                                      <br />
                                      <span style={{ fontSize: '10px' }}>
                                        {item.remark !== null &&
                                        item.remark !== undefined
                                          ? item.remark
                                          : null}
                                      </span>
                                    </>
                                  ) : (
                                    item.paymentType
                                  )}
                                </td>
                                <td className='text-center'>
                                  {item.amount.toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div>
                        <table className='table table-borderless'>
                          {' '}
                          <tbody style={{ float: 'right' }}>
                            <tr>
                              <td>
                                {' '}
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Adjustment Amount :
                                </strong>
                              </td>
                              <td className='text-right'>
                                {amount}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                  <div className='products p-2'>
                    {!this.state?.returnOrder?.deleted &&
                    this.state?.returnOrder?.orderDetails?.length > 0 ? (
                      <>
                        <h5
                          style={{
                            fontSize: '15px',

                            color: this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          Return Order No :
                          <span style={{ fontSize: '12px' }}>
                            {' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.returnOrder,
                              'returnOrderNumber'
                            )
                              ? this.state.returnOrder.returnOrderNumber
                              : 'N/A'}{' '}
                          </span>
                        </h5>
                        <h5
                          style={{
                            fontSize: '15px',

                            color: this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          Return Order Date :
                          <span style={{ fontSize: '12px' }}>
                            {' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.returnOrder,
                              'creationDate'
                            )
                              ? moment
                                  .utc(this.state.returnOrder.creationDate)
                                  .local()
                                  .format('DD-MM-yyyy')
                              : 'N/A'}{' '}
                          </span>
                        </h5>

                        <table className='table '>
                          <tbody>
                            <tr
                              className='add'
                              style={{
                                backgroundColor:
                                  this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <th>Item Name</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Unit</th>
                              <th>Base Price Discount</th>
                              <th>Tax</th>
                              <th>Amount</th>
                            </tr>
                            {this.state.returnOrder?.orderDetails?.map(item => {
                              return (
                                <tr className='content ' key={item.id}>
                                  <td
                                    style={{
                                      fontSize:
                                        item.itemName.length > 30 && '10px',
                                      maxWidth:
                                        item.itemName.length > 30
                                          ? ' 100px'
                                          : 'auto',
                                    }}
                                  >
                                    {item.itemName}
                                  </td>
                                  <td>{item.basePrice}</td>
                                  <td>{item.noOfItems}</td>
                                  <td>{item.quantity + item.unit}</td>
                                  <td>
                                    {' '}
                                    {item.basePriceDiscount !== null &&
                                    item.basePriceDiscount !== '0'
                                      ? item?.basePriceDiscount
                                      : '-'}
                                  </td>
                                  <td className='text-center'>
                                    {item?.taxAmount !== null &&
                                    item?.taxAmount !== '0'
                                      ? item.taxAmount?.toFixed(2)
                                      : '-'}
                                  </td>
                                  <td className='text-center'>{item.amount}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <table className='table table-borderless'>
                          <tbody style={{ float: 'right' }}>
                            <tr>
                              <td>
                                {' '}
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                  }}
                                >
                                  Total Return Amount :
                                </strong>
                              </td>
                              <td>
                                {Validation.isBlankObjectKeyNot(
                                  this.state.returnOrder,
                                  'totalAmount'
                                )
                                  ? this.state.returnOrder.totalAmount
                                  : 'N/A'}{' '}
                                &nbsp;
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : null}
                  </div>

                  <div
                    className='bank_details_section'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <table className='table table-borderless'>
                        <tbody>
                          {this.state.defaultConfigBankDetails?.value?.toLowerCase() !==
                          'true' ? (
                            <>
                              <tr
                                style={{
                                  backgroundColor:
                                    this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                <th>Bank Details</th>
                              </tr>
                              <tr>
                                <td>
                                  Bank Name :{' '}
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.storeAccount,
                                    'bankName'
                                  )
                                    ? this.state.storeAccount.bankName
                                    : 'N/A'}
                                  <br /> IFSC-Code :{' '}
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.storeAccount,
                                    'ifscNumber'
                                  )
                                    ? this.state.storeAccount.ifscNumber
                                    : 'N/A'}
                                  <br /> Branch Address :{' '}
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.storeAccount,
                                    'branchDetails'
                                  )
                                    ? this.state.storeAccount.branchDetails
                                    : 'N/A'}
                                  <br />
                                  Account Number :{' '}
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.storeAccount,
                                    'accountNo'
                                  )
                                    ? this.state.storeAccount.accountNo
                                    : 'N/A'}
                                  <br />{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr
                                style={{
                                  backgroundColor:
                                    this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                <th>Contact Details</th>
                              </tr>
                              <tr>
                                <td>
                                  Address :{' '}
                                  {Validation.isBlankObjectNot(
                                    Store.companyDetails?.address,
                                    'address'
                                  )
                                    ? Store.companyDetails?.address
                                    : 'N/A'}
                                  <br /> Phone Number:{' '}
                                  {Validation.isBlankObjectNot(
                                    Store.companyDetails?.phoneNumber,
                                    'phoneNumber'
                                  )
                                    ? Store.companyDetails?.phoneNumber
                                    : 'N/A'}
                                  <br /> Email :{' '}
                                  {Validation.isBlankObjectNot(
                                    Store.companyDetails?.email,
                                    'email'
                                  )
                                    ? Store.companyDetails?.email
                                    : 'N/A'}
                                  <br />
                                  Website :{' '}
                                  {Validation.isBlankObjectNot(
                                    Store.companyDetails?.website,
                                    'website'
                                  )
                                    ? Store.companyDetails?.website
                                    : 'N/A'}
                                  <br />{' '}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {!this.state.prevInvoiceData && this.state.invoiceData ? (
                      <div style={{ justifyContent: 'flex-end' }}>
                        {/* {Customer.getCustomerById(
                          this.state.orderDetails.customerId,
                          () => {}
                        ).customerName?.toUpperCase() !== 'ANONYMOUS' ? ( */}
                        <table className='table table-borderless'>
                          <tbody style={{ float: 'right' }}>
                            <tr
                              style={{
                                backgroundColor:
                                  this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <th>Invoice Summary</th>
                            </tr>
                            <tr>
                              {this.state.defaultConfigBalance?.value?.toLowerCase() !==
                                'true' && (
                                <tr>
                                  <td>
                                    Prev Balance : <hr />
                                  </td>

                                  <td style={{ float: 'right' }}>
                                    {this.state?.bal?.toFixed(2)}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                    &nbsp;
                                    <hr />
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td> Order Amount :</td>
                                <td style={{ float: 'right' }}>
                                  {`+ `}
                                  {this.state.orderDetails?.totalAmount &&
                                    this.state.orderDetails?.totalAmount?.toFixed(
                                      2
                                    )}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                  &nbsp;
                                </td>
                              </tr>
                              {this.state.generalPayment?.length > 0 ? (
                                <tr>
                                  <td> Payments :</td>
                                  <td style={{ float: 'right' }}>
                                    {'- '}
                                    {this.state.invoiceData?.payments?.toFixed(
                                      2
                                    )}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </td>
                                </tr>
                              ) : null}

                              {!this?.state?.returnOrder?.deleted &&
                              this?.state?.returnOrder?.orderDetails?.length >
                                0 ? (
                                <tr>
                                  <td>Return Amount : </td>
                                  <td style={{ float: 'right' }}>
                                    {Validation.isBlankObjectKeyNot(
                                      this.state.returnOrder,
                                      'totalAmount'
                                    )
                                      ? `- ` +
                                        this.state.returnOrder.totalAmount.toFixed(
                                          2
                                        )
                                      : 'N/A'}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </td>
                                </tr>
                              ) : null}

                              {this.state.paymentAdjustment?.length > 0 ? (
                                <tr>
                                  <td>Adjustment : </td>
                                  <td style={{ float: 'right' }}>
                                    {/[-]+/.test(this.state.invoiceData?.adjust)
                                      ? `+ ` +
                                        this.state.invoiceData?.adjust
                                          .toFixed(2)
                                          .toString()
                                          .substring(1)
                                      : `-` +
                                        this.state.invoiceData?.adjust?.toFixed(
                                          2
                                        )}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </td>
                                </tr>
                              ) : null}

                              <tr>
                                <td>
                                  <hr />
                                  <strong
                                    style={{
                                      color:
                                        this.state.selectedTemplate?.bgColor,
                                    }}
                                  >
                                    {' '}
                                    Total Balance :
                                  </strong>
                                </td>

                                <td style={{ float: 'right' }}>
                                  {' '}
                                  <hr />
                                  {/* {this.state.invoiceData?.balance?.toFixed(2)} */}
                                  {this.state.defaultConfigBalance?.value?.toLowerCase() !==
                                  'true'
                                    ? this.state.invoiceData?.balance?.toFixed(
                                        2
                                      )
                                    : this.state.invoiceData?.balance -
                                      this.state?.bal}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                  &nbsp;
                                </td>
                              </tr>
                            </tr>
                          </tbody>
                        </table>
                        {/* ) : null} */}
                      </div>
                    ) : (
                      <div>
                        {/* {Customer.getCustomerById(
                          this.state.orderDetails.customerId,
                          () => {}
                        ).customerName?.toUpperCase() !== 'ANONYMOUS' ? ( */}
                        <div>
                          {' '}
                          <table className='table table-borderless'>
                            <tbody style={{ float: 'right' }}>
                              <tr
                                style={{
                                  backgroundColor:
                                    this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                <th>Invoice Summary</th>
                              </tr>
                              <tr>
                                <tr>
                                  {' '}
                                  <td>Prev Balance : </td>
                                  <td className='text-right'>
                                    {this.state.orderDetails?.lastBalance &&
                                      this.state.orderDetails?.lastBalance.toFixed(
                                        2
                                      )}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </td>
                                </tr>

                                <tr>
                                  {' '}
                                  <td>Last Payment : </td>
                                  <td className='text-right'>
                                    {this.state.orderDetails?.lastPayment &&
                                      this.state.orderDetails?.lastPayment.toFixed(
                                        2
                                      )}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                </tr>

                                <tr>
                                  {' '}
                                  <td>Total Balance : </td>
                                  <td className='text-right'>
                                    {this.state.totalOldBalance.toFixed(2)}
                                    &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* ) : null} */}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      // margin: '5px',
                      paddingBottom: '1rem',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <h6
                      style={{
                        marginRight: '18px',
                        color: this.state.selectedTemplate?.bgColor,
                      }}
                    >
                      Signature
                    </h6>

                    {Validation.isBlankNot(this.state.signatureUrl) ? (
                      <img
                        style={{
                          maxWidth: '100px',
                          maxHeight: '80px',

                          margin: '5px',
                        }}
                        src={this.state.signatureUrl}
                        alt='Signature'
                      ></img>
                    ) : (
                      `Signature`
                    )}
                  </div>

                  {this.state?.storeDetails?.termsAndCondition !== '' ? (
                    <>
                      <div
                        className='footer_class'
                        style={{
                          backgroundImage: `url(${this.state.selectedTemplate?.bgImageFooter})`,
                          backgroundSize: 'cover',
                          border: '2px solid gray',
                          padding: '5px',
                        }}
                      >
                        <div>
                          <strong> {`Terms & Conditions:`}</strong>
                        </div>
                        <div
                          sx={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <h1 style={{ fontSize: '14px', display: 'flex' }}>
                            {this.state.storeDetails.termsAndCondition ? (
                              <HTMLRenderer
                                html={this.state.storeDetails.termsAndCondition}
                              />
                            ) : (
                              'Terms And Condition'
                            )}
                          </h1>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.invoiceData ? (
                    <span style={{ fontSize: '10px', textAlign: 'center' }}>
                      {`Invoice Date : `}
                      {moment
                        .utc(this.state.invoiceData.revisionDate)
                        .local()
                        .format('DD-MM-yyyy HH:mm:ss')}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PurchaseOrderInvoice;
