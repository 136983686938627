import UpstoreApi from '../UpstoreApi';
class ReturnOrders {
  saveReturnOrder = (returnOrder, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/return-order/addNewOrder',
      data: returnOrder,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log('Error occure while saving order', error.message);
        callBack({ status: 'error' });
        return error;
      });
  };

  getReturnOrdersByStore = (
    storeId,
    pageNum,
    deleted,
    orderFromEstore,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/return-order/getAllReturnOrder/${storeId}?pageNumber=${pageNum}&deleted=${deleted}&returnOrderFromEstore=${orderFromEstore}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log('Error occure while getting return orders', error.message);
        callBack({ status: 'error' });
        return error;
      });
  };

  getReturnOrderByOrder = (orderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/return-order/getAllByOrderId/' + orderId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
        return response.data;
      })
      .catch(error => {
        console.log('Error occure while getting return order', error.message);
        callBack({ status: 'error' });
        return error;
      });
  };
  //delete Return order
  deleteReturnOrder = (orderId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/return-order/deleteReturnOrder/' + orderId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting return order', error.message);
        callBack({ status: 'error' });
      });
  };
  updateReturnOrder = (updatedObj, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `return-order/update/${id}`,
      data: updatedObj,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log('Error occure while updating Return  order', error.message);
        callBack({ status: 'error' });
        return error;
      });
  };
}
export default new ReturnOrders();
