import UpstoreApi from '../UpstoreApi';
class UserRoles {
  getUserRoleId = (userRoleId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/userRoles/' + userRoleId,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
        return response.data;
      })
      .catch(err => {
        console.log('Error occurred while getting  role Id', err.message);

        callback({ status: 'error' });
        return err;
      });
  };
  //getting all user roles
  getAllUserRoles = callback => {
    UpstoreApi({
      method: 'GET',
      url: '/userRoles/all',
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting   all user roles',
          err.message
        );
        callback({ status: 'error' });
      });
  };
}
export default new UserRoles();
