import React from 'react';
import {
  Box,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Tooltip,
  TextField,
  Button,
  Divider,
  ListItemText,
  ListItemButton,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WarningDialog from '../../component/warningDialog/WarningDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductItemDetails from '../../component/productItemDetails/ProductItemDetails';
import Categories from '../../modules/Categories';
import Auth from '../../modules/Auth';
import Product from '../../modules/Product';
import AddNewProductItem from '../../component/addNewProductItem/AddNewProductItem';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Header from '../../component/header/Header';
import { Loading } from '../../component/loading/Loading';
import Validation from '../../modules/Validation';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { ArrowLeft, Visibility } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import Skelton from '../../component/skeltonLoading/Skelton';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import PopularSearch from '../../modules/PopularSearch';
import MessageReviewSection from '../../component/messageReviewSection/MessageReviewSection';
import { serverConfig } from '../../config/server-config';
import ProductCarouselImg from '../../component/productCarouselImg/ProductCarouselImg';
import Store from '../../modules/Store';
import TaxCalculation from '../../modules/TaxCalculation';
import UpstoreProperties from '../../modules/UpstoreProperties';
import AllPermissions from '../../modules/AllPermissions';
import { debounce } from '../../utils';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProductDetails() {
  let navigate = useNavigate();
  const listRef = React.useRef();
  const location = useLocation();
  const textField = {
    width: '100%',
    bgcolor: 'secondary.light',
    borderTop: '5px solid #E1EEF5',
  };
  const page_size = 20;
  const [selectedProductName, setSelectedProductName] = React.useState('');
  const [productDetailsData, setProductDetailsData] = React.useState(null);
  const [categoryDataList, setCategoryDataList] = React.useState([]);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [hideGrid, setHideGrid] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState('');
  const [productsList, setProductsList] = React.useState([]);
  const [loaderSkelton, setLoaderSkelton] = React.useState(false);
  const [defaultUnitsData, setDefaultUnitsData] = React.useState([]);
  const [dialogWarning, setDialogWarning] = React.useState(false);
  const [openAddProductItemDialog, setOpenAddProductItemDialog] =
    React.useState(false);
  const [showCommentsSection, setShowCommentsSection] = React.useState({
    open: false,
  });
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [buttonSubmitted, setButtonSubmitted] = React.useState(false);
  const [taxData, setTaxData] = React.useState({});
  const [propertyValue, setPropertyValue] = React.useState('');
  const [pageNo, setPageNo] = React.useState(0);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  // const systemProperty = () => {
  //   setPropertyValue(UpstoreProperties.getProperty('ESTORE_ENABLED'));
  // };

  React.useEffect(() => {
    Product.getAllUnits(response => {
      if (response.status === 'success') {
        setDefaultUnitsData(response.data);
      } else {
        setDefaultUnitsData([]);
      }
    });
    getProductByProductId(location.state, false);
    getCategoriesByStoreId();
    // systemProperty();
  }, []);

  const getTaxData = (type, productId, categoryId) => {
    if (type === 'gst') {
      TaxCalculation.getGstByProductIdAndCategoryId(
        productId,
        categoryId,
        response => {
          if (response.status === 'success') {
            setTaxData(response?.data);
          } else if (response.status === 'noData') {
            setTaxData({});
          } else {
            setTaxData({});
          }
        }
      );
    } else if (type === 'vat') {
      TaxCalculation.getVatByProductIdAndCategoryId(
        productId,
        categoryId,
        response => {
          if (response.status === 'success') {
            setTaxData(response?.data);
          } else if (response.status === 'noData') {
            setTaxData({});
          } else {
            setTaxData({});
          }
        }
      );
    }
  };
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      handleDeleteProducts();
      setDialogWarning(false);
    } else {
      setDialogWarning(false);
    }
  };
  const taxFunction = (productId, categoryId) => {
    // for tax related
    if (!Store.getSelectedTaxType()) return;
    else if (Store.getSelectedTaxType() === 'GST')
      getTaxData('gst', productId, categoryId);
    else getTaxData('vat', productId, categoryId);
  };

  // getting product by product
  const getProductByProductId = (productId, cond) => {
    Product.getProductByProductId(productId, response => {
      if (response.status === 'success') {
        // let filteredData = response.data.filter(item => {
        //   !item.productItem.deleted;
        // });
        setCategoryId(response.data.categoryId);
        taxFunction(productId, response.data?.categoryId);
        setProductDetailsData(response.data);
        setSelectedProductName(response.data?.name);
        if (!cond) {
          getAllProductsList(response.data.categoryId, true, pageNo);
        }
      } else {
        setProductDetailsData('');
      }
    });
  };

  const getCategoriesByStoreId = () => {
    Categories.getCategoryByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setCategoryDataList(response.data);
      } else {
        setCategoryDataList([]);
      }
    });
  };

  const handleOnChange = event => {
    let { name, value } = event.target;

    setProductDetailsData({ ...productDetailsData, [name]: value });
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleTaxChange = event => {
    let { name, value } = event.target;
    if (name === 'vat') {
      setTaxData({ ...taxData, [name]: value });
    } else if (name === 'gst') {
      // let total = null;
      // if (!taxData.sgst) {
      // } else {
      //   total = +value + +taxData.sgst;
      // }
      // setTaxData({ ...taxData, [name]: value });
      setTaxData({ ...taxData, [name]: value });
    }
    // } else {
    //   let total = null;
    //   if (!taxData.cgst) {
    //   } else {
    //     total = +value + +taxData.cgst;
    //   }
    //   setTaxData({ ...taxData, [name]: value, gst: total });
    // }
  };

  const addPopularSearchItems = () => {
    setButtonSubmitted(true);
    let data = {
      productId: productDetailsData.productId,
      storeId: Auth.getStoreId(),
      productName: productDetailsData.name,
      productDescription: productDetailsData.description,
    };
    PopularSearch.addPopularSearch(data, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Popular Search Added Sucesfully', 'success');
      } else {
        openUpstoreSnackBar(' error while Adding popular searches', 'error');
      }
    });
  };

  const handleDeleteProducts = () => {
    let productId = productDetailsData?.productId;
    Product.deleteProduct(productId, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Product deleted suuccessfully', 'success');
        getAllProductsList(categoryId, false, 0);
      } else {
        openUpstoreSnackBar('Product not deleted suuccessfully', 'error');
      }
    });
  };

  const handleUpdateProducts = () => {
    setButtonSubmitted(true);
    let productId = productDetailsData?.productId;
    let data = productDetailsData;
    data.commission = parseInt(productDetailsData.commission);
    data.lastModifiedBy = Auth.getUserLoginId();
    data.description = data.description === '' ? data.name : data.description;

    Product.updateProduct(productId, data, response => {
      if (response.status === 'success') {
        let data = {
          ...taxData,
          id: taxData.id,
          taxableItemId: response?.data?.productId,
          storeId: Auth.getStoreId(),
        };

        if (!Store.getSelectedTaxType()) {
          openUpstoreSnackBar(
            'Product details have been updated successfully',
            'success'
          );
          getCategoriesByStoreId();
        } else if (Store.getSelectedTaxType() === 'GST') {
          TaxCalculation.AddGstTaxData(data, response => {
            if (response.status === 'success') {
              openUpstoreSnackBar(
                'Product details have been updated successfully',
                'success'
              );
              getCategoriesByStoreId();
            } else {
              openUpstoreSnackBar(`error while adding tax`, 'error');
            }
          });
        } else {
          TaxCalculation.AddVatTaxData(data, response => {
            if (response.status === 'success') {
              openUpstoreSnackBar(
                'Product details have been updated successfully',
                'success'
              );
              getCategoriesByStoreId();
            } else {
              openUpstoreSnackBar(`error while adding tax`, 'error');
            }
          });
        }
      } else {
        openUpstoreSnackBar(
          'Product details not been updated suuccessfully',
          'error'
        );
      }
    });
  };
  setTimeout(function () {
    setButtonSubmitted(false);
  }, 7000);
  //run parameter only executes when we change category
  const getAllProductsList = (categoryId, run, pageNum) => {
    setCategoryId(categoryId);
    if (run) {
      Product.getProductByCategoryId(categoryId, pageNum, 20, response => {
        if (response.status === 'success') {
          let filterDeleted = response?.data?.filter(item => !item.deleted);
          setProductsList(filterDeleted);
          if (response.data.length < page_size) {
            setHasMoreData(false);
          }
          setProductDetailsData(filterDeleted[0]);
          setSelectedProductName(filterDeleted[0]?.name);
          taxFunction(filterDeleted[0].productId, categoryId);
          setSkeletonLoading(false);
        } else {
          setProductsList([]);
        }
      });
    } else {
      Product.getProductByCategoryId(categoryId, pageNum, 20, response => {
        if (response.status === 'success') {
          let filterDeleted = response?.data?.filter(item => !item.deleted);
          let filteredData = [...productsList, ...filterDeleted].filter(
            (item, index) =>
              [...productsList, ...filterDeleted].indexOf(item) === index
          );
          setProductsList(filteredData);
          if (response.data.length < page_size) {
            setHasMoreData(false);
          }
          setSkeletonLoading(false);
          if (response.data.length > 0) {
            setProductDetailsData(productsList[0]);
            setSelectedProductName(productsList[0]?.name);
            taxFunction(productsList[0].productId, categoryId);
          } else {
            setProductDetailsData({});
            taxFunction(productsList[0]?.productId, categoryId);
          }
        } else {
          setProductsList([]);
        }
      });
    }
  };
  let subscriptionPlan = JSON.parse(
    localStorage.getItem('storeSubscriptionPlans')
  );
  const subscriptionEnabled =
    subscriptionPlan?.userSubscribeServiceModelList?.find(
      item => item?.serviceName === 'eCommerce'
    );

  const handlcategoryDropdownOnChange = event => {
    setPageNo(0);
    setCategoryId(event.target.value);
    setProductsList([]);
    setHasMoreData(true);
    getAllProductsList(event.target.value, true, 0);
  };

  const productSearchChange = value => {
    setLoaderSkelton(true);
    if (value !== '') {
      Product.searchProductsByName(value, Auth.getStoreId(), response => {
        if (response.status === 'success') {
          setProductsList(response.data);
          setLoaderSkelton(false);
        } else {
          openUpstoreSnackBar(
            'error occured while searching products',
            'error'
          );
        }
      });
    } else {
      getAllProductsList(categoryId, false, pageNo);
    }
  };

  //for preventing frequent api calls on customer search
  const debounce = (func, timeout = 350) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const getProductName = debounce(e => productSearchChange(e));

  const closeAddDialog = () => {
    setOpenAddProductItemDialog(false);
  };
  const hideCustomerGrid = () => {
    setHideGrid(!hideGrid);
  };

  const handleScroll = () => {
    const list = listRef.current;
    if (
      Math.abs(list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1) &&
      hasMoreData
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;
      setPageNo(pageNum);
      getAllProductsList(categoryId, false, pageNum);
    }
  };

  return (
    <>
      <Header pageName='Product Details' />

      <Box
        sx={{
          // backgroundColor: 'grey',
          pl: { md: 8, sm: 6, xs: 0 },
          mt: 3,
          pt: 5,
        }}
      >
        <Grid item md={12} sm={12} xs={12} sx={{ pl: { md: 0, sm: 0, xs: 8 } }}>
          <BreadCrumb pageName='ProductDetails' />
        </Grid>
        <Grid container sx={{ alignItems: 'center', mb: 1 }}>
          <Grid item md={6} xs={12} sm={6}>
            <Tooltip title='Go to Products' placement='bottom'>
              <Button
                startIcon={<ArrowLeft />}
                variant='text'
                sx={{
                  ml: { xs: 6, md: 1 },
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                onClick={() => navigate('/products')}
              >
                products
              </Button>
            </Tooltip>{' '}
          </Grid>
          {subscriptionEnabled ? (
            <Grid item md={6} xs={12} sm={6}>
              <Tooltip title='Message OR Review' placement='left'>
                <MapsUgcIcon
                  fontSize='medium'
                  color='primary'
                  style={{ cursor: 'pointer', float: 'right' }}
                  onClick={() => setShowCommentsSection({ open: true })}
                />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>

        {productDetailsData !== null ? (
          <>
            <Grid container sx={{ display: 'flex' }}>
              {hideGrid === true ? (
                <Grid
                  item
                  md={1}
                  lg={1}
                  sx={{
                    display: { md: 'block', sm: 'none', xs: 'none' },
                  }}
                >
                  <ArrowRightSharpIcon
                    sx={{
                      ml: '6px',
                      color: '#3B78C9',
                      width: '35px',
                      height: '35px',
                      cursor: 'pointer',
                    }}
                    onClick={hideCustomerGrid}
                  />
                </Grid>
              ) : (
                <Grid
                  bgcolor='secondary.light'
                  item
                  sm={2}
                  md={2}
                  lg={2}
                  sx={{
                    display: { md: 'block', sm: 'none', xs: 'none' },
                    height: { md: '900px' },
                    overflowY: 'scroll',
                    overflowX: 'scroll',
                  }}
                  ref={listRef}
                  onScroll={handleScroll}
                >
                  <Box
                    alignItems='center'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{ color: '#fff', mt: 1 }}>
                      <Select
                        sx={{
                          padding: 0,
                          height: '27px',
                          width: '126px',
                          fontSize: '11px',
                        }}
                        defaultValue={categoryId && categoryId}
                        value={categoryId && categoryId}
                        onChange={event => {
                          handlcategoryDropdownOnChange(event);
                        }}
                        displayEmpty
                        name='categories'
                        placeholder='Select'
                      >
                        <MenuItem disabled value={undefined}>
                          <em style={{ color: 'gray' }}>Select</em>
                        </MenuItem>
                        {categoryDataList?.map(item => {
                          return (
                            <MenuItem
                              key={(Math.random() + 1)
                                .toString(36)
                                .substring(7)}
                              value={item.categoryId}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    bgcolor='secondary.light'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      pt: 2,
                      pl: 1,
                    }}
                  >
                    <input
                      type='text'
                      placeholder='search product'
                      style={{
                        borderTopLeftRadius: '50px',
                        borderBottomLeftRadius: '50px',
                        minWidth: '185px',
                        height: '30px',
                        paddingLeft: '3px',
                        fontSize: '10px',
                        border: 'none',
                      }}
                      onChange={event => {
                        getProductName(event.target.value);
                      }}
                    />
                    <SearchIcon
                      sx={{
                        backgroundColor: 'white',
                        color: '#3B78C9',
                        height: '30px',
                        borderTopRightRadius: '50px',
                        borderBottomRightRadius: '50px',
                        paddingRight: '2px',
                      }}
                    />

                    <ArrowRightSharpIcon
                      sx={{
                        color: ' #3B78C9',
                        width: '30px',
                        height: '35px',
                        cursor: 'pointer',
                      }}
                      onClick={hideCustomerGrid}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 0.5,
                      overflowX: { xs: 'scroll', md: 'hidden' },
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'column' },
                    }}
                  >
                    {loaderSkelton ? (
                      <Skelton />
                    ) : productsList.length > 0 ? (
                      productsList?.map((product, index) => {
                        return (
                          <ListItemButton
                            key={(Math.random() + 1).toString(36).substring(7)}
                            className='customerBtn'
                            sx={{
                              height: '40px',
                              color: '#3B78C9',
                              bgcolor:
                                selectedProductName === product.name
                                  ? '#ffffff'
                                  : '',
                            }}
                            onClick={event =>
                              getProductByProductId(product?.productId, true)
                            }
                          >
                            <ListItemText>
                              <Typography color='black' fontSize={16}>
                                {product.name}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        );
                      })
                    ) : (
                      <Typography
                        variant='outlined'
                        sx={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          color: '#3B78C9',
                          alignItems: 'center',
                          pt: 20,
                          pl: 5,
                        }}
                      >
                        No product Found
                      </Typography>
                    )}
                  </Box>
                  {skeletonLoading && (
                    <Box>
                      <Skeleton
                        width='100%'
                        height={50}
                        sx={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          color: '#3B78C9',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        Scroll down to Load more ...
                      </Skeleton>
                    </Box>
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={10} lg={10}>
                <form noValidate>
                  <Grid
                    container
                    columnSpacing={8}
                    rowSpacing={2}
                    item
                    xs={10}
                    md={11}
                    sm={10}
                    sx={{ ml: hideGrid === true ? null : 1 }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>
                            Product Name
                          </Typography>
                          <TextField
                            sx={textField}
                            size='small'
                            id='productName'
                            onChange={handleOnChange}
                            value={
                              Validation.isBlankObjectKeyNot(
                                productDetailsData,
                                'name'
                              )
                                ? productDetailsData.name
                                : ''
                            }
                            placeholder='Enter Product Name'
                            variant='outlined'
                            name='name'
                            autoComplete='off'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>
                            Category Name
                          </Typography>
                          <FormControl sx={textField}>
                            <Select
                              size='small'
                              value={
                                Validation.isBlankObjectKeyNot(
                                  productDetailsData,
                                  'categoryId'
                                )
                                  ? productDetailsData.categoryId
                                  : ''
                              }
                              onChange={handleOnChange}
                              displayEmpty
                              name='categoryId'
                              placeholder='Select'
                              MenuProps={MenuProps}
                            >
                              <MenuItem disabled value=''>
                                <em style={{ color: 'gray' }}>Select</em>
                              </MenuItem>
                              {categoryDataList?.map(item => (
                                <MenuItem
                                  key={(Math.random() + 1)
                                    .toString(36)
                                    .substring(7)}
                                  value={item.categoryId}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>
                            Default Unit
                          </Typography>

                          {/* ############################################## */}
                          <FormControl sx={textField}>
                            <Select
                              value={
                                Validation.isBlankObjectKeyNot(
                                  productDetailsData,
                                  'unit'
                                )
                                  ? productDetailsData.unit
                                  : ''
                              }
                              size='small'
                              displayEmpty
                              placeholder='Select'
                              name='unit'
                              id='unit'
                              onChange={handleOnChange}
                              MenuProps={MenuProps}
                            >
                              <MenuItem disabled value={undefined}>
                                <em style={{ color: 'gray' }}>Select</em>
                              </MenuItem>
                              {defaultUnitsData?.map(item => {
                                return (
                                  <MenuItem key={item.id} value={item.unit}>
                                    {`${item.unitName}-${item.unit}`}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>In Stock</Typography>
                          <FormControl sx={textField}>
                            <Select
                              size='small'
                              value={
                                Validation.isBlankObjectKeyNot(
                                  productDetailsData,
                                  'inStock'
                                )
                                  ? productDetailsData.inStock
                                  : ''
                              }
                              onChange={handleOnChange}
                              displayEmpty
                              name='inStock'
                              placeholder='Select'
                            >
                              <MenuItem disabled value=''>
                                <em style={{ color: 'gray' }}>Select</em>
                              </MenuItem>
                              <MenuItem value='true'>True</MenuItem>
                              <MenuItem value='false'>False</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>Available</Typography>
                          <FormControl sx={textField}>
                            <Select
                              value={
                                Validation.isBlankObjectKeyNot(
                                  productDetailsData,
                                  'available'
                                )
                                  ? productDetailsData.available
                                  : ''
                              }
                              size='small'
                              name='available'
                              onChange={handleOnChange}
                              displayEmpty
                              placeholder='Select'
                            >
                              <MenuItem disabled value=''>
                                <em style={{ color: 'gray' }}>Select</em>
                              </MenuItem>
                              <MenuItem value='true'>True</MenuItem>
                              <MenuItem value='false'>False</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {Store.companyDetails?.businessType === 'MANDI' && (
                          <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                              Commission
                            </Typography>

                            <TextField
                              sx={textField}
                              id='commission'
                              onChange={handleOnChange}
                              size='small'
                              type='number'
                              autoComplete='off'
                              placeholder='Enter commission'
                              variant='outlined'
                              value={
                                Validation.isBlankObjectKeyNot(
                                  productDetailsData,
                                  'commission'
                                )
                                  ? productDetailsData.commission
                                  : ''
                              }
                              name='commission'
                            />
                          </Grid>
                        )}

                        {/* Tax columns */}
                        {Store.getSelectedTaxType() &&
                        Store.getSelectedTaxType() !== 'N/A' ? (
                          <>
                            {Store.getSelectedTaxType() === 'GST' ? (
                              <>
                                <Grid container spacing={1} sx={{ mt: 0.5 }}>
                                  {/* <Grid item xs={4}>
                                    <Typography
                                      variant='subtitle1'
                                      sx={{
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '14px',
                                        },
                                      }}
                                    >
                                      Enter CGST %
                                    </Typography>
                                    <TextField
                                      sx={textField}
                                      size='small'
                                      id='cgst'
                                      autoComplete='off'
                                      placeholder='Enter cgst'
                                      variant='outlined'
                                      key={taxData?.cgst}
                                      name='cgst'
                                      type='number'
                                      value={taxData?.cgst}
                                      onChange={handleTaxChange}
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography
                                      variant='subtitle1'
                                      sx={{
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '14px',
                                        },
                                      }}
                                    >
                                      Enter SGST %
                                    </Typography>
                                    <TextField
                                      sx={textField}
                                      size='small'
                                      id='sgst'
                                      key={taxData?.sgst}
                                      type='number'
                                      autoComplete='off'
                                      placeholder='Enter sgst'
                                      variant='outlined'
                                      name='sgst'
                                      value={taxData?.sgst}
                                      onChange={handleTaxChange}
                                    />
                                  </Grid> */}
                                  <Grid item xs={12} sm={12}>
                                    <Typography
                                      variant='subtitle1'
                                      sx={{
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '14px',
                                        },
                                      }}
                                    >
                                      Total GST %
                                    </Typography>
                                    <TextField
                                      sx={textField}
                                      size='small'
                                      id='gst'
                                      // key={taxData?.gst}
                                      autoComplete='off'
                                      placeholder='Enter gst'
                                      variant='outlined'
                                      name='gst'
                                      type='number'
                                      maxLength={2}
                                      value={taxData?.gst}
                                      onInput={e => {
                                        e.target.value = Math.max(
                                          0,
                                          parseInt(e.target.value)
                                        )
                                          .toString()
                                          .slice(0, 2);
                                      }}
                                      onChange={handleTaxChange}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                  Vat %
                                </Typography>
                                <TextField
                                  sx={textField}
                                  size='small'
                                  id='vat'
                                  key={taxData?.vat}
                                  autoComplete='off'
                                  placeholder='Enter vat %'
                                  variant='outlined'
                                  name='vat'
                                  type='number'
                                  value={taxData?.vat}
                                  onChange={handleTaxChange}
                                />
                              </Grid>
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          {subscriptionEnabled &&
                          serverConfig.isUpstoreCloud ? (
                            <>
                              <Box
                                sx={{
                                  pr: { sm: 7 },
                                  pb: { sm: 7 },
                                  pl: { sm: 7 },
                                }}
                              >
                                <ProductCarouselImg
                                  productDetailsData={productDetailsData}
                                  key={productDetailsData.productId}
                                  snackbar={openUpstoreSnackBar}
                                />
                              </Box>
                            </>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            mt: {
                              md:
                                serverConfig.isUpstoreCloud &&
                                subscriptionEnabled
                                  ? -7
                                  : 0,
                              sm:
                                serverConfig.isUpstoreCloud &&
                                subscriptionEnabled
                                  ? -7
                                  : 0,
                            },
                            ml: { sm: 7 },
                            mr: { sm: 8 },
                          }}
                        >
                          <Typography variant='subtitle1'>
                            Product Description
                          </Typography>

                          <TextField
                            sx={textField}
                            multiline
                            rows={3}
                            id='brand'
                            onChange={handleOnChange}
                            size='small'
                            autoComplete='off'
                            placeholder='Enter Description'
                            variant='outlined'
                            inputProps={{ maxLength: 45 }}
                            value={
                              Validation.isBlankObjectKeyNot(
                                productDetailsData,
                                'description'
                              )
                                ? productDetailsData.description
                                : ''
                            }
                            name='description'
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box
                    item
                    display='flex'
                    sx={{ mb: 2 }}
                    justifyContent='flex-end'
                  >
                    {serverConfig.isUpstoreCloud === true &&
                    subscriptionEnabled ? (
                      <Button
                        onClick={addPopularSearchItems}
                        disabled={buttonSubmitted}
                        variant='contained'
                        sx={{
                          height: '27px',
                          p: '0',
                          width: '126px',
                          textTransform: 'capitalize',
                          backgroundColor: '#3B78C9',
                          fontSize: '10px',
                          mr: 1,
                          mt: { md: 1, sm: 0, xs: 2 },
                        }}
                      >
                        Add To Popular Searches
                      </Button>
                    ) : null}
                    {AllPermissions.getUserAccessiblePermissions('Product') !==
                      undefined &&
                    AllPermissions.getUserAccessiblePermissions('Product')
                      .write === false ? null : (
                      <div>
                        <Button
                          onClick={handleUpdateProducts}
                          disabled={buttonSubmitted}
                          variant='contained'
                          sx={{
                            fontSize: '13px',
                            height: '27px',
                            width: '126px',
                            textTransform: 'capitalize',
                            backgroundColor: '#3B78C9',
                            mt: { md: 1, sm: 0, xs: 2 },
                          }}
                        >
                          Save Details
                        </Button>
                        {'  '}
                        <Button
                          variant='contained'
                          disabled={!productDetailsData?.productId}
                          onClick={() => {
                            setDialogWarning(true);
                          }}
                          sx={{
                            fontSize: '13px',
                            height: '27px',
                            width: '126px',
                            textTransform: 'capitalize',
                            mt: { md: 1, sm: 0, xs: 2 },
                          }}
                        >
                          {' '}
                          Delete Product
                        </Button>{' '}
                      </div>
                    )}
                  </Box>
                </form>

                <Divider sx={{ ml: 7 }} />
                <Divider sx={{ ml: 7 }} />
                <Divider sx={{ ml: 7 }} />

                <Grid sx={{ ml: { md: 7, sm: 7, xs: 8 } }}>
                  {AllPermissions.getUserAccessiblePermissions('Product') !==
                    undefined &&
                  AllPermissions.getUserAccessiblePermissions('Product')
                    .write === false ? null : (
                    <Tooltip title='Add Product Item' placement='bottom'>
                      <Button
                        sx={{
                          textTransform: 'capitalize',
                          backgroundColor: '#3B78C9',
                          float: 'right',
                          height: '27px',
                          width: '126px',
                          padding: 1,
                          mr: 1,
                        }}
                        variant='contained'
                        endIcon={<AddIcon sx={{ color: '#fff' }} />}
                        onClick={() => setOpenAddProductItemDialog(true)}
                      >
                        <Typography sx={{ fontSize: '13px' }}>
                          {' '}
                          Add New Item
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}

                  <ProductItemDetails
                    productItemDetails={
                      productDetailsData?.productItems?.filter(
                        item => !item.deleted
                      ) || []
                    }
                    snackbar={openUpstoreSnackBar}
                    getProductByProductId={getProductByProductId}
                    key={productDetailsData?.productItems?.filter(
                      item => !item.deleted
                    )}
                    subscriptionEnabled={subscriptionEnabled}
                    setButtonSubmitted={setButtonSubmitted}
                    submitted={buttonSubmitted}
                  />
                </Grid>

                {openAddProductItemDialog ? (
                  <AddNewProductItem
                    categoryId={productDetailsData.categoryId}
                    productId={productDetailsData?.productId}
                    openDialog={openAddProductItemDialog}
                    ShouldPageReload={true}
                    closePopup={closeAddDialog}
                    snackbar={openUpstoreSnackBar}
                    getNewlyFetchedProducts={getProductByProductId}
                  />
                ) : null}
              </Grid>
            </Grid>

            {upstoreSnackBar.open ? (
              <UpStoreSnackBar
                upstoreSnackBar={upstoreSnackBar}
                onSnackbarClose={onSnackbarClose}
              />
            ) : null}
          </>
        ) : (
          <Loading />
        )}
      </Box>
      {showCommentsSection.open ? (
        <MessageReviewSection
          open={showCommentsSection.open}
          closeDrawer={() => setShowCommentsSection({ open: false })}
          productId={productDetailsData?.productId}
          snackbar={openUpstoreSnackBar}
        />
      ) : null}
      {dialogWarning ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
          openDialog={dialogWarning}
          content=' Are you sure you want to delete the product.'
        />
      ) : null}
    </>
  );
}

export default ProductDetails;
