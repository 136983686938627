import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import UpstoreProperties from '../../modules/UpstoreProperties';
import DAFAUT_LOGO_IMG from '../../assets/images/logo.png';
import Validation from '../../modules/Validation';
import { serverConfig } from '../../config/server-config';
import Auth from '../../modules/Auth';
import './styles.scss';

function launchLink(link) {
  window.location.href = link;
}

class Prompts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptItems: [],
    };
  }

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  componentDidMount() {
    this.createPromptItems();
  }

  createPromptItems = () => {
    let promptImageLinks = UpstoreProperties.getProperty('PROMPT_LINKS');

    if (Validation.isBlankNot(promptImageLinks)) {
      promptImageLinks = JSON.parse(promptImageLinks);
    }

    let items = [];
    let promptImageIndex = 1;
    for (var i = 0; i < promptImageLinks.length; i++) {
      let promptObj = {
        image:
          serverConfig.cmsSource +
          'store_' +
          Auth.getStoreId() +
          '/images' +
          '/Banners/prompt_images-BannerImageIndex-' +
          promptImageIndex,
        link: promptImageLinks[promptImageIndex++ - 1],
      };
      items.push(promptObj);
    }

    this.setState({ promptItems: items });
  };
  addDefaultSrc = ev => {
    ev.target.src = DAFAUT_LOGO_IMG;
  };
  render() {
    return (
      <Carousel
        key={this.state.promptItems}
        className='carousel-slider'
        responsive={this.responsive}
        infinite={true}
        infiniteLoop={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        transitionDuration={500}
        showThumbs={false}
        removeArrowOnDeviceType={['tablet', 'mobile']}
      >
        {this.state.promptItems.map(imageObj => {
          return (
            <img
              src={imageObj.image}
              onClick={event => launchLink(imageObj.Link)}
              style={{ width: '100%', height: '50%', position: 'relative' }}
              onError={this.addDefaultSrc}
            />
          );
        })}
      </Carousel>
    );
  }
}

export default Prompts;
