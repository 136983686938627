import React from 'react';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Auth from '../../modules/Auth';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import EditIcon from '@mui/icons-material/Edit';
import HTMLRenderer from 'react-html-renderer';
import Store from '../../modules/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  Box,
  Tabs,
  Tab,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import MandiChalan from '../../modules/MandiChalan';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import { Loading } from '../../component/loading/Loading';
import { Close } from '@mui/icons-material';
import MultipleWatakInvoice from '../../component/multipleWatakInvoice/MultipleWatakInvoice';
import Vendor from '../../modules/Vendor';

let dataIds = [];
function MandiWatak() {
  let navigate = useNavigate();
  let location = useLocation();

  const [value, setValue] = React.useState(0);
  const [watakList, setWatakList] = React.useState([]);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [searchString, setSearchString] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [deletedWatak, setDeletedWatak] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    IdOfDeletableWatak: '',
  });

  React.useEffect(() => {
    dataIds = [];

    getWatakByStoreId(0);
    getCurrency();
  }, [deletedWatak]);
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  //   const debounce = (func, timeout = 350) => {
  //     let timer;
  //     return (...args) => {
  //       clearTimeout(timer);
  //       timer = setTimeout(() => {
  //         func.apply(this, args);
  //       }, timeout);
  //     };
  //   };

  const createOrUpdateWatak = watak => {
    MandiChalan.getWatak(watak?.id, response => {
      if (response.status === 'success') {
        const watak = response.data;
        navigate('/watak-details', {
          state: { watak },
        });
      }
    });
    // setTimeout(() => {
    //   navigate('/watak-details', {
    //     state: { watak },
    //   });
    // }, 10);
  };

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const columns = [
    {
      field: 'watakNumber',
      headerName: 'S.No',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
            {`${params.row?.watakNumber ? params.row?.watakNumber : ''}`}
          </Typography>
        );
      },
    },
    {
      field: 'challanNumber',
      headerName: 'Challan No',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          <Typography
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
            onClick={event => {
              if (params.row.deleted) {
                openUpstoreSnackBar(
                  `Chalan is deleted you can't edit or update `,
                  'error'
                );
              } else {
                createOrUpdateWatak(params.row);
              }
            }}
          >
            {params.row.challanNumber}
          </Typography>
          <Box sx={{ pl: 3 }}>
            {params.row.edited && (
              <Tooltip title='Edited'>
                <EditIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            )}
          </Box>
        </>
      ),
    },

    {
      field: 'grower',
      headerName: 'Grower Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
            {`${params.row?.grower}`}
          </Typography>
        );
      },
    },
    {
      field: 'totalFreight',
      headerName: 'Total Freight',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalFreight}{' '}
            {currency && <HTMLRenderer html={currency} />}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalSelfAmount',
      headerName: 'Total Self Amount',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalSelfAmount}{' '}
            {currency && <HTMLRenderer html={currency} />}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalQuantity',
      headerName: 'Total Quantity',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalQuantity === null ? 0 : params.row.totalQuantity}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalActualQuantity',
      headerName: 'Actual Quantity',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',

      renderCell: params => (
        <>
          <Typography>
            {params.row.totalActualQuantity === null
              ? 0
              : params.row.totalActualQuantity}{' '}
          </Typography>
        </>
      ),
    },
    {
      field: '',
      headerName: 'Chalan Date',
      type: 'date',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params =>
        params.row.challanDate === null
          ? 'N / A'
          : moment.utc(params.row.challanDate).local().format('DD-MM-yyyy'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.status === 'CONFIRMED' ? 'Confirmed' : 'Drafted',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      type: 'action',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              <Tooltip title='Delete Watak' placement='bottom'>
                <DeleteIcon
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  onClick={event => {
                    if (params.row.deleted) {
                      openUpstoreSnackBar(
                        'Watak is already been deleted ',
                        'error'
                      );
                    } else {
                      setOpenDeleteDialog({
                        open: true,
                        IdOfDeletableWatak: params.row.id,
                      });
                    }
                  }}
                />
              </Tooltip>
            </>
          </Box>
        </>
      ),
    },
  ];
  const handlePreviousPage = () => {
    let pageNum = pageNumber - 1;
    setPageNumber(pageNum);
    getWatakByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 1;
    setPageNumber(pageNum);
    getWatakByStoreId(pageNum);
  };
  const getWatakByStoreId = pageNum => {
    let deleted = deletedWatak;
    setLoader(true);
    MandiChalan.getWatakByStoreId(
      Auth.getStoreId(),
      pageNum,
      deleted,
      response => {
        if (response.status === 'success') {
          setLoader(false);
          let sortedArray = response.data.sort(function (a, b) {
            return a.creationDate - b.creationDate;
          });
          setWatakList(sortedArray);
        } else {
          console.log('Error while Getting Watak');
        }
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDeletedWatak(false);
    } else {
      setDeletedWatak(true);
    }
  };

  const filteredWatakData = watakList.filter(list => {
    return (
      list?.grower?.toLocaleLowerCase()?.includes(searchString) ||
      list?.challanNumber?.toLocaleLowerCase()?.includes(searchString)
    );
  });
  const handleDeleteWatakCallback = usersChoice => {
    if (usersChoice === true) {
      MandiChalan.deleteWatak(openDeleteDialog.IdOfDeletableWatak, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('Watak  was deleted successfully', 'success');
          getWatakByStoreId(pageNumber);
          setOpenDeleteDialog(false);
        } else {
          openUpstoreSnackBar('There is an issue in deleting watak', 'error');
          setOpenDeleteDialog(false);
        }
      });
    }
  };

  return (
    <>
      <Header pageName='Mandi Watak' />
      {loader ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: '95%',
            height: 600,
            mt: 8,
            ml: 8,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <BreadCrumb pageName='Mandi Watak' />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Box
                sx={{
                  width: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                  }}
                  name='search'
                  placeholder='Search Watak By Name & Chalan Number'
                  onChange={event => {
                    event.preventDefault();
                    const searchFieldValue =
                      event.target.value.toLocaleLowerCase();
                    setSearchString(searchFieldValue);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='Watak Tabs'
              >
                <Tab label='Confirmed Watak' {...a11yProps(0)} />
                <Tab label='Deleted Watak' {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <MandiChalanTabs
            columns={columns}
            data={filteredWatakData}
            value={value}
          />

          <Grid
            style={{
              padding: '1.6vh',
              float: 'right',

              bottom: '0px',
            }}
          >
            <Button
              size='small'
              variant='outlined'
              disabled={pageNumber <= 0}
              onClick={handlePreviousPage}
              sx={{ color: '#3B78C9' }}
            >
              Prev
            </Button>
            <Button
              size='small'
              variant='outlined'
              disabled={watakList.length < 20}
              sx={{ margin: '1vh', color: '#3B78C9' }}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </Grid>
        </Box>
      )}

      {upstoreSnackBar.open && (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      )}
      {openDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              IdOfDeletableWatak: '',
            })
          }
          deleteCallback={handleDeleteWatakCallback}
          dialogContent='Your Watak will be deleted permanently , Are you sure you want to delete it ?'
        />
      ) : null}
    </>
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`order-tabpanel-${index}`}
        aria-labelledby={`order-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0.5 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `order-tab-${index}`,
      'aria-controls': `order-tabpanel-${index}`,
    };
  }
  function MandiChalanTabs({ columns, data, value }) {
    const [watakListToPrint, setWatakListToPrint] = React.useState({
      open: false,
      watakList: [],
    });
    const [skeletonLoading, setSkeletonLoading] = React.useState(false);
    const getByIds = ids => {
      if (ids.length > 0) {
        setSkeletonLoading(true);
        MandiChalan.getWatakByIds(ids, response => {
          if (response.status === 'success') {
            setSkeletonLoading(false);
            setWatakListToPrint({
              ...watakListToPrint,
              watakList: response.data,
              open: true,
            });
          }
        });
      } else {
        openUpstoreSnackBar('Select Atleast One Watak', 'error');
        setSkeletonLoading(false);
      }
    };
    const onRowsSelectionHandler = ids => {
      dataIds = ids;
    };
    const closeDialog = () => {
      setWatakListToPrint({ ...watakListToPrint, open: false });
    };

    return (
      <>
        <Box sx={{ width: '100%' }}>
          <TabPanel value={value} index={0}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mr: 5,
                mb: 1,
              }}
            >
              <Button
                variant='outlined'
                style={{
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                  mb: 2,
                }}
                // onClick={() => {
                //   setWatakListToPrint({ ...watakListToPrint, open: true });
                // }}
                onClick={() => getByIds(dataIds)}
              >
                Print Wataks
              </Button>
            </Grid>
            <Box
              sx={{
                width: '100%',
                height: 600,

                '& .super-app-theme--header': {
                  backgroundColor: '#EFFAFF',

                  fontSize: '18px',
                },
              }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                  '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                  },
                }}
                density='compact'
                rows={data}
                columns={columns}
                pageSize={20}
                hideFooter={true}
                aria-label='watak list'
                isRowSelectable={params =>
                  params.row.status.toUpperCase() === 'CONFIRMED' ? true : false
                }
                checkboxSelection
                onSelectionModelChange={ids => onRowsSelectionHandler(ids)}
              />
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box
              sx={{
                width: '100%',
                height: 600,

                '& .super-app-theme--header': {
                  backgroundColor: '#EFFAFF',

                  fontSize: '18px',
                },
              }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                }}
                density='compact'
                rows={data}
                columns={columns}
                pageSize={20}
                hideFooter={true}
                aria-label='watak list'
              />
            </Box>
          </TabPanel>
        </Box>
        {skeletonLoading && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={skeletonLoading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Dialog
          maxWidth={'xl'}
          open={watakListToPrint.open}

          // onClose={closeInvoiceDialog}
        >
          <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
            <Grid item>
              <Tooltip title='Close dialog' placment='right'>
                <Close
                  onClick={closeDialog}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <DialogContent>
            <MultipleWatakInvoice orderDetails={watakListToPrint.watakList} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default MandiWatak;
