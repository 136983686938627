import React from 'react';
import Header from '../../component/header/Header';
import { Box, Grid, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import CreateExpenses from '../../component/createExpenses/CreateExpenses';
import Auth from '../../modules/Auth';
import Expense from '../../modules/Expense';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../component/loading/Loading';
import { serverConfig } from '../../config/server-config';
import * as moment from 'moment';
import ExportDialog from '../../component/exportDialog/ExportDialog';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
function Expenses() {
  let navigate = useNavigate();
  const [createExpenses, setcreateExpenses] = React.useState(false);
  const [expenseAllData, setExpenseAllData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [updatedExpenseData, setUpdatedExpenseData] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [repeatExpenseData, setRepeatExpenseData] = React.useState({});
  const [expenseReportDialog, setExpenseReportDialog] = React.useState(false);
  React.useEffect(() => {
    getAllExpensesByStoreId(0);
  }, []);

  const handleDeleteExpenses = id => {
    Expense.deleteExpense(id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Expense deleted successfully', 'success');
        getAllExpensesByStoreId(pageNumber);
      }
    });
  };
  const getRepeatExpenseData = id => {
    Expense.getRepeatExpensesById(id, response => {
      if (response.status === 'success') {
        setRepeatExpenseData(response.data);
      } else {
        console.log('error');
      }
    });
  };
  const handleEditExpenses = params => {
    setcreateExpenses(true);
    setUpdatedExpenseData(params);
  };
  const columns = [
    {
      field: 'expenseNumber',
      headerName: 'Expense Number',
      width: 150,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => {
        return (
          <Typography
            sx={{ color: '#3B78C9' }}
            onClick={() => {
              handleEditExpenses(params.row);
              getRepeatExpenseData(params.row.id);
            }}
          >
            {params.row.expenseNumber}
          </Typography>
        );
      },
    },

    {
      field: 'userName',
      headerName: 'UserName',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',

      align: 'center',
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      width: 160,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',

      align: 'center',
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      width: 150,

      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography>
            {params.row.creationDate === null
              ? 'N / A'
              : moment(params.row.creationDate).format('DD-MM-YYYY')}
          </Typography>
        );
      },
    },
    {
      field: 'billDate',
      headerName: ' Bill Date',
      width: 150,

      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography>
            {params.row.billDate === null
              ? 'N / A'
              : moment(params.row.billDate).format('DD-MM-YYYY')}
          </Typography>
        );
      },
    },
    {
      field: 'invoice',
      headerName: 'Invoice ',
      width: 100,

      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'amount',
      headerName: 'Amount',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      type: 'text',
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <DeleteIcon
            sx={{ color: '#3B78C9' }}
            onClick={() => {
              handleDeleteExpenses(params.row.id);
            }}
          />
        );
      },
    },
  ];

  const handleCreateCallBack = () => {
    setcreateExpenses(false);
    setUpdatedExpenseData({});
    setRepeatExpenseData({});
  };
  const getAllExpensesByStoreId = pageNum => {
    setLoader(true);

    Expense.getAllExpensesByStoreId(Auth.getStoreId(), pageNum, response => {
      if (response.status === 'success') {
        let sortedExpense = response.data.sort(function (a, b) {
          return b.expenseNumber - a.expenseNumber;
        });
        setLoader(false);

        setExpenseAllData(sortedExpense);
      }
    });
  };

  const handlePreviousPage = () => {
    let pageNum = pageNumber - 1;
    setPageNumber(pageNum);
    getAllExpensesByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 1;
    setPageNumber(pageNum);
    getAllExpensesByStoreId(pageNum);
  };
  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  return (
    <div>
      <Header />
      {createExpenses ? (
        <CreateExpenses
          handleCreateCallBack={handleCreateCallBack}
          snackbar={openUpstoreSnackBar}
          reloadExpenses={getAllExpensesByStoreId}
          updatedExpenseData={updatedExpenseData}
          repeatExpensedData={repeatExpenseData}
        />
      ) : loader ? (
        <Loading />
      ) : (
        <Box
          sx={{
            ml: 6,

            mt: 7,
          }}
        >
          <Grid container>
            <Grid item md={6} sm={6} xs={6} sx={{ pl: 2 }}>
              <BreadCrumb pageName='Expenses' />
            </Grid>
            {/* {serverConfig.isUpstoreCloud ? ( */}
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              sx={{
                pl: 2,
                pr: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: '#3B78C9',
                    textTransform: 'capitalize',
                    p: 0,
                    width: { md: '126px', sm: '126px', xs: '80px' },
                    height: '27px',
                  }}
                  onClick={() => setExpenseReportDialog(true)}
                >
                  <Typography
                    sx={{ fontSize: { md: '13px', sm: '13px', xs: '9px' } }}
                    noWrap
                  >
                    Expense Report
                  </Typography>
                </Button>
              </Grid>
              {AllPermissions.getUserAccessiblePermissions('Expenses') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('Expenses').write ===
                false ? null : (
                <>
                  <Grid item xs={6}>
                    <Button
                      variant='contained'
                      sx={{
                        backgroundColor: '#3B78C9',
                        textTransform: 'capitalize',
                        p: 0,
                        width: { md: '126px', sm: '126px', xs: '80px' },
                        height: '27px',
                      }}
                      onClick={() => navigate('/bulkexpenses')}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: '13px', sm: '13px', xs: '9px' },
                        }}
                        noWrap
                      >
                        {' '}
                        Bulk Expenses
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                    <Button
                      variant='contained'
                      sx={{
                        backgroundColor: '#3B78C9',
                        textTransform: 'capitalize',
                        p: 0,
                        width: { md: '126px', sm: '126px', xs: '80px' },
                        height: '27px',
                      }}
                      onClick={() => {
                        setcreateExpenses(true);
                        setRepeatExpenseData({});
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: '13px', sm: '13px', xs: '9px' },
                        }}
                        noWrap
                      >
                        Create Expenses
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {/* // ) : null} */}
            <Grid item sm={12} sx={{ pt: 1, ml: 1 }}>
              <div style={{ height: '100vh', width: '100%' }}>
                <DataGrid
                  density='compact'
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  sx={{
                    '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                      backgroundColor: 'secondary.light',
                    },
                  }}
                  rows={expenseAllData}
                  columns={columns}
                  pageSize={20}
                  hideFooter={true}
                  rowsPerPageOptions={[20, 40, 60]}
                />
              </div>
            </Grid>
          </Grid>
          {loader ? null : (
            <Grid
              style={{
                padding: '1.6vh',
                float: 'right',

                bottom: '0px',
              }}
            >
              <Button
                disabled={pageNumber <= 0}
                onClick={handlePreviousPage}
                sx={{ color: '#3B78C9' }}
              >
                Prev
              </Button>
              <Button
                disabled={expenseAllData.length < 20}
                sx={{ margin: '1vh', color: '#3B78C9' }}
                onClick={handleNextPage}
              >
                Next
              </Button>
            </Grid>
          )}
        </Box>
      )}
      <ExportDialog
        openDialog={expenseReportDialog}
        closeDialog={() => setExpenseReportDialog(false)}
        type='expense'
      />

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}

      {AllPermissions.getUserAccessiblePermissions('Expenses') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Expenses').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </div>
  );
}

export default Expenses;
