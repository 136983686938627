import Store from '../src/modules/Store';
export const getData = (response, callBack) => {
  if (response.data) {
    callBack({ status: 'success', data: response.data });
    return response.data;
  }
};
export const getError = (error, callBack, message) => {
  callBack({ status: 'error' });
};
export const debounce = (func, timeout) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export const gstCheckDetails = customerDetails => {
  const storeGstNo = Store?.getGstNo();
  const customerGstNo = customerDetails?.gstNo;
  if (Store.getSelectedTaxType() === 'GST') {
    if (
      storeGstNo?.slice(0, 2) === customerGstNo?.slice(0, 2) &&
      customerGstNo?.length === 15
    ) {
      return true;
    }

    if (
      customerGstNo === '' &&
      Store?.getCompanyDetails()?.state === customerDetails?.state
    ) {
      return true;
    }
  }

  if (
    storeGstNo?.slice(0, 2) === customerGstNo?.slice(0, 2) &&
    customerDetails.tax
  ) {
    return true;
  }
  if (
    Store?.getCompanyDetails()?.state === customerDetails?.state &&
    customerDetails.tax
  ) {
    return true;
  }

  return false;
};
