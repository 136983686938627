import React from 'react';
import { Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Payments from '../../modules/Payments';
import { styled } from '@mui/material/styles';
import * as moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: 400,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaymentEditDialog({
  openDialog,
  paymentEditD,
  closeDateDialog,
  reloadPaymentList,
  selectedDateCallback,
  callbackNeeded,
}) {
  const [value, setValue] = React.useState(new Date());
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  //update payment date
  const handlePaymentsDateChange = () => {
    let id = paymentEditD.paymentDa.id;
    let data = {
      paymentDate: value,
    };

    Payments.paymentDatePicker(id, data, response => {
      if (response.status === 'success') {
        closeDateDialog();
        openUpstoreSnackBar('payment date updated successfully', 'success');
        reloadPaymentList(false, 0);
      }
    });
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const handleCallBack = () => {
    selectedDateCallback(value);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={closeDateDialog}
        aria-labelledby='customized-dialog-title'
        open={openDialog}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          sx={{ textAlign: 'center' }}
        >
          Edit Payment Date
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id='datetime-local'
              type='datetime-local'
              defaultValue={moment(value).format('yyyy-MM-DD')}
              sx={{ width: 250 }}
              value={value}
              onChange={newValue => {
                setValue(newValue);
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
          {/* <Typography gutterBottom>
            <TextField
              id="datetime-local"
              type="datetime-local"
              defaultValue={moment(value).format('yyyy-MM-DD')}
              sx={{ width: 250 }}
              onChange={event => {
                setValue({ value: event.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '126px',
              height: '27px',
            }}
            autoFocus
            onClick={closeDateDialog}
          >
            Cancel
          </Button>
          {callbackNeeded ? (
            <Button
              sx={{
                width: '126px',
                height: '27px',
              }}
              autoFocus
              onClick={handleCallBack}
            >
              Check Date
            </Button>
          ) : (
            <Button
              sx={{
                textTransform: 'capitalize',
                padding: 0,
                width: '126px',
                height: '27px',
              }}
              autoFocus
              onClick={handlePaymentsDateChange}
            >
              Save changes
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}

export default PaymentEditDialog;
