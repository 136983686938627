import React from 'react';
import { FormGroup } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import imageCompression from 'browser-image-compression';
import Auth from '../../modules/Auth';
import Store from '../../modules/Store';
import { serverConfig } from '../../config/server-config';

class UploadLogo extends React.Component {
  constructor(props) {
    super(props);
  }

  handleCapture = target => {
    if (target.files) {
      if (target.files.length !== 0) {
        this.uploadImages(target.files);
      }
    }
  };

  uploadImages = images => {
    Array.from(images).map((imageFile, index) => {
      if (index < 3) {
        this.handleImageCompression(imageFile, index);
      } else {
        return 'error';
      }
    });
  };

  handleImageCompression = async (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await this.uploadToServer(compressedFile, index);
      } catch (error) {
        console.log('Image upload failed ' + error);
      }
    } else {
      await this.uploadToServer(imageFile, index);
    }
  };
  uploadToServer = (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB

    if (imageFile.size <= maxFileSize) {
      const formData = new FormData();
      const imageName = 'logo';
      formData.append('image', imageFile);
      formData.append('name', imageName);
      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          this.props.snackbar('Logo Uploaded successfully', 'success');
          this.props.getFreshData(imageName);
        } else {
          console.log('error');
        }
      });
    }
  };

  render() {
    return (
      <>
        {serverConfig.isUpstoreCloud ? (
          <FormGroup style={{ marginTop: '20px' }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="logo-button-file"
              type="file"
              onChange={e => this.handleCapture(e.target)}
            />
            <label htmlFor="logo-button-file">
              <PhotoCameraIcon color="primary" />
            </label>
          </FormGroup>
        ) : null}
      </>
    );
  }
}

export default UploadLogo;
