import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPdf from 'react-to-pdf';
import ReactToPrint from 'react-to-print';
import Customer from '../../modules/Customer';
import { Typography, Button } from '@mui/material';
import './style.css';
import Store from '../../modules/Store';
import { ToWords } from 'to-words';
import Logo from '../../modules/Logo';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import * as moment from 'moment';
import HTMLRenderer from 'react-html-renderer';
import DAFAUT_LOGO_IMG from '../../assets/images/logo.png';
import { serverConfig } from '../../config/server-config';
import Vendor from '../../modules/Vendor';
import CancelledImG from '../../assets/images/cancelled.png';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';

const ref = React.createRef();

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
  },
});

class PaymentReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: null,
      logoUrl: null,
      signatureUrl: null,
      paymentDetails: this.props.paymentDetails,
      customerdetails: {},
      options: {
        unit: 'pt',
        format: [600, 600],
        storeAccount: [],
      },
    };
  }

  componentDidMount() {
    this.getCustomerName();
    this.getLogoUrl(Auth.getStoreId());
    this.getSignatureUrl();
    this.getCurrency();
  }
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint').clientHeight - 40;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      this.setState({ options: opt });
    }, 5);
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        signatureUrl: Logo.getSignatureUrl(),
      });
    } else {
      this.setState({
        signatureUrl: signature,
      });
    }
  };

  addDefaultSrc = ev => {
    ev.target.src = DAFAUT_LOGO_IMG;
  };
  getCustomerName = () => {
    if (this?.props?.Type === 'customer') {
      Customer.getCustomerById(
        this.props?.paymentDetails?.customerId,
        response => {
          if (response.status === 'success') {
            this.setState({
              customerdetails: response.data,
            });
          } else {
            console.log('err');
          }
        }
      );
    } else {
      Vendor.getVendorByVendorId(
        this.props?.paymentDetails?.customerId,
        response => {
          if (response.status === 'success') {
            this.setState({
              customerdetails: response.data,
            });
          } else {
            console.log('err');
          }
        }
      );
    }
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            // filename={`receipt__${this.state.orderDetails.storeOrderNumber}.pdf`}
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon sx={{ color: '#fff' }} /> Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
          {/* <ReactToPdf
            targetRef={ref}
            options={this.state.options}
            filename={`receipt__${this.state.paymentDetails.id}.pdf`}
          >
            {({ toPdf }) => (
              <Button
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  bgcolor: '#3B78C9',
                  width: '126px',
                  height: '27px',
                }}
                onClick={toPdf}
              >
                <DownloadIcon sx={{ color: '#fff' }} /> Downloads
              </Button>
            )}
          </ReactToPdf> */}
        </Typography>
        <br />
        <div className='divToDownload' ref={ref}>
          {this.state.paymentDetails.deleted ? (
            <img src={CancelledImG} className='img_cancel' />
          ) : null}
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100%' }}
          >
            <div className='invoice-content row d-flex justify-content-center'>
              <div className='col-12'>
                <div className='card'>
                  <div className='d-flex flex-row p-2'>
                    <div className='d-flex flex-column'>
                      {Validation.isBlankNot(this.state.logoUrl) ? (
                        <img
                          style={{ maxWidth: '100px', maxHeight: '50px' }}
                          src={this.state.logoUrl}
                          alt='Logo'
                          onError={this.addDefaultSrc}
                        ></img>
                      ) : (
                        `logo`
                      )}
                    </div>
                  </div>

                  <hr />
                  <div className='table-responsive p-1'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        {this.props?.Type === 'customer' ? (
                          <tr className='invoice_Hed'>
                            <th>STORE NAME</th>
                            <th>Customer </th>
                          </tr>
                        ) : (
                          <tr className='invoice_Hed'>
                            <th>STORE NAME</th>
                            <th>Vendor </th>
                          </tr>
                        )}
                        <tr className='content'>
                          <td className='font-weight-bold'>
                            {
                              Store.getStoreByStoreId(
                                Auth.getStoreId(),
                                () => {}
                              ).name
                            }
                            <br />
                            {
                              Store.getStoreByStoreId(
                                Auth.getStoreId(),
                                () => {}
                              ).address
                            }
                          </td>
                          {this.props?.Type === 'customer' ? (
                            <td className='font-weight-bold'>
                              {`${
                                this.state.customerdetails?.customerName
                                  ? this.state.customerdetails?.customerName
                                  : '-'
                              }`}
                              <br />
                              {`${
                                this.state.customerdetails?.address
                                  ? this.state.customerdetails?.address
                                  : '-'
                              }
                              `}
                              <br />
                            </td>
                          ) : (
                            <td className='font-weight-bold'>
                              {`${
                                this.state.customerdetails?.name
                                  ? this.state.customerdetails?.name
                                  : '-'
                              }`}
                              <br />
                              {`${
                                this.state.customerdetails?.address
                                  ? this.state.customerdetails?.address
                                  : '-'
                              }
                              `}
                              <br />
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div className='table-responsive p-2'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <div style={{ flex: 'flex-start' }}>
                            <h6>
                              Payment Number :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.paymentDetails,
                                'id'
                              )
                                ? this.state.paymentDetails.paymentNumber
                                : 'N/A'}{' '}
                            </h6>
                            <h6>
                              Payment Date :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.paymentDetails,
                                'paymentDate'
                              )
                                ? moment
                                    .utc(this.state.paymentDetails.paymentDate)
                                    .local()
                                    .format('DD-MM-YYYY ')
                                : 'N/A'}
                            </h6>
                            <h6>
                              Payment Time :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.paymentDetails,
                                'creationDate'
                              )
                                ? moment
                                    .utc(
                                      this.state?.paymentDetails?.creationDate
                                    )
                                    .local()
                                    .format('hh:mm:ss')
                                : 'N/A'}
                            </h6>
                          </div>
                          <div
                            style={{
                              border: '2px solid black',
                              marginLeft: '200px',
                              marginTop: '-8px',
                              height: '60px',
                            }}
                          >
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <h6>Amount Recieved</h6>
                              <h6>
                                {Validation.isBlankObjectKeyNot(
                                  this.state.paymentDetails,
                                  'amount'
                                )
                                  ? this.state.paymentDetails.amount
                                  : 'N/A'}{' '}
                                {this.state.currency ? (
                                  <HTMLRenderer html={this.state.currency} />
                                ) : null}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <h6>
                            Mode of Payment :{' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.paymentDetails,
                              'paymentType'
                            )
                              ? this.state.paymentDetails.paymentType
                              : 'N/A'}{' '}
                          </h6>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <h6>
                            Recieved By :{' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.paymentDetails,
                              'createdBy'
                            )
                              ? this.state.paymentDetails.createdBy
                              : 'N/A'}{' '}
                          </h6>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <h6 style={{ alignContent: 'space-evenly' }}>
                            Balance Due :{' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.paymentDetails,
                              'lastBalance'
                            )
                              ? this.state.paymentDetails.lastBalance
                              : '-'}
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </h6>
                        </div>

                        <h6
                          style={{
                            alignContent: 'space-evenly',
                            marginLeft: '8px',
                          }}
                        >
                          Payment in words : {''}
                          {Validation.isBlankObjectKeyNot(
                            this.state.paymentDetails,
                            'amount'
                          )
                            ? toWords.convert(this.state.paymentDetails.amount)
                            : 'N/A'}{' '}
                        </h6>
                      </tbody>
                    </table>
                  </div>
                  <hr />

                  <div className='address p-2' style={{ display: 'flex' }}>
                    <div
                      style={{
                        flex: 'start',
                      }}
                    ></div>
                    <div
                      className='d-flex flex-column'
                      style={{ paddingTop: '2rem ', paddingLeft: '32rem' }}
                    >
                      <h6>Signature</h6>
                      {/* <img
                        style={{ maxWidth: "100px", maxHeight: "50px" }}
                        src={this.state.logoUrl}
                        Alt="Signature"
                      /> */}

                      {Validation.isBlankNot(this.state.signatureUrl) ? (
                        <img
                          style={{ maxWidth: '100px', maxHeight: '50px' }}
                          src={this.state.signatureUrl}
                          alt='Signature'
                          onError={this.addDefaultSrc}
                        ></img>
                      ) : (
                        `Signature`
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentReceipt;
