import UpstoreApi from '../UpstoreApi';

class TaxCalculation {
  getGstByProductIdAndCategoryId = (productId, categoryId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `gst/${productId}/${categoryId}`,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'noData', data: {} });
        }
      })
      .catch(error => {
        console.log('Error occure while getting gst', error.message);
        callback({ status: 'error' });
      });
  };

  getVatByProductIdAndCategoryId = (productId, categoryId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `vat/${productId}/${categoryId}`,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'noData', data: {} });
        }
      })
      .catch(error => {
        console.log('Error occure while getting vat', error.message);
        callback({ status: 'error' });
      });
  };

  AddGstTaxData = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `gst`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while add gst taxdata', error.message);
        callback({ status: 'error' });
      });
  };

  AddVatTaxData = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `vat`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding vat taxData', error.message);
        callback({ status: 'error' });
      });
  };

  getCommissionForProduct = (productId, categoryId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `commission/${productId}/${categoryId}`,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'noData', data: {} });
        }
      })
      .catch(error => {
        console.log('Error occure while getting Commission', error.message);
        callback({ status: 'error' });
      });
  };
}

export default new TaxCalculation();
