import { Add, Remove } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useEffect } from 'react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Validation from '../../modules/Validation';
import ReturnOrder from '../../modules/ReturnOrder';
import Transaction from '../../modules/Transaction';
import Auth from '../../modules/Auth';
import { LogMessage } from '../../modules/Logger';
import { Box } from '@mui/system';

function ReturnOrderItems({
  row,
  snackbar,
  returnOrder,
  closeRetDialog,
  returnedOrders,
  editReturnOrder,
  storeOrderNumber,
  handleSetUpdatedOrderItem,
  handleReturnOrderCallBack,
  handleQuantityChangeCallback,
}) {
  const [rowDetails, setRowDetails] = React.useState(row);
  const [canelledDialog, setCancelledDialog] = React.useState(false);

  useEffect(() => {
    let returnedItem = returnOrder?.orderDetails?.find(
      i => i.itemNumber == row.itemNumber
    );

    if (returnedItem) {
      if (returnedItem.deleted) {
        // Use this only for Return/Cancel button display
        returnedItem.returned = false;
      } else {
        returnedItem.returned = true;
      }
      setRowDetails({ ...returnedItem });
    } else {
      returnedItem = { ...row };
      returnedItem.id = undefined;
      returnedItem.returned = false;
      setRowDetails({ ...returnedItem });
    }
  }, []);

  const cancelRow = itemNumber => {
    // if (returnOrder?.id) {
    //   let leftItems = returnOrder.orderDetails.filter(i => !i.deleted);
    //   if (leftItems?.length <= 1 && leftItems[0].itemNumber === itemNumber) {
    //     setCancelledDialog(true);
    //     return;
    //   }
    // }
    let returnedItem = { ...row };
    returnedItem.returned = false;
    returnedItem.deleted = true;
    setRowDetails({ ...returnedItem });
    handleReturnOrderCallBack(returnedItem, 'cancel');
  };
  const handleReturnOrder = () => {
    let returnedItem = { ...rowDetails };
    returnedItem.returned = true;
    returnedItem.deleted = false;
    setRowDetails({ ...returnedItem });
    handleReturnOrderCallBack(returnedItem, 'return');
  };

  const closeDilaog = () => {
    setCancelledDialog(false);
  };

  const deleteRetOrder = () => {
    let orderId = returnOrder?.id;
    ReturnOrder.deleteReturnOrder(orderId, response => {
      if (response.status === 'success') {
        snackbar('Return order deleted successfully', 'success');
        handleSetUpdatedOrderItem(returnedOrders.orderId);
        closeRetDialog();

        Transaction.log(
          Auth.getStoreId(),
          returnedOrders.customerId,
          LogMessage.DELETE_RETURN_ORDER,
          LogMessage.RETURN_ORDER_DELETED_TITLE,
          [
            returnedOrders.returnOrderNumber,
            storeOrderNumber,
            Auth.getUserName(),
          ]
        );
      } else {
        snackbar(' error occured while deleting Return order ', 'error');
      }
    });
  };
  const handleQualtityChange = value => {
    if (value == 0 && value != '') {
      value = 1;
    }
    if (value == '') {
      snackbar('please enter some value', 'error');
    }

    let numberOfItems = row?.noOfItems || 0;
    if (value <= numberOfItems && value >= 0) {
      let updatedRowDetails = { ...rowDetails };
      updatedRowDetails.noOfItems = parseFloat(value);
      setRowDetails(updatedRowDetails);
      handleQuantityChangeCallback(updatedRowDetails);
    }
  };

  return (
    <div>
      <TableContainer
        sx={{ maxHeight: 450, maxWidth: 800 }}
        component={Paper}
        className='scroll'
      >
        <Table
          stickyHeader
          aria-label='sticky table'
          sx={{
            backgroundColor: 'secondary.light',
            position: 'relative',
          }}
          size='small'
        >
          <TableBody>
            <TableRow sx={{ backgroundColor: '#fff' }}>
              <TableCell
                key={`itemName_${rowDetails?.itemName}`}
                align='left'
                width={200}
              >
                <ListItem>
                  {rowDetails.returned && !rowDetails.deleted ? (
                    <KeyboardReturnIcon fontSize='12px' sx={{ ml: -2 }} />
                  ) : null}
                  &nbsp;&nbsp;
                  <ListItemText
                    sx={{ whiteSpace: 'normal' }}
                    primary={
                      <Tooltip title={rowDetails?.itemName} placement='top'>
                        <Typography
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '120px',
                            height: '1.2em',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {rowDetails?.itemName}
                        </Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Box>{`${rowDetails.quantity} ${rowDetails.unit}`}</Box>
                    }
                  />
                </ListItem>
              </TableCell>
              <TableCell
                align='center'
                width={200}
                sx={{ whiteSpace: 'normal' }}
              >
                <Typography>{rowDetails.amount}</Typography>
              </TableCell>
              <TableCell
                align='left'
                width={200}
                key={`noOfItems_${rowDetails.noOfItems}`}
              >
                {!rowDetails.returned ? (
                  <Button
                    variant='outlined'
                    disabled={
                      Validation.isBlankObjectNot(returnOrder)
                        ? returnOrder?.status === 'CONFIRMED' &&
                          !editReturnOrder
                        : ''
                    }
                    sx={{
                      mb: 1,
                      ml: 2,
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => handleReturnOrder()}
                  >
                    Return
                  </Button>
                ) : (
                  <TextField
                    className='number__input'
                    id='noOfItems'
                    sx={{ width: '130px' }}
                    size='small'
                    autoFocus='autoFocus'
                    type='number'
                    disabled={
                      Validation.isBlankObjectNot(returnOrder)
                        ? returnOrder?.status === 'CONFIRMED'
                        : rowDetails?.noOfItems === row?.noOfItems ||
                          rowDetails?.noOfItems === 1
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          disablePointerEvents={
                            returnOrder?.status === 'CONFIRMED' ||
                            rowDetails?.noOfItems === 1
                          }
                          position='start'
                          sx={{
                            mr: 4,
                          }}
                        >
                          <Remove
                            sx={{
                              cursor: 'pointer',
                              color:
                                rowDetails.noOfItems === 1 ||
                                returnOrder.status === 'CONFIRMED'
                                  ? 'lightgrey'
                                  : 'blue',
                            }}
                            fontSize='small'
                            onClick={() => {
                              handleQualtityChange(rowDetails.noOfItems - 1);
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          disablePointerEvents={
                            (returnOrder?.status === 'CONFIRMED' &&
                              !editReturnOrder) ||
                            rowDetails?.noOfItems === row.noOfItems
                          }
                          position='end'
                        >
                          <Add
                            sx={{
                              cursor: 'pointer',
                              color:
                                rowDetails.noOfItems === row.noOfItems ||
                                (returnOrder?.status === 'CONFIRMED' &&
                                  !editReturnOrder)
                                  ? 'lightgrey'
                                  : 'blue',
                            }}
                            fontSize='small'
                            onClick={() => {
                              handleQualtityChange(rowDetails.noOfItems + 1);
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={
                      Validation.isBlankObjectKeyNot(rowDetails, 'noOfItems')
                        ? rowDetails.noOfItems
                        : ''
                    }
                    onChange={event => handleQualtityChange(event.target.value)}
                    variant='standard'
                    name='noOfItems'
                  />
                )}
              </TableCell>
              <TableCell width={200}>
                <Button
                  disabled={
                    Validation.isBlankObjectNot(returnOrder)
                      ? returnOrder.status === 'CONFIRMED' && !editReturnOrder
                      : ''
                  }
                  variant='outlined'
                  sx={{
                    mb: 1,
                    ml: 2,
                    width: '126px',
                    height: '27px',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => cancelRow(rowDetails?.itemNumber)}
                >
                  Cancel
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={canelledDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          This is the Last item in Return Order, Do you want to delete this
          Return Order?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDilaog}>Cancel</Button>
          <Button onClick={deleteRetOrder}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReturnOrderItems;
