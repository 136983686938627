import Auth from '../Auth';
import Customer from '../Customer';
import UpstoreApi from '../UpstoreApi';
import Store from '../Store';
import Validation from '../Validation';
class Category {
  constructor() {
    this.categoryList =
      JSON.parse(localStorage.getItem('storeCategoryList')) || {};
  }

  getCategoryNameById(id) {
    return this.categoryList.find(item => item.categoryId == id);
  }

  getDefaultCategoryId() {
    return this.categoryList.find(item => item.defaultCat === true).categoryId;
  }

  setCategoryList(new_categoryList) {
    this.categoryList = new_categoryList;
    localStorage.setItem('storeCategoryList', JSON.stringify(new_categoryList));
  }

  updateCategoriesLocalStorageData = (data, calledAt) => {
    let prevData = JSON.parse(localStorage.getItem('storeCategoryList'));
    let filteredArray = prevData.filter(
      item => item.categoryId !== data.categoryId
    );

    if (calledAt === 'add') {
      this.setCategoryList([...prevData, data]);
    } else if (calledAt === 'update') {
      // this.setCategoryList([...filteredArray, data]);
      prevData = prevData.map(item =>
        item.categoryId !== data.categoryId ? item : data
      );
      this.setCategoryList([...prevData]);
    } else if (calledAt === 'delete') {
      this.setCategoryList([...filteredArray]);
    } else {
      console.log('something went wrong');
    }
  };

  addCategoryData = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/category',
      data: data,
    })
      .then(response => {
        if (response.data) {
          this.updateCategoriesLocalStorageData(response.data, 'add');
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while add categories', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  getCategoryByStoreId = (storeId, callBack) => {
    let storeCategoriesFromCache = this.getStoreCategoriesFromCache();
    if (Validation.isBlankObjectNot(storeCategoriesFromCache)) {
      callBack({ status: 'success', data: storeCategoriesFromCache });
    } else {
      UpstoreApi({
        method: 'GET',
        url: '/category/store/' + storeId,
      })
        .then(response => {
          this.setCategoryList(response.data);
          Customer.getAllCustomers(Auth.getStoreId(), 0, false, () => {});
          Store.getStoreByStoreId(Auth.getStoreId(), () => {});
          if (response.data) {
            callBack({ status: 'success', data: response.data });
          }
        })
        .catch(error => {
          console.log('Error occure while getting products', error.message);
          callBack({ status: 'error' });
        });
    }
  };

  getStoreCategoriesFromCache = () => {
    if (Validation.isBlankObjectNot(this.categoryList)) {
      return [...this.categoryList];
    }

    let storeCategoriesFromCache = localStorage.getItem('storeCategoryList');
    if (Validation.isBlankObjectNot(storeCategoriesFromCache)) {
      let storeCategoriesList = JSON.parse(storeCategoriesFromCache);
      this.categoryList = [...storeCategoriesList];
      return storeCategoriesList;
    } else {
      return null;
    }
  };

  deleteCategory = (CategoryId, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/category/delete-category/' + CategoryId,
    })
      .then(response => {
        if (response.data) {
          this.updateCategoriesLocalStorageData(
            { categoryId: CategoryId },
            'delete'
          );
          callback({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Deleting category failed', error.message);
        callback({ status: 'error' });
      });
  };

  updateCategory = (categoryId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/category/update-category/' + categoryId,
      data: data,
    })
      .then(response => {
        if (response.data) {
          this.updateCategoriesLocalStorageData(response.data, 'update');

          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting categories by category Id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}
export default new Category();
