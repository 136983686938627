import React, { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import Auth from '../../modules/Auth';
import Customer from '../../modules/Customer';
import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import './styles.css';
import { styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import Store from '../../modules/Store';
const AddEditCustomer = props => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: props.buttonClicked === 'Edit' ? props.editCustomerData : '',
  });
  const [submittedButton, setSubmittedButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(props.openDialog);
  const [selectedCountry, setSelectedCountry] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [address, setAddress] = React.useState({
    country: '',
    state: '',
    city: '',
  });

  // setting value 0 by default in add customer mode
  React.useEffect(() => {
    getAllcountries();
    if (props.buttonClicked !== 'Edit') {
      setValue('openingBalance', 0);
    }
    if (props.buttonClicked === 'Edit') {
      getAllStates(props?.editCustomerData?.country);
      getAllCities(
        props?.editCustomerData?.country,
        props?.editCustomerData?.state
      );
      setAddress({
        ...address,
        country: props?.editCustomerData?.country,
        state: props?.editCustomerData?.state,
        city: props?.editCustomerData?.city,
      });
    }
  }, []);
  let navigateTo = useNavigate();
  const closePopupDialog = () => {
    setOpenDialog(false);
    props.closePopup();
  };

  // for custom styling
  const textField = {
    width: '100%',
    //
  };

  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
  });

  // function for adding new customer
  const onCustomerSubmit = data => {
    setSubmittedButton(true);
    if (props.buttonClicked === 'Add') {
      let addCustomerData = data;
      addCustomerData.city =
        data.city === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().city
          : data.city;
      addCustomerData.country =
        data.country === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().country
          : data.country;
      addCustomerData.state =
        data.state === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().state
          : data.state;
      addCustomerData.createdBy = Auth.getUserLoginId();
      addCustomerData.storeId = Auth.getStoreId();
      addCustomerData.customerStatus = 'NEW';

      Customer.addNewCustomer(addCustomerData, response => {
        if (response.status === 'success') {
          // Customer.reloadCustomers(Auth.getStoreId(), response => {});
          Transaction.log(
            Auth.getStoreId(),
            response.data.id,
            LogMessage.CUSTOMER_ADDED,
            LogMessage.CUSTOMER_ADDED_TITLE,
            [`${response.data.customerName}`, Auth.getUserName()]
          );
          Transaction.log(
            Auth.getStoreId(),
            response.data.id,
            LogMessage.OPENING_BALANCE,
            LogMessage.OPENING_BALANCE_TITLE,
            [
              response.data.openingBalance,
              Auth.getUserName(),
              `${response.data.customerName}`,
            ]
          );
          props.reloadCustomersList(0);
          closePopupDialog();
          setSubmittedButton(false);
          props.snackbar('Customer has been added successfully', 'success');
          // available only when using in create or update order component
          if (props.getNewlyAdderdCustomerDetails) {
            props.getNewlyAdderdCustomerDetails(response.data);
          }
          navigateTo('/customer-details', {
            state: response.data.id,
          });
        } else if (response.data == 416) {
          props.snackbar(`Limit exceeded please upgrade your plan`, 'error');
          setSubmittedButton(false);
        } else {
          props.snackbar('Customer was not added successfully', 'error');
          setSubmittedButton(false);
        }
      });
    }
    if (props.buttonClicked === 'Edit') {
      if (props.type === 'statement') props.getUpdatedLedger(false);
      let editCustomerdata = data;
      editCustomerdata.city =
        data.city === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().city
          : data.city;
      editCustomerdata.country =
        data.country === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().country
          : data.country;
      editCustomerdata.state =
        data.state === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().state
          : data.state;
      editCustomerdata.lastModifiedBy = Auth.getUserLoginId();
      editCustomerdata.deleted = null; //we are using same api for delete and update , so for updating customer we need to send deleted as null.
      Customer.updateCustomer(
        editCustomerdata.id,
        editCustomerdata,
        response => {
          if (response.status === 'success') {
            setSubmittedButton(true);
            Transaction.log(
              Auth.getStoreId(),
              response.data.id,
              LogMessage.CUSTOMER_DETAILS_UPDATED,
              LogMessage.CUSTOMER_UPDATED_TITLE,
              [`${response.data.customerName}`, Auth.getUserName()]
            );
            if (props.fromCustomerDetail) {
              props.forReloadCustomer(response.data.id);
              props.reloadCustomersList(0);
            } else {
              props.reloadCustomersList(0);
            }
            props.snackbar('Customer has been updated successfully', 'success');
            closePopupDialog();
            // props.type === 'statement' ? props.getUpdatedLedger(true) : '';
            if (props.type === 'statement') {
              props.getUpdatedLedger(true);
            }
          } else {
            props.snackbar('Customer was not updated successfully', 'error');
            closePopupDialog();
          }
        }
      );
    }
  };
  const handleChange = e => {
    getAllStates(e.target.value);
    setAddress({
      ...address,
      country: e.target.value,
    });
  };
  const handleStateChange = e => {
    const country = getValues('country');
    getAllCities(country, e.target.value);
    setAddress({
      ...address,
      state: e.target.value,
    });
  };
  const getAllcountries = () => {
    let allcountries = Country.getAllCountries();

    setSelectedCountry(allcountries);
  };
  const getAllStates = code => {
    let states = State.getStatesOfCountry(code);
    setStateList(states);
  };
  const getAllCities = (country, isoCode) => {
    let cities = City.getCitiesOfState(country, isoCode);
    setCityList(cities);
  };
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Dialog open={openDialog} maxWidth='md'>
        <form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit(onCustomerSubmit)}
        >
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant='h4'
                  textAlign='center'
                  className='dialog-title'
                >
                  {props.buttonClicked === 'Edit'
                    ? 'Edit Customer Details'
                    : 'Add New Customer'}
                </Typography>
                <Tooltip title='Close dialog' placment='right'>
                  <Close
                    onClick={closePopupDialog}
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  />
                </Tooltip>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Customer Name
                  </Typography>
                  <StyledTextField
                    {...register('customerName', {
                      required: 'Name is required.',
                    })}
                    sx={textField}
                    size='small'
                    id='customerName'
                    autoComplete='off'
                    placeholder='Enter First Name'
                    variant='outlined'
                    name='customerName'
                    error={Boolean(errors.customerName)}
                    helperText={errors.customerName?.message}
                  />
                </Grid>
                {Store.getSelectedTaxType() === 'GST' &&
                  Store.companyDetails?.businessType !== 'MANDI' && (
                    <Grid item xs={6} sx={{ pt: 1 }}>
                      <Typography variant='subtitle1' className='list-title'>
                        {' '}
                        GST No
                      </Typography>
                      <StyledTextField
                        {...register('gstNo', {
                          pattern: {
                            value:
                              /^\d{2}[A-Z]{5}\d{4}[A-Z][0-9A-Z][Z]{1}[0-9A-Z]$/,
                            message: 'Please enter a valid Gst Number',
                          },
                        })}
                        sx={textField}
                        id='gstNo'
                        autoComplete='off'
                        variant='outlined'
                        size='small'
                        placeholder='Enter GST no'
                        name='gstNo'
                        error={Boolean(errors.gstNo)}
                        helperText={errors.gstNo?.message}
                      />
                    </Grid>
                  )}

                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Email
                  </Typography>
                  <StyledTextField
                    {...register('email', {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Please enter a valid e-mail address',
                      },
                    })}
                    sx={textField}
                    size='small'
                    autoComplete='off'
                    id='email'
                    placeholder='Enter Email'
                    variant='outlined'
                    name='email'
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Contact No
                  </Typography>
                  <StyledTextField
                    sx={textField}
                    {...register('contact', {
                      required: 'Contact no is required',
                    })}
                    size='small'
                    type='number'
                    autoComplete='off'
                    id='contact'
                    placeholder='Enter Number'
                    variant='outlined'
                    name='contact'
                    error={Boolean(errors.contact)}
                    helperText={errors.contact?.message}
                  />
                </Grid>

                <Grid item xs={6} sx={{ pt: 1 }}>
                  <Typography variant='subtitle1' className='list-title'>
                    Whatsapp No
                  </Typography>
                  <StyledTextField
                    {...register('whatsappNo')}
                    sx={textField}
                    id='whatsappNo'
                    autoComplete='off'
                    type='number'
                    variant='outlined'
                    size='small'
                    placeholder='Enter Whatsapp No'
                    name='whatsappNo'
                  />
                </Grid>
                <Grid item xs={6} sx={{ pt: 1 }}>
                  <Typography variant='subtitle1' className='list-title'>
                    Alternate No
                  </Typography>
                  <StyledTextField
                    {...register('alternateNo')}
                    sx={textField}
                    id='alternateNo'
                    type='number'
                    autoComplete='off'
                    variant='outlined'
                    size='small'
                    placeholder='Enter Alternate no'
                    name='alternateNo'
                    error={Boolean(errors.alternateNo)}
                    helperText={errors.alternateNo?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Address
                  </Typography>
                  <StyledTextField
                    {...register('address', {
                      required: 'Address is required',
                    })}
                    multiline
                    minRows={2}
                    autoComplete='off'
                    sx={textField}
                    size='small'
                    id='address'
                    placeholder='Enter Address'
                    variant='outlined'
                    name='address'
                    error={Boolean(errors.address)}
                    helperText={errors.address?.message}
                  />
                </Grid>
                {Store.companyDetails?.businessType !== 'MANDI' && (
                  <>
                    <Grid item xs={6} sx={{ pt: 1 }}>
                      <Typography variant='subtitle1' className='list-title'>
                        Country
                      </Typography>
                      <TextField
                        {...register('country')}
                        sx={textField}
                        id='country'
                        select
                        autoComplete='off'
                        variant='outlined'
                        value={address.country}
                        onChange={handleChange}
                        size='small'
                        placeholder='Select Country '
                        name='country'
                        error={Boolean(errors.country)}
                        helperText={errors.country?.message}
                      >
                        {selectedCountry?.map((item, index) => (
                          <MenuItem value={item?.isoCode} key={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sx={{ pt: 1 }}>
                      <Typography variant='subtitle1' className='list-title'>
                        State
                      </Typography>
                      <TextField
                        {...register('state')}
                        sx={textField}
                        id='state'
                        autoComplete='off'
                        variant='outlined'
                        size='small'
                        select
                        value={address.state}
                        onChange={handleStateChange}
                        placeholder='Select State'
                        name='state'
                        error={Boolean(errors.state)}
                        helperText={errors.state?.message}
                      >
                        {stateList?.map((item, index) => (
                          <MenuItem value={item?.isoCode} key={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sx={{ pt: 1 }}>
                      <Typography variant='subtitle1' className='list-title'>
                        City
                      </Typography>
                      <TextField
                        {...register('city')}
                        sx={textField}
                        id='city'
                        select
                        autoComplete='off'
                        variant='outlined'
                        onChange={e =>
                          setAddress({
                            ...address,
                            city: e.target.value,
                          })
                        }
                        value={address.city}
                        size='small'
                        placeholder='Select City'
                        name='city'
                        error={Boolean(errors.city)}
                        helperText={errors.city?.message}
                      >
                        {cityList?.map((item, index) => (
                          <MenuItem value={item?.name} key={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </>
                )}

                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Opening Balance
                  </Typography>
                  <StyledTextField
                    {...register('openingBalance', {
                      required: 'Opening balance is required',
                    })}
                    sx={textField}
                    size='small'
                    autoComplete='off'
                    id='openingBalance'
                    disabled={
                      props.editCustomerData.customerStatus === 'NEW' ||
                      props.buttonClicked === 'Add'
                        ? false
                        : true
                    }
                    type='number'
                    placeholder='Enter Opening balance'
                    variant='outlined'
                    name='openingBalance'
                    error={Boolean(errors.openingBalance)}
                    helperText={errors.openingBalance?.message}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closePopupDialog}
                className='cancel-button'
                variant='outlined'
                sx={{
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='button-color'
                type='submit'
                value='submit'
                sx={{
                  padding: 1,
                  fontSize: '12px',
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                }}
                disabled={submittedButton}
              >
                {props.buttonClicked === 'Edit'
                  ? 'Update Details'
                  : 'Add New Customer'}
              </Button>
            </DialogActions>
          </>
        </form>
      </Dialog>
    </Box>
  );
};

export default AddEditCustomer;
