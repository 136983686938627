import React, { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  FormHelperText,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import User from '../../modules/User';
import { Close } from '@mui/icons-material';
import Auth from '../../modules/Auth';
import { styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const AddEditUser = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.buttonClicked === 'Edit' ? props.editUserData : '',
  });
  const [submittedButton, setSubmittedButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(props.openDialog);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [InvalidPhone, setInvalidPhone] = React.useState(false);
  const [userRoleId, setUserRoleId] = useState(null);
  const [nullPhone, setNullPhone] = React.useState(false);
  const [nullEmail, setNullEmail] = React.useState(false);
  const [invalidUserName, setInvalidUserName] = React.useState(false);
  const [nullUserName, setNullUserName] = React.useState(false);
  React.useEffect(() => {
    if (props.buttonClicked === 'Edit') {
      setUserRoleId(props.editUserData.userRoleId);
    }
  }, []);

  // for custom styling

  const textField = {
    width: '100%',
    //
  };

  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
  });
  const handleEmailBlurChange = event => {
    let email = event.target.value;

    User.isDistinctEmail(email, response => {
      if (response.data === true) {
        setInvalidEmail(false);
        setNullEmail(false);
      } else if (event.target.value === '') {
        setNullEmail(true);
      } else {
        setInvalidEmail(true);
        setNullEmail(false);
      }
    });
  };
  const handleUserNameBlurChange = event => {
    let userName = event.target.value;

    User.isDistinctUsername(userName, response => {
      if (response.data === true) {
        setInvalidUserName(false);
        setNullUserName(false);
      } else if (event.target.value === '') {
        setNullUserName(true);
      } else {
        setInvalidUserName(true);
        setNullUserName(false);
      }
    });
  };
  //no already exists
  const handlePhoneBlurChange = event => {
    let phone = event.target.value;
    User.isDistinctPhone(phone, response => {
      if (response.data === true) {
        setInvalidPhone(false);
        setNullPhone(false);
      } else if (event.target.value === '') {
        setNullPhone(true);
      } else {
        setInvalidPhone(true);
      }
    });
  };
  const closePopupDialog = () => {
    setOpenDialog(false);
    props.closeDialog();
  };

  // function for adding new vendor
  const onSubmitUser = dat => {
    setSubmittedButton(true);
    let data = {
      address: dat.address,
      phone: dat.phone,
      email: dat.email,
      firstName: dat.firstName,
      lastName: dat.lastName,
      userRoleId: userRoleId,
      storeId: Auth.getStoreId(),
      username: dat.username,
    };

    if (props.buttonClicked == 'Add') {
      if (invalidEmail) {
        props.snackbar('Email already exists', 'error');
        setSubmittedButton(false);
        return;
      }
      if (InvalidPhone) {
        props.snackbar('phone No already exists', 'error');
        setSubmittedButton(false);
        return;
      }
      if (invalidUserName) {
        props.snackbar('User Name already exists', 'error');
        setSubmittedButton(false);
        return;
      }
      User.addNewUser(data, response => {
        if (response.status === 'success') {
          props.snackbar('User Added successfully', 'success');
          setSubmittedButton(false);

          closePopupDialog();
          User.forgotPassword(response.data.email, response => {
            if (response.status === 'success') {
              props.snackbar(
                'Code successfully sent to email for password creation',
                'success'
              );
            }
          });
          props.getAllUsers();
        } else if (response.data == 416) {
          props.snackbar(`Limit exceeded please upgrade your plan`, 'error');
          setSubmittedButton(false);
        } else {
          props.snackbar('User not Added successfully', 'error');
          setSubmittedButton(false);
        }
      });
    }
    if (props.buttonClicked === 'Edit') {
      User.updateUserDetails(props.editUserData.id, data, response => {
        if (response.status === 'success') {
          props.snackbar('User edited successfully', 'success');
          setSubmittedButton(false);

          closePopupDialog();
          props.getAllUsers();
        } else {
          props.snackbar('User not edited successfully', 'error');
          setSubmittedButton(false);
        }
      });
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Dialog open={openDialog} maxWidth='md'>
          <form noValidate onSubmit={handleSubmit(onSubmitUser)}>
            <>
              <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant='h4'
                    textAlign='center'
                    className='dialog-title'
                  >
                    {props.buttonClicked === 'Edit'
                      ? 'Edit User Details'
                      : 'Add New User'}
                  </Typography>
                  <Tooltip placement='right-start' title='clear'>
                    <Close
                      onClick={props.closeDialog}
                      fontSize='medium'
                      sx={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container columnSpacing={4} rowSpacing={1}>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      First Name
                    </Typography>
                    <StyledTextField
                      {...register('firstName', {
                        required: ' Name is required',
                      })}
                      sx={textField}
                      size='small'
                      id='firstName'
                      placeholder='Enter Name'
                      variant='outlined'
                      name='firstName'
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Last Name
                    </Typography>
                    <StyledTextField
                      {...register('lastName', {
                        required: ' Name is required',
                      })}
                      sx={textField}
                      size='small'
                      id='lastName'
                      placeholder='Enter Name'
                      variant='outlined'
                      name='lastName'
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Contact No
                    </Typography>
                    <StyledTextField
                      sx={textField}
                      {...register('phone', {
                        required: 'phone no is required',
                      })}
                      size='small'
                      type='number'
                      id='phone'
                      placeholder='Enter Number'
                      variant='outlined'
                      name='phone'
                      onBlur={handlePhoneBlurChange}
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                    />
                    {InvalidPhone ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Phone number already exists
                      </Typography>
                    ) : nullPhone ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter Phone Number
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Email
                    </Typography>
                    <StyledTextField
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Please enter a valid e-mail address',
                        },
                      })}
                      sx={textField}
                      size='small'
                      type='text'
                      id='email'
                      placeholder='Enter Email'
                      variant='outlined'
                      onBlur={handleEmailBlurChange}
                      name='email'
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />{' '}
                    {invalidEmail ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        '*Email already exists please enter a unique email *'
                      </Typography>
                    ) : nullEmail ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter Email
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography variant='subtitle1' className='list-title'>
                      User Role
                    </Typography>
                    <FormControl sx={textField}>
                      <TextField
                        {...register('userRoleId', {
                          required: 'RoleId is required',
                        })}
                        disabled={props.editUserData.userRoleId === '1'}
                        size='small'
                        select
                        displayEmpty
                        placeholder='Select default role'
                        name='userRoleId'
                        id='userRoleId'
                        variant='outlined'
                        value={
                          props.editUserData.userRoleId === '1'
                            ? 'Admin'
                            : userRoleId
                        }
                        onChange={event => {
                          setUserRoleId(event.target.value);
                        }}
                        error={Boolean(errors.userRoleId)}
                        MenuProps={MenuProps}
                      >
                        <MenuItem disabled value={undefined}>
                          <em style={{ color: 'gray' }}>Select</em>
                        </MenuItem>

                        {props.editUserData.userRoleId !== '1' ? (
                          props.rolesData.map(role => {
                            return (
                              <MenuItem key={role.id} value={role.id}>
                                {role.type}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem key={'1'} value={'Admin'}>
                            Admin
                          </MenuItem>
                        )}
                      </TextField>
                      <FormHelperText sx={{ color: '#c22a1f' }}>
                        {errors.userRoleId?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Address
                    </Typography>
                    <StyledTextField
                      {...register('address', {
                        required: 'Address is required',
                      })}
                      multiline
                      minRows={2}
                      sx={textField}
                      size='small'
                      type='text'
                      id='address'
                      placeholder='Enter Address'
                      variant='outlined'
                      name='address'
                      error={Boolean(errors.address)}
                      helperText={errors.address?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      User Name
                    </Typography>
                    <StyledTextField
                      {...register('username', {
                        required: 'username is required',
                      })}
                      multiline
                      sx={textField}
                      size='small'
                      type='text'
                      id='username'
                      placeholder='Enter userName'
                      variant='outlined'
                      name='username'
                      onBlur={handleUserNameBlurChange}
                      error={Boolean(errors.username)}
                      helperText={errors.username?.message}
                    />
                    {invalidUserName ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *UserName already exists
                      </Typography>
                    ) : nullUserName ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter UserName
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={props.closeDialog}
                  className='cancel-button'
                  variant='outlined'
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  className='button-color'
                  type='submit'
                  value='submit'
                  sx={{
                    padding: 1,
                    fontSize: '14px',
                    width: '126px',
                    height: '27px',
                    textTransform: 'capitalize',
                  }}
                  disabled={submittedButton}
                >
                  {props.buttonClicked === 'Edit'
                    ? 'Update Details'
                    : 'Add New User'}
                </Button>
              </DialogActions>
            </>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default AddEditUser;
