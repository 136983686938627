import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import React from 'react';

function DeleteDialog(props) {
  const [clicked, setClicked] = React.useState(false);
  const [payRemark, setPayRemark] = React.useState('');
  return (
    <Dialog open={props.openDialog} maxWidth='md'>
      <DialogTitle>
        <Typography variant='h4' textAlign='center'>
          Delete Alert !
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' textAlign='start'>
          {props.dialogContent}
        </Typography>
        {props.reason ? (
          <>
            <Typography
              variant='h6'
              sx={{
                fontSize: '14px',
                pt: 1,
                color: payRemark === '' ? 'red' : null,
              }}
              textAlign='start'
            >
              Enter Remark*
            </Typography>
            <TextField
              size='small'
              placeholder='Enter remark'
              required
              fullWidth={true}
              sx={{ mt: 0.3 }}
              name='remark'
              type='remark'
              id='remark'
              autoComplete='off'
              onChange={event => {
                setPayRemark(event.target.value);
              }}
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          varient='outlined'
          sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
          onClick={() => props.closeDialogCallback()}
        >
          Cancel
        </Button>
        {props.reason ? (
          <Button
            varient='outlined'
            disabled={clicked}
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => {
              {
                if (payRemark === '')
                  props.snackbar('remark is mandatory to fill', 'error');
                else {
                  setClicked(true);
                  props.deleteCallback(true, payRemark);
                  props.snackbar('Payment Deleted Successfully', 'success');
                }
              }
            }}
          >
            Delete
          </Button>
        ) : (
          <Button
            varient='outlined'
            disabled={clicked}
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => {
              setClicked(true);
              props.deleteCallback(true);
            }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
