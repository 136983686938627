import UpstoreApi from '../UpstoreApi';

class Expense {
  addExpensesList = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/expenses`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding new expenses', error.message);
        callBack({ status: 'error' });
      });
  };
  addBulkExpensesList = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/expenses/addListOfExpenses `,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding new expenses', error.message);
        callBack({ status: 'error' });
      });
  };
  getAllExpensesByStoreId = (storeId, pageNumber, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `expenses/getAllExpense/${storeId}?pageNumber=${pageNumber}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting expenses', error.message);
        callBack({ status: 'error' });
      });
  };
  addRepeatExpenses = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/repeater/add`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while adding respaet expenses',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getRepeatExpensesById = (expenseId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `repeater/get-by-expenseId/${expenseId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting repeat expenses',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateRepeatExpense = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/repeater/update/${id}`,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log(
          'erorr occured while updating expenses Expenses ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  //update Expense
  updateExpense = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `expenses/updateExpense/${id}`,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('erorr occured while updating  Expenses ', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteExpense = (id, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/expenses/deleteExpense/' + id,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Deleting Expenses failed', error.message);
        callback({ status: 'error' });
      });
  };

  getExpenseStatementByStoreId = (
    storeId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/expenses/${storeId}/statement-expenses/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log('Error occure while getting expense Report', error.message);
        callBack({ status: 'error' });
      });
  };
}

export default new Expense();
