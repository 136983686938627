import React from 'react';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import * as moment from 'moment';
import Auth from '../../modules/Auth';
import { Loading } from '../../component/loading/Loading';
import Order from '../../modules/Order';
import Payments from '../../modules/Payments';
import CheckBalanceDashboard from '../../component/checkBalanceDashboard/CheckBalanceDashboard';
import OutStandingBalance from '../../component/outStandingBalance/OutStandingBalance';
import './style.css';
import Store from '../../modules/Store';
import DashboardCards from '../../component/dashboardCards/DashboardCards';
import Validation from '../../modules/Validation';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import PrintIcon from '@mui/icons-material/Print';
function Dashboard() {
  const [userType, setUserType] = React.useState('');
  const [salesWeeklyPrice, setSalesWeeklyPrice] = React.useState(null);
  const [salesMonthlyPrice, setSalesMonthlyPrice] = React.useState(null);
  const [dailyOrders, setDailyOrders] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [weeklyOrders, setWeeklyOrders] = React.useState(null);
  const [monthlyOrders, setMonthlyOrders] = React.useState(null);
  const [salesDailyPrice, setSalesDailyPrice] = React.useState(null);
  const [paymentsMonthly, setPaymentsMonthly] = React.useState(null);
  const [weeklyPayments, setWeeklyPayments] = React.useState(null);
  const [dailyPayments, setDailyPayments] = React.useState(null);
  const [currency, setCurrency] = React.useState(null);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    if (window.location.hash.endsWith('/customer')) {
      setUserType('customer');
      getCurrency();
      getTotalSales();
      getTotalSalesMonthly();
      getDailySales();
      getDailyStoreOrders();
      getWeeklyStoreOrders();
      getMonthlyStoreOrders();
      getTotalPaymentsMonthly('customer');
      getTotalPaymentsWeekly('customer');
      getTotalPaymentsDaily('customer');
    } else {
      setUserType('vendor');
      getCurrency();
      getWeeklyVendorSales();
      getMonthlyVendorSales();
      getDailyVendorSales();
      getDailyVendorStoreOrders();
      getWeeklyVendorStoreOrders();
      getMonthlyVendorStoreOrders();
      getTotalPaymentsMonthly('vendor');
      getTotalPaymentsWeekly('vendor');
      getTotalPaymentsDaily('vendor');
    }
  }, []);
  let newDate = new Date();

  const todaysDate = moment(newDate).format('YYYY-MM-DD');
  const todaysDateP = moment(newDate).format('DD-MMM-YY');

  // let dailyEndDate = newDate.setDate(newDate.getDate() + 1);
  // dailyEndDate = moment(dailyEndDate).format('YYYY-MM-DD');

  newDate = new Date();
  let weeklyStartDate1 =
    newDate.getDate() - newDate.getDay() + (newDate.getDay() === 0 ? -6 : 1);
  weeklyStartDate1 = new Date(newDate.setDate(weeklyStartDate1));
  let weeklyStartDate = moment(weeklyStartDate1).format('YYYY-MM-DD');
  let weeklyStartDateP = moment(weeklyStartDate).format('DD-MMM-YY');
  newDate = new Date();

  let monthlyStartDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

  monthlyStartDate = moment(monthlyStartDate).format('YYYY-MM-DD');
  let monthlyStartDateP = moment(monthlyStartDate).format('DD-MMM-YY');
  //get payments monthly

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };
  const getTotalPaymentsMonthly = userType => {
    Payments.getTotalPaymentPriceByStoreId(
      Auth.getStoreId(),
      monthlyStartDate,
      todaysDate,
      userType,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setPaymentsMonthly(response.data);

          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };

  //get daily payments
  const getTotalPaymentsDaily = userType => {
    Payments.getTotalPaymentPriceByStoreId(
      Auth.getStoreId(),
      todaysDate,
      todaysDate,
      userType,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setDailyPayments(response.data);
        } else {
          setDailyPayments([]);
        }
      }
    );
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  //get weekly payments
  const getTotalPaymentsWeekly = userType => {
    setLoader(true);
    console.log(Store.getStoreTimeZone());
    Payments.getTotalPaymentPriceByStoreId(
      Auth.getStoreId(),
      weeklyStartDate,
      todaysDate,
      userType,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setWeeklyPayments(response.data);
          setLoader(false);
        } else {
          setWeeklyPayments([]);
        }
      }
    );
  };
  //get weekly sales
  const getTotalSales = () => {
    Order.getTotalStorePriceByStoreId(
      Auth.getStoreId(),
      weeklyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response?.data?.toFixed(1) : 0;
          setSalesWeeklyPrice(data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get total sales monthly
  const getTotalSalesMonthly = () => {
    Order.getTotalStorePriceByStoreId(
      Auth.getStoreId(),
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response?.data?.toFixed(1) : 0;
          setSalesMonthlyPrice(data);
        } else {
          console.log('error');
        }
      }
    );
  };

  //get daily sales
  const getDailySales = () => {
    Order.getTotalStorePriceByStoreId(
      Auth.getStoreId(),
      todaysDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response?.data?.toFixed(1) : 0;
          setSalesDailyPrice(data);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get weekly orders
  const getWeeklyStoreOrders = () => {
    setLoader(true);

    Order.getTotalStoreOrdersByStoreId(
      Auth.getStoreId(),
      weeklyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setWeeklyOrders(response.data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get monthly orders
  const getMonthlyStoreOrders = () => {
    Order.getTotalStoreOrdersByStoreId(
      Auth.getStoreId(),
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setMonthlyOrders(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get daily orders by store id
  const getDailyStoreOrders = () => {
    Order.getTotalStoreOrdersByStoreId(
      Auth.getStoreId(),
      todaysDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setDailyOrders(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };
  //weekly sales for vendor
  const getWeeklyVendorSales = () => {
    setLoader(true);
    Order.getTotalPurchaseByStoreId(
      Auth.getStoreId(),
      weeklyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response.data.toFixed(1) : 0;
          setSalesWeeklyPrice(data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };

  //daily sales for vendor
  const getDailyVendorSales = () => {
    setLoader(true);
    Order.getTotalPurchaseByStoreId(
      Auth.getStoreId(),
      todaysDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response.data.toFixed(1) : 0;
          setSalesDailyPrice(data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };
  //monthly sales for vendor
  const getMonthlyVendorSales = () => {
    setLoader(true);
    Order.getTotalPurchaseByStoreId(
      Auth.getStoreId(),
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let data = response.data ? response.data.toFixed(1) : 0;
          setSalesMonthlyPrice(data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get daily orders by store id
  const getDailyVendorStoreOrders = () => {
    setLoader(true);
    Order.getTotalStoreVendorOrdersByStoreId(
      Auth.getStoreId(),
      todaysDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setDailyOrders(response.data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get weekly orders by store id
  const getWeeklyVendorStoreOrders = () => {
    setLoader(true);
    Order.getTotalStoreVendorOrdersByStoreId(
      Auth.getStoreId(),
      weeklyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setWeeklyOrders(response.data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };

  //get monthly orders by store id
  const getMonthlyVendorStoreOrders = () => {
    setLoader(true);
    Order.getTotalStoreVendorOrdersByStoreId(
      Auth.getStoreId(),
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setMonthlyOrders(response.data);
          setLoader(false);
        } else {
          console.log('error');
        }
      }
    );
  };

  let DailyData = [
    {
      label: 'Total Sales',
      value: salesDailyPrice,
      showCurrency: true,
    },
    {
      label: 'Orders',
      value: dailyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: dailyPayments,
      showCurrency: true,
    },
  ];
  let WeeklyData = [
    {
      label: 'Total Sales',
      value: salesWeeklyPrice,
      showCurrency: true,
    },
    {
      label: 'Orders',
      value: weeklyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: weeklyPayments,
      showCurrency: true,
    },
  ];
  let MonthlyData = [
    {
      label: 'Total Sales',
      value: salesMonthlyPrice,
      showCurrency: true,
    },
    {
      label: 'Orders',
      value: monthlyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: paymentsMonthly,
      showCurrency: true,
    },
  ];
  let DailyVendorData = [
    {
      label: 'Purchase',
      value: salesDailyPrice,
      showCurrency: true,
    },
    {
      label: 'Purchase Orders',
      value: dailyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: dailyPayments,
      showCurrency: true,
    },
  ];
  let WeeklyVendorData = [
    {
      label: 'Purchase',
      value: salesWeeklyPrice,
      showCurrency: true,
    },
    {
      label: 'Purchase Orders',
      value: weeklyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: weeklyPayments,
      showCurrency: true,
    },
  ];
  let MonthlyVendorData = [
    {
      label: 'Purchase',
      value: salesMonthlyPrice,
      showCurrency: true,
    },
    {
      label: 'Purchase Orders',
      value: monthlyOrders,
      showCurrency: false,
    },
    {
      label: 'Payments',
      value: paymentsMonthly,
      showCurrency: true,
    },
  ];

  return (
    <>
      <div>
        <Header />

        {loader ? (
          <Loading />
        ) : (
          <Box sx={{ mt: 6, ml: 8, height: '100%', overflowX: 'hidden' }}>
            <Grid md={12} sm={12} xs={12}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginRight: '1rem',
                  marginTop: '10px',
                }}
              >
                <BreadCrumb pageName='Dashboard' />
                <Tooltip title='Print Dashboard'>
                  <PrintIcon
                    onClick={() => {
                      window.print();
                    }}
                    style={{ marginRight: '1rem' }}
                    // className='ml-3'
                  />
                </Tooltip>
              </Box>
            </Grid>

            {userType === 'customer' ? (
              <Grid
                container
                sx={{ backgroundColor: '#fff', pb: 3, p: { xs: 2 } }}
              >
                <DashboardCards
                  type='DAILY'
                  Data={DailyData}
                  userType={userType}
                  currency={currency}
                />
                <DashboardCards
                  type='Weekly'
                  Data={WeeklyData}
                  userType={userType}
                  currency={currency}
                />
                <DashboardCards
                  type='Monthly'
                  Data={MonthlyData}
                  userType={userType}
                  currency={currency}
                />
              </Grid>
            ) : (
              <Grid container sx={{ backgroundColor: '#fff', pb: 3 }}>
                <DashboardCards
                  type='DAILY'
                  Data={DailyVendorData}
                  userType={userType}
                  currency={currency}
                />
                <DashboardCards
                  type='Weekly'
                  Data={WeeklyVendorData}
                  userType={userType}
                  currency={currency}
                />
                <DashboardCards
                  type='Monthly'
                  Data={MonthlyVendorData}
                  userType={userType}
                  currency={currency}
                />
              </Grid>
            )}
            <Grid container>
              <Grid item md={7} sm={12} xs={12}>
                <OutStandingBalance
                  snackbar={openUpstoreSnackBar}
                  userType={userType}
                />
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                sx={{ backgroundColor: '#fff', mt: 1 }}
              >
                <CheckBalanceDashboard userType={userType} />
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      {AllPermissions.getUserAccessiblePermissions('Dashboard') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Dashboard').read ===
        false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
}

export default Dashboard;
