import UpstoreApi from '../UpstoreApi';

class Invoice {
  getInvoiceForOrder = (orderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `invoice/${orderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
        if (response.data === '') {
          callBack({ status: 'Empty', data: 'empty' });
          return response.data;
        }
        return response.data;
      })
      .catch(error => {
        console.log(
          `Error While Getting Invoice `,

          error.message
        );
      });
  };
}
export default new Invoice();
