import { WindowSharp } from '@mui/icons-material';
import { serverConfig } from '../../config/server-config';
import UpstoreApi from '../UpstoreApi';

class Agent {
  getAllAgentsByStoreId = (storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `user/get-agents/${storeId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting Agents', error.message);
        callBack({ status: 'error' });
      });
  };
  getAgentOrders = (agentId, status, pageNumber, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `agent-delivery-status/get-orders-by-agent-id/${agentId}/${status}?${pageNumber}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting order by agentid',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateOrderDeliveryStatus = (agentData, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `orderDeliveryStatus/update-by-orderid/${id}`,
      data: agentData,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while updating status for order',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  assignAgentByAgentId = (agentData, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `orderDeliveryStatus/add`,
      data: agentData,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting customers', error.message);
        callBack({ status: 'error' });
      });
  };
  getOrderDeliveryStatus = (orderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `orderDeliveryStatus/get-by-orderid/${orderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting order Status by order id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getAddressByAddressId = (addressId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `address/getaddress/${addressId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting address by address id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getReturnOrdersForAgent = (agentId, storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `orderDeliveryStatus/getByAgentIdAndStoreId/${agentId}/${storeId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting return orders for agent',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new Agent();
