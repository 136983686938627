import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

export default function PrintBarcode({
  handleOpen,
  handleClose,
  values,
  source,
  barcodeValue,
  price,
}) {
  let componentRef = React.useRef(null);

  return (
    <Dialog open={handleOpen} onClose={handleClose} maxWidth='md'>
      <DialogTitle>
        <ReactToPrint
          pageStyle={`@page {  margin: '4mm'; }`}
          trigger={() => {
            return (
              <Button
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  bgcolor: '#3B78C9',
                  width: '126px',
                  height: '27px',
                }}
              >
                <PrintIcon sx={{ color: '#fff' }} /> Print
              </Button>
            );
          }}
          content={() => componentRef}
        />
      </DialogTitle>

      <DialogContent>
        <div
          id='divToPrint'
          ref={el => (componentRef = el)}
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {[...Array(Number(values))].map((_, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '5px',
              }}
            >
              <img
                key={`${Math.random()}`}
                src={`${source}`}
                alt='BarCode'
                style={{
                  height: '50px',
                  width: '115px',
                  objectFit: 'contain',
                }}
              />
              <small
                style={{
                  fontSize: '10px',

                  marginLeft: '17px',
                }}
              >
                {barcodeValue}
              </small>
              <small
                style={{
                  fontSize: '10px',
                  marginLeft: '17px',
                }}
              >
                Price: {price}
              </small>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
