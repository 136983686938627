import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import theme from './modules/theme';
import Products from './pages/products/Products';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import Auth from './modules/Auth';
import Categories from './pages/catagories/Catagories';
import ProductDetails from './pages/productDetails/ProductDetails';
import Customers from './pages/customers/Customers';
import Orders from './pages/orders/Orders';
import CustomerDetails from './pages/customerDetails/CustomerDetails';
import HomeStore from './pages/homeStore/HomeStore';
import Dashboard from './pages/dashboard/Dashboard';
import UserProfile from './pages/userProfile/UserProfile';
import PaymentsList from './pages/paymentsList/PaymentsList';
import UserSetUp from './pages/userSetUp/UserSetUp';
import Vendors from './pages/vendors/Vendors';
import PurchaseBill from './pages/purchaseBill/PurchaseBill';
import VendorDetais from './pages/vendorDetails/VendorDetails';
import PurchaseOrder from './pages/purchaseOrder/PurchaseOrder';
import PurchaseLedger from './pages/purchaseLedger/PurchaseLedger';
import Expenses from './pages/expenses/Expenses';
import BulkExpenses from './pages/bulkExpenses/BulkExpenses';
import MonitoringTool from './pages/monitoringTool/MonitoringTool';
import PopularSearches from './pages/popularSearches/PopularSearches';
import BannersView from './pages/banners/BannersView';
import './modules/UpStoreHotkeys';
import Properties from './component/properties/Properties';
import { Loading } from './component/loading/Loading';
import AgentView from './pages/agent/AgentView';
import EstoreOrders from './pages/estoreOrders/EstoreOrders';
import ReturnOrders from './pages/returnOrderEstore/ReturnOrders';
import CreateOrUpdateOrder from './pages/createOrUpdateOrder/CreateOrUpdateOrder';
import Configuration from './pages/configuration/Configuration';
import DefaultConfig from './component/defaultConfig/DefaultConfig';
import Templates from './pages/templates/Templates';
import NewChalan from './pages/newchalan/NewChalan';
import MandiChalanList from './pages/mandiChalan/MandiChalan';
import MandiWatak from './pages/mandiWatak/MandiWatak';
import WatakDetails from './pages/updateWatak/UpdateWatak';
import moment from 'moment';
import DayBook from './pages/mandiDaybook/DayBook';
import AllPermissions from './modules/AllPermissions';
import AccountsSection from './pages/accountsSection/AccountsSection';

function App() {
  useEffect(() => {
    if (Auth.isAuthenticated()) {
      const interval = setInterval(function () {
        reloadLogicImplementation();
      }, 60000);

      return () => clearInterval(interval);
    }
  }, []);

  window.onload = () => {
    AllPermissions.setUserAccessiblePermissions(Auth.getUserLoginId());
  };
  const reloadLogicImplementation = () => {
    const AuthData = JSON.parse(localStorage.getItem('Auth'));

    const reloadTime = new Date(AuthData.reloadTime).toLocaleString();
    const currentTime = moment(new Date(), 'MM-DD-YYYY HH:mm');

    // ** Checking whether current time is greater then equal to reload time
    if (new Date(currentTime) > new Date(reloadTime)) {
      Auth.refreshToken(response => {
        if (response.status === 'success') {
          let newAuthData = {
            ...AuthData,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
          };
          localStorage.setItem('ReloadTime', reloadTime);
          localStorage.setItem('refreshTokenTime', newAuthData.refreshToken);
          // setting localStorage with newAuthData (updated data)
          Auth.setUserToken(newAuthData, false);
        } else {
          console.log('error');
        }
      });
    }
  };

  return (
    <div className='App' style={{ backgroundColor: '#fff' }}>
      <CssBaseline />
      <MUIThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={<Loading />}>
            <Routes>
              {!Auth.isAuthenticated() ? (
                <Route path='*' element={<Navigate to={'/'} />} />
              ) : (
                <>
                  <Route path='/categories' element={<Categories />} />
                  <Route path='/products' element={<Products />} />
                  <Route path='/product-details' element={<ProductDetails />} />
                  <Route path='/customers' element={<Customers />} />
                  <Route path='/vendors' element={<Vendors />} />
                  <Route path='/vendor-details' element={<VendorDetais />} />
                  <Route path='/neworders' element={<CreateOrUpdateOrder />} />
                  <Route path='/configuration' element={<Configuration />} />
                  <Route path='/default-config' element={<DefaultConfig />} />

                  <Route
                    path='/customer-details'
                    element={<CustomerDetails />}
                  />
                  <Route path='/orders' element={<Orders />} />
                  <Route path='/estore-orders' element={<EstoreOrders />} />
                  <Route path='/store' element={<HomeStore />} />

                  <Route
                    path='/dashboard/customer'
                    element={<Dashboard key='customer' />}
                  />
                  <Route
                    path='/dashboard/vendor'
                    element={<Dashboard key='vendor' />}
                  />
                  <Route path='/user-profile' element={<UserProfile />} />
                  <Route
                    path='/payments/customer'
                    element={<PaymentsList key='customer-payments' />}
                  />
                  <Route
                    path='/payments/vendor'
                    element={<PaymentsList />}
                    key='vendor-payments'
                  />

                  <Route path='/user-setup' element={<UserSetUp />} />
                  <Route path='/templates' element={<Templates />} />
                  <Route path='/purchase-bill' element={<PurchaseBill />} />
                  <Route path='/purchase-order' element={<PurchaseOrder />} />
                  <Route path='/purchase-ledger' element={<PurchaseLedger />} />
                  <Route path='/bulkexpenses' element={<BulkExpenses />} />
                  <Route path='/new-chalan' element={<NewChalan />} />
                  <Route path='/mandi-chalan' element={<MandiChalanList />} />
                  <Route path='/mandi-watak' element={<MandiWatak />} />
                  <Route path='/watak-details' element={<WatakDetails />} />
                  <Route path='/daybook' element={<DayBook />} />
                  <Route path='/accounts' element={<AccountsSection />} />

                  <Route
                    path='/Popular-Searches'
                    element={<PopularSearches />}
                  />
                  <Route path='/expenses' element={<Expenses />} />
                  <Route path='/agent' element={<AgentView />} />
                  <Route path='/returnOrder' element={<ReturnOrders />} />
                  {Auth.getUserRoleId() && Auth.getUserRoleId() == -1 ? (
                    <Route
                      path='/monitoring-tool'
                      element={<MonitoringTool />}
                    />
                  ) : null}
                  <Route path='/Banners' element={<BannersView />} />
                  <Route path='/properties' element={<Properties />} />
                </>
              )}
              <Route path='/' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='*' element={<PageNotFound />} />

              <Route
                key='forgot-password'
                path='/forgot-password'
                element={<ForgotPassword />}
              />
            </Routes>
          </React.Suspense>
        </ThemeProvider>
      </MUIThemeProvider>
    </div>
  );
}

export default App;
