import React from 'react';
import Header from '../../component/header/Header';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Avatar,
  Switch,
  Badge,
  InputAdornment,
  ListItemText,
} from '@mui/material';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Validation from '../../modules/Validation';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../modules/Logo';
import BannerUpload from '../../component/bannerUpload/BannerUpload';
import UploadLogo from '../../component/uploadLogo/UploadLogo';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SignatureUpload from '../../component/signatureUpload/SignatureUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Country, State, City } from 'country-state-city';
import './style.css';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { useNavigate } from 'react-router-dom';
import UpstoreProperties from '../../modules/UpstoreProperties';
import moment from 'moment-timezone';
import { serverConfig } from '../../config/server-config';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';
import banner from '../../assets/my-banner.png';

function HomeStore() {
  const navigateTo = useNavigate();

  const [storeList, setStoreList] = React.useState({
    panNo: undefined,
    gstNo: undefined,
  });
  const [companyDetail, setCompanyDetail] = React.useState(false);
  const [invoiceDetails, setInvoiceDetails] = React.useState(false);
  const [regionAndConfigDetails, setRegionAndConfigDetails] =
    React.useState(false);

  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [editable, setEditable] = React.useState({
    priceEditable: false,
    discountEditable: false,
    taxEditable: false,
    includeTax: false,
  });
  const [logoUrl, setLogoUrl] = React.useState();
  const [bannerUrl, setBannerUrl] = React.useState();
  const [signatureUrl, setSignatureUrl] = React.useState();
  const [textCopied, setTextCopied] = React.useState(false);
  const [currency, setCurrency] = React.useState([]);
  const [taxType, setTaxType] = React.useState([]);
  const [idleScreenTime, setIdleScreenTime] = React.useState('3600000');
  const [refreshTokenTimeData, setRefreshTokenTimeData] = React.useState(null);
  const [selectedCountryTimezones, setSelectedCountryTimezones] =
    React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [isValidGst, setIsValidGst] = React.useState(false);
  const [isValidPan, setIsValidPan] = React.useState(false);
  const [taxTypeDesc, setTaxTypeDesc] = React.useState([]);

  React.useEffect(() => {
    getStoreById();
    getLogoUrl();
    getBannerUrl();
    getSignatureUrl();
    getCurrency();
    getStoreTaxType();
    getAllcountries();
    getTaxDescription();
    setIsValidGst(validateGSTNumber(storeList.gstNo));
    setIsValidPan(validatePANNumber(storeList.panNo));

    UpstoreProperties.getUpstoreProperties(
      Auth.getStoreId(),
      false,
      response => {
        if (response.status === 'success') {
          let data = response?.data?.filter(item => {
            return item.property === 'REFRESH_TOKEN_TIMEOUT';
          })[0];
          setIdleScreenTime(data?.propertyValue);
          setRefreshTokenTimeData(data);
        } else {
          console.log('error');
        }
      }
    );
  }, []);

  const getStoreById = () => {
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setStoreList({
          ...response.data,
          country: response.data.country,
          city: response.data.city,
          currency: response.data.currency,
          state: response.data.state,
          timeZone: response.data.timeZone,
        });
        getAllStates(response.data.country);
        getAllCities(response.data.country, response.data.state);
        setEditable({
          ...editable,
          taxEditable: response.data.taxEditable,
          storePriceEditable: response.data.storePriceEditable,
          includeTax: response.data.includeTax,
          discountEditable: response.data.discountEditable,
        });

        if (response?.data?.country) {
          getTimeZones(response?.data?.country);
        }
      } else {
        setStoreList([]);
      }
    });
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const getCurrency = () => {
    Store.getStoreCurrency(response => {
      if (response.status == 'success') {
        setCurrency(response.data);
      } else {
        setCurrency('');
      }
    });
  };

  const getStoreTaxType = () => {
    Store.getStoreTaxType(response => {
      if (response.status == 'success') {
        setTaxType([...response.data, { taxType: 'N/A' }]);
      } else {
        setTaxType('');
      }
    });
  };
  const getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      setLogoUrl(Logo.getLogoUrl(Auth.getStoreId()));
    } else {
      setLogoUrl(logo);
    }
  };

  const getBannerUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      setBannerUrl(Logo.getBannerUrl());
    } else {
      setBannerUrl(banner);
    }
  };

  const getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      setSignatureUrl(Logo.getSignatureUrl());
    } else {
      setSignatureUrl(signature);
    }
  };

  const handleOnchange = event => {
    let { name, value } = event.target;
    if (name === 'gstNo') {
      let validGst = validateGSTNumber(value);
      setIsValidGst(validGst);
      setStoreList({ ...storeList, gstNo: value });
    }
    if (name === 'panNo') {
      let isValid = validatePANNumber(value);
      setIsValidPan(isValid);
      setStoreList({ ...storeList, panNo: value });
    }
    if (name === 'state') {
      setStoreList({ ...storeList, state: value });
      getAllCities(storeList.country, value);
    } else {
      setStoreList({ ...storeList, [name]: value });
    }
  };

  const saveCompanyDetails = () => {
    setCompanyDetail(false);
    setRegionAndConfigDetails(false);
    let updatedData = {
      ...storeList,
      taxEditable: editable.taxEditable,
      storePriceEditable: editable.storePriceEditable,
      discountEditable: editable.discountEditable,
      includeTax: editable.includeTax,
    };

    if (
      (storeList?.gstNo?.length > 0 && isValidGst === false) ||
      (storeList?.panNo?.length > 0 && isValidPan === false)
    ) {
      setRegionAndConfigDetails(true);
      openUpstoreSnackBar('Please Enter Valid Pan Or Gst Details  ', 'error');
      return;
    }
    if (storeList?.taxType === 'GST' && !storeList.gstNo) {
      setRegionAndConfigDetails(true);
      openUpstoreSnackBar('Please Enter GST NUmber    Details  ', 'error');
      return;
    }
    Store.updateStoreDetails(Auth.getStoreId(), updatedData, response => {
      if (response.status == 'success') {
        openUpstoreSnackBar('Company Details Updated successfully', 'success');
      } else {
        setCompanyDetail(true);
        setRegionAndConfigDetails(true);
        console.log('error');
      }
    });
  };

  const handleCompanydetail = e => {
    setCompanyDetail(e.target.checked);
  };

  const regionAndConfig = e => {
    setRegionAndConfigDetails(e.target.checked);
  };

  const handleInvoiceEdit = () => {
    if (invoiceDetails === false) {
      setInvoiceDetails(true);
    } else {
      setInvoiceDetails(false);
    }
  };

  const submitKey = async () => {
    setTextCopied(true);
    await navigator.clipboard.writeText(Auth.getRegistrationKey());
    openUpstoreSnackBar(' text Copied !', 'gray');
  };

  const handleIdleScreenChange = event => {
    setIdleScreenTime(event.target.value);
    if (refreshTokenTimeData) {
      let data = {
        ...refreshTokenTimeData,
        propertyValue: event.target.value,
      };
      UpstoreProperties.updateUpstoreProperty(data.id, data, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar(
            'Idle Screen time Updated Sucessfully',
            'success'
          );
        } else {
          openUpstoreSnackBar(
            'Idle Screen time  not Added Sucessfully',
            'error'
          );
        }
      });
    } else {
      let data = {
        property: 'REFRESH_TOKEN_TIMEOUT',
        propertyDescription: 'Refresh token added successfully',
        propertyValue: event.target.value,
        readOnly: true,
        storeId: Auth.getStoreId(),
      };

      UpstoreProperties.addUpstoreProperty(data, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('Idle Screen time Added Sucessfully', 'success');
        } else {
          openUpstoreSnackBar(
            'Idle Screen time not Added successfully ',
            'error'
          );
        }
      });
    }
  };
  const handlePincode = event => {
    event.preventDefault();
    setStoreList({
      ...storeList,
      timeZone: event.target.value,
    });
  };

  // Get the GMT Country region  for the timezone
  const getTimeZones = val => {
    const allTimezones = moment?.tz?.zonesForCountry(val, true);

    const newList = allTimezones?.map(item => {
      let Gmt = moment()?.tz(item?.name)?.format('Z');
      return {
        ...item,
        offset: `GMT${Gmt}`,
      };
    });
    setSelectedCountryTimezones(newList);
  };
  const getAllcountries = () => {
    let allcountries = Country.getAllCountries();
    setSelectedCountry(allcountries);
  };

  const handleCountryChange = e => {
    e.preventDefault();
    setStoreList({
      ...storeList,
      country: e.target.value,
    });
    getTimeZones(e.target.value);
    getAllStates(e.target.value);
  };
  const validateGSTNumber = gstNumber => {
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][0-9A-Z][Z]{1}[0-9A-Z]$/;
    let value = gstNumber?.match(gstRegex);
    if (value) {
      return true;
    } else if (value === null) {
      return false;
    }
  };
  const validatePANNumber = panNumber => {
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    let value = panNumber?.match(panRegex);
    if (value) {
      return true;
    } else if (value === null) {
      return false;
    }
  };

  const getTaxDescription = () => {
    Store.getTaxType(response => {
      if (response.status === 'success') {
        setTaxTypeDesc(response.data);
      } else {
        setTaxType([]);
      }
    });
  };

  const getAllStates = code => {
    let states = State.getStatesOfCountry(code);
    setStateList(states);
  };
  const getAllCities = (country, isoCode) => {
    let cities = City.getCitiesOfState(country, isoCode);
    setCityList(cities);
  };

  return (
    <>
      <Header pageName='Home Store' />
      <Box sx={{ mt: 6, ml: { md: 7, sm: 8, xs: 6 } }}>
        <Grid container sx={{ minHeight: '400px' }}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ position: 'relative', width: '100%', top: 0, left: 0 }}
          >
            <Badge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              style={{
                width: '100%',
                height: '300px',
              }}
              badgeContent={
                <BannerUpload
                  snackbar={openUpstoreSnackBar}
                  getBannerData={() => getBannerUrl()}
                />
              }
            >
              <Avatar
                alt={'Banner'}
                src={bannerUrl}
                variant='square'
                sx={{
                  width: { md: '100%', sm: '100%', xs: '100%' },
                  height: '300px',
                  bgcolor: 'darkGray',

                  // position: 'relative',
                }}
              />
            </Badge>

            <IconButton sx={{ position: 'absolute', bottom: 60, left: 10 }}>
              <Badge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <UploadLogo
                    logo='logo'
                    snackbar={openUpstoreSnackBar}
                    getFreshData={() => getLogoUrl()}
                  />
                }
              >
                <Avatar
                  alt={'Logo'}
                  src={logoUrl}
                  style={{
                    width: '120px',
                    height: '120px',
                  }}
                />
              </Badge>
            </IconButton>
            <Box display='flex' justifyContent='flex-start'></Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          sx={{ pl: 2, pr: 2, backgroundColor: '#fff' }}
        >
          <Grid item lg={3} md={3} sm={6} xs={12} sx={{ mt: 1 }}>
            <Typography variant='BUTTON TEXT'>Registration Key</Typography>

            <TextField
              sx={{ mt: 1 }}
              id='brand'
              fullWidth={true}
              size='small'
              variant='outlined'
              name='registrationKey'
              value={Auth.getRegistrationKey()}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CopyAllIcon
                      color='primary'
                      sx={{
                        cursor: 'pointer',
                        color: textCopied ? '#808080' : '#3B78C9',
                      }}
                      onClick={submitKey}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} sx={{ mt: 1 }}>
            <Typography variant='BUTTON TEXT'>
              Select Idle Screen Time
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='outlined-select-city'
              select
              size='small'
              variant='outlined'
              autoCompelete='off'
              name='IdleScreenTimeOut'
              value={idleScreenTime}
              onChange={handleIdleScreenChange}
            >
              <MenuItem value={3600000}>1 hour</MenuItem>

              <MenuItem value={1200000}>20 mins</MenuItem>

              <MenuItem value={1800000}>30 mins</MenuItem>
            </TextField>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12} sx={{ mt: 5 }}>
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                color: 'white',
                bgcolor: '#3B78C9',
                width: '170px',
                height: '27px',
                p: 0,
              }}
              onClick={() => {
                navigateTo('/templates');
              }}
            >
              <Typography noWrap> Select Invoice Templates</Typography>
            </Button>
          </Grid>
          <Grid
            item
            lg={3}
            md={5}
            sm={6}
            xs={12}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              width: '100%',
              mt: 1,
              float: 'right',
            }}
          >
            <Badge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              badgeContent={
                <SignatureUpload
                  snackbar={openUpstoreSnackBar}
                  getSignatureData={() => getSignatureUrl()}
                />
              }
            >
              <Avatar
                alt={'Signature'}
                src={signatureUrl}
                variant='square'
                style={{
                  width: '360px',
                  height: '80px',
                }}
                sx={{ bgcolor: 'darkGray' }}
              />
            </Badge>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          sx={{ pl: 2, pr: 2, backgroundColor: '#fff' }}
        >
          <Grid display='flex' item md={12} sm={12} xs={12} sx={{ mt: 1 }}>
            <Grid>
              <Typography
                variant='h5'
                sx={{
                  textAlign: 'flex-start',
                  color: '#3B78C9',
                  pl: 1,
                  pt: 1,
                  pb: 1,
                  width: '100%',
                  backgroundColor: '#E1EEF5',
                }}
                noWrap
              >
                Company Details
              </Typography>
            </Grid>

            <Grid
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              sx={{
                width: '100%',
                float: 'right',
                color: '#3B78C9',
                backgroundColor: '#E1EEF5',
              }}
            >
              <Typography variant='h5'>Edit</Typography>
              <Switch
                checked={companyDetail}
                onChange={e => handleCompanydetail(e)}
              />
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>
              Company Name(Max 16 Chars)
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Description'
              variant='outlined'
              name='name'
              autoCompelete='off'
              value={storeList.name}
              disabled={companyDetail === false}
              onChange={handleOnchange}
              inputProps={{ maxLength: 16 }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography variant='BUTTON TEXT' sx={{ mr: { md: -4, sm: 0 } }}>
              Business Type
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='businessType'
              size='small'
              variant='outlined'
              autoCompelete='off'
              name='businessType'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'businessType')
                  ? storeList.businessType
                  : ''
              }
              onChange={event => {
                setStoreList({
                  ...storeList,
                  restrictedPincode: event.target.value,
                });
              }}
              disabled={true}
            ></TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Country
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='country'
              size='small'
              select
              placeholder='Enter Country'
              variant='outlined'
              name='country'
              autoCompelete='off'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'country')
                  ? storeList?.country
                  : ''
              }
              disabled={companyDetail === false}
              onChange={event => handleCountryChange(event)}
            >
              {selectedCountry?.map((item, index) => (
                <MenuItem value={item?.isoCode} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              State
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='state'
              size='small'
              placeholder='Enter state'
              select
              variant='outlined'
              name='state'
              autoCompelete='off'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'state')
                  ? storeList?.state
                  : ''
              }
              disabled={companyDetail === false}
              onChange={handleOnchange}
            >
              {stateList?.map((item, index) => (
                <MenuItem value={item?.isoCode} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              City
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='city'
              size='small'
              placeholder='Enter city'
              variant='outlined'
              name='city'
              select
              autoCompelete='off'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'city')
                  ? storeList?.city
                  : ''
              }
              disabled={companyDetail === false}
              onChange={handleOnchange}
            >
              {cityList?.map((item, index) => (
                <MenuItem value={item?.name} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Phone Number
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='phoneNumber'
              size='small'
              placeholder='Enter phone number'
              variant='outlined'
              name='phoneNumber'
              autoCompelete='off'
              value={storeList?.phoneNumber}
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Email
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='email'
              size='small'
              placeholder='Enter Email'
              variant='outlined'
              name='email'
              autoCompelete='off'
              value={storeList?.email}
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Address
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='address'
              size='small'
              placeholder='Enter Address'
              variant='outlined'
              name='address'
              autoCompelete='off'
              value={storeList?.address}
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Website
            </Typography>

            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='website'
              size='small'
              placeholder='Enter Website'
              variant='outlined'
              name='website'
              autoCompelete='off'
              value={storeList?.website}
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid>
          {/* <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>Street Name</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              autoCompelete='off'
              placeholder='Enter Description'
              variant='outlined'
              name='address'
              value={storeList.address}
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid> */}
          <Grid item md={6} sm={6} xs={12}>
            <Typography pr={2.5} variant='BUTTON TEXT'>
              Pincode
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Description'
              variant='outlined'
              name='pincodes'
              autoCompelete='off'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'pincodes')
                  ? storeList.pincodes
                  : ''
              }
              disabled={companyDetail === false}
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography variant='BUTTON TEXT'>Time Zone</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='timeZone'
              select
              size='small'
              variant='outlined'
              autoCompelete='off'
              name='timeZone'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'timeZone')
                  ? storeList.timeZone
                  : ''
              }
              onChange={event => handlePincode(event)}
              disabled={companyDetail === false}
            >
              {selectedCountryTimezones?.map((item, index) => (
                <MenuItem value={`${item.name}:${item.offset}`} key={index}>
                  {`${item.name}:${item.offset}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography variant='BUTTON TEXT' sx={{ mr: { md: -4, sm: 1 } }}>
              Allowed Pincodes
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='allowedPincode'
              size='small'
              variant='outlined'
              autoCompelete='off'
              name='allowedPincode'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'allowedPincode')
                  ? storeList.allowedPincode
                  : ''
              }
              onChange={event => {
                setStoreList({
                  ...storeList,
                  allowedPincode: event.target.value,
                });
              }}
              disabled={companyDetail === false}
            ></TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={{ alignItems: 'center' }}>
            <Typography variant='BUTTON TEXT' sx={{ mr: { md: -4, sm: 0 } }}>
              Restricted Pincodes
            </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='restrictedPincode'
              size='small'
              variant='outlined'
              autoCompelete='off'
              name='restrictedPincode'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'restrictedPincode')
                  ? storeList.restrictedPincode
                  : ''
              }
              onChange={event => {
                setStoreList({
                  ...storeList,
                  restrictedPincode: event.target.value,
                });
              }}
              disabled={companyDetail === false}
            ></TextField>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Box
              display='flex'
              justifyContent='flex-end'
              sx={{ float: 'right' }}
            >
              <Button
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  bgcolor: '#3B78C9',
                  width: '126px',
                  height: '27px',
                }}
                onClick={saveCompanyDetails}
                disabled={companyDetail === false}
              >
                <Typography variant='h5' sx={{ fontSize: '14px' }}>
                  Save address
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid display='flex' item md={12} sm={12} xs={12} sx={{ mt: 1 }}>
            <Grid>
              <Typography
                variant='h5'
                sx={{
                  textAlign: 'flex-start',
                  color: '#3B78C9',
                  pl: 1,
                  pt: 1,
                  pb: 1,
                  width: '100%',
                  backgroundColor: '#E1EEF5',
                }}
                noWrap
              >
                Configuration
              </Typography>
            </Grid>
            <Grid
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              sx={{
                width: '100%',
                float: 'right',
                color: '#3B78C9',
                backgroundColor: '#E1EEF5',
              }}
            >
              <Typography variant='h5'>Edit</Typography>
              <Switch
                checked={regionAndConfigDetails}
                onChange={e => regionAndConfig(e)}
              />
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>Currency</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='outlined-select-currency'
              select
              name='currency'
              size='small'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'currency')
                  ? storeList.currency
                  : ''
              }
              disabled={regionAndConfigDetails === false}
              onChange={event =>
                setStoreList({ ...storeList, currency: event.target.value })
              }
            >
              {currency.map(option => (
                <MenuItem key={option.id} value={option.currencyHexcode}>
                  {option.currencyCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>{' '}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1'>Annual Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['day']}
                color='#3B78C9'
                backgroundColor='red'
                value={storeList.annualStartDate}
                disabled={regionAndConfigDetails === false}
                onChange={newValue => {
                  setStoreList({ ...storeList, annualStartDate: newValue });
                }}
                renderInput={params => (
                  <TextField
                    size='small'
                    sx={{ width: '100%', mt: '10px' }}
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1'>Default Due Date</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='daysForDueDate'
              size='small'
              type='number'
              placeholder='Default due date'
              variant='outlined'
              onInput={e => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
              max={3}
              autoCompelete='off'
              name='daysForDueDate'
              value={storeList.daysForDueDate}
              disabled={regionAndConfigDetails === false}
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1'>Tax Type </Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='taxType'
              select
              name='taxType'
              size='small'
              placeholder='Tax Type'
              value={
                Validation.isBlankObjectKeyNot(storeList, 'taxType')
                  ? storeList.taxType
                  : ''
              }
              disabled={regionAndConfigDetails === false}
              onChange={event =>
                setStoreList({ ...storeList, taxType: event.target.value })
              }
            >
              {taxType?.map(option => (
                <MenuItem key={option.id} value={option.taxType}>
                  {option.taxType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {storeList.taxType === 'GST' && (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant='subtitle1'>Gst Type</Typography>
                <TextField
                  sx={{ width: '100%', mt: '10px' }}
                  id='gstType'
                  size='small'
                  select
                  variant='outlined'
                  autoCompelete='off'
                  name='gstType'
                  value={storeList.gstType}
                  disabled={regionAndConfigDetails === false}
                  onChange={e => {
                    handleOnchange(e);
                  }}
                >
                  {taxTypeDesc?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemText
                        primary={option.typeOfBusiness}
                        secondary={option.description}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {storeList.gstType === 5 ||
              storeList.gstType === 6 ||
              storeList.gstType === 7 ||
              storeList.gstType === 8 ? (
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant='subtitle1'>LUT</Typography>
                  <TextField
                    sx={{ width: '100%', mt: '10px' }}
                    id='lut'
                    size='small'
                    variant='outlined'
                    autoCompelete='off'
                    name='lut'
                    value={storeList.lut}
                    disabled={regionAndConfigDetails === false}
                    onChange={handleOnchange}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </Grid>
              ) : null}
            </>
          )}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1'>GST NO</Typography>
            <TextField
              error={storeList?.gstNo?.length > 0 && isValidGst === false}
              sx={{ width: '100%', mt: '10px' }}
              id='gstNo'
              size='small'
              placeholder='Enter GST Number'
              variant='outlined'
              autoCompelete='off'
              name='gstNo'
              value={storeList?.gstNo}
              disabled={regionAndConfigDetails === false}
              onChange={handleOnchange}
              inputProps={{
                maxLength: 15,
              }}
              helperText={
                storeList?.gstNo?.length > 0 &&
                isValidGst === false &&
                ' Incorrect GST Number'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1'>PAN NO</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              error={storeList?.panNo?.length > 0 && isValidPan === false}
              id='panNo'
              size='small'
              placeholder='Enter PAN Number'
              variant='outlined'
              autoCompelete='off'
              name='panNo'
              value={storeList.panNo}
              disabled={regionAndConfigDetails === false}
              onChange={handleOnchange}
              inputProps={{
                maxLength: 10,
              }}
              helperText={
                storeList?.panNo?.length > 0 &&
                isValidPan === false &&
                ' Incorrect Pan Number'
              }
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={6}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography variant='BUTTON TEXT' pr='18px'>
              Price Editable
            </Typography>

            <Switch
              name='storePriceEditable'
              checked={editable.storePriceEditable}
              disabled={regionAndConfigDetails === false}
              onChange={event => {
                setEditable({
                  ...editable,
                  storePriceEditable: !editable.storePriceEditable,
                });
              }}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={6}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography variant='BUTTON TEXT' pr='10px'>
              Discount Editable
            </Typography>

            <Switch
              name='discountEditable'
              checked={editable.discountEditable}
              disabled={regionAndConfigDetails === false}
              onChange={event => {
                setEditable({
                  ...editable,
                  discountEditable: !editable.discountEditable,
                });
              }}
            />
          </Grid>
          {/* <Grid
            item
            md={4}
            sm={4}
            xs={6}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography variant='BUTTON TEXT' pr='10px'>
              Tax Editable
            </Typography>

            <Switch
              name='taxEditable'
              checked={editable.taxEditable}
              disabled={regionAndConfigDetails === false}
              onChange={event => {
                setEditable({
                  ...editable,
                  taxEditable: !editable.taxEditable,
                });
              }}
            />
          </Grid> */}
          {/* <Grid
            item
            md={4}
            sm={4}
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Typography variant='BUTTON TEXT' pr='10px'>
              Include Tax
            </Typography>

            <Switch
              name='includeTax'
              checked={editable.includeTax}
              disabled={regionAndConfigDetails === false}
              onChange={event => {
                setEditable({ ...editable, includeTax: !editable.includeTax });
              }}
            />
          </Grid> */}
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ mr: '15px', mt: '30px' }}
          >
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                color: 'white',
                bgcolor: '#3B78C9',

                width: '126px',
                height: '27px',
              }}
              disabled={regionAndConfigDetails === false}
              onClick={saveCompanyDetails}
            >
              <Typography variant='h5' sx={{ fontSize: '14px' }}>
                Save Config
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} mt='50px' height='300px'>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              variant='h5'
              sx={{
                color: '#3B78C9',
                pl: 2,
                pt: 1,
                pb: 1,
                width: '100%',
                backgroundColor: '#E1EEF5',
              }}
            >
              Company Description
            </Typography>
          </Grid>

          <div className='description_ckeditor'>
            {Validation.isBlankObjectNot(storeList) ? (
              <CKEditor
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  setStoreList({
                    ...storeList,
                    description: editor.getData(),
                  });
                }}
                data={storeList?.description}
                disabled={regionAndConfigDetails === false}
              />
            ) : null}
          </div>

          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ float: 'right', mr: '15px', mt: '30px' }}
          >
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                color: 'white',
                bgcolor: '#3B78C9',
                width: '126px',
                height: '27px',
                mb: '50px',
              }}
              onClick={saveCompanyDetails}
              disabled={regionAndConfigDetails === false}
            >
              <Typography variant='h5' sx={{ fontSize: '11px' }}>
                Save Description
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} mt='50px' height='300px'>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              variant='h5'
              sx={{
                color: '#3B78C9',
                pl: 2,
                pt: 1,
                pb: 1,
                width: '100%',
                backgroundColor: '#E1EEF5',
              }}
            >
              Terms & Conditions
            </Typography>
          </Grid>

          <div className='terms_ckeditor'>
            {Validation.isBlankObjectNot(storeList) ? (
              <CKEditor
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  setStoreList({
                    ...storeList,
                    termsAndCondition: editor.getData(),
                  });
                }}
                data={storeList.termsAndCondition}
                disabled={regionAndConfigDetails === false}
              />
            ) : null}
          </div>

          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ float: 'right', mr: '15px', mt: '30px' }}
          >
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                color: 'white',
                bgcolor: '#3B78C9',
                width: '126px',
                height: '27px',
                mb: '50px',
              }}
              onClick={saveCompanyDetails}
              disabled={regionAndConfigDetails === false}
            >
              <Typography variant='h5' sx={{ fontSize: '11px' }}>
                Save Terms&Conditions
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      {AllPermissions.getUserAccessiblePermissions('Company') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Company').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
}

export default HomeStore;
