import { Button, Grid, Radio } from '@mui/material';
import React from 'react';
import Header from '../../component/header/Header';
import TemplateProperties from '../../component/invoiceTemplate/TemplateProperties';
import { Box } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import InvoiceTemplate from '../../component/invoiceTemplate/InvoiceTemplate';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import Store from '../../modules/Store';

const Templates = () => {
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    first: false,
    second: false,
    third: false,
    default: false,
  });

  React.useEffect(() => {
    getStoreById();
  }, []);

  const getStoreById = () => {
    Store.getStoreByStoreId(Auth?.getStoreId(), response => {
      if (response.status === 'success') {
        let parsed = JSON.parse(response?.data?.invoice);
        if (parsed?.id === 1) {
          setSelectedTemplate({ ...selectedTemplate, first: true });
        } else if (parsed?.id === 2) {
          setSelectedTemplate({ ...selectedTemplate, second: true });
        } else if (parsed?.id === 3) {
          setSelectedTemplate({ ...selectedTemplate, third: true });
        } else if (parsed?.id == null) {
          setSelectedTemplate({ ...selectedTemplate, default: true });
        }
      } else {
        console.log('error');
      }
    });
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleChange = (event, id) => {
    setSelectedTemplate({
      // ...selectedTemplate,
      [event.target.name]: event.target.checked,
    });
    let data;
    if (event.target.checked && id != null) {
      let selected = TemplateProperties.getTemplateById(id);

      data = {
        invoice: JSON.stringify(selected),
      };
    } else if (id == null) {
      data = {
        invoice: null,
      };
    }

    Store.updateStoreDetails(Auth.getStoreId(), data, response => {
      if (response.status == 'success') {
        openUpstoreSnackBar('Template is selected successfully', 'success');
      } else {
        console.log('error');
        openUpstoreSnackBar('Error while selecting template', 'error');
      }
    });
  };

  return (
    <>
      <Header />
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        sx={{ pl: 7, pt: 8 }}
      >
        <Button
          variant='contained'
          style={{
            width: '126px',
            height: '30px',
            fontSize: '12px',
            textTransform: 'capitalize',
            color: '#F3F5F9',
            backgroundColor: '#365E99',
          }}
          name='default'
          checked={true}
          onClick={event => handleChange(event, null)}
        >
          {selectedTemplate?.default ? 'Default Selected' : 'Select Default'}
        </Button>
      </Grid>
      <Grid container spacing={2} textAlign={'center'} sx={{ pl: 7 }}>
        <Grid item xs={12} sm={6} lg={4}>
          <Box sx={{ pl: 1 }}>
            <InvoiceTemplate
              template={TemplateProperties?.getTemplateOne()}
              key={1}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedTemplate?.first}
                  onChange={event => handleChange(event, 1)}
                  name='first'
                />
              }
              label={selectedTemplate?.first ? 'Selected' : 'Select'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box sx={{ pt: 1 }}>
            <InvoiceTemplate
              template={TemplateProperties?.getTemplateTwo()}
              key={2}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedTemplate?.second}
                  onChange={event => handleChange(event, 2)}
                  name='second'
                />
              }
              label={selectedTemplate?.second ? 'Selected' : 'Select'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box sx={{ pr: 1 }}>
            <InvoiceTemplate
              template={TemplateProperties?.getTemplateThree()}
              key={3}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedTemplate?.third}
                  onChange={event => handleChange(event, 3)}
                  name='third'
                />
              }
              label={selectedTemplate?.third ? 'Selected' : 'Select'}
            />
          </Box>
        </Grid>
      </Grid>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
};

export default Templates;
