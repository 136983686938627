import React from 'react';
import {
  Box,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  Autocomplete,
  TextField,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddIcon from '@mui/icons-material/Add';
import { PeopleAlt, Print } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Loading } from '../../component/loading/Loading';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Header from '../../component/header/Header';
import Order from '../../modules/Order';
import Auth from '../../modules/Auth';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import OrderInOrderInvoicevoice from '../../component/orderInvoice/OrderInvoice';
import { useLocation } from 'react-router-dom';
import Customer from '../../modules/Customer';
import EditIcon from '@mui/icons-material/Edit';
import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import CheckCustomerBalance from '../../component/checkCustomerBalance/CheckCustomerBalance';
import OrderRecipt from '../../component/orderRecipt/OrderRecipt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import './style.css';
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';

import HTMLRenderer from 'react-html-renderer';
import Store from '../../modules/Store';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import DraftedInvoice from '../../component/draftedInvoice/DraftedInvoice';
import OrderInvoice from '../../component/orderInvoice/OrderInvoice';
import { serverConfig } from '../../config/server-config';
import { debounce } from '../../utils';

const Orders = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [ordersList, setOrdersList] = React.useState([]);
  const [customerDataList, setCustomerDataList] = React.useState([]);

  const [loader, setLoader] = React.useState(false);

  const [orderRecipt, setOrderRecipt] = React.useState({ open: false });
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [orderInvoice, setOrderInvoice] = React.useState({
    open: false,
    drafted: false,
  });
  const [pageNumber, setPageNumber] = React.useState(0);
  const [deletedOrders, setDeletedOrders] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    IdOfDeletableOrder: '',
    orderNumber: '',
    customerId: '',
    status: '',
  });
  const [loaderForChangeCustomer, setLoaderForChangeCustomer] =
    React.useState(false);

  const [openCheckBalanceDialog, setOpenCheckBalanceDialog] =
    React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [searchOrder, setSearchOrder] = React.useState(false);
  const [draftedInvoice, setDraftedInvoice] = React.useState({ open: false });
  const [details, setDetails] = React.useState([]);
  const columns = [
    {
      field: 'storeOrderNumber',
      headerName: 'Order No',
      width: 150,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          {AllPermissions.getUserAccessiblePermissions('Orders') !==
            undefined &&
          AllPermissions.getUserAccessiblePermissions('Orders').write ===
            false ? (
            <Typography>{params.row.storeOrderNumber}</Typography>
          ) : (
            <Typography
              sx={{ cursor: 'pointer', color: '#3B78C9' }}
              onClick={event => {
                if (params.row.deleted) {
                  openUpstoreSnackBar(
                    'Order is deleted you cant edit or update ',
                    'error'
                  );
                } else {
                  createOrUpdateOrder(params.row);
                }
              }}
            >
              {params.row.storeOrderNumber}
            </Typography>
          )}
          <Box sx={{ pl: 3 }}>
            {params.row.edited ? (
              <Tooltip title='Edited'>
                <EditIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            ) : null}

            {params.row.returned ? (
              <Tooltip title='Return Order is Present'>
                <KeyboardReturnIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            ) : null}
          </Box>
        </>
      ),
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 230,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      type: 'name',
      align: 'left',
      renderCell: params => params.row?.customerName,
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      width: 190,
      type: 'date',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.invoiceDate === null
          ? 'N / A'
          : moment.utc(params.row.invoiceDate).local().format('DD-MM-yyyy'),
    },

    {
      field: 'totalAmount',
      headerName: 'Amount',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      type: 'text',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalAmount}{' '}
            {currency ? <HTMLRenderer html={currency} /> : null}
          </Typography>
        </>
      ),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 190,
      type: 'date',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.dueDate === null
          ? 'N / A'
          : moment.utc(params.row.dueDate).local().format('DD-MM-yyyy'),
    },
    {
      field: 'storeOrderStatus',
      headerName: 'Order Status',
      width: 190,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.storeOrderStatus === 'CONFIRMED' ? 'Confirmed' : 'Drafted',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      type: 'action',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          {AllPermissions.getUserAccessiblePermissions('Orders') !==
            undefined &&
          AllPermissions.getUserAccessiblePermissions('Orders').write ===
            false ? null : (
            <Box
              width='100%'
              component='div'
              display='flex'
              justifyContent='space-around'
              alignItems='baseline'
            >
              <>
                {params.row.storeOrderStatus === 'CONFIRMED' ? (
                  <Tooltip title='Download Invoice' placement='bottom'>
                    <DownloadIcon
                      sx={{ color: '#3B78C9', cursor: 'pointer' }}
                      onClick={event => downloadInvoice(params.row, false)}
                    />
                  </Tooltip>
                ) : (
                  <DownloadIcon
                    onClick={event => downloadInvoice(params.row, true)}
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  />
                )}
                {params.row.storeOrderStatus === 'CONFIRMED' ? (
                  <Tooltip title='Download Recipt' placement='bottom'>
                    <Print
                      sx={{ color: '#3B78C9', cursor: 'pointer' }}
                      onClick={event => downloadRecipt(params.row)}
                    />
                  </Tooltip>
                ) : (
                  <Print sx={{ color: 'lightGray' }} />
                )}
                <Tooltip title='Delete Order' placement='bottom'>
                  <DeleteIcon
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                    onClick={event => {
                      if (params.row.deleted) {
                        openUpstoreSnackBar(
                          'Order is already been deleted ',
                          'error'
                        );
                      } else if (params.row.orderFromEstore) {
                        openUpstoreSnackBar(
                          'Order from E-Store Cannot Be Deleted ',
                          'error'
                        );
                      } else {
                        setOpenDeleteDialog({
                          open: true,
                          IdOfDeletableOrder: params.row.id,
                          orderNumber: params.row.storeOrderNumber,
                          customerId: params.row.customerId,
                          status: params.row.storeOrderStatus,
                        });
                      }
                    }}
                  />
                </Tooltip>
              </>
            </Box>
          )}
        </>
      ),
    },
  ];

  React.useEffect(() => {
    getCustomerList();
    getAllOrdersList(0);
    getOrderFromCustomerDetails();
    getCurrency();
  }, [deletedOrders]);

  const getOrderFromCustomerDetails = () => {
    if (serverConfig.isUpstoreCloud) {
      if (window.location.hash) {
        let queryParam = window.location.hash;
        let result = queryParam.substring(queryParam.indexOf('_'));
        let orderId = result.substring(1);
        if (orderId !== '/orders') {
          Order.getStoreOrder(orderId, response => {
            if (response.status === 'success') {
              createOrUpdateOrder(response.data);
            }
          });
        }
      }
    }
  };
  const getAllOrdersList = pageNum => {
    let deleted = deletedOrders;
    let orderFromEstore = false;
    setLoader(true);

    Order.getAllOrdersByStoreId(
      Auth.getStoreId(),
      pageNum,
      deleted,
      orderFromEstore,
      response => {
        if (response.status === 'success') {
          let orderListItem = response.data;

          orderListItem.forEach(order => {
            if (order.invoiceDate != null && order.dueDate != null) {
              order.invoiceDate = new Date(order.invoiceDate + 'Z');
              order.dueDate = new Date(order.dueDate + 'Z');
            }
          });

          let sortedOrderList = orderListItem.sort(function (a, b) {
            return b.storeOrderNumber - a.storeOrderNumber;
          });
          // let filterdDeleted = sortedOrderList.filter(item => {
          //   return item.orderDetails.filter(itm => {
          //     return !itm.deleted;
          //   });
          // });
          setOrdersList(sortedOrderList);
          setLoader(false);
        } else {
          setLoader(false);
          setOrdersList([]);
        }
      }
    );
  };

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const getCustomerList = () => {
    Customer.getAllCustomers(Auth.getStoreId(), 0, false, response => {
      if (response.status === 'success') {
        let sortedCustomerList = response.data.sort(function (a, b) {
          if (a.customerName && b.customerName) {
            return a.customerName.localeCompare(b.customerName);
          }
        });
        setCustomerDataList(sortedCustomerList);
      } else {
        setCustomerDataList([]);
      }
    });
  };

  // deleting order callback
  const handleDeleteOrderCallback = usersChoice => {
    if (usersChoice === true) {
      Order.deleteStoreOrder(openDeleteDialog.IdOfDeletableOrder, response => {
        if (response.status === 'success') {
          if (openDeleteDialog.status === 'NEW') {
            Transaction.log(
              Auth.getStoreId(),
              openDeleteDialog.customerId,
              LogMessage.DRAFTED_ORDER_DELETED,
              LogMessage.DRAFTED_ORDER_DELETED_TITLE,
              [openDeleteDialog.orderNumber, Auth.getUserName()]
            );
          } else {
            Transaction.log(
              Auth.getStoreId(),
              openDeleteDialog.customerId,
              LogMessage.ORDER_DELETED,
              LogMessage.ORDER_DELETED_TITLE,
              [openDeleteDialog.orderNumber, Auth.getUserName()]
            );
          }
          openUpstoreSnackBar('Order was deleted successfully', 'success');
          getAllOrdersList(pageNumber);
          setOpenDeleteDialog(false);
        } else {
          openUpstoreSnackBar('There is an issue in deleting order', 'error');
        }
      });
    }
  };

  const handlePreviousPage = () => {
    let pageNum = pageNumber - 20;
    setPageNumber(pageNum);
    getAllOrdersList(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 20;
    setPageNumber(pageNum);
    getAllOrdersList(pageNum);
  };
  const createOrUpdateOrder = orderRow => {
    if (orderRow == false) {
      navigate('/neworders');
    } else {
      let mainOrder = orderRow;
      Order.getDetailsByOrderId(mainOrder?.id, response => {
        if (response.status === 'success') {
          setDetails(response.data);
          const ordrDlt1 = response.data?.filter(item => {
            return !item.deleted;
          });
          // const ordrDlt1 = mainOrder.orderDetails.filter(item => {
          //   return !item.deleted;
          // });

          let deletedItems = response.data?.filter(item => {
            return item.deleted;
          });
          mainOrder.orderDetails = ordrDlt1;
          setTimeout(() => {
            navigate('/neworders', {
              state: { mainOrder, deletedItems },
            });
          }, 10);
        }
      });
    }
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const downloadInvoice = (orderDetails, drafted) => {
    Order.getDetailsByOrderId(orderDetails?.id, response => {
      if (response.status === 'success') {
        let newOrderDetails = { ...orderDetails };

        let deletedItems = response?.data.filter(item => {
          return !item.deleted;
        });
        newOrderDetails.orderDetails = !newOrderDetails.deleted
          ? deletedItems
          : orderDetails.orderDetails;
        getCustomerName(newOrderDetails, drafted);
      }
    });
  };
  const getCustomerName = (newOrderDetails, drafted) => {
    setLoaderForChangeCustomer(true);
    Customer.getCustomerById(newOrderDetails.customerId, response => {
      if (response.status === 'success') {
        let orderInvoiceObj = {
          open: true,
          order: {
            ...newOrderDetails,
            customerName: response.data.customerName,
            address: response.data.address,
            gstNo: response.data.gstNo,
            state: response.data.state,
          },
          drafted: drafted,
        };
        setOrderInvoice(orderInvoiceObj);
        setLoaderForChangeCustomer(false);
      } else {
        setOrderInvoice({});
        setLoaderForChangeCustomer(false);
      }
    });
  };
  const closeInvoiceDialog = orderDetails => {
    let orderInvoiceObj = {
      open: false,
      drafted: false,
    };

    setOrderInvoice(orderInvoiceObj);
  };

  const downloadRecipt = orderDetails => {
    Order.getDetailsByOrderId(orderDetails?.id, response => {
      if (response.status === 'success') {
        let newOrderDetails = { ...orderDetails };
        let deletedItems = response?.data.filter(item => {
          return !item.deleted;
        });
        newOrderDetails.orderDetails = deletedItems;
        let orderReciptObj = {
          open: true,
          order: newOrderDetails,
        };

        setOrderRecipt(orderReciptObj);
      }
    });
  };

  const closeReciptDialog = () => {
    let orderReciptObj = {
      open: false,
    };
    setOrderRecipt(orderReciptObj);
    document.title = 'RSL | Up-Store';
  };

  const searchOrderByOrderId = search => {
    if (search !== '') {
      Order.searchOrderByOrderIdOrCustomerName(
        Auth.getStoreId(),
        search,
        response => {
          if (response.status === 'success') {
            let confirmedOrders = response.data.filter(orders => {
              return !orders.deleted;
            });
            let deletedOrders = response.data.filter(orders => {
              return orders.deleted;
            });
            console.log(value, 'tab value');
            if (value === 0) {
              setOrdersList(confirmedOrders);
            } else {
              setOrdersList(deletedOrders);
            }
          } else {
            setOrdersList([]);
          }
        }
      );
    } else {
      getAllOrdersList(0);
    }
  };

  //for preventing frequent api calls on customer search

  const handleSearchOrderByOrderId = debounce(
    e => searchOrderByOrderId(e),
    300
  );

  const handleGetOrdersByCustomerIds = customerArrays => {
    if (customerArrays.length > 0) {
      setLoaderForChangeCustomer(true);
      let ids = { customerIds: [] };
      ids.customerIds = customerArrays.map(customer => {
        return customer.id;
      });

      Order.getStoreOrdersByCustomerIds(ids, deletedOrders, response => {
        if (response.status === 'success') {
          setLoaderForChangeCustomer(false);
          setOrdersList(response.data);
        } else {
          setLoaderForChangeCustomer(false);
          setOrdersList([]);
        }
      });
    } else {
      getAllOrdersList(0);
    }
  };

  const rowData = ordersList?.map(order => {
    return {
      ...order,
      id: order?.id,
    };
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDeletedOrders(false);
    } else {
      setDeletedOrders(true);
    }
  };
  return (
    <>
      <Header />

      <Box
        sx={{
          width: '95%',
          height: 600,
          mt: 8,
          ml: 8,

          '& .super-app-theme--header': {
            backgroundColor: '#EFFAFF',

            fontSize: '18px',
          },
        }}
      >
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item md={3} sm={3} xs={12}>
                <BreadCrumb pageName='Orders' />
              </Grid>
              <Grid
                item
                md={5}
                sm={0}
                xs={12}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent='space-around'
                  alignItems={'center'}
                >
                  <input
                    type='text'
                    className='expandedSearch'
                    name='search'
                    placeholder='Search by order number or customer name'
                    onChange={event => {
                      handleSearchOrderByOrderId(event.target.value);
                    }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                sm={5}
                xs={5}
                md={2}
                textAlign={{ md: 'center', lg: 'right' }}
                sx={{ mt: { md: 0, sm: 0, xs: 2 } }}
              >
                <Tooltip title='Check customer balance' placement='bottom'>
                  <Button
                    sx={{
                      p: 0,
                      mb: 2,
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                      fontSize: { md: '12px', sm: '12px', xs: '10px' },
                    }}
                    endIcon={
                      <CreditScoreIcon
                        fontSize='small'
                        sx={{
                          color: 'white',

                          display: { md: 'block', sm: 'none', xs: 'none' },
                        }}
                      />
                    }
                    variant='contained'
                    onClick={() => setOpenCheckBalanceDialog(true)}
                  >
                    Check Balance
                  </Button>
                </Tooltip>
              </Grid>
              {AllPermissions.getUserAccessiblePermissions('Orders') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('Orders').write ===
                false ? null : (
                <Grid
                  item
                  md={2}
                  sm={4}
                  xs={4}
                  sx={{
                    textAlign: { md: 'center' },
                    mt: { md: 0, sm: 0, xs: 2 },
                  }}
                >
                  <Tooltip title='Create new order' placement='bottom'>
                    <Button
                      sx={{
                        p: 0,
                        mb: 2,
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                        color: 'white',
                        bgcolor: '#3B78C9',
                        fontSize: { sm: '12px', xs: '10px' },
                      }}
                      endIcon={
                        <AddIcon
                          fontSize='small'
                          sx={{
                            color: 'white',
                            display: {
                              md: 'block',
                              sm: 'none',
                              xs: 'none',
                            },
                          }}
                        />
                      }
                      variant='contained'
                      onClick={() => {
                        location.state = null;
                        createOrUpdateOrder('');
                      }}
                    >
                      Create Order
                    </Button>
                  </Tooltip>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: { md: 'center' },
                  display: { md: 'none', sm: 'none', xs: 'block' },
                }}
              >
                <Autocomplete
                  fullWidth
                  multiple
                  id='tags-outlined'
                  options={customerDataList}
                  onChange={(event, newValue) => {
                    if (newValue == 121) {
                      return null;
                    }
                    handleGetOrdersByCustomerIds(newValue);
                  }}
                  getOptionLabel={option =>
                    typeof option == 'string'
                      ? (option = '')
                      : option !== undefined
                      ? `${option.customerName}` || {}
                      : ''
                  }
                  renderOption={(props, option) => {
                    return (
                      <>
                        <div {...props} key={option.id}>
                          <span
                            key={option.id}
                            style={{ paddingRight: '10px' }}
                          >
                            <PeopleAlt fontSize='small' />
                          </span>
                          {`${option.customerName}`}
                          <li
                            style={{
                              color: 'gray',
                              marginLeft: 'auto',
                            }}
                            key={option.id}
                            {...props}
                          >{`${option.contact} `}</li>
                        </div>
                      </>
                    );
                  }}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      size='small'
                      placeholder='Select customer'
                    />
                  )}
                />
              </Grid>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='Order Tabs'
                >
                  <Tab label='Confirmed Orders' {...a11yProps(0)} />
                  <Tab label='Deleted Orders' {...a11yProps(1)} />
                </Tabs>

                <Box
                  sx={{
                    width: '400px',
                    position: 'absolute',
                    right: '10px',
                    mt: 2,
                    display: { md: 'block', sm: 'block', xs: 'none' },
                  }}
                >
                  <Autocomplete
                    sx={{ pl: 2 }}
                    fullWidth
                    multiple
                    id='tags-outlined'
                    options={customerDataList}
                    onChange={(event, newValue) => {
                      if (newValue == 121) {
                        return null;
                      }
                      handleGetOrdersByCustomerIds(newValue);
                    }}
                    getOptionLabel={option =>
                      typeof option == 'string'
                        ? (option = '')
                        : option !== undefined
                        ? `${option.customerName}` || {}
                        : ''
                    }
                    renderOption={(props, option) => {
                      return (
                        <>
                          <div {...props} key={option.id}>
                            <span
                              key={option.id}
                              style={{ paddingRight: '10px' }}
                            >
                              <PeopleAlt fontSize='small' />
                            </span>
                            {`${option.customerName}`}
                            <li
                              style={{
                                color: 'gray',
                                marginLeft: 'auto',
                              }}
                              key={option.id}
                              {...props}
                            >{`${option.contact} `}</li>
                          </div>
                        </>
                      );
                    }}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        {...params}
                        size='small'
                        placeholder='Select customer'
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>

            {loaderForChangeCustomer ? (
              <Loading />
            ) : (
              <>
                <OrderTab columns={columns} data={rowData} value={value} />

                <Grid
                  style={{
                    padding: '1.6vh',
                    float: 'right',

                    bottom: '0px',
                  }}
                >
                  <Button
                    disabled={pageNumber <= 0}
                    onClick={handlePreviousPage}
                    sx={{ color: '#3B78C9' }}
                  >
                    Prev
                  </Button>
                  <Button
                    disabled={ordersList.length < 20}
                    sx={{ margin: '1vh', color: '#3B78C9' }}
                    onClick={handleNextPage}
                  >
                    Next
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
      {orderInvoice.open && (
        <Dialog maxWidth={'xl'} open={orderInvoice.open}>
          <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
            <Grid item>
              <Tooltip title='Close dialog' placment='right'>
                <Close
                  onClick={closeInvoiceDialog}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {orderInvoice.drafted ? (
            <DialogContent>
              <DraftedInvoice orderDetails={orderInvoice.order} />
            </DialogContent>
          ) : (
            <DialogContent>
              <OrderInvoice orderDetails={orderInvoice.order} />
            </DialogContent>
          )}
        </Dialog>
      )}
      <Dialog
        maxWidth={'lg'}
        open={orderRecipt.open}
        onClose={closeReciptDialog}
      >
        <DialogContent>
          <OrderRecipt orderDetails={orderRecipt.order} />
        </DialogContent>
      </Dialog>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}

      {openDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              IdOfDeletableOrder: '',
              orderNumber: '',
              customerId: '',
              status: '',
            })
          }
          deleteCallback={handleDeleteOrderCallback}
          dialogContent='Your order will be deleted permanently , Are you sure you want to delete it ?'
        />
      ) : null}

      {openCheckBalanceDialog ? (
        <CheckCustomerBalance
          openDialog={openCheckBalanceDialog}
          closeDialog={() => setOpenCheckBalanceDialog(false)}
          snackbar={openUpstoreSnackBar}
        />
      ) : null}
      {AllPermissions.getUserAccessiblePermissions('Orders') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Orders').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`order-tabpanel-${index}`}
        aria-labelledby={`order-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `order-tab-${index}`,
      'aria-controls': `order-tabpanel-${index}`,
    };
  }

  function OrderTab({ columns, data, value }) {
    return (
      <Box sm={12} sx={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Orders list'
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Orders list'
            />
          </Box>
        </TabPanel>
      </Box>
    );
  }
};

export default Orders;
