import UpstoreApi from '../UpstoreApi';

class RolesAndPermissions {
  getAllPermissions = callBack => {
    UpstoreApi({
      method: 'GET',
      url: `/permission/get-permissions`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while getting permissions', error.message);
        callBack({ status: 'error' });
      });
  };

  getAllFeatures = callBack => {
    UpstoreApi({
      method: 'GET',
      url: `/features/getAll`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while getting features', error.message);
        callBack({ status: 'error' });
      });
  };

  getPermissionByRoleId = (userId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/permission/get-permissions-by-userId/${userId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting permission by role id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new RolesAndPermissions();
