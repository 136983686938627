class Utils {
  isDate = val => {
    return Object.prototype.toString.call(val) === '[object Date]';
  };

  isObj = val => {
    return typeof val === 'object';
  };

  stringifyValue = val => {
    if (this.isObj(val) && !this.isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  };

  queryParams = (query, key) => {
    const queryParam = new URLSearchParams(query);
    const param = String(queryParam.get(key));
    return param === 'null' ? '' : param;
  };

  getTimeBeforeInMins = mins => {
    let timeBeforeInMins = new Date();
    timeBeforeInMins = new Date(
      timeBeforeInMins.setMinutes(timeBeforeInMins.getMinutes() - mins)
    );
    return timeBeforeInMins;
  };
}

export default new Utils();
