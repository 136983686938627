import { serverConfig } from '../../config/server-config';

export const LogMessage = {
  ORDER_ADDED: `New order ORDER_NUMBER_{0} of amount {1} created by {2}`,
  ORDER_ADDED_TITLE: `Order Added`,
  DRAFTED_ORDER_TITLE: `Drafted Order Added`,
  DRAFTED_ORDER_DELETED_TITLE: `Drafted Order Deleted`,
  DRAFTED_ORDER_ADDED: `Order Number {0} is drafted By {1}`,
  ORDER_UPDATED: `Order number ORDER_NUMBER_{0} of amount {1} is updated by {2}`,
  ORDER_UPDATED_TITLE: `Order Updated`,
  CUSTOMER_ADDED_TITLE: `Customer Added`,
  CUSTOMER_ADDED: `Customer {0} was added by {1}`,
  OPENING_BALANCE_TITLE: `Opening balance added`,
  OPENING_BALANCE: `Opening balance of {0} was added by {1} for customer {2} `,
  CUSTOMER_UPDATED_TITLE: `Customer details updated`,
  CUSTOMER_DETAILS_UPDATED: `Customer {0} details updated by {1}`,
  PAYMENT_DONE: `Payment of {0} done by {1} Recieved by {2} and mode was {3}`,
  PAYMENT_DONE_TITLE: `Payment Received`,
  PAYMENT_DELETED_TITLE: `Payment Deleted`,
  DRAFTED_ORDER_DELETED: `Drafted Order Number {0} is Deleted  By {1}`,
  PAYMENT_DELETED: ` Payment Number {0} deleted by {1}.`,
  ORDER_DELETED: `Order Number {0} was deleted By {1}`,
  ORDER_DELETED_TITLE: `Order Deleted`,
  ORDER_RETURNED_TITLE: `Order Returned`,
  ORDER_RETURNED: `Return Order {0} against order {1} Created By {2}`,
  VENDOR_CREATED: `Vendor Added`,
  VENDOR_CREATED_TITLE: `Vendor {0} was added by {1}`,
  PAYMENT_ADJUSTMENT: `Adjustment of Rs:{0} was adjusted by {1} `,
  PAYMENT_ADJUSTMENT_TITLE: `Adjustment`,
  DELETE_RETURN_ORDER: `Return Order Number {0} against Order Number{1} was deleted By {2}`,
  RETURN_ORDER_DELETED_TITLE: `RETURN ORDER DELETED`,
};

class Logger {
  format = (logMessage, argumentsList) => {
    let orgMessage = logMessage;
    let newMessage = orgMessage?.split(' ');
    for (let i = 0; i < argumentsList?.length; i++) {
      let href = newMessage.find(item => item.indexOf(`{` + i + '}') > -1);
      if (href?.startsWith('ORDER_NUMBER_') && serverConfig.isUpstoreCloud) {
        let abc = argumentsList[i]?.substring(
          argumentsList[i]?.indexOf('_'),
          -1
        );
        let newLink = `<a href="${serverConfig?.webAppUrl}#/orders?order=${argumentsList[i]}"> ${abc} </a>`;
        // let newLink = `<a href="http://localhost:3000/#/orders?order=${argumentsList[i]}"> ${abc} </a>`;

        logMessage = logMessage.replace(href, newLink);
      }
      if (href?.startsWith('ORDER_NUMBER_') && !serverConfig.isUpstoreCloud) {
        let abc = argumentsList[i]?.substring(
          argumentsList[i]?.indexOf('_'),
          -1
        );
        logMessage = logMessage.replace(href, abc);
      }

      // else if (href?.startsWith('CUSTOMER_') && serverConfig.isUpstoreCloud) {
      //   // let newLink = `<a href="${serverConfig?.webAppUrl}customers"> ${argumentsList[i]} </a>`;

      //   logMessage = logMessage?.replace(href, newLink);
      // }
      else {
        logMessage = logMessage?.replace('{' + i + '}', argumentsList[i]);
      }
    }
    return logMessage;
  };
}
export default new Logger();
