import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Logo from '../../assets/images/logo.png';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import Customer from '../../modules/Customer';
import { Typography, Button } from '@mui/material';
import ReactToPdf from 'react-to-pdf';
import ReactToPrint from 'react-to-print';
import Logo from '../../modules/Logo';
import './style.css';
import HTMLRenderer from 'react-html-renderer';
import Validation from '../../modules/Validation';
import * as moment from 'moment';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import logo from '../../assets/my-logo.png';
import { serverConfig } from '../../config/server-config';

const ref = React.createRef();

class OrderRecipt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDetails: this.props.orderDetails,
      options: {
        unit: 'pt',
        format: [900, 300],
      },
      height: 0,
      currency: null,
      logoUrl: null,
    };
  }
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  componentDidMount() {
    this.getCurrency();
    this.getHeight();
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint').clientHeight;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      document.title = `receipt__${this.state.orderDetails.storeOrderNumber}.pdf`;
      this.setState({ options: opt });
    }, 5);
    this.getLogoUrl(Auth.getStoreId());
  }

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };
  getHeight = () => {
    const pixels =
      this.state.orderDetails.orderDetails.length < 4
        ? this.state.orderDetails.orderDetails.length * 40 + 400
        : this.state.orderDetails.orderDetails.length > 10
        ? this.state.orderDetails.orderDetails.length * 40 + 630
        : this.state.orderDetails.orderDetails.length * 40 + 430;
    const ppi = 96;
    const inches = (pixels / ppi).toFixed(2);
    this.setState({ height: inches });
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            pageStyle={`@page { size: 2.83in ${this.state.height}in  }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',
                    fontSize: '16px',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon sx={{ color: 'white' }} /> Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
        </Typography>
        <br />
        <div className='divToDownload' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
          >
            <div className='invoice-content row d-flex justify-content-center'>
              <div className='col-12'>
                <div className='card'>
                  <div>
                    <div>
                      {Validation.isBlankNot(this.state.logoUrl) ? (
                        <img
                          style={{
                            maxWidth: '180px',
                            maxHeight: '70px',
                          }}
                          src={this.state.logoUrl}
                          alt='Logo'
                        ></img>
                      ) : (
                        `Logo`
                      )}
                      <strong
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          fontSize: '18px',
                          fontWeight: 700
                        }}
                      >
                        {' '}
                        {Store?.getStoreName().name}
                      </strong>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          fontSize: '12px',
                          fontWeight: 700
                        }}
                      >
                        {Store?.getStoreAddress().address}
                      </span>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          fontSize: '12px',
                          fontWeight: 700
                        }}
                      >
                        {Store?.getStoreName().phoneNumber}
                      </span>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          fontSize: '12px',
                          fontWeight: 700
                        }}
                      >
                        {Store?.getStoreName().email}
                      </span>
                    </div>
                  </div>

                  <hr />
                  <div>
                    <div>
                      <h4 className="receipt_content receipt-font" style={{paddingLeft: '4px' }}>
                        Date :- &nbsp;
                        <span>
                          {this.state.orderDetails.creationDate &&
                            moment(this.state.orderDetails.creationDate).format(
                              'DD-MM-yyyy'
                            )}
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div className='receipt-font products p-2 receipt_content'>
                    <table
                      className='table table-borderless '
                      style={{ borderRadius: '1px solid black' }}
                    >
                      <tbody>
                        <tr>
                          <th className='receipt_content'>Item</th>
                          <th className='receipt_content'>Rate</th>
                          <th className='receipt_content'>Quantity</th>
                          <th className='receipt_content'>Amount</th>
                        </tr>
                        {this.state.orderDetails !== undefined &&
                        this.state.orderDetails !== null
                          ? this.state.orderDetails.orderDetails.map(item => {
                              return (
                                <tr className='receipt_content' key={item.id}>
                                  <td
                                    className='receipt_content'
                                    style={{
                                      fontSize:
                                        item.itemName.length > 30 && '10px',
                                      maxWidth:
                                        item.itemName.length > 30
                                          ? ' 100px'
                                          : 'auto',
                                    }}
                                  >
                                    {item.itemName}{' '}
                                  </td>
                                  <td className='receipt_content'>
                                    {item.storePrice}
                                  </td>
                                  <td className='text-center receipt_content'>
                                    {item.noOfItems}
                                  </td>
                                  <td className='receipt_content'>
                                    {item.amount} &nbsp;
                                    {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                    <hr />
                    <div>
                      <td className='receipt_content'>
                        {' '}
                        <strong>
                          {' '}
                          No of Items : {this.state.orderDetails?.totalItems}
                        </strong>
                      </td>
                    </div>
                    <div>
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        <h6 className='receipt_content amount_style'>
                          Total Discount :&nbsp;
                          {this.state.orderDetails.totalDiscount &&
                            this.state.orderDetails.totalDiscount}
                          &nbsp;
                          {this.state.currency ? (
                            <HTMLRenderer html={this.state.currency} />
                          ) : null}
                        </h6>
                        <h6 className=' receipt_content amount_style'>
                          Total Amount :&nbsp;
                          {this.state.orderDetails.totalAmount &&
                            this.state.orderDetails.totalAmount}
                          &nbsp;
                          {this.state.currency ? (
                            <HTMLRenderer html={this.state.currency} />
                          ) : null}
                        </h6>
                      </div>
                      <hr />
                      <div className='receipt_content footer_class_receipt'>
                        <div style={{ fontSize: '8px' }}>
                          <strong> {`Terms & Conditions:`}</strong>
                        </div>
                        <div
                          sx={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <h1 style={{ fontSize: '8px', display: 'flex' }}>
                            {Store?.getStoreAddress().termsAndCondition ? (
                              <HTMLRenderer
                                html={
                                  Store?.getStoreAddress().termsAndCondition
                                }
                              />
                            ) : (
                              'Terms And Condition'
                            )}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OrderRecipt;
