import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Header from '../../component/header/Header';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MandiChalanItems from '../../component/mandiChalanItems/MandiChalanItems';
import { Add, ArrowLeft, PeopleAlt } from '@mui/icons-material';
import ProductItemDrawer from '../../component/productItemDrawer/ProductItemDrawer';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import { useRef } from 'react';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Vendor from '../../modules/Vendor';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import PurchaseOrders from '../../modules/PurchaseOrders';
import DatePickerComp from '../../component/datePicker/DatePicker';
import dateConveter from '../../modules/DateConveter/dateConveter';
import MandiChalan from '../../modules/MandiChalan';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { serverConfig } from '../../config/server-config';
import EditIcon from '@mui/icons-material/Edit';
import WarningDialog from '../../component/warningDialog/WarningDialog';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { debounce } from '../../utils';
import { Loading } from '../../component/loading/Loading';

function NewChalan() {
  const { state } = useLocation();

  let navigate = useNavigate();
  const listRef = React.useRef(null);
  let fdetails = {
    pack: '',
    freight: null,
    selfAmount: null,
    labour: null,
  };
  const page_size = 18;
  const [groverFreightDetails, setGroverFreightDetails] = React.useState(
    state?.chalan?.freightDetails || [fdetails]
  );
  const [openItemSelectionDialog, setOpenItemSelectionDialog] = React.useState({
    open: false,
    subGrowerName: '',
  });
  const [isDrawerOpened, SetIsDrawerOpened] = React.useState(false);
  const [selectedProductItemList, setSelectedProductItemList] = React.useState(
    state?.chalan?.subChallan || []
  );

  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [addAccordion, setAddAccordion] = React.useState(
    state?.chalan?.subChallan || []
  );
  const [groverDialog, setGroverDialog] = React.useState(false);
  const [groverName, setGroverName] = React.useState({
    name: '',
    id: 0,
  });
  const [vendorDataList, setVendorDataList] = React.useState([]);
  const [vendorInfo, setVendorInfo] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [mandiChalanObj, setMandiChalanObj] = React.useState(
    state?.chalan || {}
  );
  const [updateChalanDetails, setUpdateChalanDetails] = React.useState(
    state?.chalan
  );
  const [packValue, setPackValue] = React.useState([]);
  const [chalanCreationDate, setChalanCreationDate] = React.useState(
    state.chalan.challanDate ? state.chalan.challanDate : new Date()
  );

  const [arrivalDate, setArrivaldate] = React.useState(
    state.chalan.arrivalDate ? state.chalan.arrivalDate : new Date()
  );
  const [challanNumber, setChallanNumber] = React.useState(
    state?.chalan?.challanNumber || ''
  );
  const [confirmMandiChalan, setConfirmMandiChalan] = React.useState(
    state?.chalan.status === 'CONFIRMED' ? true : false
  );
  const [dialogWarning, setDialogWarning] = React.useState(false);
  const [submittedButton, setSubmittedButton] = React.useState(
    state?.chalan?.status === 'CONFIRMED' ? true : false
  );
  const [watakGenerate, setWatakGenerate] = React.useState(false);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [pageNo, setPageNo] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };
  React.useEffect(() => {
    // let vendorData = JSON.parse(localStorage.getItem('storeVendorList'));
    // let vendorObj = vendorData?.find(
    //   item => item.id === mandiChalanObj?.vendorId
    // );

    // if (vendorObj) {
    //   setVendorInfo({
    //     ...vendorObj,
    //     ['vendorName']: vendorObj?.vendorName ? vendorObj?.vendorName : '',
    //   });
    // } else {
    if (state?.chalan) {
      getVendorByVendorId();
    }
    // }
    getAllVendors(pageNo);
    getAllVariatiesByType('packing');
  }, []);
  const ref = useRef();
  const handleAddRow = () => {
    const item = {
      pack: '',
      freight: null,
      selfAmount: null,
      labour: null,
    };

    setGroverFreightDetails([...groverFreightDetails, item]);
  };

  const getVendorByVendorId = () => {
    Vendor.getVendorByVendorId(mandiChalanObj?.vendorId, response => {
      if (response.status === 'success') {
        setVendorInfo(response.data);
      } else {
        setVendorInfo({});
      }
    });
  };

  const handleRemoveSpecificRow = idx => () => {
    if (groverFreightDetails.length <= 1) {
      openUpstoreSnackBar('cant Remove last Freight Detail ', 'error');
    } else {
      const NewRows = [...groverFreightDetails];
      NewRows.splice(idx, 1);
      setGroverFreightDetails(NewRows);
    }
  };
  const handleChange = (name, value, index) => {
    let newRow = [...groverFreightDetails];
    newRow[index][name] = value;
    setGroverFreightDetails([...newRow]);
  };

  const columns = [
    { id: 'itemName', label: 'Item Name', maxWidth: 200, align: 'left' },

    { id: 'packing', label: 'Packing', maxWidth: 100, align: 'center' },

    {
      id: 'chalanQuantity',
      label: 'Chalan Qty',
      maxWidth: 200,
      align: 'center',
    },

    {
      id: 'actualQuantity',
      label: 'Actual Qty',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'chalanWeight',
      label: 'Chalan Wt',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'actualWeight',
      label: 'Actual Wt',
      maxWidth: 100,
      align: 'left',
    },

    {
      id: 'labour',
      label: 'Labour',
      maxWidth: 200,
      align: 'left',
    },
    {
      id: 'freight',
      label: 'Freight',
      maxWidth: 200,
      align: 'left',
    },
    {
      id: 'amount',
      label: `Self Amount`,
      maxWidth: 150,
      align: 'left',
    },
  ];
  const freightColumns = [
    { id: 'packing', label: 'Packing', maxWidth: 90, align: 'left' },

    { id: 'freight', label: 'Freight', maxWidth: 100, align: 'left' },
    {
      id: 'amount',
      label: 'Self Amount',
      maxWidth: 110,
      align: 'left',
    },
    {
      id: 'labour',
      label: 'Labour',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Actions',
      maxWidth: 100,
      align: 'right',
    },
  ];
  const handleOrderDialog = name => {
    setOpenItemSelectionDialog({
      ...openItemSelectionDialog,
      open: true,
      subGrowerName: name,
    });
    SetIsDrawerOpened(true);
  };
  const handleSelectedProductItem = (selectedItem, product) => {
    const subGrower = selectedProductItemList?.find(
      element =>
        element?.subGrowerName === openItemSelectionDialog?.subGrowerName
    );
    let foundItem = subGrower?.challanDetails?.some(item => {
      return item?.itemNumber === selectedItem?.itemNumber;
    });

    if (foundItem === true) {
      openUpstoreSnackBar('Item already added', 'error');
      return;
    } else {
      openUpstoreSnackBar('Product added successfully', 'success');
      let updatedItem = {
        itemNumber: selectedItem.itemNumber,
        itemName: selectedItem.itemName,
        quantity: null,
        actualQuantity: '',
        weight: null,
        actualWeight: '',
        pack: '',
        variety: null,
        quality: null,
        freight: null,
        selfAmount: null,
      };

      let newItems = selectedProductItemList?.map(item => {
        let arrr = { ...item };
        if (item?.subGrowerName === openItemSelectionDialog?.subGrowerName) {
          arrr.challanDetails?.push(updatedItem);
          return arrr;
        } else {
          return item;
        }
      });

      // creating extra array variable to pass to calculate total values because state updation takes time
      let productListForPassingCalculateValue = newItems;

      setSelectedProductItemList(productListForPassingCalculateValue);
      // calculateTotalValues(productListForPassingCalculateValue);
    }
  };
  const closeItemSelectionForOrder = () => {
    setOpenItemSelectionDialog({ ...openItemSelectionDialog, open: false });
  };
  const closeDrawerOrder = () => {
    SetIsDrawerOpened(false);
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const addChalanDetailsDataCallBack = (selectedItem, name) => {
    let newRow = selectedProductItemList?.map(row => {
      if (row.subGrowerName === name) {
        const result = row?.challanDetails?.findIndex(
          item => item.itemNumber == selectedItem.itemNumber
        );
        row.challanDetails[result] = selectedItem;
      }
      let calculatedValues = PurchaseOrders.calculateSubgroverChalanDetails(
        row.challanDetails
      );
      let newRow = { ...row, ...calculatedValues };
      return newRow;
    });
    mandiChalanObjCalculation(newRow);
  };

  const addNewGrower = () => {
    if (groverName.name === '') {
      openUpstoreSnackBar('Please enter name   first', 'error');
    } else if (checkNames(groverName.name)) {
      openUpstoreSnackBar(
        'Name Cannot Be same for Multiple SubGrowers',
        'error'
      );
    } else {
      let arr = [...addAccordion];
      let newObj = {
        subGrowerName: groverName.name,
      };
      arr.push(newObj);
      setAddAccordion(arr);
      setGroverDialog(false);
      let newDetail = [...selectedProductItemList];
      let newObj1 = {
        subGrowerName: groverName.name,
        subGrowerNumber: addAccordion.length + 1,
        challanDetails: [],
      };
      newDetail.push(newObj1);
      setSelectedProductItemList(newDetail);
    }
  };
  // if the user types same name for multiple subgrowers
  const checkNames = name => {
    return addAccordion.some(item => item.subGrowerName === name);
  };

  const growerDialog = () => {
    setGroverDialog(true);
    setGroverName({ ...groverName, name: '' });
  };
  const getAllVendors = pageNo => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), pageNo, true, response => {
      if (response.status === 'success') {
        let filteredVendor = response.data.filter(item => {
          return item.active;
        });
        if (filteredVendor.length < page_size) {
          setHasMoreData(false);
        }
        setVendorDataList([...vendorDataList, ...filteredVendor]);
        setLoader(false);
      } else {
        setVendorDataList([]);
        setLoader(false);
      }
    });
  };
  const handleSelectVandor = vendor => {
    setVendorInfo(vendor);
  };
  const handleGroverAccChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //for Adding Packing
  const getAllVariatiesByType = type => {
    PurchaseOrders.getAllVariatiyByType(Auth.getStoreId(), type, response => {
      if (response.status === 'success') {
        //converting  array of stings to array of objects
        let myArrayQuality = response.data.stringArrayData.map(
          (str, index) => ({
            value: str,
            id: index + 1,
          })
        );
        if (response.data.type === 'packing') {
          setPackValue(myArrayQuality);
        }
      }
    });
  };
  const addVariatiesByStoreId = value => {
    let data = {
      storeId: Auth.getStoreId(),
      type: 'packing',
      variety: value,
    };

    PurchaseOrders.addVariatyByStoreId(data, response => {
      if (response.status === 'success') {
        getAllVariatiesByType('packing');
      }
    });
  };
  const handleSaveChallan = status => {
    if (challanFieldVal(mandiChalanObj)) {
      setSubmittedButton(true);
      let iDate;
      let aDate;
      dateConveter.convertDate(chalanCreationDate, response => {
        iDate = response.data;
      });
      dateConveter.convertDate(arrivalDate, response => {
        aDate = response.data;
      });
      let challanObj = mandiChalanObj;
      if (status === 'Drafted') {
        challanObj.challanDate = iDate;
        challanObj.arrivalDate = aDate;
        challanObj.status = status;
        challanObj.storeId = Auth.getStoreId();
      }
      if (status === 'CONFIRMED') {
        if (chalanCreationDate == null || chalanCreationDate == '') {
          openUpstoreSnackBar('Please select date first', 'error');
          return null;
        }
        challanObj.challanDate = iDate;
        challanObj.arrivalDate = aDate;
        challanObj.status = status;
        challanObj.storeId = Auth.getStoreId();
      }
      MandiChalan.addNewChalan(challanObj, response => {
        if (response.status === 'success') {
          if (status === 'Drafted') {
            let data = response.data;
            handleChallanId(data);
            openUpstoreSnackBar('chalan is saved to draft ', 'success');
          }
          if (status === 'CONFIRMED') {
            let newData = response.data;
            handleChallanId(newData);
            setConfirmMandiChalan(true);
            setSubmittedButton(true);
            setUpdateChalanDetails(response.data);
            setArrivaldate(response.data.arrivalDate);
            setChalanCreationDate(response.data.challanDate);
            setSelectedProductItemList(response.data.subChallan);
            setAddAccordion(response.data.subChallan);
            openUpstoreSnackBar('chalan  is confirmed successfully', 'success');
          }
        } else {
          openUpstoreSnackBar('error while saving chalan ', 'error');
        }
      });
    } else {
      openUpstoreSnackBar(
        'Packing & Quantity Or Weight   Cannot be empty ',
        'error'
      );
      setSubmittedButton(false);
    }
  };
  const onDateChange = newValue => {
    setChalanCreationDate(newValue);
  };
  const onArrivaldateCahnge = newValue => {
    setArrivaldate(newValue);
  };
  let freightDetails = {
    pack: '',
    freight: '',
    selfAmount: '',
    labour: '',
  };

  const createNewChalan = () => {
    setVendorInfo({});
    setSelectedProductItemList([]);
    setMandiChalanObj({});
    setConfirmMandiChalan(false);
    setChallanNumber('');
    setGroverFreightDetails([freightDetails]);
    setAddAccordion([]);
    state.chalan = '';
    setUpdateChalanDetails({});
    setSubmittedButton(false);
  };
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      setDialogWarning(false);
      setConfirmMandiChalan(false);
      setSubmittedButton(false);
    } else {
      setDialogWarning(false);
    }
  };
  const handleUpdateMandiChalan = () => {
    setSubmittedButton(true);
    let iDate;
    let aDate;
    dateConveter.convertDate(chalanCreationDate, response => {
      iDate = response.data;
    });
    dateConveter.convertDate(arrivalDate, response => {
      aDate = response.data;
    });

    updateChalanDetails.challanDate = iDate;
    updateChalanDetails.arrivalDate = aDate;
    updateChalanDetails.totalFreight = mandiChalanObj.totalFreight;
    updateChalanDetails.totalSelfAmount = mandiChalanObj.totalSelfAmount;
    updateChalanDetails.totalQuantity = mandiChalanObj.totalQuantity;
    updateChalanDetails.totalActualQuantity =
      mandiChalanObj.totalActualQuantity;
    updateChalanDetails.freightDetails = groverFreightDetails;
    updateChalanDetails.subChallan = mandiChalanObj.subChallan;
    updateChalanDetails.totalActualWeight = mandiChalanObj.totalActualWeight;
    updateChalanDetails.totalWeight = mandiChalanObj.totalWeight;

    MandiChalan.updateChalanById(
      updateChalanDetails,
      updateChalanDetails.id,
      response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('challan Updated Sucessfully', 'success');
          setMandiChalanObj(response.data);
          setUpdateChalanDetails(response.data);
          setConfirmMandiChalan(true);
          setArrivaldate(response.data.arrivalDate);
          setChalanCreationDate(response.data.challanDate);
        } else {
          console.log('Error while Updating Chalan ');
        }
      }
    );
  };
  const handleGenerateWatakChalan = () => {
    setSubmittedButton(true);
    setWatakGenerate(true);
    MandiChalan.generateWatak(updateChalanDetails.id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Watak  generated Sucessfully', 'success');
        setSubmittedButton(true);
        setConfirmMandiChalan(true);
      } else {
        console.log('error while generating Watak');
      }
    });
  };

  const mandiChalanObjCalculation = newRow => {
    let totalCalculatedValues = PurchaseOrders.calculateTotalValues(newRow);
    setSelectedProductItemList(newRow);
    setMandiChalanObj({
      ...mandiChalanObj,
      totalFreight: totalCalculatedValues.totalFreight,
      totalSelfAmount: totalCalculatedValues.totalSelfAmount,
      totalQuantity: totalCalculatedValues.totalQuantity,
      totalActualQuantity: totalCalculatedValues.totalActualQuantity,
      totalActualWeight: totalCalculatedValues.totalActualWeight,
      totalWeight: totalCalculatedValues.totalWeight,
      grower: vendorInfo.name,
      vendorId: vendorInfo.id,
      freightDetails: groverFreightDetails,
      subChallan: newRow,
      challanNumber: challanNumber,
    });
  };

  const handleRemoveSubGrover = GrovName => {
    const NewRows = selectedProductItemList.filter(item => {
      return item.subGrowerName !== GrovName;
    });
    setAddAccordion(NewRows);
    mandiChalanObjCalculation(NewRows);
  };

  const removeItemFromSelectionList = (itemData, name) => {
    let newRow = selectedProductItemList?.map(row => {
      if (row.subGrowerName === name) {
        const result = row?.challanDetails?.filter(
          item => item.itemNumber !== itemData.itemNumber
        );
        row.challanDetails = result;
      }
      let calculatedValues = PurchaseOrders.calculateSubgroverChalanDetails(
        row.challanDetails
      );
      let newRow = {
        ...row,
        ...calculatedValues,
        challanId: updateChalanDetails.id,
      };
      return newRow;
    });
    mandiChalanObjCalculation(newRow);

    if (itemData.id) {
      deleteChalanItem(itemData.id);
    }
  };
  const deleteChalanItem = id => {
    MandiChalan.deleteChallanDetailsById(id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('item deleted   Sucessfully', 'success');
      } else {
        openUpstoreSnackBar('item not deleted ', 'error');
      }
    });
  };
  const handleChallanId = data => {
    let newData = data.subChallan.map(item => {
      return { ...item, challanId: data.id };
    });
    let freightDetail = data.freightDetails.map(item => {
      return { ...item, challanId: data.id };
    });
    setMandiChalanObj({
      ...data,
      subChallan: newData,
      freightDetails: freightDetail,
    });
    setSelectedProductItemList(newData);
  };
  //validation for empty chalan details
  const challanFieldVal = data => {
    return data?.subChallan?.every(item => {
      return item?.challanDetails?.every(validateChalanDetails);
    });
  };

  function validateChalanDetails(element) {
    return (
      (element?.pack !== '' && element?.actualQuantity !== '') ||
      (element?.pack !== '' && element?.actualWeight !== '')
    );
  }

  const handleSearchVendor = event => {
    let search = event?.target?.value;
    if (search) {
      Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
        if (response.status === 'success') {
          let filteredSearchActive = response.data.filter(item => {
            return item.active === true;
          });

          setVendorDataList(filteredSearchActive);
        }
        if (response.status === 'error') {
          getAllVendors(pageNo);
        }
      });
    }
  };
  const handleVendorChange = React.useCallback(
    debounce(handleSearchVendor, 300),
    []
  );

  const handleScroll = () => {
    const list = listRef.current;
    if (
      list.scrollTop + list.clientHeight >= list.scrollHeight &&
      hasMoreData
    ) {
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;

      setPageNo(pageNum);
      getAllVendors(pageNum);
    }
  };
  return (
    <div>
      <Header />
      <Grid sx={{ ml: 8, mt: 8 }}>
        <Grid sx={{ ml: 1 }} item md={12} sm={12} xs={12}>
          <BreadCrumb pageName='Mandi Chalan' />
        </Grid>
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item md={6} sx={{ mb: '10px' }}>
                <Tooltip title='Go to orders' placement='bottom'>
                  <Button
                    startIcon={<ArrowLeft />}
                    variant='text'
                    underline='none'
                    sx={{
                      padding: 0,
                      mb: 1,
                      ml: 2,
                      textTransform: 'capitalize',
                      width: '146px',
                      height: '27px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Grover Chalan List
                  </Button>
                </Tooltip>{' '}
              </Grid>
              <Grid
                item
                md={6}
                sx={{ display: 'flex', justifyContent: 'space-around' }}
              >
                {confirmMandiChalan && serverConfig.isUpstoreCloud ? (
                  <Tooltip title='Edit Confirmed Chalan' placement='bottom'>
                    <Button
                      startIcon={
                        <EditIcon sx={{ color: '#fff', fontSize: '13px' }} />
                      }
                      variant='contained'
                      sx={{
                        width: '126px',
                        height: '27px',
                        textTransform: 'capitalize',
                        p: 0,
                        bgcolor: '#3B78C9',
                      }}
                      onClick={() => {
                        setDialogWarning(true);
                      }}
                    >
                      <Typography noWrap>Edit Chalan</Typography>
                    </Button>
                  </Tooltip>
                ) : null}
                <Grid>
                  {confirmMandiChalan ? (
                    <Tooltip title='Create New Chalan' placement='bottom'>
                      <Button
                        variant='contained'
                        sx={{
                          width: '126px',
                          height: '27px',
                          textTransform: 'capitalize',

                          bgcolor: '#3B78C9',
                        }}
                        onClick={createNewChalan}
                      >
                        New Chalan
                      </Button>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex' }}>
              <Grid item xs={10} sm={5} md={4} lg={4} sx={{ width: 500, p: 1 }}>
                <Typography variant='subtitle1' sx={{ ml: -1 }}>
                  Grower Name/Phone{' '}
                  <Tooltip title='Mandatory Field' placement='top'>
                    <span style={{ color: 'red' }}>*</span>
                  </Tooltip>
                </Typography>
                <Autocomplete
                  // freeSolo
                  ListboxProps={{
                    ref: listRef,
                    onScroll: ({ currentTarget }) => {
                      handleScroll(currentTarget);
                    },
                  }}
                  id='vendorList'
                  options={vendorDataList}
                  disableClearable
                  defaultValue=''
                  onChange={(event, newValue) => {
                    if (newValue == 121) {
                      return null;
                    }
                    handleSelectVandor(newValue);
                  }}
                  onInputChange={e => handleVendorChange(e)}
                  clearOnBlur={true}
                  getOptionLabel={option =>
                    typeof option == 'string'
                      ? (option = '')
                      : option !== undefined
                      ? `${option.name} ` || {}
                      : ''
                  }
                  value={
                    Object.keys(selectedProductItemList).length > 0
                      ? vendorInfo || {}
                      : ''
                  }
                  disabled={
                    state?.chalan?.status === 'CONFIRMED' || confirmMandiChalan
                  }
                  renderOption={(props, option) => {
                    return (
                      <>
                        <div {...props} key={option.id}>
                          <span
                            key={option.id}
                            style={{ paddingRight: '10px' }}
                          >
                            <PeopleAlt fontSize='small' />
                          </span>
                          {`${option.name}`}
                          <li
                            style={{ color: 'gray', marginLeft: 'auto' }}
                            key={option.id}
                            {...props}
                          >{`${
                            option.contactNo !== null ? option.contactNo : ''
                          } `}</li>
                        </div>
                      </>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      // sx={textField}
                      placeholder='Search Vendor'
                      size='small'
                      sx={{
                        width: '90%',
                        marginTop: '6px',
                        backgroundColor: '#E1EEF5',
                        borderTop: '5px solid #E1EEF5',
                        '& .MuiInputBase-root': {
                          paddingRight: '39px !important',
                        },
                      }}
                      variant='outlined'
                      autoFocus
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                sx={{
                  mb: 2,
                  ml: 'auto',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  item
                  md={8}
                  lg={8}
                  sx={{ mt: 1, mr: 1, ml: 'auto', maxWidth: 400 }}
                >
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 400,
                        flexWrap: 'wrap',
                        mt: 1,
                      }}
                      size='small'
                    >
                      <TableHead>
                        <TableRow>
                          {freightColumns?.map(column => {
                            return (
                              <>
                                {column.label !== null ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      maxWidth: column.maxWidth,
                                      fontSize: 'small',
                                      fontWeight: 'bold',
                                      whiteSpace: 'nowrap',
                                      backgroundColor: '#E1EEF5',
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : null}
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groverFreightDetails?.map((item, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <Autocomplete
                                disabled={confirmMandiChalan}
                                id='pack'
                                options={packValue}
                                sx={{ width: 110 }}
                                onChange={(event, newValue) => {
                                  handleChange('pack', newValue.value, idx);
                                }}
                                getOptionLabel={option =>
                                  typeof option.value == 'string' && option
                                    ? `${option.value} `
                                    : option
                                }
                                value={groverFreightDetails[idx]?.pack}
                                renderOption={(props, option) => {
                                  return (
                                    <>
                                      <div {...props} key={option.id}>
                                        {`${option.value}`}
                                      </div>
                                    </>
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    name='pack'
                                    size='small'
                                    id=' pack'
                                    onKeyPress={e => {
                                      if (e.key === 'Enter') {
                                        handleChange(
                                          'pack',
                                          e.target.value,
                                          idx
                                        );
                                        addVariatiesByStoreId(e.target.value);
                                      }
                                    }}
                                    {...params}
                                    variant='standard'
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id=' freight'
                                value={item?.freight}
                                name='freight'
                                variant='standard'
                                onChange={event =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value,
                                    idx
                                  )
                                }
                                disabled={confirmMandiChalan}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id='selfAmount'
                                value={item?.selfAmount}
                                name='selfAmount'
                                variant='standard'
                                onChange={event =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value,
                                    idx
                                  )
                                }
                                disabled={confirmMandiChalan}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id='labour'
                                value={item?.labour}
                                name='labour'
                                variant='standard'
                                onChange={event =>
                                  handleChange(
                                    event.target.name,
                                    event.target.value,
                                    idx
                                  )
                                }
                                disabled={confirmMandiChalan}
                              />
                            </TableCell>
                            <TableCell sx={{ display: 'flex' }}>
                              <CancelSharpIcon
                                onClick={handleRemoveSpecificRow(idx)}
                              />
                              &nbsp; &nbsp;
                              <AddCircleOutlineSharpIcon
                                onClick={handleAddRow}
                                sx={{ cursor: 'pointer' }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Challan Number</Typography>
                <TextField
                  sx={{
                    width: '90%',
                    marginTop: '6px',
                    backgroundColor: '#E1EEF5',
                    borderTop: '5px solid #E1EEF5',
                    mt: '-0.1px',
                  }}
                  value={challanNumber}
                  disabled={confirmMandiChalan}
                  id='challanNumber'
                  variant='outlined'
                  size='small'
                  name='challanNumber'
                  onChange={e => setChallanNumber(e.target.value)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Chalan Date</Typography>
                <DatePickerComp
                  value={chalanCreationDate}
                  maxDate={new Date()}
                  dateChange={onDateChange}
                  disabled={confirmMandiChalan}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Arrival Date</Typography>
                <DatePickerComp
                  value={arrivalDate}
                  dateChange={onArrivaldateCahnge}
                  disabled={confirmMandiChalan}
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleGroverAccChange('panel1')}
              >
                <AccordionSummary
                  sx={{ backgroundColor: 'secondary.light' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Grower{' '}
                    <b>
                      <Link
                        to='/vendor-details'
                        state={vendorInfo}
                        style={{ textDecoration: 'none' }}
                      >
                        {vendorInfo?.name}
                      </Link>
                    </b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Grower Name</Typography>
                      <TextField
                        sx={textField}
                        id='name'
                        // multiline
                        // rows="2"
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'name')
                            ? `${vendorInfo.name}`
                            : ''
                        }
                        // onChange={handleCustomerOnChange}
                        placeholder='Enter vendor name'
                        name='vendorName'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Contact No</Typography>
                      <TextField
                        sx={textField}
                        id='contactNo'
                        // multiline
                        // rows="2"
                        disabled={true}
                        // disabled={selected === true ? false : true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(
                            vendorInfo,
                            'contactNo'
                          )
                            ? `${vendorInfo.contactNo}`
                            : ''
                        }
                        // onChange={handleCustomerOnChange}
                        placeholder='Enter vendor contactNo'
                        name='contactNo'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Email</Typography>
                      <TextField
                        sx={textField}
                        id='email'
                        // multiline
                        // rows="2"
                        disabled={true}
                        // disabled={selected === true ? false : true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'email')
                            ? `${vendorInfo.email}`
                            : ''
                        }
                        // onChange={handleCustomerOnChange}
                        placeholder='Enter vendor email'
                        name='email'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Address</Typography>
                      <TextField
                        sx={textField}
                        id='address'
                        // multiline
                        // rows="2"
                        disabled={true}
                        // disabled={selected === true ? false : true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'address')
                            ? `${vendorInfo.address}`
                            : ''
                        }
                        // onChange={handleCustomerOnChange}
                        placeholder='Enter address'
                        name='address'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>
                        opening Balance
                      </Typography>
                      <TextField
                        sx={textField}
                        id='openingBalance'
                        // multiline
                        // rows="2"
                        disabled={true}
                        // disabled={selected === true ? false : true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(
                            vendorInfo,
                            'openingBalance'
                          )
                            ? `${vendorInfo.openingBalance}`
                            : ''
                        }
                        // onChange={handleCustomerOnChange}
                        placeholder='Enter openingBalance '
                        name='openingBalance'
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {vendorInfo.name ? (
                <>
                  {addAccordion?.map((point, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        sx={{ backgroundColor: 'secondary.light' }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          Sub Grower &nbsp;<b>{point?.subGrowerName}</b>
                        </Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography>
                          Sub Grower No <b> {index + 1}</b>
                        </Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {updateChalanDetails?.status === 'CONFIRMED' ? (
                          <Typography>
                            S.No :<b> {point?.subChallanNo}</b>
                          </Typography>
                        ) : (
                          ''
                        )}
                        <Box sx={{ ml: 'auto' }}>
                          <Button size='small' disabled={confirmMandiChalan}>
                            <RemoveCircleIcon
                              onClick={() =>
                                handleRemoveSubGrover(point?.subGrowerName)
                              }
                            />
                          </Button>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item md={12} sx={{ mt: 2 }}>
                          <TableContainer
                            sx={{
                              maxHeight: 450,
                              overflow: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                width: '5px',
                                backgroundColor: '#83b1cf',
                              },
                            }}
                            component={Paper}
                            className='scroll'
                          >
                            <Table
                              stickyHeader
                              aria-label='sticky table'
                              sx={{
                                backgroundColor: 'secondary.light',
                                position: 'relative',
                              }}
                              size='small'
                            >
                              <TableHead>
                                <TableRow>
                                  {columns?.map(column => {
                                    return (
                                      <>
                                        {column.label !== null ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              fontSize: 'medium',
                                              fontWeight: '500',
                                              fontSize: 'large',
                                              whiteSpace: 'nowrap',
                                              backgroundColor: '#E1EEF5',
                                            }}
                                          >
                                            {column.label}
                                          </TableCell>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedProductItemList?.length > 0
                                  ? selectedProductItemList?.map(row => {
                                      if (
                                        row?.subGrowerName ===
                                        point?.subGrowerName
                                      ) {
                                        return row?.challanDetails?.map(
                                          item => {
                                            return (
                                              <MandiChalanItems
                                                key={item?.itemNumber}
                                                row={item}
                                                name={point?.subGrowerName}
                                                AddChalanDetailsDataCallBack={
                                                  addChalanDetailsDataCallBack
                                                }
                                                getAllVariatiesByType={
                                                  getAllVariatiesByType
                                                }
                                                packingData={packValue}
                                                groverFreightDetails={
                                                  groverFreightDetails
                                                }
                                                type='chalan'
                                                confirmChalan={
                                                  confirmMandiChalan
                                                }
                                                snackbar={openUpstoreSnackBar}
                                                removeItemFromSelectionList={
                                                  removeItemFromSelectionList
                                                }
                                              />
                                            );
                                          }
                                        );
                                      }
                                    })
                                  : null}

                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  sx={{ backgroundColor: '#fff' }}
                                >
                                  <TableCell colSpan={10} align='left'>
                                    <Button
                                      variant='contained'
                                      startIcon={<Add />}
                                      style={{ marginTop: '0px' }}
                                      sx={{
                                        // TODO Move to .css
                                        textTransform: 'capitalize',
                                        height: '27px',

                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        outline: 'none',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        mt: 2,
                                        '&:hover': {
                                          backgroundColor: '#FCFCFC',
                                        },
                                      }}
                                      disabled={submittedButton}
                                      onClick={() => {
                                        handleOrderDialog(point?.subGrowerName);
                                        ref?.current?.cleanValue();
                                      }}
                                    >
                                      Add New Item
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableBody>

                              <TableFooter
                                sx={{
                                  backgroundColor: '#E1EEF5',
                                  position: 'sticky',
                                  bottom: 0,
                                }}
                              >
                                {selectedProductItemList?.map(row => {
                                  if (
                                    row?.subGrowerName === point?.subGrowerName
                                  ) {
                                    return (
                                      <TableRow key={row?.subGrowerName}>
                                        <TableCell
                                          style={{
                                            maxWidth: 100,
                                            fontSize: 'large',
                                            fontWeight: 'bold',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          Total
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.subGrowerTotalQuantity}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerActualQuantity}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerTotalWeight}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerActualWeight}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.labour?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.subGrowerFreight?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.subGrowerSelfAmount?.toFixed(
                                            2
                                          )}{' '}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                })}
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  {challanNumber ? (
                    <>
                      <Button
                        variant='contained'
                        disabled={submittedButton}
                        sx={{
                          width: '126px',
                          height: '27px',
                          textTransform: 'capitalize',
                          mt: 2,
                          mr: 2,
                          p: 0,
                          bgcolor: '#3B78C9',
                        }}
                        onClick={() => growerDialog()}
                      >
                        <Typography noWrap> Add Sub Grower</Typography>
                      </Button>
                    </>
                  ) : null}
                </>
              ) : null}

              {selectedProductItemList[0]?.challanDetails?.length > 0 &&
              challanNumber ? (
                <>
                  <Button
                    disabled={
                      submittedButton ||
                      updateChalanDetails?.status === 'CONFIRMED'
                    }
                    variant='contained'
                    sx={{
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                      float: 'right',
                      mt: 2,
                      mr: 2,
                      p: 0,
                      bgcolor: '#3B78C9',
                    }}
                    onClick={() => {
                      handleSaveChallan('CONFIRMED');
                    }}
                  >
                    <Typography noWrap> Confirm Chalan</Typography>
                  </Button>
                  <Button
                    disabled={
                      submittedButton ||
                      updateChalanDetails?.status === 'CONFIRMED'
                    }
                    variant='contained'
                    sx={{
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                      float: 'right',
                      mt: 2,
                      mr: 2,
                      p: 0,
                      bgcolor: '#3B78C9',
                    }}
                    onClick={() => handleSaveChallan('Drafted')}
                  >
                    <Typography noWrap>Draft Challan</Typography>
                  </Button>
                </>
              ) : null}
              {updateChalanDetails?.status === 'CONFIRMED' ? (
                <Button
                  disabled={submittedButton}
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    float: 'right',
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleUpdateMandiChalan}
                >
                  {`Update`}
                </Button>
              ) : null}
              {updateChalanDetails?.status === 'CONFIRMED' &&
              !updateChalanDetails.isWatakGenerated ? (
                <Button
                  disabled={
                    watakGenerate || updateChalanDetails.isWatakGenerated
                  }
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    float: 'right',
                    mt: 2,
                    mr: 2,
                    p: 0,
                  }}
                  onClick={handleGenerateWatakChalan}
                >
                  <Typography noWrap>{`Create Watak`}</Typography>
                </Button>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>

      {openItemSelectionDialog.open ? (
        <ProductItemDrawer
          selectedProductItemCallback={handleSelectedProductItem}
          openDialog={openItemSelectionDialog.open}
          closePopup={closeItemSelectionForOrder}
          productItemList={selectedProductItemList?.challanDetails}
          snackbar={openUpstoreSnackBar}
          drawerOpened={isDrawerOpened}
          closeDrawer={closeDrawerOrder}
          ref={ref}
        />
      ) : null}
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      {dialogWarning ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
          openDialog={dialogWarning}
          content='This chalan is confirmed , Are you sure you want to edit it..'
        />
      ) : null}
      {groverDialog ? (
        <Dialog
          open={groverDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
        >
          <DialogContent>
            <Typography
              variant='subtitle'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Sub Grover Name
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant='subtitle2'>
                Select Grover as default subGrower
              </Typography>
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    setGroverName({ ...groverName, name: vendorInfo.name });
                  } else {
                    setGroverName({ ...groverName, name: '' });
                  }
                }}
              />
            </Box>

            <Grid
              item
              md={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <TextField
                sx={{
                  width: '80%',
                  marginTop: '6px',
                }}
                id='name'
                variant='outlined'
                size='small'
                placeholder='Enter Sub Grover name'
                value={groverName.name}
                name='groverName'
                onChange={e =>
                  setGroverName({ ...groverName, name: e.target.value })
                }
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              sx={{ height: '27px' }}
              onClick={() => setGroverDialog(false)}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              sx={{ height: '27px' }}
              variant='outlined'
              onClick={addNewGrower}
            >
              Add SubGrower
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}

export default NewChalan;
