import React from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import HTMLRenderer from 'react-html-renderer';
import * as moment from 'moment';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded';

function DashboardCards({ type, Data, currency }) {
  let newDate = new Date();

  const todaysDate = moment(newDate).format('YYYY-MM-DD');
  const todaysDateP = moment(newDate).format('DD-MMM-YY');

  newDate = new Date();
  let weeklyStartDate1 =
    newDate.getDate() - newDate.getDay() + (newDate.getDay() === 0 ? -6 : 1);
  weeklyStartDate1 = new Date(newDate.setDate(weeklyStartDate1));
  let weeklyStartDate = moment(weeklyStartDate1).format('YYYY-MM-DD');
  let weeklyStartDateP = moment(weeklyStartDate).format('DD-MMM-YY');
  newDate = new Date();

  let monthlyStartDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

  monthlyStartDate = moment(monthlyStartDate).format('YYYY-MM-DD');
  let monthlyStartDateP = moment(monthlyStartDate).format('DD-MMM-YY');
  return (
    <>
      <Grid item md={4} sm={4} xs={12}>
        <Box
          className="boxColor"
          sx={{
            mt: 3,
            ml: { md: 5, sm: 2, xs: 0 },
            p: { md: 3, sm: 1.5, xs: 2 },

            maxWidth: '350px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: { md: '20px', sm: '14px', xs: '10px' },

              color: '#fff',
            }}
          >
            {type === 'DAILY' ? (
              <TodayRoundedIcon sx={{ color: 'white' }} />
            ) : type === 'Weekly' ? (
              <DateRangeRoundedIcon sx={{ color: 'white' }} />
            ) : (
              <CalendarViewMonthRoundedIcon sx={{ color: 'white' }} />
            )}
            {type === 'DAILY'
              ? 'Daily Updates'
              : type === 'Weekly'
              ? 'Weekly Updates'
              : 'Monthly Updates'}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontSize: '12px',
              textAlign: 'left',
              pt: 1,
            }}
          >
            {type === 'DAILY'
              ? todaysDateP
              : type === 'Weekly'
              ? ` ${weeklyStartDateP.substring(0, 10)} - ${todaysDateP}`
              : `  ${monthlyStartDateP.toString()} - 
              ${todaysDateP.toString()}`}
          </Typography>

          {Data.map(obj => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                {obj.label}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                {obj.value === 0 || obj.value === null || obj.value === ''
                  ? `0`
                  : obj.value}
                &nbsp;
                {obj.showCurrency && currency ? (
                  <HTMLRenderer html={currency} />
                ) : null}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
}

export default DashboardCards;
