import React from 'react';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Auth from '../../modules/Auth';
import PurchaseOrders from '../../modules/PurchaseOrders';
import AddIcon from '@mui/icons-material/Add';
import { Loading } from '../../component/loading/Loading';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import DeleteIcon from '@mui/icons-material/Delete';
import { Close, PeopleAlt, Print } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import CreateOrUpdateVendorOrder from '../../component/createOrUpdateVendorOrder/CreateOrUpdateVendorOrder';
import HTMLRenderer from 'react-html-renderer';
import Store from '../../modules/Store';
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  Box,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogContent,
} from '@mui/material';
import moment from 'moment';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import PurchaseOrderInvoice from '../../component/purchaseOrderInvoice/PurchaseOrderInvoice';
import DraftedPurchaseOrderInvoice from '../../component/draftedPurchaseOrderInvoice/DraftedPurchaseOrderInvoice';
import PurchaseOrderRecipt from '../../component/purchaseOrderReciept/PurchaseOrderReciept';
import Vendor from '../../modules/Vendor';
import { debounce } from '../../utils';

function PurchaseOrder() {
  const [reloadOrder, setReloadOrder] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const [purchaseOrderList, setPurchaseOrderList] = React.useState([]);
  const [createVendorOrder, setCreateVendorOrder] = React.useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });

  const [searchOrder, setSearchOrder] = React.useState(true);

  const [pageNumber, setPageNumber] = React.useState(0);
  const [deletedItems, setDeletedItems] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    IdOfDeletableOrder: '',
    orderNumber: '',
    customerId: '',
  });
  const [updateVendorOrderObj, setUpdateVendorOrder] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [deletedOrders, setDeletedOrders] = React.useState(false);
  const [orderInvoice, setOrderInvoice] = React.useState({
    open: false,
    drafted: false,
  });
  const [clickInv, setClickInv] = React.useState(false);
  const [orderRecipt, setOrderRecipt] = React.useState({ open: false });

  React.useEffect(() => {
    getAllPurchaseOrdersByStoreId(0);
    getCurrency();
  }, [deletedOrders]);
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const createOrUpdateVendorOrder = orderRow => {
    if (orderRow == false) {
      setCreateVendorOrder(true);
    } else {
      let orders = orderRow;
      PurchaseOrders.getAllPurchaseOrderDetailsById(orderRow?.id, response => {
        if (response.status === 'success') {
          const ordrDlt1 = response?.data.filter(item => {
            return !item.deleted;
          });

          let deletedItems = response?.data.filter(item => {
            return item.deleted;
          });

          orders.purchaseOrderDetails = ordrDlt1;

          const vendorData = JSON.parse(
            localStorage.getItem('storeVendorList')
          );
          if (vendorData?.length > 0) {
            const vendorObj = vendorData?.find(
              vendor => vendor.id === orders.vendorId
            );
            orders.vendor = vendorObj;
          } else {
            Vendor.getVendor(orders?.vendorId, response => {
              if (response.status === 'success') {
                orders.vendor = response.data;
              }
            });
          }

          setUpdateVendorOrder(orders);
          setDeletedItems(deletedItems);
          setTimeout(() => {
            setCreateVendorOrder(true);
          }, 10);
        }
      });
    }
  };

  const reloadCreatePurchaseOrder = () => {
    setUpdateVendorOrder('');
    setDeletedItems({});
    setReloadOrder(false);
    setTimeout(() => {
      setReloadOrder(true);
    }, 10);
  };

  const handleSearchPurchaseOrders = debounce(
    e => handleSearchPurchaseOrder(e),
    300
  );

  const handleSearchPurchaseOrder = data => {
    PurchaseOrders.searchByNameOrPurchaseOrderNumber(
      Auth.getStoreId(),
      data,
      response => {
        if (response.status === 'success') {
          if (deletedOrders) {
            let deleted = response.data.filter(item => item.deleted);
            let sortedBillData = deleted.sort(
              (a, b) => b.purchaseOrderNo - a.purchaseOrderNo
            );

            setPurchaseOrderList(sortedBillData);
          } else {
            let notDeleted = response.data.filter(item => !item.deleted);
            let sortedBillData = notDeleted.sort(
              (a, b) => b.purchaseOrderNo - a.purchaseOrderNo
            );

            setPurchaseOrderList(sortedBillData);
          }
        }
        if (response.status === 'error') {
          getAllPurchaseOrdersByStoreId(0);
        }
      }
    );
  };

  const handleDeleteOrderCallback = usersChoice => {
    if (usersChoice === true) {
      PurchaseOrders.deleteVendorStoreOrder(
        openDeleteDialog.IdOfDeletableOrder,
        response => {
          if (response.status === 'success') {
            // Transaction.log(
            //   Auth.getStoreId(),
            //   openDeleteDialog.customerId,
            //   LogMessage.ORDER_DELETED,
            //   LogMessage.ORDER_DELETED_TITLE,
            //   [openDeleteDialog.orderNumber, Auth.getUserName()]
            // );
            openUpstoreSnackBar('Order was deleted successfully', 'success');
            // getAllOrdersList(pageNumber);
            getAllPurchaseOrdersByStoreId(pageNumber);
            setOpenDeleteDialog(false);
          } else {
            openUpstoreSnackBar('There is an issue in deleting order', 'error');
          }
        }
      );
    }
  };
  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const columns = [
    {
      field: 'PurchaseOrderNo',
      headerName: 'Purchase O.No',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          <Typography
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
            onClick={event => {
              if (params.row.deleted) {
                openUpstoreSnackBar(
                  `Purchase Order is deleted you can't edit or update `,
                  'error'
                );
              } else {
                createOrUpdateVendorOrder(params.row);
              }
            }}
          >
            {params.row.purchaseOrderNo}
          </Typography>
          <Box sx={{ pl: 3 }}>
            {params.row.edited && (
              <Tooltip title='Edited'>
                <EditIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            )}

            {params.row.returned && (
              <Tooltip title='Return Order is Present'>
                <KeyboardReturnIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            )}
          </Box>
        </>
      ),
    },

    {
      field: 'name',
      headerName: 'Vendor Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Tooltip title={params?.row?.vendorName}>
            <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
              {`${params.row?.vendorName}`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'billNo',
      headerName: 'Bill No',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',

      renderCell: params => (
        <>
          <Typography>
            {params.row.totalAmount}{' '}
            {currency && <HTMLRenderer html={currency} />}
          </Typography>
        </>
      ),
    },
    {
      field: 'BillDate',
      headerName: 'Bill Date',
      type: 'date',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params =>
        params.row.billDate === null
          ? 'N / A'
          : moment.utc(params.row.billDate).local().format('DD-MM-yyyy'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.status === 'CONFIRMED' ? 'Confirmed' : 'Drafted',
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              {params.row.status === 'CONFIRMED' ? (
                <Tooltip title='Download Invoice' placement='bottom'>
                  <DownloadIcon
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                    onClick={event => downloadInvoice(params.row, false)}
                  />
                </Tooltip>
              ) : (
                <DownloadIcon
                  onClick={event => downloadInvoice(params.row, true)}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              )}
              {params.row.status === 'CONFIRMED' ? (
                <Tooltip title='Download Recipt' placement='bottom'>
                  <Print
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                    onClick={event => downloadRecipt(params.row)}
                  />
                </Tooltip>
              ) : (
                // <Print sx={{ color: 'lightGray' }} />
                <h6 style={{ color: 'lightGray' }}>pt</h6>
              )}
              <Tooltip title='Delete Order' placement='bottom'>
                <DeleteIcon
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  onClick={event => {
                    if (params.row.deleted) {
                      openUpstoreSnackBar(
                        'Purchase Order is already been deleted ',
                        'error'
                      );
                    } else if (params.row.orderFromEstore) {
                      openUpstoreSnackBar(
                        'Order from E-Store Cannot Be Deleted ',
                        'error'
                      );
                    } else {
                      setOpenDeleteDialog({
                        open: true,
                        IdOfDeletableOrder: params.row.id,
                        orderNumber: params.row.purchaseOrderNo,
                        customerId: params.row.customerId,
                        status: params.row.status,
                      });
                    }
                  }}
                />
              </Tooltip>
            </>
          </Box>
        </>
      ),
    },
  ];

  const downloadInvoice = (orderDetails, drafted) => {
    PurchaseOrders.getAllPurchaseOrderDetailsById(
      orderDetails?.id,
      response => {
        if (response.status === 'success') {
          let newOrderDetails = { ...orderDetails };
          newOrderDetails.purchaseOrderDetails = response?.data;
          let deletedItems = response?.data.filter(item => {
            return !item.deleted;
          });
          newOrderDetails.purchaseOrderDetails =
            newOrderDetails.deleted === true
              ? orderDetails.purchaseOrderDetails
              : deletedItems;

          getCustomerName(newOrderDetails);
        }
      }
    );
  };
  const getCustomerName = (newOrderDetails, drafted) => {
    setClickInv(true);
    Vendor.getVendorByVendorId(newOrderDetails.vendorId, response => {
      if (response.status === 'success') {
        let orderInvoiceObj = {
          open: true,
          order: {
            ...newOrderDetails,
            vendorName: response?.data?.name,
            address: response?.data?.address,
            gstNo: response?.data?.gstNo,
            state: response?.data?.state,
          },
          drafted: drafted,
        };
        setClickInv(false);
        setOrderInvoice(orderInvoiceObj);
      } else {
        setClickInv(false);
        setOrderInvoice({});
      }
    });
  };
  const closeInvoiceDialog = orderDetails => {
    let orderInvoiceObj = {
      open: false,
      drafted: false,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const handlePreviousPage = () => {
    let pageNum = pageNumber - 20;
    setPageNumber(pageNum);
    getAllPurchaseOrdersByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 20;
    setPageNumber(pageNum);
    getAllPurchaseOrdersByStoreId(pageNum);
  };
  const getAllPurchaseOrdersByStoreId = pageNum => {
    setLoader(true);
    let deleted = deletedOrders;
    PurchaseOrders.getAllPurchaseOrderByStoreId(
      Auth.getStoreId(),
      deleted,
      pageNum,
      response => {
        if (response.status === 'success') {
          let sortVenOrder = response.data.sort(function (a, b) {
            return b.purchaseOrderNo - a.purchaseOrderNo;
          });
          setLoader(false);
          setPurchaseOrderList(sortVenOrder);
        }
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDeletedOrders(false);
    } else {
      setDeletedOrders(true);
    }
  };

  const downloadRecipt = orderDetails => {
    PurchaseOrders.getAllPurchaseOrderDetailsById(
      orderDetails?.id,
      response => {
        if (response.status === 'success') {
          let newOrderDetails = { ...orderDetails };
          let deletedItems = response?.data.filter(item => {
            return !item.deleted;
          });
          newOrderDetails.purchaseOrderDetails = deletedItems;
          let orderReciptObj = {
            open: true,
            order: newOrderDetails,
          };

          setOrderRecipt(orderReciptObj);
        }
      }
    );
  };

  const closeReciptDialog = () => {
    let orderReciptObj = {
      open: false,
    };
    setOrderRecipt(orderReciptObj);
    document.title = 'RSL | Up-Store';
  };
  return (
    <>
      <Header pageName='Purchase-Orders' />
      <Box
        sx={{
          width: '95%',
          height: 600,
          mt: 8,
          ml: 8,

          '& .super-app-theme--header': {
            backgroundColor: '#EFFAFF',

            fontSize: '18px',
          },
        }}
      >
        {loader ? (
          <Loading />
        ) : (
          <>
            {createVendorOrder ? (
              <>
                {reloadOrder && (
                  <CreateOrUpdateVendorOrder
                    updateOrder={updateVendorOrderObj}
                    deletedItems={deletedItems}
                    setCreateVendorOrder={setCreateVendorOrder}
                    snackbar={openUpstoreSnackBar}
                    getLatestFetchedVendorOrders={() =>
                      getAllPurchaseOrdersByStoreId(0)
                    }
                    reloadPage={reloadCreatePurchaseOrder}
                  />
                )}
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <BreadCrumb pageName='Customers' />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={4}
                    sx={{
                      display: { xs: 'none', sm: 'none', md: 'block' },
                      mb: 1,
                    }}
                  >
                    <Box
                      display={'flex'}
                      justifyContent='space-around'
                      alignItems={'center'}
                    >
                      <TextField
                        sx={{ width: '100%' }}
                        name='search'
                        placeholder='Search Purchase Order'
                        onChange={event => {
                          handleSearchPurchaseOrders(event.target.value);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={4}
                    xs={4}
                    sx={{ textAlign: { md: 'center' } }}
                  >
                    <Tooltip title='Create new order' placement='bottom'>
                      <Button
                        sx={{
                          p: 0,
                          mb: 2,
                          textTransform: 'capitalize',

                          width: '126px',
                          height: '27px',
                          color: 'white',
                          bgcolor: '#3B78C9',
                        }}
                        endIcon={
                          <AddIcon fontSize='small' sx={{ color: 'white' }} />
                        }
                        variant='contained'
                        onClick={() => {
                          // location.state = null;
                          createOrUpdateVendorOrder('');
                          reloadCreatePurchaseOrder();
                        }}
                      >
                        Create PO
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label='Order Tabs'
                    >
                      <Tab label='Confirmed Orders' {...a11yProps(0)} />
                      <Tab label='Deleted Orders' {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Grid>
                {clickInv ? (
                  <Loading />
                ) : (
                  <>
                    <PurchaseOrderTab
                      columns={columns}
                      data={purchaseOrderList}
                      value={value}
                    />
                  </>
                )}
                <Grid
                  style={{
                    padding: '1.6vh',
                    float: 'right',

                    bottom: '0px',
                  }}
                >
                  <Button
                    disabled={pageNumber <= 0}
                    onClick={handlePreviousPage}
                    sx={{ color: '#3B78C9' }}
                  >
                    Prev
                  </Button>
                  <Button
                    disabled={purchaseOrderList.length < 20}
                    sx={{ margin: '1vh', color: '#3B78C9' }}
                    onClick={handleNextPage}
                  >
                    Next
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>

      {orderInvoice.open && (
        <Dialog maxWidth={'xl'} open={orderInvoice.open}>
          <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
            <Grid item>
              <Tooltip title='Close dialog' placment='right'>
                <Close
                  onClick={closeInvoiceDialog}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {orderInvoice.drafted ? (
            <DialogContent>
              <DraftedPurchaseOrderInvoice orderDetails={orderInvoice.order} />
            </DialogContent>
          ) : (
            <DialogContent>
              <PurchaseOrderInvoice orderDetails={orderInvoice.order} />
            </DialogContent>
          )}
        </Dialog>
      )}
      <Dialog
        maxWidth={'lg'}
        open={orderRecipt.open}
        onClose={closeReciptDialog}
      >
        <DialogContent>
          <PurchaseOrderRecipt orderDetails={orderRecipt.order} />
        </DialogContent>
      </Dialog>

      {openDeleteDialog.open && (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              IdOfDeletableOrder: '',
              orderNumber: '',
              customerId: '',
            })
          }
          deleteCallback={handleDeleteOrderCallback}
          dialogContent='Your order will be deleted permanently , Are you sure you want to delete it ?'
        />
      )}
      {upstoreSnackBar.open && (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      )}
    </>
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`order-tabpanel-${index}`}
        aria-labelledby={`order-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `order-tab-${index}`,
      'aria-controls': `order-tabpanel-${index}`,
    };
  }
  function PurchaseOrderTab({ columns, data, value }) {
    return (
      <Box sx={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Orders list'
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Orders list'
            />
          </Box>
        </TabPanel>
      </Box>
    );
  }
}

export default PurchaseOrder;
