import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import Catagory from '../../component/category/Catagory';
import Category from '../../modules/Categories';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Product from '../../modules/Product';
import { Loading } from '../../component/loading/Loading';
import Auth from '../../modules/Auth';
import Header from '../../component/header/Header';
import ProductDatagridComponent from '../../component/productDatagridComponent/ProductDatagridComponent';
import AddNewProduct from '../../component/addNewProduct/AddNewProduct';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Store from '../../modules/Store';
import './style.css';
import TaxCalculation from '../../modules/TaxCalculation';
import AllPermissions from '../../modules/AllPermissions';
import { serverConfig } from '../../config/server-config';
import UserSubscription from '../../modules/UserSubscription';
export default function Categories() {
  const [open, setOpen] = React.useState(false);
  const [categoryData, setCategoryData] = React.useState({
    name: '',
    timeToPrepare: '',
    available: true,
  });
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [categoryList, setCategoryList] = useState([]);
  const [buttonClicked, setButtonClicked] = React.useState('');
  const [loader, setLoader] = React.useState(true);
  const [datagridLoader, setDatagridLoader] = React.useState(false);
  const [productsList, setProductsList] = useState(null);
  const [selectedCategoryData, setSelectedCategoryData] = useState({
    name: '',
    id: null,
  });
  const [taxData, setTaxData] = React.useState({});
  const [addOpenProductDialog, setaddOpenProductDialog] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [storeSubscription, setStoreSubscription] = React.useState({});
  const [pageNumber, setPageNumber] = React.useState(0);
  const [slabDetails, setSlabDetails] = React.useState([]);
  const ref = React.useRef(null);
  useEffect(() => {
    getCategoriesList(true);
    getUserSubscriptionByStoreId();
    if (Store.getSelectedGstType()) {
      getSlabByTaxType();
    }
  }, []);

  const getTaxData = (type, data) => {
    if (type === 'gst') {
      TaxCalculation.getGstByProductIdAndCategoryId(
        data?.categoryId,
        data?.categoryId,
        response => {
          if (response.status === 'success') {
            setTaxData(response?.data);
          } else if (response.status === 'noData') {
            setTaxData({});
          } else {
            setTaxData({});
          }
        }
      );
    } else if (type === 'vat') {
      TaxCalculation.getVatByProductIdAndCategoryId(
        data?.categoryId,
        data?.categoryId,
        response => {
          if (response.status === 'success') {
            setTaxData(response?.data);
          } else if (response.status === 'noData') {
            setTaxData({});
          } else {
            setTaxData({});
          }
        }
      );
    }
  };

  const getAllProductsList = (categoryId, pageNo) => {
    setDatagridLoader(true);
    Product.getProductByCategoryId(categoryId, pageNo, 10, response => {
      if (response.status === 'success') {
        let filteredProducts = response.data.filter(item => {
          return !item.deleted;
        });
        setProductsList([...filteredProducts]);
        setDatagridLoader(false);
      } else {
        setDatagridLoader(false);
        setProductsList(null);
      }
    });
  };

  const getCategoriesList = (calledAtLoading = false) => {
    Category.getCategoryByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        if (calledAtLoading) {
          // finding category with default cat true
          let defaultCategory = response.data.find(
            item => item.defaultCat === true
          );
          setSelectedCategoryData({
            id: defaultCategory.categoryId,
            name: defaultCategory.name,
          });
          // setting grid data with defauld category
          getAllProductsList(defaultCategory.categoryId, 0);
        }
        setCategoryList(response.data);
        setLoader(false);
      } else {
        setLoader(false);
        setCategoryList([]);
      }
    });
  };

  const handleInputChange = event => {
    let { name, value } = event.target;
    if (name === 'vat') {
      setTaxData({ ...taxData, [name]: value });
    } else if (name === 'slabs') {
      setTaxData({ ...taxData, gst: value });
      // let total = null;
      // if (!taxData.sgst) {
      // } else {
      //   total = +value + +taxData.sgst;
      // }
      // setTaxData({ ...taxData, [name]: value, gst: total });
      // } else {
      //   let total = null;
      //   if (!taxData.cgst) {
      //   } else {
      //     total = +value + +taxData.cgst;
      //   }
      //   setTaxData({ ...taxData, [name]: value, gst: total });
      // }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // callback func
  const handleEditCategoryCallback = editCategoryData => {
    if (editCategoryData) {
      setCategoryData(editCategoryData);
      setOpen(true);
      setButtonClicked('Edit');

      // tax related
      if (!Store.getSelectedTaxType()) return;
      else if (Store.getSelectedTaxType() === 'GST')
        getTaxData('gst', editCategoryData);
      else getTaxData('vat', editCategoryData);
    }
  };

  const handleUpdateCategory = () => {
    let res = checkFieldsValidation();
    if (res === true || !categoryData.name) {
      openUpstoreSnackBar('All the fields are mandatory to fill', 'error');
    } else {
      setDisable(true);
      let data = { ...categoryData, storeId: Auth.getStoreId() };
      Category.updateCategory(categoryData.categoryId, data, response => {
        if (response.status === 'success') {
          let data = {
            ...taxData,
            id: taxData.id,
            taxableItemId: response.data.categoryId,
            storeId: Auth.getStoreId(),
          };
          if (!Store.getSelectedTaxType()) {
            dialogClosingFunction('updated');
          } else if (Store.getSelectedTaxType() === 'GST') {
            TaxCalculation.AddGstTaxData(data, response => {
              if (response.status === 'success') {
                dialogClosingFunction('updated');
              } else {
                openUpstoreSnackBar(`error while adding tax`, 'error');
              }
            });
          } else {
            TaxCalculation.AddVatTaxData(data, response => {
              if (response.status === 'success') {
                dialogClosingFunction('updated');
              } else {
                openUpstoreSnackBar(`error while adding tax`, 'error');
              }
            });
          }
        } else {
          openUpstoreSnackBar('Category not be updated successfully', 'error');
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setButtonClicked('');
    setDisable(false);
    setCategoryData({});
    setTaxData({});
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const dialogClosingFunction = param => {
    openUpstoreSnackBar(`Category ${param} Successfully`, 'success');
    handleClose();
    getCategoriesList(false);
  };

  const checkFieldsValidation = () => {
    let flag = false;
    if (!Store.getSelectedTaxType()) {
      flag = false;
      return flag;
    } else if (Store.getSelectedTaxType() === 'GST') {
      if (!categoryData.name) {
        flag = true;
        return flag;
      }
    } else if (Store.getSelectedTaxType() === 'VAT') {
      if (taxData?.vat === '' || !categoryData.name) {
        flag = true;
        return flag;
      }
    } else {
      if (!categoryData.name) {
        flag = true;
        return flag;
      }
    }
  };

  const handleSubmitData = () => {
    let res = checkFieldsValidation();
    if (!categoryData.name || !categoryData.available || res === true) {
      openUpstoreSnackBar('All the fields are mandatory to fill', 'error');
    } else {
      setDisable(true);
      let data = { ...categoryData, storeId: Auth.getStoreId() };
      Category.addCategoryData(data, response => {
        if (response.status === 'success') {
          let data = {
            ...taxData,
            taxableItemId: response.data.categoryId,
            storeId: Auth.getStoreId(),
          };
          if (!Store.getSelectedTaxType()) {
            dialogClosingFunction('added');
          } else if (Store.getSelectedTaxType() === 'GST') {
            TaxCalculation.AddGstTaxData(data, response => {
              if (response.status === 'success') {
                dialogClosingFunction('added');
              } else {
                openUpstoreSnackBar(`error while adding tax`, 'error');
              }
            });
          } else {
            TaxCalculation.AddVatTaxData(data, response => {
              if (response.status === 'success') {
                dialogClosingFunction('added');
              } else {
                openUpstoreSnackBar(`error while adding tax`, 'error');
              }
            });
          }
        } else if (response.data == 416) {
          openUpstoreSnackBar(
            `Limit exceeded please upgrade your plan`,
            'error'
          );
        } else {
          openUpstoreSnackBar(`error while adding category data`, 'error');
        }
      });
    }
  };

  // func for showing data grid filtered by category id
  const handleCategoryClickedCallback = (
    categoryIdOfClickedCategory,
    categoryName
  ) => {
    getAllProductsList(categoryIdOfClickedCategory, 0);
    setSelectedCategoryData({
      name: categoryName,
      id: categoryIdOfClickedCategory,
    });
  };

  const closeAddNewProductDialog = () => {
    setaddOpenProductDialog(false);
  };
  // function for scroll left and right
  const scroll = scrollOffset => {
    let value = 1000;

    value *= scrollOffset;

    ref.current.scrollLeft += value;
  };

  const getUserSubscriptionByStoreId = () => {
    UserSubscription.getUserSubscriptionByStoreId(
      Auth.getStoreId(),
      response => {
        if (response.status === 'success') {
          setStoreSubscription(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };

  const getSlabByTaxType = () => {
    Store.getSlabsByTaxType(Store.getSelectedGstType(), response => {
      if (response.status === 'success') {
        setSlabDetails(response.data);
      } else {
        setSlabDetails([]);
      }
    });
  };

  return (
    <>
      <Header pageName='Categories' />
      {loader ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              paddingLeft: '4.5rem',

              mt: 8,
              position: 'relative',
            }}
          >
            <Grid container sx={{ mb: 1, ml: 2 }}>
              <Grid item md={6} sm={7} xs={8}>
                <BreadCrumb pageName='Categories' />
              </Grid>
              {AllPermissions.getUserAccessiblePermissions('Category') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('Category').write ===
                false ? null : (
                <Grid item md={6} sm={5} xs={4}>
                  <Button
                    variant='contained'
                    onClick={handleClickOpen}
                    sx={{
                      p: 0,
                      color: '#fff',
                      backgroundColor: '#3B78C9',
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',

                      float: { md: 'right', sm: 'right', xs: 'right' },
                      mr: 3,
                    }}
                  >
                    <AddIcon sx={{ color: 'White', fontSize: '16px' }} />
                    <Typography
                      sx={{
                        fontSize: { md: '13px', sm: '10px', xs: '14px' },
                        display: { md: 'block', sm: 'block', xs: 'block' },
                        textTransform: 'capitalize',
                      }}
                      noWrap
                    >
                      Add Category
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
            <Box
              component='div'
              className='scrollCategory'
              sx={{
                overflowY: 'hidden',
                backgroundColor: 'secondary.light',
                overFlowX: 'hidden',
                whiteSpace: 'nowrap',
              }}
              ref={ref}
            >
              <>
                {!datagridLoader && !loader ? (
                  <>
                    <ChevronRightIcon
                      className='leftSlide'
                      onClick={() => scroll(1)}
                    />
                    <ChevronLeftIcon
                      className=' rightSlide'
                      onClick={() => scroll(-1)}
                    />
                  </>
                ) : null}
                {categoryList && categoryList.length > 0 ? (
                  categoryList.map(item => (
                    <Catagory
                      key={item.categoryId}
                      editDialogCallback={handleEditCategoryCallback}
                      categoryObj={item}
                      selectedCategoryId={selectedCategoryData.id}
                      refreshCategoriesList={getCategoriesList}
                      snackbar={openUpstoreSnackBar}
                      handleCategoryClickedCallback={
                        handleCategoryClickedCallback
                      }
                    />
                  ))
                ) : (
                  <Box
                    display='flex'
                    height='70vh'
                    width='100%'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography variant='h5' color='primary.main'>
                      Categories list is empty for your store
                    </Typography>
                  </Box>
                )}
              </>
            </Box>

            {!datagridLoader ? (
              <>
                {productsList !== null ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: 600,
                      mt: 4,

                      '& .super-app-theme--header': {
                        backgroundColor: '#e1ebfa',

                        fontSize: '18px',
                      },
                    }}
                  >
                    {productsList.length > 0 && categoryList.length > 0 ? (
                      <>
                        <Grid container sx={{ display: 'flex', width: '97%' }}>
                          <Grid item md={11} sm={8}>
                            <Typography
                              variant='h5'
                              color='primary.main'
                              fontSize={20}
                              mb={2}
                              sx={{ width: '100%' }}
                            >
                              Products of {selectedCategoryData.name}
                            </Typography>
                          </Grid>
                          {AllPermissions.getUserAccessiblePermissions(
                            'Product'
                          ) !== undefined &&
                          AllPermissions.getUserAccessiblePermissions('Product')
                            .write === false ? null : (
                            <Grid item md={1} sm={4}>
                              <Tooltip title='Add Product' placement='bottom'>
                                <Button
                                  variant='contained'
                                  onClick={() => setaddOpenProductDialog(true)}
                                  sx={{
                                    p: 0,
                                    color: '#fff',
                                    backgroundColor: '#3B78C9',
                                    textTransform: 'capitalize',
                                    width: '126px',
                                    height: '27px',

                                    float: {
                                      md: 'right',
                                      sm: 'right',
                                      xs: 'right',
                                    },
                                    mr: 3,
                                  }}
                                >
                                  <AddIcon
                                    sx={{ color: 'White', fontSize: '16px' }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        md: '13px',
                                        sm: '10px',
                                        xs: '14px',
                                      },
                                      display: {
                                        md: 'block',
                                        sm: 'block',
                                        xs: 'block',
                                      },
                                      textTransform: 'capitalize',
                                    }}
                                    noWrap
                                  >
                                    Add Product
                                  </Typography>
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                        <ProductDatagridComponent
                          productsList={productsList}
                          getUpdatedProductsList={getAllProductsList}
                          snackbar={openUpstoreSnackBar}
                          refreshCategoriesList={getCategoriesList}
                          setPageNumber={setPageNumber}
                          pageNumber={pageNumber}
                          selectedCategoryId={selectedCategoryData.id}
                          fromCatagoryComponent={true}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant='h4'
                          color='darkGray'
                          sx={{
                            fontSize: { xs: '12px', sm: 27 },
                          }}
                        >
                          The selected category has no products
                        </Typography>
                        {AllPermissions.getUserAccessiblePermissions(
                          'Product'
                        ) !== undefined &&
                        AllPermissions.getUserAccessiblePermissions('Product')
                          .write === false ? null : (
                          <Tooltip title='Add Product' placement='bottom'>
                            <Button
                              sx={{
                                ml: { md: 2, sm: 2, xs: 1 },
                                p: { md: 0, sm: 0, xs: 1 },
                                textTransform: 'capitalize',

                                width: '126px',
                                height: '27px',
                              }}
                              variant='outlined'
                              onClick={() => setaddOpenProductDialog(true)}
                              startIcon={<AddIcon sx={{ fontSize: '11px' }} />}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    md: '14px',
                                    sm: '13px',
                                    xs: '11px',
                                  },
                                }}
                                noWrap
                              >
                                Add Product
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    )}
                  </Box>
                ) : null}
              </>
            ) : (
              <Loading />
            )}
          </Box>
        </>
      )}

      {open ? (
        <Dialog open={open} maxWidth='sm' fullWidth={true}>
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h5' textAlign='center'>
                {buttonClicked === 'Edit'
                  ? '   Edit Category Details'
                  : '   Add Category Details'}
              </Typography>
              <Tooltip title='Close dialog' placment='right-start '>
                <Close
                  size='medium'
                  onClick={handleClose}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              </Tooltip>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Grid container rowSpacing={1} columnSpacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant='subtitle1'>Category Name</Typography>
                <TextField
                  sx={{ mt: 1 }}
                  autoFocus
                  size='small'
                  id='name'
                  type='text'
                  fullWidth
                  required={true}
                  placeholder='Enter Category Name'
                  variant='outlined'
                  value={categoryData.name}
                  name='name'
                  onChange={event =>
                    setCategoryData({
                      ...categoryData,
                      [event.target.name]: event.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant='subtitle1'>Available</Typography>

                <TextField
                  select
                  size='small'
                  sx={{ mt: 1 }}
                  variant='outlined'
                  required={true}
                  value={categoryData.available}
                  placeholder='Select default availability'
                  fullWidth
                  name='available'
                  id='available'
                  onChange={event =>
                    setCategoryData({
                      ...categoryData,
                      [event.target.name]: event.target.value,
                    })
                  }
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </TextField>
              </Grid>
              {Store.getSelectedTaxType() &&
              Store.getSelectedTaxType() !== 'N/A' ? (
                <>
                  {Store.getSelectedTaxType() === 'GST' ? (
                    <>
                      {/* <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1'>
                          Enter CGST %
                        </Typography>
                        <TextField
                          sx={{ mt: 1 }}
                          size='small'
                          id='cgst'
                          type='number'
                          fullWidth
                          placeholder='Enter cgst'
                          variant='outlined'
                          name='cgst'
                          value={taxData?.cgst}
                          onChange={handleInputChange}
                        />
                      </Grid> */}

                      <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1'>GST Slabs</Typography>
                        <TextField
                          sx={{ mt: 1 }}
                          size='small'
                          id='slabs'
                          select
                          type='number'
                          fullWidth
                          placeholder='Enter slabs'
                          variant='outlined'
                          name='slabs'
                          value={taxData?.gst}
                          onChange={handleInputChange}
                        >
                          {!Store.getLutNo() ? (
                            slabDetails.map(option => (
                              <MenuItem key={option.id} value={option.slabs}>
                                {option.slabs}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={1} value={0}>
                              0
                            </MenuItem>
                          )}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1'>
                          Enter SGST %
                        </Typography>
                        <TextField
                          sx={{ mt: 1 }}
                          size='small'
                          id='sgst'
                          type='number'
                          fullWidth
                          placeholder='Enter sgst'
                          variant='outlined'
                          name='sgst'
                          value={taxData?.sgst}
                          onChange={handleInputChange}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1'>GST %</Typography>
                        <TextField
                          sx={{ mt: 1 }}
                          size='small'
                          id='gst'
                          type='number'
                          fullWidth
                          disabled={true}
                          placeholder='Enter gst percentage'
                          variant='outlined'
                          value={taxData?.gst}
                          name='gst'
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={12}>
                      <Typography variant='subtitle1'>VAT %</Typography>
                      <TextField
                        sx={{ mt: 1 }}
                        size='small'
                        id='vat'
                        type='number'
                        fullWidth
                        placeholder='Enter Vat percentage'
                        variant='outlined'
                        name='vat'
                        value={taxData?.vat}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  )}
                </>
              ) : null}

              {storeSubscription?.userSubscribeServiceModelList?.map(item => {
                return item.serviceName === 'eCommerce' &&
                  serverConfig.isUpstoreCloud === true ? (
                  <>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant='subtitle1'>
                        Time To Prepare
                      </Typography>
                      <TextField
                        sx={{ mt: 1 }}
                        autoFocus
                        size='small'
                        id='timeToPrepare'
                        type='text'
                        fullWidth
                        required={true}
                        placeholder='Enter Time To Prapare'
                        variant='outlined'
                        value={categoryData.timeToPrepare}
                        name='timeToPrepare'
                        onChange={event =>
                          setCategoryData({
                            ...categoryData,
                            [event.target.name]: event.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <Typography variant='subtitle1'>
                        Delivery Charges
                      </Typography>
                      <TextField
                        sx={{ mt: 1 }}
                        size='small'
                        id='deliveryCharges'
                        type='number'
                        fullWidth
                        required={true}
                        placeholder='Enter Delivery Charges'
                        variant='outlined'
                        value={categoryData.deliveryCharges}
                        name='deliveryCharges'
                        onChange={event =>
                          setCategoryData({
                            ...categoryData,
                            [event.target.name]: event.target.value,
                          })
                        }
                      />
                    </Grid>
                  </>
                ) : null;
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={handleClose}
              sx={{
                bgColor: '#eee',
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
            >
              Cancel
            </Button>
            {buttonClicked === 'Edit' ? (
              <Button
                sx={{
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                disabled={disable}
                variant='contained'
                onClick={handleUpdateCategory}
              >
                Update
              </Button>
            ) : (
              <Button
                sx={{
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                disabled={disable}
                variant='contained'
                onClick={handleSubmitData}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}

      {addOpenProductDialog ? (
        // Dialog for adding product
        <AddNewProduct
          openDialog={addOpenProductDialog}
          closeDialog={closeAddNewProductDialog}
          snackbar={openUpstoreSnackBar}
          selectedCategoryId={selectedCategoryData.id}
          GetUpdatedProductsList={getAllProductsList}
        />
      ) : null}

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
}
