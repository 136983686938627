import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button, Grid, Typography } from '@mui/material';
import Categories from '../../modules/Categories';
import Product from '../../modules/Product';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import AllPermissions from '../../modules/AllPermissions';

function ProductDatagridComponent(props) {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <Link
          style={{ textDecoration: 'none' }}
          to={'/product-details'}
          state={params?.row?.productId}
        >
          <Typography
            variant='subtitle1'
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
          >
            {`${params.row?.name}`}
          </Typography>
        </Link>
      ),
    },

    {
      field: 'categoryName',
      headerName: 'Category Name',
      type: 'number',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'description',
      headerName: 'Description',
      type: 'number',
      width: 400,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Tooltip title={params.row.description} placement='bottom'>
          <Typography variant='h6' fontSize='15px'>
            {params.row.description}
          </Typography>
        </Tooltip>
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 260,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='center '
            alignItems='center'
          >
            <>
              {AllPermissions.getUserAccessiblePermissions('Product') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('Product').write ===
                false ? (
                <DeleteIcon />
              ) : (
                <Tooltip title='Delete Product' placement='bottom'>
                  <DeleteIcon
                    sx={{ color: '#3B78C9' }}
                    onClick={event => {
                      setdeleteProductDialog({
                        open: true,
                        IdOfdeletedProduct: params.row.productId,
                      });
                    }}
                  />
                </Tooltip>
              )}
            </>
          </Box>
        </>
      ),
    },
  ];

  const [deleteProductDialog, setdeleteProductDialog] = React.useState({
    open: false,
    IdOfdeletedProduct: null,
  });

  const closeDeletePopup = () => {
    setdeleteProductDialog({ open: false, IdOfdeletedProduct: null });
  };

  const handleDeleteProductCallBack = Delete => {
    let id;
    if (Delete === true) {
      id = deleteProductDialog.IdOfdeletedProduct;
      Product.deleteProduct(id, response => {
        if (response.status === 'success') {
          props.getUpdatedProductsList(props?.selectedCategoryId, 0);
          if (props.fromCatagoryComponent) {
            props?.refreshCategoriesList();
          }
          props.snackbar('Product was deleted successfully', 'success');
          closeDeletePopup();
        } else {
          props.snackbar('Product was not deleted successfully', 'error');
        }
      });
    }
  };

  const rowData = props.productsList?.map(product => {
    return {
      ...product,
      id: product?.productId,
      categoryName: Categories.getCategoryNameById(product?.categoryId)?.name,
      status: product?.available ? 'Available' : 'Not Available',
    };
  });

  const handlePreviousPage = () => {
    let pageNumb = props?.pageNumber - 1;
    props?.setPageNumber(pageNumb);
    props.getUpdatedProductsList(props?.selectedCategoryId, pageNumb);
  };

  const handleNextPage = () => {
    let pageNumb = props.pageNumber + 1;
    props.setPageNumber(pageNumb);
    props.getUpdatedProductsList(props?.selectedCategoryId, pageNumb);
  };

  return (
    <>
      <DataGrid
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: 2,
          border: 6,
          backgroundColor: 'white',
          borderColor: '#eee',
          color: 'black',

          // '.MuiDataGrid-row': {
          //   backgroundColor: 'white',
          // },
          '& .MuiDataGrid-cell': {
            outline: 'none',
            fontSize: '16px',
            fontWeight: '25px',
          },

          '& .MuiDataGrid-cell:hover': {
            outline: 'none',
            color: '#21211f',
          },
          '& svg': {
            color: '#3b78c9',
          },
          '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
            color: 'black',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
        density='compact'
        hideFooter={true}
        rows={rowData}
        columns={columns}
        pageSize={20}
        // rowsPerPageOptions={[10]}
        // checkboxSelection
        aria-label='products list'
      />
      <Grid
        style={{
          padding: '1.6vh',
          float: 'right',

          bottom: '0px',
        }}
      >
        <Button
          size='small'
          variant='outlined'
          disabled={props.pageNumber <= 0}
          onClick={handlePreviousPage}
          sx={{ color: '#3B78C9', variant: 'outlined' }}
        >
          Prev
        </Button>
        <Button
          size='small'
          variant='outlined'
          disabled={props.productsList.length < 10}
          sx={{ ml: 1, color: '#3B78C9' }}
          onClick={handleNextPage}
        >
          Next
        </Button>
      </Grid>
      {deleteProductDialog.open ? (
        <DeleteDialog
          openDialog={true}
          closeDialogCallback={closeDeletePopup}
          deleteCallback={handleDeleteProductCallBack}
          dialogContent='All the items along with this product will also be deleted, press delete to continue'
        />
      ) : null}
    </>
  );
}

export default ProductDatagridComponent;
