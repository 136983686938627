import React from 'react';
import {
  FormGroup,
  Button,
  InputLabel,
  ButtonGroup,
  Grid,
} from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Missing from '../../assets/images/missing-img.jpg';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import imageCompression from 'browser-image-compression';
import './style.css';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import Logo from '../../modules/Logo';

const classes = theme => ({
  root: {
    padding: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 1200,
    height: 500,
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});

class ProductCarouselImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: null,
    };
  }

  handleCapture = event => {
    if (event.files) {
      if (event.files.length !== 0) {
        this.uploadImages(event.files, this.state.imageIndex);
      }
    }
  };

  uploadImages = (images, imageIndex) => {
    Array.from(images).map(imageFile => {
      if (imageIndex < 3) {
        this.handleImageCompression(imageFile, imageIndex);
      } else {
        this.props.onUploadImage(
          'Only three images are allowed, image rejected.',
          'error'
        );
      }
    });
  };

  handleImageCompression = async (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await this.uploadToServer(compressedFile, index);
      } catch (error) {
        console.log('Image upload failed ' + error);
      }
    } else {
      await this.uploadToServer(imageFile, index);
    }
  };

  uploadToServer = (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size <= maxFileSize) {
      let formData = new FormData();
      let productId = this.props.productDetailsData.productId;
      const imageName = `products/${productId}_${index}`;
      formData.append('name', imageName);
      formData.append('image', imageFile);

      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          this.props.snackbar(
            'product   crousel  Image Uploaded successfully',
            'success'
          );
        } else {
          console.log('error');
          this.props.snackbar(
            'Error while uploading  product  crousel image ',
            'error'
          );
        }
      });
    }
  };
  addDefaultSrc(ev) {
    ev.target.src = Missing;
  }

  render() {
    return (
      <Grid item xs={12} sm={11}>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showArrows={true}
          onClickThumb={event => this.setState({ imageIndex: event })}
        >
          <div>
            <img
              src={Logo.getProductImages(
                this.props.productDetailsData.productId,
                0
              )}
              onError={this.addDefaultSrc}
              alt=''
              className='carousal-images'
            />
          </div>
          <div>
            <img
              src={Logo.getProductImages(
                this.props.productDetailsData.productId,
                1
              )}
              onError={this.addDefaultSrc}
              alt=''
              className='carousal-images'
            />
          </div>
          <div>
            <img
              src={Logo.getProductImages(
                this.props.productDetailsData.productId,
                2
              )}
              onError={this.addDefaultSrc}
              alt=''
              className='carousal-images'
            />
          </div>
        </Carousel>

        <FormGroup>
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id={`raised-button-file-${this.props.productDetailsData.productId}`}
            multiple
            type='file'
            onChange={e => this.handleCapture(e.target)}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-30px',
            }}
          >
            <label
              htmlFor={`raised-button-file-${this.props.productDetailsData.productId}`}
            >
              <Button
                sx={{ height: '26px' }}
                variant='contained'
                component='span'
                className={classes.button}
                startIcon={<CameraAltIcon sx={{ color: 'fff' }} />}
              >
                Upload
              </Button>
            </label>
          </div>
        </FormGroup>
      </Grid>
    );
  }
}

export default ProductCarouselImg;
