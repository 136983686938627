import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Divider,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './reviewsSection.css';
import Auth from '../../modules/Auth';
import Product from '../../modules/Product';

export default class ReviewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewMessage: '',
      reviews: [],
      replyShow: { open: false, id: '' },
      loading: false,
      disableButton: false,
    };
  }

  componentDidMount() {
    if (this.props.reviewsData) {
      this.setState({
        reviews: JSON.parse(this.props?.reviewsData),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviewsData !== this.props.reviewsData) {
      this.setState({
        reviews: JSON.parse(this.props?.reviewsData),
      });
    }
  }

  addReview = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ disableButton: true });

    let lastItem = this.state.reviews[0];

    const reviewObj = {
      name: Auth.getUserName(),
      message: this.state.reviewMessage,
      date: new Date().toLocaleDateString(),
      totalCount: lastItem === undefined ? 1 : ++lastItem.totalCount,
    };

    const updatedReviews = [reviewObj, ...this.state.reviews];

    const data = {
      productId: this.props.productId,
      reviews: JSON.stringify(updatedReviews),
    };
    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Review added successfully', 'success');
        this.setState({ reviewMessage: '', disableButton: false });
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Comment not added successfully', 'error');
        this.setState({ disableButton: false });
      }
    });
  };

  removeReview = (event, reviewMessage, reviewId) => {
    event.preventDefault();
    event.stopPropagation();

    const splicedReviews = this.state.reviews.filter(
      (review, id) => id !== reviewId || review.message !== reviewMessage
    );

    const data = {
      productId: this.props.productId,
      reviews: JSON.stringify(splicedReviews),
    };

    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Review deleted successfully', 'success');
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Review not deleted successfully', 'error');
      }
    });
  };

  render() {
    return (
      <>
        <Box className='box-container'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id='outlined-multiline-static'
                  label='Add Review'
                  value={this.state.reviewMessage}
                  multiline
                  rows={2}
                  autoFocus
                  fullWidth
                  variant='outlined'
                  onChange={(event) => {
                    this.setState({ reviewMessage: event.target.value });
                  }}
                />
              </Grid>
              <Box textAlign={'right'} width={'100%'}>
                <Button
                  className='post-button'
                  variant='contained'
                  size='small'
                  fullWidth
                  onClick={this.addReview}
                  disabled={this.state.disableButton}
                >
                  Post
                </Button>
              </Box>

              <Grid item xs={12}>
                <List>
                  {this.state?.reviews && this.state?.reviews?.length > 0 ? (
                    this.state?.reviews.map((review, index) => (
                      <div key={index}>
                        <ListItem alignItems='flex-start' className='list-item'>
                          <Grid container>
                            <Grid item xs={2} md={1}>
                              <ListItemAvatar>
                                <Avatar alt='Remy Sharp' src='' />
                              </ListItemAvatar>
                            </Grid>
                            <Grid item xs={9} md={10}>
                              <ListItemText
                                primary={review?.name}
                                secondary={
                                  <div style={{ textAlign: 'left' }}>
                                    <Box component='div' display='inline'>
                                      <Typography
                                        style={{
                                          color: 'black',
                                          width: '300px',
                                          wordWrap: 'break-word',
                                        }}
                                        component='p'
                                        variant='caption'
                                        align='left'
                                      >
                                        {review?.message}
                                      </Typography>
                                    </Box>
                                    <span style={{ marginRight: '5px' }}>
                                      {review?.date
                                        ? review?.date.slice(0, 25)
                                        : null}
                                    </span>
                                  </div>
                                }
                              />
                            </Grid>

                            <Grid item xs={1} md={1} alignSelf='center'>
                              <Tooltip title='Delete review' placement='left'>
                                <DeleteForeverIcon
                                  sx={{
                                    color: '#ef0d0dfc',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(event) =>
                                    this.removeReview(
                                      event,
                                      review.message,
                                      index
                                    )
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider variant='inset' component='li' />
                        <br />
                      </div>
                    ))
                  ) : (
                    <Box className='no-reviews-box'>
                      <Typography variant='subtitle1' color='primary'>
                        No Reviews Present
                      </Typography>
                    </Box>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}
