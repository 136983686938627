import React from 'react';
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableFooter,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
} from '@mui/material';
// for date
import { Link } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Accordion from '@mui/material/Accordion';
import Validation from '../../modules/Validation';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import VendorListOrderItem from '../vendorListOrderItem/VendorListOrderItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import PurchaseOrderReturn from '../purchaseOrderReturn/PurchaseOrderReturn';
import { ArrowLeft, PeopleAlt, Add, Close } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import Vendor from '../../modules/Vendor';
import Auth from '../../modules/Auth';
import ProductItemDrawer from '../productItemDrawer/ProductItemDrawer';
import PurchaseOrders from '../../modules/PurchaseOrders';
import Order from '../../modules/Order';
import PaymentSection from '../paymentSection/PaymentSection';
import Store from '../../modules/Store';
import DownloadIcon from '@mui/icons-material/Download';
import { Print } from '@mui/icons-material';

import { serverConfig } from '../../config/server-config';
import WarningDialog from '../warningDialog/WarningDialog';
import DeleteDialog from '../deleteDialog/DeleteDialog';
import PurchaseOrderInvoice from '../purchaseOrderInvoice/PurchaseOrderInvoice';
import PurchaseOrderRecipt from '../purchaseOrderReciept/PurchaseOrderReciept';
import moment from 'moment';
import dateConveter from '../../modules/DateConveter/dateConveter';
import HTMLRenderer from 'react-html-renderer';
import { debounce } from '../../utils';

let selectedItemsSiblingItems = null;

function CreateOrUpdateVendorOrder(props) {
  const listRef = React.useRef(null);
  const page_size = 18;
  const [vendorDataList, setVendorDataList] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [vendorInfo, setVendorInfo] = React.useState({});
  const [confirmedVendorOrder, setConfirmedVendorOrder] = React.useState(false);
  const [openItemVendorDialog, setOpenItemVendorDialog] = React.useState(false);
  const [selectedVendorProductItemList, setSelectedVendorProductItemList] =
    React.useState([]);
  const [orderExpanded, setOrderExpanded] = React.useState(true);
  const [dialogWarning, setDialogWarning] = React.useState(false);
  const [openDeletedOrderDialog, setOpenDeletedOrderDialog] =
    React.useState(false);

  const [billDate, setBillDate] = React.useState(new Date());
  const [draftedVendorMode, setDraftedVendorMode] = React.useState(false);
  const [updateVendorStoreOrder, setUpdateVendorStoreOrder] = React.useState(
    props.updateOrder
  );
  const [openPurchaseOrderReturnDialog, setOpenPurchaseOrderReturnDialog] =
    React.useState(false);

  const [openAddReasonDialog, setOpenAddReasonDialog] = React.useState({
    open: false,
  });
  const [openVendorDeleteDialog, setOpenVendorDeleteDialog] = React.useState({
    open: false,
    itemData: '',
  });

  const [paymentExpanded, setPaymentExpanded] = React.useState(false);

  const [confirmedOrderEditMode, setConfirmedOrderEditMode] =
    React.useState(false);
  const [vendorOrderObj, setVendorOrderObj] = React.useState({});
  const [vendorInform, setVendorInform] = React.useState({
    billNo: '',
    truckNo: '',
  });
  const [fridgeLabour, setFridgeLabour] = React.useState({
    fridge: '',
    labour: '',
  });
  const [
    deletedVendorItemsOfConfirmedOrders,
    setDeletedVendorItemsOfConfirmedOrders,
  ] = React.useState([]);
  const [submittedButton, setSubmittedButton] = React.useState(false);
  const [orderInvoice, setOrderInvoice] = React.useState({ open: false });
  const [orderRecipt, setOrderRecipt] = React.useState({ open: false });
  const [showVendorListItemComponent, setShowVendorListItemComponent] =
    React.useState(false);
  const [refreshLedger, setRefreshLedger] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [varietyDetails, setVarietyDetails] = React.useState({
    packingData: [],
    qualityData: [],
    varietyData: [],
  });
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [pageNo, setPageNo] = React.useState(0);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);

  const columns = [
    { id: 'edit', label: '', minWidth: 2, align: 'center', size: 'small' },

    { id: 'item', label: 'Item ', minWidth: 50, align: 'left', size: 'small' },

    {
      id: 'itemQuantityUnit',
      label: 'Unit',
      minWidth: 50,
      align: 'center',
      size: 'small',
    },

    {
      id: 'noOfItems',
      label: 'Qty',
      minWidth: 50,
      align: 'center',
      size: 'small',
    },

    {
      id: 'Price',
      label: 'Price',
      minWidth: 50,
      align: 'center',
      size: 'small',
    },
    {
      id: 'basePriceDiscount',
      label: 'Discount',
      minWidth: 70,
      align: 'left',
      size: 'small',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'otherCharges',
      label:
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'Other Charges',
      maxWidth: 150,
      align: 'left',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'taxableValue',
      label:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'Taxable Value',
      maxWidth: 150,
      align: 'left',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'gstamt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'vatamt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'TAX'
          : null,
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'Tax Amt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'Vat Amt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'Tax Amt'
          : null,
      maxWidth: 100,
      align: 'center',
    },
    {
      id: Store.companyDetails?.businessType === 'MANDI' ? 'packing' : null,
      label: Store.companyDetails?.businessType === 'MANDI' ? 'Pack' : null,
      minWidth: 50,
      align: 'center',
      size: 'small',
    },

    {
      id: Store.companyDetails?.businessType === 'MANDI' ? 'variety' : null,
      label: Store.companyDetails?.businessType === 'MANDI' ? 'Variety' : null,
      minWidth: 50,
      align: 'left',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'cess',
      label:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL' ? null : (
          <>
            {currency ? (
              <>
                Cess(%/
                <HTMLRenderer html={currency} />)
              </>
            ) : (
              'Cess'
            )}
          </>
        ),
      maxWidth: 100,
      align: 'center',
    },
    {
      id: 'amount',
      label: 'Amount',
      minWidth: 70,
      align: 'left',
      size: 'small',
    },
  ];
  let billDateVar;
  React.useEffect(() => {
    if (Validation.isBlankObjectNot(updateVendorStoreOrder)) {
      // setVendorInfo({
      //   ...updateVendorStoreOrder?.vendor,
      //   ['name']: updateVendorStoreOrder?.vendorName
      //     ? updateVendorStoreOrder?.vendorName
      //     : '',
      // });

      setBillDate(
        updateVendorStoreOrder.billDate
          ? moment.utc(updateVendorStoreOrder.billDate).local()
          : null
      );
      setVendorInform({
        ...vendorInform,
        billNo: updateVendorStoreOrder.billNo,
      });

      if (updateVendorStoreOrder.status !== 'CONFIRMED') {
        // ading extra property dynamically in drafted mode to all items
        let updatedArr = updateVendorStoreOrder.purchaseOrderDetails.map(
          item => (item = { ...item, drafted: true })
        );

        setSelectedVendorProductItemList(updatedArr);

        setVendorOrderObj(updateVendorStoreOrder);
        setDraftedVendorMode(true);
      } else {
        // Updated Confirmed Order
        setConfirmedVendorOrder(true);

        setSelectedVendorProductItemList(
          updateVendorStoreOrder.purchaseOrderDetails
        );
        setVendorOrderObj(updateVendorStoreOrder);
        // setLoader(false);
      }
    }
    getVendorInfo();
    getAllVendors(pageNo);
    getCurrency();
    setShowVendorListItemComponent(true);
    getAllVariatiesByType('packing');
    getAllVariatiesByType('quality');
    getAllVariatiesByType('variety');
  }, []);
  const handleSavedetails = status => {
    setSubmittedButton(true);

    let bDate;
    dateConveter.convertDate(billDate, response => {
      bDate = response.data;
    });

    // let trimmedFrindge = parseInt(fridgeLabour.fridge);
    // let trimmedLabour = parseInt(fridgeLabour.labour);
    // let trimmedamount = parseInt(vendorOrderObj.totalAmount);

    // let trimicn = parseInt(trimmedamount + trimmedFrindge + trimmedLabour);
    let vendorObj = vendorOrderObj;
    vendorObj.status = status;
    vendorObj.billDate = bDate;
    // vendorObj.totalAmount = trimicn;

    Vendor.addPurchaseOrder(vendorObj, response => {
      setSubmittedButton(false);
      if (response.status === 'success') {
        if (response.data.status === 'NEW') {
          props.snackbar('order is saved to Draft successfully', 'success');
          // props.getLatestFetchedVendorOrders();

          setVendorOrderObj({
            ...response.data,
          });

          setDraftedVendorMode(true);
        }
        setPaymentExpanded(true);
        setOrderExpanded(false);
        if (status === 'CONFIRMED') {
          handleSetUpdatedOrderItem(response.data.id);
          props.snackbar('order is confirmed successfully', 'success');
          setConfirmedVendorOrder(true);

          setVendorOrderObj({
            ...response.data,
          });
          //  setUpdateStoreOrder({ ...response.data });
        }
        setPaymentExpanded(true);
        setOrderExpanded(false);
      } else if ((response.data = 416)) {
        props.snackbar(`Limit exceeded please upgrade your plan`, 'error');
      } else {
        props.snackbar(`Error while saving purchase order`, 'error');
      }
    });
  };

  // callback func to individual rows to update changed rows
  const updateVendorRowData = rowData => {
    let result = selectedVendorProductItemList.findIndex(
      item => item.itemNumber == rowData.itemNumber
    );
    selectedVendorProductItemList[result] = rowData;

    calculateTotalValuesForVendor(selectedVendorProductItemList);
  };
  const getAllVendors = pageNum => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), pageNum, true, response => {
      if (response.status === 'success') {
        let filteredVendor = response.data.filter(item => {
          return item.active;
        });
        if (response.data.length < page_size) {
          setHasMoreData(false);
        }
        setVendorDataList([...vendorDataList, ...filteredVendor]);
      }
    });
    setSkeletonLoading(false);
  };

  const getCurrency = () => {
    let newCurr = Store.getCurrency();
    setCurrency(newCurr);
  };
  // const filterdData = selectedVendorProductItemList?.filter(item => {
  //   return !item.deleted;
  // });

  const closeReturn = () => {
    setConfirmedVendorOrder(false);
    setOpenPurchaseOrderReturnDialog(false);
    setConfirmedVendorOrder(true);
  };

  const handleSetUpdatedOrderItem = purchaseOrderId => {
    setShowVendorListItemComponent(false);
    PurchaseOrders.getPurchaseOrderById(purchaseOrderId, response => {
      if (response.status === 'success') {
        if (Validation.isBlankObjectNot(response.data)) {
          setVendorOrderObj(response.data);
          setUpdateVendorStoreOrder(response.data);
          setSelectedVendorProductItemList(
            response?.data?.purchaseOrderDetails
          );
          setShowVendorListItemComponent(true);
        }
      }
    });
  };
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle products list selected

  const handleSelectedVendorProductItem = (selectedItem, product) => {
    selectedItemsSiblingItems = product?.productItems;

    let foundItem = selectedVendorProductItemList.some(item => {
      return item.itemNumber === selectedItem.itemNumber;
    });
    if (foundItem === true) {
      props.snackbar('Item already added', 'error');
      return;
    } else {
      // props.snackbar('Product added successfully', 'success');

      let updatedItem = {
        ...selectedItem,
        noOfItems: 1,
        purchaseOrderId:
          updateVendorStoreOrder.status === 'CONFIRMED'
            ? updateVendorStoreOrder.id
            : undefined,
      };

      let productListForPassingCalculateValue = [
        ...selectedVendorProductItemList,
        updatedItem,
      ];

      setSelectedVendorProductItemList(productListForPassingCalculateValue);
    }
  };

  const handleSwipePoductItem = (
    prevItem,
    newItem,
    noOfItems = 1,
    productItems
  ) => {
    let arr = selectedVendorProductItemList;
    selectedItemsSiblingItems = productItems;
    let updatedItem = {
      ...newItem,
      noOfItems: noOfItems,
    };
    const index = arr.findIndex(el => el.itemNumber === prevItem.itemNumber);
    arr[index] = updatedItem;

    calculateTotalValuesForVendor(arr);
  };
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      setDialogWarning(false);
      setConfirmedVendorOrder(false);
      setConfirmedOrderEditMode(true);

      // setOrderExpanded(true);
    } else {
      setDialogWarning(false);
    }
  };

  const handleConfirmPurchaseOrder = () => {
    handleSavedetails('CONFIRMED');
  };

  const calculateTotalValuesForVendor = productList => {
    let purchaseOrderCalculation = Order.calculateTotalValues(productList);
    setSelectedVendorProductItemList([
      ...purchaseOrderCalculation.orderDetails,
    ]);

    setVendorOrderObj({
      ...vendorOrderObj,
      vendor: {
        id: vendorInfo.id,
      },
      totalItems: purchaseOrderCalculation.totalItems,
      totalDiscount: purchaseOrderCalculation?.totalBasePriceDiscount,
      totalStorePrice: purchaseOrderCalculation.totalStorePrice,
      totalAmount: purchaseOrderCalculation.totalAmount,
      tax: purchaseOrderCalculation.totalTaxValue,
      billNo: vendorInform.billNo,
      billDate: new Date(billDate),
      storeId: Auth.getStoreId(),
      purchaseOrderDetails: purchaseOrderCalculation.orderDetails,
    });
  };
  const getVendorInfo = () => {
    if (updateVendorStoreOrder.vendorId) {
      Vendor.getVendorByVendorId(updateVendorStoreOrder.vendorId, response => {
        if (response.status === 'success') {
          setVendorInfo({
            ...response.data,
            ['name']: response.data?.name ? response.data?.name : '',
          });
        } else {
          setVendorInfo({});
        }
      });
    }
  };

  // argument comment gets sometimes comment from user and sometimes string 'simpleDraftedUpdate' or 'confirmDraftedUpdate)
  // when it gets comment from user then only update will get call else same save will get call
  const handleUpdateStoreOrderVenFunc = comment => {
    setConfirmedVendorOrder(true);
    if (comment === 'simpleDraftedUpdate') {
      setSubmittedButton(true);
      handleSavedetails('NEW');
    } else if (comment === 'confirmDraftedUpdate') {
      setSubmittedButton(true);
      handleSavedetails('CONFIRMED');
    } else {
      if (comment.length > 0) {
        if (
          new Date(updateVendorStoreOrder.billDate).getTime() !==
          new Date(billDate).getTime()
        ) {
          dateConveter.convertDate(billDate, response => {
            billDateVar = response.data;
          });
        }
        let updatedObj = {
          ...vendorOrderObj,
          comment: comment,
          billDate: billDateVar ? billDateVar : billDate,
          purchaseOrderDetails: [
            ...vendorOrderObj.purchaseOrderDetails,
            ...deletedVendorItemsOfConfirmedOrders,
          ],
        };
        PurchaseOrders.updatePurchaseOrderById(
          updatedObj,
          updatedObj.id,
          response => {
            if (response.status === 'success') {
              setRefreshLedger(true);
              props.snackbar('Order has been updated successfully', 'success');
              // Transaction.log(
              //   Auth.getStoreId(),
              //   response.data.customerId,
              //   LogMessage.ORDER_UPDATED,
              //   LogMessage.ORDER_UPDATED_TITLE,
              //   [response.data.storeOrderNumber, Auth.getUserName()]
              // );
              // setOpenAddReasonDialog({ open: false });
              // setConfirmedOrder(true);
              setRefreshLedger(false);
              setOpenAddReasonDialog({ open: false });
              setConfirmedVendorOrder(true);
              setConfirmedOrderEditMode(false);
            } else {
              props.snackbar(
                'Order has not been updated successfully',
                'error'
              );
              setRefreshLedger(false);
            }
          }
        );
      } else {
        props.snackbar('Please fill the reason field', 'error');
        setRefreshLedger(false);
      }
    }
  };
  const deleteVendorConfirmOrder = () => {
    PurchaseOrders.deleteVendorStoreOrder(vendorOrderObj.id, response => {
      if (response.status === 'success') {
        setVendorOrderObj(response.data);
        props.snackbar('Order was deleted successfully', 'success');
      } else {
        props.snackbar('There is an issue in deleting order', 'error');
      }
    });
  };
  const handleVendorDeleteApiCall = (
    itemData,
    confirmedOrderDeletionMode = null // will be true only in drafted mode deletion
  ) => {
    // checking if confirmedOrderDeletionMode parameter is true or not
    if (!confirmedOrderDeletionMode) {
      //will run in confirmed mode deletion
      setOpenVendorDeleteDialog(false);
      let deletableItemdata = { ...itemData, deleted: true };
      setDeletedVendorItemsOfConfirmedOrders([
        ...deletedVendorItemsOfConfirmedOrders,
        deletableItemdata,
      ]);
      props.snackbar('Item was deleted successfully', 'success');
    } else {
      // will run in drafted mode deletion
      PurchaseOrders.deletePurchaseOrderDetails(itemData.id, response => {
        if (response.status === 'success') {
          setOpenVendorDeleteDialog(false);

          props.snackbar('Item was deleted successfully', 'success');
        } else {
          props.snackbar('Issue while deleting item', 'error');
        }
      });
    }
    let updatedList = selectedVendorProductItemList.filter(item => {
      return item.itemNumber !== itemData.itemNumber;
    });
    calculateTotalValuesForVendor(updatedList);
    // setSelectedVendorProductItemList(updatedList);
  };
  // handling callback for unselecting product item
  const handleRemoveItemFromItemList = itemData => {
    // handleVendorDeleteApiCall(itemData);
    if (confirmedOrderEditMode) {
      // will run only when user clicks on edit confirm order

      if (selectedVendorProductItemList.length < 2) {
        props.snackbar(
          'Last item of confirmed order cannot be deleted',
          'error'
        );
      } else {
        setOpenVendorDeleteDialog({ open: true, itemData: itemData });
      }
    } else {
      // added drafted property dynamically in useEffect for this deletion purpose
      if (itemData.drafted === true) {
        handleVendorDeleteApiCall(itemData, true);
      }
      // when removing item from list in order in normal creation mode
      let updatedList = selectedVendorProductItemList.filter(item => {
        return item.itemNumber !== itemData.itemNumber;
      });
      props.snackbar('item removed successfully', 'success');

      calculateTotalValuesForVendor(updatedList);
      // setSelectedVendorProductItemList(updatedList);
    }
  };

  const handleDeleteItemCallback = usersChoice => {
    if (usersChoice === true) {
      handleVendorDeleteApiCall(openVendorDeleteDialog.itemData);
    }
  };

  const handleVendorOrderDialog = () => {
    setOpenItemVendorDialog(true);
    // SetIsDrawerOpened(true);
  };
  const closeItemVendorForOrder = () => {
    setOpenItemVendorDialog(false);
  };
  const handleSelectVandor = vendor => {
    setVendorInfo(vendor);
  };

  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };

  const downloadInvoice = orderDetails => {
    let orderInvoiceObj = {
      open: true,
      order: {
        ...orderDetails,
        vendorId: orderDetails?.vendor?.id,
        vendorName: vendorInfo?.name,
        address: vendorInfo?.address,
        gstNo: vendorInfo.gstNo,
        state: vendorInfo.state,
      },
      billDate: billDate,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const closeInvoiceDialog = () => {
    let orderInvoiceObj = {
      open: false,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const downloadRecipt = orderDetails => {
    let orderReciptObj = {
      open: true,
      order: {
        ...orderDetails,
        vendorId: orderDetails.vendor.id,
      },
    };
    setOrderRecipt(orderReciptObj);
  };

  const closeReciptDialog = () => {
    let orderReciptObj = {
      open: false,
    };

    setOrderRecipt(orderReciptObj);
    document.title = 'RSL | Up-Store';
  };
  const getAllVariatiesByType = type => {
    PurchaseOrders.getAllVariatiyByType(Auth.getStoreId(), type, response => {
      if (response.status === 'success') {
        //converting  array of stings to array of objects
        let myArrayQuality = response.data.stringArrayData.map(
          (str, index) => ({
            value: str,
            id: index + 1,
          })
        );
        if (response.data.type === 'packing') {
          setVarietyDetails({ ...varietyDetails, packingData: myArrayQuality });
        }
        if (response.data.type === 'quality') {
          setVarietyDetails({ ...varietyDetails, qualityData: myArrayQuality });
        }
        if (response.data.type === 'variety') {
          setVarietyDetails({ ...varietyDetails, varietyData: myArrayQuality });
        }
      }
    });
  };

  const handleSearchVendor = event => {
    let search = event?.target?.value;
    Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
      if (response.status === 'success') {
        let filteredSearchActive = response.data.filter(item => {
          return item.active === true;
        });
        setVendorDataList(filteredSearchActive);
      }
      if (response.status === 'error') {
        getAllVendors(pageNo);
      }
    });
  };
  const handleVendorChange = React.useCallback(
    debounce(handleSearchVendor, 300),
    []
  );
  const handleScroll = () => {
    const list = listRef.current;

    if (
      list.scrollTop + list.clientHeight >= list.scrollHeight &&
      hasMoreData
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;

      setPageNo(pageNum);
      getAllVendors(pageNum);
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: '200px' }}>
        <Grid container>
          <Grid item md={8}>
            <Tooltip title='Go to orders' placement='bottom'>
              <Button
                startIcon={<ArrowLeft />}
                variant='text'
                underline='none'
                sx={{
                  padding: 0,
                  mb: 1,
                  ml: 2,
                  textTransform: 'capitalize',
                  width: '146px',
                  height: '27px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  props.setCreateVendorOrder(false);
                  props.getLatestFetchedVendorOrders(0);
                }}
              >
                Vendor Orders List
              </Button>
            </Tooltip>{' '}
          </Grid>
          <Grid sx={{ display: 'flex' }} item md={4} sm={4} xs={4}>
            <Grid>
              {confirmedVendorOrder && serverConfig.isUpstoreCloud ? (
                <Tooltip title='Edit Confirmed Order' placement='bottom'>
                  <Button
                    startIcon={<EditIcon sx={{ color: '#fff' }} />}
                    variant='contained'
                    disabled={!vendorInfo?.active}
                    sx={{
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                      mr: 2,

                      bgcolor: '#3B78C9',
                    }}
                    onClick={() => {
                      setDialogWarning(true);
                    }}
                  >
                    Edit Order
                  </Button>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid>
              {confirmedVendorOrder ? (
                <Tooltip title='Add New Order' placement='bottom'>
                  <Button
                    variant='contained'
                    disabled={!vendorInfo?.active}
                    sx={{
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                      fontSize: '14px',
                      bgcolor: '#3B78C9',
                    }}
                    onClick={() => {
                      props.reloadPage();
                    }}
                  >
                    Create PO
                  </Button>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container> */}
        <Box component='div' sx={{ mt: 2, bgcolor: '#fff' }}>
          <Grid
            container
            spacing={2}
            px={3}
            pt={2}
            sx={{ alignItems: 'center' }}
          >
            <Grid item xs={10} sm={5} md={5}>
              <Typography variant='subtitle1' sx={{ marginBottom: '-2px' }}>
                Vendor Name/Phone{' '}
                <Tooltip title='Mandatory Field' placement='top'>
                  <span style={{ color: 'red' }}>*</span>
                </Tooltip>
              </Typography>
              <Autocomplete
                // freeSolo
                ListboxProps={{
                  ref: listRef,
                  onScroll: ({ currentTarget }) => {
                    handleScroll(currentTarget);
                  },
                }}
                id='customerAutoComplete'
                options={vendorDataList}
                disableClearable
                onChange={(event, newValue) => {
                  if (newValue == 121) {
                    return null;
                  }
                  handleSelectVandor(newValue);
                }}
                onInputChange={e => handleVendorChange(e)}
                clearOnBlur={true}
                getOptionLabel={option =>
                  typeof option == 'string'
                    ? (option = '')
                    : option !== undefined
                    ? `${option.name} ` || {}
                    : ''
                }
                value={
                  Object.keys(updateVendorStoreOrder).length > 0
                    ? vendorInfo || {}
                    : ''
                }
                disabled={confirmedVendorOrder}
                renderOption={(props, option) => {
                  const isLastItem =
                    option === vendorDataList[vendorDataList.length - 1];
                  return (
                    <>
                      <div {...props} key={option.id}>
                        <span key={option.id} style={{ paddingRight: '10px' }}>
                          <PeopleAlt fontSize='small' />
                        </span>
                        {`${option.name}`}
                        <li
                          style={{ color: 'gray', marginLeft: 'auto' }}
                          key={option.id}
                          {...props}
                        >{`${
                          option.contactNo !== null ? option.contactNo : ''
                        } `}</li>
                      </div>
                      {isLastItem && skeletonLoading && (
                        <Box>
                          <Skeleton
                            width='100%'
                            height={50}
                            style={{ textAlign: 'center' }}
                          >
                            Scroll down to Load more ...
                          </Skeleton>
                        </Box>
                      )}
                    </>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      width: '90%',
                      marginTop: '6px',
                      backgroundColor: '#E1EEF5',
                      borderTop: '5px solid #E1EEF5',
                      '& .MuiInputBase-root': {
                        paddingRight: '39px !important',
                      },
                    }}
                    placeholder='Search Vendor'
                    size='small'
                    variant='outlined'
                    autoFocus
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
            {/* </Grid> */}
            {confirmedVendorOrder ? (
              <Grid item md={5} xs={12}>
                <Box sx={{ display: 'flex', mt: 3 }}>
                  <Box
                    sx={{
                      width: '450px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant='h5'>
                      Purchase order No : &nbsp;&nbsp;{' '}
                      {vendorOrderObj?.purchaseOrderNo}
                    </Typography>
                  </Box>
                  <Tooltip title='Download Invoice' placement='bottom'>
                    <DownloadIcon
                      sx={{
                        color: '#3B78C9',
                        cursor: 'pointer',
                        mr: 2,
                      }}
                      onClick={event => downloadInvoice(vendorOrderObj)}
                    />
                  </Tooltip>
                  <Tooltip title='Download Recipt' placement='bottom'>
                    <Print
                      sx={{
                        color: '#3B78C9',
                        cursor: 'pointer',
                      }}
                      onClick={event => downloadRecipt(vendorOrderObj)}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            ) : null}
            {vendorOrderObj.status === 'NEW' ? (
              <Box
                sx={{
                  width: '450px',
                  textAlign: 'center',
                  mt: 2,
                }}
              >
                <Typography variant='h5'>
                  Purchase order No : &nbsp;&nbsp;{' '}
                  {vendorOrderObj?.purchaseOrderNo}
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Box>
        <Box sx={{ p: 1, mt: 4 }}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              sx={{ backgroundColor: 'secondary.light' }}
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => {
                    setOrderExpanded(false);
                  }}
                />
              }
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                Vendor{' '}
                <b>
                  <Link
                    to='/vendor-details'
                    state={vendorInfo}
                    style={{ textDecoration: 'none' }}
                  >
                    {vendorInfo?.name}
                  </Link>
                </b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>Vendor Name</Typography>
                  <TextField
                    sx={textField}
                    id='name'
                    // multiline
                    // rows="2"
                    disabled={true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInfo, 'name')
                        ? `${vendorInfo?.name}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}
                    placeholder='Enter vendor name'
                    name='vendorName'
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>Contact No</Typography>
                  <TextField
                    sx={textField}
                    id='contactNo'
                    // multiline
                    // rows="2"
                    disabled={true}
                    // disabled={selected === true ? false : true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInfo, 'contactNo')
                        ? `${vendorInfo.contactNo}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}
                    placeholder='Enter vendor contactNo'
                    name='contactNo'
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>Email</Typography>
                  <TextField
                    sx={textField}
                    id='email'
                    // multiline
                    // rows="2"
                    disabled={true}
                    // disabled={selected === true ? false : true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInfo, 'email')
                        ? `${vendorInfo.email}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}
                    placeholder='Enter vendor email'
                    name='email'
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>Address</Typography>
                  <TextField
                    sx={textField}
                    id='address'
                    // multiline
                    // rows="2"
                    disabled={true}
                    // disabled={selected === true ? false : true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInfo, 'address')
                        ? `${vendorInfo.address}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}
                    placeholder='Enter address'
                    name='address'
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>Gst No</Typography>
                  <TextField
                    sx={textField}
                    id='gstNo'
                    // multiline
                    // rows="2"
                    disabled={true}
                    // disabled={selected === true ? false : true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInfo, 'gstNo')
                        ? `${vendorInfo.gstNo}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}

                    name='Gst NO'
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant='subtitle1'>opening Balance</Typography>
                  <TextField
                    sx={textField}
                    id='openingBalance'
                    // multiline
                    // rows="2"
                    disabled={true}
                    // disabled={selected === true ? false : true}
                    variant='outlined'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(
                        vendorInfo,
                        'openingBalance'
                      )
                        ? `${vendorInfo.openingBalance}`
                        : ''
                    }
                    // onChange={handleCustomerOnChange}
                    placeholder='Enter openingBalance '
                    name='openingBalance'
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2' || orderExpanded === true}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              sx={{ backgroundColor: 'secondary.light' }}
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => {
                    setOrderExpanded(false);
                  }}
                />
              }
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                Vendor Order
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant='subtitle1'>Bill Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={billDate}
                      inputFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      onChange={newValue => {
                        setBillDate(newValue);
                      }}
                      disabled={confirmedVendorOrder}
                      renderInput={params => (
                        <TextField size='small' sx={textField} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant='subtitle1'>
                    Bill No{' '}
                    <Tooltip title='Bill Number is required' placement='top'>
                      <span style={{ color: 'red' }}>*</span>
                    </Tooltip>
                  </Typography>
                  <TextField
                    sx={textField}
                    id='billNo'
                    // multiline
                    // rows="2"
                    disabled={confirmedVendorOrder}
                    variant='outlined'
                    type='number'
                    size='small'
                    value={
                      Validation.isBlankObjectKeyNot(vendorInform, 'billNo')
                        ? `${vendorInform.billNo}`
                        : ''
                    }
                    onChange={event => {
                      setVendorInform({
                        ...vendorInform,
                        billNo: event.target.value,
                      });
                    }}
                    placeholder='Enter bill number '
                    name='billNo'
                  />
                </Grid>
              </Grid>
              <Grid item md={12} sx={{ mt: 2 }}>
                <TableContainer
                  sx={{ maxHeight: 450 }}
                  component={Paper}
                  className='scroll'
                >
                  <Table
                    stickyHeader
                    aria-label='sticky table'
                    sx={{
                      backgroundColor: 'secondary.light',
                      position: 'relative',
                    }}
                    size='small'
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <>
                            {column.label !== null ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: '500',
                                  fontSize: 'large',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : null}
                          </>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedVendorProductItemList?.length > 0 &&
                      showVendorListItemComponent === true
                        ? selectedVendorProductItemList.map((row, index) => {
                            let newItem = [];
                            newItem.push(row);
                            return (
                              <>
                                <VendorListOrderItem
                                  key={index}
                                  index={index}
                                  row={row}
                                  productItems={newItem}
                                  purchaseOrder={vendorOrderObj}
                                  // productItems={selectedItemsSiblingItems}
                                  removeItemFromSelectionList={
                                    handleRemoveItemFromItemList
                                  }
                                  vendorDet={vendorInfo}
                                  snackbar={props.snackbar}
                                  swipeProductItemCallback={
                                    handleSwipePoductItem
                                  }
                                  updatedRowData={updateVendorRowData}
                                  currency={currency}
                                  // prop for disabling buttons
                                  getAllVariatiesByType={getAllVariatiesByType}
                                  confirmedOrder={confirmedVendorOrder}
                                  packingData={
                                    varietyDetails?.packingData &&
                                    varietyDetails?.packingData
                                  }
                                  varietyData={varietyDetails?.varietyData}
                                  qualityData={varietyDetails?.qualityData}
                                />
                              </>
                            );
                          })
                        : null}
                      <TableRow
                        hover
                        tabIndex={-1}
                        sx={{ backgroundColor: '#fff' }}
                      >
                        <TableCell
                          colSpan={!Store.getSelectedTaxType() ? 10 : 12}
                          align='left'
                        >
                          <Button
                            variant='contained'
                            style={{ marginTop: '0px' }}
                            startIcon={<Add />}
                            sx={{
                              textTransform: 'capitalize',
                              height: '27px',

                              width: !Store.getSelectedTaxType()
                                ? '90%'
                                : '100%',
                              justifyContent: 'flex-start',
                              outline: 'none',
                              backgroundColor: 'white',
                              color: 'black',
                              mt: 2,
                              '&:hover': {
                                backgroundColor: '#FCFCFC',
                              },
                            }}
                            disabled={confirmedVendorOrder}
                            onClick={() => {
                              !vendorInfo.name
                                ? props.snackbar(
                                    'vendor name is required',
                                    'error'
                                  )
                                : vendorInform.billNo === ''
                                ? props.snackbar('bill no is required', 'error')
                                : // : vendorInform.truckNo === ''
                                  // ? props.snackbar(
                                  //     'truck no is required',
                                  //     'error'
                                  //   )
                                  handleVendorOrderDialog();
                            }}
                          >
                            Add New Item
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {selectedVendorProductItemList.length > 0 ? (
                      <TableFooter
                        sx={{
                          backgroundColor: '#E1EEF5',
                          position: 'sticky',
                          bottom: 0,
                        }}
                      >
                        {' '}
                        <TableRow>
                          <TableCell
                            style={{
                              minWidth: 30,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                            }}
                          >
                            {vendorOrderObj?.totalItems?.toFixed(1)}
                          </TableCell>

                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {vendorOrderObj?.totalDiscount
                              ? vendorOrderObj?.totalDiscount
                              : '0'}
                          </TableCell>
                          {!Store.getSelectedTaxType() ||
                          Store.companyDetails.businessType === 'MANDI' ||
                          Store.companyDetails.businessType ===
                            'OPTICAL' ? null : (
                            <TableCell
                              style={{
                                minWidth: 100,
                                fontSize: 'large',
                                whiteSpace: 'nowrap',
                              }}
                            ></TableCell>
                          )}
                          {Store.companyDetails.businessType ===
                          'OPTICAL' ? null : (
                            <TableCell
                              style={{
                                minWidth: 100,
                                fontSize: 'large',
                                whiteSpace: 'nowrap',
                              }}
                            ></TableCell>
                          )}
                          {!Store.getSelectedTaxType() ||
                          Store.companyDetails.businessType ===
                            'MANDI' ? null : (
                            <TableCell
                              style={{
                                maxWidth: 100,
                                fontSize: 'large',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                              }}
                            >
                              {' '}
                              {vendorOrderObj.status === 'NEW' ||
                              vendorOrderObj.status === 'CONFIRMED'
                                ? vendorOrderObj?.tax?.toFixed(3)
                                : vendorOrderObj?.totalTaxValue?.toFixed(3)}
                            </TableCell>
                          )}

                          {Store.companyDetails?.businessType === 'MANDI' && (
                            <>
                              <TableCell
                                style={{
                                  minWidth: 100,
                                  fontSize: 'large',
                                  whiteSpace: 'nowrap',
                                }}
                              ></TableCell>
                              <TableCell
                                style={{
                                  minWidth: 100,
                                  fontSize: 'large',
                                  whiteSpace: 'nowrap',
                                }}
                              ></TableCell>
                            </>
                          )}

                          {Store.companyDetails.businessType ===
                          'OPTICAL' ? null : (
                            <TableCell
                              style={{
                                minWidth: 100,
                                fontSize: 'large',
                                whiteSpace: 'nowrap',
                              }}
                            ></TableCell>
                          )}
                          <TableCell
                            style={{
                              minWidth: 100,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {currency ? <HTMLRenderer html={currency} /> : null}
                            : {vendorOrderObj?.totalAmount?.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: 100,
                              fontSize: 'large',
                              whiteSpace: 'nowrap',
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableFooter>
                    ) : null}
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    // TODO Move to .css
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                    float: 'left',
                  }}
                >
                  {!draftedVendorMode &&
                  confirmedVendorOrder &&
                  !confirmedOrderEditMode ? (
                    <Button
                      variant='contained'
                      sx={{
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                      }}
                      onClick={event => setOpenPurchaseOrderReturnDialog(true)}
                    >
                      <Typography noWrap>{`Return Goods`}</Typography>
                    </Button>
                  ) : null}
                </Box>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent:
                    props.deletedItems.length > 0
                      ? 'space-between'
                      : 'flex-end',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                {props.deletedItems.length > 0 ? (
                  <Button
                    variant='contained'
                    sx={{
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',
                      ml: 1,
                      mb: 2,
                    }}
                    onClick={event => setOpenDeletedOrderDialog(true)}
                  >
                    <Typography noWrap>{`Deleted Items`}</Typography>
                  </Button>
                ) : null}
                {selectedVendorProductItemList.length > 0 ? (
                  <ButtonGroup sx={{ mt: 1, mb: 1 }}>
                    {confirmedOrderEditMode ? (
                      <Button
                        disabled={confirmedVendorOrder}
                        variant='contained'
                        sx={{
                          textTransform: 'capitalize',
                          p: 0,
                          fontSize: '13px',
                          width: '126px',
                          height: '27px',
                        }}
                        // onClick={event => handleSavedetails('CONFIRMED')}
                        // onClick={handleSavedetails('CONFIRMED')}
                        onClick={event =>
                          setOpenAddReasonDialog({ open: true })
                        }
                      >
                        <Typography noWrap>{`Save & Confirm`}</Typography>
                      </Button>
                    ) : draftedVendorMode && !confirmedVendorOrder ? (
                      <>
                        <Button
                          disabled={submittedButton}
                          variant='contained'
                          sx={{
                            textTransform: 'capitalize',
                            width: '126px',
                            height: '27px',
                            mr: 2,
                          }}
                          onClick={() =>
                            handleUpdateStoreOrderVenFunc('simpleDraftedUpdate')
                          }
                        >
                          {`Update`}
                        </Button>
                        <Button
                          disabled={submittedButton}
                          variant='contained'
                          sx={{
                            textTransform: 'capitalize',
                            p: 0,
                            width: '126px',
                            height: '27px',
                            fontSize: '13px',
                          }}
                          onClick={() =>
                            handleUpdateStoreOrderVenFunc(
                              'confirmDraftedUpdate'
                            )
                          }
                        >
                          <Typography noWrap>{`Update & Confirm`}</Typography>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={submittedButton || confirmedVendorOrder}
                          variant='contained'
                          sx={{
                            textTransform: 'capitalize',

                            width: '126px',
                            height: '27px',
                            mr: 2,
                          }}
                          onClick={event => handleSavedetails('NEW')}
                        >
                          <Typography noWrap>{`Draft Order`}</Typography>
                        </Button>
                        <Button
                          disabled={submittedButton || confirmedVendorOrder}
                          variant='contained'
                          sx={{
                            textTransform: 'capitalize',
                            width: '126px',
                            height: '27px',
                          }}
                          onClick={event => {
                            handleConfirmPurchaseOrder();
                          }}
                        >
                          <Typography noWrap>{`Confirm Order`}</Typography>
                        </Button>
                      </>
                    )}
                  </ButtonGroup>
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
          {confirmedVendorOrder && (
            <Accordion
              expanded={expanded === 'panel3' || paymentExpanded === true}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                sx={{ backgroundColor: 'secondary.light' }}
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() => {
                      setPaymentExpanded(false);
                    }}
                  />
                }
                aria-controls='panel3bh-content'
                id='panel3bh-header'
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Payment
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <>
                  <PaymentSection
                    customerId={
                      vendorOrderObj?.vendorId
                        ? vendorOrderObj?.vendorId
                        : vendorOrderObj.vendor.id
                    }
                    storeOrderObj={vendorOrderObj}
                    type='Vendor'
                    orderId={vendorOrderObj && vendorOrderObj?.id}
                    snackbar={props.snackbar}
                    name={vendorInfo?.name}
                    customerDetails={vendorInfo}
                    refresh={() => {
                      props.setCreateVendorOrder(false);
                      props.getLatestFetchedOrders(0);
                    }}
                    refreshLedger={refreshLedger}
                  />
                </>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Box>
      {openVendorDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openVendorDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenVendorDeleteDialog({ open: false, itemData: '' })
          }
          deleteCallback={handleDeleteItemCallback}
          dialogContent=' This will impact in all of the balances , Are you sure to delete?'
        />
      ) : null}
      {openAddReasonDialog.open ? (
        <AddReasonDialog
          openPopup={openAddReasonDialog.open}
          onClose={() => setOpenAddReasonDialog({ open: false })}
          updateStoreOrderCallback={handleUpdateStoreOrderVenFunc}
          confirmedVendorOrder={confirmedVendorOrder}
        />
      ) : null}
      {openPurchaseOrderReturnDialog ? (
        <PurchaseOrderReturn
          storeOrderObj={vendorOrderObj}
          openDialog={openPurchaseOrderReturnDialog}
          selectedReturn={selectedVendorProductItemList}
          close={closeReturn}
          snackbar={props.snackbar}
          vendorInfo={vendorInfo}
          storeOrderNumber={vendorOrderObj.purchaseOrderNo}
          handleSetUpdatedOrderItem={handleSetUpdatedOrderItem}
          columns={columns}
          currency={currency}
        />
      ) : null}

      {dialogWarning ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
          openDialog={dialogWarning}
          content='This purchase order is confirmed , Are you sure you want to edit it..'
        />
      ) : null}
      {openDeletedOrderDialog && (
        <Dialog
          maxWidth={'xl'}
          open={openDeletedOrderDialog}
          onClose={() => {
            setOpenDeletedOrderDialog(false);
          }}
        >
          <DialogContent>
            {' '}
            <Typography variant='h5'>Deleted Items</Typography>
          </DialogContent>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell>item Name</TableCell>
                    <TableCell align='right'>unit</TableCell>
                    <TableCell align='right'>QTY</TableCell>
                    <TableCell align='right'>discount</TableCell>
                    <TableCell align='right'>Base Price</TableCell>
                    <TableCell align='right'>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.deletedItems?.map(row => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.itemName}
                      </TableCell>
                      <TableCell align='right'>{row.unit}</TableCell>
                      <TableCell align='right'>{row.noOfItems}</TableCell>
                      <TableCell align='right'>{row.discount}</TableCell>
                      <TableCell align='right'>{row.basePrice}</TableCell>
                      <TableCell align='right'>{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogContent>
            <Button
              sx={{
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
              variant='outlined'
              onClick={() => {
                setOpenDeletedOrderDialog(false);
              }}
            >
              cancel
            </Button>
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        maxWidth={'xl'}
        open={orderInvoice.open}
        onClose={closeInvoiceDialog}
      >
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right'>
              <Close
                onClick={closeInvoiceDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <DialogContent>
          <PurchaseOrderInvoice
            orderDetails={orderInvoice.order}
            invoice={orderInvoice.billDate}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth={'lg'}
        open={orderRecipt.open}
        onClose={closeReciptDialog}
      >
        <DialogContent>
          <PurchaseOrderRecipt orderDetails={orderRecipt.order} />
        </DialogContent>
      </Dialog>

      <ProductItemDrawer
        snackbar={props.snackbar}
        selectedProductItemCallback={handleSelectedVendorProductItem}
        closeDrawer={closeItemVendorForOrder}
        drawerOpened={openItemVendorDialog}
        productItemList={selectedVendorProductItemList}
      />
    </>
  );
}
const AddReasonDialog = props => {
  const [comment, setComment] = React.useState('');

  return (
    <Dialog open={props.openPopup} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Add Reason Alert !!
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Reason *</Typography>
        <TextField
          sx={{ width: '100%', mt: 1 }}
          id='comment'
          multiline
          size='small'
          focused
          value={comment}
          onChange={event => setComment(event.target.value)}
          placeholder='Enter reason here'
          variant='standard'
          name='comment'
        />
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={props?.confirmedVendorOrder}
            variant='contained'
            sx={{
              padding: 0,
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => props.updateStoreOrderCallback(comment)}
          >
            Save & Confirm
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrUpdateVendorOrder;
