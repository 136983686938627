import React from 'react';
import Header from '../../component/header/Header';

import { Typography, Grid, TextField, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Banners from '../../component/banners/Banners';
import BannersXS from '../../component/banners/Banners-xs';
import UpstoreProperties from '../../modules/UpstoreProperties';
import './styles.scss';
import Validation from '../../modules/Validation';
import Prompts from '../../component/prompts/Prompts';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import UploadImages from '../../component/upload-images/upload-images';
import { Box } from '@mui/system';
import CachedIcon from '@mui/icons-material/Cached';
// Not for DeskTop App
class BannersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BannerText: [],
      PromptText: [],
      BannerLinks: [],
      BannerImageLinks: [],
      BannerNames: [],
      currentBannerUpload: '',
      currentPromptUpload: '',
      PromptLinks: [],
      upstoreSnackbar: {
        open: false,
      },
      currentItem: {
        text: '',
        key: '',
      },
      loading: false,
      bannerImageLinkUpdate: {},
      bannerTextUpdate: {},
      bannerNamesUpdate: {},
      bannerLinksUpdate: {},
      promptLinksUpdate: {},
      reloadtime: {},
    };
  }

  componentDidMount() {
    let bannerImageLinks =
      UpstoreProperties.getPropertyForBanners('BANNER_IMAGE_LINKS');
    this.setState({
      bannerImageLinkUpdate: bannerImageLinks,
    });

    if (Validation.isBlankNot(bannerImageLinks.propertyValue)) {
      this.setState({
        BannerImageLinks: JSON.parse(bannerImageLinks.propertyValue),
      });
    }
    let bannerText = UpstoreProperties.getPropertyForBanners('BANNER_TEXT');
    this.setState({
      bannerTextUpdate: bannerText,
    });
    if (Validation.isBlankNot(bannerText.propertyValue)) {
      this.setState({ BannerText: JSON.parse(bannerText.propertyValue) });
    }
    let bannerNames = UpstoreProperties.getPropertyForBanners('BANNER_NAMES');
    this.setState({
      bannerNamesUpdate: bannerNames,
    });
    if (Validation.isBlankNot(bannerNames.propertyValue)) {
      this.setState({ BannerNames: JSON.parse(bannerNames.propertyValue) });
    }
    let bannerLinks = UpstoreProperties.getPropertyForBanners('BANNER_LINKS');
    this.setState({
      bannerLinksUpdate: bannerLinks,
    });
    if (Validation.isBlankNot(bannerLinks.propertyValue)) {
      this.setState({ BannerLinks: JSON.parse(bannerLinks.propertyValue) });
    }
    let promptLinks = UpstoreProperties.getPropertyForBanners('PROMPT_LINKS');
    this.setState({
      promptLinksUpdate: promptLinks,
    });
    let reloadTimeForProp =
      UpstoreProperties.getPropertyForBanners('RELOAD_PROPERTIES');
    this.setState({
      reloadtime: reloadTimeForProp,
    });
    if (Validation.isBlankNot(promptLinks.propertyValue)) {
      this.setState({ PromptLinks: JSON.parse(promptLinks.propertyValue) });
    }
  }

  getImageUploadIndex = imagePath => {
    let index = imagePath.substring(imagePath.length - 1);
    return index;
  };

  //Upload Image Methods for Banners
  handlePath = value => {
    this.setState({
      currentBannerUpload: value,
    });
  };

  //For Prompts
  handlePath1 = value => {
    this.setState({
      currentPromptUpload: value,
    });
  };

  onUploadImage = (message, type) => {
    this.openUpstoreSnackbar(message, type);
  };

  onStartUpload = () => {
    // this.openUpstoreSnackbar('', 'loader');
  };
  openUpstoreSnackbar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    this.setState({ upstoreSnackbar: upstoreSnackbarObject });
  };

  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    this.setState({
      upstoreSnackbar: upstoreSnackbarObject,
    });
  };

  //Defining Methods For Marquees
  handleInput = value => {
    this.setState({
      currentItem: {
        text: value,
        key: Date.now(),
      },
    });
  };

  addItem = event => {
    event.preventDefault();

    let newItem = this.state.currentItem;
    if (newItem.text !== '') {
      let newMarqueeItems = [...this.state.marqueeItems];
      newMarqueeItems.push(this.state.currentItem);

      this.setState({
        marqueeItems: newMarqueeItems,
        currentItem: {
          text: '',
          key: '',
        },
      });
      this.onMarqueeSave(newMarqueeItems);
    }
  };

  //filter the marqueeItems using the key
  deleteItem = key => {
    const filteredMarqueeItems = this.state.marqueeItems.filter(
      item => item.key !== key
    );
    this.setState({
      marqueeItems: filteredMarqueeItems,
    });

    this.onMarqueeSave(filteredMarqueeItems);
  };

  onChangeText = (key, val) => {
    this.setState({ [key]: val });
  };

  // For Banners Text
  onChangeBannerText = (index, value) => {
    let BannerTextNew = this.state.BannerText;
    BannerTextNew[index] = value;
    this.setState({ BannerText: BannerTextNew });
  };

  onBannerTextSave = () => {
    let upstoreProperty = {
      property: 'BANNER_TEXT',
      propertyDescription: 'BannerText',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerText),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Banner Text is added successfully',
          'success'
        );
      }
    });
  };
  onBannerTextUpdate = () => {
    let upstoreProperty = {
      property: 'BANNER_TEXT',
      propertyDescription: 'BannerText',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerText),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.updateUpstoreProperty(
      this.state.bannerTextUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'Banner Text is added successfully',
            'success'
          );
        }
      }
    );
  };

  // For Banner Names
  onChangeBannerName = (index, value) => {
    let BannerNameNew = this.state.BannerNames;
    BannerNameNew[index] = value;
    this.setState({ BannerNames: BannerNameNew });
  };

  onBannerNameSave = () => {
    let upstoreProperty = {
      property: 'BANNER_NAMES',
      propertyDescription: 'BannerNames',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerNames),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Banner Names is added successfully',
          'success'
        );
      }
    });
  };
  onBannerNameUpdate = () => {
    let upstoreProperty = {
      property: 'BANNER_NAMES',
      propertyDescription: 'BannerNames',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerNames),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.updateUpstoreProperty(
      this.state.bannerNamesUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'Banner Names is added successfully',
            'success'
          );
        }
      }
    );
  };

  // For Banner Image Links
  onChangeBannerImageLinks = (index, value) => {
    let BannerImageLinkNew = this.state.BannerImageLinks;
    BannerImageLinkNew[index] = value;
    this.setState({ BannerImageLinks: BannerImageLinkNew });
  };
  onChangeBannerImageLinksSave = () => {
    let upstoreProperty = {
      property: 'BANNER_IMAGE_LINKS',
      propertyDescription: 'Banner Image Links',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerImageLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Banner image links is added successfully',
          'success'
        );
      }
    });
  };

  onBannerImageLinksUpdate = () => {
    let upstoreProperty = {
      property: 'BANNER_IMAGE_LINKS',
      propertyDescription: 'Banner Image Links',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerImageLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.updateUpstoreProperty(
      this.state.bannerImageLinkUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'Banner image links is added successfully',
            'success'
          );
        }
      }
    );
  };

  // For Banner Links
  onChangeBannerLinks = (index, value) => {
    let BannerLinkNew = this.state.BannerLinks;
    BannerLinkNew[index] = value;
    this.setState({ BannerLinks: BannerLinkNew });
  };
  onBannerLinkSave = () => {
    let upstoreProperty = {
      property: 'BANNER_LINKS',
      propertyDescription: 'BannerLinks',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.BannerLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Banner Links is added successfully',
          'success'
        );
      }
    });
  };
  onBannerLinkUpdate = () => {
    let upstoreProperty = {
      property: 'BANNER_LINKS',
      propertyDescription: 'BannerLinks',
      propertyValue: JSON.stringify(this.state.BannerLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.updateUpstoreProperty(
      this.state.bannerLinksUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'Banner Links is added successfully',
            'success'
          );
        }
      }
    );
  };
  // For Prompt Text
  onChangePromptText = (index, value) => {
    let PromptTextNew = this.state.PromptText;
    PromptTextNew[index] = value;
    this.setState({ PromptText: PromptTextNew });
  };

  onPromptsTextSave = () => {
    let upstoreProperty = {
      property: 'PROMPT_TEXT',
      propertyDescription: 'PromptText',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.PromptText),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'prompt Text  is added successfully',
          'success'
        );
      }
    });
  };
  onPromptsTextUpdate = () => {
    let upstoreProperty = {
      property: 'PROMPT_TEXT',
      propertyDescription: 'PromptText',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.PromptText),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.updateUpstoreProperty(
      this.state.bannerpromptTextUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'prompt Text  is added successfully',
            'success'
          );
        }
      }
    );
  };

  //For Prompt Links
  onChangePromptLinks = (index, value) => {
    let PromptLinkNew = this.state.PromptLinks;
    PromptLinkNew[index] = value;
    this.setState({ PromptLinks: PromptLinkNew });
  };

  onPromptsSave = () => {
    let upstoreProperty = {
      property: 'PROMPT_LINKS',
      propertyDescription: 'PromptLinks',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.PromptLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Prompt links is added successfully',
          'success'
        );
      }
    });
  };
  onPromptsUpdate = () => {
    let upstoreProperty = {
      property: 'PROMPT_LINKS',
      propertyDescription: 'PromptLinks',
      readOnly: true,
      propertyValue: JSON.stringify(this.state.PromptLinks),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.updateUpstoreProperty(
      this.state.promptLinksUpdate.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar(
            'Prompt links is updated successfully',
            'success'
          );
        }
      }
    );
  };
  onReloadTimeChange = () => {
    let upstoreProperty = {
      property: 'RELOAD_PROPERTIES',
      propertyDescription: 'RELOAD_PROPERTIES',
      readOnly: true,
      propertyValue: Date().substring(0, 24),
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.addUpstoreProperty(upstoreProperty, response => {
      if (response.status === 'success') {
        this.openUpstoreSnackbar(
          'Reload time links is added successfully',
          'success'
        );
      }
    });
  };
  onUpdateReloadTimeChange = () => {
    let upstoreProperty = {
      property: 'RELOAD_PROPERTIES',
      propertyDescription: 'RELOAD_PROPERTIES',
      readOnly: true,
      propertyValue: Date().substring(0, 24),
      storeId: Auth.getStoreId(),
    };

    UpstoreProperties.updateUpstoreProperty(
      this.state.reloadtime.id,
      upstoreProperty,
      response => {
        if (response.status === 'success') {
          this.openUpstoreSnackbar('Reload is updated successfully', 'success');
        }
      }
    );
  };

  render() {
    return (
      <Grid
        className='banner-root'
        style={{ marginLeft: 60, marginTop: 60, padding: 10 }}
      >
        <Header />
        <Typography>
          <h2>
            <Banners />
          </h2>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={false} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <h1>
              <BannersXS />
            </h1>
          </Grid>
          <Grid item xs={false} sm={3}></Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ButtonGroup
                color='primary'
                size='large'
                variant='contained'
                aria-label='conatined secondary button group'
                className='buttonGroup'
              >
                <Button
                  onClick={event => this.handlePath('banner_1')}
                  value='banner_1'
                  className={`${
                    this.state.currentBannerUpload == 'banner_1'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 1
                </Button>
                <Button
                  onClick={event => this.handlePath('banner_2')}
                  value='banner_2'
                  className={`${
                    this.state.currentBannerUpload == 'banner_2'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 2
                </Button>
                <Button
                  onClick={event => this.handlePath('banner_3')}
                  value='banner_3'
                  className={`${
                    this.state.currentBannerUpload == 'banner_3'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 3
                </Button>
                <Button
                  onClick={event => this.handlePath('banner_4')}
                  value='banner_4'
                  className={`${
                    this.state.currentBannerUpload == 'banner_4'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 4
                </Button>
                <Button
                  onClick={event => this.handlePath('banner_5')}
                  value='banner_5'
                  className={`${
                    this.state.currentBannerUpload == 'banner_5'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 5
                </Button>
                <Button
                  variant='contained'
                  onClick={event => this.handlePath('banner_6')}
                  value='banner_6'
                  className={`${
                    this.state.currentBannerUpload == 'banner_6'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Banner 6
                </Button>
              </ButtonGroup>
            </Box>

            <Typography textAlign='center'>
              {this.state.currentBannerUpload == '' ? (
                <p>
                  <b>Select a Banner to Upload Image</b>
                </p>
              ) : (
                <UploadImages
                  path={'banner_images'}
                  index={this.getImageUploadIndex(
                    this.state.currentBannerUpload
                  )}
                  onStartUpload={this.onStartUpload}
                  onUploadImage={this.onUploadImage}
                  snackbar={this.openUpstoreSnackbar}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <h1>Banner Image Links</h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(0, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 0
                    ? this.state.BannerImageLinks[0]
                    : null
                }
                id='BannerImageLink1'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(1, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 1
                    ? this.state.BannerImageLinks[1]
                    : null
                }
                id='BannerImageLink1'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(2, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 2
                    ? this.state.BannerImageLinks[2]
                    : null
                }
                id='BannerImageLink2'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(3, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 3
                    ? this.state.BannerImageLinks[3]
                    : null
                }
                id='BannerImageLink3'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(4, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 4
                    ? this.state.BannerImageLinks[4]
                    : null
                }
                id='BannerImageLink4'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerImageLinks(5, event.target.value)
                }
                value={
                  this.state.BannerImageLinks != undefined &&
                  this.state.BannerImageLinks.length > 5
                    ? this.state.BannerImageLinks[5]
                    : null
                }
                id='BannerImageLink5'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {this.state?.bannerImageLinkUpdate?.id ? (
                <Button
                  onClick={this.onBannerImageLinksUpdate}
                  variant='contained'
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={this.onChangeBannerImageLinksSave}
                  variant='contained'
                >
                  Save
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <h1>Banner Text</h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerText(0, event.target.value)
                }
                value={
                  this.state.BannerText != undefined &&
                  this.state.BannerText.length > 0
                    ? this.state.BannerText[0]
                    : null
                }
                id='BannerText1'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerText(1, event.target.value)
                }
                value={
                  this.state.BannerText != undefined &&
                  this.state.BannerText.length > 1
                    ? this.state.BannerText[1]
                    : null
                }
                id='BannerText2'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />{' '}
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerText(2, event.target.value)
                }
                value={
                  this.state.BannerText != undefined &&
                  this.state.BannerText.length > 2
                    ? this.state.BannerText[2]
                    : null
                }
                id='BannerText3'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.bannerTextUpdate.id ? (
                <Button onClick={this.onBannerTextUpdate} variant='contained'>
                  Update
                </Button>
              ) : (
                <Button onClick={this.onBannerTextSave} variant='contained'>
                  Save
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h1>Banner Names</h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerName(0, event.target.value)
                }
                value={
                  this.state.BannerNames != undefined &&
                  this.state.BannerNames.length > 0
                    ? this.state.BannerNames[0]
                    : null
                }
                id='BannerName1'
                label='Write Here'
                variant='outlined'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerName(1, event.target.value)
                }
                value={
                  this.state.BannerNames != undefined &&
                  this.state.BannerNames.length > 1
                    ? this.state.BannerNames[1]
                    : null
                }
                id='BannerName2'
                label='Write Here'
                variant='outlined'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerName(2, event.target.value)
                }
                value={
                  this.state.BannerNames != undefined &&
                  this.state.BannerNames.length > 2
                    ? this.state.BannerNames[2]
                    : null
                }
                id='BannerName2'
                label='Write Here'
                variant='outlined'
              />
            </h1>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.bannerNamesUpdate.id ? (
                <Typography>
                  <Button
                    onClick={this.onBannerNameUpdate}
                    variant='contained'
                    align='center'
                  >
                    Update
                  </Button>
                </Typography>
              ) : (
                <Typography>
                  <Button
                    onClick={this.onBannerNameSave}
                    variant='contained'
                    align='center'
                  >
                    Save
                  </Button>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h1>View More Links</h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerLinks(0, event.target.value)
                }
                value={
                  this.state.BannerLinks != undefined &&
                  this.state.BannerLinks.length > 0
                    ? this.state.BannerLinks[0]
                    : null
                }
                id='BannerLink1'
                label='Write Here'
                variant='outlined'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangeBannerLinks(1, event.target.value)
                }
                value={
                  this.state.BannerLinks != undefined &&
                  this.state.BannerLinks.length > 1
                    ? this.state.BannerLinks[1]
                    : null
                }
                id='BannerLink1'
                label='Write Here'
                variant='outlined'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangeBannerLinks(2, event.target.value)
                }
                value={
                  this.state.BannerLinks != undefined &&
                  this.state.BannerLinks.length > 2
                    ? this.state.BannerLinks[2]
                    : null
                }
                id='BannerLink2'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.bannerLinksUpdate.id ? (
                <Typography>
                  <Button onClick={this.onBannerLinkUpdate} variant='contained'>
                    Update
                  </Button>
                </Typography>
              ) : (
                <Typography>
                  <Button onClick={this.onBannerLinkSave} variant='contained'>
                    Save
                  </Button>
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Prompts />
          </Grid>

          <Grid item xs={12} className='Buttongroup'>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonGroup
                color='primary'
                size='large'
                variant='contained'
                aria-label='conatined secondary button group'
                className='buttonGroup'
              >
                <Button
                  onClick={event => this.handlePath1('prompt_1')}
                  value='prompt_1'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_1'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 1
                </Button>
                <Button
                  onClick={event => this.handlePath1('prompt_2')}
                  value='prompt_2'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_2'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 2
                </Button>
                <Button
                  onClick={event => this.handlePath1('prompt_3')}
                  value='prompt_3'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_3'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 3
                </Button>

                <Button
                  onClick={event => this.handlePath1('prompt_4')}
                  value='prompt_4'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_4'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 4
                </Button>
                <Button
                  onClick={event => this.handlePath1('prompt_5')}
                  value='prompt_5'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_5'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 5
                </Button>
                <Button
                  onClick={event => this.handlePath1('prompt_6')}
                  value='prompt_6'
                  className={`${
                    this.state.currentPromptUpload == 'prompt_6'
                      ? 'upload-banner'
                      : ''
                  }`}
                >
                  Prompt 6
                </Button>
              </ButtonGroup>
            </Box>
            <Typography textAlign='center'>
              {this.state.currentPromptUpload == '' ? (
                <p>
                  <b>Select a Prompt to Upload Image</b>
                </p>
              ) : (
                <UploadImages
                  path={'prompt_images'}
                  index={this.getImageUploadIndex(
                    this.state.currentPromptUpload
                  )}
                  onStartUpload={this.onStartUpload}
                  onUploadImage={this.onUploadImage}
                  snackbar={this.openUpstoreSnackbar}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <h1>Prompts links</h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(0, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 0
                    ? this.state.PromptLinks[0]
                    : null
                }
                id='PromptLink1'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(1, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 1
                    ? this.state.PromptLinks[1]
                    : null
                }
                id='PromptLink2'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(2, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 2
                    ? this.state.PromptLinks[2]
                    : null
                }
                id='PromptLink2'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(3, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 3
                    ? this.state.PromptLinks[3]
                    : null
                }
                id='PromptLink3'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(4, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 4
                    ? this.state.PromptLinks[4]
                    : null
                }
                id='PromptLink4'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <h1>
              {' '}
              <TextField
                onChange={event =>
                  this.onChangePromptLinks(5, event.target.value)
                }
                value={
                  this.state.PromptLinks != undefined &&
                  this.state.PromptLinks.length > 5
                    ? this.state.PromptLinks[5]
                    : null
                }
                id='PromptLink5'
                label='Write Here'
                variant='outlined'
                color='secondary'
              />
            </h1>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {this.state.promptLinksUpdate.id ? (
                <Typography>
                  <Button onClick={this.onPromptsUpdate} variant='contained'>
                    Update
                  </Button>
                </Typography>
              ) : (
                <Typography>
                  <Button onClick={this.onPromptsSave} variant='contained'>
                    Save
                  </Button>
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid sx={{ ml: 'auto' }}>
            {Object.keys(this.state.reloadtime).length === 0 ? (
              <Typography>
                <Button
                  onClick={this.onReloadTimeChange}
                  variant='contained'
                  endIcon={<CachedIcon />}
                >
                  Add Refresh Time
                </Button>
              </Typography>
            ) : (
              <Typography>
                <Button
                  onClick={this.onUpdateReloadTimeChange}
                  variant='contained'
                  endIcon={<CachedIcon sx={{ color: 'white' }} />}
                >
                  Refresh Time
                </Button>
              </Typography>
            )}
          </Grid>
        </Grid>

        {this.state.upstoreSnackbar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={this.state.upstoreSnackbar}
            onSnackbarClose={this.onSnackbarClose}
          />
        ) : null}
      </Grid>
    );
  }
}

export default BannersView;
