import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentsIcon from '@mui/icons-material/Payments';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import MonitorIcon from '@mui/icons-material/Monitor';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Auth from '../../modules/Auth';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AllPermissions from '../../modules/AllPermissions';
import UpstoreProperties from '../../modules/UpstoreProperties';
import { serverConfig } from '../../config/server-config';
import Store from '../../modules/Store';
import UserSubscription from '../../modules/UserSubscription';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';

const drawerWidth = 225;

const openedMixin = theme => ({
  width: drawerWidth,
  backgroundColor: '#D1E3F3 ',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#D1E3F3 ',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  opacity: '0.7px',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  marginRight: '3rem',
  flexShrink: 0,
  backdrop: { zIndex: theme.zIndex.drawer + 1 },

  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideDrawer(props) {
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(props.openDrawerProp);
  // const [propertyValue, setPropertyValue] = React.useState(null);
  const [userSubscriptionPlans, setuserSubscriptionPlans] = React.useState({});

  const [openC, setOpenC] = React.useState(false);
  React.useEffect(() => {
    getUserSubscriptionByStoreId();
    // systemProperty();
  }, []);

  const handleClickC = () => {
    setOpenC(!openC);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleClick = key => {
    // will work only when we are already in orders page
    if (key === 'orders') {
      if (window.location.href?.endsWith('/orders')) {
        window.location.reload(false);
      }
    }
    navigate(`/${key}`);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMouseClick = event => {
    event.preventDefault();

    setOpen(true);
  };
  const handleRequestClose = event => {
    setOpen(false);
  };
  // const systemProperty = () => {
  //   UpstoreProperties.getUpstoreProperties(
  //     Auth.getStoreId(),
  //     false,
  //     response => {
  //       if (response.status === 'success') {
  //         let upstoreProp = response.data?.find(
  //           prop => prop?.property === 'ESTORE_ENABLED'
  //         );
  //         setPropertyValue(upstoreProp?.propertyValue);
  //       }
  //     }
  //   );
  // };

  const getUserSubscriptionByStoreId = () => {
    UserSubscription.getUserSubscriptionByStoreId(
      Auth.getStoreId(),
      response => {
        if (response.status === 'success') {
          setuserSubscriptionPlans(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };

  return (
    <Box onMouseLeave={handleRequestClose}>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
            }}
            onClick={() => {
              open === false || open === undefined
                ? handleDrawerOpen()
                : handleDrawerClose();
            }}
          >
            {open === true ? (
              <ChevronLeftIcon
                sx={{ width: '30px', height: '30px', color: '#3B78C9' }}
              />
            ) : (
              <ChevronRightIcon
                sx={{ width: '30px', height: '30px', color: '#3B78C9' }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        {Auth.getUserRoleId() === '-1' ? (
          <List
            onMouseOver={handleMouseClick}
            sx={{
              overflowY: { md: 'scroll', sm: 'scroll', xs: 'scroll' },
              overflowX: 'hidden',
            }}
          >
            {' '}
            <ListItem
              onClick={() => {
                handleClick('monitoring-tool');
              }}
              sx={{ height: '30px' }}
            >
              <ListItemIcon onClick={handleDrawerOpen}>
                <MonitorIcon sx={{ color: '#3B78C9' }} />
              </ListItemIcon>
              <ListItemText
                className='slide_text'
                sx={{ cursor: 'pointer', fontSize: '1rem', color: '#404142' }}
                primary='Monitoring'
              />
            </ListItem>
          </List>
        ) : (
          <List
            onMouseOver={handleMouseClick}
            sx={{
              overflowY: { md: 'scroll', sm: 'scroll', xs: 'scroll' },
              overflowX: 'hidden',
            }}
          >
            {AllPermissions.getUserAccessiblePermissions('Dashboard') &&
            AllPermissions.getUserAccessiblePermissions('Dashboard')?.read ===
              false ? null : (
              <>
                <ListItem sx={{ height: '42px', color: '#5599a7' }}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Analytics' />
                </ListItem>
                <ListItem sx={{ height: '30px' }}>
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <DashboardIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Dashboard'
                    className='slide_text'
                    onClick={() => {
                      handleClick('dashboard/customer');
                    }}
                    sx={{
                      fontSize: '1rem',
                      cursor: 'pointer',
                      color: '#404142',
                    }}
                  />
                  <span className='shortcut-span'>Alt+D</span>
                </ListItem>
              </>
            )}
            <ListItem sx={{ height: '42px', color: '#5599a7' }}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary='Inventory & Sale' />
            </ListItem>
            {AllPermissions.getUserAccessiblePermissions('Orders') &&
            AllPermissions.getUserAccessiblePermissions('Orders')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('orders');
                  }}
                  sx={{ height: '42px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ShoppingCartIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Orders'
                  />
                  <span className='shortcut-span'>Alt+O</span>
                </ListItem>
              </>
            )}
            {userSubscriptionPlans?.userSubscribeServiceModelList?.map(item => {
              return item.serviceName === 'eCommerce' &&
                serverConfig.isUpstoreCloud === true ? (
                <ListItem
                  onClick={() => {
                    handleClick('estore-orders');
                  }}
                  sx={{ height: '42px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <LocalMallIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='eStore Orders'
                  />
                </ListItem>
              ) : null;
            })}

            {/* {serverConfig.isUpstoreCloud === true &&
            propertyValue === 'true' ? (
             
            ) : null} */}
            {AllPermissions.getUserAccessiblePermissions('Product') &&
            AllPermissions.getUserAccessiblePermissions('Product')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('products');
                  }}
                  sx={{ height: '42px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <Inventory2Icon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>

                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Products'
                  />
                  <span className='shortcut-span'>Ctrl+Alt+P</span>
                </ListItem>
              </>
            )}
            {AllPermissions.getUserAccessiblePermissions('Category') &&
            AllPermissions.getUserAccessiblePermissions('Category')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('categories');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <CardGiftcardIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Categories'
                  />
                  <span className='shortcut-span'>Ctrl+Alt+C</span>
                </ListItem>
              </>
            )}
            {AllPermissions.getUserAccessiblePermissions('Customer') &&
            AllPermissions.getUserAccessiblePermissions('Custome')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('customers');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <PermIdentityIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Customers'
                  />
                  <span className='shortcut-span'>Alt+C</span>
                </ListItem>
              </>
            )}
            {AllPermissions.getUserAccessiblePermissions('Payments') &&
            AllPermissions.getUserAccessiblePermissions('Payments')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('payments/customer');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <PaymentsIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Payments'
                  />
                </ListItem>
              </>
            )}
            {AllPermissions.getUserAccessiblePermissions('Vendor') &&
            AllPermissions.getUserAccessiblePermissions('Vendor')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('vendors');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <PersonIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Vendors'
                  />
                  <span className='shortcut-span'>Alt+V</span>
                </ListItem>
              </>
            )}
            {AllPermissions.getUserAccessiblePermissions('UserSetup') &&
            AllPermissions.getUserAccessiblePermissions('UserSetup')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('user-setup');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <PeopleAltIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='User Setup'
                  />
                  <span className='shortcut-span'>Alt+U</span>
                </ListItem>
              </>
            )}
            <ListItem sx={{ height: '45px', color: '#5599a7' }}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary='Company & Stock' />
            </ListItem>
            <ListItem sx={{ height: '30px' }}>
              <ListItemIcon onClick={handleDrawerOpen}>
                <ShoppingBasketIcon sx={{ color: '#3B78C9' }} />
              </ListItemIcon>
              <ListItemText
                onClick={() => {
                  handleClickC();
                }}
                className='slide_text'
                sx={{ cursor: 'pointer', fontSize: '1rem', color: '#404142' }}
                primary='Purchase'
              />
              {openC ? (
                <ExpandLessIcon
                  className='expand-icons'
                  onClick={() => {
                    handleClickC();
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  className='expand-icons'
                  onClick={() => {
                    handleClickC();
                  }}
                />
              )}
            </ListItem>
            <Collapse in={openC} timeout='auto' unmountOnExit>
              {AllPermissions.getUserAccessiblePermissions(
                'PurchaseDashboard'
              ) &&
              AllPermissions.getUserAccessiblePermissions('PurchaseDashboard')
                ?.read === false ? null : (
                <>
                  <List component='div' disablePadding sx={{ pl: 7 }}>
                    <ListItem
                      onClick={() => {
                        handleClick('dashboard/vendor');
                      }}
                      button
                    >
                      <ListItemText primary='Dashboard' />
                      <span className='shortcut-span'>Alt+P</span>
                    </ListItem>
                  </List>
                </>
              )}

              {AllPermissions.getUserAccessiblePermissions('PurchaseBill') &&
              AllPermissions.getUserAccessiblePermissions('PurchaseBill')
                ?.read === false ? null : (
                <>
                  <List component='div' disablePadding sx={{ pl: 7 }}>
                    <ListItem
                      onClick={() => {
                        handleClick('purchase-bill');
                      }}
                      button
                    >
                      <ListItemText primary='Purchase Bill' />
                      <span className='shortcut-span'>Alt+B</span>
                    </ListItem>
                  </List>
                </>
              )}

              <List component='div' disablePadding sx={{ pl: 7 }}>
                {/* {Store?.companyDetails?.businessType === 'MANDI' ? ( */}
                <ListItem
                  onClick={() => {
                    handleClick('purchase-order');
                  }}
                  button
                >
                  <ListItemText primary='Purchase Order' />
                </ListItem>
                {/* ) : null} */}
              </List>

              {AllPermissions.getUserAccessiblePermissions('VendorPayment') &&
              AllPermissions.getUserAccessiblePermissions('VendorPayment')
                ?.read === false ? null : (
                <>
                  <List component='div' disablePadding sx={{ pl: 7 }}>
                    <ListItem
                      onClick={() => {
                        handleClick('payments/vendor');
                      }}
                      button
                    >
                      <ListItemText primary='Vendor Payments' />
                    </ListItem>
                  </List>
                </>
              )}
            </Collapse>
            {AllPermissions.getUserAccessiblePermissions('AllLedger') &&
            AllPermissions.getUserAccessiblePermissions('AllLedger')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('purchase-ledger');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ArticleIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='All Ledgers'
                  />
                  <span className='shortcut-span'>Alt+L</span>
                </ListItem>
              </>
            )}
            {Store?.companyDetails?.businessType === 'MANDI' ? (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('mandi-chalan');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ReceiptLongIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Mandi Chalan'
                  />
                </ListItem>

                <ListItem
                  onClick={() => {
                    handleClick('mandi-watak');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ReceiptIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Mandi Watak'
                  />
                </ListItem>
                <ListItem
                  onClick={() => {
                    handleClick('daybook');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ReceiptIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Day Book'
                  />
                </ListItem>
              </>
            ) : null}

            {AllPermissions.getUserAccessiblePermissions('Expenses') &&
            AllPermissions.getUserAccessiblePermissions('Expenses')?.read ===
              false ? null : (
              <>
                <ListItem sx={{ height: '30px' }}>
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <ExploreIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    onClick={() => {
                      handleClick('expenses');
                    }}
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Expenses'
                  />
                  <span className='shortcut-span'>Alt+E</span>
                </ListItem>
              </>
            )}
            <ListItem sx={{ height: '30px' }}>
              <ListItemIcon onClick={handleDrawerOpen}>
                <AccountBalanceIcon sx={{ color: '#3B78C9' }} />
              </ListItemIcon>
              <ListItemText
                onClick={() => {
                  handleClick('accounts');
                }}
                className='slide_text'
                sx={{
                  cursor: 'pointer',
                  fontSize: '1rem',
                  color: '#404142',
                }}
                primary='Accounts'
              />
            </ListItem>
            {AllPermissions.getUserAccessiblePermissions('Conmpany') &&
            AllPermissions.getUserAccessiblePermissions('Company')?.read ===
              false ? null : (
              <>
                <ListItem
                  onClick={() => {
                    handleClick('store');
                  }}
                  sx={{ height: '30px' }}
                >
                  <ListItemIcon onClick={handleDrawerOpen}>
                    <StoreIcon sx={{ color: '#3B78C9' }} />
                  </ListItemIcon>
                  <ListItemText
                    className='slide_text'
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#404142',
                    }}
                    primary='Company'
                  />
                </ListItem>
              </>
            )}
            <ListItem sx={{ height: '30px' }}>
              <ListItemIcon onClick={handleDrawerOpen}>
                <SettingsIcon sx={{ color: '#3B78C9' }} />
              </ListItemIcon>
              <ListItemText
                className='slide_text'
                sx={{ cursor: 'pointer', fontSize: '1rem', color: '#404142' }}
                primary='Settings'
              />
            </ListItem>
          </List>
        )}
        <ListItem
          onClick={() => {
            Auth.logout();
          }}
          sx={{ height: '50px' }}
        >
          <ListItemIcon onClick={handleDrawerOpen}>
            <LogoutIcon sx={{ color: '#3B78C9' }} />
          </ListItemIcon>

          <Button
            variant='contained'
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              backgroundColor: 'gray',

              // float: 'right',
            }}
          >
            Logout
          </Button>
        </ListItem>
      </Drawer>
      <br />
    </Box>
  );
}
