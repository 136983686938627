import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box, Button, Tab, Tabs, Tooltip } from '@mui/material';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CommentIcon from '@mui/icons-material/Comment';
import CommentsSection from '../commentsSection/CommentsSection';
import ReviewsSection from '../reviewsSection/ReviewsSection';
import './messageReview.css';
import Product from '../../modules/Product';
import { Close } from '@mui/icons-material';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function MessageReviewSection({
  open,
  closeDrawer,
  productId,
  snackbar,
}) {
  const [value, setValue] = React.useState(0);
  const [productData, setProductData] = React.useState({
    comments: '',
    reviews: '',
  });
  React.useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = () => {
    Product.getProductByProductId(productId, (response) => {
      if (response.status === 'success') {
        setProductData({
          comments: response.data.comments,
          reviews: response.data.reviews,
        });
      } else {
        setProductData({
          comments: '',
          reviews: '',
        });
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Drawer anchor='right' open={open || closeDrawer} onClose={closeDrawer}>
        <Box maxWidth={'35rem'} sx={{ position: 'relative' }}>
          <Box className='tabs-wrapper-div'>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor='primary'
              indicatorColor='primary'
              aria-label='secondary tabs example'
            >
              <Tab
                icon={<CommentIcon />}
                iconPosition='end'
                label='Messages'
                {...a11yProps(0)}
              />
              <Tab
                icon={<ReviewsIcon />}
                iconPosition='end'
                label='Reviews'
                {...a11yProps(1)}
              />
            </Tabs>
            <Tooltip title='Close drawer' placement='left'>
              <Close className='close-drawer-icon' onClick={closeDrawer} />
            </Tooltip>
          </Box>
          <TabPanel value={value} index={0}>
            <CommentsSection
              productId={productId}
              commentsData={productData?.comments}
              getLatestData={() => getProductDetails()}
              openSnackbar={snackbar}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReviewsSection
              productId={productId}
              reviewsData={productData?.reviews}
              getLatestData={() => getProductDetails()}
              openSnackbar={snackbar}
            />
          </TabPanel>
        </Box>
      </Drawer>
    </div>
  );
}
