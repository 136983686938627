import { id } from 'date-fns/locale';
import UpstoreApi from '../UpstoreApi';

class Payments {
  getPaymentsByCustomerId = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/customer/${customerId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting payments by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getAllPaymentsByType = (storeId, startDate, endDate, type, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/get-allPaymentsByTpe/${storeId}?startDate=${startDate}&endDate=${endDate}&type=${type}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting payments by store id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  searchPaymentByPaymentId = (storeId, searchValue, type, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/search-payment/${storeId}/${type}?search=${searchValue}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while searching  payments', error.message);
        callBack({ status: 'error' });
      });
  };

  getPaymentListByStoreId = (storeId, type, pageNumber, deleted, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/store/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}&type=${type}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting payments by store id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getLastPaymentByCustomerId = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/customer-last-payment/${customerId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
        return response.data;
      })
      .catch(error => {
        console.log(
          'Error occure while getting last payment  by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  //get all payments
  getTotalPaymentPriceByStoreId = (
    storeId,
    startDate,
    newEndDate,
    type,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',

      url: `/payments/${storeId}/store/${startDate}/${newEndDate}?type=${type}&timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else if (response.data === '') {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log('Error occure while getting payments', error.message);
        callBack({ status: 'error' });
      });
  };

  paymentDatePicker = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/payments/update/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while saving payment date ', error.message);
        callBack({ status: 'error' });
      });
  };
  savePayment = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/payments',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while saving payment ', error.message);
        callBack({ status: 'error' });
      });
  };

  deletePayment = (paymentId, data, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: 'payments/delete-payment/' + paymentId,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting payment', error.message);
        callBack({ status: 'error' });
      });
  };
  getPaymentByOrderIdForAnonymous = (orderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `payments/payments-by-orderId/${orderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
        return response.data;
      })
      .catch(error => {
        console.log(
          'Error occure while getting last payment  by orderId',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new Payments();
