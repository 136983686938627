import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid, Button } from '@mui/material';
import Auth from '../../modules/Auth';
import User from '../../modules/User';
import './style.css';

const AccountSetting = () => {
  const [values, setValues] = React.useState({});
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    User.getByUserId(Auth.getUserLoginId(), response => {
      if (response.status === 'success') {
        setValues({
          title: response.data.title,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          username: response.data.username,
          phone: response.data.phone,
          id: response.data.id,
          address: response.data.address,
        });
      }
    });
  };

  const updateDetails = () => {
    let data = { ...values };
    User.updateUserDetails(Auth.getUserLoginId(), data, response => {
      if (response == 'success') {
      } else {
        console.log('error');
      }
    });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Grid container>
      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          Username
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Username"
          fullWidth
          id="Username"
          name="username"
          disabled={true}
          onChange={handleInputChange}
          value={values.username}
          autoComplete="Username"
        />
      </Grid>
      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          Email
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Eneter Email Here"
          fullWidth
          id="Email"
          name="email"
          onChange={handleInputChange}
          value={values.email}
          autoComplete="Email"
        />
      </Grid>
      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          First Name
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Enter first name Here"
          fullWidth
          id="firstName"
          name="firstName"
          value={values.firstName}
          onChange={handleInputChange}
          autoComplete="customerName"
        />
      </Grid>
      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          Last Name
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Enter Last Name Here"
          fullWidth
          id="LastName"
          name="lastName"
          value={values.lastName}
          onChange={handleInputChange}
          autoComplete="LastName"
        />
      </Grid>

      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          Phone
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Enter Phone Here"
          fullWidth
          id="Phone"
          name="phone"
          onChange={handleInputChange}
          value={values.phone}
          autoComplete="Phone"
        />
      </Grid>
      <Grid item md={6} xs={12} className="padding-1">
        <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
          address
        </Typography>
        <TextField
          sx={{ marginTop: '1px' }}
          size="small"
          variant="outlined"
          margin="normal"
          required
          placeholder="Enter address Here"
          fullWidth
          id="address"
          name="address"
          onChange={handleInputChange}
          value={values.address}
          autoComplete="address"
          multiline
          rows={3}
        />
      </Grid>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          marginTop: '1rem',
          paddingRight: '1rem',
        }}
      >
        <Button
          variant="contained"
          style={{
            width: '126px',
            height: '27px',
            textTransform: 'capitalize',

            color: '#F3F5F9',
            backgroundColor: '#365E99',
          }}
          onClick={updateDetails}
        >
          <Typography sx={{ fontSize: '14px' }} noWrap>
            Save Details
          </Typography>
        </Button>
      </div>
    </Grid>
  );
};
export default AccountSetting;
