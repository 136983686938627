import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  NativeSelect,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Ledger from '../../modules/Ledger';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import CreditCard from '../../assets/payments/credit-card.png';
import DebitCard from '../../assets/payments/debit-card.png';
import CashPayment from '../../assets/payments/cash-payment.png';
import UPIPayment from '../../assets/payments/upi-payment.png';
import Cheque from '../../assets/payments/cheque.png';

import Bank from '../../assets/payments/bank.png';
import * as moment from 'moment';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AdjustPaymentDialog from '../adjustPaymentDialog/AdjustPaymentDialog';
import DatePickerComp from '../datePicker/DatePicker';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

import Payments from '../../modules/Payments';
import Transaction from '../../modules/Transaction';
import Auth from '../../modules/Auth';
import { LogMessage } from '../../modules/Logger';
import Customer from '../../modules/Customer';

import WarningDialog from '../warningDialog/WarningDialog';
import { Loading } from '../loading/Loading';
import UserRoles from '../../modules/UserRoles';
import './styles.css';
import dateConveter from '../../modules/DateConveter/dateConveter';
import Order from '../../modules/Order';
import Vendor from '../../modules/Vendor';
import Validation from '../../modules/Validation';
import Configuration from '../../modules/Configuration';

class PaymentSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      customerLedger: [],
      checkedPaymentOption: '',
      amountPaid: '',
      remark: '',
      dialogWarning: false,
      value: new Date(),
      checkDate: new Date(),
      chequeSelected: false,
      loggedUserRoleName: '',
      defaultPaymentOption: {},
      adjusmentAmountDialog: false,
      currency: null,
      disable: false,
      bankDetails: [],
      bankId: '',
      disableAdjustmentButton: false,
      storeOrder: { ...this.props.storeOrderObj },
      customerDetails: this.props.customerDetails,
    };
  }

  componentDidMount() {
    this.getUserRole();
    this.getLedgerForCustomer();
    this.getCurrency();
    this.getDefaultConfigForPaymentoptions();
    this.getAccountDetails();
    this.setState({
      storeOrder: this.props.storeOrderObj,
      checkedPaymentOption: 'Cash',
    });
    if (
      this.props.customerDetails?.customerName?.toUpperCase() === 'ANONYMOUS' &&
      this.state.storeOrder?.storeOrderStatus === 'CONFIRMED'
    ) {
      this.setState({ amountPaid: this.props.storeOrderObj?.totalAmount });

      this.getPaymentByOrderIdForUser(true);
    }
  }
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getUserRole() {
    UserRoles.getUserRoleId(Auth.getUserRoleId(), response => {
      if (response.status === 'success') {
        this.setState({ loggedUserRoleName: response.data?.name });
      }
    });
  }

  getDefaultConfigForPaymentoptions = () => {
    Configuration.getDefaultConfigurationByBusinessTypeAndSettingNumber(
      Store?.companyDetails?.businessType,
      10,
      response => {
        if (response.status === 'success') {
          this.setState({
            defaultPaymentOption: response.data,
          });
        }
      }
    );
  };
  getPaymentByOrderIdForUser = anonymous => {
    Payments.getPaymentByOrderIdForAnonymous(this?.props?.orderId, response => {
      if (response.status === 'success') {
        if (response.data && anonymous) {
          if (Validation.isBlankNot(response.data)) {
            let totalPaidAmount = 0;
            for (let index = 0; index < response.data.length; index++) {
              const payment = response.data[index];
              totalPaidAmount += parseFloat(payment?.amount);
            }

            let newPayableAmount =
              parseFloat(this.state.amountPaid) - totalPaidAmount;

            this.setState({ amountPaid: newPayableAmount.toFixed(2) });
          } else {
            console.log('');
          }
        }
      }
    });
  };
  //for balance & payment no getting reflected in the orders section
  componentDidUpdate(prevProps) {
    if (this.props.refreshLedger !== prevProps.refreshLedger) {
      this.getLedgerForCustomer();
    }
  }
  getLedgerForCustomer = () => {
    Ledger.getLedgerForCustomer(this.props?.customerId, response => {
      if (response.status === 'success') {
        this.setState({ customerLedger: [...response.data], loader: false });
      } else {
        this.setState({ customerLedger: [], loader: false });
      }
    });
  };

  handleCheckDialog = paymentOption => {
    let chequeObj = {
      checkEditDialog: true,
      checkSelected: true,
    };
    this.setState({ checkDateDialog: chequeObj });
    this.setState({ checkedPaymentOption: paymentOption });
  };

  closeChequeDateDialog = () => {
    let closeEditDateDialog = {
      checkEditDialog: false,
    };
    this.setState({ checkDateDialog: closeEditDateDialog });
  };

  onDateChange = newValue => {
    this.setState({ value: newValue });
  };

  handleChange = paymentOption => {
    if (paymentOption === 'Cheque') {
      this.handleCheckDialog(paymentOption);
      this.setState({ chequeSelected: true });
    } else {
      this.setState({ checkedPaymentOption: paymentOption });
      this.setState({ chequeSelected: false });
    }
  };

  handlePaymentApiCall = (remark, amount, pay) => {
    let a;
    dateConveter.convertDate(this.state.value, response => {
      a = response.data;
    });
    //changed customerId from Order Details  to customerDetails As Id gets Undefined
    let data = {
      amount: pay === 'Adjust' ? amount : this.state.amountPaid,
      customerId: this.props?.customerDetails?.id,
      storeId: Auth.getStoreId(),
      paymentType:
        pay === 'Adjust' ? 'Adjustment' : this.state.checkedPaymentOption,
      remark:
        pay === 'Adjust'
          ? `Adjusted ${remark}`
          : `${this.state.checkedPaymentOption} ${this.state.remark}`,
      orderId: this.props.orderId,
      checkDate:
        this.state.checkedPaymentOption === 'Cheque'
          ? this.state.checkDate
          : '',
      createdBy: this.state.loggedUserRoleName,
      type: this.props.type,
      customerName: this.props.name,
      // paymentDate: this.state.value,
      paymentDate: a,
      bankId: this.state.bankId ? this.state.bankId : null,
    };

    Payments.savePayment(data, response => {
      if (response.status == 'success') {
        this.props.snackbar(
          `Payment of ${
            pay === 'Adjust' ? amount : this.state.amountPaid
          } is done successfully`,
          'success'
        );
        this.setState({ disableAdjustmentButton: false });
        Transaction.log(
          Auth.getStoreId(),
          response.data.customerId,
          pay === 'Adjust'
            ? LogMessage.PAYMENT_ADJUSTMENT
            : LogMessage.PAYMENT_DONE,
          pay === 'Adjust'
            ? LogMessage.PAYMENT_ADJUSTMENT_TITLE
            : LogMessage.PAYMENT_DONE_TITLE,
          pay === 'Adjust'
            ? [
                response.data.amount,
                Auth.getUserName(),
                this.state.checkedPaymentOption,
              ]
            : [
                response.data.amount,
                this.props?.customerDetails?.customerName,
                Auth.getUserName(),
                this.state.checkedPaymentOption,
              ]
        );

        if (
          this.props.customerDetails?.customerStatus?.toUpperCase() === 'NEW'
        ) {
          Customer.resetCustomerById(this.props.customerId, response => {
            if (response.status === 'success') {
              this.props.handleCutomerUpdateCallback(response.data);
            } else {
              console.log('error');
            }
          });
        } else if (
          this.props.customerDetails?.status?.toUpperCase() === 'NEW'
        ) {
          Vendor.resetVendorById(this.props.customerId, response => {
            if (response.status === 'success') {
              this.props.handleVendorUpdateCallback(response.data);
            } else {
              console.log('error');
            }
          });
        }

        this.setState({
          amountPaid: '',
          remark: '',
          checkedPaymentOption: '',
          adjusmentAmountDialog: false,
        });

        this.getLedgerForCustomer();
        if (this.props?.orderId) {
          this.reloadStoreOrder();
        }
        if (this.props.paymentFrom === 'MakePayment') {
          this.props.reload(false, 0);
        }
      }
    });
  };

  reloadStoreOrder = () => {
    Order.getStoreOrder(this.props?.orderId, response => {
      this.setState({ storeOrder: { ...response.data } });
    });
  };

  PayAdjustedAmount = (remark, amount, setAdjustAmount, setAdjustRemark) => {
    if (remark === '') {
      this.props.snackbar('remark is required', 'error');
      return;
    } else if (amount === '') {
      this.props.snackbar('amount is required', 'error');
      return;
    } else if (parseInt(amount) === 0) {
      this.props.snackbar("amount can't be zero", 'error');
      return;
    } else {
      this.setState({ disableAdjustmentButton: true });
      this.handlePaymentApiCall(remark, amount, 'Adjust');
      setAdjustAmount('');
      setAdjustRemark('');
    }
  };

  disablePayment = () => {
    this.setState({ disable: true });
    setTimeout(() => {
      this.setState({ disable: false });
    }, 5000);
  };
  handlePayAmount = () => {
    this.disablePayment();

    if (this.state?.amountPaid && parseFloat(this.state?.amountPaid) < 0) {
      this.props.snackbar('Please enter a positive amount', 'error');
      return null;
    }

    if (!this.state.checkedPaymentOption) {
      this.props.snackbar('Please select a payment method', 'error');
      return null;
    } else if (
      !this.state.remark &&
      (this.state.checkedPaymentOption === 'Cheque' ||
        this.state.checkedPaymentOption === 'Bank Transfer' ||
        this.state.checkedPaymentOption === 'UPI Transfer')
    ) {
      this.props.snackbar('Please enter a remark first', 'error');
    } else if (!this.state.amountPaid) {
      this.props.snackbar('Please enter amount first', 'error');
      return null;
    } else {
      if (this.state.amountPaid) {
        if (this.props.type === 'Vendor') {
          this.handlePaymentApiCall();
        } else {
          if (
            this.props?.customerDetails?.customerName?.toUpperCase() ===
            'ANONYMOUS'
          ) {
            this.handlePaymentApiCall();
          } else if (
            this.state?.amountPaid == this.state?.customerLedger[0]?.drAmount
          ) {
            this.setState({ dialogWarning: true });
          } else {
            this.handlePaymentApiCall();
          }
        }
      } else {
        this.props.snackbar('Invalid Amount format', 'error');
      }
    }
  };
  getAccountDetails = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        this.setState({ bankDetails: response.data });
      } else {
        this.setState({ bankDetails: [] });
      }
    });
  };
  handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      this.setState({ dialogWarning: false });
      this.handlePaymentApiCall();
    } else {
      this.setState({
        amountPaid: '',
        dialogWarning: false,
      });
    }
  };

  handleSelectedDate = value => {
    this.setState({ checkDate: { date: value.value } });
    this.closeChequeDateDialog();
  };
  paymentNow = () => {
    this.handlePayAmount();
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <Loading />
        ) : (
          <Grid container spacing={2} className='root-body'>
            <Grid item xs={12} md={6} sm={6}>
              <div className='root-payment'>
                <List>
                  <ListItem className='list-item--payment'>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={
                          this.state.checkedPaymentOption == 'Credit Card'
                        }
                        onChange={event => this.handleChange('Credit Card')}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Credit Card`} />
                    <Box component='img' src={CreditCard} className='cards' />
                  </ListItem>
                  <Divider />
                  <ListItem className='list-item--payment'>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={
                          this.state.checkedPaymentOption == 'Debit Card'
                        }
                        onChange={event => this.handleChange('Debit Card')}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Debit Card`} />
                    <Box component='img' src={DebitCard} className='cards' />
                  </ListItem>
                  <Divider />
                  {!this.state.defaultPaymentOption.hide && (
                    <>
                      <ListItem className='list-item--payment'>
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            checked={
                              this.state.checkedPaymentOption == 'Bank Transfer'
                            }
                            onChange={event =>
                              this.handleChange('Bank Transfer')
                            }
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={`Bank Transfer`} />

                        <Box component='img' src={Bank} className='cards' />
                      </ListItem>

                      <Divider />
                      <ListItem className='list-item--payment'>
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            checked={
                              this.state.checkedPaymentOption == 'UPI Transfer'
                            }
                            onChange={event =>
                              this.handleChange('UPI Transfer')
                            }
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={`UPI Transfer`} />
                        <Box
                          component='img'
                          src={UPIPayment}
                          className='cards'
                        />
                      </ListItem>
                      <Divider />
                      <ListItem className='list-item--payment'>
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            checked={
                              this.state.checkedPaymentOption == 'Cheque'
                            }
                            onChange={event => this.handleChange('Cheque')}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={`Cheque`} />
                        {this.state.chequeSelected ? (
                          <TextField
                            id='datetime-local'
                            label='cheque date'
                            type='datetime-local'
                            size='small'
                            defaultValue={moment(this.state.checkDate).format(
                              'yyyy-MM-DD[T]HH:mm'
                            )}
                            sx={{
                              width: 190,
                              height: '29px',
                              marginRight: '4rem',
                            }}
                            onChange={event => {
                              this.setState({ checkDate: event.target.value });
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        ) : null}

                        <Box component='img' src={Cheque} className='cards' />
                      </ListItem>
                    </>
                  )}
                  <Divider />
                  <ListItem className='list-item--payment'>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={this.state.checkedPaymentOption == 'Cash'}
                        onChange={event => this.handleChange('Cash')}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Cash`} />
                    <Box component='img' src={CashPayment} className='cards' />
                  </ListItem>
                  <Divider />
                  <ListItem className='list-item--payment'>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={this.state.checkedPaymentOption == 'Other'}
                        onChange={event => this.handleChange('Other')}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Other`} />
                    <Box component='img' src={CashPayment} className='cards' />
                  </ListItem>
                  <Divider />

                  <Divider />
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        sx={{
                          mt: 1,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          style={{ maxWidth: '200px' }}
                          item
                          md={4}
                          sm={6}
                          xs={12}
                        >
                          <DatePickerComp
                            value={this.state.value}
                            dateChange={this.onDateChange}
                            maxDate={new Date()}
                          />
                        </Box>
                        <Box item md={4} sm={6} xs={12}>
                          {this.props?.customerDetails?.customerName?.toUpperCase() !==
                          'ANONYMOUS' ? (
                            <Button
                              variant='contained'
                              onClick={() => {
                                this.setState({ adjusmentAmountDialog: true });
                              }}
                              sx={{
                                padding: 0,
                                textTransform: 'capitalize',
                                width: '126px',
                                height: '27px',
                              }}
                            >
                              <BuildCircleIcon sx={{ color: '#fff' }} />
                              <Typography
                                sx={{
                                  fontSize: {
                                    md: '14px',
                                    sm: '13px',
                                    xs: '10px',
                                  },
                                }}
                                noWrap
                              >
                                Adjust Amount
                              </Typography>
                            </Button>
                          ) : null}
                        </Box>
                        {Store?.companyDetails?.businessType !==
                        'MANDI' ? null : (
                          <Box item md={4} sm={6} xs={12} sx={{ ml: 1 }}>
                            <FormControl>
                              <InputLabel
                                variant='outlined'
                                htmlFor='uncontrolled-native'
                              >
                                Select Bank
                              </InputLabel>
                              <NativeSelect
                                inputProps={{
                                  name: 'selectBank',
                                  id: 'uncontrolled-native',
                                }}
                                size='small'
                                onChange={e =>
                                  this.setState({ bankId: e.target.value })
                                }
                              >
                                <option value=''></option>
                                {this.state.bankDetails?.map(item => {
                                  return (
                                    <option value={item.id}>
                                      {item?.bankName}(
                                      {item?.accountNo?.slice(-4)})
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </FormControl>
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                        {this.props?.customerDetails?.customerName?.toUpperCase() !==
                        'ANONYMOUS' ? (
                          <Typography
                            variant='h5'
                            sx={{
                              fontSize: { md: '18px', sm: '16px', xs: '12px' },
                            }}
                          >
                            <i>Total Payable Amount = &nbsp; &nbsp;</i>{' '}
                            {this.state.customerLedger[0]?.balance}
                            &nbsp;
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </Typography>
                        ) : (
                          <Typography
                            variant='h5'
                            sx={{
                              fontSize: { md: '18px', sm: '16px', xs: '12px' },
                            }}
                          >
                            {this.state.storeOrder?.paid === true ? (
                              <span>
                                &nbsp;&nbsp; Paid Amount&nbsp;&nbsp; &nbsp;
                                {this.state.storeOrder?.totalAmount}
                              </span>
                            ) : (
                              <span>
                                Total Payable Amount = &nbsp;&nbsp; &nbsp;
                                {this.state.amountPaid}
                              </span>
                            )}

                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </Typography>
                        )}
                        <br />
                      </Grid>

                      {this.state.storeOrder?.storeOrderStatus ===
                        'CONFIRMED' &&
                      this.state.storeOrder?.paid &&
                      this.props.customerDetails?.customerName?.toUpperCase() ===
                        'ANONYMOUS' ? null : (
                        <>
                          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                            <>
                              <TextField
                                className='number__input'
                                value={this.state.amountPaid}
                                disabled={
                                  this.props?.customerDetails?.customerName?.toUpperCase() ===
                                  'ANONYMOUS'
                                }
                                size='small'
                                type='number'
                                onChange={event => {
                                  this.setState({
                                    amountPaid: event.target.value,
                                  });
                                }}
                              />

                              {this.props?.customerDetails?.active ? (
                                <Button
                                  variant='contained'
                                  disabled={this.state.disable}
                                  sx={{
                                    bgcolor: '#3B78C9',
                                    ml: 2,
                                    mt: -0.5,
                                    mt: { md: 0, sm: 1, xs: 3 },
                                    textTransform: 'capitalize',
                                    width: '126px',
                                    height: '27px',
                                  }}
                                  onClick={event => {
                                    this.paymentNow();
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        md: '16px',
                                        sm: '13px',
                                        sm: '10px',
                                      },
                                    }}
                                  >
                                    {' '}
                                    Pay Now
                                  </Typography>
                                </Button>
                              ) : (
                                <Tooltip
                                  title="you cant't pay to inactive customers"
                                  placement='bottom'
                                >
                                  <Button
                                    variant='contained'
                                    sx={{
                                      bgcolor: '#3B78C9',
                                      ml: 2,
                                      mt: -0.5,

                                      backgroundColor: 'lightgray',
                                      color: 'gray',

                                      textTransform: 'capitalize',
                                      width: '126px',
                                      height: '27px',
                                    }}
                                    onClick={() => {
                                      this.props.snackbar(
                                        'You cannot  pay for inActive user',
                                        'error'
                                      );
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          md: '16px',
                                          sm: '13px',
                                          sm: '10px',
                                        },
                                      }}
                                    >
                                      {' '}
                                      Pay Now
                                    </Typography>
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          </Grid>

                          <Grid item xs={4} alignSelf='center'>
                            <Typography
                              variant='subtitle1'
                              sx={{
                                fontSize: {
                                  md: '18px',
                                  sm: '16px',
                                  xs: '11px',
                                },
                              }}
                            >
                              Enter remark :
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              value={this.state.remark}
                              multiline
                              rows={2}
                              sx={{
                                width: '100%',
                                ml: '5px',
                                mt: 1,
                                pb: 1,
                              }}
                              id='remark'
                              onChange={event =>
                                this.setState({ remark: event.target.value })
                              }
                              size='small'
                              placeholder={
                                this.state.checkedPaymentOption === 'Cheque'
                                  ? 'Please enter Cheque No'
                                  : this.state.checkedPaymentOption ===
                                      'UPI Transfer' ||
                                    this.state.checkedPaymentOption ===
                                      'Bank Transfer'
                                  ? 'Please enter transaction number'
                                  : 'Please enter remark here'
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                </List>
              </div>
            </Grid>
            {Customer?.getAnonymousCustomer()?.id !== this.props?.customerId ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ maxHeight: '600px', mt: { xs: 2 } }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Typography variant='h6' fontSize='18px' sx={{ ml: 5 }}>
                    Total Balance
                  </Typography>
                  <Typography variant='h6' fontSize='18px' sx={{ mr: 3 }}>
                    Payment Details
                  </Typography>
                </Box>
                <div className='root-ledger'>
                  {this.state.customerLedger.length > 0 ? (
                    <Timeline position='left'>
                      {this.state?.customerLedger?.map((ledger, index) => {
                        return (
                          <TimelineItem>
                            <TimelineOppositeContent
                              sx={{ m: 'auto 0', pb: 1 }}
                              // align="right"
                              variant='body2'
                              color='text.secondary'
                            >
                              <Typography
                                variant='h6'
                                sx={{
                                  fontSize: {
                                    md: '14px',
                                    sm: '14px',
                                    xs: '10px',
                                  },
                                  pl: { md: 3, sm: 3, xs: 1 },
                                }}
                              >
                                {ledger.comment ? ledger.comment : ''}&nbsp;
                                <h6
                                  style={{
                                    fontSize: '9px',
                                    fontFamily:
                                      'Roboto, Helvetica, Arial, sans-serif',
                                  }}
                                >
                                  {new Date(ledger.activityDate + 'Z')
                                    .toString()
                                    .substring(0, 15)}
                                </h6>
                                <h6
                                  style={{
                                    fontSize: '9px',
                                    fontFamily:
                                      'Roboto, Helvetica, Arial, sans-serif',
                                  }}
                                >
                                  {new Date(ledger.activityDate + 'Z')
                                    .toString()
                                    .substring(16, 25)}
                                </h6>
                              </Typography>
                              {/* </Box> */}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot
                                color='secondary'
                                align='center'
                                sx={{ fontSize: { md: '15px', xs: '8px' } }}
                              >
                                {ledger.balance !== null
                                  ? ledger.balance.toFixed(2)
                                  : ''}
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{
                                py: { md: '12px', sm: '12px', xs: '8px' },
                                px: { md: 2, sm: 2, xs: 1 },
                              }}
                            >
                              {ledger.crAmount != null &&
                              ledger.crAmount != 0 ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        md: '13px',
                                        sm: '13px',
                                        xs: '8px',
                                      },
                                      color: 'error.main',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    <span>
                                      {'Credited Amount '}
                                      <br />
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                      :{ledger.crAmount}
                                    </span>
                                    {/* {this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null} */}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        md: '13px',
                                        sm: '13px',
                                        xs: '9px',
                                      },
                                      color: '#07ce07',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    <span>
                                      {'Debited Amount '}
                                      <br />
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                      :
                                      {ledger?.drAmount !== null
                                        ? ledger.drAmount
                                        : null}
                                    </span>
                                  </Typography>
                                </>
                              )}
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  ) : null}
                </div>
              </Grid>
            ) : null}
          </Grid>
        )}

        {this.state.dialogWarning ? (
          <WarningDialog
            handleAgreeOrDisagreeClick={this.handleAgreeOrDisagreeClick}
            openDialog={this.state.dialogWarning}
            content={`You have already done the payment of same amount , Do you still want to continue ??`}
          />
        ) : null}
        <AdjustPaymentDialog
          openDialog={this.state.adjusmentAmountDialog}
          adjustAmountCallBack={this.PayAdjustedAmount}
          disableButton={this.state.disableAdjustmentButton}
          onCloseDialog={() => this.setState({ adjusmentAmountDialog: false })}
        />
      </>
    );
  }
}
export default PaymentSection;
