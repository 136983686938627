import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import './style.css';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Categories from '../../modules/Categories';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Store from '../../modules/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import Product from '../../modules/Product';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectProductITemDropdown(props) {
  //load default productitem
  React.useEffect(() => {
    props.returnSelectedItemCallback(props.dropdownData[0]);
  }, []);
  const [productItem, setProductItem] = React.useState(props.dropdownData[0]);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setProductItem(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  // const handleDeleteProductItem = product => {
  //   Product.deleteProductItem(product.itemNumber, response => {
  //     if (response.status === 'success') {
  //       openUpstoreSnackBar('Product Item Deleted Successfully', 'success');
  //       props.getProductByProductId(product.productId);
  //     } else {
  //       openUpstoreSnackBar('Product Item Not Deleted', 'error');
  //     }
  //   });
  // };

  return (
    <div>
      <FormControl
        sx={{
          width: '98%',
        }}
      >
        <Select
          // multiple'

          defaultValue={productItem || props.dropdownData[0]}
          displayEmpty
          value={productItem || props.dropdownData[0]}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (!selected || selected.length === 0) {
              return (
                <em
                  style={{
                    fontSize: '16px',
                  }}
                >
                  Select Items
                </em>
              );
            }

            return (
              <Box
                sx={{
                  height: { md: '15px' },

                  overflowY: 'hidden',

                  whiteSpace: 'nowrap',
                }}
                alignItems='center'
                display='flex'
                justifyContent='space-between'
              >
                <Tooltip title={selected?.itemName} placement='top'>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 530,
                      fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                    }}
                    sx={{
                      height: '26px',
                      overflow: 'hidden',
                      width: '40px',
                      whiteSpace: 'nowrap',
                    }}
                    primary={
                      selected?.itemName.length > 30
                        ? selected?.itemName.substring(0, 10) + ' ' + `...` ||
                          'NA'
                        : selected?.itemName
                    }
                  />
                </Tooltip>
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 530,
                    fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                  }}
                  sx={{
                    height: '26px',

                    whiteSpace: 'pre-wrap',
                    margin: '1%',
                    pl: 1,
                    display: { xs: 'block', sm: 'block' },
                  }}
                  primary={
                    Categories.getCategoryNameById(selected?.categoryId)?.name
                  }
                />
                {Store.companyDetails?.businessType !== 'STORE' && (
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 530,
                      fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                    }}
                    sx={{
                      margin: '1%',
                      pl: 1,
                      display: { xs: 'none', md: 'block' },
                    }}
                    primary={selected?.mrp || 'NA'}
                  />
                )}
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 530,
                    fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                  }}
                  sx={{
                    margin: '1%',
                    pl: 1,
                    height: '26px',
                    display: { xs: 'block', md: 'block' },
                  }}
                  primary={selected?.unit || 'NA'}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 530,
                    fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                  }}
                  sx={{
                    margin: '1%',
                    pl: 1,
                    display: { xs: 'none', md: 'block' },
                  }}
                  primary={selected?.storePrice || 'NA'}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 530,
                    fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                  }}
                  sx={{
                    margin: '1%',
                    pl: 2,
                    display: { xs: 'block', md: 'block' },
                  }}
                  primary={selected?.discount || 0}
                />
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {props.dropdownData.length === 0 ? (
            <MenuItem disabled>
              <em>No Items Available</em>
            </MenuItem>
          ) : (
            props.dropdownData.map(item => (
              <MenuItem
                // sending callback to parent with currently selected item
                onClick={() => {
                  props.returnSelectedItemCallback(item);
                }}
                key={item.itemNumber}
                value={item}
              >
                <List
                  style={{
                    width: '100%',
                    height: '75px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <ListItem>
                    <ListItemText
                      sx={{
                        fontWeight: 'bold',
                      }}
                      primary={item.itemName}
                      secondary={
                        <React.Fragment>
                          storePrice: &nbsp;{item.storePrice}&nbsp; &#8377;
                          <br />
                          description:&nbsp;{item.itemDescription}
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  {/* <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 530,
                      fontSize: { xs: '8px', md: 'medium', sm: 'medium' },
                    }}
                    sx={{
                      mr: 2,
                    }}
                    primary={
                      <DeleteIcon
                        color='warning'
                        fontSize='small'
                        onClick={() => {
                          handleDeleteProductItem(productItem);
                        }}
                      />
                    }
                  /> */}
                </List>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}
