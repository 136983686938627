import React from 'react';
import { NavLink } from 'react-router-dom';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import {
  Box,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  Divider,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import Auth from '../../modules/Auth';
import AgentNotification from '../agentNotification/AgentNotification';
import { useState } from 'react';

export default function AgentHeader() {
  const [click, setClick] = React.useState(false);
  const [logOutShow, setLogOutShow] = React.useState(false);
  const handleClick = () => setClick(!click);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [settingAnchor, setSettingAnchor] = React.useState(null);

  const open = Boolean(anchorEl);
  const open1 = Boolean(settingAnchor);

  const handleProfileIconClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateTo = useNavigate();
  const Close = () => setClick(false);
  return (
    <div>
      <div className={click ? 'main-container' : ''} onClick={() => Close()} />
      <nav className='navbar' onClick={e => e.stopPropagation()}>
        <div className='nav-container'>
          <NavLink exact to='/' className='nav-logo'>
            UPSTORE
          </NavLink>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <AgentNotification />
            </li>
          </ul>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <Tooltip title='Account' placement='bottom'>
              <AccountBoxOutlinedIcon
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
                onClick={handleProfileIconClick}
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,

                sx: {
                  backgroundColor: '#dee2e6',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  // width: '130px',
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: '#dee2e6',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Avatar /> {Auth.getUserName()}
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => {
                  Auth.logout();
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            {/* <div
              className='nav-icon'
              onMouseLeave={() => setLogOutShow(false)}
              onMouseEnter={() => setLogOutShow(true)}
              style={{ color: 'white' }}
            >
              {Auth.getUserName()}
              <PermIdentityIcon sx={{ ml: 1, color: 'blue' }} />
              {logOutShow ? (
                <div className='profileLogout'>
                  <Button
                    onClick={() => {
                      window.location.href = '/';
                      localStorage.removeItem('Auth');
                      localStorage.removeItem('storeCategoryList');
                      localStorage.removeItem('storeCustomersList');
                      localStorage.removeItem('storeCompanyDetails');
                    }}
                  >
                    LogOut
                  </Button>
                </div>
              ) : null}
            </div> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}
