import Auth from '../Auth';
import { serverConfig } from '../../config/server-config';

class Logo {
  getExpanseImageUrl = id => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/expenses/imgExpene_${id}?${timestamp}`;
  };
  getProductImages = (id, index) => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/products/${id}_${index}?${timestamp}`;
  };
  getProductItemImages = (id, index) => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/products/productItems/${id}_${index}?${timestamp}`;
  };
  getCategoryImageUrl = id => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/category/category_${id}?${timestamp}`;
  };

  getLogoUrl = id => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${serverConfig.cmsSource}store_${id}/images/logo?${timestamp}`;
    // return 'https://storage.googleapis.com/dev.upstore.cloud/store_d9ed5cb4-9802-4212-bad6-8961fd9f5a88/images/logo';
  };

  getBannerUrl = () => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/banner?${timestamp}`;
  };

  getSignatureUrl = () => {
    var timestamp = Date.now();
    // TODO Validate if the User has subscriber the load from the Google Storage
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/signature?${timestamp}`;
    // return 'https://storage.googleapis.com/dev.upstore.cloud/store_d9ed5cb4-9802-4212-bad6-8961fd9f5a88/images/logo';
  };

  // https://storage.googleapis.com/dev.upstore.cloud/store_6753db36-9b88-4141-a2af-71b48f4775e3/images/category/1a55090a-0903-4eb1-a261-7c1158ff1091.png

  getCategoryUrl = () => {
    return `${
      serverConfig.cmsSource
    }store_${Auth.getStoreId()}/images/category/1a55090a-0903-4eb1-a261-7c1158ff1091.png`;
  };
}

export default new Logo();
