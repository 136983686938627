import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Logo from '../../modules/Logo';
import { Box } from '@mui/material';
import Validation from '../../modules/Validation';
import * as moment from 'moment';
import { Button, Typography, Tooltip } from '@mui/material';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import ExportAllLedgers from '../exportAllLedgers/ExportAllLedgers';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import HTMLRenderer from 'react-html-renderer';

// for date

const ref = React.createRef();

class AllLedger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      logoUrl: null,
      openExportDialog: false,
      upstoreSnackBar: { open: false },
      storeDetails: {},
      currency: null,
    };
  }

  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };
  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  componentDidMount = () => {
    this.getLogoUrl(Auth.getStoreId());
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });
    this.getCurrency();
  };
  getLogoUrl = () => {
    this.setState({
      logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
    });
  };

  render() {
    return (
      <div className='App container'>
        <br />
        <div className='divToDownload' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100%' }}
          >
            <div
              class='row d-flex justify-content-center'
              style={{
                overflowX: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <div class='col-12'>
                <div class='card'>
                  <div class='d-flex flex-row p-2'>
                    <div class='d-flex flex-column'>
                      <div style={{ display: 'flex' }}>
                        <h1 style={{ fontSize: '20px' }}>
                          {this.props.type} Ledger
                        </h1>
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: 'flex',
                        ml: 'auto',
                      }}
                    >
                      <Tooltip title='Export To Pdf' placement='bottom'>
                        <Button
                          sx={{
                            width: { md: '126px', sm: '126px', xs: '100px' },
                            height: '27px',
                            textTransform: 'capitalize',
                            color: '#ffff',
                            backgroundColor: '#3B78C9',
                          }}
                          variant='contained'
                          onClick={() =>
                            this.setState({ openExportDialog: true })
                          }
                        >
                          <Typography
                            sx={{
                              p: 0,
                              fontSize: { md: '13px', sm: '13px', xs: '12px' },
                            }}
                          >
                            Export Ledger
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </div>

                  <hr />
                  <div class='table-responsive p-2'>
                    <table class='table table-borderless'>
                      <tbody>
                        <tr class='add'>
                          <th>
                            {' '}
                            {`${this.state.storeDetails.name}`}
                            <br />
                            {`${this.state.storeDetails.address}`}
                            <br />
                          </th>
                          <th>
                            Date From :{' '}
                            <span>
                              {moment(this.props.istDate).format('DD-MM-YYYY')}
                              &nbsp; To &nbsp;
                              {moment(this.props.lastDate).format('DD-MM-YYYY')}
                            </span>
                            <br />
                            Opening Balance :{' '}
                            <span>
                              {Validation.isBlankObjectKeyNot(
                                this.props.ledger[0],
                                'balance'
                              )
                                ? this.props.ledger[0].balance
                                : '-'}
                              &nbsp;
                              {this.state.currency ? (
                                <HTMLRenderer html={this.state.currency} />
                              ) : null}
                            </span>
                            <br />
                          </th>
                        </tr>
                        <tr class='content'>
                          <td class='font-weight-bold'></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    class='products p-2'
                    style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
                  >
                    <table class='table'>
                      <tbody>
                        <tr class='add'>
                          <th>Date</th>
                          {this.props.type == 'All' ? <th>Type</th> : null}
                          {this.props.type == 'All' ? null : <th>Name</th>}
                          <th>Particulars</th>
                          <th>Amount (Credit)</th>
                          <th class='text-center'>Payments (Debit)</th>
                        </tr>

                        {this.props.ledger !== undefined &&
                        this.props.ledger !== null
                          ? this.props.ledger.map(item => {
                              return (
                                <tr class='content' key={item.id}>
                                  <td>
                                    {item.activityDate !== null &&
                                    item.activityDate !== undefined
                                      ? new Date(item.activityDate + 'Z')
                                          .toString()
                                          .substring(0, 24) &&
                                        new Date(item.activityDate + 'Z')
                                          .toString()
                                          .substring(0, 24)
                                      : null}
                                  </td>
                                  {this.props.type == 'All' ? (
                                    <td>{item.type}</td>
                                  ) : null}
                                  {this.props.type == 'All' ? null : (
                                    <>
                                      <td>{item.ledgerName}&nbsp;</td>
                                    </>
                                  )}
                                  <td>
                                    {(item.comment && item.comment) || '-'}
                                  </td>
                                  <td>
                                    {(item.crAmount && item.crAmount) || '-'}
                                    &nbsp;
                                    {item.crAmount && this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                  <td class='text-center'>
                                    {(item.drAmount && item.drAmount) || '-'}
                                    &nbsp;
                                    {item.drAmount && this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={this.props.startIndex === 0}
              sx={{
                width: '56px',
                height: '27px',
                textTransform: 'capitalize',
                color: '#3B78C9',
              }}
              onClick={() => {
                this.props.hangePrePageNo(20);
              }}
              variant='outlined'
            >
              <Typography
                sx={{
                  p: 0,
                  fontSize: { md: '13px' },
                }}
              >
                Pre
              </Typography>
            </Button>
            <Button
              disabled={this.props.ledger.length < 20}
              sx={{
                width: '56px',
                height: '27px',
                ml: 1,
                textTransform: 'capitalize',
                color: '#3B78C9',
              }}
              onClick={() => {
                this.props.hangeNextPageNo(20);
              }}
              variant='outlined'
            >
              <Typography
                sx={{
                  p: 0,
                  fontSize: { md: '13px' },
                }}
              >
                Next
              </Typography>
            </Button>
          </Box>
        </div>
        {this.state.upstoreSnackBar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={this.state.upstoreSnackBar}
            onSnackbarClose={this.onSnackbarClose}
          />
        ) : null}
        {this.state.openExportDialog ? (
          <ExportAllLedgers
            openDialog={this.state.openExportDialog}
            closeDialog={() => this.setState({ openExportDialog: false })}
            type={this.props.type}
            snackbar={this.openUpstoreSnackBar}
            istDate={this.props.istDate}
            lastDate={this.props.lastDate}
          />
        ) : null}
      </div>
    );
  }
}
export default AllLedger;
