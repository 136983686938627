import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import LoadingIndicator from '../loader/loader';
import './snackbar.css';

function UpStoreSnackBar(props) {
  const [upstoreSnackbar, setupstoreSnackbar] = React.useState({
    open: false,
    type: '',
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setupstoreSnackbar(props.upstoreSnackBar);
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  });
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={upstoreSnackbar.open & ('loader' !== upstoreSnackbar.type)}
        autoHideDuration={3000}
        onClose={props.onSnackbarClose}
        message={upstoreSnackbar.message}
        className={upstoreSnackbar.type}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={upstoreSnackbar.open & ('loader' === upstoreSnackbar.type)}
        onClose={props.onSnackbarClose}
        className={upstoreSnackbar.type}
      >
        <LoadingIndicator />
      </Snackbar>
    </>
  );
}

export default UpStoreSnackBar;
