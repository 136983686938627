import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Typography } from '@mui/material';
import Agent from '../../modules/Agent';

import Auth from '../../modules/Auth';

export default function AgentSelection(props) {
  const [allAgents, setAllAgents] = React.useState([]);
  const [assignedAgent, setAssignedAgent] = React.useState('');
  const [assignedTrue, setAssignedTrue] = React.useState(false);
  const [orderDeliveryStatus, setOrderDeliveryStatus] = React.useState({});
  React.useEffect(() => {
    getAllAgentsByStore();
    getAgentDelivaryStatus();
  }, []);
  const getAllAgentsByStore = () => {
    Agent.getAllAgentsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setAllAgents(response.data);
      }
    });
  };

  const getAgentDelivaryStatus = () => {
    Agent.getOrderDeliveryStatus(props?.storeOrderObj?.id, response => {
      if (response.status === 'success') {
        setOrderDeliveryStatus(response.data);
      }
    });
  };
  const agentName = allAgents.find(item => {
    return item.id === orderDeliveryStatus.agentId ? item.username : '';
  });

  const assignAgenttoOrder = agent => {
    let agentAssign = {
      agentId: agent.id,
      orderId: props?.storeOrderObj?.id,
      orderStatus: 'ASSIGNED',
      orderDate: props?.storeOrderObj?.creationDate,
      storeId: Auth.getStoreId(),
      deliveryDate: props?.storeOrderObj?.estimatedDeliveryTime,
      customerId: props?.storeOrderObj?.customerId,
      dispatchDate: new Date(),
    };
    let agentAssignReturn = {
      agentId: agent.id,
      orderId: props?.storeOrderObj?.id,
      orderStatus: 'ASSIGNED',
      orderDate: props?.storeOrderObj?.creationDate,
      storeId: Auth.getStoreId(),
      deliveryDate: props?.storeOrderObj?.estimatedDeliveryTime,
      customerId: props?.storeOrderObj?.customerId,
      dispatchDate: new Date(),
      returned: true,
    };
    if (props.returnOrder) {
      assignOrderToAgent(agentAssignReturn);
    } else {
      assignOrderToAgent(agentAssign);
    }
  };
  const assignOrderToAgent = data => {
    Agent.assignAgentByAgentId(data, response => {
      if (response.status === 'success') {
        // props.snackbar('Agent is Assigned successfully', 'success');
        getAgentDelivaryStatus();
      } else {
        // props.snackbar('Error occured while assigning  Agent', 'error');
      }
    });
  };
  return (
    <Box sx={{ height: '230px' }}>
      <Grid container>
        {orderDeliveryStatus?.orderStatus !== undefined ? (
          <>
            <Grid item md={4}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Typography
                  variant='subtitle'
                  sx={{ float: 'right', mr: '2rem' }}
                >
                  Assigned to{' '}
                  <span style={{ color: 'green' }}>{agentName?.username}</span>
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item md={12}>
            <Box ssx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControl sx={{ minWidth: '200px', mt: 2, float: 'right' }}>
                <Select
                  variant='standard'
                  onChange={e => {
                    assignAgenttoOrder(e.target.value);
                    setAssignedAgent(e.target.value.firstName);
                  }}
                >
                  {allAgents.map((item, index) => {
                    return <MenuItem value={item}>{item.firstName}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
