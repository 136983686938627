import * as React from 'react';
import {
  Button,
  Tooltip,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import PaymentReceipt from '../paymentReceipt/PaymentReceipt';

export default function PaymentDialog({ openDialog, closeDialog, list, type }) {
  return (
    <>
      <Dialog open={openDialog} maxWidth='md'>
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right-start '>
              <Close
                size='medium'
                onClick={closeDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <DialogContent>
          <PaymentReceipt paymentDetails={list} Type={type} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '126px',
              height: '27px',
            }}
            variant='outlined'
            onClick={closeDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
