import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';
class Configuration {
  constructor() {
    this.defaultConfiguration =
      JSON.parse(localStorage.getItem('defaultConfiguration')) || [];
  }
  getDefaultConfigurationByBusinessType = (businessType, callBack) => {
    let defaultConfiguration = this.getStorePropertiesListFromCache();

    if (Validation.isBlankObjectNot(defaultConfiguration)) {
      callBack({ status: 'success', data: defaultConfiguration });
    } else {
      UpstoreApi({
        method: 'GET',
        url: `/business-type-configuration/getDefaultConfigurationByBusinessType/${businessType}`,
      })
        .then(response => {
          if (response.data) {
            this.setDefaultConfiguration([...response.data]);
            callBack({ status: 'success', data: response.data });
          }
        })
        .catch(error => {
          console.log(
            'Error occure while getting Configuration By Business Type',
            error.message
          );
          callBack({ status: 'error' });
        });
    }
  };

  setDefaultConfiguration = defaultConfiguration => {
    this.defaultConfiguration = [...defaultConfiguration];
    localStorage.setItem(
      'defaultConfiguration',
      JSON.stringify(defaultConfiguration)
    );
  };

  getStorePropertiesListFromCache = () => {
    if (Validation.isBlankObjectNot(this.defaultConfiguration)) {
      return [...this.defaultConfiguration];
    }

    let defaultConfigurationFromCache = localStorage.getItem(
      'defaultConfiguration'
    );

    if (Validation.isBlankObjectNot(defaultConfigurationFromCache)) {
      let defaultConfigurationList = JSON.parse(defaultConfigurationFromCache);
      this.defaultConfiguration = [...defaultConfigurationFromCache];
      return defaultConfigurationList;
    } else {
      return null;
    }
  };
  getDefaultConfigurationByBusinessTypeAndSettingNumber = (
    businessType,
    settingNumber,
    callBack
  ) => {
    let defaultConfigCache = this.getStorePropertiesListFromCache();
    let defaultConfig = {};
    if (Validation.isBlankObjectNot(defaultConfigCache)) {
      defaultConfig = defaultConfigCache?.find(
        config =>
          config?.businessType === businessType &&
          config?.defaultConfiguration?.settingNumber === settingNumber
      );

      if (Validation.isBlankObjectNot(defaultConfig)) {
        callBack({ status: 'success', data: defaultConfig });
        return defaultConfig;
      }
    }
    if (!Validation.isBlankObjectKeyNot(defaultConfig)) {
      UpstoreApi({
        method: 'GET',
        url: `/business-type-configuration/getBy/${businessType}/and/${settingNumber}`,
      })
        .then(response => {
          if (response.data) {
            callBack({ status: 'success', data: response.data });
          }
        })
        .catch(error => {
          console.log(
            `Error occure while getting  Configuration by ${businessType} and ${settingNumber} `,
            error.message
          );
          callBack({ status: 'error' });
        });
    }
  };
  getDistinctType = callBack => {
    UpstoreApi({
      method: 'GET',
      url: `/default-configuration/getDistinctDefaultConfigurationTypes`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting  distinct default Configuration ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  addDefaultConfigiuration = (configData, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/default-configuration/add',
      data: configData,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding Config', error.message);
        callBack({ status: 'error' });
      });
  };
  updateConfig = (configData, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/business-type-configuration/update-business-type-configuration/${id}`,
      data: configData,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating Configuration', error.message);
        callBack({ status: 'error' });
      });
  };
  getDefaultConfigurationByBusinessTypeForMonitoringTool = (
    businessType,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/business-type-configuration/getDefaultConfigurationByBusinessType/${businessType}`,
    })
      .then(response => {
        if (response.data) {
          this.setDefaultConfiguration(response.data);
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting Configuration By Business Type',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}
export default new Configuration();
