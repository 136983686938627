import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import './style.css';
import Notifications from '../../modules/Notification';
import Auth from '../../modules/Auth';
import HTMLRenderer from 'react-html-renderer';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
export default function PullNotification() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0); // integer state
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  let newMessages = 0;

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    Notifications.pullNotifications(Auth.getStoreId(), pageNo, response => {
      if (response.status === 'success') {
        if (pageNo > 0) {
          setLoading(false);
          if (response.data?.length <= 0) {
            setAllDataLoaded(true);
          } else {
            setData([...data, ...response.data]);
          }
        } else {
          setData(response.data);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeToRead = item => {
    item.status = 'READ';

    setValue(value + 1);
    Notifications.changeNotificationStatus(item);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleScroll = e => {
    let element = e.target;
    if (
      Math.abs(
        element.scrollHeight - (element.scrollTop + element.clientHeight) <= 1
      ) &&
      allDataLoaded === false
    ) {
      let pg = pageNo + 1;
      setPageNo(pg);
      setLoading(true);
      setTimeout(() => {
        getNotifications();
      }, 100);
    }
  };

  const renderItems = data.map(item => {
    const { message, id, status } = item;
    if (status === 'NEW') {
      ++newMessages;
    }
    return (
      <div
        className={item.status === 'NEW' ? 'notification' : 'notification-read'}
        key={id}
      >
        <div onClick={() => changeToRead(item)}>
          <Typography
            variant='subtitle1'
            gutterBottom
            sx={{
              fontSize: '13px',
              fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
              margin: '2px',
              backgroundColor: '#f3f5f7',
            }}
            color={item.status === 'NEW' ? 'primary' : ''}
          >
            <HTMLRenderer html={message} />
          </Typography>
        </div>
      </div>
    );
  });

  return (
    <>
      <Badge
        badgeContent={newMessages > 9 ? `${newMessages}+` : newMessages}
        color='primary'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NotificationsIcon
          aria-describedby={id}
          variant='contained'
          color='primary'
          onClick={handleClick}
          style={{ cursor: 'pointer', marginRight: '5px' }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className='notification__container'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <div onScroll={handleScroll} className='notification__body'>
            <Typography
              variant='h6'
              style={{
                borderBottom: '2px #CCCCCC solid',
                margin: '5px',
                textAlign: 'center',
              }}
            >
              Notifications
            </Typography>

            <div component='main' maxWidth='md'>
              {data.length != 0 ? (
                renderItems
              ) : (
                <Typography
                  variant='h5'
                  style={{ margin: '5px', textAlign: 'center' }}
                >
                  No New Notifications
                </Typography>
              )}
            </div>
            {loading ? (
              <Box sx={{ width: '100%' }}>
                <Skeleton />
                <Skeleton animation='wave' />
                <Skeleton animation={false} />
              </Box>
            ) : null}
          </div>
        </Popover>
      </Badge>
    </>
  );
}
