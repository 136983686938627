import React from 'react';
import Card from '@mui/material/Card';
import Logo from '../../modules/Logo';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DAFAUT_CAT_IMG from '../../assets/images/item.jpg';
import { Typography, IconButton } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDialog from '../deleteDialog/DeleteDialog';
import Categories from '../../modules/Categories';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import './style.css';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { serverConfig } from '../../config/server-config';
import Auth from '../../modules/Auth';
import Store from '../../modules/Store';
import AllPermissions from '../../modules/AllPermissions';

function Catagory(props) {
  let navigate = useNavigate();

  const [categoryUrl, setCategoryUrl] = React.useState();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [catImage, setCatImg] = React.useState('');

  React.useEffect(() => {
    getCategoryUrl();
  }, []);

  // function for handling both delete icon click function and deletete dialog callback also
  const handleDeleteCategory = param => {
    // param from deletecallback from delete dialog
    setOpenDeleteDialog(true);

    if (param) {
      let id = props.categoryObj.categoryId;
      Categories.deleteCategory(id, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('Category Delete Successfully', 'success');
          setTimeout(() => {
            props.refreshCategoriesList(false);
            props.getAllProductsList();
            setOpenDeleteDialog(false);
          }, 2000);
        } else {
          openUpstoreSnackBar(
            'Unable to delete. Category contain products',
            'error'
          );
          setOpenDeleteDialog(false);
          props.refreshCategoriesList(false);
          props.getAllProductsList();
        }
      });
    }
  };

  const upLoadCategoryImage = (id, catImage) => {
    if (catImage.files) {
      if (catImage.files.length !== 0) {
        uploadImagesExp(catImage.files, id);
      }
    }
  };

  const uploadImagesExp = (images, id) => {
    Array.from(images).map((imageFile, index) => {
      if (index < 3) {
        handleImageCompressionExp(imageFile, index, id);
      } else {
        return 'error';
      }
    });
  };

  const handleImageCompressionExp = async (imageFile, index, id) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await uploadToServerExp(compressedFile, index);
      } catch (error) {}
    } else {
      await uploadToServerExp(imageFile, index, id);
    }
  };
  const uploadToServerExp = (imageFile, index, id) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB

    if (imageFile.size <= maxFileSize) {
      const formData = new FormData();
      const imageName = `category/category_${id}`;
      formData.append('image', imageFile);
      formData.append('name', imageName);
      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          props.snackbar('Category  Image Uploaded successfully', 'success');
        } else {
          console.log('error');
        }
      });
    }
  };

  const getCategoryUrl = () => {
    setCategoryUrl(Logo.getCategoryUrl());
  };

  const actions = [
    {
      icon: (
        <EditIcon
          color='primary'
          fontSize='small'
          onClick={() => props.editDialogCallback(props.categoryObj)}
        />
      ),
      name: 'Edit',
    },

    {
      icon: (
        <DeleteIcon
          color='primary'
          fontSize='small'
          onClick={() => {
            props.categoryObj.defaultCat
              ? openUpstoreSnackBar(
                  'Default Category cant be deleted ',
                  'error'
                )
              : handleDeleteCategory();
          }}
        />
      ),
      name: 'Delete',
    },
    {
      icon: (
        <>
          <input
            key={props}
            accept='image/*'
            id={`icon-button-file${props.categoryObj.categoryId}`}
            type='file'
            style={{ display: 'none' }}
            onChange={e => {
              upLoadCategoryImage(props.categoryObj.categoryId, e.target);
            }}
          />
          <label htmlFor={`icon-button-file${props.categoryObj.categoryId}`}>
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
            >
              <ImageIcon
                sx={{
                  fontSize: '20px',
                }}
              />
            </IconButton>
          </label>
        </>
      ),
      name: 'Upload Image',
    },
  ];

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const closePopup = () => {
    setOpenDeleteDialog(false);
  };

  const addDefaultSrc = ev => {
    let DEFAULT_CAT_IMG_CLOUD = `${
      serverConfig.cmsSource
    }categories/${props.categoryObj.name.toLowerCase()}.png`;
    // If Default Category set already then use static default category Image
    if (ev.target.src == encodeURI(DEFAULT_CAT_IMG_CLOUD)) {
      ev.target.src = DAFAUT_CAT_IMG;
    } else {
      ev.target.src = DEFAULT_CAT_IMG_CLOUD;
    }
  };

  return (
    <>
      <Card
        key={props.categoryObj.categoryId}
        className={
          props.categoryObj.categoryId === props.selectedCategoryId
            ? `selected-category`
            : null
        }
        sx={{
          display: 'inline-block',
          borderRadius: '20px',
          transform: 'translateZ(0px)',
          width: '220px',
          mx: 2,
          my: 1,
          position: 'relative',
        }}
      >
        <CardMedia
          component='img'
          key={props.categoryObj.id}
          image={Logo.getCategoryImageUrl(props.categoryObj.categoryId)}
          alt='Category'
          onError={addDefaultSrc}
          onClick={e => {
            setTimeout(() => {
              props.handleCategoryClickedCallback(
                props.categoryObj.categoryId,
                props.categoryObj.name
              );
            }, 500);
          }}
          onDoubleClick={e =>
            navigate('/products', { state: props.categoryObj })
          }
          style={{ height: '160px', objectFit: 'contain' }}
          sx={{ cursor: 'pointer' }}
        />

        <CardContent
          sx={{
            backgroundColor: '#d0d5e3',
          }}
        >
          <Typography
            variant='h6'
            fontSize='medium'
            fontWeight='500'
            onClick={e =>
              setTimeout(() => {
                props.handleCategoryClickedCallback(
                  props.categoryObj.categoryId,
                  props.categoryObj.name
                );
              }, 300)
            }
            onDoubleClick={e =>
              navigate('/products', { state: props.categoryObj })
            }
            sx={{ cursor: 'pointer' }}
          >
            {props.categoryObj.name}
          </Typography>

          {AllPermissions.getUserAccessiblePermissions('Category') !==
            undefined &&
          AllPermissions.getUserAccessiblePermissions('Category').write ===
            false ? null : (
            <SpeedDial
              ariaLabel='SpeedDial openIcon example'
              sx={{
                position: 'absolute',
                bottom: 16,
                right: -4,
              }}
              color='red'
              FabProps={{ size: 'small' }}
              icon={<MoreVertIcon fontSize='small' />}
            >
              {actions.map(action => {
                return (
                  <SpeedDialAction
                    sx={{ mb: '2px' }}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                  />
                );
              })}
            </SpeedDial>
          )}
        </CardContent>
      </Card>
      {openDeleteDialog ? (
        <DeleteDialog
          openDialog={openDeleteDialog}
          closeDialogCallback={closePopup}
          deleteCallback={handleDeleteCategory}
          dialogContent='Are you sure you want to delete this category ?'
        />
      ) : null}
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
}

export default Catagory;
