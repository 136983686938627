class Validation {
  isBlankNot(value) {
    if (value !== undefined && value !== null && value.trim() !== '') {
      return true;
    } else {
      return false;
    }
  }

  isBlankObjectNot(value) {
    if (
      value !== undefined &&
      value !== null &&
      Object.keys(value).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  isBlankObjectKeyNot(obj, key) {
    if (
      obj !== null &&
      obj != undefined &&
      obj[key] !== undefined &&
      obj[key] !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  getGstSumOrVatSum(obj) {
    if (obj !== null && obj != undefined) {
      if (Object.keys(obj).length > 0 && Object.keys(obj).includes('vat')) {
        return obj?.vat || 0;
      } else if (
        Object.keys(obj).length > 0 &&
        Object.keys(obj).includes('gst')
      ) {
        return obj?.gst || 0;
      }
    } else {
      return null;
    }
  }

  checkMinLength(value, length) {
    if (this.isBlankNot(value) && value.trim().length >= length) {
      return true;
    } else {
      return false;
    }
  }

  checkMaxLength(value, length) {
    if (this.isBlankNot(value) && value.trim().length <= length) {
      return true;
    } else {
      return false;
    }
  }

  validatePassword(password) {
    var pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  validateUsername(username) {
    let isUsernameValid = this.isBlankNot(username);
    if (isUsernameValid) {
      var pattern = new RegExp(/^[a-zA-Z0-9_@]{6,}$/);
      if (pattern.test(username)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validateAlphabetsOnly(value) {
    if (this.isBlankNot(value)) {
      var pattern = new RegExp(/^[a-zA-Z ]+$/);
      if (!pattern.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  //For Email
  validateEmail(email) {
    let isEmailValid = this.isBlankNot(email);
    if (isEmailValid) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (pattern.test(email)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //For Pincode
  validatePin(pin) {
    if (this.isBlankNot(pin) && pin.trim().length === 6) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(pin)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  // Validate Address
  validateAddress(address) {
    let isAddressValid = this.isBlankNot(address);
    if (isAddressValid) {
      var pattern = new RegExp(
        // /(\d{1,}) [a-zA-Z0-9\s]+(\.)? [a-zA-Z]+(\,)? [A-Z]{2} [0-9]{5,6}/
        /^[#.0-9a-zA-Z\s,-]+$/
      );
      if (pattern.test(address)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //For Phone Number
  validatePhone(phone) {
    phone = phone.replace('+91', '');
    if (this.isBlankNot(phone) && phone.trim().length === 10) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phone)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  validatePrice(value) {
    if (this.isBlankNot(value)) {
      var pattern = new RegExp(/^[0-9]+([.][0-9]+)?$/);
      if (!pattern.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
export default new Validation();
