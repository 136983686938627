class DateConveter {
  convertDate = (date, callBack) => {
    let abc;
    if (date.toISOString !== undefined) {
      abc = date?.toISOString();
    } else {
      abc = date;
    }
    let def = abc.substring(abc.indexOf('T'), -1);
    var today = new Date().toISOString();
    let ghi = today.substring(today.indexOf('T'));
    let iD = def + ghi;

    callBack({ data: iD });
  };
}
export default new DateConveter();
