import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { Grid, Link } from '@mui/material';
import Header from '../../component/header/Header';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Order from '../../modules/Order';
import Auth from '../../modules/Auth';
import Customer from '../../modules/Customer';
import * as moment from 'moment';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import { Loading } from '../../component/loading/Loading';
import ReturnOrder from '../../modules/ReturnOrder';

import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import { useNavigate } from 'react-router-dom';

const EstoreOrders = () => {
  const navigateTo = useNavigate();
  const [ordersList, setOrdersList] = React.useState([]);
  const [returnOrderlist, setReturnOrderList] = React.useState([]);
  const [currency, setCurrency] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [createEstoreOrder, setCreateEstoreOrder] = React.useState(false);
  const [updateOrderdetails, setUpdateOrderDetails] = React.useState({});
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    AllOrders();
    getReturnedOrders();
    getCurrency();
  }, []);

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const AllOrders = () => {
    let deleted = false;
    let pageNum = 0;
    let orderFromEstore = true;
    setLoader(true);

    Order.getAllOrdersByStoreId(
      Auth.getStoreId(),
      pageNum,
      deleted,
      orderFromEstore,
      response => {
        if (response.status === 'success') {
          let orderListItem = response.data;

          orderListItem.forEach(order => {
            if (order.invoiceDate != null && order.dueDate != null) {
              order.invoiceDate = new Date(order.invoiceDate + 'Z');
              order.dueDate = new Date(order.dueDate + 'Z');
            }
          });
          let sortedOrderList = orderListItem.sort(function (a, b) {
            return b.storeOrderNumber - a.storeOrderNumber;
          });
          setLoader(false);
          setOrdersList(sortedOrderList);
        } else {
          setOrdersList([]);
          setLoader(false);
        }
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getReturnedOrders = () => {
    let pageNum = 0;
    let deleted = false;
    let orderFromEstore = true;

    ReturnOrder.getReturnOrdersByStore(
      Auth.getStoreId(),
      pageNum,
      deleted,
      orderFromEstore,
      response => {
        if (response.status === 'success') {
          let sortedSameAsOrder = response.data.map(item => {
            return { ...item, orderStatus: item.returnOrderStatus };
          });
          setReturnOrderList(sortedSameAsOrder);
        } else {
          setReturnOrderList([]);
        }
      }
    );
  };
  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };
  const CreateOrUpdateEstore = orderDetails => {
    setUpdateOrderDetails(orderDetails);
    setTimeout(() => {
      setCreateEstoreOrder(true);
    }, 10);
  };
  const navigateToConfirmOrder = (params, stat) => {
    if (stat === 'confirm') {
      navigateTo('/returnOrder', {
        state: { params, return: false, estoreOrder: true },
      });
    } else {
      navigateTo('/returnOrder', { state: { params, return: true } });
    }
  };
  const columns = [
    {
      field: 'storeOrderNumber',
      headerName: 'Order No',
      width: 150,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <Typography
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
            onClick={event => {
              if (value === 2) {
                navigateToConfirmOrder(params.row, 'return');
              } else {
                navigateToConfirmOrder(params.row, 'confirm');
              }
            }}
          >
            {value === 2
              ? params.row.returnOrderNumber
              : params.row.storeOrderNumber}
          </Typography>
        );
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 230,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',

      type: 'string',
      align: 'left',
      renderCell: params => {
        return (
          <Typography disabled>
            {Customer.getCustomerById(params.row.customerId, () => {}) &&
              Customer.getCustomerById(params.row.customerId, () => {})
                .customerName}
          </Typography>
        );
      },
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      width: 190,
      type: 'date',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.invoiceDate === null
          ? 'N / A'
          : moment.utc(params.row.invoiceDate).local().format('DD-MM-yyyy'),
    },

    {
      field: 'totalAmount',
      headerName: 'Amount',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalAmount}{' '}
            {currency ? <HTMLRenderer html={currency} /> : null}
          </Typography>
        </>
      ),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 190,
      type: 'date',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.dueDate === null
          ? 'N / A'
          : moment.utc(params.row.dueDate).local().format('DD-MM-yyyy'),
    },
    {
      field: 'orderStatus',
      headerName: 'Order Status',
      width: 190,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          {params.row.orderStatus === 'ASSIGNED' ? (
            <Typography
              variant='h6'
              sx={{
                color: 'green',
                fontSize: '15px',
              }}
            >
              {params.row.orderStatus}
            </Typography>
          ) : params.row.orderStatus === 'DISPATCHED' ? (
            <Typography
              variant='h6'
              sx={{
                color: 'red',
                fontSize: '15px',
              }}
            >
              {params.row.orderStatus}
            </Typography>
          ) : params.row.orderStatus === 'ARRIVING' ? (
            <Typography
              variant='h6'
              sx={{
                color: 'brown',
                fontSize: '15px',
              }}
            >
              {params.row.orderStatus}
            </Typography>
          ) : (
            <Typography
              variant='h6'
              sx={{
                color: 'blue',
                fontSize: '15px',
              }}
            >
              {params.row.orderStatus}
            </Typography>
          )}
        </>
      ),
      // },
    },
  ];

  return (
    <>
      <Grid sx={{ mt: 7, ml: 7.3 }}>
        <Header />
        <Grid item md={6} sm={7} xs={8} sx={{ ml: 1 }}>
          <BreadCrumb pageName='Estore Orders' />
        </Grid>
        {loader ? (
          <Loading />
        ) : (
          <>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                aria-label='Estore Order tabs '
              >
                <Tab label='In Progress Orders' {...a11yProps(0)} />
                <Tab label='Completed Orders' {...a11yProps(1)} />
                <Tab label='Returned Orders' {...a11yProps(2)} />
              </Tabs>
            </Box>

            <EstoreOrderTabs
              data={ordersList}
              returnedOrders={returnOrderlist}
              columns={columns}
              value={value}
            />
          </>
        )}
      </Grid>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `EstoreOrder-width-tab-${index}`,
      'aria-controls': `EstoreOrder-width-tabpanel-${index}`,
    };
  }

  function EstoreOrderTabs({ data, returnedOrders, columns, value }) {
    const theme = useTheme();

    let completedOrders = data.filter(item => {
      return item.orderStatus === 'COMPLETED';
    });
    let inProgressOrders = data.filter(item => {
      return (
        item.orderStatus === 'New' ||
        item.orderStatus === 'ASSIGNED' ||
        item.orderStatus === 'DISPATCHED' ||
        item.orderStatus === 'ARRIVING'
      );
    });

    const handleChangeIndex = index => {
      setValue(index);
    };

    return (
      <Box sx={{ mt: 1, width: '100%' }}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              sx={{
                width: '100%',
                height: 600,

                '& .super-app-theme--header': {
                  backgroundColor: '#EFFAFF',

                  fontSize: '18px',
                },
              }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                }}
                density='compact'
                rows={inProgressOrders}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20, 40, 60]}
                aria-label=' Inprogress orderlist'
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box
              sx={{
                width: '100%',
                height: 600,

                '& .super-app-theme--header': {
                  backgroundColor: '#EFFAFF',

                  fontSize: '18px',
                },
              }}
            >
              <DataGrid
                id='completedOrders'
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                }}
                density='compact'
                rows={completedOrders}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20, 40, 60]}
                aria-label='completedOrders'
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Box
              sx={{
                width: '100%',
                height: 600,

                '& .super-app-theme--header': {
                  backgroundColor: '#EFFAFF',

                  fontSize: '18px',
                },
              }}
            >
              <DataGrid
                id={'returnedOrders'}
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                }}
                density='compact'
                rows={returnedOrders}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20, 40, 60]}
                aria-label='returnedOrders'
              />
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Box>
    );
  }
};
export default EstoreOrders;
