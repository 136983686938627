import Auth from '../Auth';
import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';

class Vendor {
  constructor() {
    this.storeVendors =
      JSON.parse(localStorage.getItem('storeVendorList')) || {};
  }
  // setStoreVendors = vendorList => {
  //   this.storeVendors = [...vendorList];
  //   localStorage.setItem('storeVendorList', JSON.stringify(vendorList));
  // };
  // reloadVendors = (storeId, callBack) => {
  //   this.storeVendors = null;
  //   localStorage.removeItem('storeVendorList');
  //   this.getVendorByVendorId(storeId, callBack);
  // };
  getVendorByVendorId = (vendorId, callBack) => {
    // let vendorFound = {};
    // let storeVendorsFromCache = this.getStoreVendorsListFromCache();
    // if (Validation.isBlankObjectNot(storeVendorsFromCache)) {
    //   let vendorFound = storeVendorsFromCache?.find(
    //     item => item?.id === vendorId
    //   );
    //   if (Validation.isBlankObjectNot(vendorFound)) {
    //     callBack({ status: 'success', data: vendorFound });
    //     return vendorFound;
    //   }
    // }
    // if (!Validation.isBlankObjectKeyNot(vendorFound)) {
    UpstoreApi({
      method: 'GET',
      url: '/vendor/' + vendorId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting vendor by vendorId',
          error.message
        );
        callBack({ status: 'error' });
      });
    // }
  };
  // getStoreVendorsListFromCache = () => {
  //   if (Validation.isBlankObjectNot(this.storeVendors)) {
  //     return [...this.storeVendors];
  //   }

  //   let storeVendorsFromCache = localStorage.getItem('storeVendorList');
  //   if (Validation.isBlankObjectNot(storeVendorsFromCache)) {
  //     let storeVendorsList = JSON.parse(storeVendorsFromCache);
  //     this.storeVendors = [...storeVendorsList];
  //     return storeVendorsList;
  //   } else {
  //     return null;
  //   }
  // };

  saveNewVendor = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/vendor',
      data: data,
    })
      .then(response => {
        if (response.data) {
          // this.updateVendorsLocalStorageData(response.data, 'add');
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding vendor', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };
  //get vendor by vendorId

  //get All Vendors
  getAllVendorByStoreId = (
    storeId,
    pageNumber,
    calledAtAllVendorPage,
    callBack
  ) => {
    // let storeVendorsFromCache = this.getStoreVendorsListFromCache();
    // if (
    //   Validation.isBlankObjectNot(storeVendorsFromCache) &&
    //   !calledAtAllVendorPage
    // ) {
    //   callBack({ status: 'success', data: storeVendorsFromCache });
    // } else {
    UpstoreApi({
      method: 'GET',
      url: `/vendor/getVendorByStoreId/${storeId}?pageNumber=${pageNumber}`,
    })
      .then(response => {
        if (response.data) {
          // this.setStoreVendors([...response.data]);
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting  all vendors by storeId',
          error.message
        );
        callBack({ status: 'error' });
      });
    // }
  };

  updateVendor = (vendorId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/vendor/update/' + vendorId,
      data: data,
    })
      .then(response => {
        // this.updateVendorsLocalStorageData(response.data, 'update');
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Error occured while updating vendor', error.message);
        callBack({ status: 'error' });
      });
  };

  searchVendorByStoreId = (storeId, searchVal, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/vendor/search-vendor/${storeId}?search=${searchVal}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while searching   Vendors ', error.message);
        callBack({ status: 'error' });
      });
  };

  addPurchaseOrder = (order, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/purchaseOrder/addPurchaseOrder',
      data: order,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while saving order', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  getVendor = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/vendor/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while get vendor', error.message);
        callBack({ status: 'error' });
      });
  };
  resetVendorById = (vendorId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/vendor/' + vendorId,
    })
      .then(response => {
        if (response.data) {
          let vendorList = [
            ...JSON.parse(localStorage.getItem('storeVendorList')),
          ];

          let filteredArray = vendorList?.filter(item => item?.id !== vendorId);

          this.setStoreVendors([...filteredArray, response.data]);
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting vendor by vendor id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateVendorsLocalStorageData = (data, calledAt) => {
    let prevData = JSON.parse(localStorage.getItem('storeVendorList'));
    if (calledAt === 'add') {
      let newData = [...prevData, data];
      this.setStoreVendors(newData);
    } else if (calledAt === 'update') {
      prevData = prevData?.map(item => (item.id !== data.id ? item : data));
      this.setStoreVendors([...prevData]);
    } else {
      console.log('something went wrong');
    }
  };

  deleteVendor = (id, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `vendor/delete/${id}`,
    })
      .then(response => {
        if (response.data) {
          // this.updateVendorsLocalStorageData(response.data, 'update');
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting vendor', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };
}
export default new Vendor();
