import * as React from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';

// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Ledger from '../../modules/Ledger';
import Auth from '../../modules/Auth';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as moment from 'moment';
import { serverConfig } from '../../config/server-config';
import Store from '../../modules/Store';

export default function ExportAllLedgers({
  openDialog,
  closeDialog,
  type,
  snackbar,
  istDate,
  lastDate,
}) {
  const [value, setValue] = React.useState(new Date(istDate));
  const [value1, setValue1] = React.useState(new Date(lastDate));
  const [Export, setExport] = React.useState({});

  const getExportData = () => {
    let startDate = moment(value).format('YYYY-MM-DD');
    let endDate = moment(value1).format('YYYY-MM-DD');
    Ledger.getAllExportLedger(
      Auth.getStoreId(),
      startDate,
      endDate,
      type,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setExport(response.data);
          let ledgerFileUrl = `${
            serverConfig.cmsSource
          }store_${Auth.getStoreId()}/ledgers/${response.data}`;
          window.open(ledgerFileUrl, '_blank');
          closeDialog();
          snackbar('statement is generated successfully', 'success');
        } else {
          console.log('error');
        }
      }
    );
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} maxWidth='sm' fullWidth>
        <DialogTitle textAlign='center'>Export</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='start Date'
                value={value}
                inputFormat='dd/MM/yyyy'
                onChange={newValue => {
                  setValue(newValue);
                }}
                renderInput={params => (
                  <TextField
                    sx={{ width: '140px', height: '40px', mr: 2 }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                label='End Date'
                value={value1}
                onChange={newValue => {
                  setValue1(newValue);
                }}
                inputFormat='dd/MM/yyyy'
                renderInput={params => (
                  <TextField
                    sx={{ width: '140px', height: '60px' }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant='contained'
              sx={{
                ml: 1,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                mt: 2,
              }}
              onClick={getExportData}
            >
              <DownloadIcon sx={{ color: '#fff' }} />
              <Typography
                sx={{ fontSize: '15px', textTransform: 'capitalize' }}
              >
                Download
              </Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: 'capitalize',
              width: '127px',
              width: '126px',
              height: '27px',
            }}
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
