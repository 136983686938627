import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Header from '../../component/header/Header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MandiChalanItems from '../../component/mandiChalanItems/MandiChalanItems';
import { ArrowLeft, Close, PeopleAlt } from '@mui/icons-material';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Vendor from '../../modules/Vendor';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import PurchaseOrders from '../../modules/PurchaseOrders';
import DatePickerComp from '../../component/datePicker/DatePicker';
import dateConveter from '../../modules/DateConveter/dateConveter';
import MandiChalan from '../../modules/MandiChalan';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverConfig } from '../../config/server-config';
import EditIcon from '@mui/icons-material/Edit';
import WarningDialog from '../../component/warningDialog/WarningDialog';
import DownloadIcon from '@mui/icons-material/Download';
import { Box } from '@mui/system';
import MandiWatakInvoice from '../../component/mandiWatakInvoice/MandiWatakInvoice';
import { Link } from 'react-router-dom';
import { Loading } from '../../component/loading/Loading';

function WatakDetails() {
  const { state } = useLocation();
  let navigate = useNavigate();

  const [groverFreightDetails, setGroverFreightDetails] = React.useState([]);
  const [selectedProductItemList, setSelectedProductItemList] = React.useState(
    state?.watak?.subWatak || []
  );
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const addAccordion = state?.watak?.subWatak;
  const [vendorDataList, setVendorDataList] = React.useState([]);
  const [vendorInfo, setVendorInfo] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [mandiChalanObj, setMandiChalanObj] = React.useState(
    state?.watak || {}
  );
  const [updateChalanDetails, setUpdateChalanDetails] = React.useState(
    state?.watak
  );
  const [packValue, setPackValue] = React.useState([]);
  const [chalanCreationDate, setChalanCreationDate] = React.useState(
    state?.watak?.challanDate ? state?.watak?.challanDate : new Date()
  );
  const [watakDate, setWatakDate] = React.useState(
    state.watak.watakDate ? state.watak.watakDate : new Date()
  );
  const [arrivalDate, setArrivaldate] = React.useState(
    state.watak.arrivalDate ? state.watak.arrivalDate : new Date()
  );
  const [challanNumber, setChallanNumber] = React.useState(
    state?.watak?.challanNumber || ''
  );
  const [confirmMandiChalan, setConfirmMandiChalan] = React.useState(
    state?.watak.status === 'CONFIRMED' ? true : false
  );
  const [dialogWarning, setDialogWarning] = React.useState(false);
  const [submittedButton, setSubmittedButton] = React.useState(
    state?.watak?.status === 'CONFIRMED' ? true : false
  );
  const [orderInvoice, setOrderInvoice] = React.useState({ open: false });
  const [varietyDetails, setVarietyDetails] = React.useState({
    packingData: [],
    qualityData: [],
    varietyData: [],
  });
  const [loader, setLoader] = React.useState(true);

  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };

  React.useEffect(() => {
    getVendorByVendorId();
    getAllVendors();
    getAllVariatiesByType('packing');
    getAllVariatiesByType('quality');
    getAllVariatiesByType('variety');
    freighDetailsByChallanId();
  }, []);

  const getVendorByVendorId = () => {
    Vendor.getVendorByVendorId(mandiChalanObj.vendorId, response => {
      if (response.status === 'success') {
        setLoader(false);
        setVendorInfo(response.data);
      } else {
        setVendorInfo({});
      }
    });
  };

  const columns = [
    { id: 'itemName', label: 'Item Name', maxWidth: 150, align: 'left' },
    { id: 'packing', label: 'Packing', maxWidth: 100, align: 'left' },
    { id: 'quality', label: 'Quality', maxWidth: 100, align: 'left' },
    { id: 'variety', label: 'Variety', maxWidth: 100, align: 'left' },

    {
      id: 'chalanQuantity',
      label: 'Chalan Qty',
      maxWidth: 200,
      align: 'center',
    },

    {
      id: 'actualQuantity',
      label: 'Actual Qty',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'chalanWeight',
      label: 'Chalan wt',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'actualWeight',
      label: 'Actual Wt',
      maxWidth: 100,
      align: 'left',
    },

    {
      id: 'labour',
      label: 'labour',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'freight',
      label: 'Freight',
      maxWidth: 200,
      align: 'left',
    },

    {
      id: 'selfAmount',
      label: `Self Amount`,
      maxWidth: 150,
      align: 'left',
    },
    {
      id: 'commission',
      label: 'Comm(%)',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'basePrice',
      label: 'Rate',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'amount',
      label: 'amount',
      maxWidth: 100,
      align: 'left',
    },
  ];
  const freightColumns = [
    { id: 'packing', label: 'Packing', maxWidth: 150, align: 'left' },

    { id: 'freight', label: 'Freight', maxWidth: 150, align: 'left' },
    {
      id: 'amount',
      label: 'Self Amount',
      maxWidth: 150,
      align: 'left',
    },
    {
      id: 'labour',
      label: 'Labour',
      maxWidth: 150,
      align: 'left',
    },
  ];

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const addChalanDetailsDataCallBack = (selectedItem, name) => {
    let newRow = selectedProductItemList?.map(row => {
      if (row.subGrowerName === name) {
        const result = row?.watakDetails?.findIndex(
          item => item.itemNumber == selectedItem.itemNumber
        );
        row.watakDetails[result] = selectedItem;
      }
      let calculatedValues = PurchaseOrders.calculateSubgroverChalanDetails(
        row.watakDetails
      );
      let newRow = {
        ...row,
        ...calculatedValues,
        freight: calculatedValues.subGrowerFreight,
      };
      let calculatedVal = addSubgrowerDetails(newRow);
      return calculatedVal;
    });

    let totalCalculatedValues = PurchaseOrders.calculateTotalValues(newRow);
    setSelectedProductItemList(newRow);
    setMandiChalanObj({
      ...mandiChalanObj,
      totalFreight: totalCalculatedValues.totalFreight,
      totalSelfAmount: totalCalculatedValues.totalSelfAmount,
      totalQuantity: totalCalculatedValues.totalQuantity,
      totalActualQuantity: totalCalculatedValues.totalActualQuantity,
      totalActualWeight: totalCalculatedValues.totalActualWeight,
      totalWeight: totalCalculatedValues.totalWeight,
      grandTotal: totalCalculatedValues.grandTotal,
      grower: vendorInfo.name,
      vendorId: vendorInfo.id,
      freightDetails: groverFreightDetails,
      subWatak: newRow,
      challanNumber: challanNumber,
    });
  };

  const getAllVendors = () => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), 0, false, response => {
      if (response.status === 'success') {
        let filteredVendor = response.data.filter(item => {
          return item.active;
        });
        setVendorDataList(filteredVendor);
      }
    });
  };
  const handleSelectVandor = vendor => {
    setVendorInfo(vendor);
  };
  const handleGroverAccChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //for Adding Packing
  const getAllVariatiesByType = type => {
    PurchaseOrders.getAllVariatiyByType(Auth.getStoreId(), type, response => {
      if (response.status === 'success') {
        //converting  array of stings to array of objects
        let myArrayQuality = response.data.stringArrayData.map(
          (str, index) => ({
            value: str,
            id: index + 1,
          })
        );
        if (response.data.type === 'packing') {
          setVarietyDetails({ ...varietyDetails, packingData: myArrayQuality });
        }
        if (response.data.type === 'quality') {
          setVarietyDetails({ ...varietyDetails, qualityData: myArrayQuality });
        }
        if (response.data.type === 'variety') {
          setVarietyDetails({ ...varietyDetails, varietyData: myArrayQuality });
        }
      }
    });
  };

  const onDateChange = newValue => {
    setChalanCreationDate(newValue);
  };
  const onArrivaldateCahnge = newValue => {
    setArrivaldate(newValue);
  };
  const onWatakDatechange = newValue => {
    setWatakDate(newValue);
  };
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      setDialogWarning(false);
      setConfirmMandiChalan(false);
      setSubmittedButton(false);
    } else {
      setDialogWarning(false);
    }
  };

  const handleUpdateMandiChalan = status => {
    if (checkRateFieldVal(mandiChalanObj)) {
      setSubmittedButton(true);
      let iDate;
      let aDate;
      let wdate;
      dateConveter.convertDate(chalanCreationDate, response => {
        iDate = response.data;
      });
      dateConveter.convertDate(arrivalDate, response => {
        aDate = response.data;
      });
      dateConveter.convertDate(watakDate, response => {
        wdate = response.data;
      });
      updateChalanDetails.challanDate = iDate;
      updateChalanDetails.arrivalDate = aDate;
      updateChalanDetails.watakDate = wdate;
      updateChalanDetails.status = status;
      updateChalanDetails.totalFreight = mandiChalanObj.totalFreight;
      updateChalanDetails.totalSelfAmount = mandiChalanObj.totalSelfAmount;
      updateChalanDetails.totalQuantity = mandiChalanObj.totalQuantity;
      updateChalanDetails.totalActualQuantity =
        mandiChalanObj.totalActualQuantity;
      updateChalanDetails.freightDetails = groverFreightDetails;
      updateChalanDetails.subWatak = mandiChalanObj.subWatak;
      updateChalanDetails.totalActualWeight = mandiChalanObj.totalActualWeight;
      updateChalanDetails.totalWeight = mandiChalanObj.totalWeight;
      updateChalanDetails.grandTotal = mandiChalanObj.grandTotal;
      updateChalanDetails.totalLabour = mandiChalanObj.totalLabour;
      updateChalanDetails.totalPrice = mandiChalanObj.totalPrice;
      updateChalanDetails.totalCommission = mandiChalanObj.totalCommission;
      setLoader(true);
      MandiChalan.updateWatakById(
        updateChalanDetails,
        updateChalanDetails.id,
        response => {
          if (response.status === 'success') {
            if (response.data.status === 'Drafted') {
              setLoader(false);
              openUpstoreSnackBar(
                'Watak is Saved To Draft Sucessfully',
                'success'
              );
              setMandiChalanObj(response.data);
            } else if (status === 'CONFIRMED') {
              setLoader(false);
              setConfirmMandiChalan(true);
              setMandiChalanObj(response.data);
              setSelectedProductItemList(response.data?.subWatak);
              setUpdateChalanDetails(response.data);
              openUpstoreSnackBar('watak Updated Sucessfully', 'success');
            }
          } else if (response.status === 'error') {
            openUpstoreSnackBar(response.message, 'error');
            setLoader(false);
            setSubmittedButton(false);
          }
        }
      );
    } else {
      openUpstoreSnackBar('Rate field is  Mandatory ', 'error');
      setSubmittedButton(false);
    }
  };

  const freighDetailsByChallanId = () => {
    MandiChalan.getFreightDetailsChalanId(
      updateChalanDetails?.challanId,
      response => {
        if (response.status === 'success') {
          setGroverFreightDetails(response.data);
        } else {
          console.log('Error while Getting Watak');
        }
      }
    );
  };

  const handleDetailsChange = (event, row) => {
    let newRow = row;
    let { name, value } = event.target;
    newRow[name] = value;
    let updatedRow = addSubgrowerDetails(newRow);
    let newData = selectedProductItemList.map(element =>
      element.subGrowerName === newRow.subGrowerName ? updatedRow : element
    );

    setSelectedProductItemList(newData);
    let totalCalculatedValues = PurchaseOrders.calculateTotalValues(newData);
    setMandiChalanObj({
      ...mandiChalanObj,
      subWatak: newData,
      totalSelfAmount: totalCalculatedValues.totalSelfAmount,
      totalFreight: totalCalculatedValues.totalFreight,
      totalLabour: totalCalculatedValues.totalLabour,
      totalPrice: totalCalculatedValues.totalPrice,
      totalCommission: totalCalculatedValues.totalCommission,
      grandTotal: totalCalculatedValues.grandTotal,
    });
  };
  const addSubgrowerDetails = row => {
    let totalExpenses = 0;
    let grossTotal = 0;
    totalExpenses =
      Number(row?.labour) +
      Number(row?.postalCharges ? row?.postalCharges : 0) +
      Number(row?.association ? row?.association : 0) +
      Number(row?.commission) +
      Number(row?.freight) +
      Number(row?.selfAmount);
    grossTotal =
      Number(row?.totalAmount) - Number(totalExpenses ? totalExpenses : 0);
    return { ...row, totalExpenses: totalExpenses, grossTotal: grossTotal };
  };
  const downloadInvoice = subGrowerName => {
    let newRow = updateChalanDetails?.subWatak?.filter(item => {
      if (item.subGrowerName === subGrowerName) {
        return item;
      }
    });
    let orderInvoiceObj = {
      open: true,
      order: newRow[0],
      grower: vendorInfo,
    };
    setOrderInvoice(orderInvoiceObj);
  };
  const closeInvoiceDialog = () => {
    let orderInvoiceObj = {
      open: false,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const checkRateFieldVal = data => {
    return data.subWatak.every(item => {
      return item.watakDetails.every(
        itemDet => itemDet.price !== '' && itemDet.price !== null
      );
    });
  };

  return (
    <div>
      <Header />
      <Grid sx={{ ml: 8, mt: 8 }}>
        <Grid sx={{ ml: 1 }} item md={12} sm={12} xs={12}>
          <BreadCrumb pageName='Watak' />
        </Grid>
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item md={6} sx={{ mb: '10px' }}>
                <Tooltip title='Go to watak' placement='bottom'>
                  <Button
                    startIcon={<ArrowLeft />}
                    variant='text'
                    underline='none'
                    sx={{
                      padding: 0,
                      mb: 1,
                      ml: 2,
                      textTransform: 'capitalize',
                      width: '146px',
                      height: '27px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Watak List
                  </Button>
                </Tooltip>{' '}
              </Grid>
              <Grid
                item
                md={6}
                sx={{ display: 'flex', justifyContent: 'space-around' }}
              >
                {confirmMandiChalan && serverConfig.isUpstoreCloud ? (
                  <Tooltip title='Edit Confirmed Chalan' placement='bottom'>
                    <Button
                      startIcon={
                        <EditIcon sx={{ color: '#fff', fontSize: '13px' }} />
                      }
                      variant='contained'
                      // disabled={!customerInfo?.active}
                      sx={{
                        width: '126px',
                        height: '27px',
                        textTransform: 'capitalize',
                        p: 0,
                        bgcolor: '#3B78C9',
                      }}
                      onClick={() => {
                        setDialogWarning(true);
                      }}
                    >
                      <Typography noWrap>Edit Watak</Typography>
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex' }}>
              <Grid item xs={10} sm={5} md={5} lg={5} sx={{ width: 500, p: 1 }}>
                <Typography variant='subtitle1' sx={{ marginBottom: '-2px' }}>
                  Grower Name/Phone{' '}
                  <Tooltip title='Mandatory Field' placement='top'>
                    <span style={{ color: 'red' }}>*</span>
                  </Tooltip>
                </Typography>
                <Autocomplete
                  // freeSolo
                  id='packing'
                  options={vendorDataList}
                  disableClearable
                  defaultValue=''
                  onChange={(event, newValue) => {
                    if (newValue == 121) {
                      return null;
                    }
                    handleSelectVandor(newValue);
                  }}
                  clearOnBlur={true}
                  getOptionLabel={option =>
                    typeof option == 'string'
                      ? (option = '')
                      : option !== undefined
                      ? `${option?.name} ` || {}
                      : ''
                  }
                  value={
                    // Object.keys(selectedProductItemList)?.length > 0
                    //   ? vendorInfo || {}
                    //   : ''
                    vendorInfo
                  }
                  disabled={
                    state?.chalan?.status === 'CONFIRMED' || confirmMandiChalan
                  }
                  renderOption={(props, option) => {
                    return (
                      <>
                        <div {...props} key={option.id}>
                          <span
                            key={option.id}
                            style={{ paddingRight: '10px' }}
                          >
                            <PeopleAlt fontSize='small' />
                          </span>
                          {`${option.name}`}
                          <li
                            style={{ color: 'gray', marginLeft: 'auto' }}
                            key={option.id}
                            {...props}
                          >{`${
                            option.contactNo !== null ? option.contactNo : ''
                          } `}</li>
                        </div>
                      </>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      sx={textField}
                      placeholder='Search Vendor'
                      size='small'
                      variant='outlined'
                      autoFocus
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                sx={{
                  ml: 3,
                  mb: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item md={7} lg={7} sx={{ mt: 2, ml: 'auto' }}>
                  <TableContainer sx={{ maxHeight: 450, width: 500 }}>
                    <Table
                      stickyHeader
                      aria-label='sticky table'
                      sx={{
                        position: 'relative',
                        right: 10,
                      }}
                      size='small'
                    >
                      <TableHead>
                        <TableRow>
                          {freightColumns?.map(column => {
                            return (
                              <>
                                {column.label !== null ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      maxWidth: column.maxWidth,
                                      fontSize: 'small',
                                      fontWeight: 'bold',
                                      whiteSpace: 'nowrap',
                                      backgroundColor: '#E1EEF5',
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : null}
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groverFreightDetails?.map((item, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <TextField
                                name='pack'
                                size='small'
                                id=' pack'
                                disabled='true'
                                value={groverFreightDetails[idx]?.pack}
                                variant='standard'
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id=' freight'
                                name='freight'
                                variant='standard'
                                value={groverFreightDetails[idx]?.freight}
                                disabled={true}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id='selfAmount'
                                name='selfAmount'
                                variant='standard'
                                value={groverFreightDetails[idx]?.selfAmount}
                                disabled={true}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size='small'
                                type='number'
                                id='labour'
                                name='labour'
                                variant='standard'
                                value={groverFreightDetails[idx]?.labour}
                                disabled={true}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Challan Number</Typography>
                <TextField
                  sx={{
                    width: '90%',
                    marginTop: '6px',
                    backgroundColor: '#E1EEF5',
                    borderTop: '5px solid #E1EEF5',
                    mt: '-0.1px',
                  }}
                  value={challanNumber}
                  disabled={confirmMandiChalan}
                  id='challanNumber'
                  variant='outlined'
                  size='small'
                  name='challanNumber'
                  onChange={e => setChallanNumber(e.target.value)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Chalan Date</Typography>
                <DatePickerComp
                  value={chalanCreationDate}
                  maxDate={new Date()}
                  dateChange={onDateChange}
                  disabled={confirmMandiChalan}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Arrival Date</Typography>
                <DatePickerComp
                  value={arrivalDate}
                  dateChange={onArrivaldateCahnge}
                  disabled={confirmMandiChalan}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography variant='subtitle1'>Watak Date</Typography>
                <DatePickerComp
                  value={watakDate}
                  dateChange={onWatakDatechange}
                  disabled={confirmMandiChalan}
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleGroverAccChange('panel1')}
              >
                <AccordionSummary
                  sx={{ backgroundColor: 'secondary.light' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Grower{' '}
                    <b>
                      <Link
                        to='/vendor-details'
                        state={vendorInfo}
                        style={{ textDecoration: 'none' }}
                      >
                        {vendorInfo.name}
                      </Link>
                    </b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Grower Name</Typography>
                      <TextField
                        sx={textField}
                        id='name'
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'name')
                            ? `${vendorInfo.name}`
                            : ''
                        }
                        placeholder='Enter vendor name'
                        name='vendorName'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Contact No</Typography>
                      <TextField
                        sx={textField}
                        id='contactNo'
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(
                            vendorInfo,
                            'contactNo'
                          )
                            ? `${vendorInfo.contactNo}`
                            : ''
                        }
                        placeholder='Enter vendor contactNo'
                        name='contactNo'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Email</Typography>
                      <TextField
                        sx={textField}
                        id='email'
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'email')
                            ? `${vendorInfo.email}`
                            : ''
                        }
                        placeholder='Enter vendor email'
                        name='email'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>Address</Typography>
                      <TextField
                        sx={textField}
                        id='address'
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(vendorInfo, 'address')
                            ? `${vendorInfo.address}`
                            : ''
                        }
                        placeholder='Enter address'
                        name='address'
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant='subtitle1'>
                        opening Balance
                      </Typography>
                      <TextField
                        sx={textField}
                        id='openingBalance'
                        disabled={true}
                        variant='outlined'
                        size='small'
                        value={
                          Validation.isBlankObjectKeyNot(
                            vendorInfo,
                            'openingBalance'
                          )
                            ? `${vendorInfo.openingBalance}`
                            : ''
                        }
                        placeholder='Enter openingBalance '
                        name='openingBalance'
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {vendorInfo.name ? (
                <>
                  {addAccordion?.map((point, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        sx={{ backgroundColor: 'secondary.light' }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          Sub Grower &nbsp;<b>{point?.subGrowerName}</b>
                        </Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography>
                          Sub Grower No <b> {index + 1}</b>
                        </Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography>
                          S.No :<b> {point?.subWatakNumber}</b>
                        </Typography>
                        {updateChalanDetails.status === 'CONFIRMED' &&
                        submittedButton ? (
                          <Box sx={{ ml: 'auto' }}>
                            <Tooltip
                              title={`Download Sub Watak Invoice`}
                              placement='bottom'
                            >
                              <DownloadIcon
                                sx={{
                                  color: '#3B78C9',
                                  cursor: 'pointer',
                                  mr: 2,
                                }}
                                onClick={event =>
                                  downloadInvoice(point?.subGrowerName)
                                }
                              />
                            </Tooltip>
                          </Box>
                        ) : null}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item md={12} sx={{ mt: 2 }}>
                          <TableContainer
                            sx={{
                              maxHeight: 450,
                              overflow: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                width: '10px',
                                backgroundColor: '#83b1cf',
                              },
                            }}
                            component={Paper}
                            className='scroll'
                          >
                            <Table
                              stickyHeader
                              aria-label='sticky table'
                              sx={{
                                backgroundColor: 'secondary.light',
                                position: 'relative',
                              }}
                              size='small'
                            >
                              <TableHead>
                                <TableRow>
                                  {columns?.map(column => {
                                    return (
                                      <>
                                        {column.label !== null ? (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              fontSize: 'medium',
                                              fontWeight: '500',
                                              fontSize: 'large',
                                              whiteSpace: 'nowrap',
                                              backgroundColor: '#E1EEF5',
                                            }}
                                          >
                                            {column.label}
                                          </TableCell>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedProductItemList?.length > 0
                                  ? selectedProductItemList?.map(row => {
                                      if (
                                        row?.subGrowerName ===
                                        point?.subGrowerName
                                      ) {
                                        return row?.watakDetails?.map(item => {
                                          return (
                                            <MandiChalanItems
                                              key={item?.itemNumber}
                                              row={item}
                                              name={point?.subGrowerName}
                                              AddChalanDetailsDataCallBack={
                                                addChalanDetailsDataCallBack
                                              }
                                              getAllVariatiesByType={
                                                getAllVariatiesByType
                                              }
                                              packingData={
                                                varietyDetails?.packingData &&
                                                varietyDetails?.packingData
                                              }
                                              varietyData={
                                                varietyDetails?.varietyData
                                              }
                                              qualityData={
                                                varietyDetails?.qualityData
                                              }
                                              groverFreightDetails={
                                                groverFreightDetails
                                              }
                                              confirmChalan={confirmMandiChalan}
                                              snackbar={openUpstoreSnackBar}
                                              type='watak'
                                              status={state.watak.status}
                                            />
                                          );
                                        });
                                      }
                                    })
                                  : null}

                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  sx={{ backgroundColor: '#fff' }}
                                ></TableRow>
                              </TableBody>

                              <TableFooter
                                sx={{
                                  backgroundColor: '#E1EEF5',
                                  position: 'sticky',
                                  bottom: 0,
                                }}
                              >
                                {selectedProductItemList?.map(row => {
                                  if (
                                    row?.subGrowerName === point?.subGrowerName
                                  ) {
                                    return (
                                      <TableRow key={row?.subGrowerName}>
                                        <TableCell
                                          style={{
                                            maxWidth: 100,
                                            fontSize: 'large',
                                            fontWeight: 'bold',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          Total
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.subGrowerTotalQuantity}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerActualQuantity}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerTotalWeight}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.subGrowerActualWeight}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.labour.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.freight}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {' '}
                                          {row?.selfAmount.toFixed(2)}{' '}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.commission &&
                                            Number(row?.commission)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.price}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {row?.totalAmount?.toFixed(3)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                })}
                              </TableFooter>
                            </Table>

                            <Grid
                              sx={{
                                display: 'flex',
                                float: 'right',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mr: 1,
                              }}
                            >
                              <Table
                                aria-label='sticky table'
                                sx={{
                                  maxWidth: 500,
                                  mt: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                size='small'
                              >
                                <TableHead
                                  sx={{ backgroundColor: 'secondary.light' }}
                                >
                                  <TableRow>
                                    <TableCell align='center' colSpan={3}>
                                      <Typography
                                        sx={{
                                          width: '100%',
                                          flexShrink: 0,
                                        }}
                                      >
                                        <b> Other Charges</b>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {selectedProductItemList?.map(row => {
                                  if (
                                    row?.subGrowerName === point?.subGrowerName
                                  ) {
                                    return (
                                      <TableBody key={row?.subGrowerName}>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            {' '}
                                            Total Commission
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='commission'
                                              size='small'
                                              placeholder=' Enter Commission'
                                              type='number'
                                              value={row?.commission}
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                              variant='standard'
                                              name='commission'
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            Total Freight
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='freight'
                                              size='small'
                                              placeholder=' Enter Freight'
                                              type='number'
                                              value={row?.freight}
                                              variant='standard'
                                              name='freight'
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            {' '}
                                            Self Amount
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='selfAmount'
                                              size='small'
                                              placeholder=' Enter Self Amount'
                                              type='number'
                                              value={row?.selfAmount}
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                              variant='standard'
                                              name='selfAmount'
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            {' '}
                                            Association
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='association'
                                              size='small'
                                              type='number'
                                              placeholder=' Enter Association charges'
                                              value={row?.association}
                                              variant='standard'
                                              name='association'
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            Postal Charge
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='postalCharges'
                                              size='small'
                                              placeholder=' Enter Postal charges'
                                              type='number'
                                              value={row?.postalCharges}
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                              variant='standard'
                                              name='postalCharges'
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            {' '}
                                            Labour
                                          </TableCell>
                                          <TableCell align='right'>
                                            <TextField
                                              disabled={confirmMandiChalan}
                                              className='number__input'
                                              sx={{ width: '120px' }}
                                              id='labour'
                                              size='small'
                                              placeholder=' Enter Labour'
                                              type='number'
                                              value={row?.labour}
                                              onChange={event =>
                                                handleDetailsChange(event, row)
                                              }
                                              // onBlur={() => amountCalculation(rowData)}
                                              variant='standard'
                                              name='labour'
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            {' '}
                                            Total Expanse
                                          </TableCell>

                                          <TableCell align='center'>
                                            <Typography>
                                              {row?.totalExpenses?.toFixed(3)}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={1}>
                                            Gross Total
                                          </TableCell>
                                          <TableCell align='center'>
                                            <Typography>
                                              {row?.grossTotal?.toFixed(3)}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    );
                                  }
                                })}
                              </Table>
                            </Grid>
                          </TableContainer>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              ) : null}

              {/* <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Table sx={{ maxWidth: 600, mt: 1 }} aria-label='spanning table'>
              <TableHead sx={{ backgroundColor: 'secondary.light' }}>
                <TableRow>
                  <TableCell align='center' colSpan={3}>
                    <Typography
                      sx={{
                        width: '100%',
                        flexShrink: 0,
                      }}
                    >
                      <b> Grand Total</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProductItemList?.map(item => {
                  return (
                    <TableRow>
                      <TableCell colSpan={1}>{item.subGrowerName} </TableCell>
                      <TableCell align='right'>
                        <Typography>{item.grossTotal?.toFixed(3)}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow>
                  <TableCell colSpan={1}>Total Self Amount</TableCell>
                  <TableCell align='right' colSpan={2}>
                    - {mandiChalanObj?.totalSelfAmount?.toFixed(3)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}>Grand Total</TableCell>
                  <TableCell align='right' colSpan={2}>
                    {mandiChalanObj?.grandTotal?.toFixed(3)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid> */}

              {updateChalanDetails.status === 'Drafted' ? (
                <>
                  <Button
                    disabled={submittedButton}
                    variant='contained'
                    sx={{
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',
                      float: 'right',
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={() => handleUpdateMandiChalan('Drafted')}
                  >
                    {`Draft Watak`}
                  </Button>
                  <Button
                    disabled={submittedButton}
                    variant='contained'
                    sx={{
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',
                      float: 'right',
                      mt: 2,
                      mr: 2,
                    }}
                    onClick={() => handleUpdateMandiChalan('CONFIRMED')}
                  >
                    <Typography noWrap>Confirm Watak</Typography>
                  </Button>
                </>
              ) : (
                <Button
                  disabled={submittedButton}
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    float: 'right',
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={() => handleUpdateMandiChalan('CONFIRMED')}
                >
                  {`Update Watak`}
                </Button>
              )}
            </Grid>
             
          </>
        )}
      </Grid>
         
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      <Dialog
        maxWidth={'xl'}
        open={orderInvoice.open}
        onClose={closeInvoiceDialog}
      >
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right'>
              <Close
                onClick={closeInvoiceDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <DialogContent>
          <MandiWatakInvoice
            orderDetails={orderInvoice.order}
            groverDetails={orderInvoice.grower}
            challanDetails={updateChalanDetails}
          />
        </DialogContent>
      </Dialog>
      {dialogWarning ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
          openDialog={dialogWarning}
          content='This Watak is confirmed , Are you sure you want to edit it..'
        />
      ) : null}
    </div>
  );
}

export default WatakDetails;
