import React, { useEffect, useState } from 'react';
import DAFAUT_LOGO_IMG from '../../assets/images/invoiceDummy.png';

import Auth from '../../modules/Auth';
import Logo from '../../modules/Logo';
import './styles.css';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';
import { serverConfig } from '../../config/server-config';

const InvoiceTemplate = ({ fullHeight, template }) => {
  const [logoUrl, setLogoUrl] = useState('');
  const [signatureUrl, setSignatureUrl] = useState('');

  useEffect(() => {
    getLogoUrl();
    getSignatureUrl();
  }, []);

  const getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      setLogoUrl(Logo.getLogoUrl(Auth.getStoreId()));
    } else {
      setLogoUrl(logo);
    }
  };

  const getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      setSignatureUrl(Logo.getSignatureUrl());
    } else {
      setSignatureUrl(signature);
    }
  };

  const addDefaultSrc = ev => {
    ev.target.src = DAFAUT_LOGO_IMG;
  };
  return (
    <>
      <div
        className={fullHeight ? 'card' : 'invoice-card card'}
        style={{ textAlign: 'left', display: 'flex', flexWrap: 'wrap' }}
      >
        <header
          style={{
            backgroundImage: `url(${template?.bgImageHeader})`,
            // backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className='d-flex flex-row p-2'>
            <div className='d-flex flex-column'>
              <img
                src={logoUrl}
                alt='Logo'
                style={{ maxWidth: '100px', maxHeight: '50px' }}
                onError={addDefaultSrc}
              />
            </div>
          </div>
        </header>
        <hr />
        <div className='table-responsive p-1'>
          <table className='table table-borderless'>
            <tbody>
              <tr className='invoice_Hed'>
                <th></th>
                <th style={{ color: template?.bgColor }}>Customer </th>
              </tr>
              <tr className='content'>
                <td className='font-weight-bold'>
                  Red Stag Labs
                  <br />
                  Store Address{' '}
                </td>
                <td className='font-weight-bold'>
                  Customer Name
                  <br />
                  customer Address
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div style={{ textAlign: 'left' }}>
          <div style={{ padding: '5px' }}>
            <h5 style={{ fontSize: '15px' }}>
              Order No :<span style={{ fontSize: '12px' }}> 1111 </span>
            </h5>
            <h5 style={{ fontSize: '15px' }}>
              Invoice Date :&nbsp;&nbsp;
              <span style={{ fontSize: '12px' }}>dd-mm-yyyy</span>
            </h5>
            <h5 style={{ fontSize: '15px' }}>
              Due Date :&nbsp;&nbsp;
              <span style={{ fontSize: '12px' }}>dd-mm-yyy</span>
            </h5>
          </div>
        </div>
        <hr />
        <div className='products'>
          <table className='table '>
            <tbody>
              <tr
                className='add'
                style={{ backgroundColor: template?.bgColor }}
              >
                <th>Item Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Discount</th>
                {/* <th>Tax</th> */}
                <th className='text-center'>Amount</th>
              </tr>
              <tr className='content '>
                <td>
                  product item
                  <br />
                  <span
                    className='description'
                    style={{ fontSize: '9px' }}
                  ></span>
                </td>
                <td>9999</td>
                <td>1</td>
                <td>11(Kg)</td>
                <td>123</td>
                {/* <td className='text-center'>-</td> */}
                <td className='text-center'>377</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className='table table-borderless'>
            <tbody style={{ float: 'right' }}>
              <tr>
                <td>
                  {' '}
                  <strong style={{ color: template?.bgColor }}>
                    Order Total Amount :
                  </strong>
                </td>
                <td>111111&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <strong style={{ color: template?.bgColor }}>
                    Total Discount :
                  </strong>
                </td>
                <td>158&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <hr />

        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <table className='table table-borderless'>
              <tbody>
                <tr style={{ backgroundColor: template?.bgColor }}>
                  <th> Bank Details</th>
                </tr>
                <tr>
                  <td>
                    Bank Name : N/A
                    <br /> IFSC-Code : N/A
                    <br /> Branch Address : N/A
                    <br />
                    Account Number : N/A
                    <br />{' '}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ justifyContent: 'flex-end' }}>
            <table className='table table-borderless'>
              <tbody style={{ float: 'right' }}>
                <tr style={{ backgroundColor: template?.bgColor }}>
                  <th>Invoice Summary</th>
                </tr>
                <tr>
                  <tr>
                    <td>
                      Prev Balance : <hr />
                    </td>
                    <td style={{ float: 'right' }}>
                      -1233455.51&nbsp;
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td> Order Amount :</td>
                    <td style={{ float: 'right' }}>+ 57656.04&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Return Amount : </td>
                    <td style={{ float: 'right' }}>- 432.00&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <hr />
                      <strong style={{ color: template?.bgColor }}>
                        {' '}
                        Total Balance :
                      </strong>
                    </td>
                    <td style={{ float: 'right' }}>
                      {' '}
                      <hr />
                      -1196231.47&nbsp;&nbsp;
                    </td>
                  </tr>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            margin: '5px',
            paddingBottom: '1rem',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <h6 style={{ color: template?.bgColor }}>Signature</h6>
          <img
            src={signatureUrl}
            alt='Signature'
            style={{ maxWidth: '100px', maxHeight: '80px' }}
            onError={addDefaultSrc}
          />
        </div>

        <div
          style={{
            backgroundImage: `url(${template?.bgImageFooter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            border: '2px solid gray',
            padding: '5px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 style={{ fontSize: '14px', display: 'flex' }}>
            <span>Terms &amp; Conditions:</span>Terms And Condition
          </h1>
        </div>
        <span style={{ fontSize: '10px', textAlign: 'center' }}>
          Invoice Date : dd-mm-yyyy 15:52:37
        </span>
      </div>
    </>
  );
};

export default InvoiceTemplate;
