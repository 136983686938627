import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from '../../component/header/Header';
import { Button, Grid } from '@mui/material';
import Auth from '../../modules/Auth';
import * as moment from 'moment';
import Store from '../../modules/Store';
import Ledger from '../../modules/Ledger/index';
import AllLedger from '../../component/allLedger/AllLedger';
import { useEffect } from 'react';
import { TextField } from '@mui/material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';

const PurchaseLedger = () => {
  const [ledger1, setLedger1] = React.useState([]);
  const [startIndex, setStartIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(20);
  const [options, setOptions] = React.useState({
    unit: 'pt',
    format: [900, 900],
  });
  const date = new Date();
  const [value1, setValue1] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [value2, setValue2] = React.useState(new Date());

  useEffect(() => {
    ledger(startIndex, lastIndex);
  }, [startIndex, lastIndex]);

  const ledger = (startInd, lastInd) => {
    let storeId = Auth.getStoreId();
    let istDate = moment(value1).format('YYYY-MM-DD');
    let lastDate = moment(value2).format('YYYY-MM-DD');
    Ledger.getAllByStoreIdAndCreationDate(
      storeId,
      istDate,
      lastDate,
      startInd,
      lastInd,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          getPDFHeightAndWidth();
          setLedger1([...response.data]);
        } else {
          setLedger1([]);
        }
      }
    );
  };

  const getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...options };
      let maxHeight = document.getElementById('divToPrint').clientHeight;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      setOptions({ opt });
    }, 5);
  };
  const hangeNextPageNo = no => {
    setStartIndex(lastIndex);
    setLastIndex(lastIndex + no);
  };
  const hangePrePageNo = no => {
    setStartIndex(startIndex - no);
    setLastIndex(lastIndex - no);
  };
  return (
    <>
      <Header pageName='purchase ledger' />
      <Box
        sx={{
          paddingLeft: '3.5rem',
          paddingRight: '.5rem',
          mt: 6,
        }}
      >
        <Grid sx={{ ml: 1, mt: 1 }} item md={12} sm={12} xs={12}>
          <BreadCrumb pageName='Purchase-Ledger' />
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value1}
              inputFormat='dd/MM/yyyy'
              onChange={newValue => {
                setValue1(newValue);
              }}
              renderInput={params => (
                <TextField
                  size='small'
                  sx={{ mr: 2, mt: 1, width: '140px' }}
                  {...params}
                />
              )}
            />
            <DatePicker
              value={value2}
              onChange={newValue => {
                setValue2(newValue);
              }}
              inputFormat='dd/MM/yyyy'
              renderInput={params => (
                <TextField
                  size='small'
                  sx={{
                    width: '140px',
                    mt: 1,
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <Button
            variant='contained'
            sx={{
              padding: 0,
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              backgroundColor: '#3B78C9',
              ml: 2,
              mt: 1.5,
            }}
            onClick={() => ledger(startIndex, lastIndex)}
          >
            <Typography
              sx={{
                fontSize: { md: '12px', sm: '12px', xs: '10px' },
                p: '1px',
              }}
            >
              Generate Statement
            </Typography>
          </Button>
        </Box>

        <LedgerComponents
          ledger={ledger1}
          startDate={value1}
          endDate={value2}
          hangeNextPageNo={hangeNextPageNo}
          hangePrePageNo={hangePrePageNo}
          startIndex={startIndex}
        />
      </Box>

      {AllPermissions.getUserAccessiblePermissions('AllLedger') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('AllLedger').read ===
        false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 'none', width: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LedgerComponents = ({
  ledger,
  startDate,
  endDate,
  hangeNextPageNo,
  hangePrePageNo,
  startIndex,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='All' {...a11yProps(0)} />
          <Tab label='Customer' {...a11yProps(1)} />
          {Store.companyDetails.businessType === 'MANDI' ? (
            <Tab label='Vendor' {...a11yProps(2)} />
          ) : null}
          {Store.companyDetails.businessType === 'MANDI' ? (
            <Tab label='Party' {...a11yProps(3)} />
          ) : (
            <Tab label='Party' {...a11yProps(2)} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AllLedger
          key='ALL'
          type='All'
          ledger={ledger}
          istDate={startDate}
          lastDate={endDate}
          hangeNextPageNo={hangeNextPageNo}
          hangePrePageNo={hangePrePageNo}
          startIndex={startIndex}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllLedger
          key='CUSTOMER'
          type='Customer'
          istDate={startDate}
          lastDate={endDate}
          hangeNextPageNo={hangeNextPageNo}
          hangePrePageNo={hangePrePageNo}
          startIndex={startIndex}
          ledger={ledger.filter(name => {
            return name.type?.toUpperCase() === 'CUSTOMER';
          })}
        />
        {/* <CustomerLedger
          ledger={ledger.filter(name => {
            return name.type.toUpperCase() === 'CUSTOMER';
          })} */}
      </TabPanel>
      {Store.companyDetails.businessType === 'MANDI' ? (
        <TabPanel value={value} index={2}>
          <AllLedger
            key='VENDOR'
            type='Vendor'
            istDate={startDate}
            lastDate={endDate}
            hangeNextPageNo={hangeNextPageNo}
            hangePrePageNo={hangePrePageNo}
            startIndex={startIndex}
            ledger={ledger.filter(name => {
              return name.type?.toUpperCase() === 'VENDOR';
            })}
          />
          {/* <VendorLedger /> */}
        </TabPanel>
      ) : null}
      {Store.companyDetails.businessType === 'MANDI' ? (
        <TabPanel value={value} index={3}>
          <AllLedger
            key='PARTY'
            type='Party'
            istDate={startDate}
            lastDate={endDate}
            hangeNextPageNo={hangeNextPageNo}
            hangePrePageNo={hangePrePageNo}
            startIndex={startIndex}
            ledger={ledger.filter(name => {
              return name.type?.toUpperCase() === 'PARTY';
            })}
          />
          {/* <PartyLedger /> */}
        </TabPanel>
      ) : (
        <TabPanel value={value} index={2}>
          <AllLedger
            key='PARTY'
            type='Party'
            istDate={startDate}
            lastDate={endDate}
            startIndex={startIndex}
            ledger={ledger.filter(name => {
              return name.type?.toUpperCase() === 'PARTY';
            })}
          />
          {/* <PartyLedger /> */}
        </TabPanel>
      )}
    </Box>
  );
};

export default PurchaseLedger;
