import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { Typography, Button } from '@mui/material';
import './styles.css';
import StoreAccount from '../../modules/Store/index';
import Logo from '../../modules/Logo';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import Store from '../../modules/Store';
import * as moment from 'moment';
import HTMLRenderer from 'react-html-renderer';
import CancelledImG from '../../assets/images/cancelled.png';
import Vendor from '../../modules/Vendor';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';

import { serverConfig } from '../../config/server-config';

const ref = React.createRef();
let amount = 0;
let prevBal;

class MultipleWatakInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: null,
      signatureUrl: null,
      currency: null,
      allWataks: [],
      orderDetails: this.props.orderDetails,
      options: {
        unit: 'pt',
        format: [280, 180],
        storeAccount: [],
      },
      bal: 0,
      selectedTemplate: {},
      defaultConfigBankDetails: {},
      storeDetails: {},
    };
  }

  componentDidMount() {
    this.storeAccount();
    this.getLogoUrl(Auth.getStoreId());
    this.getSignatureUrl();
    this.getCurrency();
    this.getStoreById();
    this.getSubWataksFromWatakList(this.props.orderDetails);
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });

    amount = 0;
    // prevBal = 0;
  }

  storeAccount = () => {
    StoreAccount.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        this.getPDFHeightAndWidth();
        this.setState({ storeAccount: { ...response.data[0] } });
      } else {
        this.setState({ storeAccount: [] });
      }
    });
  };
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getStoreById = () => {
    Store.getStoreByStoreId(Auth?.getStoreId(), response => {
      if (response.status === 'success') {
        let parsed = JSON.parse(response?.data?.invoice);

        if (parsed) {
          this.setState({ selectedTemplate: parsed });
        }
      } else {
        console.log('error');
      }
    });
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint')?.clientHeight;
      let maxWidth = document.getElementById('divToPrint')?.clientWidth;
      opt.format = [maxHeight, maxWidth];
      this.setState({
        options: opt,
      });
    }, 5);
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        signatureUrl: Logo.getSignatureUrl(),
      });
    } else {
      this.setState({
        signatureUrl: signature,
      });
    }
  };
  getSubWataksFromWatakList = watakList => {
    let result = watakList?.map(a => {
      return a?.subWatak?.map(item => ({
        ...item,
        name: a.grower,
        vendorId: a.vendorId,
        challanNo: a.challanNumber,
        challanCreationDate: a.challanDate,
        address: a.vendorAddress,
      }));
    });
    let finalResult = result.flat();
    this.setState({ allWataks: finalResult });
  };
  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            filename={`receipt__${this.state.orderDetails?.purchaseOrderNo}.pdf`}
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',
                    fontSize: '14px',

                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon
                    sx={{ color: 'white', fontSize: '16px', mr: '5px' }}
                  />{' '}
                  Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
        </Typography>
        <br />

        <div className='divToDownload ' ref={ref}>
          {this.state.orderDetails.deleted ? (
            <img
              src={CancelledImG}
              style={{
                position: 'absolute',
                left: '100px',
                top: '280px',
                zIndex: 1,
                opacity: '0.9',
              }}
            />
          ) : null}

          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100% ' }}
          >
            {Validation.isBlankNot(this.state.logoUrl) ? (
              <img
                className='invoice-bg'
                style={{ width: '100%', height: '100%' }}
                src={this.state.logoUrl}
                alt='Logo'
              ></img>
            ) : (
              `Logo`
            )}
            {this.state.allWataks.map(item => {
              return (
                <div className='invoice-content row d-flex justify-content-center'>
                  <div className='col-12'>
                    <div className='card'>
                      <header
                        style={{
                          backgroundImage: `url(${this.state.selectedTemplate?.bgImageHeader})`,
                          // backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                        }}
                      >
                        <div className='d-flex flex-column ml-auto p-2'>
                          <div class='align-self-end'>
                            <Typography fontSize='12px'>
                              <strong>Email : </strong>
                              {this.state.storeDetails.email
                                ? this.state.storeDetails.email
                                : ''}
                            </Typography>
                          </div>
                          <div class='align-self-end'>
                            <Typography fontSize='12px'>
                              <strong> Contact No : </strong>{' '}
                              {this.state.storeDetails.phoneNumber}
                            </Typography>
                          </div>
                        </div>
                      </header>
                      <div class='container'>
                        <div class='row'>
                          <div class='col-3 p-1'>
                            {Validation.isBlankNot(this.state.logoUrl) ? (
                              <img
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                }}
                                src={this.state.logoUrl}
                                alt='Logo'
                              ></img>
                            ) : (
                              `Logo`
                            )}
                          </div>
                          <div class='col-9'>
                            <Typography
                              variant='h3'
                              style={{
                                color: this.state.selectedTemplate?.bgColor,
                              }}
                              fontSize='40px'
                              noWrap
                            >
                              <strong>{`${this.state.storeDetails.name}`}</strong>
                            </Typography>

                            {this.state.storeDetails.description ? (
                              <span className='text_font'>
                                <HTMLRenderer
                                  html={this.state.storeDetails.description}
                                />
                              </span>
                            ) : null}
                            <div className='text_font'>
                              <strong>
                                {' '}
                                {this.state.storeDetails.address}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className='table-responsive p-1'>
                        <table
                          className='table table-borderless'
                          style={{ flex: 'start' }}
                        >
                          <tbody>
                            <tr className='invoice_Hed'>
                              <th
                                style={{
                                  color: this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                Grower{' '}
                              </th>
                              <th
                                style={{
                                  color: this.state.selectedTemplate?.bgColor,
                                }}
                              >
                                Sub Grower
                              </th>
                            </tr>

                            <tr className='content'>
                              <td>
                                <tr>
                                  <h5
                                    style={{ fontSize: '8px' }}
                                  >{`${item?.name}`}</h5>
                                </tr>
                                <tr>
                                  <h5
                                    style={{ fontSize: '8px' }}
                                  >{`${item.address}`}</h5>
                                </tr>
                                <tr>
                                  {/* <h5 style={{ fontSize: '8px' }}>{`${
                                    Vendor.getVendorByVendorId(
                                      item?.vendorId,
                                      () => {}
                                    )?.email
                                  }`}</h5> */}
                                </tr>
                              </td>
                              <td>
                                {' '}
                                <div>
                                  <div style={{ padding: '5px' }}>
                                    <h5 style={{ fontSize: '8px' }}>
                                      Sub Grower : &nbsp;&nbsp;
                                      <span>{`${item?.subGrowerName}`}</span>
                                    </h5>

                                    <h5 style={{ fontSize: '8px' }}>
                                      Chalan No :
                                      <span style={{ fontSize: '8px' }}>
                                        {' '}
                                        {Validation.isBlankObjectKeyNot(
                                          item,
                                          'subWatakNumber'
                                        )
                                          ? item?.subWatakNumber
                                          : 'N/A'}{' '}
                                      </span>
                                      &nbsp;&nbsp; Chalan Date :&nbsp;&nbsp;
                                      <span style={{ fontSize: '8px' }}>
                                        {moment
                                          .utc(item?.challanCreationDate)
                                          .local()
                                          .format('DD-MM-yyyy')}
                                      </span>
                                    </h5>

                                    <h5 style={{ fontSize: '8px' }}>
                                      Watak No :
                                      <span style={{ fontSize: '8px' }}>
                                        {' '}
                                        {Validation.isBlankObjectKeyNot(
                                          item,
                                          'subWatakNumber'
                                        )
                                          ? item?.subWatakNumber
                                          : 'N/A'}{' '}
                                      </span>
                                      &nbsp;&nbsp; Watak Date :&nbsp;&nbsp;
                                      <span style={{ fontSize: '8px' }}>
                                        {moment
                                          .utc(item?.creationDate)
                                          .local()
                                          .format('DD-MM-yyyy')}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <hr />
                      <div className='products p-2'>
                        <table className='table '>
                          <tbody>
                            <tr
                              className='add'
                              style={{
                                backgroundColor:
                                  this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <th>Item Name</th>
                              <th>Pack</th>
                              <th>Quality</th>
                              <th>Variety</th>
                              <th>Qty</th>
                              <th>Weight</th>
                              {/* <th>Freight</th>
                              <th> Commission(%)</th> */}
                              <th>Rate</th>
                              <th className='text-center'>Amount</th>
                            </tr>
                            {item.watakDetails !== undefined &&
                            this.watakDetails !== null
                              ? item?.watakDetails?.map(item => {
                                  return (
                                    <tr className='content ' key={item.id}>
                                      <td
                                        style={{
                                          fontSize:
                                            item.itemName.length > 30 && '10px',
                                          width:
                                            item.itemName.length > 30
                                              ? ' 100px'
                                              : 'auto',
                                        }}
                                      >
                                        <>
                                          {item.itemName}
                                          <br />
                                        </>
                                      </td>

                                      <td>{item.pack}</td>
                                      <td className='text-center'>
                                        {item?.quality !== null &&
                                        item?.quality !== ''
                                          ? item?.quality
                                          : '-'}
                                      </td>
                                      <td className='text-center'>
                                        {item?.variety !== null &&
                                        item?.variety !== ''
                                          ? item?.variety
                                          : '-'}
                                      </td>
                                      <td>{item.actualQuantity}</td>
                                      <td style={{ textAlign: 'center' }}>
                                        {item.actualWeight !== null &&
                                        item.actualWeight !== '0'
                                          ? item.actualWeight
                                          : '-'}
                                      </td>

                                      <td className='text-center'>
                                        {item?.price !== null &&
                                        item?.price !== '0'
                                          ? item?.price?.toFixed(2)
                                          : '-'}
                                      </td>
                                      <td className='text-center'>
                                        {item?.amount?.toFixed(2)}
                                        {this.state.currency ? (
                                          <HTMLRenderer
                                            html={this.state.currency}
                                          />
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <table className='table table-borderless'>
                          <tbody style={{ float: 'right' }}>
                            <tr>
                              <td>
                                {' '}
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                    fontSize: '11px',
                                  }}
                                >
                                  Total Amount :
                                </strong>
                              </td>
                              <td>
                                <Typography fontSize='11px'>
                                  {item.totalAmount &&
                                    item.totalAmount?.toFixed(2)}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                    fontSize: '11px',
                                  }}
                                >
                                  Total Expenses :
                                </strong>
                              </td>

                              <td>
                                <Typography fontSize='11px'>
                                  -
                                  {item.totalExpenses &&
                                    item.totalExpenses?.toFixed(2)}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </Typography>
                              </td>
                            </tr>

                            <hr />

                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                    fontSize: '11px',
                                  }}
                                >
                                  Gross Total :
                                </strong>
                              </td>

                              <td>
                                <Typography fontSize='11px'>
                                  {item.grossTotal &&
                                    item.grossTotal?.toFixed(2)}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>

                          <tbody
                            className='expansesBody'
                            style={{ float: 'left' }}
                          >
                            <tr
                              style={{
                                backgroundColor:
                                  this.state.selectedTemplate?.bgColor,
                              }}
                            >
                              <th>Expanses</th>
                            </tr>

                            {item.commission !== 0 ? (
                              <>
                                <tr>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Total commission :
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '11px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item.commission && item.commission}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {item?.freight !== 0 && item.freight !== null ? (
                              <>
                                <tr>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Total Freight :
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '11px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item?.freight &&
                                        item?.freight?.toFixed(2)}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {item.subGrowerSelfAmount !== 0 &&
                            item.subGrowerSelfAmount !== null ? (
                              <>
                                <tr style={{ margin: 0, padding: 0 }}>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Total Self Amount :
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '11px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item.selfAmount &&
                                        item.selfAmount?.toFixed(2)}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {item.association !== 0 &&
                            item.association !== null ? (
                              <>
                                <tr>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Association:
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '9px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item?.association &&
                                        item?.association?.toFixed(2)}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {item.postalCharges !== 0 &&
                            item?.postalCharges !== null ? (
                              <>
                                <tr>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Postal Charges :
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '11px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item.postalCharges &&
                                        item.postalCharges?.toFixed(2)}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {item?.labour !== 0 && item?.labour !== null ? (
                              <>
                                <tr>
                                  <td>
                                    <strong
                                      style={{
                                        color:
                                          this.state.selectedTemplate?.bgColor,
                                        fontSize: '8px',
                                      }}
                                    >
                                      Labour :
                                    </strong>
                                  </td>

                                  <td style={{ fontSize: '10px' }}>
                                    <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                      {item?.labour && item?.labour?.toFixed(2)}
                                      &nbsp;
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            <hr />
                            <tr>
                              <td>
                                <strong
                                  style={{
                                    color: this.state.selectedTemplate?.bgColor,
                                    fontSize: '8px',
                                  }}
                                >
                                  Total Expenses :
                                </strong>
                              </td>

                              <td style={{ fontSize: '11px' }}>
                                <Typography fontSize='9px' sx={{ mt: 0.5 }}>
                                  {item?.totalExpenses &&
                                    item?.totalExpenses?.toFixed(2)}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <hr />

                      <div
                        style={{
                          // margin: '5px',
                          paddingBottom: '1rem',
                          display: 'flex',
                          alignItems: 'flex-end',
                          flexDirection: 'column',
                        }}
                      >
                        <h6
                          style={{
                            marginRight: '18px',
                            color: this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          Signature
                        </h6>

                        {Validation.isBlankNot(this.state.signatureUrl) ? (
                          <img
                            style={{
                              maxWidth: '100px',
                              maxHeight: '80px',

                              margin: '5px',
                            }}
                            src={this.state.signatureUrl}
                            alt='Signature'
                          ></img>
                        ) : (
                          `Signature`
                        )}
                      </div>

                      {this.state?.storeDetails?.termsAndCondition !== '' ? (
                        <>
                          <div
                            className='footer_class'
                            style={{
                              backgroundImage: `url(${this.state.selectedTemplate?.bgImageFooter})`,
                              backgroundSize: 'cover',
                              border: '2px solid gray',
                              padding: '5px',
                            }}
                          >
                            <div>
                              <strong> {`Terms & Conditions:`}</strong>
                            </div>
                            <div
                              sx={{
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <h1 style={{ fontSize: '7px', display: 'flex' }}>
                                {this.state.storeDetails.termsAndCondition ? (
                                  <HTMLRenderer
                                    html={
                                      this.state.storeDetails.termsAndCondition
                                    }
                                  />
                                ) : (
                                  'Terms And Condition'
                                )}
                              </h1>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {item?.creationDate ? (
                        <span style={{ fontSize: '10px', textAlign: 'center' }}>
                          {`Invoice Date : `}
                          {moment
                            .utc(item?.creationDate)
                            .local()
                            .format('DD-MM-yyyy HH:mm:ss')}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default MultipleWatakInvoice;
