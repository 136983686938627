import UpstoreApi from '../UpstoreApi';

class Ledger {
  getLedgerForCustomer = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/ledger/customer/' + customerId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customers by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getCustomerBalanceByCustomerId = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/ledger/customer-balance/' + customerId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customer balance by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getLedgerForStatementByCustomerId = (
    customerId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/ledger/${customerId}/statement/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting ledger for customer',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getSumOfSelfAmountByVendorId = (
    VendorId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    let encodedurl = `/dashBoard/get-selfamount/${VendorId}/${startDate}/${endDate}?timeZone=${encodeURIComponent(
      timeZone
    )}`;

    console.log(encodedurl);
    UpstoreApi({
      method: 'GET',
      url: encodedurl,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting selfAmount for customer',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getExportLedgerStatementByStoreId = (
    storeId,
    startDate,
    endDate,
    type,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/ledger/${storeId}/statement-ledger/${startDate}/${endDate}?type=${type}&timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting ledger for customer',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getAllByStoreIdAndCreationDate = (
    storeId,
    startDate,
    endDate,
    istInd,
    lastInd,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/ledger/getAllByStoreIdAndCreationDate/${storeId}?startDate=${startDate}&endDate=${endDate}&startingIndex=${istInd}&maximumLoadingSize=${lastInd}&timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting ledger for customer',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getAllExportLedger = (
    storeId,
    startDate,
    endDate,
    type,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/ledger/${storeId}/statement-ledger/${startDate}/${endDate}?type=${type}&timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log('Error occure while getting ledger ', error.message);
        callBack({ status: 'error' });
      });
  };
}
export default new Ledger();
