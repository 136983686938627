import UpstoreApi from '../UpstoreApi';

class Bills {
  getBillByCustomerId = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/bill/customer/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting bill by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getBillByStoreOrderId = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/bill/store-order/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting bill by store order id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getBillById = (billId, timeZone, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/bill/${billId}/${timeZone}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log(
          'Error occure while getting bill by bill id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateBill = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/bill/update-bill/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating bill  ', error.message);
        callBack({ status: 'error' });
      });
  };

  getBillPayment = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/billPayment/payment/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting bill payment', error.message);
        callBack({ status: 'error' });
      });
  };

  saveBillPayment = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/billPayment',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while saving bill payment ', error.message);
        callBack({ status: 'error' });
      });
  };

  getBillByDate = (customerId, startDate, endDate, timeZone, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/bill/${customerId}/customer/${startDate}/${endDate}/${timeZone}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting bill payment', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteBillPayment = (id, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/billPayment/delete/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting bill payment ', error.message);
        callBack({ status: 'error' });
      });
  };
}

export default new Bills();
