import React from 'react';
import Header from '../../component/header/Header';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography, Box, TextField } from '@mui/material';
import AllVendors from '../../component/vendors/AllVendors';
import Vendor from '../../modules/Vendor';
import Auth from '../../modules/Auth';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { Grid, Button } from '@mui/material';
import { Loading } from '../../component/loading/Loading';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { debounce } from '../../utils';

function Vendors() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [buttonClick, setButtonClick] = React.useState('');
  const [activeVendor, setActiveVendor] = React.useState([]);
  const [inactiveVendor, setInactiveVendor] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    getVendorByStoreId(pageNumber);
  }, []);

  const getVendorByStoreId = pageNumber => {
    setLoader(true);
    Vendor.getAllVendorByStoreId(
      Auth.getStoreId(),
      pageNumber,
      true,
      response => {
        if (response.status === 'success') {
          let filterActive = response.data.filter(name => {
            return name.active === true && name.deleted === false;
          });
          let filterInActive = response.data.filter(name => {
            return name.active === false && name.deleted == false;
          });

          setInactiveVendor(filterInActive);
          setActiveVendor(filterActive);
          setVendors(response.data);
          setLoader(false);
        } else {
          console.log('error');
          setActiveVendor([]);
          setInactiveVendor([]);
        }
      }
    );
  };

  const editDialogCallBack = () => {
    setOpenDialog(true);
    setButtonClick('Edit');
  };

  const handleSearchVendor = data => {
    Vendor.searchVendorByStoreId(Auth.getStoreId(), data, response => {
      if (response.status === 'success') {
        let filteredSearchActive = response.data.filter(item => {
          return item.active === true;
        });

        let filteredSearchInActive = response.data.filter(item => {
          return item.active === false;
        });
        setActiveVendor(filteredSearchActive);
        setInactiveVendor(filteredSearchInActive);
      }
      if (response.status === 'error') {
        getVendorByStoreId(0);
      }
    });
  };

  //for preventing frequent api calls on  search

  const vendorChange = debounce(e => handleSearchVendor(e), 300);
  const handlePreviousPage = () => {
    let pageNum = pageNumber - 1;
    setPageNumber(pageNum);
    getVendorByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 1;
    setPageNumber(pageNum);
    getVendorByStoreId(pageNum);
  };

  return (
    <Box sx={{ mt: 8, ml: 7 }}>
      <Header />
      <Grid container columnSpacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', ml: 2, justifyContent: 'space-between' }}
        >
          <Box item md={4} xs={6}>
            {' '}
            <BreadCrumb pageName='Vendor List' />
          </Box>
          <Box md={4} xs={6}>
            <TextField
              sx={{ width: '400px' }}
              name='search'
              size='small'
              placeholder='Search Vendor  By  Vendor Name and Phone number'
              onChange={event => {
                vendorChange(event.target.value);
              }}
            />
          </Box>
          <Box md={4} xs={6}>
            {' '}
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                height: '27px',
                width: '126px',
                backgroundColor: '#3B78C9',
                marginLeft: 'auto',
                mr: 1,

                marginBottom: '15px',
              }}
              onClick={() => {
                setOpenDialog(true);
                setButtonClick('Add');
              }}
            >
              <Typography variant='h5' sx={{ fontSize: '14px' }} noWrap>
                Add vendor
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <BasicTabs
        activeVendor={activeVendor}
        inactiveVendor={inactiveVendor}
        openDialog={openDialog}
        buttonClick={buttonClick}
        close={() => {
          setOpenDialog(false);
        }}
        editDialogCallBack={editDialogCallBack}
        reloadVendorList={getVendorByStoreId}
        loader={loader}
      />
      <Grid
        style={{
          padding: '1.6vh',
          float: 'right',

          bottom: '0px',
        }}
      >
        <Button
          size='small'
          variant='outlined'
          disabled={pageNumber <= 0}
          onClick={handlePreviousPage}
          sx={{ color: '#3B78C9', variant: 'outlined' }}
        >
          Prev
        </Button>
        <Button
          size='small'
          variant='outlined'
          disabled={vendors.length < 20}
          sx={{ ml: 1, color: '#3B78C9' }}
          onClick={handleNextPage}
        >
          Next
        </Button>
      </Grid>

      {AllPermissions.getUserAccessiblePermissions('Vendor') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Vendor').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </Box>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs({
  activeVendor,
  inactiveVendor,
  openDialog,
  buttonClick,
  close,
  editDialogCallBack,
  reloadVendorList,
  loader,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='ant example'>
          <Tab label='Active Vendors' {...a11yProps(0)} centered />
          <Tab label='InActive Vendors' {...a11yProps(1)} centered />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AllVendors
          editDialogCallBack={editDialogCallBack}
          vendorList={activeVendor}
          openDialog={openDialog}
          buttonClick={buttonClick}
          close={close}
          reloadVendorList={reloadVendorList}
          loader={loader}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AllVendors
          editDialogCallBack={editDialogCallBack}
          vendorList={inactiveVendor}
          openDialog={openDialog}
          buttonClick={buttonClick}
          close={close}
          reloadVendorList={reloadVendorList}
          loader={loader}
          index={1}
        />
      </TabPanel>
    </Box>
  );
}

export default Vendors;
