import UpstoreApi from '../UpstoreApi';
class PopularSearch {
  getPopularSearches = (PopularSearchId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/popular-search/${PopularSearchId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
        return response.data;
      })
      .catch(err => {
        console.log(
          'Error occurred while getting popular searches',
          err.message
        );

        callback({ status: 'error' });
        return err;
      });
  };
  //getting popular search
  deletePopularSearch = (PopularSearchId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/popular-search/delete/${PopularSearchId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while deleting popular search',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  addPopularSearch = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/popular-search/',
      data: data,
    })
      .then(response => {
        if (response.data) {
          console.log(response.data, 'data response');
          callback({ status: 'success' });
        } else {
          callback({ status: 'error' });
        }
      })
      .catch(err => {
        console.log('Error occured while addding popular search', err.message);
        callback({ status: 'server error' });
      });
  };
}
export default new PopularSearch();
