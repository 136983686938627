import { getData, getError } from '../../utils';
import Auth from '../Auth';
import Configuration from '../Configuration';
import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';

class Store {
  constructor() {
    this.companyDetails =
      JSON.parse(localStorage.getItem('storeCompanyDetails')) || {};
  }

  getCurrency = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), () => {})?.currency;
  };

  getSelectedTaxType = () => {
    return this.companyDetails?.taxType;
  };
  getSelectedGstType = () => {
    return this.companyDetails?.gstType;
  };
  getGstNo = () => {
    return this.companyDetails?.gstNo;
  };
  getLutNo = () => {
    return this.companyDetails?.lut;
  };
  getCompanyDetails = () => {
    return this.companyDetails;
  };

  getStoreTimeZone = () => {
    let str = this.companyDetails?.timeZone;
    return str?.substring(str.indexOf('T')).substring(1);
  };

  setCompanyDetails = companyData => {
    this.companyDetails = { ...companyData };
    localStorage.setItem('storeCompanyDetails', JSON.stringify(companyData));
  };

  reloadCompanyDetails = (storeId, callBack) => {
    this.companyDetails = null;
    localStorage.removeItem('storeCompanyDetails');
    this.getStoreByStoreId(storeId, callBack);
  };

  //get store account details by storeid
  getStoreAccountsByStoreId = (storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/store-accounts/store/' + storeId,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(
          error,
          callBack,
          'Error occure while getting product items by productId'
        )
      );
  };

  getAccountStatementByBankId = (
    bankId,
    storeId,
    startDate,
    endDate,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/payments/bank-statement/${bankId}/${storeId}/between/${startDate}/${endDate}`,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(
          error,
          callBack,
          'Error occure while getting product items by productId'
        )
      );
  };
  //get currency details by storeid
  getStoreCurrency = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/currency',
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occured while getting currency')
      );
  };

  //get tax type //
  getStoreTaxType = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/tax-type',
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while getting tax-type')
      );
  };
  //get store by storeid

  getStoreByStoreId = (storeId, callBack) => {
    let storeDetails = this.getStoreDetailsFromCache();

    if (Validation.isBlankObjectNot(storeDetails)) {
      callBack({ status: 'success', data: storeDetails });
      return storeDetails;
    } else {
      UpstoreApi({
        method: 'GET',
        url: '/stores/' + storeId,
      })
        .then(response => {
          if (response.data) {
            this.setCompanyDetails({ ...response.data });
            Configuration.getDefaultConfigurationByBusinessType(
              this.companyDetails.businessType
            );
            callBack({ status: 'success', data: response.data });
            return response.data;
          }
        })
        .catch(error =>
          getError(
            error,
            callBack,
            'Error occure while getting store by storeId'
          )
        );
    }
  };

  getStoreDetailsFromCache = () => {
    let storeDetails = this.companyDetails;
    if (!Validation.isBlankObjectNot(storeDetails)) {
      storeDetails = localStorage.getItem('storeCompanyDetails');
      if (Validation.isBlankObjectNot(storeDetails)) {
        return JSON.parse(storeDetails);
      } else {
        return null;
      }
    } else {
      return storeDetails;
    }
  };

  //update company details like accounts
  updateStoreAccountDetails = (storeAccountId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/store-accounts/update/' + storeAccountId,
      data: data,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occured while updating Acc details')
      );
  };
  saveStoreAccounts = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/store-accounts',
      data: data,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(
          error,
          callBack,
          'Error occure while adding store accounts details'
        )
      );
  };
  updateStoreAccDetails = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/store-accounts/update/${id}`,
      data: data,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while updating store details')
      );
  };
  //update store details by id
  updateStoreDetails = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/stores/update-store/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          this.reloadCompanyDetails(Auth.getStoreId(), () => {
            callBack({ status: 'success', data: response.data });
          });
        }
      })
      .catch(error =>
        getError(error, callBack, 'Error occure while updating store details')
      );
  };
  getTaxType = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/gst-tax/all',
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while getting tax  Description')
      );
  };
  getSlabsByTaxType = (gstTaxId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/gst-slabs/${gstTaxId}/gst-tax`,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while getting slabs')
      );
  };
  //monitoringTools
  getMontringAccountDetails = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/stores/all/',
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while getting Store details')
      );
  };

  //updating new store
  addNewStore = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/stores',
      data: data,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while adding Store details')
      );
  };
  addCompanyImages = (imageFile, storeId, callBack) => {
    // let formData = new FormData();
    // formData.append('image', imageFile);

    UpstoreApi({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data', // do not forget this
      },
      url: `/stores/uploadImage/${storeId}`,
      data: imageFile,
    })
      .then(response => getData(response, callBack))
      .catch(error =>
        getError(error, callBack, 'Error occure while uploading image')
      );
  };

  isEditableDiscount = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.discountEditable;
    });
  };
  getStoreName = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.name;
    });
  };
  getStoreAddress = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.address;
    });
  };
  isEditableDueDate = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.daysForDueDate;
    });
  };
  isEditableStorePrice = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.storePriceEditable;
    });
  };
  isTermsAndConditions = () => {
    return this.getStoreByStoreId(Auth.getStoreId(), response => {
      return response.data.termsAndCondition;
    });
  };
}

export default new Store();
