import * as React from 'react';
import {
  Button,
  Tooltip,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
} from '@mui/material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function StatementDialog({
  openDialog,
  closeDialog,
  dateObjCallback,
}) {
  const date = new Date();
  const [value, setValue] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [value1, setValue1] = React.useState(new Date());

  const handleStatement = () => {
    let dateObj = {
      startDate: value,
      endDate: value1,
    };
    dateObjCallback(dateObj);
    closeDialog();
  };

  return (
    <div>
      <Dialog open={openDialog} maxWidth='sm' fullWidth>
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right-start '>
              <Close
                size='medium'
                onClick={closeDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <DialogTitle textAlign='center'>Generate Statement</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='start Date'
                value={value}
                inputFormat='dd/MM/yyyy'
                onChange={newValue => {
                  setValue(newValue);
                }}
                renderInput={params => (
                  <TextField
                    sx={{ width: '140px', height: '40px', mr: 2 }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                label='End Date'
                value={value1}
                onChange={newValue => {
                  setValue1(newValue);
                }}
                inputFormat='dd/MM/yyyy'
                renderInput={params => (
                  <TextField
                    sx={{ width: '140px', height: '60px' }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant='contained'
              sx={{
                p: 0,
                ml: 1,
                backgroundColor: '#3B78C9',
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                mt: 2,
              }}
              onClick={handleStatement}
            >
              <Typography
                sx={{ fontSize: '13px', textTransform: 'capitalize' }}
              >
                Generate Statement
              </Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: 'capitalize',
              width: '127px',
              width: '126px',
              height: '27px',
            }}
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
