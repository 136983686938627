import React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';

function SalesChart({ data }) {
  return (
    <>
      <Box
        sx={{
          width: { md: '650px', sm: '700px', xs: '600px' },
          height: '200px',
        }}
      >
        <Box>
          <Bar data={data} />
        </Box>
      </Box>
    </>
  );
}

export default SalesChart;
