import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#163EC1',
      main: '#3b78c9',

      light: '#F0F3FD',
      dark: '#22324D',
    },
    secondary: {
      // main: "#fff",
      main: '#797D88',
      light: '#E1EEF5 ',
    },
    error: {
      main: '#c22a1f',
    },
    success: {
      main: '#42ba96',
    },
  },
  typography: {
    h5: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '15px',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
});

export default theme;
