import React, { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Tooltip,
  InputLabel,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import Categories from '../../modules/Categories';
import Product from '../../modules/Product';
import Auth from '../../modules/Auth';
import Store from '../../modules/Store';
import TaxCalculation from '../../modules/TaxCalculation';
import HTMLRenderer from 'react-html-renderer';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const AddNewProduct = ({
  openDialog,
  closeDialog,
  snackbar,
  selectedCategoryId,
  GetUpdatedProductsList,
}) => {
  // for styling
  const textField = {
    width: '100%',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [taxData, setTaxData] = React.useState({});
  const [unitsData, setUnitsData] = React.useState([]);
  const [commissionPak, setCommissionPak] = React.useState({
    commission: '',
  });
  const [categoryDataList, setCategoryDataList] = React.useState([]);
  const [units, setUnits] = React.useState({
    default: '',
    itemUnit: '',
  });
  const [currency, setCurrency] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  const [barcode, setBarcode] = React.useState('');
  const [slabDetails, setSlabDetails] = React.useState([]);
  React.useEffect(() => {
    getCategoriesByStoreId();
    getCurrency();

    getSlabByTaxType();
    Product.getAllUnits(response => {
      if (response.status === 'success') {
        setUnitsData(response.data);
      } else {
        setUnitsData([]);
      }
    });
  }, []);

  const getCurrency = () => {
    let newCurr = Store.getCurrency();
    setCurrency(newCurr);
  };

  const getTaxData = (type, id) => {
    if (type === 'gst') {
      TaxCalculation.getGstByProductIdAndCategoryId(id, id, response => {
        if (response.status === 'success') {
          setTaxData(response?.data);
        } else if (response.status === 'noData') {
          setTaxData({});
        } else {
          setTaxData({});
        }
      });
    } else if (type === 'vat') {
      TaxCalculation.getVatByProductIdAndCategoryId(id, id, response => {
        if (response.status === 'success') {
          setTaxData(response?.data);
        } else if (response.status === 'noData') {
          setTaxData({});
        } else {
          setTaxData({});
        }
      });
    }
  };

  const taxFunction = id => {
    // tax related
    if (!Store.getSelectedTaxType()) return;
    else if (Store.getSelectedTaxType() === 'GST') getTaxData('gst', id);
    else getTaxData('vat', id);
  };

  const closePopupDialog = () => {
    closeDialog();
  };
  const getSlabByTaxType = () => {
    Store.getSlabsByTaxType(Store.getSelectedGstType(), response => {
      if (response.status === 'success') {
        setSlabDetails(response.data);
      } else {
        setSlabDetails([]);
      }
    });
  };
  const getCategoriesByStoreId = () => {
    Categories.getCategoryByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setCategoryDataList(response.data);
        taxFunction(
          !selectedCategoryId
            ? Categories.getDefaultCategoryId()
            : selectedCategoryId
        );
      } else {
        setCategoryDataList([]);
      }
    });
  };
  const handleUnitChange = event => {
    setUnits({
      default: event.target.value,
      itemUnit: event.target.value,
    });
  };

  const handleInputChange = event => {
    let { name, value } = event.target;
    if (name === 'vat') {
      setTaxData({ ...taxData, [name]: value });
    } else if (name === 'slabs') {
      if (value === 'n/a') {
        setTaxData({ ...taxData, gst: 0 });
      } else {
        setTaxData({ ...taxData, gst: value });
      }
    } else if (name === 'commission') {
      setCommissionPak({ ...commissionPak, commission: value });
    }
    // } else {
    //   let total = null;
    //   if (!taxData.cgst) {
    //   } else {
    //     total = +value + +taxData.cgst;
    //   }
    //   setTaxData({ ...taxData, [name]: value, gst: total });
    // }
  };

  const dialogClosingFunction = (data, param) => {
    snackbar(`Product ${param} successfully`, 'success');
    closeDialog();
    GetUpdatedProductsList(data.categoryId, 0);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent function execution on Enter key
    }
  };

  const handleBarcodeInput = event => {
    if (event.key === 'Enter') {
      setBarcode(event.target.value);
      // event.target.value = '';
    }
  };

  // func for adding new product data
  const handleAddProductData = data => {
    disableButton();
    let productData = {};
    productData.name = data.name;
    productData.categoryId = data.categoryId;
    productData.createdBy = Auth.getUserLoginId();
    productData.storeId = Auth.getStoreId();
    productData.available = true;
    productData.unit = units.default;
    productData.inStock = true;
    productData.commission = parseInt(commissionPak.commission);

    productData.stock = data.stock === '' ? 5 : +data.stock;
    productData.productItems = [
      {
        itemName: data.name,
        // barcode: barcode.substring(0, barcode.length - 1),
        barcode: barcode,

        categoryId: data.categoryId,
        storeId: Auth.getStoreId(),
        createdBy: Auth.getUserLoginId(),
        itemAvailable: true,
        mrp: data.mrp,
        basePrice: data.basePrice,
        discount: data.discount === '' ? 0 : data.discount,
        unit: units.itemUnit,
        stock: data.stock === '' ? 5 : +data.stock,
        quantity: data.quantity,
        storePrice: data.storePrice,
        basePriceDiscount:
          data.basePriceDiscount === '' ? 0 : data.basePriceDiscount,
      },
    ];

    Product.addNewProduct(productData, response => {
      if (response.status === 'success') {
        if (taxData.gst || taxData.vat) {
          if (!Store.getSelectedTaxType()) {
            dialogClosingFunction(data, 'added');
          } else if (Store.getSelectedTaxType() === 'GST') {
            let postData = {
              ...taxData,
              id: undefined,
              taxableItemId: response?.data?.productId,
              storeId: Auth.getStoreId(),
            };
            TaxCalculation.AddGstTaxData(postData, response => {
              if (response.status === 'success') {
                dialogClosingFunction(data, 'added');
              } else {
                snackbar(`error while adding tax`, 'error');
              }
            });
          } else {
            let postData = {
              ...taxData,
              taxableItemId: response?.data?.productId,
              storeId: Auth.getStoreId(),
            };
            TaxCalculation.AddVatTaxData(postData, response => {
              if (response.status === 'success') {
                dialogClosingFunction(data, 'added');
              } else {
                snackbar(`error while adding tax`, 'error');
              }
            });
          }
        } else {
          dialogClosingFunction(data, 'added');
        }
      } else if ((response.data = 416)) {
        snackbar(`Limit exceeded please upgrade your plan`, 'error');
      } else {
        snackbar('Product not added successfully', 'error');
      }
    });
  };

  const disableButton = () => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 5000);
  };

  return (
    <Dialog open={openDialog} maxWidth='sm'>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h5' noWrap='true'>
            Add Product Here
          </Typography>
          <Tooltip placement='right-start' title='close'>
            <Close
              onClick={closePopupDialog}
              fontSize='medium'
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </Box>
      </DialogTitle>
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit(handleAddProductData)}
        onKeyDown={handleKeyDown}
      >
        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant='subtitle1'>Product Name</Typography>
              <TextField
                size='small'
                placeholder='Enter Product Name'
                {...register('name', {
                  required: 'Product name is required.',
                })}
                fullWidth={true}
                autoComplete='off'
                name='name'
                type='text'
                id='productName'
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item lg={6} xs={6}>
              <Typography sx={{ p: '5px' }} variant='subtitle1'>
                Default Unit
              </Typography>
              <FormControl sx={{ pt: '8px', width: '100%' }}>
                <InputLabel id='defaultunit'>Select Default Unit</InputLabel>
                <Select
                  {...register('defaultunit', {
                    required: 'Default Unit is required',
                  })}
                  size='small'
                  placeholder='Select default unit'
                  name='defaultunit'
                  labelId='defaultunit'
                  value={units.default}
                  onChange={handleUnitChange}
                  id='defaultunit'
                  error={Boolean(errors.defaultunit)}
                  helperText={errors.defaultunit?.message}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={undefined}>
                    <em style={{ color: 'gray' }}>Select</em>
                  </MenuItem>
                  {unitsData?.map(i => {
                    return (
                      <MenuItem key={i.id} value={i.unit}>
                        {`${i.unitName}-${i.unit}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText sx={{ color: '#c22a1f' }}>
                  {errors.defaultunit?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ m: '5px' }} variant='subtitle1'>
                Category Name
              </Typography>
              <FormControl sx={{ pt: '8px', width: '100%' }}>
                <Select
                  size='small'
                  defaultValue={
                    !selectedCategoryId
                      ? Categories.getDefaultCategoryId()
                      : selectedCategoryId
                  }
                  displayEmpty
                  autoComplete='off'
                  name='categoryId'
                  placeholder='Select'
                  MenuProps={MenuProps}
                  {...register('categoryId', {
                    required: 'Category name is required.',
                  })}
                  error={Boolean(errors.categoryId)}
                >
                  <MenuItem disabled value={undefined}>
                    <em style={{ color: 'gray' }}>Select</em>
                  </MenuItem>
                  {categoryDataList?.map(item => (
                    <MenuItem
                      onClick={() => taxFunction(item?.categoryId)}
                      key={item.categoryId}
                      value={item.categoryId}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: '#c22a1f' }}>
                  {errors.categoryId?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            {Store.companyDetails?.businessType !== 'STORE' && (
              <Grid item lg={4} xs={6}>
                <Typography variant='subtitle1'>M.R.P</Typography>
                <TextField
                  {...register('mrp', {
                    pattern: {
                      value: /^\d+(\.\d{1,2})?$/,
                      message: 'Please enter a number',
                    },
                  })}
                  sx={textField}
                  id='mrp'
                  size='small'
                  autoComplete='off'
                  placeholder='Enter Mrp'
                  variant='outlined'
                  name='mrp'
                  error={Boolean(errors.mrp)}
                  helperText={errors.mrp?.message}
                />
              </Grid>
            )}
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>Base Price</Typography>
              <TextField
                {...register('basePrice', {
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: 'Please enter a number',
                  },
                })}
                sx={textField}
                id='basePrice'
                autoComplete='off'
                size='small'
                placeholder='Enter Mrp'
                variant='outlined'
                name='basePrice'
                error={Boolean(errors.basePrice)}
                helperText={errors.basePrice?.message}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1' sx={{ fontSize: '15px' }} noWrap>
                Base Discount (%/
                {currency ? <HTMLRenderer html={currency} /> : null})
              </Typography>
              <TextField
                {...register('basePriceDiscount')}
                sx={textField}
                id='basePriceDiscount'
                size='small'
                type='text'
                autoComplete='off'
                placeholder='Enter Base Discount'
                variant='outlined'
                name='basePriceDiscount'
                error={Boolean(errors.basePriceDiscount)}
                helperText={errors.basePriceDiscount?.message}
              />
            </Grid>

            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>Unit</Typography>
              <FormControl sx={textField}>
                <Select
                  size='small'
                  displayEmpty
                  placeholder='Select'
                  name='unit'
                  id='unit'
                  value={units.itemUnit}
                  MenuProps={MenuProps}
                  onChange={event =>
                    setUnits({
                      ...units,
                      itemUnit: event.target.value,
                    })
                  }
                >
                  <MenuItem disabled value={undefined}>
                    <em style={{ color: 'gray' }}>Select</em>
                  </MenuItem>
                  {unitsData?.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.unit}>
                        {`${item.unitName}-${item.unit}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText sx={{ color: '#c22a1f' }}>
                  {errors.unit?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>
                {' '}
                {Store.companyDetails?.businessType !== 'STORE'
                  ? 'Store Price *'
                  : 'M.R.P *'}
              </Typography>
              <TextField
                {...register('storePrice', {
                  required:
                    Store.companyDetails?.businessType !== 'STORE'
                      ? 'Store price is required *'
                      : 'M.R.P is required',
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: 'Please enter a number',
                  },
                })}
                sx={textField}
                id='storePrice'
                size='small'
                autoComplete='off'
                placeholder={
                  Store.companyDetails?.businessType !== 'STORE'
                    ? ' Enter Store price'
                    : ' Enter MRP'
                }
                variant='outlined'
                name='storePrice'
                error={Boolean(errors.storePrice)}
                helperText={errors.storePrice?.message}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>Quantity</Typography>
              <TextField
                {...register('quantity', {
                  required: 'Quantity is required',
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: 'Please enter a number',
                  },
                })}
                sx={textField}
                id='quantity'
                size='small'
                autoComplete='off'
                placeholder='Enter Quantity '
                variant='outlined'
                name='quantity'
                error={Boolean(errors.quantity)}
                helperText={errors.quantity?.message}
              />
            </Grid>

            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>
                Discount (%/{currency ? <HTMLRenderer html={currency} /> : null}
                )
              </Typography>
              <TextField
                {...register('discount')}
                sx={textField}
                id='discount'
                size='small'
                type='text'
                autoComplete='off'
                placeholder='Enter Discount'
                variant='outlined'
                name='discount'
                error={Boolean(errors.discount)}
                helperText={errors.discount?.message}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1'>Stock</Typography>
              <TextField
                {...register('stock')}
                sx={textField}
                id='stock'
                size='small'
                type='text'
                autoComplete='off'
                placeholder='Enter stock'
                variant='outlined'
                name='stock'
                error={Boolean(errors.stock)}
                helperText={errors.stock?.message}
              />
            </Grid>

            {Store.getSelectedTaxType() &&
            Store.getSelectedTaxType() !== 'N/A' ? (
              <>
                {Store.getSelectedTaxType() === 'GST' ? (
                  <>
                    <Grid item xs={6} sm={4}>
                      <Typography variant='subtitle1'>GST Slabs</Typography>
                      <TextField
                        sx={textField}
                        size='small'
                        id='slabs'
                        select
                        type='number'
                        fullWidth
                        placeholder='Enter slabs'
                        variant='outlined'
                        name='slabs'
                        value={taxData?.gst}
                        onChange={handleInputChange}
                      >
                        {!Store.getLutNo() ? (
                          slabDetails.map(option => (
                            <MenuItem key={option.id} value={option.slabs}>
                              {option.slabs}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={1} value={0}>
                            0
                          </MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <Typography variant='subtitle1'>Total GST %</Typography>
                      <TextField
                        sx={textField}
                        size='small'
                        id='gst'
                        autoComplete='off'
                        placeholder='Enter gst'
                        variant='outlined'
                        name='gst'
                        type='number'
                        disabled='true'
                        value={taxData?.gst}
                      />
                    </Grid>
                    {Store.companyDetails?.businessType === 'MANDI' && (
                      <Grid item xs={6} lg={4}>
                        <Typography variant='subtitle1'>
                          Enter Commission{' '}
                        </Typography>
                        <TextField
                          sx={textField}
                          size='small'
                          id='commission'
                          type='number'
                          autoComplete='off'
                          placeholder='Enter commission'
                          variant='outlined'
                          name='commission'
                          // value={taxData?.sgst}
                          onChange={handleInputChange}
                          // error={Boolean(errors.sgst)}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item xs={6} lg={4}>
                    <Typography variant='subtitle1'>Vat %</Typography>
                    <TextField
                      sx={textField}
                      size='small'
                      id='vat'
                      autoComplete='off'
                      placeholder='Enter vat %'
                      variant='outlined'
                      name='vat'
                      type='number'
                      value={taxData?.vat}
                      onChange={handleInputChange}
                      error={Boolean(errors.vat)}
                    />
                  </Grid>
                )}
              </>
            ) : null}
            <Grid item lg={4} xs={6}>
              <Typography variant='subtitle1' noWrap>
                Barcode
              </Typography>
              <TextField
                {...register('barcode')}
                sx={textField}
                id='barcode'
                size='small'
                type='text'
                autoComplete='off'
                placeholder='Barcode'
                variant='outlined'
                name='barcode'
                error={Boolean(errors.barcode)}
                helperText={errors.barcode?.message}
                onKeyDown={handleBarcodeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closePopupDialog}
            variant='outlined'
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            disabled={disable}
            sx={{
              bgcolor: '#3B78C9',
              width: '126px',
              height: '27px',

              textTransform: 'capitalize',
            }}
          >
            Add Product
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddNewProduct;
