import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Grid,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ErrorDialog() {
  const [open, setOpen] = useState(true);

  let navigateTo = useNavigate();
  return (
    <div>
      <Grid container>
        <Dialog
          open={open}
          maxWidth='md'
          sx={{
            backdropFilter: 'blur(2rem)',
            backgroundColor: 'rgba(0,0,30,0.4)',
          }}
        >
          <Grid item xs={12} style={{ padding: '2rem 2rem 0 2rem' }}>
            <DialogTitle>
              <Typography
                variant='h6'
                style={{ fontWeight: 'bold', color: '#666' }}
                align='center'
              >
                User Restricted
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={12} style={{ padding: ' 0 1rem 1rem 2rem' }}>
            <DialogContent>
              <Typography variant='subtitle1' style={{ color: 'gray' }}>
                Sorry! your role does'nt have access to the page please contact
                Admin for further queries...
              </Typography>
            </DialogContent>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: '#d0dae8' }}>
            <DialogActions>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setOpen(false);
                  navigateTo('/');
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </Grid>
    </div>
  );
}
