import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PrintIcon from '@mui/icons-material/Print';

import ReactToPrint from 'react-to-print';
import './styles.css';
import Ledger from '../../modules/Ledger/index';
import Logo from '../../modules/Logo';
import { Typography, Box, Button } from '@mui/material';
import Customer from '../../modules/Customer';
import Vendor from '../../modules/Vendor';
import Validation from '../../modules/Validation';
import * as moment from 'moment';
import StatementDialog from '../StatementDialog/StatementDialog';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import HTMLRenderer from 'react-html-renderer';

// for date

const ref = React.createRef();
const newDate = new Date();

class VendorStatement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDetails: this.props.vendorObj,
      customerList: [],
      payments: [],
      ledger: [],
      logoUrl: null,
      options: {
        unit: 'pt',
        format: [900, 900],
      },
      currency: null,
      customer: {},
      Vendor: {},
      customerBalance: {},
      customerData: {},
      open: false,
      storeDetails: {},
      sumOfSelfAmount: 0,
      date: {
        startDate: Store.getStoreByStoreId(Auth.getStoreId(), () => {})
          .annualStartDate,
        endDate: new Date(),
      },
      dateSelected: false,
    };
  }

  componentDidMount = () => {
    let id = this.state.orderDetails;
    this.customerBalance();
    this.ledger('mount');
    this.getLogoUrl(Auth.getStoreId());
    this.vendor();
    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });
    this.getCurrency();
    this.getSumOfSelfAmount();
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.getUpdatedLedger !== this.props.getUpdatedLedger &&
      this.props.getUpdatedLedger === true
    ) {
      this.ledger();
      this.customerBalance();
    }
  }

  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };
  getLogoUrl = () => {
    this.setState({
      logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
    });
  };
  getUpdatedDateObj = obj => {
    this.setState({ dateSelected: true });
    this.setState(
      {
        date: {
          startDate: obj.startDate.toString(),
          endDate: obj.endDate.toString(),
        },
      },
      () => {
        this.ledger();
        this.getSumOfSelfAmount();
      }
    );
  };

  ledger = param => {
    let istDate = moment(this.state.date.startDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    let lastDate = moment(this.state.date.endDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    Ledger.getLedgerForStatementByCustomerId(
      this.props.vendorObj,
      istDate,
      lastDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          this.getPDFHeightAndWidth();
          this.setState({ ledger: [...response.data] });
        } else {
          this.setState({ ledger: [] });
        }
      }
    );

    Customer.getDataForCustomerByCreationDate(
      this.props.vendorObj,
      istDate,
      lastDate,
      Store.getStoreTimeZone(),

      response => {
        if (response.status === 'success') {
          this.setState({ customerData: response.data });
        } else {
          this.setState({ customerData: {} });
        }
      }
    );
  };
  vendor = () => {
    Vendor.getVendorByVendorId(this.props.vendorObj, response => {
      if (response.status === 'success') {
        this.setState({ Vendor: response.data });
      } else {
        this.setState({ Vendor: {} });
      }
    });
  };

  customerBalance = () => {
    let customerId = { customerIds: [this.props.vendorObj] };
    if (customerId) {
      Customer.getCustomerBalance(customerId, response => {
        if (response.status === 'success') {
          this.setState({ customerBalance: { ...response.data[0] } });
        } else {
          this.setState({ customerBalance: {} });
        }
      });
    }
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint').clientHeight;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      this.setState({ options: opt });
    }, 5);
  };
  packAndSelfAmount = item => {
    let newPack = JSON.parse(item);
    return newPack.map(item => {
      for (const [key, value] of Object.entries(item)) {
        return (
          <>
            <Typography variant='subtitle1' fontSize='11px'>
              {key}: {value}
            </Typography>
          </>
        );
      }
    });
  };

  getSumOfSelfAmount = () => {
    let istDate = moment(this.state.date.startDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    let lastDate = moment(this.state.date.endDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    Ledger.getSumOfSelfAmountByVendorId(
      this.props.vendorObj,
      istDate,
      lastDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          this.setState({ sumOfSelfAmount: response.data });
        } else {
          this.setState({ sumOfSelfAmount: 0 });
        }
      }
    );
  };
  render() {
    return (
      <div className='App container'>
        <Box sx={{ display: 'flex' }}>
          <ReactToPrint
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            filename={`receipt__${this.state.orderDetails.storeOrderNumber}.pdf`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',

                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon sx={{ color: 'white' }} /> Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
          <Button
            variant='contained'
            sx={{
              textTransform: 'capitalize',
              p: 0,
              width: '126px',
              height: '27px',
              backgroundColor: '#3B78C9',
              marginLeft: 'auto',
            }}
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            <Typography variant='h5' sx={{ fontSize: '12px' }} noWrap>
              Generate Statement
            </Typography>
          </Button>
        </Box>
        <br />
        <div className='divToDownload' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100%' }}
          >
            <div
              class='row d-flex justify-content-center'
              style={{
                overflowX: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              <div class='col-12'>
                <div class='card'>
                  <div class='d-flex flex-row p-2'>
                    <div class='d-flex flex-column'>
                      {' '}
                      <div style={{ display: 'flex' }}>
                        <h1 style={{ fontSize: '20px' }}>Statement</h1>{' '}
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class='table-responsive p-2'>
                    <table class='table table-borderless'>
                      <tbody>
                        <tr class='add'>
                          <th>
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>From</span>
                            <br />
                            {`${this.state.storeDetails.name}`}
                            <br />
                            {`${this.state.storeDetails.address}`}
                            <br />
                            <span style={{ fontWeight: 'bold' }}>To </span>
                            <br />
                            {this.state.Vendor.name}
                            <br />
                            {this.state.Vendor.address}
                            <br />
                          </th>
                          <th>
                            Date From :{' '}
                            <span>
                              {!this.state.dateSelected
                                ? moment(
                                    Store.getStoreByStoreId(
                                      Auth.getStoreId(),
                                      () => {}
                                    ).annualStartDate
                                  ).format('DD-MM-YYYY')
                                : moment(this.state.date.startDate).format(
                                    'DD-MM-YYYY'
                                  )}
                              &nbsp; To &nbsp;
                              {moment(this.state.date.endDate).format(
                                'DD-MM-YYYY'
                              )}
                            </span>
                            <br />
                            Opening Balance :{' '}
                            <span>
                              {Validation.isBlankObjectKeyNot(
                                this.state.ledger[0],
                                'balance'
                              )
                                ? this.state.ledger[0].balance
                                : '-'}
                              &nbsp;
                              {this.state.currency ? (
                                <HTMLRenderer html={this.state.currency} />
                              ) : null}
                            </span>
                            <br />
                            Payments :{' '}
                            <span>
                              {Validation.isBlankObjectKeyNot(
                                this.state.customerData,
                                'paymentAmount'
                              )
                                ? this.state.customerData.paymentAmount
                                : '-'}{' '}
                              &nbsp;
                              {this.state.currency ? (
                                <HTMLRenderer html={this.state.currency} />
                              ) : null}
                            </span>
                            <br />
                            Self Amount :{' '}
                            <span style={{ fontSize: '12px' }}>
                              {this.state.sumOfSelfAmount &&
                                this.state.sumOfSelfAmount}
                              &nbsp;
                              {this.state.currency ? (
                                <HTMLRenderer html={this.state.currency} />
                              ) : null}
                            </span>
                          </th>
                        </tr>
                        <tr class='content'>
                          <td class='font-weight-bold'></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div>
                    <div
                      style={{
                        textAlign: 'center',
                        paddingRight: '4rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      <h1 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        Balance Due :{' '}
                        <span>
                          {Validation.isBlankObjectKeyNot(
                            this.state.customerBalance,
                            'balanceAmount'
                          )
                            ? this.state.customerBalance.balanceAmount
                            : 'N/A'}
                          &nbsp;
                          {this.state.currency ? (
                            <HTMLRenderer html={this.state.currency} />
                          ) : null}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <hr />
                  <div
                    class='products p-2'
                    style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
                  >
                    <table class='table'>
                      <tbody>
                        <tr class='add'>
                          <th>Date</th>
                          <th>Particulars</th>
                          {Store.companyDetails?.businessType == 'MANDI' && (
                            <>
                              <th>Packing</th>
                              <th>Self Amount</th>
                            </>
                          )}
                          <th>Amount (Credit)</th>
                          <th class='text-center'>Payments (Debit)</th>
                          <th>Balance</th>
                        </tr>
                        <tr class='bal'>
                          <th
                            colSpan={
                              Store.companyDetails?.businessType == 'MANDI'
                                ? 5
                                : 3
                            }
                            style={{
                              backgroundColor: ' rgb(165, 158, 158)',
                            }}
                          >
                            Opening Balance{' '}
                          </th>
                          <th
                            colSpan={1}
                            style={{
                              backgroundColor: ' rgb(165, 158, 158)',
                            }}
                          >
                            {' '}
                          </th>

                          <th
                            colSpan={1}
                            style={{
                              backgroundColor: ' rgb(165, 158, 158)',
                            }}
                          >
                            {' '}
                            {Validation.isBlankObjectKeyNot(
                              this.state.ledger[0],
                              'balance'
                            )
                              ? this.state.ledger[0].balance
                              : '-'}
                            &nbsp;
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </th>
                        </tr>
                        {this.state.ledger !== undefined &&
                        this.state.ledger !== null
                          ? this.state.ledger.map(item => {
                              return (
                                <tr class='content' key={item.id}>
                                  <td
                                    style={{
                                      maxWidth: '100px',
                                      whiteSpace: 'initial',
                                    }}
                                  >
                                    {item.activityDate !== null &&
                                    item.activityDate !== undefined
                                      ? new Date(item.activityDate + 'Z')
                                          .toString()
                                          .substring(0, 24) &&
                                        new Date(item.activityDate + 'Z')
                                          .toString()
                                          .substring(0, 24)
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      maxWidth: '80px',
                                      whiteSpace: 'initial',
                                    }}
                                  >
                                    {Store.companyDetails?.businessType ==
                                      'MANDI' && item?.packing
                                      ? `Watak No    ${
                                          (item.comment && item.comment) || '-'
                                        }`
                                      : (item.comment && item.comment) || '-'}
                                  </td>
                                  {Store.companyDetails?.businessType ==
                                    'MANDI' && (
                                    <>
                                      <td>
                                        {item?.packing
                                          ? this.packAndSelfAmount(item.packing)
                                          : '_'}
                                      </td>
                                      <td>
                                        {(item.totalSelfAmount &&
                                          item.totalSelfAmount) ||
                                          '-'}
                                        &nbsp;
                                        {item.totalSelfAmount &&
                                        this.state.currency ? (
                                          <HTMLRenderer
                                            html={this.state.currency}
                                          />
                                        ) : null}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    {(item.crAmount && item.crAmount) || '-'}{' '}
                                    &nbsp;
                                    {item.crAmount && this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                  <td class='text-center'>
                                    {(item.drAmount && item.drAmount) || '-'}{' '}
                                    &nbsp;
                                    {item.drAmount && this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                  <td>
                                    {item.balance}&nbsp;
                                    {item.balance && this.state.currency ? (
                                      <HTMLRenderer
                                        html={this.state.currency}
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div>
                    <div
                      style={{
                        textAlign: 'right',
                        paddingRight: '4rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      <h1 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        Balance Due :{' '}
                        <span>
                          {' '}
                          {Validation.isBlankObjectKeyNot(
                            this.state.customerBalance,
                            'balanceAmount'
                          )
                            ? this.state.customerBalance.balanceAmount
                            : 'N/A'}{' '}
                          &nbsp;{' '}
                          {this.state.customerBalance.balanceAmount &&
                          this.state.currency ? (
                            <HTMLRenderer html={this.state.currency} />
                          ) : null}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StatementDialog
          dateObjCallback={this.getUpdatedDateObj}
          openDialog={this.state.open}
          closeDialog={() => {
            this.setState({ open: false });
          }}
        />
      </div>
    );
  }
}
export default VendorStatement;
