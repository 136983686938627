import React from 'react';
import './PageNotFound.css';
import { Link } from 'react-router-dom';
function PageNotFound() {
  return (
    <div style={{ height: '100vh', backgroundColor: '#e1ebfa' }}>
      <div className="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Page not found">Opps! Page not found</h4>
          <p>Sorry, the page you're looking for doesn't exist...</p>
          <div className="btns">
            <Link to={'/'}>Return To Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
