import React, { Component } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  Tooltip,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Product from '../../modules/Product';
import Auth from '../../modules/Auth';
import { Close } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import Store from '../../modules/Store';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddNewProductItem = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [unitsData, setUnitsData] = React.useState([]);
  const [productSubmit, setProductSubmit] = React.useState(false);
  const [defaultPackage, setDefaultPackage] = React.useState([]);
  const [packageValue, setPackageValue] = React.useState('');
  const [barcode, setBarcode] = React.useState('');
  React.useEffect(() => {
    Product.getAllUnits(response => {
      if (response.status === 'success') {
        setUnitsData(response.data);
      } else {
        setUnitsData([]);
      }
    });
  }, []);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent function execution on Enter key
    }
  };

  const handleBarcodeInput = event => {
    if (event.key === 'Enter') {
      setBarcode(event.target.value);
      // event.target.value = '';
    }
  };
  const closePopupDialog = () => {
    props.closePopup();
  };

  // for custom styling
  const textField = {
    width: '100%',
    bgcolor: 'secondary.light',
    borderTop: '5px solid #E1EEF5',
  };

  const handleAddProductItem = data => {
    setProductSubmit(true);
    let productItemData = data;
    productItemData.categoryId = props.categoryId;
    productItemData.productId = props.productId;
    productItemData.createdBy = Auth.getUserLoginId();
    productItemData.storeId = Auth.getStoreId();
    productItemData.quantity = +data.quantity;
    productItemData.storePrice = +data.storePrice;
    productItemData.basePrice = +data.basePrice;
    productItemData.stock = data.stock === '' ? 5 : +data.stock;
    productItemData.mrp = +data.mrp;
    productItemData.discount = data.discount === '' ? 0 : +data.discount;
    productItemData.basePriceDiscount =
      data.basePriceDiscount === '' ? 0 : data.basePriceDiscount;
    productItemData.itemDescription =
      data.itemDescription === undefined ? '' : data.itemDescription;
    // productItemData.barcode = barcode.substring(0, barcode.length - 1);
    productItemData.barcode = barcode;

    Product.addNewProductItem(productItemData, response => {
      if (response.status === 'success') {
        setProductSubmit(false);
        props.snackbar('item was added successfully', 'success');
        closePopupDialog();
        // conditionaly reloading page
        if (props.ShouldPageReload === true) {
          // window.location.reload(false);
          props.getNewlyFetchedProducts(response.data?.productId, true);
        }
        // checking if getNewlyAddedItemCallback is sent as props or not
        if (props?.getNewlyAddedItemCallback) {
          props.getNewlyAddedItemCallback(response.data);
        }
      } else if ((response.data = 416)) {
        props.snackbar(`Limit exceeded please upgrade your plan`, 'error');
        setProductSubmit(false);
      } else {
        props.snackbar('item was not added successfully', 'error');
        setProductSubmit(false);
      }
    });
  };

  return (
    <>
      <Dialog open={props.openDialog} maxWidth='md'>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h4' fontSize='22' textAlign='center'>
              Add Product Item
            </Typography>
            <Tooltip placement='right-start' title='close'>
              <Close
                onClick={closePopupDialog}
                fontSize='medium'
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Box>
        </DialogTitle>

        <form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit(handleAddProductItem)}
          onKeyDown={handleKeyDown}
        >
          <DialogContent>
            <Grid container columnSpacing={4} rowSpacing={1}>
              <Grid item sm={6} xs={12}>
                <Typography variant='subtitle1'>Product Item Name *</Typography>
                <TextField
                  autoComplete='off'
                  {...register('itemName', {
                    required: 'Item name is required.',
                  })}
                  sx={textField}
                  size='small'
                  id='itemName'
                  placeholder='Enter Item Name'
                  variant='outlined'
                  name='itemName'
                  error={Boolean(errors.itemName)}
                  helperText={errors.itemName?.message}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Typography variant='subtitle1'>Brand</Typography>
                <TextField
                  autoComplete='off'
                  sx={textField}
                  {...register('brand')}
                  id='brand'
                  size='small'
                  placeholder='Enter Brand Name'
                  variant='outlined'
                  name='brand'
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant='subtitle1'>Description </Typography>
                <TextField
                  autoComplete='off'
                  sx={textField}
                  {...register('itemDescription')}
                  multiline
                  rows={6}
                  id='itemDescription'
                  size='small'
                  placeholder='Enter Description'
                  variant='outlined'
                  name='itemDescription'
                  inputProps={{ maxLength: 45 }}
                  error={Boolean(errors.itemDescription)}
                  helperText={errors.itemDescription?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Available *</Typography>
                    <FormControl sx={textField}>
                      <Select
                        size='small'
                        defaultValue='true'
                        name='available'
                        {...register('itemAvailable', {
                          required: 'Available is required.',
                        })}
                        displayEmpty
                        placeholder='Select'
                        error={Boolean(errors.itemAvailable)}
                      >
                        <MenuItem disabled value={undefined}>
                          <em style={{ color: 'gray' }}>Select</em>
                        </MenuItem>
                        <MenuItem value='true'>True</MenuItem>
                        <MenuItem value='false'>False</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: '#c22a1f' }}>
                        {errors.itemAvailable?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Unit *</Typography>
                    <FormControl sx={textField}>
                      <Select
                        {...register('unit', {
                          required: 'Unit is required',
                        })}
                        defaultValue='(Kg)'
                        size='small'
                        displayEmpty
                        placeholder='Select'
                        name='unit'
                        id='unit'
                        error={Boolean(errors.unit)}
                        MenuProps={MenuProps}
                      >
                        <MenuItem disabled value={undefined}>
                          <em style={{ color: 'gray' }}>Select</em>
                        </MenuItem>
                        {unitsData?.map(item => {
                          return (
                            <MenuItem key={item.id} value={item.unit}>
                              {`${item.unitName}-${item.unit}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: '#c22a1f' }}>
                        {errors.unit?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Quantity *</Typography>
                    <TextField
                      autoComplete='off'
                      {...register('quantity', {
                        required: 'Quantity is required',
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: 'Please enter a number',
                        },
                      })}
                      sx={textField}
                      id='quantity'
                      size='small'
                      placeholder='Enter Quantity '
                      variant='outlined'
                      name='quantity'
                      error={Boolean(errors.quantity)}
                      helperText={errors.quantity?.message}
                    />
                  </Grid>

                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>
                      {' '}
                      {Store.companyDetails?.businessType !== 'STORE'
                        ? 'Store Price *'
                        : 'M.R.P *'}
                    </Typography>
                    <TextField
                      autoComplete='off'
                      {...register('storePrice', {
                        required:
                          Store.companyDetails?.businessType !== 'STORE'
                            ? 'Store price is required *'
                            : 'M.R.P is required',
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: 'Please enter a number',
                        },
                      })}
                      sx={textField}
                      id='storePrice'
                      size='small'
                      placeholder={
                        Store.companyDetails?.businessType !== 'STORE'
                          ? 'Enter Store price'
                          : 'Enter MRP'
                      }
                      variant='outlined'
                      name='storePrice'
                      error={Boolean(errors.storePrice)}
                      helperText={errors.storePrice?.message}
                    />
                  </Grid>
                  {Store.companyDetails?.businessType !== 'STORE' && (
                    <Grid item lg={4} xs={6}>
                      <Typography variant='subtitle1'>M.R.P</Typography>
                      <TextField
                        autoComplete='off'
                        {...register('mrp', {
                          // required: 'MRP is required',
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: 'Please enter a number',
                          },
                        })}
                        sx={textField}
                        id='mrp'
                        size='small'
                        placeholder='Enter Mrp'
                        variant='outlined'
                        name='mrp'
                        // error={Boolean(errors.mrp)}
                        // helperText={errors.mrp?.message}
                      />
                    </Grid>
                  )}

                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Discount</Typography>
                    <TextField
                      autoComplete='off'
                      {...register('discount')}
                      sx={textField}
                      id='discount'
                      size='small'
                      type='text'
                      placeholder='Enter Discount'
                      variant='outlined'
                      name='discount'
                      error={Boolean(errors.discount)}
                      helperText={errors.discount?.message}
                    />
                  </Grid>

                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Stock</Typography>
                    <TextField
                      autoComplete='off'
                      {...register('stock', {
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: 'Please enter a number',
                        },
                      })}
                      sx={textField}
                      id='stock'
                      size='small'
                      placeholder='Enter Stock'
                      variant='outlined'
                      name='stock'
                      error={Boolean(errors.stock)}
                      helperText={errors.stock?.message}
                    />
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Base Price</Typography>
                    <TextField
                      autoComplete='off'
                      sx={textField}
                      {...register('basePrice')}
                      // {...register('basePrice', {
                      //   required: 'Base price is required',
                      //   pattern: {
                      //     value: /^\d+(\.\d{1,2})?$/,
                      //     message: 'Please enter a number',
                      //   },
                      // })}
                      id='basePrice'
                      placeholder='Enter Price'
                      size='small'
                      variant='outlined'
                      name='basePrice'
                      error={Boolean(errors.basePrice)}
                      helperText={errors.basePrice?.message}
                    />
                  </Grid>

                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1'>Stock Threshold</Typography>
                    <TextField
                      {...register('threshold')}
                      sx={textField}
                      id='threshold'
                      type='number'
                      size='small'
                      placeholder='Enter stock Threshhold'
                      variant='outlined'
                      name='threshold'
                      error={Boolean(errors.threshold)}
                      helperText={errors.threshold?.message}
                    />
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1' noWrap>
                      Base Discount
                    </Typography>
                    <TextField
                      {...register('basePriceDiscount')}
                      sx={textField}
                      id='basePriceDiscount'
                      size='small'
                      type='text'
                      autoComplete='off'
                      placeholder='Enter Base Discount'
                      variant='outlined'
                      name='basePriceDiscount'
                      error={Boolean(errors.basePriceDiscount)}
                      helperText={errors.basePriceDiscount?.message}
                    />
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <Typography variant='subtitle1' noWrap>
                      Barcode
                    </Typography>
                    <TextField
                      {...register('barcode')}
                      sx={textField}
                      id='barcode'
                      size='small'
                      type='text'
                      autoComplete='off'
                      placeholder='Barcode'
                      variant='outlined'
                      name='barcode'
                      error={Boolean(errors.barcode)}
                      helperText={errors.barcode?.message}
                      onKeyDown={handleBarcodeInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closePopupDialog}
              variant='outlined'
              sx={{
                width: '126px',
                height: '27px',
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={productSubmit}
              type='submit'
              variant='contained'
              sx={{
                width: '126px',
                height: '27px',
                textTransform: 'capitalize',
                backgroundColor: '#3B78C9',
              }}
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              Add Item
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddNewProductItem;
