import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Autocomplete,
  TextField,
  AppBar,
  Tooltip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import PurchaseBills from '../../modules/PurchaseBills';
import Validation from '../../modules/Validation';
import AddIcon from '@mui/icons-material/Add';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Vendor from '../../modules/Vendor';
import PaymentSection from '../../component/paymentSection/PaymentSection';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Auth from '../../modules/Auth';
import Customer from '../../modules/Customer';
import VendorDialog from '../vendorDialog/VendorDialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

import dateConveter from '../../modules/DateConveter/dateConveter';
import { debounce } from '../../utils';

function CreatePurchaseBill(props) {
  const {
    register,

    // watch,
    formState: { errors },
  } = useForm();

  const [billData, setBillData] = React.useState([]);
  const [balance, setBalance] = React.useState({});
  const [confirmBill, setConfirmBill] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState('');
  const [vendorPurchaseDetails, setVendorPurchaseDetails] = React.useState(
    props.updatedPurchseBillData
  );
  const [updatedPurchseBillData, setUpdatedPurchseBillData] = React.useState(
    props.purchaseDetails
  );
  const [vendorDate, setVendorDate] = React.useState(
    vendorPurchaseDetails.vendorBillDate || new Date()
  );
  const [openVendorDialog, setOpenVendorDialog] = React.useState(false);
  const [vendorData, setVendorData] = React.useState({});
  const [savedVendor, setSavedVendor] = React.useState({});
  const [saved, setSaved] = React.useState(false);
  const [editPurchaseBill, setEditPurchaseBill] = React.useState(false);
  // const [renderLedger, setRenderLedger] = React.useState(false);
  const [accordionExpanded, setAccordionExpanded] = React.useState('panel2');
  const [refreshLedger, setRefreshLedger] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [hasMoreData, setHasMoreData] = React.useState(true);

  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };
  let billDateVar;
  const listRef = React.useRef(null);
  const page_size = 18;
  React.useEffect(() => {
    if (props.updatedPurchseBillData.status === 'CONFIRMED') {
      setSaved(true);
    }
    if (Validation.isBlankObjectNot(updatedPurchseBillData)) {
      setVendorData(props.purchaseDetails);
      getTotalVendorBalance(updatedPurchseBillData.id);
    } else {
      if (Validation.isBlankObjectNot(vendorPurchaseDetails)) {
        Vendor.getVendorByVendorId(vendorPurchaseDetails.vendorId, response => {
          if (response.status === 'success') {
            setVendorData(response.data);
          }
        });

        getTotalVendorBalance(vendorPurchaseDetails.vendorId);
      } else {
        setVendorData(vendorPurchaseDetails);
      }
    }
    getVendorByStoreId(pageNo);
  }, []);

  const saveVendor = status => {
    let id =
      vendorPurchaseDetails.status !== 'DRAFTED'
        ? undefined
        : vendorPurchaseDetails.id;

    let currentTime = new Date();
    if (vendorDate) {
      vendorDate.setHours(currentTime.getHours());
      vendorDate.setMinutes(currentTime.getMinutes());
      vendorDate.setSeconds(currentTime.getSeconds());
    }

    let data = {
      ...vendorPurchaseDetails,
      id: id,
      vendorId: vendorData.id || vendorPurchaseDetails.vendorId,
      storeId: vendorData.storeId || vendorPurchaseDetails.storeId,
      vendorData: vendorData.openingBalance,
      status: status,

      //don't remove, required for nodejs
      billAmount: parseFloat(vendorPurchaseDetails.billAmount),
      vendorBillDate: vendorPurchaseDetails.vendorBillDate || vendorDate,
    };
    if (!vendorData.name) {
      props.snackbar('Please Select Vendor', 'error');
    } else if (!vendorPurchaseDetails.vendorBillNo) {
      props.snackbar('bill Number is required', 'error');
    } else if (!vendorPurchaseDetails.billAmount) {
      props.snackbar('bill amount is required', 'error');
    } else if (!vendorPurchaseDetails.remark) {
      props.snackbar('Description is required', 'error');
    } else {
      setSaved(true);

      PurchaseBills.addPurchaseBill(data, response => {
        if (response.status === 'success') {
          props.snackbar('Bill details added successfully', 'success');
          setConfirmBill(true);
          if (status !== 'CONFIRMED') {
            props.backToPurchaseBill();
          }
        } else {
          props.snackbar('Bill details not added successfully', 'error');
          setSaved(false);
        }
      });
    }
  };

  const getVendorByStoreId = pageNum => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), pageNum, true, response => {
      if (response.status === 'success') {
        let Activevendors = response.data.filter(vendor => {
          return vendor.active === true;
        });
        let sortedVendorList = Activevendors.sort(function (a, b) {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
        });
        if (sortedVendorList < page_size) {
          setHasMoreData(false);
        }
        setBillData([...billData, ...sortedVendorList]);
      }
    });
  };
  const getTotalVendorBalance = id => {
    let ids = { customerIds: [id] };
    Customer.getCustomerBalance(ids, response => {
      if (response.status === 'success') {
        let vendBalance = response.data[0].balanceAmount;
        setBalance(vendBalance);
      }
    });
  };
  const closeVendorDialog = () => {
    setOpenVendorDialog(false);
  };
  // const handlenewVendor = () => {
  //   debugger;
  //   props.setCreatePurchaseBill(true);
  //   setVendorPurchaseDetails({
  //     openingBalance: '',
  //     vendorBillDate: new Date(),
  //     remark: '',
  //     billAmount: '',
  //     vendorBillNo: '',
  //     truckNo: '',
  //   });

  //   setBalance([]);

  //   setVendorData({
  //     name: '',
  //     address: '',
  //   });
  //   setConfirmBill(false);
  //   setSaved(false);
  // };

  const handleSelectVendor = vendor => {
    setVendorData(vendor);
    setSavedVendor({ ...savedVendor, openingBalance: vendor.openingBalance });
    getTotalVendorBalance(vendor.id);
  };
  const updateEditedPurchaseBill = () => {
    if (
      new Date(vendorPurchaseDetails?.vendorBillDate).getTime() !==
      new Date(vendorDate).getTime()
    ) {
      dateConveter.convertDate(vendorDate, response => {
        billDateVar = response.data;
      });
    }

    let editedData = {
      ...vendorPurchaseDetails,
      edited: true,
      vendorData: vendorData.openingBalance,
      status: 'CONFIRMED',
      vendorBillDate: billDateVar ? billDateVar : vendorDate,
    };
    setRefreshLedger(true);
    PurchaseBills.updatePurchaseBill(
      editedData,
      vendorPurchaseDetails.id,
      response => {
        if (response.status === 'success') {
          setRefreshLedger(false);
          props.snackbar('Bill details Edited successfully', 'success');
          setSaved(true);
          setEditPurchaseBill(false);
        } else {
          props.snackbar('Bill details not edited successfully', 'error');
        }
      }
    );
  };

  const handleChange = panel => (event, newExpanded) => {
    setAccordionExpanded(newExpanded ? panel : false);
  };

  const handleSearchVendor = event => {
    let search = event.target.value;
    Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
      if (response.status === 'success') {
        let filteredSearchActive = response.data.filter(item => {
          return item.active === true;
        });

        setBillData(filteredSearchActive);
      }
      if (response.status === 'error') {
        getVendorByStoreId(pageNo);
      }
    });
  };
  const handleVendorChange = React.useCallback(
    debounce(handleSearchVendor, 300),
    []
  );
  const handleScroll = () => {
    const list = listRef.current;

    if (
      list.scrollTop + list.clientHeight >= list.scrollHeight &&
      hasMoreData
    ) {
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;

      setPageNo(pageNum);
      getVendorByStoreId(pageNum);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} sm={6} sx={{ display: 'flex' }}>
          <Button
            variant='outlined'
            sx={{
              ml: 1,
              color: 'blue',
              border: 'none',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => {
              props.backToPurchaseBill();
              setSaved(false);
            }}
          >
            <ArrowLeftIcon />
            <Typography sx={{ fontSize: { md: '13px', sm: '12px' } }}>
              Bill
            </Typography>
          </Button>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            mt: { md: 2, sm: 2, xs: 1 },
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sm={8}
            sx={{
              ml: 'auto',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: { xs: '750px', sm: '500px', md: '600px' },
            }}
          >
            <Button
              variant='contained'
              sx={{
                color: '#fff',
                border: 'none',
                float: 'right',
                textTransform: 'capitalize',
                width: { xs: '100', sm: '100px', md: '126px' },
                height: '27px',
              }}
              onClick={() => {
                setOpenVendorDialog(true);
                setButtonClicked('Add');
              }}
            >
              <Typography
                sx={{ fontSize: { md: '14px', sm: '12px', xs: '10px' } }}
                noWrap
              >
                Add Vendor
              </Typography>
            </Button>
            <Button
              disabled={
                vendorPurchaseDetails.deleted === true ||
                vendorPurchaseDetails?.status !== 'CONFIRMED'
              }
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                width: { xs: '100', sm: '100px', md: '126px' },
                height: '27px',
                backgroundColor: '#3B78C9',
              }}
              onClick={() => {
                setEditPurchaseBill(true);
                setConfirmBill(false);
                setSaved(false);
              }}
            >
              <Typography
                sx={{ fontSize: { md: '15px', sm: '12px', xs: '10px' } }}
                noWrap
              >
                Edit Bill
              </Typography>
            </Button>
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',
                width: { xs: '100', sm: '100px', md: '126px' },
                height: '27px',
                backgroundColor: '#3B78C9',
                mr: 2,
              }}
              onClick={() => props.reloadPurchaseBill()}
            >
              <Typography
                sx={{ fontSize: { md: '15px', sm: '12px', xs: '10px' } }}
                noWrap
              >
                New Bill
              </Typography>
            </Button>
            <Link to='/purchase-order' style={{ textDecoration: 'none' }}>
              <Tooltip title='Create Purchase Order'>
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    width: { xs: '100', sm: '100px', md: '126px' },
                    height: '27px',
                    backgroundColor: '#3B78C9',
                    p: 0,
                  }}
                  startIcon={
                    <AddIcon fontSize='small' sx={{ color: 'white' }} />
                  }
                >
                  <Typography
                    sx={{ fontSize: { md: '12px', sm: '12px', xs: '10px' } }}
                    noWrap
                  >
                    Purchase Order
                  </Typography>
                </Button>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12} alignItems='center'>
          <Box sx={{ ml: 3, mt: 2 }}>
            <Autocomplete
              disabled={
                (vendorPurchaseDetails?.status === 'CONFIRMED' &&
                  confirmBill) ||
                props?.updatedPurchseBillData?.status === 'CONFIRMED' ||
                saved
              }
              ListboxProps={{
                ref: listRef,
                onScroll: ({ currentTarget }) => {
                  handleScroll(currentTarget);
                },
              }}
              options={billData}
              disableClearable
              onChange={(event, newValue) => {
                if (newValue == 121) {
                  return null;
                }

                handleSelectVendor(newValue);
              }}
              onInputChange={e => handleVendorChange(e)}
              value={
                (Object.keys(vendorPurchaseDetails).length > 0 ||
                  updatedPurchseBillData) &&
                Object.keys(vendorData).length > 0
                  ? vendorData || {}
                  : ''
              }
              getOptionLabel={option =>
                typeof option == 'string'
                  ? (option = '')
                  : option !== undefined
                  ? option.vendorName !== undefined
                    ? option.vendorName
                    : option.name
                  : ''
              }
              renderOption={(props, option) => {
                return (
                  <div {...props} key={option.id}>
                    <span
                      key={option.id}
                      style={{ paddingRight: '10px' }}
                    ></span>
                    {`${option.name}`}
                    <br />
                    {`${option.address}`}
                    <li
                      style={{ color: 'gray', marginLeft: 'auto' }}
                      key={option.id}
                      {...props}
                    >{`${
                      option.contactNo !== null ? option.contactNo : ''
                    } `}</li>
                  </div>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    width: { md: '300px', sm: '270px', xs: '250px' },
                    mb: 2,
                    backgroundColor: '#E1EEF5',
                    borderTop: '5px solid #E1EEF5',
                    '& .MuiInputBase-root': {
                      paddingRight: '39px !important',
                    },
                  }}
                  placeholder='Search vendor'
                  size='small'
                  variant='outlined'
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />

            <>
              <Accordion
                expanded={accordionExpanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  sx={{ backgroundColor: 'secondary.light' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography sx={{ width: '97%', flexShrink: 0 }}>
                    Vendor{' '}
                    <b>
                      <Link
                        to='/vendor-details'
                        state={vendorData}
                        style={{ textDecoration: 'none' }}
                      >
                        {vendorData?.vendorName || vendorData?.name}
                      </Link>
                    </b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex' }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant='subtitle1'>Vendor Name</Typography>
                      <TextField
                        sx={textField}
                        disabled={true}
                        id='vendor Name'
                        variant='outlined'
                        size='small'
                        value={vendorData.vendorName || vendorData?.name}
                        placeholder='vendor Name'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant='subtitle1'>
                        Vendor Address
                      </Typography>
                      <TextField
                        sx={textField}
                        disabled={true}
                        id='vendor Adress'
                        variant='outlined'
                        size='small'
                        value={vendorData?.address}
                        placeholder='vendor Address'
                      />
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
            <Accordion
              expanded={accordionExpanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                sx={{ backgroundColor: 'secondary.light' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                <Typography>Purchase Bill Detail</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container sx={{ pt: 5 }}>
                  <Grid item md={3} sm={4} xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '18px', mb: 1 }}>
                      Vendor Bill No
                    </Typography>
                    <TextField
                      required={true}
                      sx={textField}
                      isRequired='true'
                      id='vendorBillNo'
                      type='text'
                      variant='outlined'
                      size='small'
                      onChange={event => {
                        setVendorPurchaseDetails({
                          ...vendorPurchaseDetails,
                          vendorBillNo: event.target.value,
                        });
                      }}
                      disabled={saved}
                      value={vendorPurchaseDetails?.vendorBillNo}
                      placeholder='Enter vendor Bill No'
                      name='vendorBillNo'
                    />
                  </Grid>
                  <Grid item md={3} sm={4} xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '18px', mb: 1 }}>
                      Balance
                    </Typography>

                    <TextField
                      // defaultValue={saved}
                      disabled={true}
                      sx={textField}
                      id='balance'
                      // multiline
                      type='number'
                      variant='outlined'
                      size='small'
                      value={
                        !vendorPurchaseDetails?.lastBalance
                          ? balance
                          : vendorPurchaseDetails?.lastBalance
                      }
                      onChange={event => {
                        setVendorPurchaseDetails({
                          ...vendorPurchaseDetails,
                          openingBalance: event.target.value,
                        });
                      }}
                      placeholder='Balance'
                      name='openingBalance'
                    />
                  </Grid>
                  <Grid md={3} sm={4} xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '18px', mb: 1 }}>
                      Bill Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={saved}
                        value={vendorDate}
                        onChange={newValue => {
                          setVendorDate(newValue);
                        }}
                        inputFormat='dd-MM-yyyy'
                        renderInput={params => (
                          <TextField size='small' sx={textField} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item md={3} sm={4} xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '18px', mb: 1 }}>
                      Bill Amount
                    </Typography>
                    <TextField
                      sx={textField}
                      disabled={saved}
                      id='billAmount'
                      type='number'
                      onChange={event => {
                        setVendorPurchaseDetails({
                          ...vendorPurchaseDetails,
                          billAmount:
                            event.target.value < 0
                              ? (event.target.value = 0)
                              : event.target.value,
                        });
                      }}
                      size='small'
                      placeholder='Enter Bill Amount'
                      variant='outlined'
                      name='billAmount'
                      value={vendorPurchaseDetails?.billAmount}
                    />
                  </Grid>
                  <Grid item md={3} sx={{ pt: 2 }} sm={4} xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '18px', mb: 1 }}>
                      Vehicle No
                    </Typography>

                    <TextField
                      sx={textField}
                      disabled={saved}
                      id='truckNo'
                      onChange={event => {
                        setVendorPurchaseDetails({
                          ...vendorPurchaseDetails,
                          truckNo: event.target.value,
                        });
                      }}
                      size='small'
                      placeholder='Enter Truck No'
                      variant='outlined'
                      name='truckNo'
                      value={vendorPurchaseDetails?.truckNo}
                    />
                  </Grid>
                  <Grid item md={5} sm={4} xs={12} sx={{ pt: 2 }}>
                    <Typography variant='h6' sx={{ fontSize: '18px' }}>
                      Bill Description
                    </Typography>
                    <TextField
                      sx={textField}
                      {...register('remark', {
                        required: 'remark is required to fill',
                      })}
                      multiline
                      rows={4}
                      id='remark'
                      onChange={event => {
                        setVendorPurchaseDetails({
                          ...vendorPurchaseDetails,
                          remark: event.target.value,
                        });
                      }}
                      size='small'
                      placeholder='Enter Bill Description'
                      variant='outlined'
                      value={vendorPurchaseDetails?.remark}
                      disabled={saved}
                      name='remark'
                      error={Boolean(errors.remark)}
                      helperText={errors.remark?.message}
                    />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {vendorPurchaseDetails?.status === 'DRAFTED' ? (
                        <>
                          <Button
                            disabled={saved}
                            variant='contained'
                            sx={{
                              backgroundColor: '#3B78C9',
                              ml: 1,
                              textTransform: 'capitalize',
                              padding: 0,
                              fontSize: '14px',

                              width: '126px',
                              height: '27px',
                            }}
                            onClick={() => {
                              saveVendor('DRAFTED');
                            }}
                          >
                            <Typography noWrap>update & Draft</Typography>
                          </Button>{' '}
                          <Button
                            disabled={saved}
                            variant='contained'
                            sx={{
                              ml: 1,
                              backgroundColor: '#3B78C9',
                              textTransform: 'capitalize',
                              padding: 0,
                              width: '126px',
                              height: '27px',
                              fontSize: '14px',
                            }}
                            onClick={() => {
                              saveVendor('CONFIRMED');
                            }}
                          >
                            <Typography noWrap>update & confirm</Typography>
                          </Button>
                        </>
                      ) : editPurchaseBill ? (
                        <Button
                          variant='contained'
                          sx={{
                            backgroundColor: '#3B78C9',
                            textTransform: 'capitalize',
                            padding: 0,
                            width: '126px',
                            height: '27px',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            updateEditedPurchaseBill();
                          }}
                        >
                          <Typography noWrap>update & confirm</Typography>
                        </Button>
                      ) : (
                        <>
                          <Button
                            disabled={saved}
                            variant='contained'
                            sx={{
                              backgroundColor: '#3B78C9',
                              ml: 1,
                              textTransform: 'capitalize',

                              width: '126px',
                              height: '27px',
                            }}
                            onClick={() => {
                              saveVendor('DRAFTED');
                              // props.backToPurchaseBill();
                            }}
                          >
                            Draft
                          </Button>{' '}
                          <Button
                            disabled={saved}
                            variant='contained'
                            sx={{
                              backgroundColor: '#3B78C9',
                              textTransform: 'capitalize',

                              width: '126px',
                              height: '27px',
                              ml: 2,
                            }}
                            onClick={() => {
                              saveVendor('CONFIRMED');
                            }}
                          >
                            Confirm
                          </Button>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        {/* #////////////payment/// */}
        {vendorPurchaseDetails?.status === 'CONFIRMED' || confirmBill ? (
          <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <PaymentSection
              key={vendorData.id}
              snackbar={props.snackbar}
              customerId={
                vendorData.id !== undefined
                  ? vendorData.id
                  : vendorPurchaseDetails.vendorId
              }
              name={vendorData.name}
              customerDetails={
                Object.keys(vendorData).length > 0
                  ? vendorData
                  : vendorPurchaseDetails
              }
              type='Party'
              purchaseBillNo={vendorPurchaseDetails.vendorId}
              refreshLedger={refreshLedger}
            />
          </Grid>
        ) : null}
      </Grid>
      {openVendorDialog ? (
        <VendorDialog
          openDialog={openVendorDialog}
          snackbar={props.snackbar}
          closeDialog={closeVendorDialog}
          buttonClicked={buttonClicked}
          reloadVendorList={getVendorByStoreId}
        />
      ) : null}
    </div>
  );
}

export default CreatePurchaseBill;
