import React, { Component } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { Loading } from '../../component/loading/Loading';
import Customer from '../../modules/Customer';
import ActivationCustomerDialog from '../../component/activationDialog/ActivationCustomerDialog';
import ExportDialog from '../../component/exportDialog/ExportDialog';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import AddEditCustomer from '../../component/addEditCustomer/AddEditCustomer';
import Header from '../../component/header/Header';
import { Link } from 'react-router-dom';
import Auth from '../../modules/Auth';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import CheckCustomerBalance from '../../component/checkCustomerBalance/CheckCustomerBalance';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { debounce } from '../../utils';
class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      customerDataList: [],
      openAddCustomerDialog: false,
      selectedCustomerData: '',
      allCustomers: [],
      buttonClicked: '',
      upstoreSnackBar: { open: false },
      deleteProductDialog: { open: false, idOfDeletableProduct: null },
      deleteCustomerDialog: {
        open: false,
        idOfDeletableCustomer: null,
        custName: null,
      },
      pageNum: 0,
      openCheckBalanceDialog: false,
      selectedRowData: null,
      changeMassage: true,
      openExportDialog: false,
    };
  }

  componentDidMount() {
    this.getCustomerList(this.state.pageNum);
  }
  handleChange = event => {
    this.setState({ age: event.target.value });
  };
  getCustomerList = pageNum => {
    this.setState({ loader: true });

    // Customer.reloadCustomers(Auth.getStoreId(), response => {
    Customer.getAllCustomers(Auth.getStoreId(), pageNum, true, response => {
      if (response.status === 'success') {
        let filteredArr = response.data.filter(
          item => item.customerName?.toUpperCase() !== 'ANONYMOUS'
        );
        let sortedCustomers = filteredArr.sort(
          (a, b) => b.customerNumber - a.customerNumber
        );
        this.setState({
          customerDataList: [...sortedCustomers],
          loader: false,
          allCustomers: response.data,
        });
      } else {
        this.setState({ customerDataList: [], loader: false });
      }
    });
  };

  reloadCustomersList = () => {
    Customer.reloadCustomers(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        let filteredArr = response.data.filter(
          item => item.customerName?.toUpperCase() !== 'ANONYMOUS'
        );
        let sortedCustomers = filteredArr.sort(
          (a, b) => b.customerNumber - a.customerNumber
        );
        this.setState({
          customerDataList: [...sortedCustomers],
          loader: false,
        });
      } else {
        this.setState({ customerDataList: [], loader: false });
      }
    });
  };
  handleFilterCustomerChange = event => {
    let search = event.target.value;
    if (event.target.value) {
      Customer.getCustomerSearchName(search, Auth.getStoreId(), response => {
        if (response.status === 'success') {
          this.setState({ customerDataList: response.data });
        }
      });
    } else {
      this.getCustomerList(0);
    }
  };

  customerChange = debounce(e => this.handleFilterCustomerChange(e), 300);
  // for snackbar
  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  closeAddEditCustomerDialog = () => {
    this.setState({
      openAddCustomerDialog: false,
      selectedCustomerData: '',
      buttonClicked: '',
    });
  };

  closeDeletePopup = (soft = false) => {
    if (soft == true) {
      this.setState({
        deleteCustomerDialog: {
          open: false,
          idOfDeletableCustomer: null,
          custName: null,
        },
      });
    } else {
      this.setState({
        deleteProductDialog: { open: false, idOfDeletableProduct: null },
      });
    }
  };

  // handling delete customer
  handleDeleteCustomerCallBack = (Delete, soft = false) => {
    let id;
    if (Delete === true) {
      id = this.state.deleteProductDialog.idOfDeletableProduct;
      if (soft === false) {
        Customer.deleteCustomer(id, response => {
          if (response.status === 'success') {
            this.openUpstoreSnackBar(
              this.state.changeMassage === false
                ? 'Customer was deactivated successfully'
                : 'Customer was activated successfully',
              'success'
            );

            this.closeDeletePopup();
            this.getCustomerList(this.state.pageNum);
          } else {
            this.openUpstoreSnackBar(
              'Customer was not deleted successfully',
              'error'
            );
          }
        });
      } else {
        let data = {
          deleted: true,
          customerName: this.state.deleteCustomerDialog.custName,
        };
        id = this.state.deleteCustomerDialog.idOfDeletableCustomer;
        Customer.updateCustomer(id, data, response => {
          if (response.status === 'success') {
            this.openUpstoreSnackBar(
              'Customer was deleted successfully',
              'success'
            );

            this.closeDeletePopup(true);
            this.getCustomerList(this.state.pageNum);
          } else if (response.data == 403) {
            this.openUpstoreSnackBar(
              `Customer has transactions, can't delete`,
              'error'
            );
            this.closeDeletePopup(true);
          } else {
            this.openUpstoreSnackBar(
              'Customer was not deleted successfully',
              'error'
            );
            this.closeDeletePopup(true);
          }
        });
      }
    }
  };
  handleActiveInactiveCustomers = (status, id) => {
    this.setState({ changeMassage: status });
    this.setState({
      deleteProductDialog: {
        open: true,
        idOfDeletableProduct: id,
      },
    });
  };

  handleSoftDeleteCustomer = (id, name) => {
    this.setState({
      deleteCustomerDialog: {
        open: true,
        idOfDeletableCustomer: id,
        custName: name,
      },
    });
  };

  handleEditCustomerCallBack = data => {
    this.setState({
      openAddCustomerDialog: true,
      selectedCustomerData: data,
      buttonClicked: 'Edit',
    });
  };
  handlePreviousPage = () => {
    let pageNumb = this.state.pageNum - 1;
    this.setState({ pageNum: pageNumb });
    this.getCustomerList(pageNumb);
  };

  handleNextPage = () => {
    let pageNumb = this.state.pageNum + 1;
    this.setState({ pageNum: pageNumb });
    this.getCustomerList(pageNumb);
  };

  render() {
    return (
      <>
        <Header pageName='Customers' />

        <Box
          sx={{
            width: '95%',
            height: 600,
            mt: 9,
            ml: 8,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {this.state.loader ? (
            <Loading />
          ) : (
            <>
              <Grid container>
                <Grid item md={2} sm={3} xs={6}>
                  <BreadCrumb pageName='Customers' />
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={0}
                  xs={0}
                  sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                  }}
                >
                  {/* visible only in large  screen size */}

                  <TextField
                    size='small'
                    // sx={{ width: '90%', fontSize: '1.2vw' }}
                    fullWidth
                    variant='outlined'
                    onChange={e => this.customerChange(e)}
                    placeholder='Search by Name Email or Phone'
                    id='search'
                  />
                </Grid>
                {AllPermissions.getUserAccessiblePermissions('Customer') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('Customer')
                  .write === false ? null : (
                  <Grid
                    item
                    sm={2}
                    xs={6}
                    md={2}
                    textAlign={{ xs: 'left', sm: 'right' }}
                  >
                    <Tooltip title='Export To Pdf' placement='bottom'>
                      <Button
                        sx={{
                          mb: 1,
                          textTransform: 'capitalize',
                          width: '126px',
                          height: '27px',
                          color: '#ffff',
                          backgroundColor: '#3B78C9',
                          marginRight: '10px',
                        }}
                        variant='contained'
                        onClick={() =>
                          this.setState({ openExportDialog: true })
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: { md: '0.9vw' },
                          }}
                        >
                          Export
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={4}
                  textAlign={{ xs: 'left', sm: 'right' }}
                >
                  <Tooltip title='Check customer balance' placement='bottom'>
                    <Button
                      sx={{
                        p: 0,
                        mb: 1,
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                        color: '#ffff',
                        backgroundColor: '#3B78C9',
                        marginRight: '10px',
                      }}
                      variant='contained'
                      onClick={() =>
                        this.setState({ openCheckBalanceDialog: true })
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: { md: '0.9vw' },
                        }}
                        noWrap
                      >
                        {' '}
                        Check Balance
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
                {AllPermissions.getUserAccessiblePermissions('Customer') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('Customer')
                  .write === false ? null : (
                  <Grid
                    item
                    md={2}
                    sm={2}
                    xs={4}
                    textAlign={{ xs: 'left', sm: 'right' }}
                  >
                    <Tooltip title='Add Customer' placement='bottom'>
                      <Button
                        sx={{
                          p: 0,
                          mb: 1.5,
                          ml: 2,
                          textTransform: 'capitalize',
                          width: '126px',
                          height: '27px',
                          color: '#ffff',
                          backgroundColor: '#3B78C9',
                        }}
                        variant='contained'
                        onClick={() =>
                          this.setState({
                            openAddCustomerDialog: true,
                            buttonClicked: 'Add',
                          })
                        }

                        // onClick={() => setaddOpenProductDialog(true)}
                      >
                        <AddIcon
                          sx={{
                            color: '#ffff',
                            fontSize: '16px',
                            display: { md: 'block', sm: 'block', xs: 'none' },
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: { md: '0.9vw' },
                          }}
                          noWrap
                        >
                          New Customer
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item md={1} sm={2} xs={4} textAlign='right'>
                  <Tooltip placement='bottom' title='reload'>
                    <RestartAltIcon
                      sx={{
                        mb: 1,
                        width: '30px',
                        height: '30px',
                        color: '#3B78C9',
                        cursor: 'pointer',
                      }}
                      onClick={this.reloadCustomersList}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  sx={{
                    display: { md: 'none', xs: 'block' },
                  }}
                >
                  {/* visible only in large  screen size */}

                  <TextField
                    size='small'
                    sx={{ width: '90%' }}
                    variant='outlined'
                    onChange={this.handleFilterCustomerChange}
                    placeholder='Search by Name Email or Phone'
                    id='fullWidth'
                  />
                </Grid>
              </Grid>
              <BasicTabs
                customerData={this.state.customerDataList}
                columns={this.columns}
                activeInactiveCallBack={this.handleActiveInactiveCustomers}
                handleEditCustomerCallBack={this.handleEditCustomerCallBack}
                handleSoftDelete={this.handleSoftDeleteCustomer}
              />
            </>
          )}
          <Grid
            style={{
              padding: '1.6vh',
              float: 'right',

              bottom: '0px',
            }}
          >
            <Button
              size='small'
              variant='outlined'
              disabled={this.state.pageNum <= 0}
              onClick={this.handlePreviousPage}
              sx={{ color: '#3B78C9', variant: 'outlined' }}
            >
              Prev
            </Button>
            <Button
              size='small'
              variant='outlined'
              disabled={this.state.allCustomers.length < 20}
              sx={{ ml: 1, color: '#3B78C9' }}
              onClick={this.handleNextPage}
            >
              Next
            </Button>
          </Grid>

          {this.state.upstoreSnackBar.open ? (
            <UpStoreSnackBar
              upstoreSnackBar={this.state.upstoreSnackBar}
              onSnackbarClose={this.onSnackbarClose}
            />
          ) : null}

          {this.state.openAddCustomerDialog ? (
            <AddEditCustomer
              openDialog={this.state.openAddCustomerDialog}
              closePopup={this.closeAddEditCustomerDialog}
              buttonClicked={this.state.buttonClicked}
              editCustomerData={
                this.state.selectedCustomerData &&
                this.state.selectedCustomerData
              }
              reloadCustomersList={this.getCustomerList}
              snackbar={this.openUpstoreSnackBar}
            />
          ) : null}

          {this.state.deleteProductDialog.open ? (
            <ActivationCustomerDialog
              openDialog={true}
              reloadCustomersList={this.getCustomerList}
              closeDialogCallback={this.closeDeletePopup}
              deleteCallback={this.handleDeleteCustomerCallBack}
              dialogContent={
                this.state.changeMassage === false
                  ? 'Are you sure you want deactivate user ?'
                  : 'Are you sure you want to activate user '
              }
              buttonAlert={
                this.state.changeMassage === false ? 'Deactivate' : 'activate'
              }
              soft={false}
            />
          ) : null}

          {this.state.deleteCustomerDialog.open ? (
            <ActivationCustomerDialog
              openDialog={true}
              reloadCustomersList={this.getCustomerList}
              closeDialogCallback={this.closeDeletePopup}
              deleteCallback={this.handleDeleteCustomerCallBack}
              dialogContent={'Are you sure you want delete this customer ?'}
              buttonAlert={'Delete'}
              soft={true}
            />
          ) : null}
          {this.state.openCheckBalanceDialog ? (
            <CheckCustomerBalance
              openDialog={this.state.openCheckBalanceDialog}
              closeDialog={() =>
                this.setState({ openCheckBalanceDialog: false })
              }
              snackbar={this.openUpstoreSnackBar}
              // avaialable only when row is selecteds
              selectedCustomerRowData={this.state.selectedRowData}
            />
          ) : null}
          {this.state.openExportDialog ? (
            <ExportDialog
              snackbar={this.openUpstoreSnackBar}
              openDialog={this.state.openExportDialog}
              closeDialog={() => this.setState({ openExportDialog: false })}
              type='customer'
            />
          ) : null}
          {AllPermissions.getUserAccessiblePermissions('Customer') !==
            undefined &&
          AllPermissions.getUserAccessiblePermissions('Customer').read ===
            false ? (
            <ErrorDialog></ErrorDialog>
          ) : null}
        </Box>
      </>
    );
  }
}
//for  active & inactive Customers in Tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs({
  customerData,
  activeInactiveCallBack,
  handleEditCustomerCallBack,
  handleSoftDelete,
}) {
  const columns = [
    {
      field: 'CustomerNo',
      headerName: 'Customer No',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => {
        return <Typography>{params.row?.customerNumber}</Typography>;
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 220,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <>
            <Link
              style={{ textDecoration: 'none' }}
              to={'/customer-details'}
              state={params.row}
            >
              <Typography
                variant='subtitle1'
                sx={{ cursor: 'pointer', color: '#3B78C9' }}
              >
                {`${params.row?.customerName}`}
              </Typography>
            </Link>
          </>
        );
      },
    },
    {
      field: 'GstNo',
      headerName: 'GST NO',
      width: 180,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => (
        <Typography variant='h6' fontSize='15px'>
          {params.row.gstNo === null || params.row.gstNo === ''
            ? '-'
            : params.row.gstNo}
        </Typography>
      ),
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 230,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'address',
      headerName: 'Address',
      type: 'string',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },

    {
      field: 'contact',
      headerName: 'Ph No',
      type: 'number',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },

    {
      field: 'active',
      headerName: 'Active',
      type: 'number',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <FormControl fullWidth>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={params.row.active}
              disabled={
                AllPermissions.getUserAccessiblePermissions('Customer') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('Customer')
                  .write === false
                  ? true
                  : false
              }
              sx={{
                color: '#fff',
                backgroundColor:
                  params.row.active === true ? '#20CCA1' : '#F85D59',
              }}
              onChange={event => {
                activeInactiveCallBack(event.target.value, params.row.id);
              }}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In-Active</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            {AllPermissions.getUserAccessiblePermissions('Customer') !==
              undefined &&
            AllPermissions.getUserAccessiblePermissions('Customer').write ===
              false ? (
              <EditIcon />
            ) : (
              <>
                <Tooltip title='Edit Customer' placement='bottom'>
                  <EditIcon
                    onClick={() => handleEditCustomerCallBack(params.row)}
                    sx={{ color: '#3B78C9' }}
                  />
                </Tooltip>
                {value == 1 ? (
                  <Tooltip title='Delete Customer' placement='bottom'>
                    <DeleteIcon
                      onClick={() =>
                        handleSoftDelete(params.row.id, params.row.customerName)
                      }
                    >
                      Delete
                    </DeleteIcon>
                  </Tooltip>
                ) : null}
              </>
            )}
          </Box>
        </>
      ),
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let customerData1 = customerData.filter(item => {
    return item.active === true && item.deleted === false;
  });
  let customerData2 = customerData.filter(item => {
    return item.active === false && item.deleted === false;
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          key={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Active Customers' {...a11yProps(0)} />
          <Tab label='Inactive Customers' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            width: '100%',
            height: 600,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {customerData1.length > 0 ? (
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                boxShadow: 2,
                border: 6,
                backgroundColor: '#fff',
                borderColor: '#eee',
                color: 'black',
                '& .MuiDataGrid-cell': {
                  outline: 'none',
                  fontSize: '16px',
                  fontWeight: '25px',
                },
                '& .MuiDataGrid-cell:hover': {
                  outline: 'none',
                  color: '#21211f',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& svg': {
                  color: '#3b78c9',
                },
                '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                  color: 'black',
                },
              }}
              density='compact'
              rows={customerData1}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='customers list'
              onSelectionModelChange={id => {
                let selectedRow = customerData1.filter(
                  customer => customer.id === id.join()
                )[0];
                let selectedRowData = selectedRow;
              }}
            />
          ) : (
            <Typography>No Customer Found</Typography>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            width: '100%',
            height: 600,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {customerData2.length > 0 ? (
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                boxShadow: 2,
                border: 6,
                backgroundColor: '#fff',
                borderColor: '#eee',
                color: 'black',
                '& .MuiDataGrid-cell': {
                  outline: 'none',
                  fontSize: '16px',
                  fontWeight: '25px',
                },
                '& .MuiDataGrid-cell:hover': {
                  outline: 'none',
                  color: '#21211f',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& svg': {
                  color: '#3b78c9',
                },
                '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                  color: 'black',
                },
              }}
              density='compact'
              rows={customerData2}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='customers list'
              onSelectionModelChange={id => {
                let selectedRow = customerData2.filter(
                  customer => customer.id === id.join()
                )[0];
                this.setState({ selectedRowData: selectedRow });
              }}
            />
          ) : (
            <Typography>No Customer Found</Typography>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
}
export default Customers;
