import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import AgentSelection from '../../component/agentSelectSection/AgentSelection';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Header from '../../component/header/Header';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { ArrowLeft } from '@mui/icons-material';
import Customer from '../../modules/Customer';

function ReturnOrders() {
  let location = useLocation();
  const navigateTo = useNavigate();
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [expanded, setExpanded] = React.useState(true);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const textField = {
    width: '300px',
    mt: 1,
    //
  };
  const customrName = Customer.getCustomerById(
    location.state.params.customerId,
    response => {
      return response.data;
    }
  );
  console.log(location.state.params);
  return (
    <div>
      <Box sx={{ mt: 8, ml: 9, mr: 2 }}>
        <Header />
        <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: 'flex', ml: 2, justifyContent: 'space-between' }}
          >
            <Box item md={12} xs={12}>
              {' '}
              <BreadCrumb pageName='Return Orders' />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Tooltip title='Go to Estore Orders' placement='bottom'>
              <Button
                startIcon={<ArrowLeft sx={{ fontSize: '10px' }} />}
                variant='text'
                underline='none'
                sx={{
                  mb: 1,
                  p: 0,
                  fontSize: '11px',
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  navigateTo('/estore-orders');
                }}
              >
                <Typography noWrap> Estore Orders</Typography>
              </Button>
            </Tooltip>{' '}
          </Grid>
          <Grid item md={6} sx={{ alignItems: 'center' }}>
            <Typography sx={{ mr: 1 }} variant='subtitle1'>
              Customer Name
            </Typography>
            <TextField
              sx={textField}
              id='customerName'
              variant='outlined'
              size='small'
              disabled={true}
              value={customrName.customerName}
              name='customerName'
            />
          </Grid>
          <Grid item md={6} sx={{ alignItems: 'center', mt: 2 }}>
            {location.state.return ? (
              <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                Return Order Number : &nbsp;{' '}
                {location.state?.params?.returnOrderNumber}
              </Typography>
            ) : (
              <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                Store Order Number: &nbsp;{' '}
                {location.state?.params?.storeOrderNumber}
              </Typography>
            )}
          </Grid>
          <Grid item md={12} sx={{ mt: 1 }}>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                sx={{ backgroundColor: 'secondary.light' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3bh-content'
                id='panel3bh-header'
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Order Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>item Name</TableCell>
                        <TableCell align='right'>No Of Items</TableCell>
                        <TableCell align='right'>Unit</TableCell>
                        <TableCell align='right'>Discount</TableCell>
                        <TableCell align='right'>StorePrice</TableCell>
                        <TableCell align='right'>Mrp</TableCell>
                        <TableCell align='right'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {location?.state?.params?.orderDetails?.map(row => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            {row.itemName}
                          </TableCell>
                          <TableCell align='right'>{row.noOfItems}</TableCell>
                          <TableCell align='right'>{row.unit}</TableCell>
                          <TableCell align='right'>{row.discount}</TableCell>
                          <TableCell align='right'>{row.storePrice}</TableCell>
                          <TableCell align='right'>{row.mrp}</TableCell>
                          <TableCell align='right'>
                            {row.canceled ? (
                              <h6 className='h_color'>Cancelled</h6>
                            ) : row.returned ? (
                              <p style={{ fontSize: '14px' }}>Item Returned</p>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter
                      sx={{
                        backgroundColor: '#E1EEF5',
                        position: 'sticky',
                        bottom: 0,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          style={{
                            // TODO Move to .css
                            maxWidth: 30,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            maxWidth: 100,
                            fontSize: 'large',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: 50,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            maxWidth: 50,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {location.state?.params?.totalDiscount === null
                            ? 0
                            : location.state?.params?.totalDiscount}
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: 50,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {location.state?.params?.totalStorePrice}
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: 100,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {location.state?.params?.totalMrp}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: 100,
                            fontSize: 'large',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {/* {storeOrderObj?.totalDiscount?.toFixed(3)} */}
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: 100,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {/* Rs: {storeOrderObj?.totalAmount?.toFixed(3)} */}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: 100,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ mt: 0.5 }}>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  sx={{ backgroundColor: 'secondary.light' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel3bh-content'
                  id='panel3bh-header'
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Agent Selection
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AgentSelection
                    storeOrderObj={location.state.params}
                    returnOrder={location.state.return}
                    // snackbar={snackbar}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ReturnOrders;
