import * as React from 'react';
import {
  Box,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  Divider,
  Tooltip,
  Typography,
  Button,
  Alert,
  Popover,
} from '@mui/material';
import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import './style.css';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import Logout from '@mui/icons-material/Logout';
import Auth from '../../modules/Auth';
import PullNotification from '../pullNotification/Notifications';
import { serverConfig } from '../../config/server-config';
import AppRegistrationSharpIcon from '@mui/icons-material/AppRegistrationSharp';
import SideDrawer from '../sideDrawer/SideDrawer';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import UpstoreProperties from '../../modules/UpstoreProperties';
import AllPermissions from '../../modules/AllPermissions';

import StorefrontIcon from '@mui/icons-material/Storefront';

import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ShortcutIcon from '@mui/icons-material/Shortcut';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [settingAnchor, setSettingAnchor] = React.useState(null);
  const [shortcutAnchor, setShortcutAnchor] = React.useState(null);
  const [propertyValue, setPropertyValue] = React.useState(null);
  const [companyDetails, setCompanyDetails] = React.useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(settingAnchor);
  const open2 = Boolean(shortcutAnchor);
  React.useEffect(() => {
    getCompanyDetails();
    // systemProperty();
  }, []);
  // const systemProperty = () => {
  //   setPropertyValue(UpstoreProperties.getProperty('ESTORE_ENABLED'));
  // };
  const iconStyle = {
    fontSize: '35px',
    color: '#3B78C9',
    cursor: 'pointer',
    padding: '2px',
  };
  const icons = [
    {
      icon: <DashboardIcon sx={iconStyle} />,
      shortcut: 'Dashboard(ALT+D)',
      route: '/dashboard/customer',
    },
    {
      icon: <DashboardCustomizeIcon sx={iconStyle} />,
      shortcut: 'VendorDB(ALT+P)',
      route: '/dashboard/vendor',
    },
    {
      icon: <ShoppingCartIcon sx={iconStyle} />,
      shortcut: 'Order(ALT+N)',
      route: '/neworders',
    },
    {
      icon: <StorefrontIcon sx={iconStyle} />,
      shortcut: 'P.Order(ALT+W)',
      route: '/purchase-order',
    },
    {
      icon: <DescriptionIcon sx={iconStyle} />,
      shortcut: 'P.Bill(ALT+B)',
      route: '/purchase-bill',
    },
    {
      icon: <ReceiptIcon sx={iconStyle} />,
      shortcut: 'Expenses(ALT+E)',
      route: '/expenses',
    },

    {
      icon: <PaymentsIcon sx={iconStyle} />,
      shortcut: 'C.Payments(ALT+F)',
      route: '/payments/customer',
    },
    {
      icon: <PaymentIcon sx={iconStyle} />,
      shortcut: 'V.Payments(ALT+S)',
      route: '/payments/vendor',
    },
    {
      icon: <PersonAddAltIcon sx={iconStyle} />,
      shortcut: 'Customer(ALT+C)',
      route: '/customers',
    },
    {
      icon: <PersonAddAlt1Icon sx={iconStyle} />,
      shortcut: 'Vendor(ALT+V)',
      route: '/vendors',
    },
  ];

  const handleProfileIconClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateTo = useNavigate();
  // const location = useLocation();

  // const pathNames = location.pathname.split('/').filter(x => x);
  // const getCategoriesList = event => {
  //   Category.getCategoryByStoreId(Auth.getStoreId(), response => {
  //     if (response.status === 'success') {
  //       setCategoryList(response.data);
  //     } else {
  //       setCategoryList([]);
  //     }
  //   });
  // };
  const getCompanyDetails = () => {
    setCompanyDetails(JSON.parse(localStorage.getItem('storeCompanyDetails')));
  };
  const handleSettingIconClick = event => {
    setSettingAnchor(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setSettingAnchor(null);
  };
  const handleShortCut = () => {
    setShortcutAnchor(true);
  };
  let subscriptionPlan = JSON.parse(
    localStorage.getItem('storeSubscriptionPlans')
  );
  const subscriptionEnabled =
    subscriptionPlan?.userSubscribeServiceModelList?.find(
      item => item?.serviceName === 'eCommerce'
    );

  const gotoRoute = route => {
    navigateTo(route);
  };
  const handCloseShortcut = () => {
    setShortcutAnchor(null);
  };
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <SideDrawer />

      <Box sx={{ flexGrow: 1, ml: 7 }}>
        <AppBar
          position='fixed'
          sx={{ backgroundColor: 'secondary.light', height: '70px' }}
          elevation={3}
        >
          <Toolbar>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box component='div'>
                <img
                  onClick={() => navigateTo('/')}
                  style={{
                    height: '60px',
                    marginLeft: '45px',
                  }}
                  src={Logo}
                  alt='upstore logo'
                />
              </Box>

              {Auth.getUserRoleId() === '-1' ? null : (
                <>
                  {companyDetails?.suspend && (
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Alert
                        className='subscription'
                        severity='error'
                        variant='outlined'
                      >
                        Subscription about to expire, please subscribe for
                        hassle free usage!
                      </Alert>
                    </Box>
                  )}

                  <Box
                    sx={{
                      marginLeft: 'auto',
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    {AllPermissions.getUserAccessiblePermissions('Orders') !==
                      undefined &&
                    AllPermissions.getUserAccessiblePermissions('Orders')
                      .write === false ? null : (
                      <Tooltip title='Create Order (Alt+N)' placement='bottom'>
                        <AddShoppingCartIcon
                          fontSize='small'
                          sx={{
                            color: '#3B78C9',
                            mr: 0.5,
                            mt: '1px',
                            cursor: 'pointer',
                          }}
                          onClick={() => navigateTo('/neworders')}
                        />
                      </Tooltip>
                    )}
                    <ShortcutIcon
                      sx={{ cursor: 'pointer' }}
                      color='primary'
                      onClick={handleShortCut}
                    />
                    <PullNotification color='primary' />

                    <SettingsOutlinedIcon
                      sx={{ color: '#3B78C9', mr: 1 }}
                      onClick={handleSettingIconClick}
                    />
                    <HelpOutlineIcon sx={{ color: '#3B78C9', mr: 1 }} />
                    <Tooltip title='Account' placement='bottom'>
                      <AccountBoxOutlinedIcon
                        sx={{ color: '#3B78C9', cursor: 'pointer' }}
                        onClick={handleProfileIconClick}
                      />
                    </Tooltip>
                  </Box>
                </>
              )}
            </Box>

            <Popover
              anchorReference='anchorPosition'
              id={id}
              open={open2}
              anchorEl={shortcutAnchor}
              anchorPosition={{ top: 50, left: 980 }}
              onClose={handCloseShortcut}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box
                sx={{
                  p: 2,
                  width: '300px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {icons.map(({ icon, shortcut, route }, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '30%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <span onClick={() => gotoRoute(route)}>{icon}</span>
                    <Typography variant='subtitle1' fontSize='9px'>
                      {shortcut}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Popover>

            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,

                sx: {
                  backgroundColor: '#dee2e6',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  // width: '130px',
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: '#dee2e6',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigateTo('/user-profile')}>
                <Avatar /> {Auth.getUserName()}
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => {
                  Auth.logout();
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
          {serverConfig.isUpstoreCloud ? (
            <Menu
              anchorEl={settingAnchor}
              id='account-menu1'
              open={open1}
              onClose={handleCloseSetting}
              onClick={handleCloseSetting}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 50,
                    height: 50,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 10,
                    width: 10,
                    height: 10,
                    bgcolor: 'grey',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Typography
                variant='h6'
                style={{
                  margin: '3px',
                  textAlign: 'center',
                }}
              >
                Configurations
              </Typography>
              <Divider />

              <MenuItem onClick={() => navigateTo('/Popular-Searches')}>
                Popular Searches
              </MenuItem>
              {subscriptionEnabled ? (
                <MenuItem onClick={() => navigateTo('/banners')}>
                  Banners
                </MenuItem>
              ) : null}
              <MenuItem onClick={() => navigateTo('/Properties')}>
                Properties
              </MenuItem>
            </Menu>
          ) : null}
        </AppBar>
      </Box>
    </>
  );
};
export default Header;
