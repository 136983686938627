import React, { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Vendor from '../../modules/Vendor';
import { styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Auth from '../../modules/Auth';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import Store from '../../modules/Store';

const VendorDialog = props => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: props.buttonClicked === 'Edit' ? props.editVendorData : null,
  });
  const [submittedButton, setSubmittedButton] = useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [address, setAddress] = React.useState({
    state: '',
    country: '',
    city: '',
  });

  const [openDialog, setOpenDialog] = useState(props.openDialog);
  React.useEffect(() => {
    getAllcountries();

    if (props.buttonClicked === 'Edit') {
      getAllStates(props?.editVendorData?.country);
      getAllCities(props?.editVendorData?.country, props.editVendorData.state);
      setAddress({
        ...address,
        state: props.editVendorData.state,
        country: props?.editVendorData?.country,
        city: props?.editVendorData?.city,
      });
    }
  }, []);
  // for custom styling

  const textField = {
    width: '100%',
    fieldset: { borderColor: '#3B78c9' },

    //
  };

  let navigateTo = useNavigate();
  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
  });

  // function for adding new vendor
  const onSubmitVendor = dat => {
    setSubmittedButton(true);
    let data = {
      address: dat.address,
      contactNo: dat.contactNo,
      email: dat.email,
      name: dat.name,
      status: 'NEW',
      city:
        dat.city === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().city
          : dat.city,
      country:
        dat.country === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().country
          : dat.country,
      state:
        dat.state === '' && Store.companyDetails?.businessType !== 'MANDI'
          ? Store.getCompanyDetails().state
          : dat.state,
      openingBalance: dat.openingBalance,
      gstNo: dat.gstNo,
      storeId: Auth.getStoreId(),
    };
    if (props.buttonClicked == 'Add') {
      Vendor.saveNewVendor(data, response => {
        if (response.status === 'success') {
          props.snackbar('Vendor Added  successfully', 'success');
          props.reloadVendorList();
          setSubmittedButton(false);
          props.closeDialog();
          navigateTo('/vendor-details', {
            state: response?.data?.id,
          });
        } else if (response.data == 416) {
          props.snackbar(`Limit exceeded please upgrade your plan`, 'error');
          props.closeDialog();
        } else {
          props.snackbar('Error occurred while Add Vendor', 'error');
          props.closeDialog();
        }
      });
    }
    if (props.buttonClicked === 'Edit') {
      if (props.type === 'statement') props.getUpdatedLedger(false);
      Vendor.updateVendor(props.editVendorData.id, data, response => {
        if (response.status === 'success') {
          props.reloadVendorList(0);
          props.forReloadVendor(response.data.id);
          props.closeDialog();
          setSubmittedButton(false);
          if (props.type === 'statement') {
            props.getUpdatedLedger(true);
            props.snackbar('Vendor edited  successfully', 'success');
          }
        } else {
          console.log('Error occurred while Edit Vendor');
          props.closeDialog();
        }
      });
    }
  };
  const handleChange = e => {
    getAllStates(e.target.value);
    setAddress({
      ...address,
      country: e.target.value,
    });
  };
  const handleStateChange = e => {
    const country = getValues('country');
    getAllCities(country, e.target.value);
    setAddress({
      ...address,
      state: e.target.value,
    });
  };
  const getAllcountries = () => {
    let allcountries = Country.getAllCountries();

    setSelectedCountry(allcountries);
  };
  const getAllStates = code => {
    let states = State.getStatesOfCountry(code);
    setStateList(states);
  };
  const getAllCities = (country, isoCode) => {
    let cities = City.getCitiesOfState(country, isoCode);
    setCityList(cities);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Dialog open={openDialog} onClose={props.closeDialog} maxWidth='md'>
          <form noValidate onSubmit={handleSubmit(onSubmitVendor)}>
            <>
              <DialogTitle>
                <Typography
                  variant='h4'
                  textAlign='center'
                  className='dialog-title'
                >
                  {props.buttonClicked === 'Edit'
                    ? 'Edit Vendor Details'
                    : 'Add New Vendor'}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container columnSpacing={4} rowSpacing={1}>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Name
                    </Typography>
                    <StyledTextField
                      {...register('name', {
                        required: ' Name is required',
                      })}
                      sx={textField}
                      size='small'
                      id='vendor name'
                      placeholder='Enter Name'
                      variant='outlined'
                      name='name'
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  {Store.getSelectedTaxType() === 'GST' &&
                    Store.companyDetails?.businessType !== 'MANDI' && (
                      <Grid item xs={12} md={6} sx={{ pt: 1 }}>
                        <Typography variant='subtitle1' className='list-title'>
                          GST No
                        </Typography>
                        <StyledTextField
                          {...register('gstNo', {
                            pattern: {
                              value:
                                /^\d{2}[A-Z]{5}\d{4}[A-Z][0-9A-Z][Z]{1}[0-9A-Z]$/,
                              message: 'Please enter a valid Gst Number',
                            },
                          })}
                          sx={textField}
                          id='gstNo'
                          autoComplete='off'
                          variant='outlined'
                          size='small'
                          placeholder='Enter GST no'
                          name='gstNo'
                          error={Boolean(errors.gstNo)}
                          helperText={errors.gstNo?.message}
                        />
                      </Grid>
                    )}
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Email
                    </Typography>
                    <StyledTextField
                      {...register('email', {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Please enter a valid e-mail address',
                        },
                      })}
                      sx={textField}
                      size='small'
                      type='text'
                      id='email'
                      placeholder='Enter Email'
                      variant='outlined'
                      name='email'
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Contact No
                    </Typography>
                    <StyledTextField
                      sx={textField}
                      {...register('contactNo', {
                        required: 'Contact no is required',
                      })}
                      size='small'
                      type='number'
                      id='contactNo'
                      placeholder='Enter Number'
                      variant='outlined'
                      name='contactNo'
                      error={Boolean(errors.contactNo)}
                      helperText={errors.contactNo?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Address
                    </Typography>
                    <StyledTextField
                      {...register('address', {
                        required: 'Address is required',
                      })}
                      multiline
                      minRows={2}
                      sx={textField}
                      size='small'
                      type='text'
                      id='address'
                      placeholder='Enter Address'
                      variant='outlined'
                      name='address'
                      error={Boolean(errors.address)}
                      helperText={errors.address?.message}
                    />
                  </Grid>
                  {Store.companyDetails?.businessType !== 'MANDI' && (
                    <>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          pt: 1,
                          mt: Store.getSelectedTaxType() === 'GST' ? 2 : 0,
                        }}
                      >
                        <Typography variant='subtitle1'>Country</Typography>

                        <TextField
                          {...register('country')}
                          sx={textField}
                          id='country'
                          select
                          autoComplete='off'
                          variant='outlined'
                          onChange={handleChange}
                          size='small'
                          placeholder='Select Country '
                          name='country'
                          value={address.country}
                          error={Boolean(errors.country)}
                          helperText={errors.country?.message}
                        >
                          {selectedCountry?.map((item, index) => (
                            <MenuItem value={item?.isoCode} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={6} sx={{ pt: 1 }}>
                        <Typography variant='subtitle1'>State</Typography>
                        <TextField
                          {...register('state')}
                          sx={textField}
                          id='state'
                          autoComplete='off'
                          variant='outlined'
                          size='small'
                          select
                          value={address.state}
                          onChange={handleStateChange}
                          placeholder='Select State'
                          name='state'
                          error={Boolean(errors.state)}
                          helperText={errors.state?.message}
                        >
                          {stateList?.map((item, index) => (
                            <MenuItem value={item?.isoCode} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sx={{ pt: 1 }}>
                        <Typography variant='subtitle1'>City</Typography>
                        <TextField
                          {...register('city')}
                          sx={textField}
                          id='city'
                          select
                          autoComplete='off'
                          variant='outlined'
                          size='small'
                          onChange={e =>
                            setAddress({
                              ...address,
                              city: e.target.value,
                            })
                          }
                          value={address.city}
                          placeholder='Select City'
                          name='city'
                          error={Boolean(errors.city)}
                          helperText={errors.city?.message}
                        >
                          {cityList?.map((item, index) => (
                            <MenuItem value={item?.name} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}

                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Opening Balance
                    </Typography>
                    <TextField
                      {...register('openingBalance', {
                        required: 'Opening balance is required',
                      })}
                      sx={textField}
                      size='small'
                      id='openingBalance'
                      disabled={
                        props.buttonClicked === 'Add'
                          ? false
                          : props?.editVendorData
                          ? props?.editVendorData?.status === 'NEW'
                            ? false
                            : true
                          : false
                      }
                      type='number'
                      placeholder='Enter Opening balance'
                      variant='outlined'
                      name='openingBalance'
                      error={Boolean(errors.openingBalance)}
                      helperText={errors.openingBalance?.message}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={props.closeDialog}
                  className='cancel-button'
                  variant='outlined'
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  className='button-color'
                  type='submit'
                  value='submit'
                  sx={{
                    p: 0,
                    textTransform: 'capitalize',
                    fontSize: '12px',

                    width: '126px',
                    height: '27px',
                  }}
                  disabled={submittedButton}
                >
                  {props.buttonClicked === 'Edit'
                    ? 'Update Details'
                    : 'Add New Vendor'}
                </Button>
              </DialogActions>
            </>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default VendorDialog;
