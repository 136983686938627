import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { Button, TextField, Typography } from '@mui/material';
function AdjustPaymentDialog({
  openDialog,
  adjustAmountCallBack,
  onCloseDialog,
  disableButton,
}) {
  const [adjustAmount, setAdjustAmount] = React.useState('');
  const [adjustRemark, setAdjustRemark] = React.useState('');

  const textField = {
    width: 'auto',
    height: '60px',
  };

  const callAdjustmentAmount = event => {
    adjustAmountCallBack(
      adjustRemark,
      adjustAmount,
      setAdjustAmount,
      setAdjustRemark
    );
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={onCloseDialog}>
        <Typography
          variant='h5'
          sx={{ p: 2, textAlign: 'center', fontSize: '24px' }}
        >
          Adjustment Balance
        </Typography>
        <Typography variant='h5' sx={{ pl: 2 }}>
          Amount
        </Typography>
        <DialogContent>
          <TextField
            size='small'
            sx={textField}
            id='amount'
            autoFocus
            variant='standard'
            placeholder='amount'
            type='number'
            onChange={event => setAdjustAmount(event.target.value)}
          />
          <br />
          <Typography variant='h5'>Remark*</Typography>
          <TextField
            sx={{ width: '300px' }}
            margin='dense'
            id='remark'
            placeholder='Enter remark'
            type='remark'
            fullWidth
            onChange={event => setAdjustRemark(event.target.value)}
          />{' '}
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            sx={{
              width: '126px',
              height: '27px',
              textTransform: 'capitalize',
            }}
            onClick={onCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            sx={{
              width: '126px',
              height: '27px',
              textTransform: 'capitalize',
            }}
            disabled={disableButton}
            onClick={event => {
              callAdjustmentAmount(event);
            }}
          >
            Pay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdjustPaymentDialog;
