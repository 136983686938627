import React from 'react';

import Header from '../../component/header/Header';
import { Box, Grid, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import PopularSearch from '../../modules/PopularSearch';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import DeleteIcon from '@mui/icons-material/Delete';

import { Loading } from '../../component/loading/Loading';
import Auth from '../../modules/Auth';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';

function PopularSearches() {
  const [searchData, setSearchData] = React.useState([]);

  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getPopularSearches();
  }, []);
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleDeleteSearches = id => {
    PopularSearch.deletePopularSearch(id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('search  deleted successfully', 'success');
        getPopularSearches();
      }
    });
  };
  const getPopularSearches = () => {
    PopularSearch.getPopularSearches(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setSearchData(response.data);
      }
    });
  };

  const columns = [
    {
      field: 'productName',
      headerName: 'Product Name',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',

      align: 'left',
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',

      align: 'left',
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <DeleteIcon
            sx={{ color: '#3B78C9' }}
            onClick={() => {
              handleDeleteSearches(params.row.id);
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Header />

      <Box
        sx={{
          ml: 7,
          mt: 7,
        }}
      >
        <Grid container>
          <Grid item md={12} sx={{ pt: 1, ml: 1 }}>
            <BreadCrumb pageName="Popular Searches" />
            <div style={{ height: '100vh', width: '100%' }}>
              <DataGrid
                density="compact"
                components={{
                  Toolbar: GridToolbar,
                }}
                sx={{
                  '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                    backgroundColor: 'secondary.light',
                  },
                }}
                columns={columns}
                rows={searchData}
                pageSize={20}
                rowsPerPageOptions={[20]}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}

export default PopularSearches;
