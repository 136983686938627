import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Button,
  Tabs,
  Tab,
  ListItemText,
  ListItemButton,
  AppBar,
  Skeleton,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Loading } from '../../component/loading/Loading';
import { ArrowLeft } from '@mui/icons-material';
import Header from '../../component/header/Header';
import Add from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import Customer from '../../modules/Customer';
import Edit from '@mui/icons-material/Edit';
import AddEditCustomer from '../../component/addEditCustomer/AddEditCustomer';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import CustomerOverview from '../../component/customerOverview/CustomerOverview';
import CustomerBills from '../../component/customerBills/CustomerBills';
import CustomerPaymentHistory from '../../component/customerPaymentHistory/CustomerPaymentHistory';
import CustomerStatement from '../../component/customerStatement/CustomerStatement';
import PaymentSection from '../../component/paymentSection/PaymentSection';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import './style.css';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { debounce } from '../../utils';
const page_size = 18;
function CustomerDetails() {
  let navigate = useNavigate();
  const listRef = React.useRef();
  const location = useLocation();
  const [customers, setCustomers] = React.useState([]);

  const [customerList, setCustomerList] = React.useState([]);
  const [customerDetails, setCustomerDetails] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [addEditCustomerDialog, setAddEditCustomerDialog] =
    React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState('');
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(
    location?.state?.id || location?.state
  );
  const [hideGrid, setHideGrid] = React.useState(false);
  const [getUpdatedLedger, setGetUpdatedLedger] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);

  React.useEffect(() => {
    getCustomerList(pageNo);
    getCustomerById(location.state.id || location.state);
  }, []);

  const getCustomerList = pageNum => {
    Customer.getAllCustomers(Auth.getStoreId(), pageNum, true, response => {
      if (response.status === 'success') {
        let filteredArr = response.data.filter(
          item => item.customerName?.toUpperCase() !== 'ANONYMOUS'
        );
        if (response.data.length < page_size) {
          setHasMoreData(false);
        }
        setCustomerList([...customerList, ...filteredArr]);

        setCustomers(filteredArr); // Setting this set only once, and then passing while searching customers in customer-details page
      } else {
        setCustomerList([]);
      }
      setSkeletonLoading(false);
    });
  };

  const reloadCustomer = () => {
    // Customer.reloadCustomers(Auth.getStoreId(), response => {
    Customer.getAllCustomers(Auth.getStoreId(), 0, false, response => {
      if (response.status === 'success') {
        let filteredArr = response.data.filter(
          item => item.customerName?.toUpperCase() !== 'ANONYMOUS'
        );
        setCustomerList(filteredArr);
      } else {
        setCustomerList([]);
      }
    });
  };

  // getting customers by id
  const getCustomerById = id => {
    Customer.getCustomerById(id, response => {
      if (response.status === 'success') {
        setLoader(false);
        setCustomerDetails(response.data);
      } else {
        setLoader(false);
        setCustomerDetails(null);
      }
    });
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleListItemButtonClick = (event, customerId) => {
    setSelectedItemIndex(customerId);
    getCustomerById(customerId);
  };
  const hideCustomerGrid = () => {
    setHideGrid(!hideGrid);
  };

  const handleFilterCustomerChange = event => {
    let search = event.target.value;
    if (event.target.value) {
      Customer.getCustomerSearchName(search, Auth.getStoreId(), response => {
        if (response.status === 'success') {
          setCustomerList(response.data);
        }
      });
    } else {
      getCustomerList(pageNo);
    }
  };

  const handlecustomerChange = React.useCallback(
    debounce(handleFilterCustomerChange, 300),
    []
  );

  // const handleFilterCustomerChange = event => {
  //   let search = event.target.value;
  //   let filteredArray = customers.filter(customer => {
  //     return customer.customerName
  //       ?.toUpperCase()
  //       .includes(search.toUpperCase());
  //   });

  //   if (filteredArray.length <= 0 || event.target.value === '') {
  //     getCustomerList();
  //   } else {
  //     setCustomerList(filteredArray);
  //   }

  //   if (event.target.value === '') {
  //     getCustomerList();
  //   } else {
  //     setCustomerList(filteredArray);
  //   }
  // };
  const handleGetUpdatedLedger = param => {
    if (param === true) setGetUpdatedLedger(true);
    else setGetUpdatedLedger(false);
  };

  const handleCutomerUpdateCallback = data => {
    setCustomerDetails(data);
  };
  const handleScroll = () => {
    const list = listRef.current;

    if (
      Math.abs(
        list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1 &&
          hasMoreData
      )
    ) {
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;
      setSkeletonLoading(true);
      setPageNo(pageNum);
      getCustomerList(pageNum);
    }
  };

  return (
    <>
      <Header pageName='Customer Details' />
      <Box
        sx={{
          paddingLeft: '3.5rem',
          paddingRight: '.2rem',
          mt: 6,
        }}
      >
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container sx={{ padding: '5px' }}>
              <Grid item md={12} sm={12} xs={12}>
                <BreadCrumb pageName='Customer-details' />
              </Grid>
              <Grid item md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                <Grid item md={6} sm={6} xs={6}>
                  <Tooltip title='Go to customers' placement='bottom'>
                    <Button
                      startIcon={<ArrowLeft />}
                      variant='text'
                      underline='none'
                      sx={{
                        mb: 1,

                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                      }}
                      onClick={() => navigate('/customers')}
                    >
                      customer
                    </Button>
                  </Tooltip>{' '}
                </Grid>
                {AllPermissions.getUserAccessiblePermissions('Customer') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('Customer')
                  .write === false ? null : (
                  <Grid item md={6} sm={6} xs={6}>
                    <Tooltip title='Add Customer' placement='bottom'>
                      <Button
                        variant='contained'
                        onClick={() => {
                          setAddEditCustomerDialog(true);
                          setButtonClicked('Add');
                        }}
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          bgcolor: '#3B78C9',
                          p: 0,
                          float: 'right',
                          width: '126px',
                          height: '27px',
                        }}
                      >
                        <Add fontSize='small' sx={{ color: 'white' }} />
                        <Typography
                          sx={{
                            fontSize: { md: '13px', sm: '13px', xs: '11px' },
                          }}
                        >
                          New Customer
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>

              {hideGrid === true ? (
                <Grid sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
                  <ArrowRightSharpIcon
                    sx={{
                      ml: '6px',
                      color: ' #3B78C9',
                      width: '35px',
                      height: '35px',
                    }}
                    onClick={hideCustomerGrid}
                  />
                </Grid>
              ) : (
                <Grid
                  // bgcolor="#AFC9F2"
                  // bgcolor="#fafbfc"
                  className='customer-list'
                  item
                  xs={12}
                  md={2}
                  lg={2}
                  sx={{
                    height: { md: '800px', sm: '550', xs: '60px' },
                    overflowY: 'scroll',
                  }}
                  ref={listRef}
                  onScroll={handleScroll}

                  // className={`${hideGrid ? 'hide-grid' : 'show-grid'}`}
                >
                  <Box
                    bgcolor='#AFC9F2'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      p: 2,
                      position: 'sticky',
                      zIndex: '1',
                      top: '0px',
                      display: {
                        lg: 'flex',
                        md: 'none',
                        xs: 'none',
                        sm: 'none',
                      },
                      width: '100%',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='search customer'
                      style={{
                        borderRadius: '50px',

                        // borderBottomLeftRadius: '50px',
                        minWidth: '145px',
                        height: '30px',
                        paddingLeft: '6px',
                        fontSize: '14px',
                        border: 'none',
                      }}
                      onChange={handlecustomerChange}
                    />

                    <ArrowLeftSharpIcon
                      sx={{
                        ml: '1px',
                        color: ' #3B78C9',
                        width: '40px',
                        height: '35px',
                      }}
                      onClick={hideCustomerGrid}
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: 0.5,
                      // height: { md: '800px', sm: '550', xs: '60px' },
                      overflowX: { xs: 'scroll', md: 'hidden' },
                      overflowY: 'scroll',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'column', sm: 'row' },
                    }}
                  >
                    {customerList.length > 0
                      ? customerList.map((customer, index) => {
                          return (
                            <ListItemButton
                              selected={selectedItemIndex === customer.id}
                              key={customer.id}
                              className='customerBtn'
                              sx={{
                                height: '60px',
                                color: '#3B78C9',
                                ml: { md: 0, sm: 5, xs: 5 },
                                mr: { md: 0, sm: 5, xs: 5 },
                              }}
                              onClick={event => {
                                handleListItemButtonClick(event, customer.id);
                              }}
                            >
                              <ListItemText>
                                <Typography
                                  color='black'
                                  sx={{
                                    fontSize: {
                                      md: '18px',
                                      sm: '15px',
                                      xs: '14px',
                                    },
                                  }}
                                >{`${customer.customerName}`}</Typography>
                              </ListItemText>
                            </ListItemButton>
                          );
                        })
                      : null}
                    {hasMoreData ? (
                      <Box>
                        <Skeleton
                          width='100%'
                          height={50}
                          style={{ textAlign: 'center' }}
                        >
                          Scroll down to Load more ...
                        </Skeleton>
                      </Box>
                    ) : (
                      <Box>
                        <Skeleton
                          width='100%'
                          height={50}
                          style={{ textAlign: 'center' }}
                        >
                          No More Customers
                        </Skeleton>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={`${hideGrid === true ? 11 : 10}`}>
                {customerDetails !== null && (
                  <Box
                    bgcolor='#ecf6fb'
                    sx={{
                      overflowX: { xs: 'scroll', md: 'hidden' },
                      overflowY: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'row', sm: 'row' },
                      py: 2,
                      px: 2,
                      justifyContent: 'space-between',
                      mb: { xs: 2 },
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='#6a769c'
                      sx={{ fontSize: { md: '15px', sm: '14px', xs: '12px' } }}
                    >
                      {`${customerDetails.customerName}`}
                      {AllPermissions.getUserAccessiblePermissions(
                        'Customer'
                      ) !== undefined &&
                      AllPermissions.getUserAccessiblePermissions('Customer')
                        .write === false ? null : (
                        <Tooltip title='Edit Customer' placement='bottom'>
                          <Edit
                            fontSize='small'
                            onClick={() => {
                              setAddEditCustomerDialog(true);
                              setButtonClicked('Edit');
                            }}
                            sx={{
                              color: '#3B78C9',
                              fontSize: { md: '18px', sm: '18px', xs: '16px' },
                              cursor: 'pointer',

                              ml: 1,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                    <Tooltip
                      title={`${customerDetails?.address}`}
                      placement='bottom'
                    >
                      <Typography
                        variant='h5'
                        color='#6a769c'
                        sx={{
                          width: '60%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                          fontSize: { md: '15px', sm: '14px', xs: '12px' },
                          display: {
                            xs: 'none',
                            md: 'inline-block',
                          },
                        }}
                      >
                        {customerDetails?.address}
                      </Typography>
                    </Tooltip>
                    <Typography
                      variant='h5'
                      color='#6a769c'
                      sx={{
                        fontSize: { md: '15px', sm: '14px', xs: '12px' },
                        pl: { md: 0, sm: 0, xs: 2 },
                      }}
                    >
                      {customerDetails?.contact}
                    </Typography>

                    {AllPermissions.getUserAccessiblePermissions('Orders') !==
                      undefined &&
                    AllPermissions.getUserAccessiblePermissions('Orders')
                      .write === false ? null : (
                      <>
                        {customerDetails?.active === false ? (
                          <Tooltip
                            title='You cannot create order for inActive user'
                            placement='left-start'
                          >
                            <Button
                              variant='outlined'
                              onClick={() =>
                                openUpstoreSnackBar(
                                  'You cannot create order for inActive user',
                                  'error'
                                )
                              }
                              sx={{
                                textTransform: 'capitalize',
                                backgroundColor: 'lightgray',

                                width: '126px',
                                height: '27px',
                                color: 'gray',

                                ml: { md: 0, sm: 0, xs: 2 },
                              }}
                            >
                              Create order
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            variant='outlined'
                            sx={{
                              textTransform: 'capitalize',
                              width: '126px',
                              height: '27px',
                              color: '#3B78C9',
                              fontSize: { md: '', sm: '', xs: '10px' },
                              ml: { md: 0, sm: 0, xs: 2 },
                            }}
                            onClick={() =>
                              setTimeout(() => {
                                navigate('/neworders', {
                                  state: { customerDetails },
                                });
                              }, 10)
                            }
                          >
                            Create order
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                )}
                <CustomerTabsComponent
                  snackbar={openUpstoreSnackBar}
                  customerId={customerDetails !== null && customerDetails.id}
                  customerList={customerList !== null && customerList}
                  customerDetails={customerDetails}
                  name={customerDetails?.customerName}
                  getUpdatedLedger={getUpdatedLedger}
                  handleCutomerUpdateCallback={handleCutomerUpdateCallback}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {addEditCustomerDialog ? (
        <AddEditCustomer
          openDialog={addEditCustomerDialog}
          closePopup={() => {
            setAddEditCustomerDialog(false);
            setButtonClicked('');
          }}
          customerId={customerDetails !== null && customerDetails.id}
          buttonClicked={buttonClicked}
          editCustomerData={customerDetails && customerDetails}
          snackbar={openUpstoreSnackBar}
          fromCustomerDetail={true}
          reloadCustomersList={getCustomerList}
          forReloadCustomer={getCustomerById && getCustomerById}
          getUpdatedLedger={handleGetUpdatedLedger}
          type={'statement'}
        />
      ) : null}

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}

      {AllPermissions.getUserAccessiblePermissions('Customer') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Customer').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: 3, sm: 2, xs: 0 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomerTabsComponent = ({
  customerId,

  customerList,
  snackbar,
  customerDetails,
  name,
  getUpdatedLedger,
  handleCutomerUpdateCallback,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 1,
        bgcolor: '#F1F4F5',
      }}
    >
      <Box
        sx={{
          overflowX: { xs: 'scroll', md: 'hidden' },
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: { xs: 'row', md: 'row', sm: 'row' },
        }}
      >
        <AppBar
          position='static'
          sx={{
            bgcolor: 'secondary.light',
            overflowX: { xs: 'scroll', md: 'hidden' },
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: { xs: 'row', md: 'row', sm: 'row' },
          }}
        >
          <Tabs
            variant='scrollable'
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            {/* <TabList
            variant="scrollable"
            onChange={handleChange}
            aria-label="simple tabs example"
          > */}
            <Tab label='Overview' {...a11yProps(0)} />
            <Tab label='Bills' {...a11yProps(1)} />
            <Tab label='Payment History' {...a11yProps(2)} />
            <Tab label='Make Payment' {...a11yProps(3)} />
            <Tab label='Statements' {...a11yProps(4)} />
            {/* </TabList> */}
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        <CustomerOverview key={customerId} customerId={customerId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomerBills key={customerId} customerId={customerId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomerPaymentHistory
          key={customerId}
          customerId={customerId}
          snackbar={snackbar}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PaymentSection
          key={customerId}
          customerId={customerId}
          snackbar={snackbar}
          type='Customer'
          customerDetails={customerDetails}
          name={name}
          handleCutomerUpdateCallback={handleCutomerUpdateCallback}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CustomerStatement
          getUpdatedLedger={getUpdatedLedger}
          key={customerId}
          customerObj={customerId}
          customerList={customerList !== null && customerList}
        />
      </TabPanel>
    </Box>
  );
};

export default CustomerDetails;
