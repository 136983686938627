import LOADER_GIF from '../../assets/loader.gif';

export default function LoadingIndicator() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{
          width: '102px',
          height: '102px',
          margin: '15px',
          objectFit: 'contain',
        }}
        src={LOADER_GIF}
      />
    </div>
  );
}
