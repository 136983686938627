import UpstoreApi from '../UpstoreApi';

class MandiChalan {
  addNewChalan = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/challan/`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding chalan ', error.message);
        callBack({ status: 'error' });
      });
  };

  getAllChalanByStoreId = (storeId, pageNumber, deleted, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/challan/getAllChallansByStoreId/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting mandi Chalan', error.message);
        callBack({ status: 'error' });
      });
  };

  getChalanByStoreId = (storeId, pageNumber, deleted, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/challan/get-challans/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting mandi Chalan', error.message);
        callBack({ status: 'error' });
      });
  };
  getAllWatakByStoreId = (storeId, pageNumber, deleted, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/watak/getAllWataksByStoreId/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting  Watak', error.message);
        callBack({ status: 'error' });
      });
  };
  getWatakByStoreId = (storeId, pageNumber, deleted, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/watak/get-wataks/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting  Watak', error.message);
        callBack({ status: 'error' });
      });
  };
  getChalanById = (ChalanId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/challan/getChallanById/${ChalanId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting  Chalan', error.message);
        callBack({ status: 'error' });
      });
  };
  getFreightDetailsChalanId = (ChalanId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/freight-details/getFreightDetailsByChallanId/${ChalanId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting  freight details',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateChalanById = (updatedObj, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/challan/update/${id}`,
      data: updatedObj,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating Chalan', error.message);
        callBack({ status: 'error' });
      });
  };
  updateWatakById = (updatedObj, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/watak/update/${id}`,
      data: updatedObj,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating Watak', error.message);
        callBack({ status: 'error', message: error.message });
      });
  };
  deleteChallanDetailsById = (id, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/challan-detail/delete/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting chalan item', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteChalan = (chalanId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/challan/delete/${chalanId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting Chalan', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteWatak = (watakId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/watak/delete/${watakId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting Watak', error.message);
        callBack({ status: 'error' });
      });
  };

  generateWatak = (challanId, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/watak/generateWatak/${challanId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while generating Watak ', error.message);
        callBack({ status: 'error' });
      });
  };

  getWatak = (id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/watak/getWatakById/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting Watak ', error.message);
        callBack({ status: 'error' });
      });
  };
  getWatakByIds = (ids, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/watak/get-by-ids`,
      data: ids,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting Wataks ', error.message);
        callBack({ status: 'error' });
      });
  };
}
export default new MandiChalan();
