import UpstoreApi from '../UpstoreApi';

class PurchaseBills {
  addPurchaseBill = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/purchaseBill/addPurchaseBill',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while adding new product Item',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getPurchaseBillByStoreId = (storeId, deleted, pageNum, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseBill/getAllPurchaseBillByStoreId/${storeId}?deleted=${deleted}&pageNumber=${pageNum}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
        return response.data;
      })
      .catch(error => {
        console.log(
          'Error occure while getting Bill  by storeId',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getBillByBillId = (Id, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/purchaseBill/getPurchaseBillById/' + Id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting bill by id', error.message);
        callBack({ status: 'error' });
      });
  };
  //search for Purchase Billl

  searchPurchaseBillByName = (storeId, searchVal, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseBill/search/${storeId}?search=${searchVal}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while searching  Purchase Bill ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  //delete Purchase Bill
  deletePurchaseBill = (id, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/purchaseBill/delete-by-id/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting purchase bill', error.message);
        callBack({ status: 'error' });
      });
  };
  //Update Purchase Bill
  updatePurchaseBill = (data, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/purchaseBill/updatePurchaseBillById/${id}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while updating Purchase  Bill',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}
export default new PurchaseBills();
