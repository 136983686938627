import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  Autocomplete,
  createFilterOptions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  NativeSelect,
} from '@mui/material';
import MISSING_IMG from '../../assets/images/missing-img.jpg';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as moment from 'moment';
import User from '../../modules/User';
import Auth from '../../modules/Auth';
import DatePickerComp from '../datePicker/DatePicker';
import Expense from '../../modules/Expense';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Logo from '../../modules/Logo';
import Validation from '../../modules/Validation';
import Store from '../../modules/Store';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import AllPermissions from '../../modules/AllPermissions';

const filter = createFilterOptions();
const filter2 = createFilterOptions();
function CreateExpenses(props) {
  const [successUpload, setSuccessUpload] = React.useState({});
  const [thirdPartyLabel, setThirdPartyLabel] = React.useState(false);
  const [updateExpenese, setUpdateExpenese] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState({});
  const [showImageSection, setShowImageSection] = React.useState({
    show: false,
    expenseNo: '',
  });
  const [expenseData, setExpenseData] = React.useState(
    props?.updatedExpenseData
  );
  const [updatedExpense, setUpdatedExpense] = React.useState(
    props?.updatedExpenseData
  );
  const [imageUrl, setImageUrl] = React.useState('');
  const [expenseSubmitted, setExpenseSubmitted] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [nonStopCheck, setNonStopCheck] = React.useState(
    props?.repeatExpensedData?.repeat === -1 ? true : false
  );
  const [errorImage, setErrorImage] = React.useState(false);
  const [repeatId, setRepeatId] = React.useState('');
  const [showUploadedImage, setShowUploadedImage] = React.useState(false);
  const [repeatExpenseData, setRepeatExpenseData] = React.useState({});
  const [accordionExpanded, setAccordionExpanded] = React.useState('panel1');
  const [updateImage, setUpdateImage] = React.useState(false);
  const [showRepeatExpenses, setShowRepeatExpenses] = React.useState(true);
  const [bankDetails, setBankDetails] = React.useState([]);

  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };
  const typography = {
    fontSize: '15px',
    mb: 1,
  };

  React.useEffect(() => {
    if (Validation.isBlankObjectNot(updatedExpense)) {
      setExpenseSubmitted(true);
      setShowUploadedImage(true);
    }
    getAllUsers();
    getAccountDetails();
  }, []);

  const handleCaptureExp = () => {
    if (imageUrl.files) {
      if (imageUrl.files.length !== 0) {
        uploadImagesExp(imageUrl.files);
      }
    }
  };

  const handleChange = event => {
    setChecked1(event.target.checked);
    setRepeatExpenseData(props?.repeatExpensedData);
  };

  const handleChange1 = event => {
    setNonStopCheck(event.target.checked);
  };
  const uploadImagesExp = images => {
    Array.from(images).map((imageFile, index) => {
      if (index < 3) {
        handleImageCompressionExp(imageFile, index);
      } else {
        return 'error';
      }
    });
  };

  const handleImageCompressionExp = async (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await handleImageCompressionExp(
          imageFile,
          options
        );
        await uploadToServerExp(compressedFile, index);
      } catch (error) {
        console.log('Image upload failed ' + error);
      }
    } else {
      await uploadToServerExp(imageFile, index);
    }
  };
  const uploadToServerExp = (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB

    if (imageFile.size <= maxFileSize) {
      const formData = new FormData();
      const imageName = `expenses/imgExpene_${showImageSection?.expenseNo}`;

      formData.append('image', imageFile);
      formData.append('name', imageName);
      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          setShowUploadedImage(true);
          setShowImageSection({ ...showImageSection, show: false });
          setUpdateImage(false);

          props.snackbar('Expense Image Uploaded successfully', 'success');
        } else {
          console.log('error');
          props.snackbar('Error while uploading image', 'error');
        }
      });
    }
  };

  const getAllUsers = () => {
    User.getAllUsers(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setAllUsers(response.data || {});
      } else {
        console.log('error');
      }
    });
  };

  const addDefaultImage = ev => {
    setErrorImage(true);
    ev.target.src = MISSING_IMG;
  };

  const repeatExpenseses = repeatId => {
    let data1 = {
      ...repeatExpenseData,

      expenseId: repeatId !== undefined ? repeatId : null,
      repeat: nonStopCheck === true ? '-1' : repeatExpenseData.repeat,
      storeId: Auth.getStoreId(),

      scheduledDate: moment(repeatExpenseData.scheduledDate).format(
        'YYYY-MM-DD'
      ),
    };
    Expense.addRepeatExpenses(data1, response => {
      if (response.status === 'success') {
        setRepeatExpenseData(response.data);
        props.snackbar(
          ' Expense and Repeat Expense Added Successfully',
          'success'
        );

        props.reloadExpenses(0);

        // setShowImageSection({
        //   show: true,
        //   expenseNo: response.data.expenseNumber,
        // });
      } else {
        setExpenseSubmitted(false);
        props.snackbar(
          'Error occured while adding Expense and  Repeat expenses',
          'error'
        );
      }
    });
  };

  const options = [
    { expenseType: 'Payment' },
    { expenseType: 'Bank' },
    { expenseType: 'UPI' },
    { expenseType: 'Electricity Bill' },
  ];
  const onSubmitExpenses = () => {
    if (expenseData?.amount === '' || expenseData?.amount === undefined) {
      props.snackbar('Amount is required', 'error');
    } else if (
      expenseData?.expenseType === '' ||
      expenseData?.expenseType === undefined
    ) {
      props.snackbar('Type is required', 'error');
    } else if (
      expenseData?.invoice === '' ||
      expenseData?.invoice === undefined
    ) {
      props.snackbar('Invoice is required', 'error');
    } else if (
      expenseData?.comment === '' ||
      expenseData?.comment === undefined
    ) {
      props.snackbar('Comment is required', 'error');
    } else if (
      expenseData?.userName === '' ||
      expenseData?.userName === undefined
    ) {
      props.snackbar('UserName is required', 'error');
    } else {
      setExpenseSubmitted(true);

      let data = {
        ...expenseData,

        storeId: Auth.getStoreId(),

        date: moment(expenseData?.date).format('YYYY-MM-DD'),
        billDate: moment(expenseData?.billDate).format('YYYY-MM-DD[T]HH:mm:ss'),
      };

      if (checked1) {
        Expense.addExpensesList(data, response => {
          if (response.status === 'success') {
            setRepeatId(response?.data?.id);
            repeatExpenseses(response?.data?.id);
            setShowImageSection({
              show: true,
              expenseNo: response.data.expenseNumber,
            });
            setExpenseData(response.data);
            props.reloadExpenses(0);
          } else {
            setExpenseSubmitted(false);
            props.snackbar('Error occured while adding expenses', 'error');
          }
        });
      } else if (!checked1) {
        Expense.addExpensesList(data, response => {
          if (response.status === 'success') {
            props.snackbar('Expense Added Successfully', 'success');
            setShowImageSection({
              show: true,
              expenseNo: response.data.expenseNumber,
            });
            setExpenseData(response.data);

            props.reloadExpenses(0);
            setShowRepeatExpenses(false);
          } else {
            setExpenseSubmitted(false);
            props.snackbar('Error occured while adding expenses', 'error');
          }
        });
      }
    }
  };

  const onExpenseDateChange = date => {
    setExpenseData({ ...expenseData, date: date });
  };
  const onExpenseRepeatDateChange = date => {
    setRepeatExpenseData({ ...repeatExpenseData, scheduledDate: date });
  };
  const onExpenseBillDateChange = date => {
    setExpenseData({ ...expenseData, billDate: date });
  };

  const onUpdateExpense = () => {
    let data1 = {
      ...repeatExpenseData,
      expenseId: expenseData.id,
      repeat: nonStopCheck === true ? -1 : repeatExpenseData.repeat,
      scheduledDate: moment(repeatExpenseData.scheduledDate).format(
        'YYYY-MM-DD'
      ),
    };
    let data = {
      ...expenseData,
      storeId: Auth.getStoreId(),
      date: moment(expenseData.date).format('YYYY-MM-DD'),
      billDate: moment(expenseData.billDate).format('YYYY-MM-DD[T]HH:mm:ss'),
    };
    if (repeatExpenseData.id != null && checked1) {
      Expense.updateRepeatExpense(repeatExpenseData?.id, data1, response => {
        if (response.status === 'success') {
          props.snackbar(
            'update  Repeat Expense Added Successfully',
            'success'
          );
          setExpenseSubmitted(true);
          setUpdateImage(false);
          setShowUploadedImage(true);
          props.reloadExpenses(0);
        } else {
          setExpenseSubmitted(false);
          props.snackbar(
            'Error occured while adding   Repeat expenses',
            'error'
          );
        }
      });

      Expense.updateExpense(data.id, data, response => {
        if (response.status === 'success') {
          props.snackbar('Expense updated Successfully', 'success');
          setExpenseSubmitted(true);
          setUpdateImage(false);
          setShowUploadedImage(true);

          props.reloadExpenses(0);
        } else {
          setExpenseSubmitted(false);
          props.snackbar('Error occured while updating expenses', 'error');
        }
      });
    } else if (repeatExpenseData?.id == null && checked1) {
      Expense.addRepeatExpenses(data1, response => {
        if (response.status === 'success') {
          setRepeatExpenseData(response.data);
          props.snackbar(
            ' Expense and Repeat Expense Added Successfully',
            'success'
          );
          setExpenseSubmitted(true);
          setUpdateImage(false);
          setShowUploadedImage(true);

          props.reloadExpenses(0);
        } else {
          setExpenseSubmitted(false);
          props.snackbar(
            'Error occured while adding Expense and  Repeat expenses',
            'error'
          );
        }
      });
    } else {
      Expense.updateExpense(data.id, data, response => {
        if (response.status === 'success') {
          props.snackbar('Expense updated Successfully', 'success');
          setExpenseSubmitted(true);
          setUpdateImage(false);
          setShowUploadedImage(true);

          props.reloadExpenses(0);
        } else {
          setExpenseSubmitted(false);
          props.snackbar('Error occured while updating expenses', 'error');
        }
      });
    }
  };
  const handleChangeAccordion = panel => (event, newExpanded) => {
    setAccordionExpanded(newExpanded ? panel : false);
  };
  const stopRepeatExpenses = () => {
    let data1 = {
      ...repeatExpenseData,
      expenseId: expenseData.id,
      repeat: 0,
      scheduledDate: moment(repeatExpenseData.scheduledDate).format(
        'YYYY-MM-DD'
      ),
    };
    Expense.updateRepeatExpense(repeatExpenseData?.id, data1, response => {
      if (response.status === 'success') {
        props.snackbar('Repeat Expense Stopped Successfully', 'success');
        setRepeatExpenseData(response.data);
        setExpenseSubmitted(true);
        props.reloadExpenses(0);
      } else {
        setExpenseSubmitted(false);
        props.snackbar(
          'Error occured while   changing the  Repeat expenses',
          'error'
        );
      }
    });
  };
  const getAccountDetails = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setBankDetails(response.data);
      } else {
        setBankDetails([]);
      }
    });
  };

  return (
    <div>
      <Grid container sx={{ pt: 8, pl: 7 }}>
        <Grid item md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              sx={{
                p: 0,
                fontSize: '13px',
                textTransform: 'capitalize',
                height: '27px',
                width: '126px',
              }}
              onClick={() => {
                props.handleCreateCallBack();
                setExpenseData({});
                setExpenseSubmitted(false);
                setRepeatExpenseData({});
              }}
            >
              <ArrowLeftIcon /> Back Expenses
            </Button>
            {AllPermissions.getUserAccessiblePermissions('Expenses') !==
              undefined &&
            AllPermissions.getUserAccessiblePermissions('Expenses').write ===
              false ? null : (
              <>
                {expenseSubmitted ? (
                  <Button
                    sx={{
                      textTransform: 'capitalize',
                      height: '27px',
                      width: '126px',
                      bgcolor: '#3B78C9',
                      color: '#fff',
                      mr: 5,
                      mb: 1,
                    }}
                    variant='contained'
                    onClick={() => {
                      setExpenseSubmitted(false);
                      setUpdateExpenese(true);
                      setUpdateImage(true);
                      setShowUploadedImage(false);
                      setShowImageSection({
                        ...showImageSection,
                        expenseNo: expenseData.expenseNumber,
                      });
                      setShowRepeatExpenses(true);
                    }}
                  >
                    Edit
                  </Button>
                ) : null}
              </>
            )}
          </Box>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{ pl: 1, pr: 1, mt: 2, alignItems: 'center', display: 'flex' }}
          >
            <Grid item md={12}>
              <Accordion
                expanded={accordionExpanded === 'panel1'}
                onChange={handleChangeAccordion('panel1')}
              >
                <AccordionSummary
                  sx={{ backgroundColor: 'secondary.light' }}
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Typography sx={{ width: '97%', flexShrink: 0 }}>
                    Expenses{' '}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item md={4} sm={4} xs={12}>
                      <Typography variant='h6' sx={typography}>
                        Date<sup>*</sup>
                      </Typography>
                      <Box>
                        <DatePickerComp
                          sx={textField}
                          disabled={expenseSubmitted}
                          value={expenseData?.date}
                          maxDate={new Date()}
                          dateChange={onExpenseDateChange}
                          type='Expense'
                        />
                      </Box>
                    </Grid>

                    <Grid item md={4} sm={4} xs={12}>
                      <Typography variant='h6' sx={typography}>
                        Bill Date<sup>*</sup>
                      </Typography>
                      <Box>
                        <DatePickerComp
                          sx={textField}
                          disabled={expenseSubmitted}
                          value={expenseData?.billDate}
                          maxDate={new Date()}
                          dateChange={onExpenseBillDateChange}
                          type='Expense'
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4} sm={4} xs={12}>
                      <Typography variant='h6' sx={typography}>
                        Type
                        <sup>*</sup>
                      </Typography>

                      <Autocomplete
                        sx={textField}
                        size='small'
                        disabled={expenseSubmitted}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== '') {
                            let newVl = { expenseType: params.inputValue };

                            filtered.push(newVl);
                          }

                          return filtered;
                        }}
                        value={
                          Object.keys(updatedExpense).length > 0
                            ? expenseData || {}
                            : expenseData?.expenseType
                        }
                        autoHighlight
                        onChange={(event, newValue) => {
                          setExpenseData({
                            ...expenseData,
                            expenseType: newValue?.expenseType,
                          });
                        }}
                        options={options}
                        getOptionLabel={option =>
                          typeof option == 'string'
                            ? (option = '')
                            : option !== undefined
                            ? `${option.expenseType} ` || {}
                            : ''
                        }
                        renderOption={(props, option) => (
                          <>
                            <Box {...props}>{option?.expenseType}</Box>
                          </>
                        )}
                        renderInput={params => (
                          <TextField {...params} variant='outlined' />
                        )}
                      />
                    </Grid>

                    <Grid item md={4} sm={4} xs={12} sx={{ mt: 2 }}>
                      <Typography variant='h6' sx={typography}>
                        Amount
                        <sup>*</sup>
                      </Typography>
                      <TextField
                        sx={textField}
                        disabled={expenseSubmitted}
                        placeholder='Enter Amount'
                        type='number'
                        size='small'
                        value={expenseData?.amount}
                        onChange={event => {
                          setExpenseData({
                            ...expenseData,
                            amount: event.target.value,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item md={4} sm={4} xs={12} sx={{ mt: 2 }}>
                      <Typography variant='h6' sx={typography}>
                        Invoice
                        <sup>*</sup>
                      </Typography>
                      <TextField
                        sx={textField}
                        disabled={expenseSubmitted}
                        placeholder='Enter Invoice'
                        size='small'
                        onChange={event => {
                          setExpenseData({
                            ...expenseData,
                            invoice: event.target.value,
                          });
                        }}
                        value={expenseData?.invoice}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} sx={{ mt: 2.5 }}>
                      <Typography variant='h6' sx={typography}>
                        User Name
                        <sup style={{ color: 'red' }}>*</sup>
                      </Typography>

                      {!thirdPartyLabel ? (
                        <>
                          <Autocomplete
                            sx={textField}
                            size='small'
                            filterOptions={(options, params) => {
                              const filtered2 = filter2(options, params);

                              if (params.inputValue !== '') {
                                let newVl = { username: params.inputValue };
                                filtered2.push(newVl);
                              }

                              return filtered2;
                            }}
                            value={
                              Object.keys(updatedExpense).length > 0
                                ? expenseData || {}
                                : expenseData?.username
                            }
                            disabled={expenseSubmitted}
                            options={allUsers}
                            autoHighlight
                            onChange={(event, newValue) => {
                              setExpenseData({
                                ...expenseData,
                                userName: newValue?.username,
                                userId: newValue.id,
                              });
                            }}
                            getOptionLabel={option =>
                              typeof option == 'string'
                                ? (option = '')
                                : option !== undefined
                                ? `${
                                    option.username ||
                                    option.firstName ||
                                    option.userName
                                  } ` || {}
                                : ''
                            }
                            ListboxProps={{
                              style: { maxHeight: 200, overflow: 'auto' },
                            }}
                            renderOption={(props, option) => (
                              <Box {...props}>{option?.username}</Box>
                            )}
                            renderInput={params => <TextField {...params} />}
                          />
                        </>
                      ) : (
                        <TextField
                          placeholder='Enter User Name'
                          sx={textField}
                          size='small'
                        />
                      )}
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} sx={{ mt: 2.5 }}>
                      <Typography variant='h6' sx={typography}>
                        Select Bank
                      </Typography>
                      <FormControl fullWidth>
                        <TextField
                          sx={textField}
                          disabled={expenseSubmitted}
                          size='small'
                          select
                          value={expenseData?.bankId}
                          onChange={event => {
                            let id = event.target.value;
                            setExpenseData({
                              ...expenseData,
                              bankId: id ? id : null,
                            });
                          }}
                        >
                          <MenuItem value=''>Select Bank</MenuItem>
                          {bankDetails.map(item => {
                            return (
                              <MenuItem value={item.id}>
                                {item.bankName}({item.accountNo?.slice(-4)})
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} sx={{ mt: 2 }}>
                      <Typography variant='h6' sx={typography}>
                        Notice
                        <sup>*</sup>
                      </Typography>
                      <TextField
                        sx={textField}
                        disabled={expenseSubmitted}
                        size='small'
                        onChange={event => {
                          setExpenseData({
                            ...expenseData,
                            comment: event.target.value,
                          });
                        }}
                        placeholder='Enter Notice'
                        multiline
                        rows={3}
                        value={expenseData?.comment}
                      />
                    </Grid>

                    <Grid item md={4} sm={12} xs={12} sx={{ ml: 'auto' }}>
                      {(showImageSection?.show === true &&
                        showImageSection.expenseNo !== '') ||
                      updateImage ? (
                        <Box sx={{ width: '100%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              border: '2px solid gray',
                              m: '3rem',
                              height: '400px',
                            }}
                          >
                            <input
                              accept='image/*'
                              id='icon-button-file'
                              type='file'
                              style={{ display: 'none' }}
                              onChange={e => {
                                setImageUrl(e.target);
                              }}
                            />
                            <label htmlFor='icon-button-file'>
                              <IconButton
                                color='primary'
                                aria-label='upload picture'
                                component='span'
                              >
                                <CloudUploadIcon
                                  sx={{
                                    fontSize: '180px',
                                    color: successUpload.selected
                                      ? 'green'
                                      : 'gray',
                                    mt: '3rem',
                                  }}
                                />
                              </IconButton>
                              {successUpload.selected ? (
                                <>
                                  <h1
                                    style={{
                                      fontSize: '20px',
                                      textAlign: 'center',
                                      mt: '5px',
                                    }}
                                  >
                                    {successUpload.message}
                                  </h1>
                                </>
                              ) : (
                                <h1
                                  style={{
                                    fontSize: '20px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Upload Image{' '}
                                </h1>
                              )}
                            </label>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              pb: 5,
                            }}
                          >
                            <Button
                              variant='contained'
                              sx={{ textTransform: 'capitalize' }}
                              onClick={() => {
                                handleCaptureExp();
                              }}
                            >
                              Uplaod Image
                            </Button>
                          </Box>
                        </Box>
                      ) : null}

                      {showUploadedImage ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 2,
                          }}
                        >
                          <img
                            src={Logo.getExpanseImageUrl(
                              showImageSection?.expenseNo ||
                                props?.updatedExpenseData?.expenseNumber
                            )}
                            onError={addDefaultImage}
                            alt='Expense Img'
                            style={{
                              height: errorImage ? '200px' : '400px',
                              width: errorImage ? '200px' : '400px',
                            }}
                          />
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {showRepeatExpenses ? (
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  label='Repeat Expense'
                  control={
                    <Checkbox checked={checked1} onChange={handleChange} />
                  }
                />
              </Box>
            ) : null}
            {checked1 === true ? (
              <Grid item md={12} sx={{ mt: 2 }}>
                <Accordion
                  expanded={accordionExpanded === 'panel2' || checked1}
                  onChange={handleChangeAccordion('panel2')}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: 'secondary.light' }}
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls='panel2-content'
                    id='panel2-header'
                  >
                    <Grid
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      item
                      md={10}
                    >
                      <Typography sx={{ width: '97%', flexShrink: 0 }}>
                        Repeat Expenses{' '}
                      </Typography>
                      {repeatExpenseData.id && repeatExpenseData.repeat ? (
                        <Box>
                          <Button
                            variant='contained'
                            sx={{
                              textTransform: 'capitalize',
                              height: '27px',
                              width: '150px',
                              p: 0,
                            }}
                            onClick={stopRepeatExpenses}
                          >
                            <Typography sx={{ fontSize: '13px' }} noWrap>
                              Stop Repeat Expenses{' '}
                            </Typography>
                          </Button>
                        </Box>
                      ) : null}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item md={4} sm={4} xs={12} sx={{ mt: 0.5 }}>
                        {' '}
                        <Typography variant='h6' sx={typography}>
                          Schedule Date{' '}
                        </Typography>
                        <DatePickerComp
                          disabled={expenseSubmitted}
                          value={repeatExpenseData?.scheduledDate}
                          maxDate={new Date()}
                          dateChange={onExpenseRepeatDateChange}
                          type='scheduledDate'
                        />
                      </Grid>

                      <Grid item md={4} sm={4} xs={12}>
                        <Typography variant='h6' sx={typography}>
                          Occurance{' '}
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            sx={textField}
                            disabled={expenseSubmitted}
                            size='small'
                            select
                            value={repeatExpenseData?.occurrence}
                            onChange={event => {
                              setRepeatExpenseData({
                                ...repeatExpenseData,
                                occurrence: event.target.value,
                              });
                            }}
                          >
                            <MenuItem value='Daily'>Daily</MenuItem>
                            <MenuItem value='Weekly'>Weekly</MenuItem>
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>

                      <Grid item md={4} sm={4} xs={12}>
                        <Typography variant='h6' sx={typography}>
                          Repeat
                        </Typography>
                        <TextField
                          sx={textField}
                          disabled={expenseSubmitted}
                          value={repeatExpenseData?.repeat}
                          size='small'
                          type='number'
                          placeholder='Repeat Times'
                          onChange={event => {
                            setRepeatExpenseData({
                              ...repeatExpenseData,
                              repeat: event.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 3,
                        }}
                      >
                        <Typography
                          variant='h6'
                          sx={{ fontSize: '15px', mb: 1 }}
                        >
                          Non Stop
                        </Typography>
                        <Checkbox
                          disabled={expenseSubmitted}
                          checked={nonStopCheck}
                          onChange={handleChange1}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : (
              ''
            )}
            <Grid item md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pt: 5,
                }}
              >
                {updateExpenese ? (
                  <Button
                    disabled={expenseSubmitted}
                    variant='contained'
                    sx={{
                      p: 0,
                      ml: 2,
                      textTransform: 'capitalize',
                      height: '27px',
                      width: '126px',
                    }}
                    onClick={() => {
                      onUpdateExpense();
                    }}
                  >
                    <Typography sx={{ fontSize: '13px ' }} noWrap>
                      update & Confirm
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    disabled={expenseSubmitted}
                    variant='contained'
                    sx={{
                      ml: 2,
                      textTransform: 'capitalize',
                      height: '27px',
                      width: '126px',
                    }}
                    onClick={onSubmitExpenses}
                  >
                    Confirm
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateExpenses;
