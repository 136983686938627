import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Button,
  TextField,
  Tabs,
  Tab,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Vendor from '../../modules/Vendor';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import PurchaseBills from '../../modules/PurchaseBills';
import Auth from '../../modules/Auth';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.css';
import * as moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import { useLocation } from 'react-router-dom';
import CreatePurchaseBill from '../../component/createPurchaseBill/CreatePurchaseBill';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { Loading } from '../../component/loading/Loading';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import { debounce } from '../../utils';

function PurchaseBill() {
  let location = useLocation();
  const [vendorBillData, setVendorBillData] = React.useState([]);
  const [currency, setCurrency] = React.useState(null);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [updatedPurchseBillData, setUpdatedPurchseBillData] = React.useState(
    {}
  );
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [deletedBills, setDeletedBills] = React.useState(false);

  const [createPurchaseBill, setCreatePurchaseBill] = React.useState(false);
  const [vendorPurchaseBillDetails, setVendorPurchaseBillDetails] =
    React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    IdOfDeletableBill: '',
  });

  React.useEffect(() => {
    getVendorByStoreId();
    getCurrency();

    if (location.state) {
      editDaftedData('');
    }
  }, [deletedBills]);

  const editDaftedData = rowData => {
    setCreatePurchaseBill(true);
    setUpdatedPurchseBillData(rowData);
  };

  const deleteOrder = id => {
    PurchaseBills.deletePurchaseBill(id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Order is already been deleted ', 'success');
        getAllBillsByStoreId(0);
      } else {
        console.log('error');
      }
    });
  };
  const viewPurchaseBill = row => {
    if (row.deleted) {
      openUpstoreSnackBar('You cant View the Deleted Purchase Bill', 'error');
    } else {
      handleBillByBillNo(row.id);
      editDaftedData(row);
    }
  };

  const getVendorByStoreId = () => {
    setLoader(true);
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), 0, false, response => {
      if (response.status === 'success') {
        getAllBillsByStoreId(0);
      } else {
        console.log('error');
      }
    });
  };
  const columns = [
    {
      field: 'Bill No',
      headerName: 'Bill No',
      width: 150,

      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      type: 'number',

      renderCell: params => (
        <>
          <Typography
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
            onClick={() => {
              viewPurchaseBill(params.row);
            }}
          >
            {params.row.billNumber}
          </Typography>

          <Box sx={{ pl: 2 }}>
            {params.row?.edited === true ? (
              <Tooltip title='Edited'>
                <EditIcon sx={{ fill: 'black', fontSize: '17px', mr: 3 }} />
              </Tooltip>
            ) : null}
            {params.row?.deleted ? (
              <Tooltip title='Order Has Been Deleted'>
                <DeleteIcon
                  sx={{
                    fill: 'red',
                    fontSize: '17px',
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        </>
      ),
    },
    {
      field: 'vendorName',
      headerName: 'Name',
      width: 250,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Tooltip title={params?.row?.vendorName}>
            <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
              {`${params?.row?.vendorName}`}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'Bill Amount',
      headerName: 'Bill Amount',
      width: 230,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.billAmount}{' '}
            {currency ? <HTMLRenderer html={currency} /> : null}
          </Typography>
        </>
      ),
    },
    {
      field: 'VendorBillDate',
      headerName: 'Bill Date',
      width: 200,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.vendorBillDate === null
              ? 'N / A'
              : moment
                  .utc(params.row.vendorBillDate)
                  .local()
                  .format('DD-MM-yyyy')}
          </Typography>
        </>
      ),
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 250,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography
            sx={{
              cursor: 'pointer',
              color: params.row.status === 'CONFIRMED' ? 'green' : '#3B78C9',
            }}
          >
            {params.row.status}
          </Typography>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      type: 'action',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              {AllPermissions.getUserAccessiblePermissions('PurchaseBill') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('PurchaseBill')
                .write === false ? (
                <DeleteIcon />
              ) : (
                <Tooltip title='Delete Order' placement='bottom'>
                  <DeleteIcon
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                    onClick={event => {
                      if (params.row.deleted) {
                        openUpstoreSnackBar(
                          'Order is already been deleted ',
                          'error'
                        );
                      } else {
                        setOpenDeleteDialog({
                          open: true,
                          IdOfDeletableBill: params.row.id,
                        });
                      }
                    }}
                  />
                </Tooltip>
              )}
            </>
          </Box>
        </>
      ),
    },
  ];

  const getAllBillsByStoreId = pageNum => {
    setLoader(true);
    PurchaseBills.getPurchaseBillByStoreId(
      Auth.getStoreId(),
      deletedBills,
      pageNum,
      response => {
        if (response.status === 'success') {
          let sortedBillData = response.data.sort(
            (a, b) => b.billNumber - a.billNumber
          );
          setVendorBillData(sortedBillData);
          setLoader(false);
        }
      }
    );
  };
  // callback for setBill
  const backToPurchaseBill = () => {
    setCreatePurchaseBill(false);
    setUpdatedPurchseBillData({});
    getAllBillsByStoreId(0);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDeletedBills(false);
    } else {
      setDeletedBills(true);
    }
  };
  const handlePreviousPage = () => {
    let pageNum = pageNumber - 1;
    setPageNumber(pageNum);
    getAllBillsByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 1;
    setPageNumber(pageNum);
    getAllBillsByStoreId(pageNum);
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const handleBillByBillNo = id => {
    PurchaseBills.getBillByBillId(id, response => {
      if (response.status === 'success') {
        setVendorPurchaseBillDetails(response.data);
      } else {
        openUpstoreSnackBar('error while getting bill by id', 'error');
      }
    });
  };

  const handleSearchBills = debounce(e => handleSearchBill(e), 300);

  const handleSearchBill = data => {
    PurchaseBills.searchPurchaseBillByName(
      Auth.getStoreId(),
      data,
      response => {
        if (response.status === 'success') {
          if (deletedBills) {
            let deleted = response.data.filter(item => item.deleted);
            let sortedBillData = deleted.sort(
              (a, b) => b.billNumber - a.billNumber
            );
            setVendorBillData(sortedBillData);
          } else {
            let notDeleted = response.data.filter(item => !item.deleted);
            let sortedBillData = notDeleted.sort(
              (a, b) => b.billNumber - a.billNumber
            );
            setVendorBillData(sortedBillData);
          }
        }
        if (response.status === 'error') {
          getAllBillsByStoreId(0);
        }
      }
    );
  };
  const reloadCreatePurchasePurchaseBill = () => {
    setUpdatedPurchseBillData('');
    setCreatePurchaseBill(false);
    setTimeout(() => {
      setCreatePurchaseBill(true);
    }, 5);
  };

  const handleDeleteBillCallback = usersChoice => {
    if (usersChoice === true) {
      PurchaseBills.deletePurchaseBill(
        openDeleteDialog.IdOfDeletableBill,
        response => {
          if (response.status === 'success') {
            openUpstoreSnackBar(
              'Order Has been deleted Successfully',
              'success'
            );
            getAllBillsByStoreId(0);
            setOpenDeleteDialog(false);
          } else {
            console.log('error');
          }
        }
      );
    }
  };

  return (
    <div>
      <Box>
        <Header />
        <Box sx={{ mt: 7, ml: 7 }}>
          {!createPurchaseBill ? (
            <Grid container sx={{ padding: '5px' }}>
              <Grid item md={3} sm={6} xs={8}>
                <BreadCrumb pageName='Purchase' />
              </Grid>
              <Grid
                item
                md={6}
                sm={0}
                xs={0}
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'block' },
                  mb: 1,
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent='space-around'
                  alignItems={'center'}
                >
                  <TextField
                    sx={{ width: '70%' }}
                    name='search'
                    placeholder='Search Bill by Bill Number and Vendor Name..'
                    onChange={event => {
                      handleSearchBills(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              {AllPermissions.getUserAccessiblePermissions('PurchaseBill') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('PurchaseBill')
                .write === false ? null : (
                <Grid item md={3} sm={6} xs={4}>
                  <Button
                    variant='contained'
                    sx={{
                      width: '126px',
                      height: '27px',
                      float: 'right',
                      textTransform: 'capitalize',
                      backgroundColor: '#3B78C9',
                    }}
                    onClick={() => {
                      setCreatePurchaseBill(true);
                    }}
                  >
                    <Typography
                      sx={{ fontSize: { md: '15px', sm: '12px', xs: '10px' } }}
                    >
                      Create Bill
                    </Typography>
                  </Button>
                </Grid>
              )}
              {loader ? (
                <Loading />
              ) : (
                <Grid item md={12} sm={12} xs={12}>
                  {vendorBillData && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label='Order Tabs'
                        >
                          <Tab label='Confirmed Bills' {...a11yProps(0)} />
                          <Tab label='Deleted Bills' {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <BillsTab
                        columns={columns}
                        data={vendorBillData}
                        value={value}
                      />
                      <Grid
                        style={{
                          padding: '1.6vh',
                          float: 'right',

                          bottom: '0px',
                        }}
                      >
                        <Button
                          disabled={pageNumber <= 0}
                          onClick={handlePreviousPage}
                          sx={{ color: '#3B78C9' }}
                        >
                          Prev
                        </Button>
                        <Button
                          disabled={vendorBillData.length < 20}
                          sx={{ margin: '1vh', color: '#3B78C9' }}
                          onClick={handleNextPage}
                        >
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          ) : (
            <CreatePurchaseBill
              backToPurchaseBill={backToPurchaseBill}
              purchaseDetails={location.state}
              snackbar={openUpstoreSnackBar}
              updatedPurchseBillData={updatedPurchseBillData}
              vendorPurchaseBillDetails={vendorPurchaseBillDetails}
              reloadPurchaseBill={reloadCreatePurchasePurchaseBill}
            />
          )}
        </Box>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      {openDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              IdOfDeletableBill: '',
            })
          }
          deleteCallback={handleDeleteBillCallback}
          dialogContent='Your Bill will be deleted permanently , Are you sure you want to delete it ?'
        />
      ) : null}
      {AllPermissions.getUserAccessiblePermissions('PurchaseBill') !==
        undefined &&
      AllPermissions.getUserAccessiblePermissions('PurchaseBill').read ===
        false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </div>
  );
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`order-tabpanel-${index}`}
        aria-labelledby={`order-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `order-tab-${index}`,
      'aria-controls': `order-tabpanel-${index}`,
    };
  }

  function BillsTab({ columns, data, value }) {
    return (
      <Box sx={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                boxShadow: 2,
                border: 6,
                backgroundColor: '#fff',
                borderColor: '#eee',
                color: 'black',
                '& .MuiDataGrid-cell': {
                  outline: 'none',
                  fontSize: '16px',
                  fontWeight: '25px',
                },
                '& .MuiDataGrid-cell:hover': {
                  outline: 'none',
                  color: '#21211f',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& svg': {
                  color: '#3b78c9',
                },
                '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                  color: 'black',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              hideFooter={true}
              pageSize={20}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Bills list'
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                boxShadow: 2,
                border: 6,
                backgroundColor: '#fff',
                borderColor: '#eee',
                color: 'black',
                '& .MuiDataGrid-cell': {
                  outline: 'none',
                  fontSize: '16px',
                  fontWeight: '25px',
                },
                '& .MuiDataGrid-cell:hover': {
                  outline: 'none',
                  color: '#21211f',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& svg': {
                  color: '#3b78c9',
                },
                '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                  color: 'black',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              hideFooter={true}
              pageSize={20}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='Orders list'
            />
          </Box>
        </TabPanel>
      </Box>
    );
  }
}

export default PurchaseBill;
