import React from 'react';
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Tooltip,
} from '@mui/material';
import Payments from '../../modules/Payments';
import { Loading } from '../loading/Loading';
import Customer from '../../modules/Customer';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../deleteDialog/DeleteDialog';
import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';

import Auth from '../../modules/Auth';
import moment from 'moment';

const CustomerPaymentHistory = ({ customerId, snackbar }) => {
  const columns = [
    {
      id: 'paymentNumber',
      label: 'Payment Number',
      minWidth: 200,
      align: 'center',
    },

    { id: 'paymentDate', label: 'Date', minWidth: 200, align: 'left' },

    {
      id: 'amount',
      label: 'Amount ',
      minWidth: 200,
      align: 'left',
    },
    { id: 'paymentType', label: 'Mode', minWidth: 170, align: 'left' },

    // { id: 'Transaction', label: 'Transaction ', minWidth: 200, align: 'left' },
    { id: 'remark', label: 'Remark', minWidth: 200, align: 'left' },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 20,
      align: 'center',
    },
  ];
  const [paymentsData, setPaymentsData] = React.useState([]);
  const [currency, setCurrency] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    deletableRowId: '',
    paymentNumber: '',
  });
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getPayments();
    getCurrency();
  }, []);

  const getPayments = () => {
    setLoader(true);
    Payments.getPaymentsByCustomerId(customerId, response => {
      if (response.status === 'success') {
        setPaymentsData(response.data);
        setLoader(false);
      } else {
        setPaymentsData([]);
        setLoader(false);
      }
    });
  };
  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const handleDeletePaymentCallback = (usersChoice, remark) => {
    let data = {
      deleteComment: remark,
    };
    if (usersChoice === true) {
      Payments.deletePayment(
        openDeleteDialog.deletableRowId,
        data,
        response => {
          if (response.status === 'success') {
            Transaction.log(
              Auth.getStoreId(),
              customerId,
              LogMessage.PAYMENT_DELETED,
              LogMessage.PAYMENT_DELETED_TITLE,
              [openDeleteDialog.paymentNumber, Auth.getUserName()]
            );
            snackbar('Payment was deleted successfully', 'success');
            setOpenDeleteDialog({ open: false });
            getPayments();
          } else {
            snackbar('Payment was not deleted successfully', 'error');
          }
        }
      );
    }
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <>
          {paymentsData.length > 0 ? (
            <Box container='div'>
              <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontSize: 'large',
                            whiteSpace: 'nowrap',
                            backgroundColor: '#eee',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentsData.map(row => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map(column => {
                            const value = row[column.id];

                            return (
                              <>
                                {column.label === 'Remark' ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {!value ? 'N / A' : value}
                                  </TableCell>
                                ) : column.label === 'Actions' ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <Tooltip
                                      title='Delete payment'
                                      placement='bottom'
                                    >
                                      <DeleteIcon
                                        onClick={() => {
                                          setOpenDeleteDialog({
                                            open: true,
                                            deletableRowId: row?.id,
                                            paymentNumber: row?.paymentNumber,
                                          });
                                        }}
                                        sx={{ color: 'error.main' }}
                                      >
                                        Delete
                                      </DeleteIcon>
                                    </Tooltip>
                                  </TableCell>
                                ) : column.label === 'Payment Number' ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value}
                                  </TableCell>
                                ) : column.label === 'Date' ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {row.paymentDate &&
                                      moment(row.paymentDate).format(
                                        'DD/MM/YYYY'
                                      )}
                                  </TableCell>
                                ) : column.label === 'Mode' ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value}
                                    {currency ? (
                                      <HTMLRenderer html={currency} />
                                    ) : null}
                                  </TableCell>
                                )}
                              </>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography color='#3B78C9' variant='h5' textAlign='center'>
              No data present
            </Typography>
          )}
        </>
      )}

      {openDeleteDialog.open ? (
        <DeleteDialog
          reason={true}
          snackbar={snackbar}
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              deletableRowId: '',
              paymentNumber: '',
            })
          }
          deleteCallback={handleDeletePaymentCallback}
          dialogContent=' Are you sure you want to delete this payment ?'
        />
      ) : null}
    </>
  );
};

export default CustomerPaymentHistory;
