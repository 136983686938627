import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Validation from '../../modules/Validation';

function ChangeUserPassword({
  handleClosePassword,
  openPasswordDialog,
  resetPassword,
}) {
  const [password, setPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [validConfirm, setValidConfirm] = useState(false);
  const [validNewPassword, setValidNewPassword] = useState(false);

  const handleResetPassword = (password, openPasswordDialog) => {
    let validPassowrd = isConfirmValid();

    if (validPassowrd !== '') {
      setValidConfirm(true);
    } else {
      setValidConfirm(false);
      if (!validNewPassword) {
        resetPassword(password, openPasswordDialog);
      }
    }
  };

  const isConfirmValid = () => {
    if (password.newPassword === password.confirmPassword) {
      return '';
    } else {
      return 'password and confirm password is not same ';
    }
  };
  const handleBlur = () => {
    if (isConfirmValid() !== '') {
      setValidConfirm(false);
    } else {
      setValidConfirm(true);
    }
  };
  const checkPassword = event => {
    if (Validation.validatePassword(password.newPassword)) {
      setValidNewPassword(false);
    } else {
      setValidNewPassword(true);
    }
  };
  return (
    <div>
      <div>
        <Dialog
          fullWidth='xs'
          open={openPasswordDialog.open}
          onClose={handleClosePassword}
        >
          <DialogTitle>Change User Password </DialogTitle>
          <DialogContent>
            <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
              Enter New Password
            </Typography>
            <TextField
              sx={{ marginTop: '1rem' }}
              size='small'
              variant='standard'
              margin='normal'
              required
              placeholder='Enter New Password'
              fullWidth
              id='newPassword'
              name='newPassword'
              //   disabled={true}

              onChange={e => {
                setPassword({ ...password, newPassword: e.target.value });
                checkPassword();
              }}
              value={password?.newPassword}
              autoComplete='password'
            />
            {validNewPassword && (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>
                Password must contain Special Characters (!@#$%^&*) and
                Number(0,9)
              </p>
            )}
            <Typography style={{ marginTop: '-1.7rem', color: '#8492A8' }}>
              Confirm Password
            </Typography>
            <TextField
              sx={{ marginTop: '1rem' }}
              size='small'
              variant='standard'
              margin='normal'
              required
              type='password'
              placeholder='Enter Confirm Password'
              fullWidth
              id='confirmPassword'
              name='confirmPassword'
              //   disabled={true}
              onChange={e =>
                setPassword({ ...password, confirmPassword: e.target.value })
              }
              onBlur={() => {
                handleBlur();
              }}
              value={password.confirmPassword}
              autoComplete='confirmPassword'
            />
            {validConfirm && (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>
                {isConfirmValid()}
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePassword}>Cancel</Button>
            <Button
              onClick={() =>
                handleResetPassword(
                  password?.newPassword,
                  openPasswordDialog.userData
                )
              }
            >
              Set Password
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ChangeUserPassword;
