import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';

import React from 'react';
import Auth from '../../modules/Auth';
import Daybook from '../../modules/Daybook';

const DayBookEntryDialog = ({
  entryDialog,
  data,
  closeDialog,
  snackBar,
  getAllEntries,
  bankData,
}) => {
  const [ledgerEntry, setLedgerEntry] = React.useState(data);
  const [bankDetails, setBankDetails] = React.useState(bankData);
  const handleChange = event => {
    let { name, value } = event.target;
    setLedgerEntry({ ...ledgerEntry, [name]: value });
  };

  const UpdateEntry = () => {
    let data = {
      ...ledgerEntry,
      lastModifiedDate: new Date(),
    };
    Daybook.UpdateCashBookEntry(data.id, data, response => {
      if (response.status === 'success') {
        snackBar('entry Updated sucessfully', 'success');
        getAllEntries();
        closeDialog();
      } else {
        snackBar('error while   updating', 'error');
      }
    });
  };
  const AddEntry = () => {
    if (checkFieldsVal()) {
      let data = {
        ...ledgerEntry,
        bankId: ledgerEntry.bankId === '' ? null : ledgerEntry.bankId,
        storeId: Auth.getStoreId(),
      };

      Daybook.addCashBookEntry(data, response => {
        if (response.status === 'success') {
          snackBar('entry added sucessfully', 'success');
          getAllEntries();
          closeDialog();
        } else {
          snackBar('error while  adding sucessfully', 'error');
        }
      });
    } else {
      snackBar('please fill all the details ', 'error');
    }
  };
  const checkFieldsVal = () => {
    if (
      ledgerEntry.cashType !== '' &&
      ledgerEntry.credit !== '' &&
      ledgerEntry.description !== ''
    ) {
      return true;
    } else if (
      ledgerEntry.cashType !== '' &&
      ledgerEntry.debit !== '' &&
      ledgerEntry.description !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container>
      <Dialog
        open={entryDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogContent>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <div className='container-fluid'>
                <div className='row clearfix'>
                  <div className='col-md-12 column'>
                    <table
                      className='table table-bordered table-hover'
                      id='tab_logic'
                    >
                      <thead>
                        <tr>
                          <th className='text-center'> Description</th>
                          <th className='text-center'> Credit </th>
                          <th className='text-center'> Debit </th>
                          <th className='text-center'> Bank </th>
                          <th className='text-center'> Cash Type</th>

                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr id='entryTable'>
                          <td>
                            <input
                              type='text'
                              name='description'
                              id='description'
                              value={ledgerEntry?.description}
                              onChange={handleChange}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              disabled={ledgerEntry?.debit || data.debit}
                              name='credit'
                              id='credit'
                              value={ledgerEntry?.credit}
                              onChange={handleChange}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              name='debit'
                              disabled={ledgerEntry?.credit || data.credit}
                              id='debit'
                              value={ledgerEntry?.debit}
                              onChange={handleChange}
                              className='form-control'
                            />
                          </td>
                          <td>
                            {ledgerEntry.id ? (
                              <input
                                type='string'
                                name='bank'
                                id='bank'
                                disabled={ledgerEntry?.bankName}
                                value={ledgerEntry?.bankName}
                                onChange={handleChange}
                                className='form-control'
                              />
                            ) : (
                              <select
                                type='string'
                                name='bankId'
                                id='bank'
                                value={ledgerEntry.bankId}
                                onChange={handleChange}
                                className='form-control'
                              >
                                <option value=''>Select Bank</option>
                                {bankDetails.map(item => {
                                  return (
                                    <option value={item.id}>
                                      {' '}
                                      {item.bankName}(
                                      {item.accountNo?.slice(-4)})
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </td>
                          <td>
                            {ledgerEntry.id ? (
                              <select
                                type='string'
                                name='cashType'
                                id='cashType'
                                value={ledgerEntry?.cashType}
                                onChange={handleChange}
                                className='form-control'
                              >
                                <option>{ledgerEntry?.cashType}</option>
                              </select>
                            ) : (
                              <select
                                type='string'
                                name='cashType'
                                id='cashType'
                                value={ledgerEntry?.cashType}
                                onChange={handleChange}
                                className='form-control'
                              >
                                <option value=''>Select Type</option>
                                <option>Manual</option>
                              </select>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {ledgerEntry.id ? (
            <Button variant='outlined' size='small' onClick={UpdateEntry}>
              Update Entry
            </Button>
          ) : (
            <Button
              variant='outlined'
              size='small'
              disabled={checkFieldsVal === false}
              onClick={AddEntry}
            >
              Add Entry
            </Button>
          )}

          <Button variant='outlined' size='small' onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DayBookEntryDialog;
