import UpstoreApi from '../UpstoreApi';

class BillTransaction {
  getBillTransactionForCustomer = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/transaction/customer/' + customerId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting bill transaction by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  saveBillTransaction = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/transaction',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while saving bill transaction ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new BillTransaction();
