import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  createFilterOptions,
  Select,
  MenuItem,
} from '@mui/material';
import Header from '../../component/header/Header';
import Expense from '../../modules/Expense';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Auth from '../../modules/Auth';
import moment from 'moment';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import User from '../../modules/User';
import Store from '../../modules/Store';

const EXPENSE_TYPE_DATA = [
  { expenseType: 'Payment' },
  { expenseType: 'Bank' },
  { expenseType: 'UPI' },
  { expenseType: 'Electricity Bill' },
];
const filter = createFilterOptions();
const filter2 = createFilterOptions();
export default function BulkExpenses() {
  let navigate = useNavigate();
  const [expenseBulkDataArray, setExpenseBulkDataArray] = React.useState([]);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState({});
  const [bankDetails, setBankDetails] = React.useState([]);

  React.useEffect(() => {
    intializeDataFields('onLoad');
    getAllUsers();
    getAccountDetails();
  }, []);

  const intializeDataFields = calledAt => {
    setExpenseBulkDataArray([]);
    const FIELDS_ARRAY = [];
    let ONE_OBJECT = {
      comment: null,
      amount: null,
      userName: null,
      expenseType: null,
      invoice: null,
      storeId: Auth.getStoreId(),
      billDate: moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss'),
      isValid: false,
    };
    for (let i = 0; i < 10; i++) {
      FIELDS_ARRAY.push({ ...ONE_OBJECT });
    }
    setExpenseBulkDataArray(FIELDS_ARRAY);

    if (calledAt === 'onLoad') return;
    else setButtonDisabled(false);
    return;
  };

  const handleInputchange = (name, value, row, index) => {
    let updatedState = [...expenseBulkDataArray];
    if (name === 'billDate') {
      updatedState[index][name] = moment(new Date(value)).format(
        'YYYY-MM-DD[T]HH:mm:ss'
      );
    } else {
      updatedState[index][name] = value;
    }

    updatedState[index].isValid = checkRowData(row);
    setExpenseBulkDataArray([...updatedState]);
  };

  const addExpenseBulkEx = () => {
    setButtonDisabled(true);
    let data = expenseBulkDataArray.filter(item => item.isValid === true);

    if (data?.length > 0) {
      Expense.addBulkExpensesList(data, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('Bulk Expense Added successfully', 'success');
          intializeDataFields('reintializeFields');
        } else {
          openUpstoreSnackBar(
            'Error Occured while adding Bulk Expense',
            'error'
          );
        }
      });
    } else {
      openUpstoreSnackBar('Please fill atleast one row data', 'error');
    }
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const getAllUsers = () => {
    User.getAllUsers(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setAllUsers(response.data);
      } else {
        console.log('error');
      }
    });
  };

  const checkRowData = object => {
    let flag = true;
    for (const key in object) {
      const element = object[key];

      if (element === null || element === '') {
        if (key === 'bankId') {
          flag = true;
        } else {
          flag = false;
        }
      }
    }
    return flag;
  };
  setTimeout(() => {
    setButtonDisabled(false);
  }, 5000);
  const getAccountDetails = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setBankDetails(response.data);
      } else {
        setBankDetails([]);
      }
    });
  };

  return (
    <>
      <Header />
      <Grid container>
        <Grid item md={12} sx={{ pt: 7, pl: 7.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              startIcon={<ArrowLeftIcon />}
              variant='text'
              underline='none'
              sx={{
                width: '126px',
                height: '27px',
                textTransform: 'capitalize',
              }}
              onClick={() => {
                navigate('/expenses');
              }}
            >
              Expense
            </Button>

            <Box sx={{ ml: 2 }}>
              {' '}
              <BreadCrumb pageName='BulkExpenses' />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ pl: 8, pr: 3, pt: 2, pb: 3 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead sx={{ backgroundColor: 'secondary.light' }}>
              <TableRow>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  Bill Date *
                </TableCell>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  Invoice *
                </TableCell>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  Amount *
                </TableCell>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  User name *
                </TableCell>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  Expense Type *
                </TableCell>
                {Store?.companyDetails?.businessType !== 'MANDI' ? null : (
                  <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                    Bank
                  </TableCell>
                )}
                {}

                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                  Comment *
                </TableCell>
                <TableCell align='center'>Completed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenseBulkDataArray?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    <TextField
                      variant='standard'
                      type='date'
                      value={moment(row?.billDate).format('YYYY-MM-DD')}
                      style={{ height: '30px', p: 2 }}
                      name='billDate'
                      onChange={event =>
                        handleInputchange(
                          event.target.name,
                          event.target.value,
                          row,
                          index
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <TextField
                      variant='standard'
                      value={row?.invoice}
                      type='text'
                      placeholder='Enter Invoice'
                      style={{ height: '30px', width: '100px', p: 2 }}
                      name='invoice'
                      onChange={event =>
                        handleInputchange(
                          event.target.name,
                          event.target.value,
                          row,
                          index
                        )
                      }
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <TextField
                      variant='standard'
                      type='number'
                      value={row?.amount}
                      placeholder='Enter Amount'
                      style={{ height: '30px', width: '100px', p: 2 }}
                      name='amount'
                      onChange={event =>
                        handleInputchange(
                          event.target.name,
                          event.target.value,
                          row,
                          index
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <Autocomplete
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          let newVl = { username: params.inputValue };
                          filtered.push(newVl);
                        }

                        return filtered;
                      }}
                      value={row?.username}
                      options={allUsers}
                      onChange={(event, newValue) => {
                        handleInputchange(
                          'userName',
                          newValue?.username,
                          row,
                          index
                        );
                      }}
                      autoHighlight
                      getOptionLabel={option =>
                        typeof option == 'string'
                          ? (option = '')
                          : option !== undefined
                          ? `${option.username || option.firstName}` || {}
                          : ''
                      }
                      renderOption={(props, option) => (
                        <Box {...props}>{option?.username}</Box>
                      )}
                      style={{ width: 180 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='standard'
                          placeholder='Enter UserName'
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <Autocomplete
                      filterOptions={(options, params) => {
                        const filtered2 = filter2(options, params);

                        if (params.inputValue !== '') {
                          let newVl = { expenseType: params.inputValue };
                          filtered2.push(newVl);
                        }

                        return filtered2;
                      }}
                      value={row?.expenseType}
                      options={EXPENSE_TYPE_DATA}
                      onChange={(event, newValue) => {
                        handleInputchange(
                          'expenseType',
                          newValue?.expenseType,
                          row,
                          index
                        );
                      }}
                      autoHighlight
                      getOptionLabel={option =>
                        typeof option == 'string'
                          ? option || {}
                          : option !== undefined
                          ? option || {}
                          : ''
                      }
                      renderOption={(props, option) => (
                        <Box {...props}>{option?.expenseType}</Box>
                      )}
                      style={{ width: 180 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='standard'
                          placeholder='Enter Expense'
                        />
                      )}
                    />
                  </TableCell>
                  {Store?.companyDetails?.businessType !== 'MANDI' ? null : (
                    <TableCell align='center'>
                      <Select
                        type='text'
                        size='small'
                        variant='standard'
                        style={{ width: 130 }}
                        // style={{ height: '30px', p: 2 }}
                        name='bankId'
                        value={row?.bankId}
                        onChange={event =>
                          handleInputchange(
                            event.target.name,
                            event.target.value,
                            row,
                            index
                          )
                        }
                      >
                        <MenuItem value={''}>select Bank</MenuItem>
                        {bankDetails?.map(item => {
                          return (
                            <MenuItem value={item.id}>
                              {item.bankName}({item.accountNo?.slice(-4)})
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                  )}

                  <TableCell align='center'>
                    <TextField
                      type='text'
                      size='small'
                      variant='standard'
                      placeholder='Enter comment'
                      // style={{ height: '30px', p: 2 }}
                      name='comment'
                      value={row?.comment}
                      onChange={event =>
                        handleInputchange(
                          event.target.name,
                          event.target.value,
                          row,
                          index
                        )
                      }
                    />
                  </TableCell>

                  <TableCell align='center'>
                    {row?.isValid ? (
                      <Badge badgeContent='' color='success'></Badge>
                    ) : (
                      <Badge badgeContent='' color='error'></Badge>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant='contained'
            disabled={buttonDisabled}
            sx={{
              backgroundColor: '#3B78C9',
              textTransform: 'capitalize',

              color: 'white',
              p: 0,
              width: '126px',
              height: '27px',
            }}
            onClick={addExpenseBulkEx}
          >
            Add Data
          </Button>
        </Box>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
}
