import React, { Component, useCallback, useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Typography,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';

import './styles.css';
import { styled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Configuration from '../../modules/Configuration';

const AddEditconfig = props => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues:
      props.configButtonClick === 'Edit'
        ? props.configData.defaultConfiguration
        : '',
  });

  const [submittedButton, setSubmittedButton] = useState(false);
  const [distinctConfig, setDistinctConfig] = useState([]);

  const [typeDropDown, setTypeDropDown] = useState(
    props.configButtonClick === 'Edit'
      ? props.configData.defaultConfiguration.type
      : ''
  );
  const [hideValue, setHideValue] = useState(props.configData.hide);
  React.useEffect(() => {
    getDistinctDefaultConfig();
  }, []);

  // for custom styling
  const textField = {
    width: '100%',
    //
  };

  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: '#3B78c9',
      },
  });
  const getDistinctDefaultConfig = () => {
    Configuration.getDistinctType(response => {
      if (response.status === 'success') {
        setDistinctConfig(response.data);
      } else {
        setDistinctConfig([]);
      }
    });
  };

  const onConfigDataSubmit = data => {
    if (props.configButtonClick === 'Add') {
      data.type = typeDropDown;
      let configData = data;
      setSubmittedButton(true);
      Configuration.addDefaultConfigiuration(configData, response => {
        if (response.status === 'success') {
          props.snackbar(
            'Configuration has been added successfully',
            'success'
          );
          props.getDefaultConfig(props.value);
          props.closeDailog();
        } else {
          props.snackbar('Configuration was not added successfully', 'error');
          setSubmittedButton(false);
        }
      });
    } else if (props.configButtonClick === 'Edit') {
      props.configData.value = data?.defaultValue;
      props.configData.hide = hideValue;
      let updatedData = props.configData;
      Configuration.updateConfig(updatedData, updatedData?.id, response => {
        if (response.status === 'success') {
          props.snackbar('Configiration was  updated successfully', 'success');
          props.getDefaultConfig(props.value);
          setSubmittedButton(true);
          props.closeDailog();
        } else {
          props.snackbar('Configiration was not updated successfully', 'error');
        }
      });
    }
  };
  const handleTypeChange = useCallback(
    (e, newValue) => {
      setTypeDropDown(newValue);
    },
    [setTypeDropDown]
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Dialog open={props.openDialog} maxWidth='md'>
        <form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit(onConfigDataSubmit)}
        >
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant='h4'
                  textAlign='center'
                  className='dialog-title'
                >
                  {props.configButtonClick === 'Edit'
                    ? 'Edit Configuration'
                    : 'Add New Configuration'}
                </Typography>
                <Tooltip title='Close dialog' placment='right'>
                  <Close
                    onClick={props.closeDailog}
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  />
                </Tooltip>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Setting Name
                  </Typography>
                  <StyledTextField
                    {...register('setting', {
                      required: ' setting  is required.',
                    })}
                    sx={textField}
                    disabled={props.configButtonClick === 'Edit'}
                    size='small'
                    autoComplete='off'
                    id='setting'
                    placeholder='Enter Setting'
                    variant='outlined'
                    name='setting'
                    error={Boolean(errors.setting)}
                    helperText={errors.setting?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Setting Value
                  </Typography>
                  <StyledTextField
                    sx={textField}
                    {...register('defaultValue', {
                      required: ' config Value is required.',
                    })}
                    size='small'
                    type='text'
                    autoComplete='off'
                    id='defaultValue'
                    placeholder='Enter Config Value'
                    variant='outlined'
                    name='defaultValue'
                    error={Boolean(errors.defaultValue)}
                    helperText={errors.defaultValue?.message}
                  />
                </Grid>
                {props.configButtonClick === 'Edit' ? (
                  <Grid item md={6} xs={12}>
                    <Typography variant='subtitle1' className='list-title'>
                      Hide
                    </Typography>
                    <StyledTextField
                      sx={textField}
                      {...register('hide', {
                        required: ' hide is required.',
                      })}
                      size='small'
                      select
                      type='text'
                      id='hide'
                      value={hideValue}
                      onChange={e => setHideValue(e.target.value)}
                      placeholder='Enter hide Value'
                      variant='outlined'
                      name='hide'
                      error={Boolean(errors.hide)}
                      helperText={errors.hide?.message}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </StyledTextField>
                  </Grid>
                ) : null}
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Setting Type
                  </Typography>

                  <Autocomplete
                    id='type'
                    freeSolo
                    onChange={handleTypeChange}
                    autoSelect
                    disabled={props.configButtonClick === 'Edit'}
                    value={typeDropDown}
                    options={distinctConfig}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size='small'
                        variant='outlined'
                        placeholder='select or Enter Type'
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant='subtitle1' className='list-title'>
                    Setting Description
                  </Typography>
                  <StyledTextField
                    {...register('description', {
                      required: ' description  is required.',
                    })}
                    multiline
                    rows={2}
                    disabled={props.configButtonClick === 'Edit'}
                    sx={textField}
                    size='small'
                    autoComplete='off'
                    id='description'
                    placeholder='Enter Description'
                    variant='outlined'
                    name='description'
                    error={Boolean(errors.setting)}
                    helperText={errors.setting?.message}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.closeDailog}
                className='cancel-button'
                variant='outlined'
                sx={{
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='button-color'
                type='submit'
                value='submit'
                sx={{
                  padding: 1,
                  fontSize: '12px',
                  width: '126px',
                  height: '27px',
                  textTransform: 'capitalize',
                }}
                disabled={submittedButton}
              >
                {props.configButtonClick === 'Edit'
                  ? 'Update Config'
                  : 'Add Config'}
              </Button>
            </DialogActions>
          </>
        </form>
      </Dialog>
    </Box>
  );
};

export default AddEditconfig;
