import Auth from '../Auth';
import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';
import Store from '../Store';
class Customer {
  constructor() {
    this.storeCustomers =
      JSON.parse(localStorage.getItem('storeCustomersList')) || {};
  }

  setStoreCustomers = customersList => {
    this.storeCustomers = [...customersList];
    localStorage.setItem('storeCustomersList', JSON.stringify(customersList));
  };

  // reloadCustomers = (storeId, callBack) => {
  //   this.storeCustomers = null;
  //   localStorage.removeItem('storeCustomersList');

  //   this.getAllCustomers(storeId, callBack, 0);
  // };

  getAllCustomers = (
    storeId,
    pageNumber,
    calledAtAllCustomerCustomer,
    callBack
  ) => {
    // let storeCustomersFromCache = this.getStoreCustomersListFromCache();
    // if (
    //   Validation.isBlankObjectNot(storeCustomersFromCache) &&
    //   !calledAtAllCustomerCustomer
    // ) {
    //   callBack({ status: 'success', data: storeCustomersFromCache });
    // } else {
    UpstoreApi({
      method: 'GET',
      url: `/customers/store/${storeId}?pageNumber=${pageNumber}`,
    })
      .then(response => {
        if (response.data) {
          let annon = response.data.filter(
            item => item.customerName === 'Anonymous'
          );
          this.setStoreCustomers([...annon]);
          callBack({ status: 'success', data: response.data });
          //TODO Validate this API Call, why is this needed - Ehsaan
          Store.getStoreByStoreId(Auth.getStoreId(), () => {});
        }
      })
      .catch(error => {
        console.log('Error occure while getting customers', error.message);
        callBack({ status: 'error' });
      });
    // }
  };

  getAnonymousCustomer = () => {
    return this.storeCustomers?.find(
      customer => customer?.customerName?.toUpperCase() === 'ANONYMOUS'
    );
  };

  getCustomerById = (customerId, callBack) => {
    // let customeFound = {};

    // let storeCustomersFromCache = this.getStoreCustomersListFromCache();
    // if (Validation.isBlankObjectNot(storeCustomersFromCache)) {
    //   let customeFound = storeCustomersFromCache?.find(
    //     customer => customer?.id === customerId
    //   );

    //   if (Validation.isBlankObjectNot(customeFound)) {
    //     callBack({ status: 'success', data: customeFound });
    //     return customeFound;
    //   }
    // }

    // if (!Validation.isBlankObjectNot(customeFound)) {
    UpstoreApi({
      method: 'GET',
      url: '/customers/' + customerId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customers by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
    // }
  };

  // getStoreCustomersListFromCache = () => {
  //   if (Validation.isBlankObjectNot(this.storeCustomers)) {
  //     return [...this.storeCustomers];
  //   }

  //   let storeCustomersFromCache = localStorage.getItem('storeCustomersList');
  //   if (Validation.isBlankObjectNot(storeCustomersFromCache)) {
  //     let storeCustomersList = JSON.parse(storeCustomersFromCache);
  //     this.storeCustomers = [...storeCustomersList];
  //     return storeCustomersList;
  //   } else {
  //     return null;
  //   }
  // };

  addNewCustomer = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/customers',
      data: data,
    })
      .then(response => {
        if (response.data) {
          // this.reloadCustomers(Auth.getStoreId(), () => {
          // this.updateCustomersLocalStorageData(response.data, 'add');
          callBack({ status: 'success', data: response.data });
          // });
        }
      })
      .catch(error => {
        console.log('Error occure while adding new customer', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };
  getTotalBalanceAmount = (storeId, type, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/customer-balance/total-balance/${storeId}?type=${type}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting customers', error.message);
        callBack({ status: 'error' });
      });
  };

  updateCustomer = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/customers/update-customer/' + id,
      data: data,
    })
      .then(response => {
        // this.updateCustomersLocalStorageData(response.data, 'update');
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Error occured while updating customer', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  deleteCustomer = (id, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/customers/delete-customer/' + id,
    })
      .then(response => {
        // this.reloadCustomers(Auth.getStoreId(), () => {
        //   callback({ status: 'success', data: response.data });
        // });
        // this.updateCustomersLocalStorageData({ id: id }, 'delete');
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Deleting customer failed', error.message);
        callback({ status: 'error' });
      });
  };

  getCustomerSearchName = (searchVal, storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/customers/search-customer/${storeId}?search=${searchVal}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log(' searching customer failed', error.message);
        callback({ status: 'error' });
      });
  };

  getCustomerBalance = (customerIds, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/customer-balance/customer',
      data: customerIds,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting customer balance', error.message);
        callback({ status: 'error' });
      });
  };

  //vendor Balance , Not used as of now
  getVendorBalance = (storeId, callback) => {
    UpstoreApi({
      method: 'get',
      url: `/customer-balance/get-vendor-balance/${storeId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting customer balance', error.message);
        callback({ status: 'error' });
      });
  };

  getDataForCustomerByCreationDate = (
    customerId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `customers/${customerId}/dashboard/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting data for customer',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  resetCustomerById = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/customers/' + customerId,
    })
      .then(response => {
        if (response.data) {
          // let customerList = [
          //   ...JSON.parse(localStorage.getItem('storeCustomersList')),
          // ];

          // let filteredArray = customerList.filter(
          //   item => item.id !== customerId
          // );

          // this.setStoreCustomers([...filteredArray, response.data]);
          callBack({ status: 'success', data: response.data });
          return response.data;
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customers by customer id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  // updateCustomersLocalStorageData = (data, calledAt) => {
  //   let prevData = JSON.parse(localStorage.getItem('storeCustomersList'));
  //   // let filteredArray = prevData.filter(item => item.id !== data.id);

  //   if (calledAt === 'add') {
  //     this.setStoreCustomers([...prevData, data]);
  //   } else if (calledAt === 'update') {
  //     // this.setStoreCustomers([...filteredArray, data]);
  //     prevData = prevData.map(item => (item.id !== data.id ? item : data));
  //     this.setStoreCustomers([...prevData]);
  //   } else if (calledAt === 'delete') {
  //     let deletableRow = prevData.filter(item => item.id == data.id)[0];
  //     deletableRow.active = !deletableRow.active;

  //     prevData = prevData.map(item =>
  //       item.id !== data.id ? item : deletableRow
  //     );

  //     this.setStoreCustomers([...prevData]);
  //   } else {
  //     console.log('something went wrong');
  //   }
  // };
}

export default new Customer();
