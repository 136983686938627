import * as React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
  Grid,
  TextField,
  Typography,
  Box,
  Skeleton,
} from '@mui/material';
import { Close, ViewAgendaRounded } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import Auth from '../../modules/Auth';
import Customer from '../../modules/Customer';
import Vendor from '../../modules/Vendor';
import { PeopleAlt } from '@mui/icons-material';
import PaymentSection from '../paymentSection/PaymentSection';
import { debounce } from '../../utils';

export default function MakePaymentDialog({
  openDialog,
  closeDialog,
  snackbar,
  type,
  reloadPayments,
}) {
  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };
  const listRef = React.useRef(null);
  const page_size = 18;
  const [customerDataList, setCustomerDataList] = React.useState([]);
  const [name, setName] = React.useState();
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [customerInfo, setCustomerInfo] = React.useState(null);
  const [pageNo, setPageNo] = React.useState(0);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);

  React.useEffect(() => {
    getCustomerList(pageNo);
  }, []);

  const getCustomerList = pageNo => {
    if (type === 'customer') {
      Customer.getAllCustomers(Auth.getStoreId(), pageNo, true, response => {
        if (response.status === 'success') {
          let customerList = response.data.map(customer => {
            customer.name = customer.customerName;
            return customer;
          });
          loadData([...customerList]);
        } else {
          setCustomerDataList([]);
        }
      });
    } else {
      Vendor.getAllVendorByStoreId(
        Auth.getStoreId(),
        pageNo,
        true,
        response => {
          if (response.status === 'success') {
            let vendorList = response.data.map(vendor => {
              vendor.contact = vendor.contactNo;
              return vendor;
            });
            loadData([...vendorList]);
          } else {
            setCustomerDataList([]);
          }
        }
      );
    }
  };

  const loadData = data => {
    let filteredData = data.filter(customer => {
      return customer.active !== false;
    });

    let filteredAnonymusData = filteredData.filter(anony => {
      return anony.name.toUpperCase() !== 'ANONYMOUS';
    });
    let sortedCustomerList = filteredAnonymusData.sort(function (a, b) {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
    });
    if (data < page_size) {
      setHasMoreData(false);
    }
    setCustomerDataList([...customerDataList, ...sortedCustomerList]);
    setTimeout(() => {
      let autoCompleteInput = document.getElementById('customerAutoComplete');
      if (autoCompleteInput) {
        autoCompleteInput.addEventListener('keyup', e => {
          setName(e.target.value);
        });
      }
    }, 3);
  };

  const handleSelectCustomer = customer => {
    setCustomerInfo(customer);
  };

  const handleFilterCustomerChange = event => {
    if (type == 'customer') {
      let search = event.target.value;
      if (search) {
        Customer.getCustomerSearchName(search, Auth.getStoreId(), response => {
          if (response.status === 'success') {
            let customerList = response.data.map(customer => {
              customer.name = customer.customerName;
              return customer;
            });
            loadData([...customerList]);
          }
        });
      } else {
        getCustomerList(pageNo);
      }
    } else {
      let search = event.target.value;
      if (event.target.value !== '') {
        Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
          if (response.status === 'success') {
            let customerList = response.data.map(customer => {
              customer.name = customer.customerName;
              return customer;
            });
            loadData([...customerList]);
          }
        });
      } else {
        getCustomerList(pageNo);
      }
    }
  };

  const handlecustomerChange = React.useCallback(
    debounce(handleFilterCustomerChange, 300),
    []
  );
  const handleScroll = () => {
    const list = listRef.current;
    if (
      Math.abs(
        list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1 &&
          hasMoreData
      )
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;

      setPageNo(pageNum);
      getCustomerList(pageNum);
    }
  };

  return (
    <div>
      <Dialog open={openDialog} maxWidth='lg' fullWidth>
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right-start '>
              <Close
                size='medium'
                onClick={closeDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <DialogTitle textAlign='center'></DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='subtitle1' sx={{ marginBottom: '-2px' }}>
              {type === 'customer'
                ? ' Customer Name/Phone'
                : ' Vendor Name/Phone'}
            </Typography>
            <Autocomplete
              sx={{ mb: 2 }}
              // freeSolo
              id='customerAutoComplete'
              options={customerDataList}
              disableClearable
              ListboxProps={{
                ref: listRef,
                onScroll: ({ currentTarget }) => {
                  handleScroll();
                },
              }}
              clearOnBlur={true}
              renderOption={(props, option) => {
                const isLastItem =
                  option === customerDataList[customerDataList.length - 1];
                console.log(isLastItem, skeletonLoading);
                return (
                  <>
                    <div {...props} key={option.id}>
                      <span key={option.id} style={{ paddingRight: '10px' }}>
                        <PeopleAlt fontSize='small' />
                      </span>
                      {`${option.name}`}
                      <li
                        style={{ color: 'gray', marginLeft: 'auto' }}
                        key={option.id}
                        {...props}
                      >{`${
                        option.contact !== null ? option.contact : ''
                      } `}</li>
                    </div>
                    {isLastItem && (
                      <Box>
                        <Skeleton
                          width='100%'
                          height={50}
                          style={{ textAlign: 'center' }}
                        >
                          Scroll down to Load more ...
                        </Skeleton>
                      </Box>
                    )}
                  </>
                );
              }}
              getOptionLabel={option =>
                typeof option == 'string'
                  ? (option = '')
                  : option !== undefined
                  ? `${option.name} ` || {}
                  : ''
              }
              onChange={(event, newValue) => {
                if (newValue == 121) {
                  return null;
                }
                handleSelectCustomer(newValue);
              }}
              onInputChange={handlecustomerChange}
              value={customerInfo}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    width: '90%',
                    marginTop: '6px',
                    backgroundColor: '#E1EEF5',
                    borderTop: '5px solid #E1EEF5',
                    '& .MuiInputBase-root': {
                      paddingRight: '39px !important',
                    },
                  }}
                  placeholder={
                    type === 'customer' ? 'Search Customer' : 'Search Vendor'
                  }
                  size='small'
                  variant='outlined'
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </Grid>
          <hr />
          {customerInfo !== null ? (
            <Grid>
              <Grid
                item
                md={12}
                sx={{
                  mt: 2,
                  mb: 2,
                  height: 50,
                  display: 'flex',
                  border: '1px solid grey',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  backgroundColor: '#F5F5F5',
                }}
              >
                <Typography variant='subtitle'>{customerInfo?.name}</Typography>
                <Typography variant='subtitle'>
                  {customerInfo?.contact}
                </Typography>
                <Typography variant='subtitle'>
                  {customerInfo?.address}
                </Typography>
              </Grid>
              <hr />

              <PaymentSection
                key={customerInfo !== null && customerInfo.id}
                customerId={customerInfo !== null && customerInfo.id}
                customerDetails={customerInfo}
                type={type === 'customer' ? 'Customer' : 'Vendor'}
                snackbar={snackbar}
                name={customerInfo?.name}
                paymentFrom='MakePayment'
                reload={reloadPayments}
              />
            </Grid>
          ) : (
            <Typography
              sx={{
                fontSize: 30,
                color: 'transparent',
                textShadow: '0 0 3px #000',
                textAlign: 'center',
              }}
            >
              {type === 'customer'
                ? 'Please Select  Customer'
                : 'Please Select Vendor'}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontSize: '16px',
              width: '126px',
              height: '27px',
            }}
            onClick={closeDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
