import * as React from 'react';
import {
  Button,
  TextField,
  Box,
  Tooltip,
  Table,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableFooter,
  Divider,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import ReturnOrder from '../../modules/ReturnOrder';
import Order from '../../modules/Order';
import Auth from '../../modules/Auth';
import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import Store from '../../modules/Store';
import PurchaseReturnOrderItems from '../purchaseReturnOrderItems/PurchaseReturnOrderItems';
import VendorListOrderItem from '../vendorListOrderItem/VendorListOrderItem';
import Validation from '../../modules/Validation';
import DatePickerComp from '../datePicker/DatePicker';
import dateConveter from '../../modules/DateConveter/dateConveter';
import HTMLRenderer from 'react-html-renderer';

export default function AlertDialog(props) {
  const totalReturnAmountArr = props.selectedReturn.map(item => {
    return item.amount;
  });

  const totalReturnAmount = totalReturnAmountArr.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const [openAddReasonDialog, setOpenAddReasonDialog] = React.useState(false);
  const [returnOrder, setReturnOrder] = React.useState([]);
  const [editReturnOrder, setEditReturnOrder] = React.useState(false);
  const [selectedReturnList, setSelectedReturnList] = React.useState([
    ...props.selectedReturn,
  ]);
  const [returnableItems, setReturnableItems] = React.useState([]);
  const [returnedOrders, setReturnedOrders] = React.useState({
    totalAmount: totalReturnAmount,
  });
  const [successReturn, setSuccessReturn] = React.useState(false);
  const [updatedRow, setUpdatedRow] = React.useState([]);
  const [buttonSubmitted, setButtonSubmitted] = React.useState(false);

  const [returnOrderDate, setReturnOrderDate] = React.useState(
    new Date(props?.storeOrderObj?.billDate)
  );

  React.useEffect(() => {
    getReturnedOrders(props?.storeOrderObj?.id);
  }, []);
  const openReson = () => {
    setOpenAddReasonDialog(props?.storeOrderObj, 'storeOrderObj');
  };

  // const columns = [
  //   { id: 'edit', label: '', minWidth: 2, align: 'center', size: 'small' },

  //   { id: 'item', label: 'Item ', minWidth: 50, align: 'left', size: 'small' },

  //   {
  //     id: 'itemQuantityUnit',
  //     label: 'Unit',
  //     minWidth: 50,
  //     align: 'center',
  //     size: 'small',
  //   },

  //   {
  //     id: 'noOfItems',
  //     label: 'Qty',
  //     minWidth: 50,
  //     align: 'center',
  //     size: 'small',
  //   },

  //   {
  //     id: Store.companyDetails.businessType === 'MANDI' ? 'packing' : null,
  //     label: Store.companyDetails.businessType === 'MANDI' ? 'Pack' : null,
  //     minWidth: 50,
  //     align: 'center',
  //     size: 'small',
  //   },

  //   {
  //     id: Store.companyDetails.businessType === 'MANDI' ? 'variety' : null,
  //     label: Store.companyDetails.businessType === 'MANDI' ? 'Variety' : null,
  //     minWidth: 50,
  //     align: 'left',
  //   },

  //   {
  //     id: 'Price',
  //     label: 'Price',
  //     minWidth: 50,
  //     align: 'center',
  //     size: 'small',
  //   },
  //   {
  //     id:
  //       Store.getSelectedTaxType() === 'GST'
  //         ? 'gstrate'
  //         : Store.getSelectedTaxType() === 'VAT'
  //         ? 'vatrate'
  //         : Store.getSelectedTaxType() === 'N/A'
  //         ? 'TAX%'
  //         : null,
  //     label:
  //       Store.getSelectedTaxType() === 'GST'
  //         ? 'Gst Rate'
  //         : Store.getSelectedTaxType() === 'VAT'
  //         ? 'Vat Rate'
  //         : Store.getSelectedTaxType() === 'N/A'
  //         ? 'TAX%'
  //         : null,
  //     minWidth: 50,
  //     align: 'center',
  //     size: 'small',
  //   },
  //   {
  //     id: 'basePriceDiscount',
  //     label: 'Discount',
  //     minWidth: 70,
  //     align: 'left',
  //     size: 'small',
  //   },

  //   {
  //     id: 'amount',
  //     label: 'Amount',
  //     minWidth: 70,
  //     align: 'left',
  //     size: 'small',
  //   },
  // ];

  // const simpleOrder = columns.filter(item => {
  //   return item.label !== 'commission (%)' && item.label !== 'packing';
  // });
  const column1 = [
    { id: 'itemName', label: 'Item Name', minWidth: 200, align: 'left' },
    { id: 'amount', label: 'Amount', minWidth: 200, align: 'center' },
    { id: 'return', label: 'Return', minWidth: 200, align: 'center' },

    {
      id: 'cancelReturn',
      label: 'Cancel Return',
      minWidth: 200,
      align: 'left',
    },
  ];
  const saveReturnedOrder = (status, comment) => {
    setButtonSubmitted(true);
    let iDate;
    dateConveter.convertDate(returnOrderDate, response => {
      iDate = response.data;
    });
    console.log(iDate);
    let orderObj = {
      ...returnOrder,
      invoiceDate: iDate,

      status,
      type: 'Customer',
      comment: `Returned Goods for Order:${props.storeOrderNumber} ${comment} `,
    };

    if (comment.length > 0) {
      if (orderObj?.orderDetails?.length > 0) {
        ReturnOrder.saveReturnOrder(orderObj, response => {
          if (response.status === 'success') {
            if (status === 'NEW') {
              props.snackbar('order is saved to draft ', 'success');
              setOpenAddReasonDialog(false);
            } else if (status === 'CONFIRMED') {
              props?.handleSetUpdatedOrderItem(response.data.orderId);
              props.snackbar('Return Order is confirmed ', 'success');
              setOpenAddReasonDialog(false);

              setTimeout(() => {
                props.close();
              }, 1000);

              Transaction.log(
                Auth.getStoreId(),
                response.data.customerId,
                LogMessage.ORDER_RETURNED,
                LogMessage.ORDER_RETURNED_TITLE,
                [
                  response.data.returnOrderNumber,
                  props.storeOrderNumber,
                  Auth.getUserName(),
                ]
              );
            }
          } else {
            props.snackbar('error while saving return order ', 'error');
            setButtonSubmitted(false);
          }
        });
      } else {
        props.snackbar('Please Select Item to Return ', 'error');
        setTimeout(() => {
          props.close();
        }, 100);
      }
    } else {
      props.snackbar('Please enter comment first ', 'error');
      setButtonSubmitted(false);
    }
  };
  const getReturnedOrders = orderId => {
    ReturnOrder.getReturnOrderByOrder(orderId, response => {
      if (response.status === 'success') {
        let filtererd = response.data.filter(item => {
          return item.deleted == false;
        });

        let returnOrderObj = filtererd[0];

        setReturnOrder(returnOrderObj);
        setSuccessReturn(true);
        setReturnableItems(returnOrderObj.orderDetails);
        setReturnOrderDate(new Date(returnOrderObj.invoiceDate));
        setReturnedOrders(returnOrderObj);
      } else {
        return 'error';
      }
    });
  };
  const updateReturnOrderItems = () => {
    setButtonSubmitted(true);
    setEditReturnOrder(false);
    let iDate;
    dateConveter.convertDate(returnOrderDate, response => {
      iDate = response.data;
    });
    returnOrder.orderDetails.map(detail => {
      detail.storeOrderId = detail.purchaseOrderId;
      return detail;
    });
    let returnOrderObj = {
      ...returnOrder,
      invoiceDate: iDate,
    };
    ReturnOrder.updateReturnOrder(returnOrderObj, returnOrder.id, response => {
      if (response.status === 'success') {
        props.snackbar('order is updated Sucessfully ', 'success');
        props?.handleSetUpdatedOrderItem(returnedOrders.orderId);
        props.close();
      } else {
        props.snackbar('order is not  Updated ', 'error');
      }
    });
  };

  const calculateReturnOrderTotalValues = productList => {
    let orderNew = Order.calculateTotalValues(productList);
    let returnOrderObj = {
      id: returnOrder?.id ? returnOrder?.id : undefined,
      customerId: props?.vendorInfo.id,
      customerName: props.vendorInfo.name,
      totalItems: orderNew.totalItems,
      totalAmount: orderNew.totalAmount,
      totalDiscount: orderNew.totalBasePriceDiscount,
      totalMrp: orderNew.totalMrp,
      totalStorePrice: orderNew.totalBasePrice,
      storeId: Auth.getStoreId(),
      orderDetails: orderNew.orderDetails,
      orderId: props?.storeOrderObj?.id,
      returnType: 'PurchaseOrder',
      // // invoiceDate: !returnOrder?.id ? new Date() : returnOrder?.invoiceDate,
      invoiceDate: returnOrderDate,
    };
    setReturnOrder(returnOrderObj);
    return returnOrderObj;
  };

  const handleQuantityChangeCallback = rowDetails => {
    let taxValue = rowDetails?.taxValue
      ? rowDetails?.taxValue
      : Validation.getGstSumOrVatSum(rowDetails?.taxRate);
    let totalStorePrice = parseFloat(
      rowDetails?.basePrice * rowDetails?.noOfItems
    );
    let data = Order.getCalculatedAmount(
      rowDetails?.basePriceDiscount,
      totalStorePrice,
      taxValue,
      rowDetails.commission
    );
    let addedValue = {
      ...rowDetails,
      noOfItems: parseFloat(rowDetails.noOfItems),
      amount: parseFloat(data?.amount),
      taxAmount: parseFloat(data.taxAmount),
    };

    let returnAdd = returnableItems.map(item => {
      return item.itemNumber === rowDetails.itemNumber ? addedValue : item;
    });

    calculateReturnOrderTotalValues(returnAdd);
    setReturnableItems(returnAdd);
  };

  const handleReturnOrderCallBack = (row, mode) => {
    if (mode === 'return') {
      let allReturnableItems = [];
      if (
        returnableItems.findIndex(i => i.itemNumber == row.itemNumber) != -1
      ) {
        allReturnableItems = returnableItems.map(item => {
          if (item.itemNumber == row.itemNumber) {
            item = row;
          }
          return item;
        });
      } else {
        allReturnableItems = [...returnableItems, row];
      }
      setReturnableItems(allReturnableItems);
      calculateReturnOrderTotalValues(allReturnableItems);
    } else if (mode === 'cancel') {
      let allReturnableItems = [];
      if (row.id == undefined) {
        allReturnableItems = returnableItems.filter(
          item => item.itemNumber != row.itemNumber
        );
      } else {
        allReturnableItems = returnableItems.map(item => {
          if (item.itemNumber == row.itemNumber) {
            item = { ...row, id: item.id };
          }
          return item;
        });
      }
      setReturnableItems(allReturnableItems);
      calculateReturnOrderTotalValues(allReturnableItems);
    }
  };

  const onReturnDateChange = newValue => {
    setReturnOrderDate(newValue);
  };
  return (
    <div>
      <Dialog
        open={props.openDialog}
        maxWidth='md'
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#E1EEF5',
          }}
        >
          <Typography
            variant='h4'
            fontSize='22'
            marginTop={'2px'}
            marginLeft={5}
          >
            Return Order
          </Typography>
          <Box
            sx={{
              ml: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0.5,
            }}
          >
            <Typography variant='h6' sx={{ mt: 0.5, mr: 1 }} noWrap>
              Return Date
            </Typography>

            <Box>
              <DatePickerComp
                value={returnOrderDate}
                disabled={
                  Validation.isBlankObjectNot(returnOrder)
                    ? returnOrder.status === 'CONFIRMED' && !editReturnOrder
                    : ''
                }
                minDate={props?.storeOrderObj?.billDate}
                dateChange={onReturnDateChange}
              />
            </Box>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button
              variant='contained'
              disabled={returnedOrders?.status !== 'CONFIRMED'}
              onClick={() => setEditReturnOrder(true)}
              sx={{
                mt: 1,
                p: 0,
                mr: 1,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
            >
              <Typography sx={{ fontSize: '13px' }} noWrap>
                Edit Order
              </Typography>
            </Button>
          </Box>

          <Tooltip placement='bottom' title='close'>
            <Close
              onClick={props.close}
              fontSize='medium'
              sx={{ cursor: 'pointer', mr: 1, mt: 1, color: '#3B78C9' }}
            />
          </Tooltip>
        </Box>

        <Grid
          sx={{
            mt: 2,
            ml: 5,
          }}
        >
          <TableHead>
            <TableRow>
              {column1.map(col => (
                <TableCell
                  key={col.id}
                  align={col.align}
                  style={{
                    minWidth: col.minWidth,
                    fontWeight: '500',
                    fontSize: 'large',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#E1EEF5',
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {successReturn &&
            selectedReturnList.map(row => {
              return (
                <PurchaseReturnOrderItems
                  handleQuantityChangeCallback={handleQuantityChangeCallback}
                  key={row.id}
                  row={row}
                  editReturnOrder={editReturnOrder}
                  returnOrder={returnOrder && returnOrder}
                  handleReturnOrderCallBack={handleReturnOrderCallBack}
                  closeRetDialog={props?.close}
                  snackbar={props.snackbar}
                  returnedOrders={returnedOrders}
                  storeOrderNumber={props?.storeOrderNumber}
                  handleSetUpdatedOrderItem={props?.handleSetUpdatedOrderItem}
                />
              );
            })}
          <TableFooter
            sx={{
              backgroundColor: '#E1EEF5',

              bottom: 0,
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                }}
              >
                Total
              </TableCell>
              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              ></TableCell>

              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {returnOrder?.totalItems?.toFixed(1)}
              </TableCell>

              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  whiteSpace: 'nowrap',
                }}
              >
                Rs: {returnOrder?.totalAmount?.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableFooter>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={returnedOrders?.status === 'CONFIRMED'}
              variant='contained'
              onClick={() => saveReturnedOrder('NEW', 'draftReturnPurchase')}
              sx={{
                fontSize: '15px',
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                mr: 2,
              }}
            >
              Save
            </Button>
            {returnedOrders?.status === 'CONFIRMED' ? (
              <Button
                variant='contained'
                disabled={!editReturnOrder}
                onClick={updateReturnOrderItems}
                sx={{
                  fontSize: '15px',
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
              >
                <Typography sx={{ fontSize: '13px' }} noWrap>
                  Update & close
                </Typography>
              </Button>
            ) : (
              <Button
                variant='contained'
                disabled={buttonSubmitted}
                onClick={openReson}
                sx={{
                  fontSize: '15px',
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
              >
                <Typography noWrap> Save & close</Typography>
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item md={12} sx={{ mt: 2, ml: 5 }}>
          <Divider />
          <Typography
            variant='h4'
            fontSize='22'
            marginLeft={'10px'}
            marginTop={'2px'}
          >
            Order Details
          </Typography>
          <TableContainer
            sx={{ maxHeight: 450, maxWidth: 800 }}
            component={Paper}
            className='scroll'
          >
            <Table
              stickyHeader
              aria-label='sticky table'
              sx={{
                backgroundColor: 'secondary.light',
                position: 'relative',
              }}
              size='small'
            >
              <TableHead>
                <TableRow>
                  {Store?.companyDetails?.businessType === 'MANDI'
                    ? props?.columns?.map(column => {
                        return (
                          <>
                            {column.label !== null ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  fontSize: 'medium',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : null}
                          </>
                        );
                      })
                    : null}
                </TableRow>
                <TableRow>
                  {Store?.companyDetails?.businessType !== 'MANDI'
                    ? props.columns?.map(column => {
                        return (
                          <>
                            {column.label !== null ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  fontSize: 'medium',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : null}
                          </>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>

              <TableBody>
                <>
                  {selectedReturnList?.length > 0 ? (
                    <>
                      {selectedReturnList?.map(row => {
                        return (
                          <>
                            <VendorListOrderItem
                              key={`return_${row.itemNumber}`}
                              row={row}
                              updatedRowData={() => updatedRow}
                              snackbar={props.snackbar}
                              returnOrderMode={true}
                            />
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </>
              </TableBody>
              <TableFooter
                sx={{
                  backgroundColor: '#E1EEF5',
                  position: 'sticky',
                  bottom: 0,
                }}
              >
                {' '}
                <TableRow>
                  <TableCell
                    style={{
                      minWidth: 30,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {props?.storeOrderObj?.totalItems?.toFixed(1)}
                  </TableCell>

                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {props?.storeOrderObj?.totalDiscount
                      ? props?.storeOrderObj?.totalDiscount
                      : '0'}
                  </TableCell>
                  {Store.companyDetails.businessType !== 'MANDI' && (
                    <TableCell
                      style={{
                        minWidth: 100,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                      }}
                    ></TableCell>
                  )}
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  {!Store.getSelectedTaxType() ||
                  Store.companyDetails.businessType === 'MANDI' ? null : (
                    <TableCell
                      style={{
                        maxWidth: 100,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      {props?.storeOrderObj?.status === 'NEW' ||
                      props?.storeOrderObj?.status === 'CONFIRMED'
                        ? props?.storeOrderObj?.tax?.toFixed(3)
                        : props?.storeOrderObj?.totalTaxValue?.toFixed(3)}
                    </TableCell>
                  )}

                  {Store.companyDetails?.businessType === 'MANDI' && (
                    <>
                      <TableCell
                        style={{
                          minWidth: 100,
                          fontSize: 'large',
                          whiteSpace: 'nowrap',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          minWidth: 100,
                          fontSize: 'large',
                          whiteSpace: 'nowrap',
                        }}
                      ></TableCell>
                    </>
                  )}
                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      minWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {props?.currency ? (
                      <HTMLRenderer html={props?.currency} />
                    ) : null}
                    : {props?.storeOrderObj?.totalAmount?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>

        <DialogActions></DialogActions>
      </Dialog>

      {openAddReasonDialog ? (
        <AddReasonDialog
          openPopup={openReson}
          onClose={() => {
            setOpenAddReasonDialog(false);
          }}
          saveReturnedOrder={saveReturnedOrder}
          buttonSubmitted={buttonSubmitted}
        />
      ) : null}
    </div>
  );
}
const AddReasonDialog = props => {
  const [comment, setComment] = React.useState('');

  return (
    <Dialog open={props.openPopup} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Add Reason Alert !!
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Reason *</Typography>
        <TextField
          sx={{ width: '100%', mt: 1 }}
          id='comment'
          multiline
          size='small'
          focused
          value={comment}
          onChange={event => setComment(event.target.value)}
          placeholder='Enter reason here'
          variant='standard'
          name='comment'
        />
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant='outlined'
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            disabled={props?.buttonSubmitted}
            sx={{ textTransform: 'capitalize', width: '127px', height: '32px' }}
            onClick={event => props.saveReturnedOrder('CONFIRMED', comment)}
          >
            Confirm
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
