import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';

class UpstoreProperties {
  constructor() {
    this.upstoreProperties =
      JSON.parse(localStorage.getItem('upstoreProperties')) || [];
  }
  getUpstoreProperties = (storeId, calledAfterAdd = false, callBack) => {
    let storePropertiesListFromCache = this.getStorePropertiesListFromCache();

    if (
      Validation.isBlankObjectNot(storePropertiesListFromCache) &&
      calledAfterAdd === false
    ) {
      callBack({ status: 'success', data: storePropertiesListFromCache });
    } else {
      return UpstoreApi({
        method: 'GET',
        url: `public/UpstoreProperties/${storeId}`,
      })
        .then(response => {
          if (storeId === -1) {
            callBack({ status: 'success', data: response.data });
          } else {
            this.setUpstoreProperties([...response.data]);
            callBack({ status: 'success', data: response.data });
          }
        })
        .catch(error => {
          console.log('error while ', error.message);
          callBack({ status: 'error' });
        });
    }
  };

  // getAllProperties(storeId, callBack) {
  //   let storePropertiesListFromCache = this.getStorePropertiesListFromCache();
  //   if (Validation.isBlankObjectNot(storePropertiesListFromCache)) {
  //     callBack({ status: 'success', data: storePropertiesListFromCache });
  //   } else {
  //     return UpstoreApi({
  //       method: 'GET',
  //       url: `public/UpstoreProperties/${storeId}`,
  //     })
  //       .then(response => {
  //         if (storeId === -1) {
  //           callBack({ status: 'success', data: response.data });
  //         } else {
  //           this.setUpstoreProperties(response.data);
  //           callBack({ status: 'success', data: response.data });
  //         }
  //       })

  //       .catch(error => {
  //         console.log('error while ', error.message);
  //         callBack({ status: 'error' });
  //       });
  //   }
  // }
  getStorePropertiesListFromCache = () => {
    if (Validation.isBlankObjectNot(this.upstoreProperties)) {
      return [...this.upstoreProperties];
    }

    let storePropertiesListFromCache =
      localStorage.getItem('upstoreProperties');

    if (Validation.isBlankObjectNot(storePropertiesListFromCache)) {
      let upstorePropertiesList = JSON.parse(storePropertiesListFromCache);
      this.upstoreProperties = [...storePropertiesListFromCache];
      return upstorePropertiesList;
    } else {
      return null;
    }
  };
  addUpstoreProperty = (upstoreProperty, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `public/add-property`,
      data: upstoreProperty,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
        this.upstoreProperties = null;

        this.getUpstoreProperties(upstoreProperty.storeId, () => {});
      })
      .catch(error => {
        console.log('Update UptoreProperties failed', error.message);
        callback({ status: 'error' });
      });
  };
  updateUpstoreProperty = (id, upstoreProperty, callback) => {
    UpstoreApi({
      method: 'PUT',
      url: `public/updatePropertyById/${id}`,
      data: upstoreProperty,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
        this.upstoreProperties = null;

        this.getUpstoreProperties(upstoreProperty.storeId, () => {});
      })
      .catch(error => {
        console.log('Update UptoreProperties failed', error.message);
        callback({ status: 'error' });
      });
  };

  setUpstoreProperties = upstoreProperties => {
    this.upstoreProperties = upstoreProperties;
    localStorage.setItem(
      'upstoreProperties',
      JSON.stringify(upstoreProperties)
    );
  };

  getProperty = propertyName => {
    let upstoreProp = this.upstoreProperties?.find(
      prop => prop.property === propertyName
    );
    return upstoreProp != undefined ? upstoreProp.propertyValue : '';
  };

  getPropertyForBanners = propertyName => {
    let upstoreProp = this.upstoreProperties?.find(
      prop => prop.property === propertyName
    );
    return upstoreProp != undefined ? upstoreProp : '';
  };

  getDefaultProperty = (propertyName, defaultValue) => {
    let upstoreProp = this.upstoreProperties.find(
      prop => prop.property === propertyName
    );

    return upstoreProp != undefined ? upstoreProp.propertyValue : defaultValue;
  };
}
export default new UpstoreProperties();
