import React from 'react';
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Tooltip,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import Customer from '../../modules/Customer';
import { Close } from '@mui/icons-material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Bills from '../../modules/Bills';
import * as moment from 'moment';
import { Loading } from '../loading/Loading';
import CustomerBillDialog from '../customerBillDialog/CustomerBillDialog';
import Store from '../../modules/Store';
const CustomerBills = ({ customerId }) => {
  const date = new Date();
  const [billsData, setBillsData] = React.useState([]);
  const [billData, setBillData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  // const [value, setValue] = React.useState([
  //   new Date(date.getFullYear(), date.getMonth(), 1),
  //   new Date(),
  // ]);
  const [value, setValue] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [value1, setValue1] = React.useState(new Date());
  const [customerBill, setCustomerBill] = React.useState({ open: false });
  React.useEffect(() => {
    getBillByCustomerId();
  }, []);

  const getBillByCustomerId = () => {
    Bills.getBillByCustomerId(customerId, response => {
      if (response.status === 'success') {
        setBillsData(response.data);
        setLoader(false);
      } else {
        setBillsData([]);
        setLoader(false);
      }
    });
  };

  const generateBillForCustomer = () => {
    let newStartDate = moment(value).format('YYYY-MM-DD');
    let newEndDate = moment(value1).format('YYYY-MM-DD');
    let newSelectDate = moment(value).format('dd/MM/yyyy');
    let newSelectEndDate = moment(value1).format('dd/MM/yyyy');
    // let startDate = value[0].toISOString().substring(0, 10);
    // let endDate = value[1].toISOString().substring(0, 10);
    Bills.getBillByDate(
      customerId,
      newStartDate,
      newEndDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          let billData = { ...response.data };
          billData.id = billData.billId;
          setBillData(billData);
          downloadBill(billData);
        } else {
          console.log('error');
        }
      }
    );
  };
  const closeInvoiceDialog = () => {
    let customerBillObj = {
      open: false,
    };

    setCustomerBill(customerBillObj);
  };

  const downloadBill = billData => {
    setTimeout(() => {
      let customerBillObj = {
        open: true,
        data: billData,
      };

      setCustomerBill(customerBillObj);
    }, 1000);
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <Box container='div'>
          <Box
            sx={{
              display: { md: 'block', sm: 'block', xs: 'flex' },
              p: { md: 1, sm: 1, xs: 0 },
              alignItems: 'center',
              ml: 'auto',
              mr: 'auto',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={value}
                inputFormat='dd/MM/yyyy'
                onChange={newValue => {
                  setValue(newValue);
                }}
                renderInput={params => (
                  <TextField
                    size='small'
                    sx={{
                      width: '140px',
                      height: '40px',
                      mr: 1,
                      mt: { md: 0, sm: 1, xs: 3 },
                    }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                value={value1}
                minDate={new Date(value)}
                onChange={newValue => {
                  setValue1(newValue);
                }}
                inputFormat='dd/MM/yyyy'
                disableFuture
                renderInput={params => (
                  <TextField
                    size='small'
                    sx={{
                      width: '140px',
                      height: '40px',
                      mt: { md: 0, sm: 1, xs: 3 },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>

            <Button
              variant='contained'
              sx={{
                ml: 1,
                fontSize: { md: '15px', sm: '15px', xs: '10px' },
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                color: 'white',
                bgcolor: '#3B78C9',
                p: 0,
                mt: { md: 1, sm: 1, xs: 2 },
              }}
              onClick={event => generateBillForCustomer()}
            >
              Generate Bill
            </Button>
          </Box>

          <TableContainer sx={{ maxHeight: 500, mt: 1 }} component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size='small'
              aria-label='a dense table'
            >
              <TableHead>
                <TableRow
                  style={{
                    fontSize: 'large',

                    whiteSpace: 'nowrap',
                    backgroundColor: '#eee',
                  }}
                >
                  <TableCell
                    sx={{
                      pl: 5,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                  >
                    Bill No
                  </TableCell>
                  <TableCell
                    sx={{
                      pr: 4,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    Customer Name
                  </TableCell>
                  <TableCell
                    sx={{
                      pr: 4,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    sx={{
                      pr: 2,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 2,
                      pt: 2,
                      pb: 2,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    Due Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 5,
                      fontSize: { md: '18px', sm: '15px', xs: '15px' },
                      fontWeight: 500,
                    }}
                    align='right'
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {billsData.map(row => (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      component='th'
                      scope='row'
                    >
                      {row.billNo}
                    </TableCell>
                    <TableCell
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      align='right'
                    >
                      {`${
                        Customer.getCustomerById(customerId, () => {})
                          ?.customerName
                      }`}
                    </TableCell>
                    <TableCell
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      align='right'
                    >
                      {row.startDate === null
                        ? 'N / A'
                        : row.startDate.toString().substring(0, 10)}
                    </TableCell>
                    <TableCell align='right'>
                      {row.endDate === null
                        ? 'N / A'
                        : row.endDate.toString().substring(0, 10)}
                    </TableCell>
                    <TableCell align='right'>
                      {row.dueDate === null
                        ? 'N / A'
                        : row.dueDate.toString().substring(0, 10)}
                    </TableCell>
                    <TableCell align='right'>{row.status}</TableCell>
                    <TableCell>
                      <Box
                        width='100%'
                        component='div'
                        display='flex'
                        justifyContent='space-around'
                        alignItems='baseline'
                      >
                        <>
                          <Tooltip title='Download Invoice' placement='bottom'>
                            <DownloadIcon
                              sx={{ color: '#3B78C9', cursor: 'pointer' }}
                              onClick={event => downloadBill(row)}
                            />
                          </Tooltip>
                        </>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {billsData.length === 0 || billsData === undefined ? (
            <Box>
              <Typography
                color='#3B78C9'
                variant='h5'
                sx={{
                  fontSize: '20px',
                  pt: 5,
                }}
                textAlign='center'
              >
                No data present
              </Typography>
            </Box>
          ) : null}

          {/* <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                        backgroundColor: '#eee',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {billData.map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <>
                            {column.label === 'Customer Name' ? (
                              <>
                                <TableCell key={column.id} align={column.align}>
                                  {Customer.getCustomerById(
                                    customerId,
                                    () => {}
                                  ) &&
                                    `${
                                      Customer.getCustomerById(
                                        customerId,
                                        () => {}
                                      ).firstName
                                    } 
                                ${
                                  Customer.getCustomerById(customerId, () => {})
                                    .lastName
                                }`}
                                </TableCell>
                              </>
                            ) : (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            )}
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer> */}
          {/*        
            <Typography
              color="#3B78C9"
              variant="h5"
              sx={{ margin: '50px auto' }}
              textAlign="center"
            >
              No data present
            </Typography> */}
        </Box>
      )}

      <Dialog
        maxWidth={'xl'}
        open={customerBill.open}
        // onClose={closeInvoiceDialog}
      >
        <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
          <Grid item>
            <Tooltip title='Close dialog' placment='right-start '>
              <Close
                size='medium'
                onClick={closeInvoiceDialog}
                sx={{ color: '#3B78C9', cursor: 'pointer' }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <DialogContent>
          <CustomerBillDialog
            billData={customerBill.data}
            customerId={customerId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerBills;
