import React from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Store from '../../modules/Store';
import { DoneOutline } from '@mui/icons-material';

const ProductItemAccordionDetails = ({
  itemData,
  selectedProductItemCallback,
  snackbar,
  product,
}) => {
  const columns = [
    {
      id: 'itemName',
      label: 'Item Name',
      minWidth: 60,
      format: value => value.toLocaleString('en-US'),
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'mrp',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'MRP',
      minWidth: 50,
    },
    { id: 'basePrice', label: 'Base Price', minWidth: 100 },
    {
      id: 'storePrice',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? 'Price'
          : Store?.companyDetails?.businessType === 'STORE'
          ? 'M.R.P'
          : 'Store Price',
      minWidth: 100,
    },
    { id: 'quantity', label: 'Quantity', minWidth: 100 },
    { id: 'unit', label: 'Unit', minWidth: 100 },
    {
      id: 'itemDescription',
      label: 'Description',
      minWidth: 100,
      align: 'center',
      format: value => value.toLocaleString('en-US'),
    },

    {
      id: 'barcode',
      label: 'Barcode',
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'select',
      label: 'Select',
      minWidth: 80,
      align: 'center',
    },
  ];

  const simpleOrder = columns?.filter(item => {
    return item.label !== 'Base Price';
  });
  const purchaseOrder = columns?.filter(item => {
    return item.id !== 'storePrice';
  });

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {window.location?.hash?.endsWith('/purchase-order')
                  ? purchaseOrder?.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: 16,
                          fontWeight: 580,
                          whiteSpace: 'nowrap',
                          backgroundColor: '#eee',
                          padding: '10px 10px',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))
                  : simpleOrder?.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: 16,
                          fontWeight: 580,
                          whiteSpace: 'nowrap',
                          backgroundColor: '#eee',
                          padding: '10px 10px',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemData?.map(row => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.itemNumber}
                  >
                    {window.location.hash.endsWith('/purchase-order')
                      ? purchaseOrder.map(column => {
                          const value = row[column.id];

                          return (
                            <>
                              {column.label !== 'Select' ? (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format &&
                                  typeof value === 'number' ? (
                                    <Tooltip title={value} placement='top'>
                                      <Typography
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          width: '90px',
                                          height: '1.2em',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {column.format(value)}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={value} placement='top'>
                                      <Typography
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          width: '90px',
                                          height: '1.2em',
                                          whiteSpace: 'nowrap',
                                          fontSize:
                                            column.label === 'Barcode'
                                              ? '12px'
                                              : null,
                                        }}
                                      >
                                        {value}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              ) : (
                                <>
                                  {/* using that dynamically added property itemAddedOnDialog here */}
                                  {row.itemAddedOnDialog ? (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <DoneOutline fontSize='small' />
                                    </TableCell>
                                  ) : (
                                    <ProductAddButtonComPonent
                                      snackbar={snackbar}
                                      key={row.itemNumber}
                                      align={column.align}
                                      product={product}
                                      row={row}
                                      selectedProductItemCallback={
                                        selectedProductItemCallback
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </>
                          );
                        })
                      : simpleOrder.map(column => {
                          const value = row[column.id];
                          return (
                            <>
                              {column.label !== 'Select' ? (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format &&
                                  typeof value === 'number' ? (
                                    <Tooltip title={value} placement='top'>
                                      <Typography
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          width: '90px',
                                          height: '1.2em',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {column.format(value)}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={value} placement='top'>
                                      <Typography
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          width:
                                            column.label === 'Item Name'
                                              ? '120px'
                                              : '60px',
                                          height: '1.2em',
                                          whiteSpace: 'nowrap',
                                          fontSize:
                                            column.label === 'Barcode'
                                              ? '12px'
                                              : '10px',
                                        }}
                                      >
                                        {value}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              ) : (
                                <>
                                  {/* using that dynamically added property itemAddedOnDialog here */}
                                  {row.itemAddedOnDialog ? (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <DoneOutline fontSize='small' />
                                    </TableCell>
                                  ) : (
                                    <ProductAddButtonComPonent
                                      snackbar={snackbar}
                                      key={row.itemNumber}
                                      align={column.align}
                                      product={product}
                                      row={row}
                                      selectedProductItemCallback={
                                        selectedProductItemCallback
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
const ProductAddButtonComPonent = ({
  row,
  snackbar,
  selectedProductItemCallback,
  product,
}) => {
  const [productAdded, setProductAdded] = React.useState(false);

  const handleProductItemClick = (event, rowItem) => {
    event.preventDefault();
    selectedProductItemCallback(rowItem, product);
    // snackbar('Product added successfully', 'success');
  };

  return (
    <TableCell key={row.itemNumber} align='center'>
      {!productAdded ? (
        <Button
          onClick={event => {
            handleProductItemClick(event, row);
            setProductAdded(true);
          }}
          sx={{
            textTransform: 'capitalize',
            fontSize: '12px',
            width: '126px',
            height: '27px',
          }}
        >
          Add
        </Button>
      ) : (
        <DoneOutline fontSize='small' />
      )}
    </TableCell>
  );
};
export default ProductItemAccordionDetails;
