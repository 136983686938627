import React from 'react';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Auth from '../../modules/Auth';
import AddIcon from '@mui/icons-material/Add';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import EditIcon from '@mui/icons-material/Edit';
import HTMLRenderer from 'react-html-renderer';
import Store from '../../modules/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  Box,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import MandiChalan from '../../modules/MandiChalan';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import { Loading } from '../../component/loading/Loading';

function MandiChalanList() {
  let navigate = useNavigate();
  let location = useLocation();
  const [value, setValue] = React.useState(0);

  const [growerChalanList, setGrowerChalanList] = React.useState([]);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [searchString, setSearchString] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [deletedChalan, setDeletedChalan] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    IdOfDeletableChalan: '',
  });

  React.useEffect(() => {
    getAllChalanByStoreId(0);
    getCurrency();
  }, [deletedChalan]);
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  // const debounce = (func, timeout = 350) => {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // };

  // const createOrUpdateChalan = chalan => {

  //   setTimeout(() => {
  //     navigate('/new-chalan', {
  //       state: { chalan },
  //     });
  //   }, 10);
  // };

  const createOrUpdateChalan = chalan => {
    if (chalan?.id) {
      MandiChalan.getChalanById(chalan?.id, response => {
        if (response.status === 'success') {
          const chalan = response.data;
          navigate('/new-chalan', {
            state: { chalan },
          });
        }
      });
    } else {
      setTimeout(() => {
        navigate('/new-chalan', {
          state: { chalan },
        });
      }, 10);
    }
  };

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };

  const columns = [
    {
      field: 'challanNo',
      headerName: 'S.No',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
            {`${params.row?.challanNo}`}
          </Typography>
        );
      },
    },
    {
      field: 'challanNumber',
      headerName: 'C.No',
      type: 'number',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          <Typography
            sx={{ cursor: 'pointer', color: '#3B78C9' }}
            onClick={event => {
              if (params.row.deleted) {
                openUpstoreSnackBar(
                  `Chalan is deleted you can't edit or update `,
                  'error'
                );
              } else {
                createOrUpdateChalan(params.row);
              }
            }}
          >
            {params.row.challanNumber}
          </Typography>
          <Box sx={{ pl: 3 }}>
            {params.row.edited && (
              <Tooltip title='Edited'>
                <EditIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            )}

            {/* {params.row.returned === true ? (
              <Tooltip title='Return Order is Present'>
                <KeyboardReturnIcon sx={{ fontSize: '25px', pr: 1 }} />
              </Tooltip>
            ) : null}  */}
          </Box>
        </>
      ),
    },

    {
      field: 'grower',
      headerName: 'Grower Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Typography variant='subtitle1' sx={{ cursor: 'pointer' }}>
            {`${params.row?.grower}`}
          </Typography>
        );
      },
    },
    {
      field: 'totalFreight',
      headerName: 'Total Freight',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalFreight}{' '}
            {currency && <HTMLRenderer html={currency} />}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalSelfAmount',
      headerName: 'Total Self Amount',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalSelfAmount}{' '}
            {currency && <HTMLRenderer html={currency} />}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalQuantity',
      headerName: 'Total Quantity',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params.row.totalQuantity === null ? 0 : params.row.totalQuantity}
          </Typography>
        </>
      ),
    },
    {
      field: 'totalActualQuantity',
      headerName: 'Actual Quantity',
      width: 170,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',

      renderCell: params => (
        <>
          <Typography>
            {params.row.totalActualQuantity === null
              ? 0
              : params.row.totalActualQuantity}{' '}
          </Typography>
        </>
      ),
    },
    {
      field: 'challanDate',
      headerName: 'Chalan Date',
      type: 'date',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params =>
        params.row.challanDate === null
          ? 'N / A'
          : moment.utc(params.row.challanDate).local().format('DD-MM-yyyy'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params =>
        params.row.status === 'CONFIRMED' ? 'Confirmed' : 'Drafted',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      type: 'action',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              <Tooltip title='Delete Chalan' placement='bottom'>
                <DeleteIcon
                  sx={{
                    color: params.row.deleted ? 'red' : '#3B78C9',
                    cursor: 'pointer',
                  }}
                  onClick={event => {
                    if (params.row.deleted) {
                      openUpstoreSnackBar(
                        'Chalan is already been deleted ',
                        'error'
                      );
                    } else {
                      setOpenDeleteDialog({
                        open: true,
                        IdOfDeletableChalan: params.row.id,
                      });
                    }
                  }}
                />
              </Tooltip>
            </>
          </Box>
        </>
      ),
    },
  ];
  const handlePreviousPage = () => {
    let pageNum = pageNumber - 1;
    setPageNumber(pageNum);
    getAllChalanByStoreId(pageNum);
  };

  const handleNextPage = () => {
    let pageNum = pageNumber + 1;
    setPageNumber(pageNum);
    getAllChalanByStoreId(pageNum);
  };
  const getAllChalanByStoreId = pageNum => {
    let deleted = deletedChalan;
    setLoader(true);

    MandiChalan.getChalanByStoreId(
      Auth.getStoreId(),
      pageNum,
      deleted,
      response => {
        if (response.status === 'success') {
          let sortedArray = response.data.sort(function (a, b) {
            return a.creationDate - b.creationDate;
          });
          setLoader(false);
          setGrowerChalanList(sortedArray);
        }
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDeletedChalan(false);
    } else {
      setDeletedChalan(true);
    }
  };

  const filteredChalanData = growerChalanList.filter(ChalanList => {
    return (
      ChalanList?.grower?.toLocaleLowerCase()?.includes(searchString) ||
      ChalanList?.challanNumber?.toLocaleLowerCase()?.includes(searchString)
    );
  });

  // deleting order callback
  const handleDeleteChalanCallback = usersChoice => {
    if (usersChoice === true) {
      MandiChalan.deleteChalan(
        openDeleteDialog.IdOfDeletableChalan,
        response => {
          if (response.status === 'success') {
            openUpstoreSnackBar('Chalan was deleted successfully', 'success');
            getAllChalanByStoreId(pageNumber);
            setOpenDeleteDialog(false);
          } else {
            openUpstoreSnackBar(
              'There is an issue in deleting chalan',
              'error'
            );
          }
        }
      );
    }
  };

  return (
    <>
      <Header pageName='Mandi Chalan' />

      {loader ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: '95%',
            height: 600,
            mt: 8,
            ml: 8,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <BreadCrumb pageName='Customers' />
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
                mb: 1,
              }}
            >
              <Box
                display={'flex'}
                justifyContent='space-around'
                alignItems={'center'}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name='search'
                  size='small'
                  placeholder='Search  Chalan by Chalan id & chalan Number'
                  onChange={event => {
                    event.preventDefault();
                    const searchFieldValue =
                      event.target.value.toLocaleLowerCase();
                    setSearchString(searchFieldValue);
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              sx={{ textAlign: { md: 'center' } }}
            >
              <Tooltip title='Create new chalan' placement='bottom'>
                <Button
                  sx={{
                    p: 0,
                    mb: 2,
                    textTransform: 'capitalize',

                    width: '126px',
                    height: '27px',
                    color: 'white',
                    bgcolor: '#3B78C9',
                  }}
                  startIcon={
                    <AddIcon fontSize='10px' sx={{ color: 'white' }} />
                  }
                  variant='contained'
                  onClick={() => {
                    location.state = null;
                    createOrUpdateChalan('');
                  }}
                >
                  Create Chalan
                </Button>
              </Tooltip>
            </Grid>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='Order Tabs'
              >
                <Tab label='Confirmed Challans' {...a11yProps(0)} />
                <Tab label='Deleted Challans' {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>

          <MandiChalanTabs
            columns={columns}
            data={filteredChalanData}
            value={value}
          />
          <Grid
            style={{
              padding: '1.6vh',
              float: 'right',

              bottom: '0px',
            }}
          >
            <Button
              size='small'
              variant='outlined'
              disabled={pageNumber <= 0}
              onClick={handlePreviousPage}
              sx={{ color: '#3B78C9', variant: 'outlined' }}
            >
              Prev
            </Button>
            <Button
              size='small'
              variant='outlined'
              disabled={growerChalanList.length < 20}
              sx={{ ml: 1, color: '#3B78C9' }}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </Grid>
        </Box>
      )}
      {upstoreSnackBar.open && (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      )}
      {openDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              IdOfDeletableChalan: '',
            })
          }
          deleteCallback={handleDeleteChalanCallback}
          dialogContent='Your Chalan will be deleted permanently , Are you sure you want to delete it ?'
        />
      ) : null}
    </>
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`order-tabpanel-${index}`}
        aria-labelledby={`order-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0.5 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `order-tab-${index}`,
      'aria-controls': `order-tabpanel-${index}`,
    };
  }
  function MandiChalanTabs({ columns, data, value }) {
    return (
      <Box sx={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='chalan list'
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              width: '100%',
              height: 600,

              '& .super-app-theme--header': {
                backgroundColor: '#EFFAFF',

                fontSize: '18px',
              },
            }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                  backgroundColor: 'secondary.light',
                },
              }}
              density='compact'
              rows={data}
              columns={columns}
              pageSize={20}
              hideFooter={true}
              rowsPerPageOptions={[20, 40, 60]}
              aria-label='chalan list'
            />
          </Box>
        </TabPanel>
      </Box>
    );
  }
}

export default MandiChalanList;
