import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from '../header/Header';
import User from '../../modules/User';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Tooltip } from '@mui/material';
import Configuration from '../../modules/Configuration';
import AddEditconfig from '../addEditConfig/AddEditConfig';
import EditIcon from '@mui/icons-material/Edit';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DefaultConfig() {
  const [value, setValue] = React.useState(0);
  const [businessNames, setBusinessNames] = React.useState([]);
  const [defaultConfig, setDefaultConfig] = React.useState([]);
  const [openConfigDialog, setOpenConfigDialog] = React.useState(false);
  const [configButtonClick, setConfigButtonClick] = React.useState('');
  const [configData, setConfigData] = React.useState({});
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  React.useEffect(() => {
    getDefaultConfig(0);
    getBusinessType();
  }, []);

  const columns = [
    {
      field: 'businessType',
      headerName: 'Business Type',
      width: 200,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'setting',
      headerName: 'Setting Name',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      type: 'string',
      align: 'left',
      renderCell: params => (
        <>
          <Typography>{params?.row?.defaultConfiguration?.setting}</Typography>
        </>
      ),
    },
    {
      field: 'settingNumber',
      headerName: 'Setting Number',
      width: 200,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>
            {params?.row?.defaultConfiguration?.settingNumber}
          </Typography>
        </>
      ),
    },
    {
      field: 'defaultValue',
      headerName: 'Configuration Value',
      width: 250,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          <Typography>{params?.row?.value}</Typography>
        </>
      ),
    },
    {
      field: 'type',
      headerName: 'Configuration Type',
      width: 250,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          <Typography>{params?.row?.defaultConfiguration?.type}</Typography>
        </>
      ),
    },
    {
      field: 'hide',
      headerName: 'Hidden',
      width: 150,
      type: 'boolean',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => (
        <>
          {params.row.hide ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography>No</Typography>
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <Tooltip title='Edit Configuration' placement='bottom'>
              <EditIcon
                onClick={() => {
                  setConfigButtonClick('Edit');

                  setConfigData(params.row);
                  setOpenConfigDialog(true);
                }}
                sx={{ color: '#3B78C9' }}
              />
            </Tooltip>
          </Box>
        </>
      ),
    },
  ];

  const getDefaultConfig = value => {
    let businessType =
      value === 0
        ? 'STORE'
        : value === 1
        ? 'DISTRIBUTOR'
        : value === 2
        ? 'MANDI'
        : value === 3
        ? 'OPTICAL'
        : 'STORE';
    Configuration.getDefaultConfigurationByBusinessTypeForMonitoringTool(
      businessType,
      response => {
        if (response.status === 'success') {
          setDefaultConfig(response.data);
        } else {
          setDefaultConfig([]);
        }
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getDefaultConfig(newValue);
  };
  const getBusinessType = () => {
    User.AllBusinessType(response => {
      if (response.status === 'success') {
        setBusinessNames(response.data);
      } else {
        setBusinessNames([]);
      }
    });
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  return (
    <Box sx={{ width: '95%', mt: 7, ml: 7 }}>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <Button
          variant='contained'
          sx={{
            ml: 'auto',
            p: 0,
            textTransform: 'capitalize',
            width: '126px',
            height: '27px',
            color: 'white',
            bgcolor: '#3B78C9',
          }}
          onClick={() => {
            setOpenConfigDialog(true);
            setConfigButtonClick('Add');
          }}
        >
          Add Config
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {businessNames.map((item, index) => {
            return (
              <Tab
                key={item.id}
                label={item.businessName}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {defaultConfig.length > 0 ? (
          <DefaultConfigGrid data={defaultConfig} columns={columns} />
        ) : (
          <Typography>No Configuration Found</Typography>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DefaultConfigGrid data={defaultConfig} columns={columns} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DefaultConfigGrid data={defaultConfig} columns={columns} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DefaultConfigGrid data={defaultConfig} columns={columns} />
      </TabPanel>
      {openConfigDialog ? (
        <AddEditconfig
          openDialog={openConfigDialog}
          closeDailog={() => {
            setOpenConfigDialog(false);
          }}
          configData={configData}
          configButtonClick={configButtonClick}
          businessType={businessNames}
          snackbar={openUpstoreSnackBar}
          getDefaultConfig={getDefaultConfig}
          value={value}
        />
      ) : null}
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </Box>
  );
}

const DefaultConfigGrid = ({ data, columns }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 600,

        '& .super-app-theme--header': {
          backgroundColor: '#EFFAFF',

          fontSize: '18px',
        },
      }}
    >
      <DataGrid
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{
          boxShadow: 2,
          border: 6,
          backgroundColor: '#fff',
          borderColor: '#eee',
          color: 'black',
          '& .MuiDataGrid-cell': {
            outline: 'none',
            fontSize: '16px',
            fontWeight: '25px',
          },
          '& .MuiDataGrid-cell:hover': {
            outline: 'none',
            color: '#21211f',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& svg': {
            color: '#3b78c9',
          },
          '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
            color: 'black',
          },
        }}
        rows={data}
        density='compact'
        columns={columns}
        pageSize={20}
        hideFooter={true}
        rowsPerPageOptions={[20, 40, 60]}
        aria-label='config list'
      />
    </Box>
  );
};
