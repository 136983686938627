import React from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import './style.css';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';

class SecuritySetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }
  initState = () => {
    const initState = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      validCurrentPassword: false,
      validNewPassword: false,
      validConfirmPassword: false,
      invalidCurrentPassword: false,
      invalidNewPassword: false,
      invalidConfirmPassword: false,
    };
    return initState;
  };

  // passwordExists = () => {
  //   this.setState(
  //     {
  //       validCurrentPassword: Validation.validateCurrentPassword(
  //         this.state.currentPassword
  //       ),
  //     },
  //     () => {
  //       this.setState({
  //         invalidCurrentPassword: !this.state.validCurrentPassword,
  //       });
  //     }
  //   );
  // };
  checkPassword = event => {
    if (event.target.name === 'currentpassword') {
      // this.passwordExists();
    } else if (event.target.name === 'newpassword') {
      if (Validation.validatePassword(this.state.newPassword)) {
        this.setState({ validNewPassword: true, invalidNewPassword: false });
      } else {
        this.setState({ validNewPassword: false, invalidNewPassword: true });
      }
    } else if (event.target.name === 'confirmpassword') {
      if (
        Validation.validatePassword(this.state.confirmPassword) &&
        this.state.newPassword === this.state.confirmPassword
      ) {
        this.setState({
          validConfirmPassword: true,
          invalidConfirmPassword: false,
        });
      } else {
        this.setState({
          validConfirmPassword: false,
          invalidConfirmPassword: true,
        });
      }
    }
  };
  changePassword = event => {
    if (this.state.validNewPassword && this.state.validConfirmPassword) {
      const data = {
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      };

      const id = Auth.getUserLoginId();
      Auth.changePassword(id, data, response => {
        if (response.status === 'success' && response.data?.includes('Wrong')) {
          this.props.upstoreSnackBar('Current Password Not Valid', 'error');
          this.setState(this.initState());
        } else if (response.status === 'success' && !response.data) {
          this.props.upstoreSnackBar(
            'Password Changed Successfully',
            'success'
          );
          Auth.logout();
          this.setState(this.initState());
        } else {
          this.props.upstoreSnackBar('Unable to change password', 'error');
        }
      });
    }
  };

  render() {
    return (
      <Grid container className='SecuritySetting' style={{ minHeight: '100%' }}>
        <Grid
          item
          md={6}
          sm={12}
          className='SecuritySetting__typography'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '2rem',
          }}
        >
          <Typography gutterBottom={true} variant='h4'>
            Change Password
          </Typography>
          <Typography gutterBottom={true} variant='subtitle1'>
            Password must contain:
          </Typography>
          <Typography variant='body1'>At Least 6 Characters</Typography>
          <Typography variant='body1'>
            At Least 1 upper case letter (A..Z)
          </Typography>
          <Typography variant='body1'>
            At Least 1 upper lower case letter (a..z)
          </Typography>
          <Typography variant='body1'>At Least 1 Number (0..9)</Typography>
          <Typography variant='body1'>
            At Least 1 Special Character (!@#$%^&*)
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} className='SecuritySetting__fields'>
          <TextField
            variant='outlined'
            margin='normal'
            required
            label='current password'
            fullWidth
            name='currentpassword'
            type='password'
            value={this.state.currentPassword}
            onChange={e => {
              this.setState({ currentPassword: e.target.value });
            }}
            onBlur={this.checkPassword}
            id='password'
            autoComplete='current-password'
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            label='new password'
            fullWidth
            name='newpassword'
            type='password'
            value={this.state.newPassword}
            onChange={e => {
              this.setState({ newPassword: e.target.value }, () => {
                this.checkPassword(e);
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {this.state.newPassword.length > 6 ? (
                    this.state.validNewPassword ? (
                      <Check style={{ color: '#42BA96' }} />
                    ) : (
                      <Clear style={{ color: '#DF4759' }} />
                    )
                  ) : null}
                </InputAdornment>
              ),
            }}
            // type={values.showPassword ? 'text' : 'password'}
            id='new-password'
            autoComplete='current-password'
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            label='confirm new password'
            fullWidth
            name='confirmpassword'
            value={this.state.confirmPassword}
            onChange={e => {
              this.setState({ confirmPassword: e.target.value }, () => {
                this.checkPassword(e);
              });
            }}
            type='password'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {this.state.confirmPassword.length > 6 ? (
                    this.state.validConfirmPassword &&
                    this.state.confirmPassword === this.state.newPassword ? (
                      <Check style={{ color: '#42BA96' }} />
                    ) : (
                      <Clear style={{ color: '#DF4759' }} />
                    )
                  ) : null}
                </InputAdornment>
              ),
            }}
            id='current-password'
            autoComplete='current-password'
          />
          <Button
            variant='contained'
            color='primary'
            fullWidth={true}
            className='SecuritySetting__save-button'
            onClick={() => {
              this.changePassword();
            }}
            sx={{
              textTransform: 'capitalize',
              padding: 0,
              width: '126px',
              height: '27px',
              float: 'right',
            }}
          >
            Save password
          </Button>
        </Grid>
      </Grid>
    );
  }
}
export default SecuritySetting;
