import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { Paper, CardMedia } from '@mui/material';

import './banners-xs.scss';
import UpstoreProperties from '../../modules/UpstoreProperties';
import Validation from '../../modules/Validation';
import { serverConfig } from '../../config/server-config';
import Auth from '../../modules/Auth';

function launchLink(link) {
  window.location.href = link;
}

function Banner(props) {
  return (
    <Paper className='BannerXS' elevation={10}>
      <CardMedia
        className='Media'
        image={props.item.image}
        style={{ borderRadius: '5px' }}
        onClick={event => launchLink(props.item.Link)}
      />
    </Paper>
  );
}

export default class BannersXS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlay: true,
      timer: 500,
      animation: 'fade',
      indicators: true,
      timeout: 500,
      bannerItems: [],
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false,
    };
  }
  componentDidMount() {
    this.createBannerItems();
  }

  createBannerItems = () => {
    let bannerImageLinks = UpstoreProperties.getProperty('BANNER_IMAGE_LINKS');
    if (Validation.isBlankNot(bannerImageLinks)) {
      bannerImageLinks = JSON.parse(bannerImageLinks);
    }

    let items = [];
    let bannerImageIndex = 1;
    for (var i = 0; i < bannerImageLinks.length; i++) {
      let bannerObj = {
        image:
          serverConfig.cmsSource +
          'store_' +
          Auth.getStoreId() +
          '/images' +
          '/Banners/banner_images-BannerImageIndex-' +
          bannerImageIndex,
        link: bannerImageLinks[bannerImageIndex++ - 1],
      };
      items.push(bannerObj);
    }

    this.setState({ bannerItems: items });
  };

  render() {
    return (
      <div style={{ color: '#494949' }}>
        <Carousel
          key={this.state.bannerItems}
          className='BannerXS'
          infiniteLoop={true}
          autoPlay={this.state.autoPlay}
          timer={this.state.timer}
          animation={this.state.animation}
          indicators={this.state.indicators}
          timeout={this.state.timeout}
          navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
          showThumbs={false}
        >
          {this.state.bannerItems.map((item, index) => {
            return <Banner item={item} key={index} />;
          })}
        </Carousel>
      </div>
    );
  }
}
