import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Skeleton,
  TextField,
  Tooltip,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Customer from '../../modules/Customer';
import Auth from '../../modules/Auth';
import { Loading } from '../loading/Loading';
import { Close } from '@mui/icons-material';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import { debounce } from '../../utils';

function CheckCustomerBalance({
  openDialog,
  closeDialog,
  snackbar,
  selectedCustomerRowData,
}) {
  const [customerList, setCustomerList] = useStateCallback([], () => {});
  const [currency, setCurrency] = React.useState(null);
  const [offset, setOffset] = React.useState(0);
  const [responseData, setResponseData] = React.useState([]);
  const [filteredArray, setFilteredArray] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  const [noCustomerFound, setNoCustomerFound] = React.useState(false);

  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [searchVal, setSearchVal] = React.useState('');

  React.useEffect(() => {
    if (selectedCustomerRowData) {
      setSearchVal(`${selectedCustomerRowData.customerName}`);
    }
    getCustomerData(pageNo);
    getCurrency();
  }, []);
  const page_size = 18;
  // const getSlicedData = (data, offSetVal, calledAtSearchEmpty = false) => {
  //   if (data.length > offSetVal) {
  //     setSkeletonLoading(true);
  //     setNoCustomerFound(false);
  //     // const slice = data.slice(offSetVal, offSetVal + 6);
  //     // setOffset(offSetVal + 6);
  //     let ids = { customerIds: [] };
  //     ids.customerIds = data.map(customer => {
  //       return customer.id;
  //     });
  //     Customer.getCustomerBalance(ids, response => {
  //       if (response.status === 'success') {
  //         let updatedSlice = data.map(customer => {
  //           let ii = response.data.find(item => {
  //             return item.customerId === customer.id;
  //           });
  //           customer.balanceAmount = ii?.balanceAmount;
  //           customer.billDate = ii?.lastBillId?.billDate;
  //           customer.dueDate = ii?.lastBillId?.dueDate;
  //           return customer;
  //         });
  //         // let arr = customerList;

  //         // updatedSlice = arr.concat(updatedSlice);
  //         if (calledAtSearchEmpty) {
  //           setCustomerList([...customerList, ...data]);
  //         } else {
  //           setCustomerList([...new Set(updatedSlice)]);
  //         }
  //         setLoader(false);
  //       }
  //     });
  //   }
  // };

  // const getCustomerData = pageNum => {
  //   // if (responseData.length <= 0) {
  //   Customer.getAllCustomers(Auth.getStoreId(), pageNum, true, response => {
  //     if (response.status === 'success') {
  //       let filterry = response.data.filter(item => {
  //         return (
  //           item.customerName?.toUpperCase() !== 'ANONYMOUS' &&
  //           item.deleted == false
  //         );
  //       });
  //       setResponseData(filterry);
  //       // when selectedCustomerRowData prop is available
  //       if (
  //         selectedCustomerRowData !== undefined &&
  //         selectedCustomerRowData !== null
  //       ) {
  //         let arr = filterry;
  //         arr = arr.concat(selectedCustomerRowData);
  //         getSlicedData(arr, 0);
  //       } else {
  //         getSlicedData(filterry, 0);
  //       }
  //     } else {
  //       setCustomerList([]);
  //       setLoader(false);
  //     }
  //   });
  //   // } else {
  //   //   getSlicedData(responseData, 0, true);
  //   // }
  // };
  const getCustomerBalance = (customerData, ids, calledAt) => {
    Customer.getCustomerBalance(ids, response => {
      if (response.status === 'success') {
        let updatedSlice = customerData.map(customer => {
          let ii = response.data.find(item => {
            return item.customerId === customer.id;
          });

          customer.balanceAmount = ii?.balanceAmount;
          customer.lastModifiedDate = ii?.lastModifiedDate;
          return customer;
        });

        // let arr = customerList;

        // arr = arr.concat(updatedSlice);
        // let filterData = arr.filter(item => {
        //   return item.balanceAmount !== undefined;
        // });
        // let sortarra = filterData.sort(function (a, b) {
        //   return b.balanceAmount - a.balanceAmount;
        // });

        if (calledAt) {
          setCustomerList(updatedSlice);
        } else {
          setCustomerList([...customerList, ...updatedSlice]);
        }
        setLoader(false);
      }
    });
  };
  const getCustomerData = pageNo => {
    Customer.getAllCustomers(Auth.getStoreId(), pageNo, true, response => {
      if (response.status === 'success') {
        let filteredAnonymous = response.data.filter(item => {
          return item.customerName?.toUpperCase() !== 'ANONYMOUS';
        });
        if (filteredAnonymous.length === 0) {
          setHasMoreData(false);
        }
        if (filteredAnonymous.length > 0) {
          // setCustomerList(filteredAnonymous);
          // setCustomerListiii(filteredAnonymous);
          let ids = { customerIds: [] };
          ids.customerIds = filteredAnonymous.map(customer => {
            return customer.id;
          });

          getCustomerBalance(filteredAnonymous, ids);
        }
      } else {
        setCustomerList([]);
      }

      setSkeletonLoading(false);
    });
  };

  const handleScroll = debounce(e => {
    let element = e.target;
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 100 &&
      hasMoreData
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;
      setPageNo(pageNum);
      getCustomerData(pageNum);
    } else {
      setSkeletonLoading(false);
    }
  }, 500);

  const handleFilterCustomerChange = event => {
    let searchVal = event.target.value;
    if (searchVal) {
      Customer.getCustomerSearchName(searchVal, Auth.getStoreId(), response => {
        if (response.status === 'success') {
          let filteredAnonymous = response.data.filter(item => {
            return (
              item.customerName?.toUpperCase() !== 'ANONYMOUS' &&
              item.deleted == false
            );
          });
          setCustomerList(filteredAnonymous);
          let ids = { customerIds: [] };
          ids.customerIds = filteredAnonymous.map(customer => {
            return customer.id;
          });

          if (filteredAnonymous.length > 0) {
            getCustomerBalance(filteredAnonymous, ids, true);
            setNoCustomerFound(false);
          } else {
            setNoCustomerFound(true);
            setCustomerList([]);
          }
        }
      });
    } else {
      getCustomerData(0);
    }
  };
  const handlecustomerChange = debounce(handleFilterCustomerChange, 500);

  // const handleFilterCustomerChange = event => {
  //   setSearchVal(event.target.value);
  //   if (
  //     event.target.value == '' ||
  //     event.target.value.length <= 0 ||
  //     event.target.value == false
  //   ) {
  //     // Promise.resolve()
  //     //   .then(() => {
  //     //     setCustomerList([]);
  //     //     setFilteredArray([]);
  //     //   })
  //     //   .then(() => {
  //     //     getSlicedData(responseData, 0);
  //     //   });
  //     // return;
  //     setCustomerList([]);

  //     setFilteredArray([]);
  //     // getSlicedData(responseData, 0, true);
  //     getCustomerData();
  //   } else if (event.target.value.length < 3) {
  //     setFilteredArray([]);
  //     return;
  //   }

  //   setCustomerList([]);
  //   let searchedCustomers = responseData.filter(customer => {
  //     return (
  //       customer.customerName
  //         ?.toUpperCase()
  //         .includes(event.target.value.toUpperCase()) ||
  //       customer.email
  //         ?.toUpperCase()
  //         ?.includes(event.target.value.toUpperCase()) ||
  //       customer.contact?.toString().includes(event.target.value)
  //     );
  //   });

  //   if (searchedCustomers.length <= 0) {
  //     setNoCustomerFound(true);
  //   } else {
  //     setFilteredArray(searchedCustomers);
  //     getSlicedData(searchedCustomers, 0, true);
  //   }
  // };
  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      open={openDialog}
      // onClose={closeDialog}
      maxWidth='md'
      fullWidth
    >
      {loader ? (
        <Loading />
      ) : (
        <>
          <DialogTitle sx={{ backgroundColor: '#E1EEF5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='h6'>Customers</Typography>

              <TextField
                size='small'
                sx={{ width: '50%', mb: 1 }}
                variant='outlined'
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position='end'>
                //       <Tooltip placement='right-start' title='clear'>
                //         <Close
                //           fontSize='small'
                //           onClick={event => {
                //             setSearchVal('');
                //             getSlicedData(responseData, 0);
                //           }}
                //           sx={{ cursor: 'pointer' }}
                //         />
                //       </Tooltip>
                //     </InputAdornment>
                //   ),
                // }}
                // value={searchVal}
                onChange={e => handlecustomerChange(e)}
                placeholder='Search by Name Email or Phone'
                id='fullWidth'
              />
              <Tooltip title='Close dialog' placement='bottom'>
                <Close
                  onClick={closeDialog}
                  sx={{ color: '#3B78C9', cursor: 'pointer' }}
                />
              </Tooltip>
            </Box>
          </DialogTitle>

          {noCustomerFound ? (
            <DialogContent sx={{ height: '400px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '100px',
                }}
              >
                <h2 style={{ color: 'gray' }}> No Customer Found</h2>
              </div>
            </DialogContent>
          ) : (
            <DialogContent sx={{ maxHeight: '400px' }} onScroll={handleScroll}>
              {customerList.length > 0 &&
                customerList.map(customer => (
                  <>
                    <Box
                      key={customer.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <List sx={{ width: '100%' }}>
                        <ListItem alignItems='flex-start'>
                          <ListItemAvatar>
                            <Avatar
                              alt={customer.customerName}
                              src='/static/images/avatar/1.jpg'
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                variant='subtitle1'
                                color='text.primary'
                              >
                                {`${customer.customerName}`}
                              </Typography>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component='span'
                                >
                                  {customer.contact}
                                  &nbsp;
                                </Typography>
                                --{customer.address}
                                {/* -- {customer.email} */}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                      <Box
                        sx={{
                          width: '200px',
                          // mb: -4,
                        }}
                      >
                        <Typography variant='subtitle2' color='text.primary'>
                          Balance:- &nbsp;{customer.balanceAmount}{' '}
                          {currency ? <HTMLRenderer html={currency} /> : null}
                        </Typography>
                        <Typography variant='subtitle2' color='text.primary'>
                          Bill Date:- &nbsp;
                          {customer.billDate &&
                            customer.billDate.substring(0, 10)}
                        </Typography>
                        <Typography variant='subtitle2' color='text.primary'>
                          Due Date:- &nbsp;
                          {customer.dueDate &&
                            customer.dueDate.substring(0, 10)}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                ))}

              <Box>
                <Skeleton
                  width='100%'
                  height={50}
                  style={{ textAlign: 'center' }}
                >
                  Scroll down to Load more ...
                </Skeleton>
              </Box>
              {skeletonLoading ? (
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                  }}
                  open={skeletonLoading}
                >
                  <CircularProgress color='inherit' />
                </Backdrop>
              ) : null}
            </DialogContent>
          )}
          <DialogActions>
            <Button
              variant='outlined'
              sx={{
                width: '126px',
                height: '27px',
                textTransform: 'capitalize',
              }}
              onClick={closeDialog}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
const useStateCallback = (initVal, callBack) => {
  const [state, setState] = React.useState(initVal);
  React.useEffect(() => callBack(state), [state]);
  return [state, setState];
};
export default CheckCustomerBalance;
