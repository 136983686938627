import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Typography } from '@mui/material';

const ref = React.createRef();

class PrescriptionHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PrescData: props.historyData,
      options: {
        unit: 'pt',
        format: [900, 300],
      },
      currency: null,
      logoUrl: null,
    };
  }

  render() {
    return (
      <div className='col-12'>
        <div className='card'>
          <div className='header d-flex justify-content-center'>
            <table>
              <tr className='invoice_Hed align-self-center'>
                <th>
                  <Typography>PRESCRIPTION HISTORY</Typography>
                </th>
              </tr>
            </table>
          </div>

          <div className='products p-2'>
            <table
              className='table table-borderless'
              style={{ borderRadius: '1px solid black' }}
            >
              <tbody>
                <tr className='add'>
                  <th>Rx</th>
                  <th>Date</th>
                  <th>SPH</th>
                  <th>CYL</th>
                  <th>AXIS</th>
                  <th>ADD</th>
                  <th>PRISM</th>
                  <th>VISION</th>
                  <th>PD</th>
                </tr>
                {this.state.PrescData !== undefined &&
                this.state.PrescData !== null
                  ? this.state.PrescData.map(item => {
                      return (
                        <tr className='content ' key={item.id}>
                          <td>{item.rx} </td>
                          <td style={{ width: '150px' }}>
                            {new Date(item.creationDate)
                              .toString()
                              .substring(0, 15)}{' '}
                          </td>
                          <td>{item.sph === null ? '_' : item.sph}</td>
                          <td className='content'>
                            {item.cyl === null ? '_' : item.cyl}
                          </td>
                          <td className='content'>
                            {item.axis === null ? '_' : item.axis}
                          </td>
                          <td className='content'>
                            {item.add === null ? '_' : item.add}
                          </td>
                          <td className='content'>
                            {item.prism === null ? '_' : item.prism}
                          </td>
                          <td className='content'>
                            {item.vision === null ? '_' : item.vision}
                          </td>
                          <td className='content'>
                            {item.pd === null ? '_' : item.pd}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default PrescriptionHistory;
