import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Auth from '../../modules/Auth';

export default function IdleTimer() {
  const [tokenTimeout, setTokenTimeout] = React.useState(0);
  React.useEffect(() => {
    const AuthData = JSON.parse(localStorage.getItem('Auth'));
    const refreshTokenTime = AuthData?.refreshTokenTime;
    setTokenTimeout(refreshTokenTime);
  }, []);

  // const getRefreshToken = () => {
  //   if (Auth.isAuthenticated()) {
  //     debugger;
  //     UpstoreProperties.getUpstoreProperties(
  //       Auth.getStoreId(),
  //       false,
  //       response => {
  //         if (response.status === 'success') {
  //           console.log('Zahid');
  //           let property = response.data.find(
  //             prop => prop.property === 'REFRESH_TOKEN_TIMEOUT'
  //           );
  //           let val = property?.propertyValue;
  //           setTokenTimeout(val);
  //         }
  //       }
  //     );
  //   }
  // };

  const idleTimeRef = React.useRef(null);
  const onIdle = () => {
    if (Auth.isAuthenticated()) {
      Auth.logout();
    }
  };

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    timeout: tokenTimeout ? tokenTimeout : 60000 * 30,
    // timeout: 10000,
    onIdle: onIdle,
  });
  return <div idleTimer={idleTimer}></div>;
}
