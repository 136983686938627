import React, { useState, useEffect } from 'react';
import {
  Typography,
  Link,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import frontImage from '../../assets/images/frontImg.png';
import { useNavigate, useLocation } from 'react-router-dom';
import Auth from '../../modules/Auth';
import { CircularProgress } from '@mui/material';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './style.css';
import AllPermissions from '../../modules/AllPermissions';
import UpstoreProperties from '../../modules/UpstoreProperties';
import Configuration from '../../modules/Configuration';
import Store from '../../modules/Store';
import { Box } from '@mui/system';
import Logo from '../../assets/images/logo.png';
const Signin = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      if (Auth.getUserRoleId() && Auth.getUserRoleId() == -1) {
        navigate('/monitoring-tool');
      } else if (Auth?.getUserRoleId() == 4) {
        navigate('/agent');
      } else {
        navigate('/categories');
      }
      AllPermissions.setUserAccessiblePermissions(Auth.getUserLoginId());
      UpstoreProperties.getUpstoreProperties(
        Auth.getStoreId(),
        false,
        response => {}
      );
    }
  }, []);

  // for password toggling
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const onForgotPassword = () => {
    navigate('/forgot-password');
  };

  // func for logging user
  const onSubmit = data => {
    setLoader(true);
    Auth.authenticate(data, response => {
      if (response.status === 'success') {
      } else if (response.data == 426) {
        setLoader(false);
        openUpstoreSnackBar(
          'Subscription expired, please subscribe first',
          'error'
        );
      } else {
        setLoader(false);
        openUpstoreSnackBar('username or password incorrect', 'error');
      }
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',

        backgroundImage:
          'linear-gradient(to left bottom, rgb(230,242,255) 0%, rgb(230,242,255) 60%, rgb(195,213,255) 100%)',
      }}
    >
      <Grid
        container
        spacing={3}
        className='form__div'
        sx={{ paddingLeft: '8rem' }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          className='left__content'
          sx={{
            boxShadow: '-5px 5px 1rem rgba(0,0,0,0.1)',
            backgroundColor: 'secondary.light',
            p: 8,
            borderRadius: '10px',
            height: '520px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <form
              autoComplete='off'
              className='left__content--form'
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={Logo}
                  style={{
                    height: '80px',
                  }}
                ></img>
              </Box>
              <Typography
                varient='h5'
                textAlign='center'
                sx={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: '#3B78C9',
                  pt: 1,
                }}
              >
                Sign In
              </Typography>
              <TextField
                size='small'
                placeholder='Enter User Name'
                margin='normal'
                fullWidth={true}
                autoComplete='off'
                name='username'
                type='text'
                id='username'
                // autoComplete="username"
                {...register('username', {
                  required: 'Username is required to fill',
                })}
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
              />

              <TextField
                size='small'
                placeholder='Enter Password'
                margin='normal'
                required
                fullWidth={true}
                name='password'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id='Password'
                autoComplete='current-Password'
                {...register('password', {
                  required: 'Password is required to fill',
                })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />

              <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ mr: 1 }}>
                  <Link
                    onClick={onForgotPassword}
                    sx={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    Forgot Password ?{' '}
                  </Link>
                </Typography>
                <Typography className='sign_up'>
                  Don't have an account ?{' '}
                  <Link
                    onClick={() => {
                      navigate('/signup', { replace: true });
                    }}
                    sx={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    SignUp
                  </Link>
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  sx={{
                    fontSize: '16px',
                    bgColor: 'primary.main',
                    color: '#fff',
                    mt: 2,
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                  }}
                  type='submit'
                  variant='contained'
                >
                  Login
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
                {loader ? (
                  <CircularProgress style={{ color: '#3B78C9' }} />
                ) : null}
              </Box>
            </form>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} lg={5} className='secondary__text1'>
          <Typography
            className='heading__right'
            varient='h5'
            textAlign='start'
            sx={{
              pl: 2,
              fontSize: '20px',
              color: '#3B78C9',
              textAlign: 'center',
            }}
          >
            Accounting made easy as counting
          </Typography>
          <Typography
            varient='h5'
            textAlign='start'
            sx={{
              pl: 2,
              color: 'secondary.main',
              textAlign: 'center',
              fontSize: '',
            }}
          >
            Use UpStore for store management and many more...
          </Typography>
          <img src={frontImage} alt='img' className='image' />
        </Grid>
      </Grid>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
};

export default Signin;
