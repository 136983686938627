import { locale } from 'moment';
import UpstoreApi from '../UpstoreApi';
class UserSubscription {
  constructor() {
    this.userSubscriptionPlans = JSON.parse(
      localStorage.getItem('storeSubscriptionPlans')
    );
  }
  setSubscriptionPlans = subscriptionPlan => {
    this.userSubscriptionPlans = { ...subscriptionPlan };
    localStorage.setItem(
      'storeSubscriptionPlans',
      JSON.stringify(subscriptionPlan)
    );
  };

  getUserSubscriptionByStoreId = (storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `user-subscription/subscription/${storeId}`,
    })
      .then(response => {
        if (response.data) {
          this.setSubscriptionPlans(response.data);
          callback({ status: 'success', data: response.data });
        }
      })
      .catch(err => {
        console.log(
          'Error occurred while getting user subscription',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  addSubscription = (userId, subscriptionPlanId, callBack) => {
    let body = {
      userId,
      subscriptionPlanId,
    };
    UpstoreApi({
      method: 'POST',
      url: `/user-subscription/subscribeUser/`,
      data: body,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while  Subscription', error.message);
        callBack({ status: 'error' });
      });
  };
  getAllSubscriptionPlans = callback => {
    UpstoreApi({
      method: 'GET',
      url: `/subscription-plan/all`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting  subscription Plan',
          err.message
        );
        callback({ status: 'error' });
      });
  };

  getAdminId = (storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/user/get-admin/${storeId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting   Admin Id', err.message);
        callback({ status: 'error' });
      });
  };

  lockUser = (storeId, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/stores/lock/${storeId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured While locking User   ', error.message);
        callBack({ status: 'error' });
      });
  };
  unlockUser = (storeId, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/stores/un-lock/${storeId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured While unlocking User   ', error.message);
        callBack({ status: 'error' });
      });
  };

  getSoftDeleteOtp = (storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/stores/get-otp-softDelete-store/${storeId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting  Otp ', err.message);
        callback({ status: 'error' });
      });
  };
  //soft delete
  softDeleteAccount = (storeId, otp, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/stores/soft-delete/${storeId}/${otp}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Error occured While Deleting User   ', error.message);
        callBack({ status: 'error' });
      });
  };
  getHardDeleteOtp = (storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/stores/get-otp-delete-store/${storeId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting Hard Delete Otp ',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  //Hard  delete
  hardDeleteAccount = (storeId, otp, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/stores/delete-store-by-otp/${storeId}/${otp}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Error occured While Deleting User   ', error.message);
        callBack({ status: 'error' });
      });
  };
}

export default new UserSubscription();
