import * as React from 'react';
import {
  Button,
  TextField,
  Box,
  Tooltip,
  Table,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableFooter,
  Divider,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListOrderItem from '../listOrderItem/ListOrderItem';
import { DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import ReturnOrder from '../../modules/ReturnOrder';
import Order from '../../modules/Order';
import Auth from '../../modules/Auth';
import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import ReturnOrderItems from '../returnOrderItems/ReturnOrderItems';
import Store from '../../modules/Store';
import Validation from '../../modules/Validation';
import DatePickerComp from '../datePicker/DatePicker';
import dateConveter from '../../modules/DateConveter/dateConveter';
import HTMLRenderer from 'react-html-renderer';

export default function ReturnOrderDialog(props) {
  const totalReturnAmountArr = props.storeOrderProductItems.map(item => {
    return item.amount;
  });

  const totalReturnAmount = totalReturnAmountArr.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const [openAddReasonDialog, setOpenAddReasonDialog] = React.useState(false);
  const [returnOrder, setReturnOrder] = React.useState([]);
  const [editReturnOrder, setEditReturnOrder] = React.useState(false);
  const [storeOrderProductItemsList, setStoreOrderProductItemsList] =
    React.useState([...props.storeOrderProductItems]);
  const [returnableItems, setReturnableItems] = React.useState([]);
  const [returnedOrders, setReturnedOrders] = React.useState({
    totalAmount: totalReturnAmount,
  });
  const [successReturn, setSuccessReturn] = React.useState(false);
  const [buttonSubmitted, setButtonSubmitted] = React.useState(false);

  const [returnOrderDate, setReturnOrderDate] = React.useState(
    new Date(props?.storeOrderObj?.invoiceDate)
  );

  React.useEffect(() => {
    getReturnedOrders(props?.storeOrderObj?.id);
  }, []);

  const openReasonDialog = () => {
    setOpenAddReasonDialog(props?.storeOrderObj, 'storeOrderObj');
  };

  const columns = [
    { id: 'edit', label: '', maxWidth: 2, align: 'center' },

    { id: 'itemName', label: 'Item Name', maxWidth: 300, align: 'left' },

    { id: 'itemQuantityUnit', label: 'Unit', maxWidth: 100, align: 'center' },
    {
      id: 'noOfItems',
      label: 'Qty',
      maxWidth: 100,
      align: 'center',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'mrp',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'MRP',
      maxWidth: 100,
      align: 'center',
    },
    {
      id: 'storePrice',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? 'Price'
          : Store?.companyDetails?.businessType === 'STORE'
          ? 'M.R.P'
          : 'Store Price',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'discount',
      label: props?.currency ? (
        <>
          Discount(%/
          <HTMLRenderer html={props?.currency} />){' '}
        </>
      ) : (
        'Discount'
      ),
      maxWidth: 80,
      align: 'left',
    },
    {
      id: 'otherCharges',
      label: 'Other Charges',
      maxWidth: 150,
      align: 'left',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'MANDI' ? null : 'taxableValue',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : 'Taxable Value',
      maxWidth: 150,
      align: 'left',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'gstamt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'vatamt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'TAX'
          : null,
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'Tax Amt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'Vat Amt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'Tax Amt'
          : null,
      maxWidth: 100,
      align: 'center',
    },

    {
      id: 'Packing',
      label: 'packing',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'quality',
      label: 'quality',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'Commission ',
      label: 'commission (%)',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'cess',
      label: 'Cess',
      maxWidth: 100,
      align: 'center',
    },
    {
      id: 'amount',
      label: 'Amount',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'cross',
      label: '',
      maxWidth: 20,
      align: 'center',
    },
  ];

  const simpleOrder = columns.filter(item => {
    return (
      item.label !== 'commission (%)' &&
      item.label !== 'packing' &&
      item.label !== 'quality'
    );
  });
  const column1 = [
    { id: 'itemName', label: 'Item Name', minWidth: 200, align: 'left' },
    { id: 'amount', label: 'Amount', minWidth: 200, align: 'center' },
    { id: 'return', label: 'Return', minWidth: 200, align: 'center' },

    {
      id: 'cancelReturn',
      label: 'Cancel Return',
      minWidth: 200,
      align: 'left',
    },
  ];
  const saveReturnedOrder = (status, comment) => {
    let iDate;
    setButtonSubmitted(true);
    dateConveter.convertDate(returnOrderDate, response => {
      iDate = response.data;
    });

    let orderObj = {
      ...returnOrder,
      invoiceDate: iDate,
      status,
      type: 'Customer',
      comment: `Returned Goods for Order:${props.storeOrderNumber} ${comment} `,
    };
    if (comment.length > 0) {
      if (orderObj?.orderDetails?.length > 0) {
        ReturnOrder.saveReturnOrder(orderObj, response => {
          if (response.status === 'success') {
            if (status === 'NEW') {
              props.snackbar('order is saved to draft ', 'success');
              setOpenAddReasonDialog(false);
            } else if (status === 'CONFIRMED') {
              props?.handleSetUpdatedOrderItem(response.data.orderId);
              props.snackbar('Return Order is confirmed ', 'success');
              setOpenAddReasonDialog(false);

              setTimeout(() => {
                props.close();
              }, 1000);

              Transaction.log(
                Auth.getStoreId(),
                response.data.customerId,
                LogMessage.ORDER_RETURNED,
                LogMessage.ORDER_RETURNED_TITLE,
                [
                  response.data.returnOrderNumber,
                  props.storeOrderNumber,
                  Auth.getUserName(),
                ]
              );
            }
          } else {
            props.snackbar('error while saving return order ', 'error');
            setButtonSubmitted(false);
          }
        });
      } else {
        props.snackbar('Please Select Item to Return ', 'error');
        setTimeout(() => {
          props.close();
        }, 100);
      }
    } else {
      props.snackbar('Please enter comment first ', 'error');
      setButtonSubmitted(false);
    }
  };
  const getReturnedOrders = orderId => {
    ReturnOrder.getReturnOrderByOrder(orderId, response => {
      if (response.status === 'success' && response.data.length > 0) {
        let filtererd = response.data.filter(item => {
          return item.deleted == false;
        });
        let returnOrderObj = filtererd[0];
        setReturnOrder(returnOrderObj);
        setReturnOrderDate(new Date(returnOrderObj.invoiceDate));
        setReturnableItems(returnOrderObj.orderDetails);
        setReturnedOrders(returnOrderObj);
        setSuccessReturn(true);
      } else {
        setSuccessReturn(true);
        return 'error';
      }
    });
  };

  const updateReturnOrderItems = () => {
    let iDate;

    dateConveter.convertDate(returnOrderDate, response => {
      iDate = response.data;
    });
    setEditReturnOrder(false);
    let returnOrderObj = {
      ...returnOrder,
      invoiceDate: iDate,
    };
    ReturnOrder.updateReturnOrder(returnOrderObj, returnOrder.id, response => {
      if (response.status === 'success') {
        props.snackbar('Return order is updated Sucessfully ', 'success');
        props?.handleSetUpdatedOrderItem(returnedOrders.orderId);
        props.close();
      } else {
        props.snackbar('Return order is not  Updated ', 'error');
      }
    });
  };

  const calculateReturnOrderTotalValues = productList => {
    let orderNew = Order.calculateTotalValues(productList);
    let returnOrderObj = {
      id: returnOrder?.id ? returnOrder?.id : undefined,
      customerId: props.customerInfo.id,
      customerName: props.customerInfo.customerName,
      totalItems: orderNew.totalItems,
      totalAmount: orderNew.totalAmount,
      totalDiscount: orderNew.totalDiscount,
      totalMrp: orderNew.totalMrp,
      totalStorePrice: orderNew.totalStorePrice,
      storeId: Auth.getStoreId(),
      orderDetails: orderNew.orderDetails,
      orderId: props?.storeOrderObj?.id,
      returnType: 'StoreOrder',
      invoiceDate: returnOrderDate,
    };
    setReturnOrder(returnOrderObj);
    return returnOrderObj;
  };

  const handleQuantityChangeCallback = rowDetails => {
    let taxValue = rowDetails?.taxValue
      ? rowDetails?.taxValue
      : Validation.getGstSumOrVatSum(rowDetails?.taxRate);
    let totalStorePrice = parseFloat(
      rowDetails?.storePrice * rowDetails?.noOfItems
    );
    let data = Order.getCalculatedAmount(
      rowDetails?.discount,
      totalStorePrice,
      taxValue,
      rowDetails.commission
    );
    let addedValue = {
      ...rowDetails,
      noOfItems: parseFloat(rowDetails.noOfItems),
      amount: parseFloat(data?.amount),
      taxAmount: parseFloat(data.taxAmount),
    };

    let returnAdd = returnableItems.map(item => {
      return item.itemNumber === rowDetails.itemNumber ? addedValue : item;
    });

    calculateReturnOrderTotalValues(returnAdd);
    setReturnableItems(returnAdd);
  };

  // will get two modes return and cancel on return we have to add and on cancel we have to remove items
  const handleReturnOrderCallBack = (row, mode) => {
    if (mode === 'return') {
      let allReturnableItems = [];
      if (
        returnableItems.findIndex(i => i.itemNumber == row.itemNumber) != -1
      ) {
        allReturnableItems = returnableItems.map(item => {
          if (item.itemNumber == row.itemNumber) {
            item = row;
          }
          return item;
        });
      } else {
        allReturnableItems = [...returnableItems, row];
      }
      setReturnableItems(allReturnableItems);
      calculateReturnOrderTotalValues(allReturnableItems);
    } else if (mode === 'cancel') {
      let allReturnableItems = [];
      if (row.id == undefined) {
        allReturnableItems = returnableItems.filter(
          item => item.itemNumber != row.itemNumber
        );
      } else {
        allReturnableItems = returnableItems.map(item => {
          if (item.itemNumber === row.itemNumber) {
            item = { ...row, id: item.id };
          }
          return item;
        });
      }
      setReturnableItems(allReturnableItems);
      calculateReturnOrderTotalValues(allReturnableItems);
    }
  };
  const onReturnDateChange = newValue => {
    setReturnOrderDate(newValue);
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        maxWidth='md'
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#E1EEF5',
          }}
        >
          <Typography
            variant='h4'
            fontSize='22'
            marginTop={'2px'}
            marginLeft={5}
          >
            Return Order
          </Typography>
          <Box
            sx={{
              ml: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              p: 0.5,
            }}
          >
            <Typography variant='h6' sx={{ mt: 0.5, mr: 1 }} noWrap>
              Return Date
            </Typography>

            <Box>
              <DatePickerComp
                value={returnOrderDate}
                disabled={
                  Validation.isBlankObjectNot(returnOrder)
                    ? returnOrder.status === 'CONFIRMED' && !editReturnOrder
                    : ''
                }
                minDate={new Date(props?.storeOrderObj?.invoiceDate)}
                dateChange={onReturnDateChange}
              />
            </Box>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button
              variant='contained'
              disabled={returnedOrders?.status !== 'CONFIRMED'}
              onClick={() => setEditReturnOrder(true)}
              sx={{
                mt: 1,
                p: 0,
                mr: 1,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
            >
              <Typography sx={{ fontSize: '13px' }} noWrap>
                Edit Order
              </Typography>
            </Button>
          </Box>

          <Tooltip placement='bottom' title='close'>
            <Close
              onClick={props.close}
              fontSize='medium'
              sx={{ cursor: 'pointer', mr: 1, mt: 1, color: '#3B78C9' }}
            />
          </Tooltip>
        </Box>

        <Grid
          sx={{
            mt: 2,
            ml: 5,
          }}
        >
          <TableHead>
            <TableRow>
              {column1.map(col => (
                <TableCell
                  key={col.id}
                  align={col.align}
                  style={{
                    minWidth: col.minWidth,
                    fontWeight: '500',
                    fontSize: 'large',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#E1EEF5',
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {successReturn &&
            storeOrderProductItemsList.map(row => {
              return (
                <ReturnOrderItems
                  handleQuantityChangeCallback={handleQuantityChangeCallback}
                  key={row.id}
                  row={row}
                  editReturnOrder={editReturnOrder}
                  returnOrder={returnOrder && returnOrder}
                  handleReturnOrderCallBack={handleReturnOrderCallBack}
                  closeRetDialog={props?.close}
                  snackbar={props.snackbar}
                  returnedOrders={returnedOrders}
                  storeOrderNumber={props?.storeOrderNumber}
                  handleSetUpdatedOrderItem={props?.handleSetUpdatedOrderItem}
                />
              );
            })}
          <TableFooter
            sx={{
              backgroundColor: '#E1EEF5',

              bottom: 0,
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                }}
              >
                Total
              </TableCell>
              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              ></TableCell>

              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {returnOrder?.totalItems?.toFixed(1)}
              </TableCell>

              <TableCell
                style={{
                  minWidth: 200,
                  fontSize: 'large',
                  whiteSpace: 'nowrap',
                }}
              >
                Rs: {returnOrder?.totalAmount?.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableFooter>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={returnedOrders?.status === 'CONFIRMED'}
              variant='contained'
              onClick={() => saveReturnedOrder('NEW', 'draftOrder')}
              sx={{
                fontSize: '15px',
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                mr: 2,
              }}
            >
              Save
            </Button>
            {returnedOrders?.status === 'CONFIRMED' ? (
              <Button
                variant='contained'
                onClick={updateReturnOrderItems}
                disabled={!editReturnOrder}
                sx={{
                  fontSize: '15px',
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
              >
                <Typography sx={{ fontSize: '13px' }} noWrap>
                  Update & close
                </Typography>
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={openReasonDialog}
                sx={{
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  width: '130px',
                  height: '27px',
                  p: 0,
                }}
              >
                <Typography noWrap> Confirm & close</Typography>
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item md={12} sx={{ mt: 2, ml: 5 }}>
          <Divider />
          <Typography
            variant='h4'
            fontSize='22'
            marginLeft={'10px'}
            marginTop={'2px'}
          >
            Order Details
          </Typography>
          <TableContainer
            sx={{ maxHeight: 450, maxWidth: 800 }}
            component={Paper}
            className='scroll'
          >
            <Table
              stickyHeader
              aria-label='sticky table'
              sx={{
                backgroundColor: 'secondary.light',
                position: 'relative',
              }}
              size='small'
            >
              <TableHead>
                <TableRow>
                  {Store?.companyDetails?.businessType === 'MANDI'
                    ? columns?.map(column => {
                        return (
                          <>
                            {column.label !== null ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  fontSize: 'medium',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : null}
                          </>
                        );
                      })
                    : null}
                </TableRow>
                <TableRow>
                  {Store?.companyDetails?.businessType !== 'MANDI'
                    ? simpleOrder.map(column => {
                        return (
                          <>
                            {column.label !== null ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  fontSize: 'medium',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : null}
                          </>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>

              <TableBody>
                <>
                  {storeOrderProductItemsList?.length > 0 ? (
                    <>
                      {storeOrderProductItemsList?.map(row => {
                        return (
                          <>
                            <ListOrderItem
                              row={row}
                              key={`return_${row.itemNumber}`}
                              snackbar={props.snackbar}
                              returnOrderMode={true}
                            />
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </>
              </TableBody>
              <TableFooter
                sx={{
                  backgroundColor: '#E1EEF5',
                  position: 'sticky',
                  bottom: 0,
                }}
              >
                <TableRow>
                  <TableCell
                    style={{
                      // TODO Move to .css
                      maxWidth: 30,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 50,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {props.storeOrderObj?.totalItems?.toFixed(3)}
                  </TableCell>
                  {Store.companyDetails.businessType === 'STORE' ? null : (
                    <TableCell
                      style={{
                        maxWidth: 50,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                      }}
                    ></TableCell>
                  )}
                  {Store.companyDetails.businessType === 'MANDI' ? null : (
                    <TableCell
                      style={{
                        maxWidth: 50,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                      }}
                    ></TableCell>
                  )}
                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {/* {storeOrderObj.storeOrderStatus ===
                                          'NEW' ||
                                        storeOrderObj.storeOrderStatus ===
                                          'CONFIRMED'
                                          ? storeOrderObj?.totalDiscount?.toFixed(
                                              3
                                            )
                                          : storeOrderObj?.totalDiscount?.toFixed(
                                              3
                                            )} */}
                    {props.storeOrderObj?.totalDiscount?.toFixed(3)}
                  </TableCell>
                  {!Store.getSelectedTaxType() ||
                  Store.companyDetails.businessType === 'MANDI' ? null : (
                    <TableCell
                      style={{
                        maxWidth: 50,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                      }}
                    ></TableCell>
                  )}

                  <TableCell
                    style={{
                      maxWidth: 50,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>

                  {!Store.getSelectedTaxType() ||
                  Store.companyDetails.businessType === 'MANDI' ? null : (
                    <TableCell
                      style={{
                        maxWidth: 100,
                        fontSize: 'large',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      {props.storeOrderObj.storeOrderStatus === 'NEW' ||
                      props.storeOrderObj.storeOrderStatus === 'CONFIRMED'
                        ? props.storeOrderObj.tax.toFixed(3)
                        : props.storeOrderObj?.totalTaxValue?.toFixed(3)}
                    </TableCell>
                  )}
                  {Store?.companyDetails?.businessType === 'MANDI' ? (
                    <>
                      <TableCell
                        style={{
                          maxWidth: 100,
                          fontSize: 'large',
                          whiteSpace: 'nowrap',
                        }}
                      ></TableCell>

                      <TableCell
                        style={{
                          maxWidth: 50,
                          fontSize: 'large',
                          whiteSpace: 'nowrap',
                        }}
                      ></TableCell>

                      <TableCell
                        style={{
                          maxWidth: 100,
                          fontSize: 'large',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {props.currency ? (
                          <HTMLRenderer html={props.currency} />
                        ) : null}
                        :{props.storeOrderObj?.totalCommission?.toFixed(3)}
                      </TableCell>
                    </>
                  ) : null}

                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  ></TableCell>

                  <TableCell
                    style={{
                      maxWidth: 100,
                      fontSize: 'large',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {props.currency ? (
                      <HTMLRenderer html={props.currency} />
                    ) : null}

                    {props.storeOrderObj?.totalAmount?.toFixed(3)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            {/* {!showSelected && returnedOrders.status !== 'CONFIRMED' ? (
              <div
                style={{
                  margin: '10px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  variant='outlined'
                  color='error'
                  onClick={props.close}
                  autoFocus
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={() => {
                    openReasonDialog();
                  }}
                  variant='contained'
                  autoFocus
                  sx={{
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                  }}
                >
                  Confirm
                </Button>
              </div>
            ) : (
              <>
                {returnedOrders.status !== 'CONFIRMED' ? (
                  <div
                    style={{
                      margin: '10px',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        returnItems();
                        setShowReturnConfirm('return');
                      }}
                      variant='contained'
                      sx={{
                        fontSize: '15px',
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                      }}
                    >
                      Return
                    </Button>
                  </div>
                ) : null}
              </>
            )} */}
          </TableContainer>
        </Grid>

        <DialogActions></DialogActions>
      </Dialog>

      {openAddReasonDialog ? (
        <AddReasonDialog
          openPopup={openReasonDialog}
          onClose={() => {
            setOpenAddReasonDialog(false);
          }}
          saveReturnedOrder={saveReturnedOrder}
          buttonSubmitted={buttonSubmitted}
        />
      ) : null}
    </div>
  );
}
const AddReasonDialog = props => {
  const [comment, setComment] = React.useState('');

  return (
    <Dialog open={props.openPopup} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Add Reason Alert !!
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Reason *</Typography>
        <TextField
          sx={{ width: '100%', mt: 1 }}
          id='comment'
          multiline
          size='small'
          focused
          value={comment}
          onChange={event => setComment(event.target.value)}
          placeholder='Enter reason here'
          variant='standard'
          name='comment'
        />
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant='outlined'
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          {/* <Button
            variant='contained'
            sx={{
              textTransform: 'capitalize',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={event => props.saveReturnedOrder('NEW', comment)}
          >
            Draft
          </Button> */}
          <Button
            disabled={props.buttonSubmitted}
            variant='contained'
            sx={{ textTransform: 'capitalize', width: '127px', height: '32px' }}
            onClick={event => props.saveReturnedOrder('CONFIRMED', comment)}
          >
            Confirm
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
