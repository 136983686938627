import UpstoreApi from '../UpstoreApi';
class Prescription {
  getPrescriptionByCustomerId = (customerId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/prescription/getPrescriptionByCustomerId/${customerId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting prescription  By  customer Id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  addPrescription = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/prescription/add',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding prescription', error.message);
        callBack({ status: 'error' });
      });
  };
  updatePrescription = (data, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/prescription/updatePrescriptionById/${id}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating prescription', error.message);
        callBack({ status: 'error' });
      });
  };
}
export default new Prescription();
