import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import PrintIcon from '@mui/icons-material/Print';

import ReactToPrint from 'react-to-print';
import './styles.css';
import Logo from '../../modules/Logo';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
} from '@mui/material';
import Validation from '../../modules/Validation';
import * as moment from 'moment';
import StatementDialog from '../StatementDialog/StatementDialog';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import HTMLRenderer from 'react-html-renderer';
import { Close } from '@mui/icons-material';

// for date

const ref = React.createRef();
const newDate = new Date();

class BankStatementDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDetails: this.props.vendorObj,
      customerList: [],
      payments: [],
      ledger: [],
      logoUrl: null,
      options: {
        unit: 'pt',
        format: [900, 900],
      },
      currency: null,
      customer: {},
      Vendor: {},
      customerBalance: {},
      customerData: {},
      open: false,
      storeDetails: {},
      sumOfSelfAmount: 0,
      date: {
        startDate: Store.getStoreByStoreId(Auth.getStoreId(), () => {})
          .annualStartDate,
        endDate: new Date(),
      },
      dateSelected: false,
    };
  }

  componentDidMount = () => {
    let id = this.state.orderDetails;

    this.getLogoUrl(Auth.getStoreId());

    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });
    });
    this.getCurrency();
    this.getSumOfSelfAmount();
  };

  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };
  getLogoUrl = () => {
    this.setState({
      logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
    });
  };

  getPDFHeightAndWidth = () => {
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint').clientHeight;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      this.setState({ options: opt });
    }, 5);
  };

  getSumOfSelfAmount = () => {
    let istDate = moment(this.state.date.startDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    let lastDate = moment(this.state.date.endDate).format(
      'YYYY-MM-DD[T]HH:mm:ss'
    );

    Store.getAccountStatementByBankId(
      this?.props?.bankId,
      Auth.getStoreId(),
      istDate,
      lastDate,
      response => {
        if (response.status === 'success') {
          this.setState({ ledger: response.data });
        } else {
          this.setState({ ledger: [] });
        }
      }
    );
  };
  render() {
    return (
      <Dialog open={this.props.openPopup} maxWidth='lg'>
        <Tooltip title='Close dialog' placment='right'>
          <Close
            onClick={this.props.closePopup}
            sx={{
              color: '#3B78C9',
              cursor: 'pointer',
              ml: 'auto',
              mt: 1,
              mr: 1,
            }}
          />
        </Tooltip>
        <div className='App container'>
          <DialogContent>
            <Box sx={{ display: 'flex' }}>
              <ReactToPrint
                pageStyle={`@page { size: ${this.state?.options?.format[1]}pt ${this.state?.options?.format[0]}pt }`}
                filename={`receipt__${this.state?.orderDetails?.storeOrderNumber}.pdf`}
                trigger={() => {
                  return (
                    <Button
                      variant='contained'
                      sx={{
                        textTransform: 'capitalize',
                        color: 'white',
                        bgcolor: '#3B78C9',

                        width: '126px',
                        height: '27px',
                      }}
                    >
                      <PrintIcon sx={{ color: 'white' }} /> Print
                    </Button>
                  );
                }}
                content={() => this.componentRef}
              />
              &nbsp;&nbsp;
              <Button
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  p: 0,
                  width: '126px',
                  height: '27px',
                  backgroundColor: '#3B78C9',
                  marginLeft: 'auto',
                }}
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                <Typography variant='h5' sx={{ fontSize: '12px' }} noWrap>
                  Generate Statement
                </Typography>
              </Button>
            </Box>
            <br />
            <div className='divToDownload' ref={ref}>
              <div
                id='divToPrint'
                ref={el => (this.componentRef = el)}
                style={{ height: '100%' }}
              >
                <div
                  class='row d-flex justify-content-center'
                  style={{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div class='col-12'>
                    <div class='card'>
                      <div class='d-flex flex-row p-2'>
                        <div class='d-flex flex-column'>
                          {' '}
                          <div style={{ display: 'flex' }}>
                            <h1 style={{ fontSize: '20px' }}>Bank Statement</h1>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div class='table-responsive p-2'>
                        <table class='table table-borderless'>
                          <tbody>
                            <tr class='add'>
                              <th>
                                {' '}
                                <span style={{ fontWeight: 'bold' }}>From</span>
                                <br />
                                {`${this.state.storeDetails.name}`}
                                <br />
                                {`${this.state.storeDetails.address}`}
                                <br />
                                <span style={{ fontWeight: 'bold' }}>To </span>
                                <br />
                                {this.state.Vendor.name}
                                <br />
                                {this.state.Vendor.address}
                                <br />
                              </th>
                              <th>
                                Date From :{' '}
                                <span>
                                  {!this.state.dateSelected
                                    ? moment(
                                        Store.getStoreByStoreId(
                                          Auth.getStoreId(),
                                          () => {}
                                        ).annualStartDate
                                      ).format('DD-MM-YYYY')
                                    : moment(this.state.date.startDate).format(
                                        'DD-MM-YYYY'
                                      )}
                                  &nbsp; To &nbsp;
                                  {moment(this.state.date.endDate).format(
                                    'DD-MM-YYYY'
                                  )}
                                </span>
                                <br />
                                Opening Balance :{' '}
                                <span>
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.ledger[0],
                                    'balance'
                                  )
                                    ? this.state.ledger[0].balance
                                    : '-'}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </span>
                                <br />
                                Payments :{' '}
                                <span>
                                  {Validation.isBlankObjectKeyNot(
                                    this.state.customerData,
                                    'paymentAmount'
                                  )
                                    ? this.state.customerData.paymentAmount
                                    : '-'}{' '}
                                  &nbsp;
                                  {this.state.currency ? (
                                    <HTMLRenderer html={this.state.currency} />
                                  ) : null}
                                </span>
                                <br />
                              </th>
                            </tr>
                            <tr class='content'>
                              <td class='font-weight-bold'></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div></div>
                      <hr />
                      <div
                        class='products p-2'
                        style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
                      >
                        <table class='table'>
                          <tbody>
                            <tr class='add'>
                              <th>Date</th>
                              <th>Particulars</th>
                              <th> Type</th>
                              <th>Credit</th>
                              <th class='text-center'>Debit</th>
                              <th>Balance</th>
                            </tr>

                            {this.state.ledger !== undefined &&
                            this.state.ledger !== null
                              ? this.state.ledger.map(item => {
                                  return (
                                    <tr class='content' key={item.id}>
                                      <td
                                        style={{
                                          maxWidth: '100px',
                                          whiteSpace: 'initial',
                                        }}
                                      >
                                        {item.creationDate !== null &&
                                        item.creationDate !== undefined
                                          ? new Date(item.creationDate + 'Z')
                                              .toString()
                                              .substring(0, 24) &&
                                            new Date(item.creationDate + 'Z')
                                              .toString()
                                              .substring(0, 24)
                                          : null}
                                      </td>

                                      <td
                                        style={{
                                          maxWidth: '80px',
                                          whiteSpace: 'initial',
                                        }}
                                      >
                                        {(item.particular && item.particular) ||
                                          '-'}
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: '100px',
                                          whiteSpace: 'initial',
                                        }}
                                      >
                                        {(item.type && item.type) || '-'}
                                      </td>

                                      <td>
                                        {(item.type === 'Customer' &&
                                          item.amount) ||
                                          '-'}{' '}
                                        &nbsp;
                                        {item.amount && this.state.currency ? (
                                          <HTMLRenderer
                                            html={this.state.currency}
                                          />
                                        ) : null}
                                      </td>
                                      <td class='text-center'>
                                        {(item.type === 'Vendor' &&
                                          item.amount) ||
                                          '-'}{' '}
                                        &nbsp;
                                        {item.amount && this.state.currency ? (
                                          <HTMLRenderer
                                            html={this.state.currency}
                                          />
                                        ) : null}
                                      </td>
                                      <td>
                                        {item.balance}&nbsp;
                                        {item.balance && this.state.currency ? (
                                          <HTMLRenderer
                                            html={this.state.currency}
                                          />
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div>
                        <div
                          style={{
                            textAlign: 'right',
                            paddingRight: '4rem',
                            paddingTop: '1rem',
                            paddingBottom: '1rem',
                          }}
                        >
                          <h1 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                            Balance Due :{' '}
                            <span>
                              {' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.customerBalance,
                                'balanceAmount'
                              )
                                ? this.state.customerBalance.balanceAmount
                                : 'N/A'}{' '}
                              &nbsp;{' '}
                              {this.state.customerBalance.balanceAmount &&
                              this.state.currency ? (
                                <HTMLRenderer html={this.state.currency} />
                              ) : null}
                            </span>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StatementDialog
              dateObjCallback={this.getUpdatedDateObj}
              openDialog={this.state.open}
              closeDialog={() => {
                this.setState({ open: false });
              }}
            />
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}
export default BankStatementDialog;
