import UpstoreApi from '../UpstoreApi';
import Store from '../Store';
import Auth from '../Auth';
import Validation from '../Validation';
class Order {
  getCustomerOrders = (customerId, status, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/storeOrder/${customerId}/customer-orders/${status}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customer orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  searchOrderByOrderIdOrCustomerName = (storeId, searchVal, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `storeOrder/search/${storeId}?search=${searchVal}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while searching orders by orderId',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getAllUpStoreOrdersForStore = (storeId, status, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/storeOrder/${storeId}/store-orders/${status}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting All orders for store',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getStoreOrder = (storeOrderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/storeOrder/${storeOrderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting store orders', error.message);
        callBack({ status: 'error' });
      });
  };

  // getAllOrdersByStoreId = (
  //   storeId,
  //   pageNumber,
  //   deleted,
  //   orderFromEstore,
  //   callBack
  // ) => {
  //   UpstoreApi({
  //     method: 'GET',
  //     url: `/storeOrder/store-orders/${storeId}?pageNumber=${pageNumber}&deleted=${deleted}&orderFromEstore=${orderFromEstore}`,
  //   })
  //     .then(response => {
  //       if (response.data) {
  //         callBack({ status: 'success', data: response.data });

  //         Store.getStoreByStoreId(Auth.getStoreId(), () => {});
  //       }
  //     })
  //     .catch(error => {
  //       console.log(
  //         'Error occure while getting customer orders',
  //         error.message
  //       );
  //       callBack({ status: 'error' });
  //     });
  // };

  getAllOrdersByStoreId = (
    storeId,
    pageNumber,
    deleted,
    orderFromEstore,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/storeOrder/orders/${storeId}?deleted=${deleted}&orderFromEstore=${orderFromEstore}&startingIndex=${pageNumber}&maximumLoadingSize=${20}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });

          Store.getStoreByStoreId(Auth.getStoreId(), () => {});
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customer orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  saveStoreOrder = (order, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/storeOrder',
      data: order,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while saving order', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  updateStoreOrder = (order, id, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/storeOrder/update/${id}`,
      data: order,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating store order', error.message);
        callBack({ status: 'error' });
      });
  };

  getStoreOrdersByCustomerIds = (customerIds, deleted, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/storeOrder/customer?deleted=${deleted}`,
      data: customerIds,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating store order', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteStoreOrder = (storeOrderId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/storeOrder/delete/' + storeOrderId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting order', error.message);
        callBack({ status: 'error' });
      });
  };
  //vendor purchases
  getTotalPurchaseByStoreId = (
    storeId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/dashBoard/${storeId}/total-store-purchases/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else if (response.data === '') {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting vendor purchases',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  //vendor orders
  getTotalStoreVendorOrdersByStoreId = (
    storeId,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/purchaseBill/count/${storeId}/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log('Error occure while getting vendor orders', error.message);
        callBack({ status: 'error' });
      });
  };
  getTotalStorePriceByStoreId = (
    storeId,
    startDate,
    newEndDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      // url:
      //   '/storeOrder/' +
      //   storeId +
      //   '/total-store-sales/' +
      //   startDate +
      //   '/' +
      //   newEndDate +
      //   '/' +
      //   timeZone,
      url: `/storeOrder/${storeId}/total-store-sales/${startDate}/${newEndDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customer orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getDetailsByOrderId = (orderId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/orderDetails/get-details/${orderId}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log('Error occure while getting order details ', error.message);
        callBack({ status: 'error' });
      });
  };
  getTotalStoreOrdersByStoreId = (
    storeId,
    startDate,
    newEndDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      // url:
      //   '/storeOrder/' +
      //   storeId +
      //   '/total-store-orders/' +
      //   startDate +
      //   '/' +
      //   newEndDate +
      //   '/' +
      //   timeZone,
      url: `/storeOrder/${storeId}/total-store-orders/${startDate}/${newEndDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: '' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting customer orders',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  deleteOrderDetail = (orderDetailsId, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/orderDetails/delete/' + orderDetailsId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting order details', error.message);
        callBack({ status: 'error' });
      });
  };

  //get weekly,daily and monthly sales
  getTotalSalesChartData = (
    type,
    storeId,
    startDate,
    newEndDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      // url:
      //   '/dashBoard/getSalesChartData/' +
      //   storeId +
      //   '/' +
      //   type +
      //   '/' +
      //   startDate +
      //   '/' +
      //   newEndDate +
      //   '/' +
      //   timeZone,
      url: `/dashBoard/getSalesChartData/${storeId}/${type}/${startDate}/${newEndDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting sales', error.message);
        callBack({ status: 'error' });
      });
  };
  //get weekly,daily and monthly orders
  getTotalOrdersChartData = (
    event,
    storeId,
    startDate,
    newEndDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      // url:
      //   '/dashBoard/getOrdersChartData/' +
      //   storeId +
      //   '/' +
      //   event +
      //   '/' +
      //   startDate +
      //   '/' +
      //   newEndDate +
      //   '/' +
      //   timeZone,
      url: `/dashBoard/getOrdersChartData/${storeId}/${event}/${startDate}/${newEndDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting orders', error.message);
        callBack({ status: 'error' });
      });
  };
  //total sales chart data for Vendor

  getVendorTotalSalesChartData = (
    storeId,
    type,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      url: `/dashBoard/get-sales-chart-data-vendor/${storeId}/${type}/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting sales', error.message);
        callBack({ status: 'error' });
      });
  };

  //total payments chart data vendor
  getTotalVendorPaymentByStoreId = (
    storeId,
    type,
    startDate,
    endDate,

    callBack
  ) => {
    UpstoreApi({
      method: 'GET',

      url: `/dashBoard/get-payments-chart-data-vendor/${storeId}/${type}/${startDate}/${endDate}`,
    })
      .then(response => {
        if (Validation.isBlankObjectNot(response.data)) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: null });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while getting vendor payments ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  //get total orders vendor
  getTotalVendorOrdersByStoreId = (
    storeId,
    type,
    startDate,
    endDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',

      url: `/dashBoard/get-no-orders-chart-data-vendor/${storeId}/${type}/${startDate}/${endDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (Validation.isBlankObjectNot(response.data)) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: null });
        }
      })
      .catch(error => {
        console.log('Error occure while getting vendor orders ', error.message);
        callBack({ status: 'error' });
      });
  };

  getTotalPaymentsChartData = (
    event,
    storeId,
    startDate,
    newEndDate,
    timeZone,
    callBack
  ) => {
    UpstoreApi({
      method: 'GET',
      // url:
      //   '/dashBoard/getPaymentsChartData/' +
      //   storeId +
      //   '/' +
      //   event +
      //   '/' +
      //   startDate +
      //   '/' +
      //   newEndDate +
      //   '/' +
      //   timeZone,
      url: `/dashBoard/getPaymentsChartData/${storeId}/${event}/${startDate}/${newEndDate}?timeZone=${encodeURIComponent(
        timeZone
      )}`,
    })
      .then(response => {
        if (Validation.isBlankObjectNot(response.data)) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'success', data: null });
        }
      })
      .catch(error => {
        console.log('Error occure while getting payments', error.message);
        callBack({ status: 'error' });
      });
  };

  getDiscount = (discount = 0, amount) => {
    if (discount === false || discount === null || discount === '') {
      return 0;
    }
    if (discount?.toString().endsWith('%')) {
      discount =
        (parseFloat(discount.substring(0, discount.length - 1)) * amount) / 100;
    } else {
      discount = parseFloat(discount);
    }
    return discount;
  };
  getCommission = (commission, totaDiscount, amount) => {
    if (commission === undefined || commission === 0) {
      return 0;
    } else {
      commission = ((amount - totaDiscount) * commission) / 100;
    }
    return commission;
  };
  calculateTotalValues = productList => {
    let totalItems = 0;
    let totalDiscount = 0;
    let totalBasePriceDiscount = 0;
    let totalAmount = 0;
    let totalMrp = 0;
    let totalStorePrice = 0;
    let totalTaxValue = 0;
    let totalCommission = 0;
    let totalBasePrice = 0;
    productList
      ?.filter(i => !i.deleted)
      .forEach(item => {
        item.creationDate = null;
        item.noOfItems = parseFloat(item.noOfItems);

        totalItems += parseFloat(item.noOfItems || 0);

        totalDiscount += parseFloat(
          this.getDiscount(item?.discount, item?.storePrice * item?.noOfItems)
        );
        totalCommission += parseFloat(
          this.getCommission(
            item.commission,
            this.getDiscount(
              item?.discount,
              item?.storePrice * item?.noOfItems
            ),
            item.storePrice * item?.noOfItems
          )
        );
        totalBasePriceDiscount += parseFloat(
          this.getDiscount(
            item.basePriceDiscount,
            item.basePrice * item.noOfItems
          )
        );
        totalTaxValue += parseFloat(item?.taxAmount || 0);
        totalMrp += parseFloat(item?.mrp || 0);
        totalStorePrice += parseFloat(item?.storePrice || 0);

        totalAmount += parseFloat(item?.amount || 0);
        totalBasePrice += parseFloat(item?.basePrice || 0);
      });

    return {
      totalItems: totalItems,
      totalAmount: totalAmount,
      totalDiscount: totalDiscount,
      totalMrp: totalMrp,
      totalStorePrice: totalStorePrice,
      totalTaxValue: totalTaxValue,
      orderDetails: productList,
      totalCommission: totalCommission,
      totalBasePriceDiscount: totalBasePriceDiscount,
      totalBasePrice: totalBasePrice,
    };
  };
  getCalculatedAmount = (
    discount = 0,
    amount,
    taxValue = 0,
    commission = 0,
    cess = 0,
    otherCharges = 0
  ) => {
    let taxAmount = null;
    let taxableAmt = null;
    let cessAmt = null;
    // debugger;
    if (discount?.toString()?.endsWith('%')) {
      taxableAmt =
        amount -
        (parseFloat(discount?.substring(0, discount?.length - 1)) * amount) /
          100;
      amount =
        amount -
        (parseFloat(discount?.substring(0, discount?.length - 1)) * amount) /
          100;

      let trimmed = discount?.slice(0, -1);
      if (+trimmed > 100) {
        this.props.snackbar('% must be less or equal to 100', 'error');
      }
      if (taxValue) {
        taxAmount = parseFloat(amount * (taxValue / 100));
        let comm = parseFloat(amount * (commission / 100));

        amount = amount + taxAmount + comm;
      } else {
        let comm = parseFloat(amount * (commission / 100));

        amount = amount + comm;
      }
    } else if (Store?.companyDetails?.businessType === 'MANDI') {
      let comm = parseFloat(amount - parseInt(discount)) * (commission / 100);
      taxableAmt = amount - Number(discount);
      amount = amount - parseFloat(discount) + comm;
    } else if (commission == 0 || commission == null) {
      taxableAmt = amount - Number(discount);
      amount = amount - Number(discount);

      if (taxValue) {
        taxAmount = parseFloat(amount * (taxValue / 100));
        amount = amount + parseFloat(amount * (taxValue / 100));
      }
    }
    if (cess?.toString()?.endsWith('%')) {
      cessAmt =
        (parseFloat(cess?.substring(0, cess?.length - 1)) * taxableAmt) / 100;
    } else if (cess !== 0 || cess !== '') {
      cessAmt = Number(cess);
    }

    let data = {
      amount: amount + Number(otherCharges) + cessAmt,
      taxAmount: taxAmount,
      taxableAmt: taxableAmt,
    };
    return data;
  };
}

export default new Order();
