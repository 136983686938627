import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Agent from '../../modules/Agent';

const DeliveryAddress = props => {
  const [addressObj, setAddressObj] = useState(props.order);
  const [deliveringTo, isDeliveringTo] = useState(false);
  const [pickupAddress, isPickupAddress] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className='delivery-details'>
      {/* <b>{deliveringTo ? <u>Delivery Address:</u> : <u>Pickup Address:</u>}</b> */}
      <Box variant='body1'>
        Customer Name: &nbsp;&nbsp;
        {props?.order?.name}
      </Box>
      <Box variant='body1'>
        Phone:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {props?.order?.contact}
      </Box>
      <Box variant='body1'>
        Address:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {props?.order?.address}
      </Box>
      <Box variant='body1'>
        Landmark:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {props?.order?.landmark}
      </Box>
      <Box variant='body1'>
        Pincode:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>{props?.order?.pincode}</i>
      </Box>
    </div>
  );
};

export default DeliveryAddress;
