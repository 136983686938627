import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import UserSubscription from '../../modules/UserSubscription';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DoneIcon from '@mui/icons-material/Done';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CardHeader from '@mui/material/CardHeader';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import WarningDialog from '../warningDialog/WarningDialog';
import './styles.css';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';
import DeleteAccountDialog from '../deleteAccountDialog/DeleteAccountDialog';
import moment from 'moment';
import PropTypes from 'prop-types';

const SubscribedPlan = props => {
  const [subscriptionPlan, setSubscriptionPlan] = React.useState(null);
  const [SubscriptionPlanDetail, setsubscriptionPlanDetail] = React.useState(
    []
  );
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [openDailog, setOpenDailog] = React.useState(false);
  const [userId, setUserId] = React.useState('');
  const [lockAccDialog, setLockAccDialog] = React.useState(false);
  const [buttonClick, setButtonClick] = React.useState('');
  const [deleteAccDialog, setDeleteAccDialog] = React.useState(false);
  const [deletButtonClick, setDeleteButtonClick] = React.useState('');
  const [disableClickedButton, setDisableClickedButton] = React.useState(false);
  const [plandata, setPlanData] = React.useState([]);

  React.useEffect(() => {
    getUserSubscription();
  }, []);

  const getUserSubscription = () => {
    UserSubscription.getUserSubscriptionByStoreId(props.storeId, response => {
      if (response.status === 'success') {
        setSubscriptionPlan(response.data);
      } else {
        setSubscriptionPlan(null);
      }
    });
  };
  const disableButtonClick = () => {
    setDisableClickedButton(true);
    setTimeout(() => {
      setDisableClickedButton(false);
    }, 9000);
  };
  const getSubscriptionDetails = () => {
    UserSubscription.getAllSubscriptionPlans(response => {
      if (response.status === 'success') {
        setsubscriptionPlanDetail(response.data);
        setPlanData(response.data.filter(item => item.tagName === 'desktop'));
      } else {
        setsubscriptionPlanDetail([]);
      }
    });
  };
  const adminId = () => {
    UserSubscription.getAdminId(props?.storeId, response => {
      if (response.status === 'success') {
        setUserId(response.data.id);
      } else {
        setUserId('');
      }
    });
  };

  const addSubscription = id => {
    UserSubscription.addSubscription(userId, id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Subscription added Sucessfully', 'success');
        getUserSubscription();
      } else {
        openUpstoreSnackBar('Subscription  Not added ', 'error');
      }
    });
  };
  const lockUserAcc = () => {
    UserSubscription.lockUser(props?.storeId, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Store Locked Sucessfully', 'success');
      } else {
        openUpstoreSnackBar('Store Not Locked ', 'error');
      }
    });
  };

  const unLockUserAcc = () => {
    UserSubscription.unlockUser(props?.storeId, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Store Unlocked Sucessfully', 'success');
      } else {
        openUpstoreSnackBar('Store Not UnLocked ', 'error');
      }
    });
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleLockDialog = () => {
    setLockAccDialog(true);
    setButtonClick('lock');
  };
  const handleUnlockLockDialog = () => {
    setLockAccDialog(true);
    setButtonClick('unlock');
  };
  const handleClickOpen = () => {
    setOpenDailog(true);
    getSubscriptionDetails();
    adminId();
  };

  const handleLockAccClick = usersChoice => {
    if (buttonClick === 'lock') {
      if (usersChoice === 'yes') {
        setLockAccDialog(false);
        lockUserAcc(props.storeId);
      } else {
        setLockAccDialog(false);
      }
    } else {
      if (usersChoice === 'yes') {
        setLockAccDialog(false);
        unLockUserAcc(props.storeId);
      } else {
        setLockAccDialog(false);
      }
    }
  };

  const softDeleteAccOtp = () => {
    disableButtonClick();
    UserSubscription.getSoftDeleteOtp(props?.storeId, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar(
          'Otp has been Sent to The Registered Email ',
          'success'
        );

        setDeleteAccDialog(true);
        setButtonClick('softDelete');
      } else {
        openUpstoreSnackBar('Unable To send Otp  ', 'error');
      }
    });
  };
  const hardDeleteAccOtp = () => {
    disableButtonClick();
    UserSubscription.getHardDeleteOtp(props?.storeId, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar(
          'Otp has been Sent to The Registered Email ',
          'success'
        );
        setButtonClick('hardDelete');
        setDeleteAccDialog(true);
      } else {
        openUpstoreSnackBar('Unable To send Otp  ', 'error');
      }
    });
  };
  const handleFilterData = newValue => {
    if (newValue === 0) {
      setPlanData(
        SubscriptionPlanDetail.filter(item => item.tagName === 'desktop')
      );
    }
    if (newValue === 1) {
      setPlanData(
        SubscriptionPlanDetail.filter(item => item.tagName === 'upstore')
      );
    }
    if (newValue === 2) {
      setPlanData(
        SubscriptionPlanDetail.filter(item => item.tagName === 'enterprise')
      );
    }
  };

  return (
    <Box sx={{ minWidth: 500, ml: 1 }}>
      <Box container sx={{ display: 'flex' }}>
        <Box item md={6} sx={{ display: 'flex', m: 1 }}>
          <Button
            variant='contained'
            sx={{
              p: 0,
              mr: 1,
              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
            }}
            onClick={handleLockDialog}
          >
            Lock{' '}
          </Button>
          <Button
            variant='contained'
            sx={{
              p: 0,

              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
            }}
            onClick={handleUnlockLockDialog}
          >
            Un-Lock
          </Button>
        </Box>
        <Box item md={6} sx={{ display: 'flex', m: 1 }}>
          <Button
            variant='contained'
            disabled={disableClickedButton}
            sx={{
              p: 0,

              mr: 1,
              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
            }}
            onClick={() => {
              softDeleteAccOtp();
            }}
          >
            Soft Delete
          </Button>

          <Button
            variant='contained'
            disabled={disableClickedButton}
            sx={{
              p: 0,
              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
            }}
            onClick={() => {
              hardDeleteAccOtp();
            }}
          >
            Hard Delete
          </Button>
        </Box>
      </Box>
      <Box>
        <Button
          variant='contained'
          sx={{
            p: 0,
            m: 1,

            fontSize: '13px',
            textTransform: 'capitalize',
            width: '126px',
            height: '27px',
            color: '#ffff',
            backgroundColor: '#3B78C9',
          }}
          onClick={handleClickOpen}
        >
          Subscription Plans
        </Button>
      </Box>

      <Grid
        container
        spacing={2}
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        {subscriptionPlan && (
          <Grid item md={12}>
            <Card sx={{ backgroundColor: '#3B78C9' }}>
              <CardHeader
                sx={{ textAlign: 'center', color: '#fff' }}
                title={`Subscribed Plan ${subscriptionPlan?.serviceName} `}
              />
              <CardContent
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography
                    // variant='h6'
                    sx={{
                      width: '200px',
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    subscription Date :
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    expiry Date :
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    Suspension Date :
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    Active :
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    Service Name :
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    {moment
                      .utc(subscriptionPlan?.subscriptionDate)
                      .local()
                      .format('llll')}
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    {moment
                      .utc(subscriptionPlan?.expiryDate)
                      .local()
                      .format('llll')}
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    {moment
                      .utc(subscriptionPlan?.suspensionDate)
                      .local()
                      .format('llll')}
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    {subscriptionPlan?.active === true ? 'true' : ''}
                  </Typography>
                  <Typography
                    // variant='h6'
                    sx={{
                      color: '#fff',
                      fontSize: '15px',
                      textAlign: 'left',
                      pb: 1,
                    }}
                  >
                    {subscriptionPlan?.userSubscribeServiceModelList?.map(
                      (item, index) => {
                        let abc = `${item.serviceName}  , `;
                        if (
                          subscriptionPlan.userSubscribeServiceModelList
                            .length -
                            1 ==
                          index
                        ) {
                          abc = abc.substring(abc.indexOf(','), -1);
                        }
                        return abc;
                      }
                    )}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {upstoreSnackBar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={upstoreSnackBar}
            onSnackbarClose={onSnackbarClose}
          />
        ) : null}
        {openDailog ? (
          <AlertDialog
            open={openDailog}
            Close={() => {
              setOpenDailog(false);
            }}
            planDetail={SubscriptionPlanDetail && SubscriptionPlanDetail}
            // intialPlan={intialPlan}
            plandata={plandata}
            handleFilterData={handleFilterData}
            addSubscriptionCallback={addSubscription}
          />
        ) : null}
      </Grid>
      <DeleteAccountDialog
        openDailog={deleteAccDialog}
        close={() => {
          setDeleteAccDialog(false);
          setDeleteButtonClick('');
        }}
        storeId={props.storeId}
        snackbar={openUpstoreSnackBar}
        ButtonClicked={buttonClick}
      />
      {lockAccDialog ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={
            buttonClick === 'lock' ? handleLockAccClick : handleLockAccClick
          }
          openDialog={lockAccDialog}
          content={
            buttonClick === 'lock'
              ? 'Do You Want To Lock User'
              : 'Do You Want To Unlock User'
          }
          HeaderText='Warning! '
        />
      ) : null}
    </Box>
  );
};

function AlertDialog({
  open,
  Close,
  plandata,
  addSubscriptionCallback,
  handleFilterData,
}) {
  const [confirmSubscriptionDialog, setConfirmSubscriptionDialog] =
    React.useState({ openalert: false, id: '' });
  const [value, setValue] = React.useState(0);

  const [cardDetail, setCardDetail] = React.useState({});
  // console.log(intialPlan);

  const handleChange = (event, newValue) => {
    handleFilterData(newValue);

    setValue(newValue);
  };
  const handleClickDialog = id => {
    let subscribeObj = {
      openalert: true,
      id: id,
    };

    setConfirmSubscriptionDialog(subscribeObj);
  };

  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      setConfirmSubscriptionDialog({ open: false });

      addSubscriptionCallback(confirmSubscriptionDialog.id);
      Close();
    } else {
      setConfirmSubscriptionDialog(false);
    }
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Dialog open={open} onClose={Close} maxWidth>
        <DialogContent className='dialog'>
          <DialogContentText className='Dialog_content'>
            <Grid container>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} centered>
                    <Tab label='Desktop' {...a11yProps(0)} />
                    <Tab label='Upstore' {...a11yProps(1)} />
                    <Tab label='Upstore Enterprise' {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={value}>
                  <Grid item md={3} className='card_grid'>
                    {plandata?.map(item => {
                      return (
                        <Box>
                          <Card className='contain-box'>
                            <Tooltip
                              title='Double Click Activate Plan'
                              placment='right'
                            >
                              <CardContent
                                onDoubleClick={() => {
                                  handleClickDialog(item.id);
                                  setCardDetail(item);
                                }}
                              >
                                <Typography className='text' variant='h5'>
                                  {item.name}
                                </Typography>

                                <Typography
                                  color='#3b78c9'
                                  variant='overline'
                                  textAlign={'center'}
                                >
                                  <p>
                                    {item.name.toLowerCase() !== 'trial' ? (
                                      <>
                                        <strong style={{ fontSize: '30px' }}>
                                          <AttachMoneyIcon /> {item.price}
                                        </strong>
                                        <p style={{ marginTop: '-30px' }}>
                                          {`${item.validity} Days`}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <strong style={{ fontSize: '30px' }}>
                                          Free
                                        </strong>
                                        <i
                                          style={{
                                            fontSize: '12px',
                                            color: '#000',
                                          }}
                                        ></i>
                                        <p style={{ marginTop: '-30px' }}>
                                          for month
                                        </p>
                                      </>
                                    )}
                                  </p>
                                </Typography>
                                <hr />

                                <Typography
                                  fontSize={'16px'}
                                  color='text.secondary'
                                  textAlign={'center'}
                                >
                                  <strong
                                    style={{
                                      fontWeight: '600',
                                    }}
                                    className='text_color'
                                  >
                                    Serivces Available
                                  </strong>
                                  <br />
                                </Typography>
                                {item.subscriptionPlanServices?.map(service => {
                                  return (
                                    <Typography
                                      color='text.secondary'
                                      variant='overline'
                                      fontSize={'11px'}
                                      className='text_color'
                                    >
                                      <DoneIcon
                                        fontSize='small'
                                        sx={{ color: '#3b78c9' }}
                                        shapeRendering='crispEdges'
                                      />
                                      {service?.value != 'true' ? (
                                        <strong
                                          style={{
                                            color: '#3b78c9',
                                          }}
                                        >
                                          {service?.value}
                                        </strong>
                                      ) : null}{' '}
                                      <span
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {service?.upstoreServices?.serviceName}
                                      </span>
                                      <br />
                                    </Typography>
                                  );
                                })}
                              </CardContent>
                            </Tooltip>
                            <CardActions></CardActions>
                          </Card>
                        </Box>
                      );
                    })}
                  </Grid>
                </TabPanel>
              </Box>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            sx={{
              p: 0,
              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',

              float: 'right',
            }}
            onClick={Close}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
        {confirmSubscriptionDialog ? (
          <WarningDialog
            handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
            openDialog={confirmSubscriptionDialog.openalert}
            content={`if you subscribe this Plan ${cardDetail.name}  For ${cardDetail.price} you will get access to prime features And the plan validity is ${cardDetail.validity} days `}
            HeaderText='Subscribe '
          />
        ) : null}
      </Dialog>
    </>
  );
}

export default SubscribedPlan;
