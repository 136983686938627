import React, { Component } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import { Loading } from '../../component/loading/Loading';
import User from '../../modules/User';
import Auth from '../../modules/Auth';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import UserRoles from '../../modules/UserRoles';
import AddEditUser from '../../component/addEditUser/AddEditUser';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';
import { serverConfig } from '../../config/server-config';
import UserRoleDialog from '../../component/userRoleDialog/UserRoleDialog';
import LockResetIcon from '@mui/icons-material/LockReset';
import ChangeUserPassword from '../../component/changeUserPassword/ChangeUserPassword';
let allUsersData = [];
export default class UserSetUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loader: true,
      roles: [],
      deleteProductDialog: { open: false, idOfDeletableProduct: null },
      upstoreSnackBar: { open: false },
      OpenUserDialog: false,
      buttonClicked: '',
      selectedUserData: '',
      open: false,
      openPasswordDialog: {
        open: false,
        userData: {},
      },
      //changeMassage: true,
    };
  }
  componentDidMount() {
    this.getAllUsers();
    this.getAllRoles();
  }
  getAllRoles = () => {
    UserRoles.getAllUserRoles(response => {
      if (response.status === 'success') {
        this.setState({ roles: response.data });
      }
    });
  };
  getAllUsers = () => {
    User.getAllUsers(Auth.getStoreId(), response => {
      this.setState({ loader: true });
      if (response.status === 'success') {
        allUsersData = response.data;
        this.setState({ users: [...response.data], loader: false });
      } else {
        this.openUpstoreSnackBar('users not fetched successfully', 'error');
        this.setState({ users: [], loader: false });
      }
    });
  };
  closeDeletePopup = () => {
    this.setState({
      deleteProductDialog: { open: false, idOfDeletableProduct: null },
    });
  };

  // for snackbar
  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };

  openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    this.setState({
      upstoreSnackBar: upstoreSnackbarObject,
    });
  };
  handleClosePassword = () => {
    this.setState({ openPasswordDialog: { open: false } });
  };
  closeUserDialog = () => {
    this.setState({
      OpenUserDialog: false,
      buttonClicked: '',
      selectedUserData: '',
    });
  };
  handleActive = (e, id, userRoleId) => {
    let data = {
      active: e.target.value,
    };
    if (userRoleId !== '1') {
      User.updateUserDetails(id, data, response => {
        if (response.status === 'success') {
          this.openUpstoreSnackBar('user Status chnged', 'success');
          this.getAllUsers();
        } else {
          console.log('err');
        }
      });
    } else {
      this.openUpstoreSnackBar("Admin can't be Deactivated ", 'error');
    }
  };

  handleSearchChange = value => {
    if (value !== '') {
      let filteredResults = this.state?.users?.filter(user => {
        return (
          user.firstName?.toUpperCase().includes(value.toUpperCase()) ||
          user.lastName?.toUpperCase().includes(value.toUpperCase()) ||
          user.email?.toUpperCase().includes(value.toUpperCase()) ||
          user.phone?.toUpperCase().includes(value.toUpperCase())
        );
      });
      if (filteredResults.length <= 0) {
        this.setState({ users: allUsersData });
      } else {
        this.setState({ users: filteredResults });
      }
    } else {
      this.setState({ users: allUsersData });
    }
  };

  resetPassword = (password, data) => {
    let newData = {
      newPassword: password,
    };
    if (password === '') {
      this.openUpstoreSnackBar('password is mandatory to fill', 'error');
    } else {
      User.addChangePassword(data.id, newData, response => {
        if (response.status === 'success') {
          this.openUpstoreSnackBar(
            'Password is Changed for user Successfully',
            'success'
          );
          this.handleClosePassword();
        }
      });
    }
  };

  columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <Typography variant='subtitle1'>{`${params.row?.firstName} ${params.row?.lastName}`}</Typography>
        );
      },
    },

    // {
    //   field: 'address',
    //   headerName: 'Address',
    //   width: 200,
    //   headerClassName: 'super-app-theme--header',
    //   headerAlign: 'center',
    //   align: 'center',
    // },
    {
      field: 'phone',
      headerName: 'Contact No',
      type: 'number',
      width: 213,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 320,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'left',
    },

    {
      field: 'userRoleId',
      headerName: 'User Role Id',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'active',
      headerName: 'Active',
      type: 'number',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <FormControl fullWidth>
            <Select
              id='demo-simple-select'
              value={
                params.row.active === null || !params.row.active ? false : true
              }
              disabled={
                AllPermissions.getUserAccessiblePermissions('UserSetup') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('UserSetup')
                  .write === false
                  ? true
                  : false
              }
              sx={{
                color: '#fff',
                backgroundColor: params.row.active ? '#20CCA1' : '#F85D59',
              }}
              onChange={event => {
                this.handleActive(event, params.row.id, params.row.userRoleId);
                // this.setState({ changeMassage: event.target.value });
              }}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In-Active</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              <Tooltip title=' Change Password' placement='bottom'>
                <LockResetIcon
                  onClick={() => {
                    this.setState({
                      openPasswordDialog: { open: true, userData: params.row },
                    });
                  }}
                />
              </Tooltip>
              {AllPermissions.getUserAccessiblePermissions('UserSetup') !==
                undefined &&
              AllPermissions.getUserAccessiblePermissions('UserSetup').write ===
                false ? (
                <EditIcon />
              ) : (
                <Tooltip title=' Edit User' placement='bottom'>
                  <EditIcon
                    onClick={() =>
                      this.setState({
                        OpenUserDialog: true,
                        selectedUserData: params.row,
                        buttonClicked: 'Edit',
                      })
                    }
                    sx={{ color: '#3B78C9' }}
                  />
                </Tooltip>
              )}
            </>
          </Box>
        </>
      ),
    },
  ];

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <>
        <Header pageName='users' />
        <Box
          sx={{
            width: '95%',
            height: 600,
            mt: 9,
            ml: 8,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {this.state.loader ? (
            <Loading />
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <BreadCrumb pageName='Users' />
                </Grid>
                <Grid item xs={8} md={5} mb={1}>
                  <TextField
                    variant='outlined'
                    name='search'
                    fullWidth
                    placeholder='Search users'
                    size='small'
                    onChange={event =>
                      this.handleSearchChange(event.target.value)
                    }
                  />
                </Grid>
                {(AllPermissions.getUserAccessiblePermissions('UserSetup') !==
                  undefined &&
                  AllPermissions.getUserAccessiblePermissions('UserSetup')
                    .write === false) ||
                !serverConfig.isUpstoreCloud ? null : (
                  <>
                    <Grid item md={2} xs={4} textAlign='right'>
                      <Button
                        variant='contained'
                        sx={{
                          textTransform: 'capitalize',
                          width: '126px',
                          height: '27px',
                          backgroundColor: '#3B78C9',
                          mb: 1,
                        }}
                        onClick={() => {
                          this.setState({
                            OpenUserDialog: true,
                            buttonClicked: 'Add',
                          });
                        }}
                      >
                        <Typography variant='h5' sx={{ fontSize: '14px' }}>
                          Add User
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        variant='contained'
                        sx={{
                          textTransform: 'capitalize',
                          width: '126px',
                          height: '27px',
                          backgroundColor: '#3B78C9',
                          mb: 1,
                        }}
                        onClick={this.handleClickOpen}
                      >
                        <Typography variant='h5' sx={{ fontSize: '14px' }}>
                          User Roles
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>

              {this.state.users.length > 0 ? (
                <DataGrid
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  sx={{
                    boxShadow: 2,
                    border: 6,
                    backgroundColor: '#fff',
                    borderColor: '#eee',
                    color: 'black',
                    '& .MuiDataGrid-cell': {
                      outline: 'none',
                      fontSize: '16px',
                      fontWeight: '25px',
                    },
                    '& .MuiDataGrid-cell:hover': {
                      outline: 'none',
                      color: '#21211f',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                    '& svg': {
                      color: '#3b78c9',
                    },
                    '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                      color: 'black',
                    },
                  }}
                  density='compact'
                  rows={this.state.users}
                  columns={this.columns}
                  pageSize={20}
                  aria-label='Users'
                />
              ) : (
                <Typography>No User Found</Typography>
              )}
            </>
          )}
        </Box>
        {this.state.OpenUserDialog && this.state.roles ? (
          <AddEditUser
            openDialog={this.state.OpenUserDialog}
            closeDialog={this.closeUserDialog}
            buttonClicked={this.state.buttonClicked}
            editUserData={
              this.state.selectedUserData && this.state.selectedUserData
            }
            rolesData={this.state.roles.filter(role => role.id !== '1')}
            getAllUsers={this.getAllUsers}
            snackbar={this.openUpstoreSnackBar}
            role
          />
        ) : null}

        {this.state.upstoreSnackBar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={this.state.upstoreSnackBar}
            onSnackbarClose={this.onSnackbarClose}
          />
        ) : null}
        {AllPermissions.getUserAccessiblePermissions('UserSetup') !==
          undefined &&
        AllPermissions.getUserAccessiblePermissions('UserSetup').read ===
          false ? (
          <ErrorDialog></ErrorDialog>
        ) : null}
        {this.state.open && (
          <UserRoleDialog
            open={this.state.open}
            handleClose={this.handleClose}
          />
        )}

        {this.state.openPasswordDialog?.open && (
          <ChangeUserPassword
            handleClosePassword={this.handleClosePassword}
            openPasswordDialog={this.state.openPasswordDialog}
            resetPassword={this.resetPassword}
          />
        )}
      </>
    );
  }
}
