import React, { useEffect, useState } from 'react';

import { Typography, Link } from '@mui/material';
import Popover from '@mui/material/Popover';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Notifications from '../../modules/Notification';
import Badge from '@mui/material/Badge';
import './style.css';
import Auth from '../../modules/Auth';
import { Loading } from '../../component/loading/Loading';
export default function AgentNotification() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0); // integer state
  const [loader, setLoader] = useState(true);
  const [fas, setFas] = useState([]);
  let newMessages = 0;

  const getNotifications = () => {
    setLoader(true);
    Notifications.pullNotifications(Auth.getStoreId(), 0, response => {
      //   let sortedNoti = response.data.sort(function (a, b) {
      //     return b.id - a.id;
      //   });
      setData(response.data);
      setLoader(false);
    });
  };

  //   function launchLink(link) {
  //     if (link != undefined && link != null && link.trim() != '') {
  //       window.open(link, '_self');
  //     }
  //   }

  // Get Notifications After Every Minute
  useEffect(() => {
    setInterval(() => {
      getNotifications();
    }, 60000);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getNotifications();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value]);

  const changeNotificationStatus = item => {
    item.status = 'READ';

    Notifications.changeNotificationStatus(item);
  };

  const renderItems = data.map((item, key) => {
    const obj = item.message;
    // const { subject, message, link } = obj;
    if (item.status === 'NEW') {
      ++newMessages;
    }
    return (
      <div
        className='notification__item'
        key={key}
        onClick={() => {
          item.status = 'READ';
          /* setValue is just used to force Pull Notification
           Component to update to decrement badge Number */
          setValue(value + 1);
          // Here We Need to Send the Status to backend also
          changeNotificationStatus(item);
        }}
      >
        {/* show badge if unread */}
        {item.status === 'NEW' ? (
          <Badge
            style={{
              position: 'absolute',
              right: '3%',
              top: '18%',
              transform: 'scale(1.5)',
            }}
            variant='dot'
            color='primary'
          ></Badge>
        ) : (
          ''
        )}

        <>
          <Typography
            className='notification__heading'
            variant='subtitle2'
            gutterBottom
            color={item.status === 'NEW' ? 'primary' : ''}
          >
            {item.message}
          </Typography>
          <Link
            className='notification__link'
            component='button'
            variant='body2'
            color='secondary'
          >
            Know More ...
          </Link>
        </>
      </div>
    );
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Badge badgeContent={newMessages} color='primary'>
      <NotificationsIcon
        aria-describedby={id}
        variant='contained'
        color='primary'
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='notification__container'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography
          variant='h6'
          style={{
            color: 'blue',
            borderBottom: '1px #CCCCCC solid',
            margin: '5px',
          }}
        >
          Notifications
        </Typography>
        <div
          component='main'
          maxWidth='md'
          //   className={(classes.paper, 'notification')}
        >
          {loader ? (
            <Loading />
          ) : data.length > 0 ? (
            renderItems
          ) : (
            <Typography variant='h5' style={{ margin: '5px' }}>
              No New Notifications
            </Typography>
          )}
        </div>
      </Popover>
    </Badge>
  );
}
