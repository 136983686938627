import React, { useEffect } from 'react';
import {
  Typography,
  Link,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from '@mui/material';
import { Box, width } from '@mui/system';
import frontImage from '../../assets/images/frontImg.png';
import { useForm } from 'react-hook-form';
import './style.css';
import Auth from '../../modules/Auth';
import { useNavigate } from 'react-router-dom';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import { CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import User from '../../modules/User';

const Login = () => {
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [loader, setLoader] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [invalidUsername, setInvalidUsername] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [InvalidPhone, setInvalidPhone] = React.useState(false);
  const [businessNames, setBusinessNames] = React.useState([]);
  const [businessType, setBusinessType] = React.useState([]);
  const [nullEmail, setNullEmail] = React.useState(false);
  const [nullPhone, setNullPhone] = React.useState(false);
  const [nullUserName, setNullUserName] = React.useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      navigate('/categories');
    }
    getBusinessType();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  //all business Type
  const getBusinessType = () => {
    User.AllBusinessType(response => {
      if (response.status === 'success') {
        setBusinessNames(response.data);
      } else {
        console.log(Error);
      }
    });
  };

  // for password toggling
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // for confirm password toggling
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = event => {
    event.preventDefault();
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  //username already exists
  const handleUsernameBlurChange = event => {
    let userName = event.target.value;
    User.isDistinctUsername(userName, response => {
      if (response.data === true) {
        setInvalidUsername(false);
        setNullUserName(false);
      } else if (event.target.value === '') {
        setNullUserName(true);
      } else {
        setInvalidUsername(true);
      }
    });
  };
  //email already exists
  const handleEmailBlurChange = event => {
    let email = event.target.value;

    User.isDistinctEmail(email, response => {
      if (response.data === true) {
        setInvalidEmail(false);
        setNullEmail(false);
      } else if (event.target.value === '') {
        setNullEmail(true);
      } else {
        setInvalidEmail(true);
        setNullEmail(false);
      }
    });
  };
  const handlePhoneBlurChange = event => {
    let phone = event.target.value;
    User.isDistinctPhone(phone, response => {
      if (response.data === true) {
        setInvalidPhone(false);
        setNullPhone(false);
      } else if (event.target.value === '') {
        setNullPhone(true);
      } else {
        setInvalidPhone(true);
      }
    });
  };

  // function for registering user
  const onSubmit = data => {
    if (invalidUsername) {
      openUpstoreSnackBar('userName  is mandatory to fill', 'error');
      return null;
    }
    if (InvalidPhone) {
      openUpstoreSnackBar('Phone is mandatory to fill ', 'error');
      return null;
    }
    if (invalidEmail) {
      openUpstoreSnackBar('email is mandatory to fill', 'error');
      return null;
    }

    setLoader(true);

    const formData = {
      ...data,
      userRoleId: 1,
    };

    Auth.register(formData, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('user was created successfully', 'success');
        setLoader(false);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setLoader(false);

        openUpstoreSnackBar('user was not signed in successfully', 'error');
      }
    });
  };

  return (
    <>
      <Box
        className='main__div'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          backgroundImage:
            'linear-gradient(to left bottom, rgb(230,242,255) 0%, rgb(230,242,255) 60%, rgb(195,213,255) 100%)',
        }}
      >
        <Box
          sx={{
            width: '90%',
            borderRadius: '1.6rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            className='second_pro'
            sx={{
              bgColor: 'secondary.light',
              flexBasis: '20%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '2px solid #fff',
              borderRight: 'none',
              borderTopLeftRadius: '1.6rem',
              borderBottomLeftRadius: '1.6rem',
              padding: '1rem 1.8rem',
              boxShadow: '-3px 5px 1rem rgba(0,0,0,0.1)',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                lineHeight: '1.3',
                fontFamily: 'futura',
                margin: '1rem 0',
                fontSize: '1.4rem',
                paddingTop: '1.6rem',
                color: '#1A293F',
              }}
            >
              Manage your inventory, Sales, billing & Much more…!
            </Typography>
            <img src={frontImage} alt='img' className='SignUpImg' />

            <Typography
              style={{ padding: '1.6rem 0 1rem' }}
              variant='subtitle1'
            >
              <span sx={{ color: '#1A293F', fontWeight: '100' }}>
                Already have an account?
              </span>
              &nbsp;
              <Link
                href=''
                onClick={() => {
                  navigate('/', { replace: true });
                }}
                underline='hover'
              >
                SignIn
              </Link>
            </Typography>
          </Box>

          <Box
            className='right__form--div'
            sx={{
              backgroundColor: 'secondary.lght',
              flexBasis: '80%',
              height: '100%',
              borderRadius: '1.6rem',
            }}
            display='flex'
            ml={8}
            flexDirection='column'
          >
            <Typography
              variant='h5'
              className='main__heading'
              textAlign='start'
              sx={{ pt: 3, pl: 2 }}
            >
              Get your free Upstore account now.
            </Typography>

            <Typography
              variant='h6'
              textAlign='start'
              sx={{ pl: 2, color: 'secondary.main' }}
            >
              lets get you all setup so you can verify your personal account
              <br />
              And begin setting up your Profile
            </Typography>

            <Box Width='100%'>
              <form
                autoComplete='off'
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container sx={{ padding: '20px' }} spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('username', {
                        required: 'Username is required',
                      })}
                      sx={{ mt: '5px' }}
                      size='small'
                      placeholder='User Name*'
                      fullWidth={true}
                      name='username'
                      onBlur={handleUsernameBlurChange}
                      type='text'
                      autoComplete='off'
                      id='username'
                      error={Boolean(errors.username)}
                      helperText={errors.userName?.message}
                    />
                    {invalidUsername ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Username already exists please enter a unique username
                      </Typography>
                    ) : nullUserName ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter User-name
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  ></Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size='small'
                      placeholder='Enter First Name'
                      required
                      fullWidth={true}
                      {...register('firstName', {
                        required: 'First name is required.',
                      })}
                      name='firstName'
                      type='firstName'
                      id='firstName'
                      autoComplete='off'
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('lastName', {
                        required: 'Last name is required',
                      })}
                      size='small'
                      placeholder='Enter Last Name'
                      required
                      fullWidth={true}
                      name='lastName'
                      type='lastName'
                      id='lastName'
                      autoComplete='lastName'
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('password', {
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Should contain at least 8 characters',
                        },
                      })}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size='small'
                      placeholder='Enter Password'
                      required
                      fullWidth={true}
                      name='password'
                      id='password'
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('confirmPassword', {
                        required: 'Confirm password is required',
                        validate: value =>
                          value === watch('password') ||
                          'Shoud be same as password',
                      })}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge='end'
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size='small'
                      placeholder='Enter Password'
                      required
                      fullWidth={true}
                      name='confirmPassword'
                      id='confirmPassword'
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Please enter a valid Email address',
                        },
                      })}
                      size='small'
                      placeholder='Enter Email'
                      required
                      autoComplete='off'
                      onBlur={handleEmailBlurChange}
                      fullWidth={true}
                      name='email'
                      type='email'
                      id='email'
                      error={Boolean(errors?.email)}
                      helperText={errors.email?.message}
                    />
                    {invalidEmail ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Email already exists please enter a unique email
                      </Typography>
                    ) : nullEmail ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter Email
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('phone', {
                        required: 'Phone is required',
                      })}
                      size='small'
                      placeholder='Enter Phone No'
                      required
                      fullWidth={true}
                      name='phone'
                      type='number'
                      autoComplete='off'
                      onBlur={handlePhoneBlurChange}
                      id='phone'
                      // autoComplete="phone"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                    />
                    {InvalidPhone ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Phone number already exists
                      </Typography>
                    ) : nullPhone ? (
                      <Typography
                        variant='body2'
                        textAlign='left'
                        color='error'
                        sx={{ mt: 1 }}
                      >
                        *Enter Phone Number
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider sx={{ color: 'red' }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('storeName', {
                        required: 'Company name is required',
                      })}
                      size='small'
                      placeholder='Enter Company Name'
                      required
                      fullWidth={true}
                      name='storeName'
                      type='storeName'
                      id='storeName'
                      // autoComplete="storeName"
                      autoComplete='off'
                      error={Boolean(errors.storeName)}
                      helperText={errors.storeName?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      {...register('businessType', {
                        required: 'Business  Type is required',
                      })}
                      select
                      size='small'
                      name='businessType'
                      fullWidth={true}
                      onChange={event => {
                        setBusinessType(event.target.value);
                      }}
                      error={Boolean(errors.businessType)}
                      helperText={errors.businessType?.message}
                    >
                      {businessNames.map(item => {
                        return (
                          <MenuItem key={item.id} value={item.businessName}>
                            {item.businessName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>

                <Box display='flex' alignItems='center' sx={{ ml: 2 }}>
                  <FormGroup errors={Boolean(errors.tnc)}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='policies'
                          {...register('policies', {
                            required:
                              'Please agree our terms and conditions to continue',
                          })}
                        />
                      }
                      label={
                        <Typography variant='h2'>
                          I agree to the
                          <Link
                            sx={{ textDecoration: 'none', cursor: 'pointer' }}
                          >
                            &nbsp;Terms of Policy &nbsp;
                          </Link>
                          and &nbsp;
                          <Link
                            sx={{ textDecoration: 'none', cursor: 'pointer' }}
                          >
                            &nbsp;Privacy Policy.
                          </Link>
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
                <FormHelperText
                  sx={{ color: '#c22a1f', pl: '3rem', zIndex: '-20' }}
                >
                  {errors.policies?.message}
                </FormHelperText>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pr: 4,
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    sx={{
                      display: 'block',
                      textTransform: 'capitalize',
                      bgColor: 'primary',

                      width: '126px',
                      height: '27px',
                      p: 0,

                      ml: 2,
                      mt: 2,
                      mb: 2,
                    }}
                    type='submit'
                    value='submit'
                    variant='contained'
                  >
                    <Typography sx={{ p: 0 }} noWrap>
                      Create Account
                    </Typography>
                  </Button>
                  <Link
                    sx={{ display: { md: 'none', sm: 'none', xs: 'block' } }}
                    href=''
                    onClick={() => {
                      navigate('/', { replace: true });
                    }}
                    underline='hover'
                  >
                    SignIn
                  </Link>
                </Box>
                {loader ? <CircularProgress /> : null}
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
