import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Agent from '../../modules/Agent';

import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import ReturnAgentOrders from '../../component/agentReturnedOrders/ReturnAgentOrders';
import Auth from '../../modules/Auth';
import DeliveryAddress from '../../component/DeleveringAddress/DeliveryAddress';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import AgentHeader from '../../component/agentHeader/AgentHeader';
import Notification from '../../modules/Notification';
import { Loading } from '../../component/loading/Loading';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const onGoingOrderStatus = 'ASSIGNED,DISPATCHED,ARRIVING';
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
// --------------------------------------order Status--------------------------------
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <ShoppingCartIcon />,
    2: <DoneIcon />,
    3: <AssignmentTurnedInIcon />,
    4: <LocalShippingIcon />,
    5: <ShoppingBasketIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  'Order Placed',
  'Order Assigned',
  'Order Dispatched',
  'Order Arriving',
  'Order Delivered',
];
export default function AgentView() {
  const [expanded, setExpanded] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [confirmBox, setConfirmBox] = React.useState({ open: false });

  const [agentOrders, setAgentOrders] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [delivaryAddressData, setDelivaryAddressData] = React.useState({});

  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  React.useEffect(() => {
    getAllAgentOrders(onGoingOrderStatus, pageNo);
  }, []);

  const handleChange1 = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getAllAgentOrders = (onGoingOrderStatus, pageNo) => {
    setLoader(true);
    Agent.getAgentOrders(
      Auth.getUserLoginId(),
      onGoingOrderStatus,
      pageNo,
      response => {
        if (response.status === 'success') {
          setLoader(false);
          let sortedOrders = response.data.sort(function (a, b) {
            return b.storeOrderNumber - a.storeOrderNumber;
          });
          setAgentOrders(sortedOrders);
        }
      }
    );
  };

  //snackbar

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const handleConfirmClose = () => {
    setConfirmBox({ open: false });
  };
  const getActiveStepper = storeOrderStatus => {
    switch (storeOrderStatus) {
      case 'NEW':
        return 0;
      case 'ASSIGNED':
        return 1;
      case 'DISPATCHED':
        return 2;
      case 'ARRIVING':
        return 3;
      case 'COMPLETED':
        return 4;
    }
  };
  const getAddressOfCustomer = addressId => {
    Agent.getAddressByAddressId(addressId, response => {
      if (response.status === 'success') {
        setDelivaryAddressData(response.data);
      }
    });
  };
  const confirmOrderStatusChange = (labelCount, orderObj) => {
    if (
      (orderObj.orderStatus == 'ASSIGNED' && labelCount == 2) ||
      (orderObj.orderStatus == 'DISPATCHED' && labelCount == 3) ||
      (orderObj.orderStatus == 'ARRIVING' && labelCount == 4)
    ) {
      let confirmBoxObj = {
        open: true,
        labelCount: labelCount,

        orderObj: orderObj,
      };
      setConfirmBox({ ...confirmBoxObj });
    }
  };
  const getOrderStatusObj = (orderObj, status) => {
    let agentAssign = {
      agentId: Auth.getUserLoginId(),
      orderId: orderObj.id,
      orderStatus: status,
      orderDate: orderObj.creationDate,
      storeId: Auth.getStoreId(),
      deliveryDate: orderObj.estimatedDeliveryTime,
      customerId: orderObj.customerId,
      dispatchDate: new Date(),
    };
    return agentAssign;
  };
  const setOrderStatus = async (labelCount, orderObj, order, pageNo) => {
    if (labelCount >= 2) {
      if (orderObj.orderStatus == 'ASSIGNED' && labelCount == 2) {
        let status = `Order status has been updated to  DISPATCHED with order number ${orderObj.storeOrderNumber} `;
        await Notification.pushNotification(orderObj, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(orderObj, 'DISPATCHED'),
          orderObj.id,
          response => {
            if (response.status === 'success') {
              openUpstoreSnackBar('Order status is updated', 'success');
              getAllAgentOrders(onGoingOrderStatus, pageNo);
              handleConfirmClose();
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'error'
              );
              handleConfirmClose();
            }
          }
        );
      } else if (orderObj.orderStatus == 'DISPATCHED' && labelCount == 3) {
        let status = `Order status has been updated to   ARRIVING with order number ${orderObj.storeOrderNumber} `;
        await Notification.pushNotification(orderObj, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(orderObj, 'ARRIVING'),
          orderObj.id,
          response => {
            if (response.status === 'success') {
              openUpstoreSnackBar('Order status is updated', 'success');
              getAllAgentOrders(onGoingOrderStatus, pageNo);
              handleConfirmClose();
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'success'
              );
              handleConfirmClose();
            }
          }
        );
      } else if (orderObj.orderStatus == 'ARRIVING' && labelCount == 4) {
        let status = `Order status has been updated to  COMPLETED with order number ${orderObj.storeOrderNumber}.Track your order in orders page `;
        await Notification.pushNotification(orderObj, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(orderObj, 'COMPLETED'),
          orderObj.id,
          response => {
            if (response.status === 'success') {
              openUpstoreSnackBar('Order status is updated', 'success');
              getAllAgentOrders(onGoingOrderStatus, pageNo);

              handleConfirmClose();
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'error'
              );
              handleConfirmClose();
            }
          }
        );
      }
      Notification.pullNotifications();
    } else {
      console.log('Order is yet to be ACCEPTED by RM ');
    }
  };
  const getDirectionsToCustomer = () => {
    if (delivaryAddressData != undefined) {
      let location = JSON.parse(delivaryAddressData.location);

      if (location.lat != undefined && location.lng != undefined) {
        let directionsLink =
          'https://www.google.com/maps/dir/Current+Location/' +
          location.lat +
          ',' +
          location.lng;

        window.open(directionsLink, '_blank');
      }
    }
  };

  const handleAccordionChange = id => {
    setOpenAccordion(prevState => (id === prevState ? false : id));
  };
  const handleNext = () => {
    let pageNum = pageNo + 1;
    setPageNo(pageNum);
    if (value === 0) {
      getAllAgentOrders(onGoingOrderStatus, pageNo);
    } else if (value === 1) {
      getAllAgentOrders('CANCELLED,COMPLETED', pageNo);
    } else {
      getAllAgentOrders('RETURNED', pageNo);
    }
  };
  const handlePrevious = () => {
    let pageNum = pageNo - 1;
    setPageNo(pageNum);
    if (value === 0) {
      getAllAgentOrders(onGoingOrderStatus, pageNo);
    } else if (value === 1) {
      getAllAgentOrders('CANCELLED,COMPLETED', pageNo);
    } else {
      getAllAgentOrders('RETURNED', pageNo);
    }
  };
  return (
    <div>
      <Box>
        <AgentHeader />
        <Box sx={{ p: 2, backgroundColor: '#fff', height: '100vh' }}>
          <Grid container columnSpacing={2}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 3 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
              >
                <Tab
                  label='OnGoing Order'
                  onClick={event => {
                    getAllAgentOrders(onGoingOrderStatus, 0);
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  label='Completed Order'
                  onClick={event => {
                    getAllAgentOrders('CANCELLED,COMPLETED', 0);
                  }}
                  {...a11yProps(1)}
                />
                <Tab
                  label='Returned Order'
                  onClick={event => {
                    getAllAgentOrders('RETURNED', 0);
                  }}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Grid>
          {/* ------------------------OnGoingOrder------------------------ */}
          <TabPanel value={value} index={0}>
            {loader ? (
              <Loading />
            ) : (
              <>
                <Grid container>
                  <Grid item md={12} sx={{ mt: 2 }}>
                    {agentOrders.map(order => {
                      return (
                        <Accordion
                          key={order.id}
                          expanded={openAccordion === order.id}
                        >
                          <AccordionSummary
                            style={{ backgroundColor: '#F0F3FD' }}
                            aria-controls='panel1d-content'
                            id='panel1d-header'
                            expandIcon={
                              <ExpandMoreIcon
                                fontSize='large'
                                onClick={() => {
                                  setDelivaryAddressData({});
                                  handleAccordionChange(order.id);
                                  getAddressOfCustomer(order.deliveryAddress);
                                }}
                              />
                            }
                          >
                            <Grid container style={{ paddingLeft: '0px' }}>
                              <Grid
                                Item
                                xs={8}
                                sm={6}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    color: '#d53030',
                                    textAlign: 'left',
                                  }}
                                >
                                  <span>
                                    <ShoppingBasketIcon
                                      sx={{ fontSize: '1rem' }}
                                    />
                                    Order Number: {order?.storeOrderNumber}
                                    &nbsp;&nbsp;
                                  </span>
                                  <span style={{ display: 'inline-block' }}>
                                    Order Id : {order?.id}
                                  </span>
                                </div>
                              </Grid>

                              <Grid
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                item
                                xs={12}
                                sm={2}
                              >
                                <Typography color=' rgb(213, 48, 48)'>
                                  {order.orderStatus}
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography style={{ width: '100%' }}>
                              <List>
                                {order.orderDetails.map(item => {
                                  return (
                                    <ListItem alignItems='flex-start'>
                                      <Grid container>
                                        <Grid item xs={2} sm={1}>
                                          <ListItemAvatar>
                                            <Avatar
                                              variant='rounded'
                                              alt='productItem1'
                                              // src={
                                              //   serverConfig.imageServerUrl +
                                              //   '/product_' +
                                              //   item.productId +
                                              //   '/img_192X192_0.jpg?' +
                                              //   new Date().getTime()
                                              // }
                                            />
                                          </ListItemAvatar>
                                        </Grid>
                                        <Grid item xs={10} md={5}>
                                          <ListItemText
                                            primary={
                                              <div
                                                style={{ textAlign: 'left' }}
                                              >
                                                <Box
                                                  component='div'
                                                  display='inline'
                                                  color=' rgb(213, 48, 48)'
                                                >
                                                  <b>{item.itemName}</b>
                                                </Box>
                                                <Box
                                                  component='div'
                                                  display='inline'
                                                  color=' rgb(213, 48, 48)'
                                                >
                                                  &nbsp;&nbsp;&nbsp;
                                                  {item.quantity}
                                                  {item.unit}
                                                </Box>
                                              </div>
                                            }
                                            secondary={
                                              <div
                                                style={{ textAlign: 'left' }}
                                              >
                                                <Box
                                                  component='div'
                                                  color=' rgb(213, 48, 48)'
                                                >
                                                  {item?.itemDescription}
                                                </Box>
                                                <Box
                                                  component='div'
                                                  display='inline'
                                                >
                                                  MRP: <s>{item?.mrp}</s>
                                                </Box>
                                                <Box
                                                  component='div'
                                                  display='inline'
                                                >
                                                  &nbsp;&nbsp;&nbsp; Upstore
                                                  price:{' '}
                                                  <b>{item?.storePrice}</b>
                                                </Box>
                                              </div>
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          className='take-right'
                                          item
                                          xs={4}
                                          sm={2}
                                          color=' rgb(213, 48, 48)'
                                        >
                                          Qty: <Chip label={item.noOfItems} />
                                        </Grid>
                                        <Grid
                                          className='take-right'
                                          item
                                          xs={4}
                                          sm={2}
                                          color=' rgb(213, 48, 48)'
                                        >
                                          Price:
                                          <Chip
                                            icon={<h6>&#8377;</h6>}
                                            label={item.storePrice}
                                          />
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                          {item.notDeliverable != undefined &&
                                          item.notDeliverable ? (
                                            <Typography color='secondary'>
                                              <b>Not deliverable</b>
                                            </Typography>
                                          ) : null}
                                          {item.deleted != undefined &&
                                          item.deleted ? (
                                            <Typography color='secondary'>
                                              <b>Cancelled</b>
                                            </Typography>
                                          ) : null}

                                          {order.returned != undefined &&
                                          order.returned ? (
                                            <Typography color='secondary'>
                                              <b>Returned</b>
                                            </Typography>
                                          ) : null}
                                        </Grid>
                                      </Grid>
                                      <Divider />
                                    </ListItem>
                                  );
                                })}
                                <Divider />
                                <br />
                                <Stack sx={{ width: '100%' }} spacing={4}>
                                  <Stepper
                                    alternativeLabel
                                    activeStep={getActiveStepper(
                                      order.orderStatus
                                    )}
                                    connector={<ColorlibConnector />}
                                  >
                                    {steps.map((label, index) => (
                                      <Step key={label}>
                                        <StepLabel
                                          StepIconComponent={ColorlibStepIcon}
                                          onClick={() =>
                                            confirmOrderStatusChange(
                                              index,
                                              order
                                            )
                                          }
                                        >
                                          {label}
                                        </StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </Stack>
                                <br />
                                <Divider />
                                <br />
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    style={{
                                      textAlign: 'left',
                                      padding: '15px',
                                    }}
                                  >
                                    <b>Delivered on </b>
                                    <span style={{ color: 'green' }}>
                                      {/* {Utils.finalDateString(new Date(orderObj.eta))}   */}
                                      {order?.estimatedDeliveryTime}
                                    </span>
                                  </Grid>
                                  {/* <Grid item xs={12} sm={6}>
                              <OrderSummary orderSummary={order} />
                            </Grid> */}

                                  {order?.deliveryAddress != undefined ? (
                                    <Grid item xs={12}>
                                      <DeliveryAddress
                                        order={delivaryAddressData}
                                      />
                                    </Grid>
                                  ) : null}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={event =>
                                      getDirectionsToCustomer(order)
                                    }
                                  >
                                    Get Directions
                                  </Button>
                                </Grid>
                                <Grid xs={12}>
                                  <Divider />
                                  <Box variant='body1'>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <b>Payment Status:</b>
                                      {order.paymentMode}
                                    </Box>
                                    {order.paymentMode === 'ONLINE' ? (
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          color: 'green',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {order?.transaction}
                                      </Typography>
                                    ) : (
                                      <OrderSummaryNewTotal
                                        orderSummary={order}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </List>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    disabled={pageNo === 0}
                    variant='contained'
                    onClick={handlePrevious}
                    style={{
                      mb: 2,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                  >
                    Pre
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    style={{
                      mb: 2,
                      mr: 3,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                    disabled={agentOrders.length < 20}
                    variant='contained'
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </TabPanel>
          {/* -----------------------------COMPLATED ORDER--------------------------------- */}
          <TabPanel value={value} index={1}>
            {loader ? (
              <Loading />
            ) : (
              <>
                <Grid item md={12} sx={{ mt: 2 }}>
                  {agentOrders.map(orderObjC => {
                    return (
                      <Accordion
                        expanded={expanded === orderObjC.id}
                        onChange={handleChange1(orderObjC.id)}
                      >
                        <AccordionSummary
                          aria-controls='panel1d-content'
                          id='panel1d-header'
                          expandIcon={
                            <ExpandMoreIcon
                              fontSize='large'
                              onClick={() => {
                                setDelivaryAddressData({});
                                handleAccordionChange(orderObjC.id);
                                getAddressOfCustomer(orderObjC.deliveryAddress);
                              }}
                            />
                          }
                        >
                          <Grid container style={{ paddingLeft: '0px' }}>
                            <Grid
                              Item
                              xs={8}
                              sm={6}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  color: '#d53030',
                                  textAlign: 'left',
                                }}
                              >
                                <span>
                                  <ShoppingBasketIcon
                                    sx={{ fontSize: '1rem' }}
                                  />
                                  Order Number: {orderObjC.storeOrderNumber}{' '}
                                  &nbsp;&nbsp;
                                </span>
                                <span style={{ display: 'inline-block' }}>
                                  Order Id : {orderObjC.id}
                                </span>
                              </div>
                            </Grid>

                            <Grid
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              item
                              xs={12}
                              sm={2}
                            >
                              <Typography color=' rgb(213, 48, 48)'>
                                {orderObjC.orderStatus}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ width: '100%' }}>
                            <List>
                              {orderObjC.orderDetails.map(item => {
                                return (
                                  <ListItem alignItems='flex-start'>
                                    <Grid container>
                                      <Grid item xs={2} sm={1}>
                                        <ListItemAvatar>
                                          <Avatar
                                            variant='rounded'
                                            alt='productItem1'
                                            // src={
                                            //   serverConfig.imageServerUrl +
                                            //   '/product_' +
                                            //   item.productId +
                                            //   '/img_192X192_0.jpg?' +
                                            //   new Date().getTime()
                                            // }
                                          />
                                        </ListItemAvatar>
                                      </Grid>
                                      <Grid item xs={10} md={5}>
                                        <ListItemText
                                          primary={
                                            <div style={{ textAlign: 'left' }}>
                                              <Box
                                                component='div'
                                                display='inline'
                                              >
                                                <b>{item.itemName}</b>
                                              </Box>
                                              <Box
                                                component='div'
                                                display='inline'
                                              >
                                                &nbsp;&nbsp;&nbsp;
                                                {item.quantity}
                                                {item.unit}
                                              </Box>
                                            </div>
                                          }
                                          secondary={
                                            <div style={{ textAlign: 'left' }}>
                                              <Box component='div'>
                                                {item.itemDescription}
                                              </Box>
                                              <Box
                                                component='div'
                                                display='inline'
                                              >
                                                MRP: <s>{item.mrp}</s>
                                              </Box>
                                              <Box
                                                component='div'
                                                display='inline'
                                              >
                                                &nbsp;&nbsp;&nbsp; Upstore
                                                price: <b>{item.storePrice}</b>
                                              </Box>
                                            </div>
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        className='take-right'
                                        item
                                        xs={4}
                                        sm={2}
                                      >
                                        Qty: <Chip label={item.noOfItems} />
                                      </Grid>
                                      <Grid
                                        className='take-right'
                                        item
                                        xs={4}
                                        sm={2}
                                      >
                                        Price:{' '}
                                        <Chip
                                          icon={<h6>&#8377;</h6>}
                                          label={
                                            item.storePrice * item.noOfItems
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={4} sm={2}>
                                        {/* {item.notDeliverable != undefined &&
                                item.notDeliverable ? (
                                  <Typography color='secondary'>
                                    <b>Not deliverable</b>
                                  </Typography> */}
                                        {/* ) : null}  */}
                                        {item.deleted != undefined &&
                                        item.deleted ? (
                                          <Typography color='secondary'>
                                            <b>Cancelled</b>
                                          </Typography>
                                        ) : null}
                                        {item.returned != undefined &&
                                        item.returned ? (
                                          <Typography color='secondary'>
                                            <b>Returned</b>
                                          </Typography>
                                        ) : null}
                                      </Grid>
                                    </Grid>
                                    <Divider />
                                  </ListItem>
                                );
                              })}

                              <br />

                              <Divider />
                              <br />
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  style={{
                                    textAlign: 'left',
                                    padding: '15px',
                                  }}
                                >
                                  <b>Delivered on </b>
                                  {/* <span style={{ color: 'green' }}>
                            {Utils.finalDateString(new Date(orderObj.eta))}
                          </span> */}
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <OrderSummary orderSummary={orderObjC} />
                                </Grid>

                                {orderObjC.deliveryAddress != undefined ? (
                                  <Grid item xs={12}>
                                    <DeliveryAddress
                                      order={delivaryAddressData}
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>
                            </List>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Grid>

                <div
                  style={{
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    disabled={pageNo === 0}
                    variant='contained'
                    onClick={handlePrevious}
                    style={{
                      mb: 2,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                  >
                    Pre
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    style={{
                      mb: 2,
                      mr: 3,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                    disabled={
                      agentOrders.length < 20 || agentOrders.length === 0
                    }
                    variant='contained'
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {loader ? (
              <Loading />
            ) : (
              <>
                <ReturnAgentOrders />
                <div
                  style={{
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    disabled={pageNo === 0}
                    variant='contained'
                    onClick={handlePrevious}
                    style={{
                      mb: 2,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                  >
                    Pre
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    style={{
                      mb: 2,
                      mr: 3,
                      textTransform: 'capitalize',
                      width: '80px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                    }}
                    disabled={agentOrders.length < 20}
                    variant='contained'
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </TabPanel>
          <Dialog
            maxWidth='xs'
            onClose={handleConfirmClose}
            aria-labelledby='confirmation-dialog-title'
            open={confirmBox.open}
          >
            <DialogTitle id='confirmation-dialog-title'>
              Are you sure to update order status?
            </DialogTitle>

            <DialogContent dividers>
              <b style={{ color: '#d53030' }}>
                Are you sure to update order status? <br />
                Once updated, it can't be reverted.
              </b>
            </DialogContent>

            <DialogActions>
              <Button
                autoFocus
                color='primary'
                onClick={() => handleConfirmClose()}
              >
                Cancel
              </Button>

              <Button
                color='primary'
                onClick={event =>
                  setOrderStatus(
                    confirmBox.labelCount,
                    confirmBox.orderObj,
                    confirmBox.order
                  )
                }
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}

const OrderSummary = props => {
  const orderSummary = props.orderSummary;

  return (
    <ListItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div>
        <Box component='div' display='inline-block' color=' rgb(213, 48, 48)'>
          MRP: &nbsp; &nbsp;
          <Chip
            variant='outlined'
            icon={<h6>&#8377;</h6>}
            label={orderSummary.totalAmount}
          />
          &nbsp;&nbsp;
        </Box>

        <Box component='div' display='inline-block' color=' rgb(213, 48, 48)'>
          Discount: &nbsp; &nbsp;
          <Chip
            variant='outlined'
            icon={<h6>&#8377;</h6>}
            label={orderSummary.totalDiscount}
          />
          &nbsp;&nbsp;
        </Box>

        {orderSummary.deliveryCharges !== undefined &&
        orderSummary.deliveryCharges > 0 ? (
          <Box component='div' display='inline-block' color=' rgb(213, 48, 48)'>
            Delivery Charges: &nbsp; &nbsp;
            <Chip
              variant='outlined'
              icon={<h3>&#8377;</h3>}
              label={orderSummary.deliveryCharges}
            />
            &nbsp;&nbsp;
          </Box>
        ) : null}
        <Box component='div' display='inline-block' color=' rgb(213, 48, 48)'>
          Total Payable Amount: &nbsp; &nbsp;
          <Chip
            variant='outlined'
            icon={<h6>&#8377;</h6>}
            label={orderSummary.totalStorePrice}
          />
          &nbsp;&nbsp;
        </Box>
        {orderSummary.newTotalAmount != undefined ? (
          <Box component='div' display='inline-block' color=' rgb(213, 48, 48)'>
            <b>New Total:</b> &nbsp; &nbsp;
            <Chip
              variant='outlined'
              icon={<h6>&#8377;</h6>}
              label={orderSummary.newTotalAmount}
            />
            &nbsp;&nbsp;
          </Box>
        ) : null}

        <Grid />
        {orderSummary.comment.startsWith('Order') ? null : (
          <Box variant='body1'>
            <strong>Reason To Cancel:</strong>
            {orderSummary.comment}
          </Box>
        )}
      </div>
    </ListItem>
  );
};
export function OrderSummaryNewTotal({ orderSummary }) {
  let orderSummaryObj = orderSummary;
  return (
    <>
      {orderSummaryObj?.newTotalAmount !== undefined &&
      orderSummaryObj?.newTotalAmount !== null ? (
        <Typography style={{ fontSize: '16px', color: 'green' }}>
          {`Please collect Cash from  Customer `}
          <s>{`Total Amount : Rs ${orderSummaryObj.totalStorePrice} /-`}</s>
          <br />
          {/* <b>{`New Total Amount : Rs ${orderSummaryObj.newTotalAmount} /-`}</b> */}
        </Typography>
      ) : (
        <Typography
          style={{ fontSize: '16px', color: 'green', textAlign: 'center' }}
        >
          {`Please collect Cash from  Customer `}
          <b>{`Total Amount : Rs ${orderSummaryObj.totalStorePrice} /-`}</b>
        </Typography>
      )}
    </>
  );
}
