import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import React from 'react';

function ActivationCustomerDialog(props) {
  const handleDelete = () => {
    if (props.soft === true) {
      props.deleteCallback(true, true);
    } else {
      props.deleteCallback(true);
    }
  };

  const handleVendor = () => {
    props.deleteVendor();
    props.reloadVendorList();
  };
  return (
    <Dialog open={props.openDialog} maxWidth='md'>
      <DialogTitle>
        {props.soft != true && props?.vendor != true ? (
          <Typography variant='h4' textAlign='center'>
            Activation Alert !
          </Typography>
        ) : (
          <>
            {props.vendor === true ? (
              <Typography variant='h4' textAlign='center'>
                Delete Vendor Alert !
              </Typography>
            ) : (
              <Typography variant='h4' textAlign='center'>
                Delete Customer Alert !
              </Typography>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' textAlign='start'>
          {props.dialogContent}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: '126px', height: '27px', textTransform: 'capitalize' }}
          varient='outlined'
          onClick={() => {
            props.vendor || props.soft === false
              ? props.closeDialogCallback()
              : props.closeDialogCallback(true);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{ width: '126px', height: '27px', textTransform: 'capitalize' }}
          varient='outlined'
          onClick={props.vendor ? () => handleVendor() : () => handleDelete()}
        >
          {props.buttonAlert}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActivationCustomerDialog;
