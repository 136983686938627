import * as React from 'react';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DAFAUT_LOGO_IMG from '../../assets/images/logo.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Store from '../../modules/Store';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import Logo from '../../modules/Logo';
import OutStandingBalance from '../../component/outStandingBalance/OutStandingBalance';
import SubscribedPlan from '../../component/subscribedPlan/SubscribedPlan';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Loading } from '../../component/loading/Loading';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const addDefaultSrc = ev => {
    ev.target.src = DAFAUT_LOGO_IMG;
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          <Box sx={{ height: '30px', display: 'flex' }}>
            <img
              stlye={{ height: '15px', width: '15px ' }}
              src={Logo.getLogoUrl(row.id)}
              onError={addDefaultSrc}
            />{' '}
            <Typography sx={{ ml: 2 }}> {row?.name}</Typography>
          </Box>
        </TableCell>
        <TableCell align='center'>
          {row?.active ? (
            <Typography variant='subtitle' sx={{ color: 'green' }}>
              Active
            </Typography>
          ) : (
            <Typography variant='subtitle' sx={{ color: 'red' }}>
              In-Active
            </Typography>
          )}
        </TableCell>
        <TableCell align='center'>
          {row.lastLoginDate
            ? moment
                .utc(row.lastLoginDate)
                .local()
                .format('MMMM Do YYYY, h:mm:ss a')
            : 'N/A'}
        </TableCell>
        <TableCell align='center'>{row.numberOfUsers}</TableCell>
        {/* <TableCell align='center'>{row.registrationKey}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Grid container>
              <Box sx={{ margin: 1, display: 'flex' }}>
                <OutStandingBalance storeId={row.id} userType='customer' />
                <SubscribedPlan storeId={row.id} />
              </Box>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MonitoringTool() {
  let navigate = useNavigate();
  const [moneteringData, setMoneteringData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    getAllMonetoringToolsData();
  }, []);

  const getAllMonetoringToolsData = () => {
    Store.getMontringAccountDetails(response => {
      if (response.status === 'success') {
        setMoneteringData(response.data);
        setLoader(false);
      } else {
        setMoneteringData([]);
      }
    });
  };
  return (
    <>
      <Header />
      <Box sx={{ ml: 8, mt: 7 }}>
        <Box sx={{ display: 'flex' }}>
          <BreadCrumb pageName='Monitoring' />

          <Button
            variant='contained'
            sx={{
              p: 0,
              mr: 1,
              fontSize: '13px',
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
              ml: 'auto',
            }}
            onClick={() => navigate('/default-config')}
          >
            Default Config
          </Button>
        </Box>
      </Box>
      {loader ? (
        <Loading />
      ) : (
        <Box sx={{ ml: 6, mt: 1 }}>
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'secondary.light' }}>
                  <TableCell />
                  <TableCell>Store Name</TableCell>
                  <TableCell align='center'> Status</TableCell>
                  <TableCell align='center'>Last Login Date</TableCell>
                  <TableCell align='center'>Number Of Users</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {moneteringData.map(row => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}
