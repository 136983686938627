import template1Header from '../../assets/images/template_1_header.png';
import template2Header from '../../assets/images/template_2_header.png';
import template3Header from '../../assets/images/template_3_header.png';

import template1Footer from '../../assets/images/template_1_footer.png';
import template2Footer from '../../assets/images/template_2_footer.png';
import template3Footer from '../../assets/images/template_3_footer.png';

class TemplateProperties {
  constructor() {
    this.templates = {
      templateOne: {
        id: 1,
        bgImageHeader: template1Header,
        bgImageFooter: template1Footer,
        bgColor: '#ff6a6a',
      },
      templateTwo: {
        id: 2,
        bgImageHeader: template2Header,
        bgImageFooter: template2Footer,
        bgColor: '#77e877',
      },
      templateThree: {
        id: 3,
        bgImageHeader: template3Header,
        bgImageFooter: template3Footer,
        bgColor: '#a6a6f1',
      },
    };
  }

  getTemplateOne = () => this?.templates?.templateOne;

  getTemplateTwo = () => this?.templates?.templateTwo;

  getTemplateThree = () => this?.templates?.templateThree;

  getTemplateById = id => {
    if (id === 1) return this?.templates?.templateOne;
    else if (id === 2) return this?.templates?.templateTwo;
    else return this?.templates?.templateThree;
  };
}

export default new TemplateProperties();
