import React, { useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Button,
  Tooltip,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableFooter,
  ButtonGroup,
  Tabs,
  Tab,
  AppBar,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton,
  DialogActions,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import { Print } from '@mui/icons-material';
import Customer from '../../modules/Customer';
import { Box } from '@mui/system';
import ReturnOrder from '../../component/returnOrder/ReturnOrder';
import OrderInvoice from '../../component/orderInvoice/OrderInvoice';
import OrderRecipt from '../../component/orderRecipt/OrderRecipt';
import { ArrowLeft, PeopleAlt, Add } from '@mui/icons-material';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';

import './style.css';
import Auth from '../../modules/Auth';
import Order from '../../modules/Order';
import DownloadIcon from '@mui/icons-material/Download';

import Validation from '../../modules/Validation';

import EditIcon from '@mui/icons-material/Edit';

import Transaction from '../../modules/Transaction';
import { LogMessage } from '../../modules/Logger';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Store from '../../modules/Store';
import moment from 'moment';
import dateConveter from '../../modules/DateConveter/dateConveter';
import { serverConfig } from '../../config/server-config';
import { Link } from 'react-router-dom';
import { Loading } from '../../component/loading/Loading';
import AddEditCustomer from '../../component/addEditCustomer/AddEditCustomer';
import ListOrderItem from '../../component/listOrderItem/ListOrderItem';
import ProductItemDrawer from '../../component/productItemDrawer/ProductItemDrawer';
import DatePickerComp from '../../component/datePicker/DatePicker';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import WarningDialog from '../../component/warningDialog/WarningDialog';
import CustomerPaymentHistory from '../../component/customerPaymentHistory/CustomerPaymentHistory';
import PaymentSection from '../../component/paymentSection/PaymentSection';
import CustomerBills from '../../component/customerBills/CustomerBills';
import Header from '../../component/header/Header';
import { useNavigate } from 'react-router-dom';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Product from '../../modules/Product';
import OpticalPrescription from '../../component/opticalPrescription/OpticalPrescription';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRef } from 'react';
import HTMLRenderer from 'react-html-renderer';
import PurchaseOrders from '../../modules/PurchaseOrders';
import { debounce } from '../../utils';
import ProductItemAccordionDetails from '../../component/productItemAccordionDetails/ProductItemAccordionDetails';

// main component
const CreateOrUpdateOrder = () => {
  // for custom styling
  const textField = {
    width: '90%',
    marginTop: '6px',
    backgroundColor: '#E1EEF5',
    borderTop: '5px solid #E1EEF5',
  };
  const ref = useRef();
  const listRef = useRef(null);
  const productListRef = useRef(null);
  const inputRef = React.useRef(null);
  const { state } = useLocation();

  let navigate = useNavigate();
  const page_size = 19;
  const [customerAdded, setCustomerAdded] = React.useState(false);
  const [customerDataList, setCustomerDataList] = React.useState([]);
  const [confirmedOrder, setConfirmedOrder] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [customerInfo, setCustomerInfo] = React.useState({});
  const [customerName, setCustomerName] = React.useState('');
  const [openDeletedOrderDialog, setOpenDeletedOrderDialog] = useState(false);
  const [selectedProductItemList, setSelectedProductItemList] = React.useState(
    []
  );
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [hasMoreProductData, setHasMoreProductData] = React.useState(true);
  const [saveCustomerDisabled, setSaveCustomerDisabled] = React.useState(false);
  const [openReturnOrderDialog, setOpenReturnOrderDialog] =
    React.useState(false);
  const [deletedItemsOfConfirmedOrders, setDeletedItemsOfConfirmedOrders] =
    React.useState([]);
  const [storeOrderObj, setStoreOrderObj] = React.useState({});
  const [draftedMode, setDraftedMode] = React.useState(false);
  // TODO name variable properly
  const [clicked, setClicked] = React.useState(false);
  // for customer related
  const [openAddCustomerDialog, setOpenAddCustomerDialog] =
    React.useState(false);
  // TODO name variable properly, remove if not used
  const [buttonClicked, setButtonClicked] = React.useState('');
  // TODO name variable properly
  const [expanded, setExpanded] = React.useState(false);
  const [customerDetails, setCustomerDetails] = React.useState(
    state === null ? '' : state.customerDetails
  );

  // for product item related
  const [openItemSelectionDialog, setOpenItemSelectionDialog] =
    React.useState(false);

  const [dialogWarning, setDialogWarning] = React.useState(false);
  const [orderRecipt, setOrderRecipt] = React.useState({ open: false });
  const [confirmedOrderEditMode, setConfirmedOrderEditMode] =
    React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const [orderExpanded, setOrderExpanded] = React.useState(true);
  const [paymentExpanded, setPaymentExpanded] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    itemData: '',
  });
  const [orderInvoice, setOrderInvoice] = React.useState({ open: false });
  const [openAddReasonDialog, setOpenAddReasonDialog] = React.useState({
    open: false,
  });
  const [isDrawerOpened, SetIsDrawerOpened] = React.useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [validEmail, setValidEmail] = useState(false);

  const [customerChangedialog, setCustomerChangedialog] = React.useState(false);
  const [invoiceDate, setInvoiveDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(null);
  const [submittedButton, setSubmittedButton] = useState(false);
  // TODO check usage or remove
  const [openPrescriptionDialog, setOpenPrescriptionDialog] = useState(false);

  const [updateStoreOrder, setUpdateStoreOrder] = useState(
    state === null ? '' : state.mainOrder
  );

  const [selectedItemsSiblingItems, setSelectedItemsSiblingItems] =
    React.useState(null);
  const [productItemsOfProductIds, setProductItemsOfProductIds] =
    React.useState([]);
  const [showListorderItemComponent, setShowListorderItemComponent] =
    React.useState(false);
  const [showEditMode, setShowEditMode] = React.useState({
    showEditProd: false,
    editRowData: {},
  });
  const [refreshLedger, setRefreshLedger] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);
  const [varietyDetails, setVarietyDetails] = React.useState({
    packingData: [],
    qualityData: [],
  });
  const [productList, setProductList] = React.useState([]);

  const [pageNo, setPageNo] = React.useState(0);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  const [showFilteredProducts, setShowFilteredProducts] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [productPageNo, setProductPageNo] = React.useState(0);
  const [productSkeletonLoading, setProductSkeletonLoading] =
    React.useState(false);
  const [isValidGst, setIsValidGst] = React.useState(false);
  const [barcode, setBarcode] = useState('');
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [clickedUnitChip, setClickedUnitChip] = React.useState([]);
  let dueDateVar;
  let invoiceDateVar;
  React.useEffect(() => {
    if (Validation.isBlankObjectNot(updateStoreOrder)) {
      setDueDate(
        updateStoreOrder.dueDate
          ? moment.utc(updateStoreOrder.dueDate).local()
          : null
      );
      setInvoiveDate(
        updateStoreOrder.invoiceDate
          ? moment.utc(updateStoreOrder.invoiceDate).local()
          : null
      );

      // Customer.getCustomerById(updateStoreOrder.customerId, response => {

      // });
      getCustomerInfo();

      setIsValidGst(validateGSTNumber(customerInfo?.gstNo));
      let ids = updateStoreOrder?.orderDetails.map(item => item.productId);
      getProductItemsByProductIds(ids);
      if (updateStoreOrder?.storeOrderStatus !== 'CONFIRMED') {
        // Updated Drafted Order
        // Adding extra property dynamically in drafted mode to all items
        let updatedArr = updateStoreOrder?.orderDetails.map(
          item => (item = { ...item, drafted: true })
        );
        setSelectedProductItemList(updatedArr);

        setStoreOrderObj(updateStoreOrder);
        setDraftedMode(true);
      } else {
        // Updated Confirmed Order
        setConfirmedOrder(true);
        setSelectedProductItemList(updateStoreOrder.orderDetails);
        setStoreOrderObj(updateStoreOrder);
        let checkedUnitChips = [];
        updateStoreOrder.orderDetails.forEach(item => {
          checkedUnitChips.push(item.itemNumber);
        });
        setClickedUnitChip(checkedUnitChips);
      }
    } else {
      if (customerDetails) {
        // when user clicks in customer details page on create order button then customerDetails prop is recieved
        setCustomerInfo(customerDetails);
      } else {
        setCustomerInfo(Customer.getAnonymousCustomer());
      }
      let dueDate = new Date();
      dueDate.setDate(
        dueDate.getDate() + Store.isEditableDueDate()?.daysForDueDate
      );
      setShowListorderItemComponent(true);
      setDueDate(dueDate);
    }
    getCurrency();
    getCustomerList(pageNo);
    getAllVariatiesByType('packing');
    getAllVariatiesByType('quality');
    getProductByStoreId(0);
  }, []);

  // const systemProperty = () => {
  //   setPropertyValue(UpstoreProperties.getProperty('ESTORE_ENABLED'));
  // };
  // Function is used to prevent duplicate items while editing order without refreshing page.
  const handleSetUpdatedOrderItem = orderId => {
    Order.getStoreOrder(orderId, response => {
      if (response.status === 'success') {
        if (Validation.isBlankObjectNot(response.data)) {
          setStoreOrderObj(response.data);
          setUpdateStoreOrder(response.data);
          setSelectedProductItemList(response.data.orderDetails);
        }
      }
    });
  };
  const handleChipSelection = (item, product) => {
    handleSelectedProductItem(item, product);
    setClickedUnitChip(prevArray => [...prevArray, item.itemNumber]);
  };
  const getCurrency = () => {
    let newCurr = Store.getCurrency();
    setCurrency(newCurr);
  };

  const getCustomerInfo = () => {
    if (updateStoreOrder.customerId) {
      Customer.getCustomerById(updateStoreOrder?.customerId, response => {
        if (response.status === 'success') {
          setCustomerInfo({
            ...response.data,
            ['name']: response.data?.customerName
              ? response.data?.customerName
              : '',
          });
          setLoader(false);
        } else {
          setCustomerInfo({});
        }
      });
    }
  };
  const columns = [
    { id: 'edit', label: '', maxWidth: 2, align: 'center' },

    { id: 'itemName', label: 'Item Name', maxWidth: 300, align: 'left' },

    { id: 'itemQuantityUnit', label: 'Unit', maxWidth: 100, align: 'center' },
    {
      id: 'noOfItems',
      label: 'Qty',
      maxWidth: 100,
      align: 'center',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'mrp',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store?.companyDetails?.businessType === 'STORE'
          ? null
          : 'MRP',
      maxWidth: 100,
      align: 'center',
    },
    {
      id: 'storePrice',
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? 'Price'
          : Store?.companyDetails?.businessType === 'STORE'
          ? 'M.R.P'
          : 'Store Price',
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'discount',
      label: currency ? (
        <>
          Discount(%/
          <HTMLRenderer html={currency} />){' '}
        </>
      ) : (
        'Discount'
      ),
      maxWidth: 80,
      align: 'left',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'otherCharges',
      label:
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'Other Charges',
      maxWidth: 150,
      align: 'left',
    },
    {
      id:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'taxableValue',
      label:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'Taxable Value',
      maxWidth: 150,
      align: 'left',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'gstamt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'vatamt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'TAX'
          : null,
      label:
        Store?.companyDetails?.businessType === 'MANDI'
          ? null
          : Store.getSelectedTaxType() === 'GST'
          ? 'Tax Amt'
          : Store.getSelectedTaxType() === 'VAT'
          ? 'Vat Amt'
          : Store.getSelectedTaxType() === 'N/A'
          ? 'Tax Amt'
          : null,
      maxWidth: 100,
      align: 'center',
    },

    {
      id: 'Packing',
      label: 'packing',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'quality',
      label: 'quality',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'Commission ',
      label: 'commission (%)',
      maxWidth: 300,
      align: 'left',
    },

    {
      id:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL'
          ? null
          : 'cess',
      label:
        Store?.companyDetails?.businessType === 'MANDI' ||
        Store?.companyDetails?.businessType === 'OPTICAL' ? null : (
          <>
            {currency ? (
              <>
                Cess(%/
                <HTMLRenderer html={currency} />)
              </>
            ) : (
              'Cess'
            )}
          </>
        ),
      maxWidth: 100,
      align: 'center',
    },
    {
      id: 'amount',
      label: 'Amount',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'cross',
      label: '',
      maxWidth: 20,
      align: 'center',
    },
  ];
  const simpleOrder = columns.filter(item => {
    return (
      item.label !== 'commission (%)' &&
      item.label !== 'packing' &&
      item.label !== 'quality'
    );
  });
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const onDateChange = newValue => {
    setInvoiveDate(newValue);
  };

  const onDueDateChange = newValue => {
    setDueDate(newValue);
  };
  const getCustomerList = pageNo => {
    Customer.getAllCustomers(Auth.getStoreId(), pageNo, true, response => {
      if (response.status === 'success') {
        if (response.data.length < page_size) {
          setHasMoreData(false);
        }
        let filteredData = response.data.filter(customer => {
          return customer.active !== false;
        });

        let filteredAnonymusData = filteredData.filter(anony => {
          return anony.customerName?.toUpperCase() !== 'ANONYMOUS';
        });
        let sortedCustomerList = filteredAnonymusData.sort(function (a, b) {
          if (a.customerName && b.customerName) {
            return a.customerName.localeCompare(b.customerName);
          }
        });

        setCustomerDataList([...customerDataList, ...sortedCustomerList]);
        setTimeout(() => {
          let autoCompleteInput = document.getElementById(
            'customerAutoComplete'
          );
          if (autoCompleteInput) {
            autoCompleteInput.addEventListener('keyup', e => {
              setCustomerName(e.target.value);
            });
          }
        }, 3);
        setLoader(false);
      } else {
        setCustomerDataList([]);
        setLoader(false);
      }
      setSkeletonLoading(false);
    });
  };

  const closeReturn = () => {
    setConfirmedOrder(false);
    setOpenReturnOrderDialog(false);
    setConfirmedOrder(true);
  };
  // handling customer change

  const validateGSTNumber = gstNumber => {
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][0-9A-Z][Z]{1}[0-9A-Z]$/;
    let value = gstNumber?.match(gstRegex);
    if (value) {
      return true;
    } else if (value === null) {
      return false;
    }
  };
  const handleCustomerOnChange = event => {
    let { name, value } = event.target;
    if (name === 'gstNo') {
      let validGst = validateGSTNumber(value);
      setIsValidGst(validGst);
      setCustomerInfo({ ...customerInfo, gstNo: value });
    }

    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const closeAddEditCustomerDialog = () => {
    setOpenAddCustomerDialog(false);
  };

  const closeItemSelectionForOrder = () => {
    setOpenItemSelectionDialog(false);
  };

  // func from product item selection dialog
  const handleSelectedProductItem = (selectedItem, product) => {
    setSelectedItemsSiblingItems(product?.productItems);

    let foundItem = selectedProductItemList.some(item => {
      return item.itemNumber === selectedItem.itemNumber;
    });
    if (foundItem === true) {
      openUpstoreSnackBar('Item already added', 'error');
      return;
    } else {
      // openUpstoreSnackBar('Product added successfully', 'success');
      // let num = parseFloat(selectedItem.storePrice * 1);

      let updatedItem = {
        ...selectedItem,
        noOfItems: 1,
        // amount: Order.getCalculatedAmount(selectedItem.discount, num)?.amount,
        storeOrderId:
          updateStoreOrder?.storeOrderStatus === 'CONFIRMED'
            ? updateStoreOrder?.id
            : undefined,
      };

      // creating extra array variable to pass to calculate total values because state updation takes time
      let productListForPassingCalculateValue = [
        ...selectedProductItemList,
        updatedItem,
      ];

      setSelectedProductItemList(productListForPassingCalculateValue);

      // calculateTotalValues(productListForPassingCalculateValue);
    }
  };
  //check if item already in list
  const handleSwipePoductItem = (
    prevItem,
    newItem,
    noOfItems = 1,
    productItems
  ) => {
    let arr = selectedProductItemList;
    setSelectedItemsSiblingItems(productItems);
    let updatedItem = {
      ...newItem,
      noOfItems: noOfItems,
    };
    const index = arr.findIndex(el => el.itemNumber === prevItem.itemNumber);
    arr[index] = updatedItem;

    calculateTotalValues(arr);
  };

  // calculate sum
  const calculateTotalValues = productList => {
    let storeOrderCalculated = Order.calculateTotalValues(productList);

    setSelectedProductItemList([...storeOrderCalculated.orderDetails]);

    setStoreOrderObj({
      ...updateStoreOrder,
      customerId: customerInfo?.id,
      customerName: customerInfo?.customerName,
      totalItems: storeOrderCalculated.totalItems,
      totalAmount: storeOrderCalculated.totalAmount,
      totalDiscount: storeOrderCalculated.totalDiscount,
      totalCommission: storeOrderCalculated.totalCommission,
      totalTaxValue: storeOrderCalculated.totalTaxValue,
      tax: storeOrderCalculated.totalTaxValue,
      totalMrp: storeOrderCalculated.totalMrp,
      totalStorePrice: storeOrderCalculated.totalStorePrice,
      storeId: Auth.getStoreId(),
      orderDetails: storeOrderCalculated.orderDetails,
    });
  };

  // serves purpose of delete api and updating list in ui
  const handleDeleteApiCall = (
    itemData,
    confirmedOrderDeletionMode = null // will be true only in drafted mode deletion
  ) => {
    // checking if confirmedOrderDeletionMode parameter is true or not
    if (!confirmedOrderDeletionMode) {
      //will run in confirmed mode deletion
      setOpenDeleteDialog(false);

      let deletableItemdata = { ...itemData, deleted: true };
      setDeletedItemsOfConfirmedOrders([
        ...deletedItemsOfConfirmedOrders,
        deletableItemdata,
      ]);
      openUpstoreSnackBar('Item was deleted successfully', 'success');
    } else {
      // will run in drafted mode deletion
      Order.deleteOrderDetail(itemData.id, response => {
        if (response.status === 'success') {
          setOpenDeleteDialog(false);

          openUpstoreSnackBar('Item was deleted successfully', 'success');
        } else {
          openUpstoreSnackBar('Issue while deleting item', 'error');
        }
      });
    }
    let updatedList = selectedProductItemList.filter(item => {
      return item.itemNumber !== itemData.itemNumber;
    });
    calculateTotalValues(updatedList);
    setClickedUnitChip(
      clickedUnitChip.filter(item => item !== itemData.itemNumber)
    );
  };
  //delete order when no item is in list
  // const deleteConfirmOrder = () => {
  //   Order.deleteStoreOrder(storeOrderObj.id, response => {
  //     if (response.status === 'success') {
  //       setStoreOrderObj(response.data);
  //       // openUpstoreSnackBar('Order was deleted successfully', 'success');
  //     } else {
  //       // openUpstoreSnackBar('There is an issue in deleting order', 'error');
  //     }
  //   });
  // };
  // handling callback for unselecting product item
  const handleRemoveItemFromItemList = itemData => {
    if (confirmedOrderEditMode) {
      // will run only when user clicks on edit confirm order
      if (selectedProductItemList.length < 2) {
        // when there is only one element left
        openUpstoreSnackBar(
          'Last item of confirmed order cannot be deleted',
          'error'
        );

        // deleteConfirmOrder();
        // setOpenDeleteDialog({ open: true, itemData: itemData });
      } else {
        setOpenDeleteDialog({ open: true, itemData: itemData });
      }
    } else {
      // added drafted property dynamically in useEffect for this deletion purpose
      if (itemData.drafted === true) {
        handleDeleteApiCall(itemData, true);
      }
      // when removing item from list in order in normal creation mode
      let updatedList = selectedProductItemList.filter(item => {
        return item.itemNumber !== itemData.itemNumber;
      });
      openUpstoreSnackBar('item removed successfully', 'success');
      calculateTotalValues(updatedList);
    }
    setClickedUnitChip(
      clickedUnitChip.filter(item => item !== itemData.itemNumber)
    );
    ref?.current?.cleanValue();
  };

  // callback func to individual rows to update changed rows
  const updateRowData = rowData => {
    let result = selectedProductItemList.findIndex(
      item => item.itemNumber == rowData.itemNumber
    );
    selectedProductItemList[result] = rowData;
    calculateTotalValues(selectedProductItemList);
  };

  const handleConfirmOrder = () => {
    setSelectedItemsSiblingItems(null);
    handleSaveAndDraftOrder('CONFIRMED');
    setPaymentExpanded(true);
    setOrderExpanded(false);
  };

  // handling both save and confirm order
  const handleSaveAndDraftOrder = status => {
    setLoader(true);
    let iDate;
    setSubmittedButton(true);
    dateConveter.convertDate(invoiceDate, response => {
      iDate = response.data;
    });
    let orderObj = storeOrderObj;
    if (status === 'NEW') {
      orderObj.invoiceDate = iDate;
      orderObj.dueDate = dueDate;
      orderObj.storeOrderStatus = status;
      setConfirmedOrder(false);
    }
    if (status === 'CONFIRMED') {
      if (updateStoreOrder !== '' && (dueDate == null || dueDate == '')) {
        openUpstoreSnackBar('Please select due date first', 'error');

        return null;
      }
      orderObj.invoiceDate = iDate;
      orderObj.dueDate = dueDate; // due date state variable
      orderObj.storeOrderStatus = status;
      orderObj.id = storeOrderObj.id;
    }
    Order.saveStoreOrder(orderObj, response => {
      if (response.status === 'success') {
        if (status === 'NEW') {
          Transaction.log(
            Auth.getStoreId(),
            response.data.customerId,
            LogMessage.DRAFTED_ORDER_ADDED,
            LogMessage.DRAFTED_ORDER_TITLE,
            [response.data.storeOrderNumber, Auth.getUserName()]
          );
          openUpstoreSnackBar('order is saved to draft ', 'success');
          setStoreOrderObj(response.data);
          handleSetUpdatedOrderItem(response.data.id);
          setLoader(false);
          setSubmittedButton(false);
        }
        if (status === 'CONFIRMED') {
          setLoader(false);
          setRefreshLedger(true);
          setSelectedProductItemList([]);
          setRefreshLedger(false);
          handleSetUpdatedOrderItem(response.data.id);

          Transaction.log(
            Auth.getStoreId(),
            response.data.customerId,
            LogMessage.ORDER_ADDED,
            LogMessage.ORDER_ADDED_TITLE,
            [
              response.data.storeOrderNumber + '_' + response.data.id,
              response.data.totalAmount,
              Auth.getUserName(),
            ]
          );
          setSubmittedButton(false);
          openUpstoreSnackBar('order is confirmed successfully', 'success');
          if (customerInfo.customerStatus === 'NEW') {
            Customer.resetCustomerById(customerInfo?.id, response => {
              if (response.status === 'success') {
                setCustomerInfo(response.data);
              } else {
                console.log('error');
              }
            });

            setConfirmedOrder(true);
          } else {
            setConfirmedOrder(true);
          }
        }
      } else if (response.data === 416) {
        openUpstoreSnackBar(`Limit exceeded please upgrade your plan`, 'error');
      } else {
        openUpstoreSnackBar('error while saving order ', 'error');
      }
      setLoader(false);
    });
  };

  document.body.onkeyup = function (e) {
    if (e.key == 'Escape' || e.code == 'Escape') {
      handleClick();
    }
  };

  const getProductItemsByProductIds = ids => {
    Product.getProductIemsByProductIds(Auth.getStoreId(), ids, response => {
      if (response.status === 'success') {
        setProductItemsOfProductIds(response.data);
        setShowListorderItemComponent(true);
      } else {
        openUpstoreSnackBar(
          'error occured while getting productitems b productIds',
          'error'
        );
      }
    });
  };
  const getSelectedItemsSiblings = id => {
    let productItems = productItemsOfProductIds.filter(item => {
      return item.productId === id;
    });
    return productItems[0]?.productItems;
  };
  const closeDrawerOrder = () => {
    SetIsDrawerOpened(false);
  };
  const handleOrderDialog = () => {
    setOpenItemSelectionDialog(true);
    SetIsDrawerOpened(true);
  };
  // function for updating storOrderObj
  // argument comment gets sometimes comment from user and sometimes string 'simpleDraftedUpdate' or 'confirmDraftedUpdate)
  // when it gets comment from user then only update will get call else same save will get call
  const handleUpdateStoreOrderFunc = comment => {
    setConfirmedOrder(true);
    if (comment === 'simpleDraftedUpdate') {
      setSubmittedButton(true);
      handleSaveAndDraftOrder('NEW');
    } else if (comment === 'confirmDraftedUpdate') {
      setSubmittedButton(true);
      handleSaveAndDraftOrder('CONFIRMED');
    } else {
      if (comment.length > 0) {
        if (
          new Date(updateStoreOrder.dueDate).getTime() !==
          new Date(dueDate).getTime()
        ) {
          dateConveter.convertDate(dueDate, response => {
            dueDateVar = response.data;
          });
        }
        if (
          new Date(updateStoreOrder.invoiceDate).getTime() !==
          new Date(invoiceDate).getTime()
        ) {
          dateConveter.convertDate(invoiceDate, response => {
            invoiceDateVar = response.data;
          });
        }

        let updatedObj = {
          ...storeOrderObj,
          comment: comment,
          dueDate: dueDateVar ? dueDateVar : dueDate,
          invoiceDate: invoiceDateVar ? invoiceDateVar : invoiceDate,
          orderDetails: [
            ...storeOrderObj.orderDetails,
            ...deletedItemsOfConfirmedOrders,
          ],
        };

        Order.updateStoreOrder(updatedObj, updatedObj.id, response => {
          if (response.status === 'success') {
            setStoreOrderObj({ ...storeOrderObj, paid: response.data?.paid });
            Transaction.log(
              Auth.getStoreId(),
              response.data.customerId,
              LogMessage.ORDER_UPDATED,
              LogMessage.ORDER_UPDATED_TITLE,
              [
                response.data.storeOrderNumber + '_' + response.data.id,
                response.data.totalAmount,
                Auth.getUserName(),
              ]
            );
            setRefreshLedger(true);
            setOpenAddReasonDialog({ open: false });
            setConfirmedOrder(true);
            setConfirmedOrderEditMode(false);

            openUpstoreSnackBar(
              'Order has been updated successfully',
              'success'
            );
          } else {
            openUpstoreSnackBar(
              'Order has not been updated successfully',
              'error'
            );
          }
        });
      } else {
        openUpstoreSnackBar('Please fill the reason field', 'error');
      }
      setRefreshLedger(false);
    }
  };

  const handleSelectCustomer = customer => {
    // customer.customerSelected = true;
    setClicked(true);
    window.removeEventListener('click', handleClick);
    setCustomerInfo(customer);
    // window.addEventListener('click', handleClick);
    setSelected(false);
    setIsValidGst(validateGSTNumber(customer?.gstNo));
    setStoreOrderObj({
      ...storeOrderObj,
      customerId: customer.id,
    });
  };

  const handleClick = () => {
    inputRef?.current?.focus();
  };

  const handleClearSelectedCustomer = event => {
    setCustomerChangedialog(true);
  };

  // handling callback with users choice for deleting item or not from db
  const handleDeleteItemCallback = usersChoice => {
    if (usersChoice === true) {
      handleDeleteApiCall(openDeleteDialog.itemData);
    }
  };

  // handling users response from warning dialog
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      setDialogWarning(false);
      setConfirmedOrder(false);
      setConfirmedOrderEditMode(true);

      // setOrderExpanded(true);
    } else {
      setDialogWarning(false);
    }
  };
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const saveCustomer = id => {
    const data1 = {
      ...customerInfo,
      whatsappNo: customerInfo.whatsappNo?.toString().trim(),
      alternateNo: customerInfo.alternateNo?.toString().trim(),
    };
    if (customerInfo?.gstNo?.length > 0 && !isValidGst) {
      openUpstoreSnackBar('Please Enter Valid Gst Details  ', 'error');
      return;
    }
    if (!customerInfo.customerName) {
      openUpstoreSnackBar('Please enter a valid name', 'error');
    } else if (!customerInfo.contact) {
      openUpstoreSnackBar('Please enter a valid contact', 'error');
    } else if (!customerInfo.address) {
      openUpstoreSnackBar('Please enter a valid address', 'error');
    } else {
      setSaveCustomerDisabled(true);
      data1.deleted = null; //we are using same api for delete and update , so for updating customer we need to send deleted as null.
      Customer.updateCustomer(id, data1, response => {
        if (response.status === 'success') {
          setOrderExpanded(true);
          setSaveCustomerDisabled(false);
          Transaction.log(
            Auth.getStoreId(),
            response.data.id,
            LogMessage.CUSTOMER_DETAILS_UPDATED,
            LogMessage.CUSTOMER_UPDATED_TITLE,
            [`${response.data.customerName}`, Auth.getUserName()]
          );
          openUpstoreSnackBar('Customer details added seccessfully', 'success');

          setCustomerInfo(response.data);
        } else {
          setSaveCustomerDisabled(false);
          openUpstoreSnackBar('Customer details not added', 'error');
        }
      });
    }
  };
  const changeCustomer = () => {
    setClicked(false);
    setCustomerInfo({});
    setCustomerInfo(Customer.getAnonymousCustomer());
    setCustomerDetails({});
    setCustomerChangedialog(false);

    openUpstoreSnackBar('customer removed successfully', 'success');
  };
  const createNewCustomer = event => {
    setOrderExpanded(false);
    setClicked(true);
    let autoCompleteVal = customerName;
    let data = {
      customerName: autoCompleteVal,
      storeId: Auth.getStoreId(),
      customerStatus: 'NEW',
      openingBalance: 0,
      createdBy: Auth.getUserLoginId(),
    };

    if (!autoCompleteVal) {
      openUpstoreSnackBar('Customer name is required', 'error');
      setClicked(false);
    } else if (isNaN(autoCompleteVal) === true) {
      Customer.addNewCustomer(data, response => {
        if (response.status === 'success') {
          setCustomerInfo(response.data);

          setStoreOrderObj({
            ...storeOrderObj,
            customerId: response.data?.id,
            customerName: response.data?.customerName,
          });
          Transaction.log(
            Auth.getStoreId(),
            response.data.id,
            LogMessage.OPENING_BALANCE,
            LogMessage.OPENING_BALANCE_TITLE,
            [
              response.data.openingBalance,
              Auth.getUserName(),
              `${response.data.customerName}`,
            ]
          );

          openUpstoreSnackBar(
            'Customer added seccessfully by customer Name',
            'success'
          );
          setSelected(true);
        } else if ((response.data = 416)) {
          openUpstoreSnackBar(
            `Limit exceeded please upgrade your plan`,
            'error'
          );
          setClicked(false);
          setSelected(false);
        }
      });
    } else if (isNaN(autoCompleteVal) === false) {
      Customer.addNewCustomer(data, response => {
        if (response.status === 'success') {
          setCustomerInfo(response.data);
          setStoreOrderObj({
            ...storeOrderObj,
            customerId: response.data?.id,
            customerName: response.data?.customerName,
          });
          openUpstoreSnackBar(
            'Customer added seccessfully by customer contact',
            'success'
          );
          setSelected(true);
        } else if ((response.data = 416)) {
          openUpstoreSnackBar(
            `Limit exceeded please upgrade your plan`,
            'error'
          );
          setClicked(false);
          setSelected(false);
        }
      });
    }
  };

  const downloadInvoice = orderDetails => {
    let orderInvoiceObj = {
      open: true,
      order: {
        ...orderDetails,
        customerName: customerInfo?.customerName,
        state: customerInfo?.state,
        address: customerInfo?.address,
        gstNo: customerInfo?.gstNo,
      },
      invoiceDate: invoiceDate,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const closeInvoiceDialog = () => {
    let orderInvoiceObj = {
      open: false,
    };

    setOrderInvoice(orderInvoiceObj);
  };
  const downloadRecipt = orderDetails => {
    let orderReciptObj = {
      open: true,
      order: orderDetails,
    };

    setOrderRecipt(orderReciptObj);
  };

  const closeReciptDialog = () => {
    let orderReciptObj = {
      open: false,
    };

    setOrderRecipt(orderReciptObj);
    document.title = 'RSL | Up-Store';
  };

  const createNewOrder = () => {
    setUpdateStoreOrder('');
    setConfirmedOrder(false);
    setSelectedProductItemList({});
    setCustomerInfo(Customer.getAnonymousCustomer());
    setCustomerDetails(null);
    setStoreOrderObj({});
    setSelectedProductItemList([]);
    setClickedUnitChip([]);
    ref.current.cleanValue();
  };

  const handleCustomerEmail = e => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.match(validRegex) && e !== '') {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  };

  // for filtering deleted items on orderitems
  const rowdata = selectedProductItemList?.filter(item => {
    return !item.deleted;
  });
  const handleProductEditMode = editedRow => {
    let obj = {
      showEditProd: true,
      editRowData: editedRow,
    };

    setShowEditMode(obj);
  };
  const getAllVariatiesByType = type => {
    PurchaseOrders.getAllVariatiyByType(Auth.getStoreId(), type, response => {
      if (response.status === 'success') {
        //converting  array of stings to array of objects
        let myArrayQuality = response.data.stringArrayData.map(
          (str, index) => ({
            value: str,
            id: index + 1,
          })
        );
        if (response.data.type === 'packing') {
          setVarietyDetails({ ...varietyDetails, packingData: myArrayQuality });
        }
        if (response.data.type === 'quality') {
          setVarietyDetails({ ...varietyDetails, qualityData: myArrayQuality });
        }
      }
    });
  };

  const handleFilterCustomerChange = event => {
    if (event?.target?.value) {
      let search = event.target.value;
      Customer.getCustomerSearchName(search, Auth.getStoreId(), response => {
        if (response.status === 'success') {
          setCustomerDataList(response.data);
        }
      });
    } else if (event?.target?.value !== undefined) {
      getCustomerList(pageNo);
    }
  };
  const debouncedCustomerChange = React.useCallback(
    debounce(handleFilterCustomerChange, 300),
    []
  );

  const handlecustomerChange = event => {
    debouncedCustomerChange(event);
  };
  const handleScroll = () => {
    const list = listRef.current;

    if (
      Math.abs(
        list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1 &&
          hasMoreData
      )
    ) {
      setSkeletonLoading(true);
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;

      setPageNo(pageNum);
      getCustomerList(pageNum);
    }
  };
  const getProductByStoreId = pageNum => {
    setShowFilteredProducts(false);
    Product.getProductByStoreId(Auth.getStoreId(), pageNum, response => {
      if (response.status === 'success') {
        let arr = productList;
        arr = arr.concat([...response.data]);
        let filteredArray = arr.filter(product => {
          return product.available && !product.deleted;
        });
        if (response.data.length < 15) {
          setHasMoreProductData(false);
        }
        setProductList(filteredArray);
        setProductSkeletonLoading(false);
      } else {
        setProductList([]);
      }
    });
  };

  const handleProductSearch = e => {
    // debugger;
    let value = e.target?.value;
    let num = parseInt(value);
    if (num) {
      getDataByBarCode(num);
    }

    if (value !== '') {
      if (!value?.includes('/')) {
        Product.searchProductsByName(value, Auth.getStoreId(), response => {
          if (response.status === 'success') {
            setShowFilteredProducts(false);
            let filteredArray = response.data.filter(product => {
              return product.available && !product.deleted;
            });
            setProductList(filteredArray);
          } else {
            openUpstoreSnackBar('error occured while searching', 'error');
          }
        });
      } else {
        const searchString = value
          ?.slice(value?.indexOf('/') + 1)
          ?.toLowerCase();
        if (searchString?.length > 0) {
          setShowFilteredProducts(true);
          const filteredItemData = productList?.flatMap(product => {
            const filteredItems = product.productItems.filter(item => {
              return (
                item.itemName?.toLowerCase()?.includes(searchString) ||
                item.storePrice
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchString)
              );
            });
            return filteredItems.length > 0 ? filteredItems : [];
          });

          setFilteredItems(filteredItemData);
        } else {
          setShowFilteredProducts(false);
          setFilteredItems([]);
        }
      }
    } else {
      getProductByStoreId(0);
    }
  };

  const handleBarcodeInput = e => {
    if (e.key === 'Enter') {
      // setBarCodeData(event.target.value);
      getDataByBarCode(e.target.value);
      e.target.value = '';
    }
  };
  const getDataByBarCode = value => {
    let barcode = value;
    Product.getItemByStoreIdAndBarcode(Auth.getStoreId(), barcode, response => {
      if (response.status === 'success') {
        let newProd = productList.find(
          product1 => product1.productId === response.data.productId
        );
        // Product.getProductByProductId(response.data.productId, response1 => {
        //   if (response.status === 'success') {
        // handleChipSelection(response.data, newProd);
        // openUpstoreSnackBar.snackbar(`Item Added Successfully`, 'success');
        //   }
        // });
        handleSelectedProductItem(response.data, newProd);
        // setClickedUnitChip(prevArray => [
        //   ...prevArray,
        //   response.data.itemNumber,
        // ]);
      } else {
        openUpstoreSnackBar.snackbar(`Error Try Again`, 'error');
      }
    });
  };

  const handleSearchFieldByStoreId = event => {
    debounceProductSearchFieldByStoreId(event);
  };

  const debounceProductSearchFieldByStoreId = React.useCallback(
    debounce(e => handleProductSearch(e), 300),
    [productList]
  );
  const handleProductScroll = () => {
    const list = productListRef.current;
    if (
      Math.abs(
        list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1 &&
          hasMoreProductData
      )
    ) {
      setProductSkeletonLoading(true);
      let pageNum = productPageNo === 0 ? 1 : productPageNo + 1;

      setProductPageNo(pageNum);
      getProductByStoreId(pageNum);
    }
  };
  const handleAccordionChange = id => {
    setOpenAccordion(prevState => (id === prevState ? false : id));
  };

  return (
    <>
      <Header />
      {loader ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ marginBottom: '200px', ml: 7, mt: 7 }}>
            <Grid sx={{ ml: 1 }} item md={12} sm={12} xs={12}>
              <BreadCrumb pageName='NewOrders' />
            </Grid>
            <Grid container>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title='Go to orders' placement='bottom'>
                  <Button
                    startIcon={<ArrowLeft />}
                    variant='text'
                    underline='none'
                    sx={{
                      mb: 1,
                      ml: 2,
                      width: '126px',
                      height: '27px',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => {
                      navigate('/orders');
                    }}
                  >
                    Orders List
                  </Button>
                </Tooltip>{' '}
              </Grid>

              <Grid
                sx={{ display: 'flex', justifyContent: 'space-around' }}
                item
                md={6}
                sm={6}
                xs={6}
              >
                <Grid>
                  {confirmedOrder && serverConfig.isUpstoreCloud ? (
                    <Tooltip title='Edit Confirmed Order' placement='bottom'>
                      <Button
                        startIcon={<EditIcon sx={{ color: '#fff' }} />}
                        variant='contained'
                        disabled={!customerInfo?.active}
                        sx={{
                          width: '126px',
                          height: '27px',
                          textTransform: 'capitalize',
                          mr: 2,

                          bgcolor: '#3B78C9',
                        }}
                        onClick={() => {
                          setDialogWarning(true);
                        }}
                      >
                        Edit Order
                      </Button>
                    </Tooltip>
                  ) : null}
                </Grid>
                <Grid>
                  {confirmedOrder ? (
                    <Tooltip title='Create Order' placement='bottom'>
                      <Button
                        variant='contained'
                        disabled={!customerInfo?.active}
                        sx={{
                          width: '126px',
                          height: '27px',
                          textTransform: 'capitalize',

                          bgcolor: '#3B78C9',
                        }}
                        onClick={createNewOrder}
                      >
                        Create Order
                      </Button>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Box component='div' sx={{ mt: 1, bgcolor: '#fff' }}>
              <Box component='div' sx={{ mt: 1, bgcolor: '#fff' }}>
                <Grid
                  container
                  spacing={2}
                  px={3}
                  pt={2}
                  sx={{ alignItems: 'center' }}
                >
                  <Grid item sx={{ display: 'none' }}>
                    <Typography variant='subtitle1'>Anonymous</Typography>
                    <TextField
                      sx={textField}
                      id='customerName'
                      variant='outlined'
                      size='small'
                      disabled={true}
                      value={
                        Customer.getAnonymousCustomer()?.customerName
                          ? Customer.getAnonymousCustomer()?.customerName
                          : null
                      }
                      name='customerName'
                    />
                  </Grid>

                  <Grid item xs={10} sm={5} md={5}>
                    <Typography
                      variant='subtitle1'
                      sx={{ marginBottom: '-2px' }}
                    >
                      Customer Name/Phone
                    </Typography>
                    <Autocomplete
                      id='customerAutoComplete'
                      key='customerAutoComplete'
                      options={customerDataList}
                      ListboxProps={{
                        ref: listRef,
                        onScroll: ({ currentTarget }) => {
                          handleScroll(currentTarget);
                        },
                      }}
                      disableClearable
                      onChange={(event, newValue) => {
                        if (newValue == 121) {
                          return null;
                        }
                        handleSelectCustomer(newValue);
                      }}
                      onInputChange={e => handlecustomerChange(e)}
                      clearOnBlur={true}
                      getOptionLabel={option =>
                        typeof option == 'string'
                          ? (option = '')
                          : option !== undefined
                          ? `${option.customerName} ` || {}
                          : ''
                      }
                      value={
                        // TODO Use Validation Methods
                        customerDetails ||
                        Object.keys(updateStoreOrder).length > 0 ||
                        customerAdded
                          ? customerInfo || {}
                          : ''
                      }
                      disabled={
                        confirmedOrder ||
                        updateStoreOrder?.storeOrderStatus === 'CONFIRMED'
                      }
                      renderOption={(props, option) => {
                        const isLastItem =
                          option ===
                          customerDataList[customerDataList.length - 1];
                        return (
                          <>
                            <div {...props} key={option.id}>
                              <span
                                key={option.id}
                                style={{
                                  paddingRight: '10px',
                                }}
                              >
                                <PeopleAlt fontSize='small' />
                              </span>
                              {`${option.customerName}`}
                              <li
                                style={{ color: 'gray', marginLeft: 'auto' }}
                                key={option.id}
                                {...props}
                              >{`${
                                option.contact !== null ? option.contact : ''
                              } `}</li>
                            </div>

                            {isLastItem && skeletonLoading && (
                              <Box>
                                <Skeleton
                                  width='100%'
                                  height={50}
                                  style={{ textAlign: 'center' }}
                                >
                                  Scroll down to Load more ...
                                </Skeleton>
                              </Box>
                            )}
                          </>
                        );
                      }}
                      // renderOption={(props, option) => {
                      //   return (
                      //     <>
                      //       <div {...props} key={option.id}>

                      //       </div>
                      //     </>
                      //   );
                      // }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          sx={{
                            width: '90%',
                            marginTop: '6px',
                            backgroundColor: '#E1EEF5',
                            borderTop: '5px solid #E1EEF5',
                            '& .MuiInputBase-root': {
                              paddingRight: '39px !important',
                            },
                          }}
                          placeholder={
                            customerInfo?.customerName?.toUpperCase() ===
                            'ANONYMOUS'
                              ? 'Cash Customer'
                              : 'Search Customer'
                          }
                          size='small'
                          variant='outlined'
                          autoFocus
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={2} xs={2} sx={{ mt: 3.5 }}>
                    <Button
                      disabled={
                        confirmedOrder ||
                        clicked ||
                        customerDetails ||
                        updateStoreOrder?.storeOrderStatus === 'CONFIRMED'
                      }
                      sx={{
                        float: { md: 'right', sm: 'center', xs: 'center' },
                        backgroundColor: '#3B78C9',

                        width: '126px',
                        height: '27px',
                        textTransform: 'capitalize',
                      }}
                      variant='contained'
                      onClick={event => {
                        createNewCustomer();
                      }}
                    >
                      <Typography
                        variant='h6'
                        sx={{ fontSize: '14px', textTransform: 'capitalize' }}
                      >
                        {' '}
                        Add Customer
                      </Typography>
                    </Button>
                  </Grid>

                  {confirmedOrder ? (
                    <Grid item md={5} xs={12}>
                      <Box sx={{ display: 'flex', mt: 3 }}>
                        <Box sx={{ width: '450px', textAlign: 'center' }}>
                          <Typography variant='h5'>
                            order No : &nbsp;&nbsp; &nbsp;{' '}
                            {storeOrderObj?.storeOrderNumber}
                          </Typography>
                        </Box>
                        <Tooltip title='Download Invoice' placement='bottom'>
                          <DownloadIcon
                            sx={{
                              color: '#3B78C9',
                              cursor: 'pointer',
                              mr: 2,
                            }}
                            onClick={event => downloadInvoice(storeOrderObj)}
                          />
                        </Tooltip>
                        <Tooltip title='Download Recipt' placement='bottom'>
                          <Print
                            sx={{
                              color: '#3B78C9',
                              cursor: 'pointer',
                            }}
                            onClick={event => downloadRecipt(storeOrderObj)}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  ) : null}
                  {storeOrderObj.storeOrderStatus === 'NEW' ? (
                    <Box sx={{ width: '450px', textAlign: 'center', mt: 4 }}>
                      <Typography variant='h5'>
                        order No : &nbsp;&nbsp; &nbsp;{' '}
                        {storeOrderObj?.storeOrderNumber}
                      </Typography>
                    </Box>
                  ) : null}
                </Grid>
              </Box>
              <Box sx={{ p: 1, mt: 4 }}>
                <Grid container>
                  <Grid item md={12}>
                    {customerInfo?.customerName?.toUpperCase() !==
                    'ANONYMOUS' ? (
                      <Accordion
                        expanded={expanded === 'panel1' || selected === true}
                        onChange={handleChange('panel1')}
                      >
                        <AccordionSummary
                          sx={{ backgroundColor: 'secondary.light' }}
                          expandIcon={
                            <ExpandMoreIcon
                              onClick={() => {
                                setSelected(false);
                              }}
                            />
                          }
                          aria-controls='panel1bh-content'
                          id='panel1bh-header'
                        >
                          <Typography>
                            Customer{' '}
                            <b>
                              {customerInfo?.customerName?.toLowerCase() ===
                              'anonymous' ? (
                                customerInfo?.customerName
                              ) : (
                                <Link
                                  to='/customer-details'
                                  state={customerInfo?.id}
                                  style={{ textDecoration: 'none' }}
                                >
                                  {customerInfo?.customerName}
                                </Link>
                              )}
                            </b>
                          </Typography>

                          <Box sx={{ ml: 'auto', mr: 3 }}>
                            {Store?.companyDetails?.businessType ===
                            'OPTICAL' ? (
                              <Tooltip title=' Order' placement='bottom'>
                                <Button
                                  variant='contained'
                                  sx={{
                                    width: '126px',
                                    height: '27px',
                                    textTransform: 'capitalize',
                                    p: 1,

                                    bgcolor: '#3B78C9',
                                    mr: 3,
                                  }}
                                  onClick={() => {
                                    setOpenPrescriptionDialog(true);
                                  }}
                                  startIcon={
                                    <VisibilityIcon
                                      sx={{ color: 'white', size: '8px' }}
                                    />
                                  }
                                >
                                  <Typography sx={{ fontSize: '13px' }} noWrap>
                                    prescription
                                  </Typography>
                                </Button>
                              </Tooltip>
                            ) : null}
                            {customerInfo && (
                              <>
                                {customerInfo?.active ? (
                                  <Typography
                                    variant='subtitle'
                                    sx={{ color: 'green', fontSize: '16px' }}
                                  >
                                    {' '}
                                    Active
                                  </Typography>
                                ) : !customerInfo?.active ? (
                                  <Typography
                                    variant='subtitle'
                                    sx={{ color: 'red', fontSize: '16px' }}
                                  >
                                    InActive
                                  </Typography>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </Box>
                          {customerInfo && (
                            <>
                              {storeOrderObj.storeOrderStatus ===
                              'CONFIRMED' ? null : (
                                <Typography sx={{ float: 'right' }}>
                                  <CloseIcon
                                    onClick={handleClearSelectedCustomer}
                                  />
                                </Typography>
                              )}
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item md={3}>
                              <Typography variant='subtitle1' noWrap>
                                Customer Name *
                              </Typography>
                              <TextField
                                sx={textField}
                                id='customerName'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'customerName'
                                  )
                                    ? `${customerInfo?.customerName}`
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter customer name'
                                name='customerName'
                              />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography variant='subtitle1'>
                                Contact No *
                              </Typography>
                              <TextField
                                sx={textField}
                                id='contact'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'contact'
                                  )
                                    ? customerInfo.contact
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter number'
                                name='contact'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography variant='subtitle1'>Email</Typography>
                              <TextField
                                sx={textField}
                                id='email'
                                variant='outlined'
                                type='email'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'email'
                                  )
                                    ? customerInfo.email
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                onBlur={e =>
                                  handleCustomerEmail(e.target.value)
                                }
                                placeholder='Email not added'
                                name='email'
                              />
                              {validEmail && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                >
                                  please enter a valid email
                                </span>
                              )}
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant='subtitle1'>
                                Address
                              </Typography>
                              <TextField
                                sx={textField}
                                id='address'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'address'
                                  )
                                    ? customerInfo.address
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter Address'
                                name='address'
                              />
                            </Grid>
                            <Grid item md={3} sx={{ pt: 1 }}>
                              <Typography variant='subtitle1'>
                                Whatsapp No
                              </Typography>
                              <TextField
                                sx={textField}
                                id='whatsappNo'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'whatsappNo'
                                  )
                                    ? customerInfo.whatsappNo
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter Whatsapp No'
                                name='whatsappNo'
                              />
                            </Grid>
                            <Grid item md={3} sx={{ pt: 1 }}>
                              <Typography variant='subtitle1'>
                                Alternate No
                              </Typography>
                              <TextField
                                sx={textField}
                                id='alternateNo'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'alternateNo'
                                  )
                                    ? customerInfo.alternateNo
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter Alternate no'
                                name='alternateNo'
                              />
                            </Grid>
                            <Grid item md={3} sx={{ pt: 1 }}>
                              <Typography variant='subtitle1'>
                                GST No
                              </Typography>
                              <TextField
                                sx={textField}
                                inputProps={{
                                  maxLength: 15,
                                }}
                                id='gstNo'
                                variant='outlined'
                                size='small'
                                value={
                                  Validation.isBlankObjectKeyNot(
                                    customerInfo,
                                    'gstNo'
                                  )
                                    ? customerInfo.gstNo
                                    : ''
                                }
                                onChange={handleCustomerOnChange}
                                placeholder='Enter Gst no'
                                name='gstNo'
                                helperText={
                                  customerInfo?.gstNo?.length > 0 &&
                                  isValidGst === false && (
                                    <Typography color='red' variant='subtitle'>
                                      Incorrect GST Number
                                    </Typography>
                                  )
                                }
                              />
                            </Grid>
                            <Grid item md={3} sx={{ pt: 1 }}>
                              <Typography variant='subtitle1'>
                                Opening Balance
                              </Typography>
                              <TextField
                                sx={textField}
                                id='openingBalance'
                                autoFocus={selected}
                                type='number'
                                variant='outlined'
                                size='small'
                                disabled={
                                  customerInfo?.customerStatus !== 'NEW'
                                }
                                value={customerInfo?.openingBalance}
                                onChange={handleCustomerOnChange}
                                placeholder='Enter Opening Balance'
                                name='openingBalance'
                              />
                            </Grid>
                            <Grid item md={12} sx={{ mt: 2 }}>
                              <Button
                                variant='contained'
                                disabled={saveCustomerDisabled}
                                onClick={() => {
                                  saveCustomer(customerInfo.id);
                                  setSelected(false);
                                }}
                                sx={{
                                  width: '126px',
                                  height: '27px',
                                  textTransform: 'capitalize',
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Accordion>
                        <AccordionSummary
                          sx={{ backgroundColor: 'secondary.light' }}
                          aria-controls='panel2bh-content'
                          id='cash-customer-header'
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Cash Customer
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    )}

                    <Accordion
                      expanded={expanded === 'panel2' || orderExpanded === true}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        sx={{ backgroundColor: 'secondary.light' }}
                        expandIcon={
                          <ExpandMoreIcon
                            onClick={() => {
                              setOrderExpanded(false);
                            }}
                          />
                        }
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          Order
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='subtitle1'>
                              Invoice Date
                            </Typography>
                            <DatePickerComp
                              value={invoiceDate}
                              maxDate={new Date()}
                              inovoiceDateChange={onDateChange}
                              type='invoice'
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='subtitle1'>
                              Due Date
                            </Typography>
                            <DatePickerComp
                              value={dueDate}
                              disabled={confirmedOrder}
                              dateChange={onDueDateChange}
                            />
                          </Grid>

                          {updateStoreOrder !== undefined &&
                          updateStoreOrder !== '' ? (
                            <>
                              {updateStoreOrder.storeOrderStatus ===
                              'NEW' ? null : (
                                <>
                                  <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant='subtitle1'>
                                      Creation Date
                                    </Typography>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        minDate={new Date()}
                                        inputFormat='dd/MM/yyyy'
                                        value={
                                          Validation.isBlankObjectNot(
                                            updateStoreOrder
                                          )
                                            ? updateStoreOrder.creationDate
                                            : ''
                                        }
                                        disabled={true}
                                        renderInput={params => (
                                          <TextField
                                            size='small'
                                            sx={textField}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant='subtitle1'>
                                      Modified Date
                                    </Typography>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DatePicker
                                        minDate={new Date()}
                                        inputFormat='dd/MM/yyyy'
                                        value={
                                          Validation.isBlankObjectNot(
                                            updateStoreOrder
                                          )
                                            ? updateStoreOrder.lastModifiedDate
                                            : ''
                                        }
                                        disabled={true}
                                        renderInput={params => (
                                          <TextField
                                            size='small'
                                            sx={textField}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                </>
                              )}
                            </>
                          ) : null}
                          <Grid item md={12} sx={{ mt: 2 }}>
                            <TableContainer
                              sx={{ maxHeight: 450 }}
                              component={Paper}
                              className='scroll'
                            >
                              <Table
                                stickyHeader
                                aria-label='sticky table'
                                sx={{
                                  backgroundColor: 'secondary.light',
                                  position: 'relative',
                                }}
                                size='small'
                              >
                                <TableHead>
                                  <TableRow>
                                    {Store?.companyDetails?.businessType ===
                                    'MANDI'
                                      ? columns?.map(column => {
                                          return (
                                            <>
                                              {column.label !== null ? (
                                                <TableCell
                                                  key={column.id}
                                                  align={column.align}
                                                  style={{
                                                    maxWidth: column.maxWidth,
                                                    fontSize: 'medium',
                                                    whiteSpace: 'nowrap',
                                                    backgroundColor: '#E1EEF5',
                                                  }}
                                                >
                                                  {column.label}
                                                </TableCell>
                                              ) : null}
                                            </>
                                          );
                                        })
                                      : null}
                                  </TableRow>
                                  <TableRow>
                                    {Store?.companyDetails?.businessType !==
                                    'MANDI'
                                      ? simpleOrder.map(column => {
                                          return (
                                            <>
                                              {column.label !== null ? (
                                                <TableCell
                                                  key={column.id}
                                                  align={column.align}
                                                  style={{
                                                    maxWidth: column.maxWidth,
                                                    fontSize: 'medium',
                                                    whiteSpace: 'nowrap',
                                                    backgroundColor: '#E1EEF5',
                                                  }}
                                                >
                                                  {column.label}
                                                </TableCell>
                                              ) : null}
                                            </>
                                          );
                                        })
                                      : null}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {rowdata?.length > 0 &&
                                  showListorderItemComponent === true
                                    ? rowdata.map(row => {
                                        return (
                                          <ListOrderItem
                                            key={row.itemNumber}
                                            editMode={confirmedOrderEditMode}
                                            orderObj={storeOrderObj}
                                            row={row}
                                            productItems={
                                              selectedItemsSiblingItems ||
                                              getSelectedItemsSiblings(
                                                row?.productId
                                              )
                                            }
                                            handleProductEditMode={
                                              handleProductEditMode
                                            }
                                            removeItemFromSelectionList={
                                              handleRemoveItemFromItemList
                                            }
                                            snackbar={openUpstoreSnackBar}
                                            swipeProductItemCallback={
                                              handleSwipePoductItem
                                            }
                                            updatedRowData={updateRowData}
                                            // prop for disabling buttons
                                            confirmedOrder={confirmedOrder}
                                            sowManualEditMode={showEditMode}
                                            customerDet={customerInfo}
                                            closeEditIcon={() => {
                                              setShowEditMode({
                                                ...showEditMode,
                                                showEditProd: false,
                                              });
                                            }}
                                            currency={currency}
                                            getAllVariatiesByType={
                                              getAllVariatiesByType
                                            }
                                            packingData={
                                              varietyDetails?.packingData &&
                                              varietyDetails?.packingData
                                            }
                                            qualityData={
                                              varietyDetails?.qualityData
                                            }
                                            handlePropCilck={handleClick}
                                          />
                                        );
                                      })
                                    : null}
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    sx={{ backgroundColor: '#fff' }}
                                  >
                                    <TableCell
                                      colSpan={
                                        !Store.getSelectedTaxType() ? 12 : 12
                                      }
                                      align='left'
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Autocomplete
                                          id='ProductList'
                                          key='productListDropdown'
                                          noOptionsText=''
                                          disabled={confirmedOrder}
                                          options={productList}
                                          sx={{
                                            width: '80%',
                                            borderRadius: '50px',
                                            mt: 1,
                                          }}
                                          onInputChange={e =>
                                            handleSearchFieldByStoreId(e)
                                          }
                                          ListboxProps={{
                                            ref: productListRef,
                                            onScroll: ({ currentTarget }) => {
                                              handleProductScroll(
                                                currentTarget
                                              );
                                            },
                                          }}
                                          getOptionLabel={option =>
                                            option['name'] || option
                                          }
                                          renderOption={(props, option) => {
                                            const isLastItem =
                                              option ===
                                              productList[
                                                productList.length - 1
                                              ];
                                            if (!option.productItems)
                                              return null;
                                            return (
                                              <>
                                                <Accordion
                                                  sx={{
                                                    bgcolor: '#E1EEF5',
                                                    justifyContent: 'center',
                                                  }}
                                                  key={option.productId}
                                                  expanded={
                                                    openAccordion ===
                                                    option.productId
                                                  }
                                                >
                                                  <AccordionSummary
                                                    sx={{
                                                      bgcolor: '#E1EEF5',
                                                      alignItems: 'center',
                                                      height: '60px',
                                                    }}
                                                    expandIcon={
                                                      <ExpandMoreIcon
                                                        fontSize='large'
                                                        onClick={() =>
                                                          handleAccordionChange(
                                                            option.productId
                                                          )
                                                        }
                                                      />
                                                    }
                                                    aria-controls='panel1bh-content'
                                                    id='panel1bh-header'
                                                    key={option.productId}
                                                  >
                                                    <List
                                                      style={{ width: '30%' }}
                                                      key={option.itemNumber}
                                                    >
                                                      <ListItem>
                                                        <ListItemText
                                                          sx={{
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                              'ellipsis',
                                                            display:
                                                              '-webkit-box',
                                                            WebkitLineClamp:
                                                              '2',
                                                            WebkitBoxOrient:
                                                              'vertical',
                                                          }}
                                                          primary={
                                                            <Tooltip
                                                              title={
                                                                option.name
                                                              }
                                                              placment='top'
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  textOverflow:
                                                                    'ellipsis',
                                                                  overflow:
                                                                    'hidden',
                                                                  width:
                                                                    '260px',
                                                                  height:
                                                                    '1.6em',
                                                                  whiteSpace:
                                                                    'nowrap',
                                                                }}
                                                              >
                                                                {option.name}
                                                              </Typography>
                                                            </Tooltip>
                                                          }
                                                          secondary={
                                                            <Tooltip
                                                              title={
                                                                option.description
                                                              }
                                                              placment='top'
                                                            >
                                                              <Box>
                                                                {
                                                                  option.description
                                                                }
                                                              </Box>
                                                            </Tooltip>
                                                          }
                                                        />
                                                      </ListItem>
                                                    </List>
                                                    <Stack
                                                      direction='row'
                                                      alignItems='center'
                                                      sx={{
                                                        overflowX: 'scroll',
                                                        whiteSpace: 'nowrap',
                                                        scrollBehavior:
                                                          'smooth',
                                                        width: '450px',
                                                      }}
                                                      spacing={1}
                                                    >
                                                      {option.productItems &&
                                                      option.productItems
                                                        .length > 0
                                                        ? option.productItems
                                                            .filter(
                                                              item =>
                                                                !item.deleted
                                                            )
                                                            .map(item => (
                                                              <Tooltip
                                                                title={
                                                                  item.itemName
                                                                }
                                                                placement='bottom'
                                                              >
                                                                <Chip
                                                                  key={
                                                                    item.productId
                                                                  }
                                                                  color={
                                                                    clickedUnitChip &&
                                                                    clickedUnitChip.includes(
                                                                      item.itemNumber
                                                                    )
                                                                      ? 'info'
                                                                      : 'default'
                                                                  }
                                                                  disabled={
                                                                    clickedUnitChip &&
                                                                    clickedUnitChip.includes(
                                                                      item.itemNumber
                                                                    )
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  label={`${
                                                                    item.quantity
                                                                  } ${
                                                                    item.unit
                                                                  } Rs:  ${
                                                                    window?.location?.hash?.endsWith(
                                                                      '/neworders'
                                                                    )
                                                                      ? item?.storePrice
                                                                      : item?.basePrice ===
                                                                        null
                                                                      ? 0
                                                                      : item?.basePrice
                                                                  }`}
                                                                  sx={{
                                                                    cursor:
                                                                      'pointer',
                                                                  }}
                                                                  onClick={() =>
                                                                    handleChipSelection(
                                                                      item,
                                                                      option
                                                                    )
                                                                  }
                                                                />
                                                              </Tooltip>
                                                            ))
                                                        : null}
                                                    </Stack>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <ProductItemAccordionDetails
                                                      itemData={
                                                        option.productItems
                                                      }
                                                      product={option}
                                                      selectedProductItemCallback={
                                                        handleSelectedProductItem
                                                      }
                                                      snackbar={
                                                        openUpstoreSnackBar
                                                      }
                                                    />
                                                  </AccordionDetails>
                                                </Accordion>
                                                {isLastItem &&
                                                  productSkeletonLoading && (
                                                    <Box>
                                                      <Skeleton
                                                        width='100%'
                                                        height={50}
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        Scroll down to Load more
                                                        ...
                                                      </Skeleton>
                                                    </Box>
                                                  )}
                                              </>
                                            );
                                          }}
                                          renderInput={params => (
                                            <>
                                              <input
                                                ref={inputRef}
                                                style={{
                                                  position: 'absolute',
                                                  top: '-100px',
                                                }}
                                                type='text'
                                                id='barcode-input'
                                                autoFocus
                                                onKeyDown={handleBarcodeInput}
                                              />
                                              <TextField
                                                {...params}
                                                placeholder='search product'
                                                size='small'
                                                variant='outlined'
                                                InputProps={{
                                                  ...params.InputProps,
                                                  type: 'search',
                                                }}
                                              />
                                              {showFilteredProducts && (
                                                <Box sx={{ mt: 4 }}>
                                                  <ProductItemAccordionDetails
                                                    itemData={filteredItems}
                                                    selectedProductItemCallback={
                                                      handleSelectedProductItem
                                                    }
                                                    snackbar={
                                                      openUpstoreSnackBar
                                                    }
                                                  />
                                                </Box>
                                              )}
                                            </>
                                          )}
                                        />

                                        {/* <Autocomplete
                                          id='productAutocomplete'
                                          options={
                                            showFilteredProducts
                                              ? filteredItems
                                              : productList
                                          }
                                          // ListboxProps={{
                                          //   ref: listRef,
                                          //   onScroll: ({ currentTarget }) => {
                                          //     handleScroll(currentTarget);
                                          //   },
                                          // }}
                                          sx={{
                                            width: '80%',
                                            borderRadius: '50px',
                                            mt: 1,
                                          }}
                                          onInputChange={e =>
                                            handleSearchFieldByStoreId(e)
                                          }
                                          getOptionLabel={option =>
                                            typeof option == 'string'
                                              ? (option = '')
                                              : option !== undefined
                                              ? `${option} ` || {}
                                              : ''
                                          }
                                          renderOption={(props, option) => {
                                            return (
                                              <>
                                                {showFilteredProducts ? (
                                                  <ProductItemAccordionDetails
                                                    itemData={filteredItems}
                                                    selectedProductItemCallback={
                                                      handleSelectedProductItem
                                                    }
                                                    snackbar={
                                                      openUpstoreSnackBar
                                                    }
                                                  />
                                                ) : (
                                                  <Accordion>
                                                    <AccordionSummary>
                                                      <Typography>
                                                        {option.name}
                                                      </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <ProductItemAccordionDetails
                                                        itemData={
                                                          option.productItems
                                                        }
                                                        product={option}
                                                        selectedProductItemCallback={
                                                          handleSelectedProductItem
                                                        }
                                                        snackbar={
                                                          openUpstoreSnackBar
                                                        }
                                                      />
                                                    </AccordionDetails>
                                                  </Accordion>
                                                )}
                                              </>
                                            );
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              placeholder={'search product'}
                                              size='small'
                                              variant='outlined'
                                              autoFocus
                                              InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                              }}
                                            />
                                          )}
                                        /> */}

                                        <Button
                                          variant='contained'
                                          startIcon={<Add />}
                                          style={{ marginTop: '0px' }}
                                          size='small'
                                          sx={{
                                            // TODO Move to .css
                                            textTransform: 'capitalize',
                                            // height: '27px',

                                            width: '132px',
                                            justifyContent: 'flex-start',
                                            outline: 'none',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            mt: 2,
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: '#FCFCFC',
                                            },
                                          }}
                                          disabled={confirmedOrder}
                                          onClick={handleOrderDialog}
                                        >
                                          <Typography
                                            sx={{ p: 0, fontSize: '11px' }}
                                            noWrap
                                          >
                                            Add New Item
                                          </Typography>
                                        </Button>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                                {selectedProductItemList.length > 0 ? (
                                  <TableFooter
                                    sx={{
                                      backgroundColor: '#E1EEF5',
                                      position: 'sticky',
                                      bottom: 0,
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          // TODO Move to .css
                                          maxWidth: 30,
                                          fontSize: 'large',
                                          whiteSpace: 'nowrap',
                                        }}
                                      ></TableCell>
                                      <TableCell
                                        style={{
                                          maxWidth: 100,
                                          fontSize: 'large',
                                          fontWeight: 'bold',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        Total
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          maxWidth: 50,
                                          fontSize: 'large',
                                          whiteSpace: 'nowrap',
                                        }}
                                      ></TableCell>
                                      <TableCell
                                        style={{
                                          maxWidth: 100,
                                          fontSize: 'large',
                                          whiteSpace: 'nowrap',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {storeOrderObj?.totalItems?.toFixed(3)}
                                      </TableCell>
                                      {Store?.companyDetails?.businessType ===
                                      'STORE' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        ></TableCell>
                                      )}
                                      {Store.companyDetails.businessType ===
                                      'MANDI' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        ></TableCell>
                                      )}
                                      <TableCell
                                        style={{
                                          maxWidth: 100,
                                          fontSize: 'large',
                                          textAlign: 'left',
                                          whiteSpace: 'nowrap',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {/* {storeOrderObj.storeOrderStatus ===
                                          'NEW' ||
                                        storeOrderObj.storeOrderStatus ===
                                          'CONFIRMED'
                                          ? storeOrderObj?.totalDiscount?.toFixed(
                                              3
                                            )
                                          : storeOrderObj?.totalDiscount?.toFixed(
                                              3
                                            )} */}
                                        {storeOrderObj?.totalDiscount?.toFixed(
                                          3
                                        )}
                                      </TableCell>
                                      {!Store.getSelectedTaxType() ||
                                      Store.companyDetails.businessType ===
                                        'MANDI' ||
                                      Store.companyDetails.businessType ===
                                        'OPTICAL' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        ></TableCell>
                                      )}

                                      {Store.companyDetails.businessType ===
                                      'OPTICAL' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 50,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        ></TableCell>
                                      )}
                                      {!Store.getSelectedTaxType() ||
                                      Store.companyDetails.businessType ===
                                        'MANDI' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 100,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {' '}
                                          {storeOrderObj.storeOrderStatus ===
                                            'NEW' ||
                                          storeOrderObj.storeOrderStatus ===
                                            'CONFIRMED'
                                            ? storeOrderObj?.tax?.toFixed(3)
                                            : storeOrderObj?.totalTaxValue?.toFixed(
                                                3
                                              )}
                                        </TableCell>
                                      )}
                                      {Store?.companyDetails?.businessType ===
                                      'MANDI' ? (
                                        <>
                                          <TableCell
                                            style={{
                                              maxWidth: 100,
                                              fontSize: 'large',
                                              whiteSpace: 'nowrap',
                                            }}
                                          ></TableCell>

                                          <TableCell
                                            style={{
                                              maxWidth: 50,
                                              fontSize: 'large',
                                              whiteSpace: 'nowrap',
                                            }}
                                          ></TableCell>

                                          <TableCell
                                            style={{
                                              maxWidth: 100,
                                              fontSize: 'large',
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            {currency ? (
                                              <HTMLRenderer html={currency} />
                                            ) : null}
                                            :
                                            {storeOrderObj?.totalCommission?.toFixed(
                                              3
                                            )}
                                          </TableCell>
                                        </>
                                      ) : null}

                                      {Store.companyDetails.businessType ===
                                      'OPTICAL' ? null : (
                                        <TableCell
                                          style={{
                                            maxWidth: 100,
                                            fontSize: 'large',
                                            whiteSpace: 'nowrap',
                                          }}
                                        ></TableCell>
                                      )}
                                      <TableCell
                                        style={{
                                          maxWidth: 100,
                                          fontSize: 'large',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {currency ? (
                                          <HTMLRenderer html={currency} />
                                        ) : null}

                                        {storeOrderObj?.totalAmount?.toFixed(3)}
                                      </TableCell>
                                    </TableRow>
                                  </TableFooter>
                                ) : null}
                              </Table>
                            </TableContainer>

                            <Box
                              sx={{
                                // TODO Move to .css
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                                float: 'left',
                              }}
                            >
                              {!draftedMode &&
                              confirmedOrder &&
                              !confirmedOrderEditMode ? (
                                <>
                                  <Button
                                    variant='contained'
                                    sx={{
                                      textTransform: 'capitalize',
                                      width: '126px',
                                      height: '27px',
                                    }}
                                    onClick={event =>
                                      setOpenReturnOrderDialog(true)
                                    }
                                  >
                                    <Typography noWrap>
                                      {`Return Goods`}
                                    </Typography>
                                  </Button>
                                  {state?.deletedItems?.length > 0 ? (
                                    <Button
                                      variant='contained'
                                      sx={{
                                        textTransform: 'capitalize',
                                        width: '126px',
                                        height: '27px',
                                        ml: 1,
                                      }}
                                      onClick={event =>
                                        setOpenDeletedOrderDialog(true)
                                      }
                                    >
                                      <Typography noWrap>
                                        {`Deleted Items`}
                                      </Typography>
                                    </Button>
                                  ) : null}
                                </>
                              ) : null}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                                float: 'right',
                              }}
                            >
                              {selectedProductItemList.length > 0 ? (
                                <ButtonGroup>
                                  {confirmedOrderEditMode ? (
                                    <Button
                                      disabled={confirmedOrder}
                                      variant='contained'
                                      sx={{
                                        textTransform: 'capitalize',
                                        p: 0,
                                        fontSize: '13px',
                                        width: '126px',
                                        height: '27px',
                                      }}
                                      onClick={event => {
                                        setOpenAddReasonDialog({ open: true });
                                        setShowEditMode({
                                          showEditProd: false,
                                        });
                                      }}
                                    >
                                      <Typography noWrap>
                                        {`Save & Confirm`}
                                      </Typography>
                                    </Button>
                                  ) : draftedMode && !confirmedOrder ? (
                                    <>
                                      <Button
                                        disabled={submittedButton}
                                        variant='contained'
                                        sx={{
                                          textTransform: 'capitalize',
                                          width: '126px',
                                          height: '27px',

                                          mr: 2,
                                        }}
                                        onClick={() =>
                                          handleUpdateStoreOrderFunc(
                                            'simpleDraftedUpdate'
                                          )
                                        }
                                      >
                                        {`Update`}
                                      </Button>
                                      <Button
                                        disabled={submittedButton}
                                        variant='contained'
                                        sx={{
                                          textTransform: 'capitalize',
                                          p: 0,
                                          width: '126px',
                                          height: '27px',
                                          fontSize: '13px',
                                        }}
                                        onClick={() => {
                                          handleUpdateStoreOrderFunc(
                                            'confirmDraftedUpdate'
                                          );
                                        }}
                                      >
                                        <Typography noWrap>
                                          {`Update & Confirm`}
                                        </Typography>
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        disabled={
                                          submittedButton || confirmedOrder
                                        }
                                        variant='contained'
                                        sx={{
                                          textTransform: 'capitalize',
                                          width: '126px',
                                          height: '27px',
                                          mr: 2,
                                        }}
                                        onClick={event => {
                                          handleSaveAndDraftOrder('NEW');
                                        }}
                                      >
                                        <Typography noWrap>
                                          {`Draft Order`}
                                        </Typography>
                                      </Button>
                                      <Button
                                        disabled={
                                          submittedButton || confirmedOrder
                                        }
                                        variant='contained'
                                        sx={{
                                          textTransform: 'capitalize',
                                          width: '126px',
                                          height: '27px',
                                        }}
                                        onClick={event => {
                                          handleConfirmOrder();
                                          setShowEditMode({
                                            showEditProd: false,
                                          });
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                        >{`Confirm Order`}</Typography>
                                      </Button>
                                    </>
                                  )}
                                </ButtonGroup>
                              ) : null}
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {confirmedOrder ? (
                      <Accordion
                        expanded={
                          expanded === 'panel3' || paymentExpanded === true
                        }
                        onChange={handleChange('panel3')}
                      >
                        <AccordionSummary
                          sx={{ backgroundColor: 'secondary.light' }}
                          expandIcon={
                            <ExpandMoreIcon
                              onClick={() => {
                                setPaymentExpanded(false);
                              }}
                            />
                          }
                          aria-controls='panel3bh-content'
                          id='panel3bh-header'
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Payment
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            {storeOrderObj.id && (
                              <PaymentSection
                                customerDetails={customerInfo}
                                storeOrderObj={storeOrderObj}
                                customerId={
                                  storeOrderObj?.customerId &&
                                  storeOrderObj?.customerId
                                }
                                type='Customer'
                                orderId={storeOrderObj && storeOrderObj?.id}
                                snackbar={openUpstoreSnackBar}
                                name={customerInfo?.customerName}
                                refreshLedger={refreshLedger}
                              />
                            )}
                            {Customer.getAnonymousCustomer()?.id !==
                            storeOrderObj?.customerId ? (
                              <BillsAndPayementHistoryTab
                                customerId={customerInfo?.id}
                                snackbar={openUpstoreSnackBar}
                              />
                            ) : null}
                          </>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}
                    {/* {serverConfig.isUpstoreCloud === true &&
                    propertyValue === 'true' ? (
                      <>
                        {confirmedOrder &&
                        Customer.getAnonymousCustomer()?.id !==
                          storeOrderObj?.customerId ? (
                          <Accordion
                            expanded={expanded === 'panel4'}
                            onChange={handleChange('panel4')}
                          >
                            <AccordionSummary
                              sx={{ backgroundColor: 'secondary.light' }}
                              expandIcon={
                                <ExpandMoreIcon
                                  onClick={() => {
                                    setPaymentExpanded(false);
                                    setOrderExpanded(false);
                                  }}
                                />
                              }
                              aria-controls='panel3bh-content'
                              id='panel3bh-header'
                            >
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Agent Selection
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <AgentSelection
                                storeOrderObj={storeOrderObj}
                                customerDetails={customerInfo}
                                snackbar={openUpstoreSnackBar}
                              />
                            </AccordionDetails>
                          </Accordion>
                        ) : null}
                      </>
                    ) : null} */}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {openAddCustomerDialog === true ? (
              <AddEditCustomer
                openDialog={openAddCustomerDialog}
                closePopup={closeAddEditCustomerDialog}
                buttonClicked={buttonClicked}
                snackbar={openUpstoreSnackBar}
                reloadCustomersList={getCustomerList}
                getNewlyAdderdCustomerDetails={data => {
                  setCustomerAdded(true);
                  setCustomerDataList([...customerDataList, data]);
                }}
              />
            ) : null}

            {/* add product item drawer */}
            {openItemSelectionDialog ? (
              <ProductItemDrawer
                selectedProductItemCallback={handleSelectedProductItem}
                openDialog={openItemSelectionDialog}
                closePopup={closeItemSelectionForOrder}
                productItemList={selectedProductItemList}
                snackbar={openUpstoreSnackBar}
                drawerOpened={isDrawerOpened}
                closeDrawer={closeDrawerOrder}
                ref={ref}
              />
            ) : null}
            {dialogWarning ? (
              <WarningDialog
                handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
                openDialog={dialogWarning}
                content='This order is confirmed , Are you sure you want to edit it..'
              />
            ) : null}

            {openDeleteDialog.open ? (
              <DeleteDialog
                openDialog={openDeleteDialog.open}
                closeDialogCallback={() =>
                  setOpenDeleteDialog({ open: false, itemNumber: '' })
                }
                deleteCallback={handleDeleteItemCallback}
                dialogContent=' This will impact in all of the balances , Are you sure to delete?'
              />
            ) : null}
            {openAddReasonDialog.open ? (
              <AddReasonDialog
                openPopup={openAddReasonDialog.open}
                onClose={() => setOpenAddReasonDialog({ open: false })}
                updateStoreOrderCallback={handleUpdateStoreOrderFunc}
                confirmedOrder={confirmedOrder}
              />
            ) : null}
            {openReturnOrderDialog ? (
              <ReturnOrder
                storeOrderObj={storeOrderObj}
                openDialog={openReturnOrderDialog}
                storeOrderProductItems={selectedProductItemList}
                close={closeReturn}
                snackbar={openUpstoreSnackBar}
                customerInfo={customerInfo}
                storeOrderNumber={storeOrderObj.storeOrderNumber}
                handleSetUpdatedOrderItem={handleSetUpdatedOrderItem}
                currency={currency}
              />
            ) : null}
            {openPrescriptionDialog ? (
              <OpticalPrescription
                openDialog={openPrescriptionDialog}
                close={() => {
                  setOpenPrescriptionDialog(false);
                }}
                customerId={customerInfo?.id}
                customerInfo={customerInfo}
              />
            ) : null}
            {upstoreSnackBar.open ? (
              <UpStoreSnackBar
                upstoreSnackBar={upstoreSnackBar}
                onSnackbarClose={onSnackbarClose}
              />
            ) : null}
          </Box>

          {/* Deleted items dialog */}

          <Dialog
            maxWidth={'xl'}
            open={openDeletedOrderDialog}
            onClose={() => {
              setOpenDeletedOrderDialog(false);
            }}
          >
            <DialogContent>
              {' '}
              <Typography variant='h5'>Deleted Items</Typography>
            </DialogContent>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size='small'
                  aria-label='a dense table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>item Name</TableCell>
                      <TableCell align='right'>unit</TableCell>
                      <TableCell align='right'>QTY</TableCell>
                      <TableCell align='right'>discount</TableCell>
                      <TableCell align='right'>store Price</TableCell>
                      <TableCell align='right'>Mrp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.deletedItems?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.itemName}
                        </TableCell>
                        <TableCell align='right'>{row.unit}</TableCell>
                        <TableCell align='right'>{row.noOfItems}</TableCell>
                        <TableCell align='right'>{row.discount}</TableCell>
                        <TableCell align='right'>{row.storePrice}</TableCell>
                        <TableCell align='right'>{row.mrp}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogContent>
              <Button
                sx={{
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                variant='outlined'
                onClick={() => {
                  setOpenDeletedOrderDialog(false);
                }}
              >
                cancel
              </Button>
            </DialogContent>
          </Dialog>
          {/* open customer dialog */}
          <Dialog
            maxWidth={'xl'}
            open={customerChangedialog}
            onClose={() => {
              setCustomerChangedialog(false);
            }}
          >
            <DialogContent>
              <Typography variant='h5'>
                Are you sure you want to change customer
              </Typography>
            </DialogContent>
            <DialogContent>
              <Button
                sx={{
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                variant='outlined'
                onClick={() => {
                  setCustomerChangedialog(false);
                }}
              >
                cancel
              </Button>
              <Button
                variant='outlined'
                sx={{
                  float: 'right',
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                }}
                onClick={changeCustomer}
              >
                Agree
              </Button>
            </DialogContent>
          </Dialog>
          {/* open invoice and receipt dialog */}
          <Dialog
            maxWidth={'xl'}
            open={orderInvoice.open}
            onClose={closeInvoiceDialog}
          >
            <Grid container alignItems='flex-end' direction='column' mt={'5px'}>
              <Grid item>
                <Tooltip title='Close dialog' placment='right'>
                  <Close
                    onClick={closeInvoiceDialog}
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <DialogContent>
              <OrderInvoice
                orderDetails={orderInvoice.order}
                invoice={orderInvoice.invoiceDate}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            maxWidth={'lg'}
            open={orderRecipt.open}
            onClose={closeReciptDialog}
          >
            <DialogContent>
              <OrderRecipt orderDetails={orderRecipt.order} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

// for tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BillsAndPayementHistoryTab(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <AppBar position='static' sx={{ bgcolor: 'secondary.light' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {/* <Tab label='Bills' {...a11yProps(0)} /> */}
          <Tab label='Payment History' {...a11yProps(0)} />
        </Tabs>
      </AppBar>

      {/* <TabPanel value={value} index={0}>
        <CustomerBills customerId={props.customerId} />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <CustomerPaymentHistory
          customerId={props.customerId}
          snackbar={props.snackbar}
        />
      </TabPanel>
    </Box>
  );
}

// for reason popup dialog

const AddReasonDialog = props => {
  const [comment, setComment] = React.useState('');

  return (
    <Dialog open={props.openPopup} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Add Reason Alert !!
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>Reason *</Typography>
        <TextField
          sx={{ width: '100%', mt: 1 }}
          id='comment'
          multiline
          size='small'
          focused
          value={comment}
          onChange={event => setComment(event.target.value)}
          placeholder='Enter reason here'
          variant='standard'
          name='comment'
        />
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={props?.confirmedOrder}
            sx={{
              padding: 0,
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => props.updateStoreOrderCallback(comment)}
          >
            Save & Confirm
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrUpdateOrder;
