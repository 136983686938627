import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, MenuItem, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import UpstoreProperties from '../../modules/UpstoreProperties';
import Auth from '../../modules/Auth';

export default function AddNewProperty({
  open,
  close,
  snackbar,
  getDefaultProperties,
}) {
  const [dropDownValue, setDropDownValue] = React.useState('');
  const [propertyData, setPropertyData] = React.useState({});
  const textField = {
    width: '90%',
  };

  const handleChangeReadOnly = event => {
    setDropDownValue(event.target.value);
  };
  const addNewProperty = () => {
    let data = {
      ...propertyData,
      readOnly: dropDownValue,
      storeId: Auth.getStoreId(),
    };
    UpstoreProperties.addUpstoreProperty(data, response => {
      if (response.status === 'success') {
        snackbar('property Added Sucessfully', 'success');
        getDefaultProperties(0);
        close();
      } else {
        snackbar('property not Added ', 'error');
      }
    });
  };
  const handleOnchange = event => {
    let { name, value } = event.target;
    setPropertyData({ ...propertyData, [name]: value });
  };
  return (
    <div>
      <Dialog open={open} onClose={close} fullWidth={true}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {' '}
          Add New Property
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {' '}
            <Grid container columnSpacing={4} rowSpacing={1}>
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle1' className='list-title'>
                  Property Name
                </Typography>
                <TextField
                  sx={textField}
                  size='small'
                  id='property'
                  placeholder='Enter Property Name'
                  variant='outlined'
                  name='property'
                  onChange={handleOnchange}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant='subtitle1' className='list-title'>
                  Property Value
                </Typography>
                <TextField
                  sx={textField}
                  size='small'
                  id='propertyValue'
                  placeholder='Enter Property Value'
                  variant='outlined'
                  name='propertyValue'
                  onChange={handleOnchange}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant='subtitle1' className='list-title'>
                  Description
                </Typography>
                <TextField
                  sx={textField}
                  size='small'
                  id='propertyDescription'
                  placeholder='Enter Description'
                  variant='outlined'
                  name='propertyDescription'
                  onChange={handleOnchange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle1' className='list-title'>
                  Read Only
                </Typography>
                <TextField
                  sx={textField}
                  select
                  size='small'
                  id='readOnly'
                  variant='outlined'
                  name='readOnly'
                  onChange={handleChangeReadOnly}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}></Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
          >
            Close
          </Button>
          <Button
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
              color: '#ffff',
              backgroundColor: '#3B78C9',
            }}
            variant='contained'
            onClick={addNewProperty}
          >
            Add Property
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
