import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { Loading } from '../../component/loading/Loading';
import Product from '../../modules/Product';
import {
  Button,
  FormControl,
  Typography,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import Auth from '../../modules/Auth';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Header from '../../component/header/Header';
import AddNewProduct from '../../component/addNewProduct/AddNewProduct';
import ProductDatagridComponent from '../../component/productDatagridComponent/ProductDatagridComponent';
import Categories from '../../modules/Categories';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import AllPermissions from '../../modules/AllPermissions';
import ErrorDialog from '../../component/ErrorDialog/ErrorDialog';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function Products() {
  // assigning useLocation hook to a variable
  let location = useLocation();
  const [productsList, setProductsList] = useState([]);
  // const [addProductData, setAddProductData] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [datagridLoader, setDatagridLoader] = React.useState(false);
  const [addOpenProductDialog, setaddOpenProductDialog] = React.useState(false);
  // for both opening delete dialog and storing id of product to be deleted
  const [categoryData, setCategoryData] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState('');
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [pageNumber, setPageNumber] = React.useState(0);

  useEffect(() => {
    getCategoryByStoreId();
  }, []);

  const getCategoryByStoreId = () => {
    Categories.getCategoryByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setCategoryData(response.data);
        let defaultCategory;
        // checking if location state is null or undefined
        if (location.state === null || location.state === undefined) {
          // finding category with default cat true
          defaultCategory = response.data.find(
            item => item.defaultCat === true
          );
        } else {
          // assingning category id with category id of double clicked category
          defaultCategory = location.state;
        }

        // setCategoryId(defaultCategory.categoryId);
        getAllProductsList(defaultCategory.categoryId, 0);
      } else {
        setCategoryData([]);
      }
    });
  };

  const getAllProductsList = (categoryId, pageNum) => {
    setDatagridLoader(true);
    setCategoryId(categoryId);
    Product.getProductByCategoryId(categoryId, pageNum, 10, response => {
      if (response.status === 'success') {
        let filteredProducts = response.data.filter(item => {
          return !item.deleted;
        });

        setProductsList(filteredProducts);

        setLoader(false);
        setDatagridLoader(false);
      } else {
        setLoader(false);
        setDatagridLoader(false);
        setProductsList([]);
      }
    });
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const closeAddNewProductDialog = () => {
    setaddOpenProductDialog(false);
  };

  const handlcategoryDropdownOnChange = event => {
    setCategoryId(event.target.value);
    getAllProductsList(event.target.value, 0);
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '95%',
          height: 600,
          // mt: 12,
          ml: 8,
          mt: 8,

          '& .super-app-theme--header': {
            backgroundColor: '#e1ebfa',

            fontSize: '18px',
          },
        }}
      >
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item md={6} sm={3} xs={6}>
                <BreadCrumb pageName='Products' />
              </Grid>
              <Grid
                md={6}
                sm={6}
                xs={0}
                item
                sx={{
                  display: 'flex',
                  // flexDirection: { xs: 'column', sm: 'row' },
                  marginLeft: 'auto',
                  // mr: 2,

                  justifyContent: 'right',
                }}
              >
                <Typography
                  variant='subtitle1'
                  sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}
                >
                  Change Category
                </Typography>
                <FormControl
                  sx={{
                    pl: 1,
                    display: { md: 'block', sm: 'block', xs: 'block' },
                  }}
                >
                  <Select
                    size='small'
                    defaultValue={categoryId !== '' && categoryId}
                    value={categoryId !== '' && categoryId}
                    onChange={event => {
                      handlcategoryDropdownOnChange(event);
                    }}
                    sx={{
                      backgroundColor: '#3B78C9',
                      color: '#fff',
                      borderTop: '5px solid #3B78C9',
                      height: '27px',
                      width: '146px',
                      fontSize: '14px',
                      overflow: 'none',
                      whiteSpace: 'nowrap',
                    }}
                    displayEmpty
                    name='categories'
                    placeholder='Select'
                  >
                    <MenuItem disabled value={undefined}>
                      <em style={{ color: 'gray', padding: 0 }}>Select</em>
                    </MenuItem>
                    {categoryData?.map(item => {
                      return (
                        <MenuItem key={item.categoryId} value={item.categoryId}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                {AllPermissions.getUserAccessiblePermissions('Product') !==
                  undefined &&
                AllPermissions.getUserAccessiblePermissions('Product').write ===
                  false ? null : (
                  <Grid item md={3} sm={3} xs={6}>
                    <Tooltip title='Add Product' placement='bottom'>
                      <Button
                        variant='contained'
                        onClick={() => setaddOpenProductDialog(true)}
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          bgcolor: '#3B78C9',
                          float: 'right',
                          width: { md: '126px', sm: '120', xs: '120px' },
                          height: '27px',
                        }}
                      >
                        {' '}
                        <Typography> Add Product</Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                &nbsp;&nbsp; &nbsp;&nbsp;
              </Grid>
            </Grid>
            {datagridLoader ? (
              <Loading />
            ) : (
              <ProductDatagridComponent
                productsList={productsList}
                getUpdatedProductsList={getAllProductsList}
                selectedCategoryId={categoryId}
                fromCatagoryComponent={false}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                snackbar={openUpstoreSnackBar}
              />
            )}
          </>
        )}
      </Box>

      {addOpenProductDialog ? (
        // Dialog for adding product
        <AddNewProduct
          openDialog={addOpenProductDialog}
          closeDialog={closeAddNewProductDialog}
          snackbar={openUpstoreSnackBar}
          selectedCategoryId={categoryId}
          GetUpdatedProductsList={getAllProductsList}
        />
      ) : null}

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
      {AllPermissions.getUserAccessiblePermissions('Product') !== undefined &&
      AllPermissions.getUserAccessiblePermissions('Product').read === false ? (
        <ErrorDialog></ErrorDialog>
      ) : null}
    </>
  );
}

export default Products;
