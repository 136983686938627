import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import Header from '../../component/header/Header';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';

export default function Configuration() {
  const buttonStyles = {
    mt: 1,
    p: 0,
    ml: 1,
    mr: 2,
    textTransform: 'capitalize',
    width: '126px',
    height: '27px',
    ml: 'auto',
    color: '#696969',
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Header />
      <Grid sx={{ ml: 7, mt: 7, pl: 1, pr: 1 }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>General Settings</Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button variant='outlined' sx={buttonStyles}>
              Expand
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
              feugiat. Aliquam eget maximus est, id dignissim quam.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>Payment Settings</Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button sx={buttonStyles}>Expand</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>Invoice Settings</Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button sx={buttonStyles}>Expand</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>Bill Settings</Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button sx={buttonStyles}>Expand</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>SMS Settings</Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button sx={buttonStyles}>Expand</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary>
            <Box>
              <Typography variant='BUTTON TEXT'>
                Notification Settings
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>helo</Typography>
            </Box>

            <Button sx={buttonStyles}>Expand</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
}
