import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Header from '../header/Header';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddNewProperty from '../addNewProperty/AddNewProperty';
import UpstoreProperties from '../../modules/UpstoreProperties';
import Auth from '../../modules/Auth';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';
import BreadCrumb from '../breadcrumd/BreadCrumb';
import { Loading } from '../loading/Loading';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='Properties'
      hidden={value !== index}
      id={`Properties-${index}`}
      aria-labelledby={`Properties-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Properties() {
  const [value, setValue] = React.useState(0);
  const [defaultPropertiesData, setDefaultPropertiesData] = React.useState([]);

  const [updatePropertiesData, setUpdatePropertiesData] = React.useState([]);

  const [customPropertiesData, setCustomPropertiesData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    getDefaultProperties(0);
  }, []);
  const handleInputchangeCallback = (name, value, index, row) => {
    let updatedData = { ...defaultPropertiesData };
    updatedData[index][name] = value;

    setUpdatePropertiesData(updatedData);
  };
  const handleCustomchangeCallback = (name, value, index, row) => {
    let updatedData = { ...customPropertiesData };
    updatedData[index][name] = value;

    setUpdatePropertiesData(updatedData);
  };

  const getDefaultProperties = ind => {
    let id = ind === 0 ? Auth.getStoreId() : -1;
    setLoader(true);
    UpstoreProperties.getUpstoreProperties(id, true, response => {
      if (response.status === 'success') {
        setLoader(false);
        if (ind === 0) {
          setCustomPropertiesData(response.data);
        } else {
          setDefaultPropertiesData(response.data);
        }
      } else {
        setCustomPropertiesData([]);
        setDefaultPropertiesData([]);
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getDefaultProperties(newValue);
  };

  const duplicateData = customPropertiesData.filter(value =>
    defaultPropertiesData.some(
      oneElement => oneElement.property === value.property
    )
  );

  return (
    <>
      <Header pageName='Properties' />
      {loader ? (
        <Loading />
      ) : (
        <Box sx={{ width: '98%', ml: 7, mt: 8 }}>
          <Grid item md={6} sm={3} xs={6} sx={{ ml: 2 }}>
            <BreadCrumb pageName='Properties' />
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='Properties'>
              <Tab label='Custom Properties' {...a11yProps(0)} />
              <Tab label='Default Properties' {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} key={0} index={0}>
            <PropertiesDataGrid
              key={value}
              show={true}
              data={customPropertiesData}
              handleInputchangeCallback={handleCustomchangeCallback}
              getDefaultProperties={getDefaultProperties}
              filteredProperties={duplicateData}
              value={value}
              setValue={setValue}
            />
          </TabPanel>

          <TabPanel value={value} key={1} index={1}>
            <PropertiesDataGrid
              key={value}
              show={false}
              data={defaultPropertiesData}
              handleInputchangeCallback={handleInputchangeCallback}
              getDefaultProperties={getDefaultProperties}
              filteredProperties={duplicateData}
              value={value}
              setValue={setValue}
            />
          </TabPanel>
        </Box>
      )}
    </>
  );
}

const PropertiesDataGrid = ({
  show,
  data,
  handleInputchangeCallback,
  getDefaultProperties,
  filteredProperties,
  value,
  setValue,
}) => {
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleOpenDialog = () => {
    setOpenAddDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
  };

  const UpadteProperty = id => {
    let filteredData = data?.filter(item => item.id === id)[0];
    if (filteredData.storeId === '-1') {
      delete filteredData.id;
      filteredData.storeId = Auth.getStoreId();
      UpstoreProperties.addUpstoreProperty(filteredData, response => {
        if (response.status === 'success') {
          typeof response.data === 'object'
            ? openUpstoreSnackBar(
                'property Added to Custom Sucessfully',
                'success'
              )
            : openUpstoreSnackBar(
                'property Already in Added In Custom',
                'error'
              );
          setValue(0);
          getDefaultProperties(0);
        } else {
          openUpstoreSnackBar('error while saving to custom ', 'error');
        }
      });
    } else {
      UpstoreProperties.updateUpstoreProperty(
        filteredData.id,
        filteredData,
        response => {
          if (response.status === 'success') {
            openUpstoreSnackBar('property Updated Sucessfully', 'success');
            getDefaultProperties(0);
          } else {
            openUpstoreSnackBar('property  not Added Sucessfully', 'error');
          }
        }
      );
    }
  };

  return (
    <>
      <Box sx={{ pl: 0, pr: 3, pb: 3 }}>
        <TableContainer component={Paper}>
          {show ? (
            <Button
              sx={{
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                color: '#ffff',
                mr: 1,
                mt: 1,
                mb: 0.5,
                backgroundColor: '#3B78C9',
                float: 'right',
              }}
              onClick={handleOpenDialog}
              variant='contained'
            >
              Add New
            </Button>
          ) : null}
          <Table sx={{ minWidth: 500 }}>
            <TableHead sx={{ backgroundColor: 'secondary.light' }}>
              <TableRow>
                <TableCell align='left'>Property Name</TableCell>
                <TableCell align='center'>Property value</TableCell>
                <TableCell align='center'>Description</TableCell>
                <TableCell align='center'>Read Only</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    <TextField
                      variant='standard'
                      type='text'
                      style={{ height: '30px', p: 2 }}
                      name='property'
                      placeholder='Enter Property Name'
                      value={row.property}
                      onChange={event =>
                        handleInputchangeCallback(
                          event.target.name,
                          event.target.value,
                          index,
                          row
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <TextField
                      variant='standard'
                      value={row?.propertyValue}
                      type='text'
                      placeholder='Enter Property Value'
                      style={{ height: '30px', p: 2 }}
                      name='propertyValue'
                      onChange={event =>
                        handleInputchangeCallback(
                          event.target.name,
                          event.target.value,
                          index,
                          row
                        )
                      }
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <TextField
                      variant='standard'
                      type='text'
                      value={row?.propertyDescription}
                      placeholder='Enter Description'
                      style={{ height: '30px', p: 2 }}
                      name='propertyDescription'
                      onChange={event =>
                        handleInputchangeCallback(
                          event.target.name,
                          event.target.value,
                          index,
                          row
                        )
                      }
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <TextField
                      // type='text'
                      size='small'
                      select
                      variant='standard'
                      placeholder='Select Read Only '
                      style={{ height: '30px', p: 1, width: '50%' }}
                      name='readOnly'
                      disabled={value === 0 && row.readOnly}
                      value={row?.readOnly}
                      onChange={event =>
                        handleInputchangeCallback(
                          event.target.name,
                          event.target.value,
                          index,
                          row
                        )
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}> False</MenuItem>
                    </TextField>
                  </TableCell>

                  <TableCell align='center'>
                    {filteredProperties?.find(
                      element => element?.property === row?.property
                    ) && value === 1 ? (
                      <Button
                        variant='contained'
                        sx={{
                          backgroundColor: '#3B78C9',
                          textTransform: 'capitalize',
                          p: 0,
                          color: 'white',
                          mr: 1,
                          width: '100px',
                          height: '27px',
                        }}
                        onClick={() =>
                          openUpstoreSnackBar(
                            'Property Already Present In Custom ',
                            'error'
                          )
                        }
                      >
                        <Typography sx={{ fontSize: '12px' }} noWrap>
                          Saved
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        disabled={value === 0 && row.readOnly}
                        sx={{
                          backgroundColor: '#3B78C9',
                          textTransform: 'capitalize',
                          p: 0,

                          color: 'white',
                          mr: 1,
                          width: '100px',
                          height: '27px',
                        }}
                        onClick={event => UpadteProperty(row?.id)}
                      >
                        {row.storeId === '-1' ? (
                          <Typography sx={{ fontSize: '12px' }} noWrap>
                            Save as Custom
                          </Typography>
                        ) : (
                          'Update'
                        )}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <AddNewProperty
        open={openAddDialog}
        close={handleCloseDialog}
        snackbar={openUpstoreSnackBar}
        getDefaultProperties={getDefaultProperties}
      />
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
};
