import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function WarningDialog({
  openDialog,
  content,
  handleAgreeOrDisagreeClick,
  HeaderText,
}) {
  // sending callback to parent with users choice
  const handleClose = userChoice => {
    handleAgreeOrDisagreeClick(userChoice);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('no')}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle textAlign='center'>
          {HeaderText !== '' ? HeaderText : ' Warning Alert!!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => handleClose('no')}
          >
            Disagree
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', width: '126px', height: '27px' }}
            onClick={() => handleClose('yes')}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
