import UpstoreApi from '../UpstoreApi';
import Validation from '../Validation';

class Product {
  constructor() {
    this.allStoreUnits = JSON.parse(localStorage.getItem('allUnitsList')) || [];
  }

  getAllProducts = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/products/all',
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while getting products', error.message);
        callBack({ status: 'error' });
      });
  };

  getProductByCategoryId = (categoryId, pageNum, pageSize, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/products/category/${categoryId}?pageNo=${pageNum}&pageSize=${pageSize} `,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting products by category id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getProductByStoreId = (storeId, pageNumber, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/products/store/' + storeId + '?pageNumber=' + pageNumber,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting products by store Id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  setAllUnitsList = unitsData => {
    this.allStoreUnits = [...unitsData];
    localStorage.setItem('allUnitsList', JSON.stringify(unitsData));
  };

  getAllUnits = callBack => {
    let storeUnitsListFromCache = this.getStoreUnitsListFromCache();
    if (Validation.isBlankObjectNot(storeUnitsListFromCache)) {
      callBack({ status: 'success', data: storeUnitsListFromCache });
    } else {
      UpstoreApi({
        method: 'GET',
        url: '/units/all',
      })
        .then(response => {
          if (response.data) {
            this.setAllUnitsList([...response.data]);
            callBack({ status: 'success', data: response.data });
          }
        })
        .catch(error => {
          console.log('Error occured while getting units ', error.message);
          callBack({ status: 'error' });
        });
    }
  };

  getStoreUnitsListFromCache = () => {
    if (Validation.isBlankObjectNot(this.allStoreUnits)) {
      return [...this.allStoreUnits];
    }

    let storeUnitsListFromCache = localStorage.getItem('allUnitsList');

    if (Validation.isBlankObjectNot(storeUnitsListFromCache)) {
      let storeUnitsList = JSON.parse(storeUnitsListFromCache);
      this.allStoreUnits = [...storeUnitsListFromCache];
      return storeUnitsList;
    } else {
      return null;
    }
  };

  generateBarCodeByItemId = (itemNumber, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/productItems/generate-barcode/' + itemNumber,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting barcode by productitem number',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getProductByProductId = (productId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/products/' + productId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting products by product Id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  addNewProduct = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/products',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding new product', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  updateProduct = (productId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/products/update-product/' + productId,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while updating product', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteProduct = (productId, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/products/delete-product/' + productId,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Deleting product failed', error.message);
        callback({ status: 'error' });
      });
  };

  // product items

  getAllProducItems = callBack => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/all',
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while getting product items', error.message);
        callBack({ status: 'error' });
      });
  };

  getProductItemByProductItemId = (productItemId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/' + productItemId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting product item by product item Id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getProductItemsByProductId = (productId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/getByProductId/' + productId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting product items by productId',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  addNewProductItem = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: '/productItems',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while adding new product Item',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  updateProductItem = (productItemId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/productItems/update-productItem/' + productItemId,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while updating product Item', error.message);
        callBack({ status: 'error', data: error.response.status });
      });
  };

  deleteProductItem = (itemNumber, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/productItems/delete-productItem/' + itemNumber,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Deleting product Item failed', error.message);
        callback({ status: 'error' });
      });
  };
  searchProductsByName = (searchVal, storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/products/search-product/store/' + storeId + '?search=' + searchVal,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Searching product Item failed', error.message);
        callback({ status: 'error' });
      });
  };

  getDistinctPacking = (storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/getDistinctPacking/' + storeId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting distinct packing by store id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getProductIemsByProductIds = (storeId, data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `products/getProductByProductIds/${storeId}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting product by productIdssss',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getDistinctQuality = (storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/getDistinctQuality/' + storeId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting distinct quality by store id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  getDefaultPackages = (storeId, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: '/productItems/getDistinctQuality/' + storeId,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting default package by store id',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  upgradeItemToProduct = (data, callBack) => {
    UpstoreApi({
      method: 'POST',
      url: `/productItems/upgrade-Item-To-Product`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while Updating Item to Product ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getItemByStoreIdAndBarcode = (storeId, barcode, callBack) => {
    UpstoreApi({
      method: 'GET',
      url: `/productItems/get-barcode/${storeId}/and/${barcode}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occured while getting product item', error.message);
        callBack({ status: 'error' });
      });
  };
}

export default new Product();
