import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Divider,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Auth from '../../modules/Auth';
import './commentSection.css';
import Product from '../../modules/Product';

export default class CommentsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentMessage: '',
      comments: [],
      replyShow: { open: false, id: '' },
      disableButton: false,
      disableReplyButton: false,
    };
  }

  componentDidMount() {
    if (this.props.commentsData) {
      this.setState({
        comments: JSON.parse(this.props?.commentsData),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commentsData !== this.props.commentsData) {
      this.setState({
        comments: JSON.parse(this.props?.commentsData),
      });
    }
  }

  replyButton = (event, index) => {
    this.setState({
      replyShow: { open: !this.state.replyShow.open, id: index },
    });
  };

  addComment = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ disableButton: true });

    let lastItem = this.state.comments[0];
    const commentObj = {
      name: Auth.getUserName(),
      message: this.state.commentMessage,
      date: new Date().toLocaleDateString(),
      totalCount: lastItem === undefined ? 1 : ++lastItem.totalCount,
    };

    const updatedComments = [commentObj, ...this.state.comments];

    const data = {
      productId: this.props.productId,
      comments: JSON.stringify(updatedComments),
    };
    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Comment added successfully', 'success');
        this.setState({ commentMessage: '', disableButton: false });
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Comment not added successfully', 'error');
        this.setState({ disableButton: false });
      }
    });
  };

  addReply = (comment) => {
    this.setState({ disableReplyButton: true });

    if (comment.reply != undefined) {
      comment.reply.unshift({
        replyName: Auth.getUserName(),
        replyMessage: this.state.replyMessage,
        date: new Date().toLocaleDateString(),
      });
    } else {
      comment.reply = [
        {
          replyName: Auth.getUserName(),
          replyMessage: this.state.replyMessage,
          date: new Date().toLocaleDateString(),
        },
      ];
    }

    let updatedComments = [...this.state.comments];
    let replyIndex = updatedComments.findIndex(
      (c) => c.message === comment.message
    );
    updatedComments[replyIndex] = comment;

    const data = {
      productId: this.props.productId,
      comments: JSON.stringify(updatedComments),
    };

    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Reply added successfully', 'success');
        this.setState({
          replyMessage: '',
          disableReplyButton: false,
          replyShow: { open: !this.state.replyShow.open, id: '' },
        });
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Reply not added successfully', 'error');
        this.setState({
          disableReplyButton: false,
        });
      }
    });
  };

  removeComment = (event, commentMessage, commentId) => {
    event.preventDefault();
    event.stopPropagation();

    const splicedComments = this.state.comments.filter(
      (comment, id) => id !== commentId || comment.message !== commentMessage
    );

    const data = {
      productId: this.props.productId,
      comments: JSON.stringify(splicedComments),
    };

    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Comment deleted successfully', 'success');
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Comment not deleted successfully', 'error');
      }
    });
  };

  removeReply = (event, replyMessage, replyId, comment, commentId) => {
    event.preventDefault();
    event.stopPropagation();

    let updatedComments = [...this.state.comments];
    let replyIndex = updatedComments.findIndex(
      (c) => c.message === comment.message
    );

    let parentComment = updatedComments[replyIndex];

    const splicedReply = parentComment?.reply.filter(
      (r, id) => id !== replyId || r.replyMessage !== replyMessage
    );

    parentComment = { ...parentComment, reply: splicedReply };

    const splicedComments = this.state.comments.filter(
      (comm, id) => id !== commentId || comm.message !== comment.message
    );

    const data = {
      productId: this.props.productId,
      comments: JSON.stringify(splicedComments),
    };

    Product.updateProduct(this.props?.productId, data, (response) => {
      if (response.status === 'success') {
        this.props?.openSnackbar('Reply deleted successfully', 'success');
        this.props.getLatestData();
      } else {
        this.props?.openSnackbar('Reply not deleted successfully', 'error');
      }
    });
  };

  render() {
    return (
      <>
        <Box className='box-container'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id='outlined-multiline-static'
                  label='Add Comment'
                  value={this.state.commentMessage}
                  multiline
                  rows={2}
                  autoFocus
                  endIcon={<AddCommentIcon />}
                  fullWidth
                  variant='outlined'
                  onChange={(event) => {
                    this.setState({ commentMessage: event.target.value });
                  }}
                />
              </Grid>
              <Box textAlign={'right'} width={'100%'}>
                <Button
                  className='post-button'
                  variant='contained'
                  size='small'
                  fullWidth
                  disabled={this.state.disableButton}
                  onClick={this.addComment}
                >
                  Post
                </Button>
              </Box>

              <Grid item xs={12}>
                <List>
                  {this.state?.comments && this.state?.comments?.length > 0 ? (
                    this.state?.comments.map((comment, index) => (
                      <div key={index}>
                        <ListItem alignItems='flex-start' className='list-item'>
                          <Grid container>
                            <Grid item xs={2} md={1}>
                              <ListItemAvatar>
                                <Avatar alt='Remy Sharp' src='' />
                              </ListItemAvatar>
                            </Grid>
                            <Grid item xs={9} md={10}>
                              <ListItemText
                                primary={comment?.name}
                                secondary={
                                  <div style={{ textAlign: 'left' }}>
                                    <Box component='div' display='inline'>
                                      <Typography
                                        style={{
                                          color: 'black',
                                          width: '300px',
                                          wordWrap: 'break-word',
                                        }}
                                        component='p'
                                        variant='caption'
                                        align='left'
                                      >
                                        {comment?.message}
                                      </Typography>
                                    </Box>
                                    <span style={{ marginRight: '5px' }}>
                                      {comment?.date
                                        ? comment?.date.slice(0, 25)
                                        : null}
                                    </span>
                                    <Button
                                      className='send-reply-button'
                                      variant='normal'
                                      size='small'
                                      onClick={(event) =>
                                        this.replyButton(event, index)
                                      }
                                    >
                                      Reply
                                    </Button>
                                    {this.state?.replyShow.open &&
                                    this.state.replyShow.id === index ? (
                                      <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                      >
                                        <Grid item xs={8}>
                                          <TextField
                                            id='outlined-multiline-static'
                                            multiline
                                            rows={1}
                                            fullWidth
                                            label='Add reply'
                                            autoFocus
                                            variant='outlined'
                                            onChange={(event) => {
                                              this.setState({
                                                replyMessage:
                                                  event.target.value,
                                              });
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Button
                                            className='send-reply-button'
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            disabled={
                                              this.state.disableReplyButton
                                            }
                                            onClick={() =>
                                              this.addReply(comment)
                                            }
                                          >
                                            send
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    ) : null}
                                  </div>
                                }
                              />
                            </Grid>

                            <Grid item xs={1} md={1} alignSelf='center'>
                              <Tooltip title='Delete comment' placement='left'>
                                <DeleteForeverIcon
                                  sx={{
                                    color: '#ef0d0dfc',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(event) =>
                                    this.removeComment(
                                      event,
                                      comment.message,
                                      index
                                    )
                                  }
                                />
                              </Tooltip>
                              {/* </Button> */}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Box>
                          {comment?.reply
                            ? comment?.reply?.map((reply, replyIndex) => (
                                <>
                                  <Grid container>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2} md={1}>
                                      <ListItemAvatar>
                                        <Avatar alt='Remy Sharp' src='' />
                                      </ListItemAvatar>
                                    </Grid>
                                    <Grid item xs={6} md={7}>
                                      <ListItemText
                                        primary={
                                          <div
                                            style={{
                                              textAlign: 'left',
                                            }}
                                          >
                                            <Box
                                              component='div'
                                              display='inline'
                                            >
                                              {reply?.replyName}
                                            </Box>
                                          </div>
                                        }
                                        secondary={
                                          <div
                                            style={{
                                              textAlign: 'left',
                                            }}
                                          >
                                            <Box
                                              component='div'
                                              display='inline'
                                            >
                                              <Typography
                                                style={{
                                                  color: 'black',
                                                  width: '100%',
                                                  wordWrap: 'break-word',
                                                }}
                                                component='p'
                                                variant='caption'
                                                align='left'
                                              >
                                                {reply?.replyMessage}
                                              </Typography>
                                            </Box>
                                            <Box
                                              component='div'
                                              display='inline'
                                            >
                                              {reply?.date != null
                                                ? reply?.date.slice(0, 25)
                                                : null}
                                            </Box>
                                          </div>
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={3} md={3} alignSelf='center'>
                                      <Tooltip
                                        title='Delete reply'
                                        placement='left'
                                      >
                                        <DeleteForeverIcon
                                          sx={{
                                            color: '#ef0d0dfc',
                                            cursor: 'pointer',
                                          }}
                                          onClick={(event) =>
                                            this.removeReply(
                                              event,
                                              reply.replyMessage,
                                              replyIndex,
                                              comment,
                                              index
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </>
                              ))
                            : null}
                          <Divider variant='inset' component='li' />
                          <br />
                        </Box>
                      </div>
                    ))
                  ) : (
                    <Box className='no-comments-box'>
                      <Typography variant='subtitle1' color='primary'>
                        No Comments Present
                      </Typography>
                    </Box>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}
