import React from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Divider,
  FormHelperText,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Product from '../../modules/Product';
import SelectProductITemDropdown from '../selectProductItemDropdown/SelectProductItemDropdown';
import Validation from '../../modules/Validation';

import ProductItemCarousel from '../productItemCarousel/ProductItemCarousel';
import { serverConfig } from '../../config/server-config';
import AllPermissions from '../../modules/AllPermissions';

import Store from '../../modules/Store';
import WarningDialog from '../../component/warningDialog/WarningDialog';
import PrintBarcode from '../printBarcode/PrintBarcode';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductItemDetails = props => {
  const [unitsData, setUnitsData] = React.useState([]);
  const [productItem, setProductItem] = React.useState('');
  const [dialogWarning, setDialogWarning] = React.useState(false);
  // creating state variables for for unit and available
  const [productItemDetailsData, setProductItemDetailsData] = React.useState(
    {}
  );
  const [source, setSource] = React.useState('');
  const [showScanner, setShowScanner] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [showBtn, setShowBtn] = React.useState(true);
  const [barcodeSource, setBarcodeSource] = React.useState(false);
  const [copies, setCopies] = React.useState(false);
  const [values, setValues] = React.useState('');

  // for custom styling
  const textField = {
    width: '100%',
    bgcolor: 'secondary.light',
    borderTop: '5px solid #E1EEF5',
  };

  let componentRef = React.useRef(null);
  let copiesRef = React.useRef('');

  React.useEffect(() => {
    Product.getAllUnits(response => {
      if (response.status === 'success') {
        setUnitsData(response.data);
      } else {
        setUnitsData([]);
      }
    });
  }, []);

  const handleBarcodeInput = e => {
    if (e.key === 'Enter') {
      // setBarCodeData(event.target.value);
      barCode(e.target.value);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
  });
  // getting currently selected item form item dropdown
  const handleGetCurrentlySelectedItem = item => {
    // handling unit and available separately
    setProductItemDetailsData({
      ...productItemDetailsData,
      itemAvailable: item?.itemAvailable,
      unit: item?.unit,
      itemNumber: item?.itemNumber,
      barcode: item?.barcode,
      price: item?.storePrice,
    });

    // setting dynamicaaly values of fields using setValue from useForm hook
    for (let i in item) {
      let newItem = item;

      setValue(i, newItem[i]);
    }

    //when barcode is available for item
    if (item?.barcode) {
      Product.generateBarCodeByItemId(item?.itemNumber, response => {
        if (response.status === 'success') {
          setSource(`data:image/png;base64,${response.data}`);
          setBarcodeSource(true);
          setShowBtn(false);
        } else {
          setSource(null);
          setBarcodeSource(false);
          setShowBtn(true);
        }
      });
    } else {
      setSource(null);
      setBarcodeSource(false);
      setShowBtn(true);
    }
  };

  // onChange for controlabble inputs
  const handleOnChange = event => {
    let { name, value } = event.target;

    setProductItemDetailsData({ ...productItemDetailsData, [name]: value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const generateBarcode = () => {
    Product.generateBarCodeByItemId(
      productItemDetailsData?.itemNumber,
      response => {
        if (response.status === 'success') {
          setSource(`data:image/png;base64,${response.data}`);
          setBarcodeSource(true);
          setShowBtn(false);
          props.snackbar('Barcode generated  successfully', 'success');
        } else {
          setSource(null);
        }
      }
    );
  };
  const handleAgreeOrDisagreeClick = usersChoice => {
    if (usersChoice === 'yes') {
      handleDeleteProductItem();
      setDialogWarning(false);
    } else {
      setDialogWarning(false);
    }
  };
  const barCode = value => {
    // setData(value);

    setShowScanner(false);
    // let barcodeNumber = value.substring(0, value.length - 1);
    let data = {
      barcode: value,
    };
    Product.updateProductItem(
      productItemDetailsData.itemNumber,
      data,
      response => {
        if (response.status === 'success') {
          handleGetCurrentlySelectedItem(response.data);
          // props.getProductByProductId(response.data.productId, false);
          props.snackbar('Barcode data added successfully', 'success');
          props.setButtonSubmitted(true);
        } else if (response.data == 409) {
          props.snackbar('Duplicate entry for this barcode', 'error');
          setData(null);
        } else {
          props.snackbar('Please try again', 'error');
        }
      }
    );
  };
  const handleDeleteProductItem = () => {
    Product.deleteProductItem(productItemDetailsData?.itemNumber, response => {
      if (response.status === 'success') {
        props.snackbar('Product Item deleted suuccessfully', 'success');
        props.getProductByProductId(
          props.productItemDetails[0]?.productId,
          true
        );
      } else {
        props.snackbar('Product Item not deleted suuccessfully', 'error');
      }
    });
  };

  const handleUpdateProdutITemDetails = (data, e) => {
    props.setButtonSubmitted(true);
    // setting item available and units data by their onchange values
    data.itemAvailable = productItemDetailsData.itemAvailable;
    data.unit = productItemDetailsData.unit;
    data.itemDescription = data.itemDescription;
    data.packing = data.packing;
    Product.updateProductItem(data.itemNumber, data, response => {
      if (response.status === 'success') {
        props.getProductByProductId(response.data.productId, false);
        props.snackbar('item details updated successfully', 'success');
        props.setButtonSubmitted(true);
      } else {
        props.snackbar('details not updated successfully', 'error');
      }
    });
  };
  setTimeout(function () {
    props.setButtonSubmitted(false);
  }, 7000);
  const upgradeItemToProduct = () => {
    Product.upgradeItemToProduct(props.productItemDetails[0], response => {
      if (response.status === 'success') {
        props.snackbar('Item Upgraded to Product Sucessfully', 'success');
        props.getProductByProductId(
          props.productItemDetails[0]?.productId,
          false
        );
      } else {
        props.snackbar(
          'error Occured While Upgrading item to Product ',
          'error'
        );
      }
    });
  };

  const handleClose = () => {
    setCopies(false);
  };
  const handleCopiesDialog = () => {
    setValues(copiesRef.current.value);
    if (copiesRef.current.value !== '') {
      setCopies(true);
    } else {
      props.snackbar('Enter number of copies to print', 'error');
    }
  };
  const openDialog = () => {
    setDialogWarning(true);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Typography variant='subtitle1'>Select Product Items</Typography>
      </Box>

      <Box
        sx={{
          mb: 1,
          mt: 2,
          ml: 'none',
          overflowY: 'hidden',

          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 30,
          width: '98%',
          borderRadius: '3px',
          bgcolor: 'secondary.light',
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 530,
            fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
          }}
          sx={{ pl: 1 }}
        >
          Item Name
        </ListItemText>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 530,
            fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
          }}
          sx={{
            ml: { md: 0, sm: 0, xs: 0 },
            display: { xs: 'block', md: 'block' },
          }}
        >
          Category Name
        </ListItemText>
        {Store.companyDetails?.businessType !== 'STORE' && (
          <ListItemText
            primaryTypographyProps={{
              fontWeight: 530,
              fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
            }}
            sx={{
              ml: { md: 0, sm: 0, xs: 8 },

              display: { xs: 'none', md: 'block' },
            }}
          >
            M.R.P
          </ListItemText>
        )}
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 530,
            fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
          }}
          sx={{
            ml: { md: 0, sm: 0, xs: 2 },

            display: { xs: 'block', md: 'block' },
          }}
        >
          Unit
        </ListItemText>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 530,
            fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
          }}
          sx={{
            ml: { md: 0, sm: 0, xs: 6 },

            display: { xs: 'none', md: 'block' },
          }}
        >
          {Store.companyDetails?.businessType !== 'STORE'
            ? ' Store Price'
            : 'M.R.P'}
        </ListItemText>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 530,
            fontSize: { xs: '11px', md: 'medium', sm: 'medium' },
          }}
          sx={{
            ml: { md: 0, sm: 0, xs: 2 },
            display: { xs: 'block', md: 'block' },
          }}
        >
          Discount
        </ListItemText>
      </Box>

      <SelectProductITemDropdown
        key={
          props?.productItemDetails[0]?.productId ||
          productItemDetailsData.itemNumber
        }
        getProductByProductId={props.getProductByProductId}
        dropdownData={props.productItemDetails.filter(item => !item.deleted)}
        returnSelectedItemCallback={handleGetCurrentlySelectedItem}
        sx={{ color: 'secondary.light' }}
      />

      {/* key to select default productItem */}

      <Divider sx={{ mt: 3, mb: 2 }} />
      <Box
        sx={{
          width: '300px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* {showBtn ? ( */}
        <Button
          variant='contained'
          sx={{
            marginLeft: productItemDetailsData.barcode != null ? '8px' : null,
            width: '126px',
            height: '27px',
            p: 1,
            textTransform: 'capitalize',
            backgroundColor: '#3B78C9',
          }}
          onClick={() => {
            setShowScanner(true);
          }}
        >
          <Typography
            sx={{
              fontSize:
                productItemDetailsData.barcode != null ? '12px' : '15px',
            }}
          >
            {productItemDetailsData.barcode != null
              ? 'Update Barcode'
              : 'Add Barcode'}
          </Typography>
        </Button>
        {/* ) : null} */}
        {!productItemDetailsData?.barcode && showBtn ? (
          <>
            <Button
              variant='contained'
              sx={{
                width: '150px',
                height: '27px',
                p: 1,
                textTransform: 'capitalize',
                backgroundColor: '#3B78C9',
              }}
              onClick={() => generateBarcode()}
            >
              <Typography sx={{ fontSize: '15px' }}>
                Generate Barcode{' '}
              </Typography>
            </Button>
          </>
        ) : null}
      </Box>

      {showScanner && (
        <Dialog
          open={handleOpen}
          onClose={() => {
            setShowScanner(false);
          }}
        >
          <DialogTitle>Add Barcode</DialogTitle>
          <DialogContent>
            <input type='text' autoFocus onKeyDown={handleBarcodeInput}></input>
          </DialogContent>
        </Dialog>
      )}

      <Box sx={{ flexGrow: 1, p: 1 }} key={`${Math.random()}`}>
        {' '}
        {barcodeSource && source ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                id='divToPrint'
                ref={el => (componentRef = el)}
                key={`${Math.random()}`}
                src={`${source}`}
                alt='BarCode'
                style={{
                  height: '50px',
                  width: '135px',
                  objectFit: 'contain',
                }}
              />

              <small
                style={{
                  fontSize: '10px',
                  marginLeft: '28px',
                }}
              >
                {productItemDetailsData?.barcode}
              </small>
            </div>
            <TextField
              inputRef={copiesRef}
              variant='outlined'
              size='small'
              placeholder='No. of Copies'
              type='text'
              sx={{
                mt: 1,
                width: '130px',
              }}
            />
            <Button
              variant='contained'
              sx={{
                width: '100px',
                height: '27px',
                p: 1,
                textTransform: 'capitalize',
                m: 1,
              }}
              onClick={handleCopiesDialog}
            >
              Print
            </Button>
          </>
        ) : null}
      </Box>
      <form noValidate onSubmit={handleSubmit(handleUpdateProdutITemDetails)}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={2}>
            <Grid item md={5} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>Product Item Name</Typography>
                  <TextField
                    {...register('itemName', {
                      required: 'Item name is required.',
                    })}
                    sx={textField}
                    size='small'
                    id='itemName'
                    placeholder='Enter Item Name'
                    variant='outlined'
                    name='itemName'
                    error={Boolean(errors.itemName)}
                    helperText={errors.itemName?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>Brand</Typography>
                  <TextField
                    sx={textField}
                    {...register('brand')}
                    id='brand'
                    size='small'
                    placeholder='Enter Brand Name'
                    variant='outlined'
                    name='brand'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>Barcode</Typography>
                  <TextField
                    disabled
                    sx={textField}
                    {...register('barcode')}
                    id='barcode'
                    size='small'
                    placeholder='Enter Barcode'
                    variant='outlined'
                    name='barcode'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Available</Typography>
                      <FormControl sx={textField}>
                        <Select
                          value={
                            Validation.isBlankObjectKeyNot(
                              productItemDetailsData,
                              'itemAvailable'
                            )
                              ? productItemDetailsData.itemAvailable
                              : ''
                          }
                          size='small'
                          // defaultValue='true'
                          name='itemAvailable'
                          onChange={handleOnChange}
                          // {...register('itemAvailable', {
                          //   required: 'Available is required.',
                          // })}
                          displayEmpty
                          placeholder='Select'
                          error={Boolean(errors.itemAvailable)}

                          //   inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem disabled value={undefined}>
                            <em style={{ color: 'gray' }}>Select</em>
                          </MenuItem>
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: '#c22a1f' }}>
                          {errors.itemAvailable?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {Store.companyDetails?.businessType !== 'STORE' && (
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>M.R.P</Typography>
                        <TextField
                          {...register('mrp')}
                          sx={textField}
                          id='mrp'
                          size='small'
                          placeholder='Enter Mrp'
                          variant='outlined'
                          name='mrp'
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Unit</Typography>
                      <FormControl sx={textField}>
                        <Select
                          {...register('unit', {
                            required: 'Unit is required',
                          })}
                          value={
                            Validation.isBlankObjectKeyNot(
                              productItemDetailsData,
                              'unit'
                            )
                              ? productItemDetailsData.unit
                              : ''
                          }
                          size='small'
                          displayEmpty
                          // defaultValue=""
                          placeholder='Select'
                          name='unit'
                          onChange={handleOnChange}
                          id='unit'
                          error={Boolean(errors.unit)}
                          MenuProps={MenuProps}
                        >
                          <MenuItem disabled value={undefined}>
                            <em style={{ color: 'gray' }}>Select</em>
                          </MenuItem>
                          {unitsData?.map(item => {
                            return (
                              <MenuItem key={item.id} value={item.unit}>
                                {`${item.unitName}-${item.unit}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText sx={{ color: '#c22a1f' }}>
                          {errors.unit?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        {' '}
                        {Store.companyDetails?.businessType !== 'STORE'
                          ? 'Store Price'
                          : 'MRP'}
                      </Typography>
                      <TextField
                        {...register('storePrice', {
                          required:
                            Store.companyDetails?.businessType !== 'STORE'
                              ? 'Store Price  is required'
                              : 'M.R.P  is required',

                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: 'Please enter a number',
                          },
                        })}
                        sx={textField}
                        id='storePrice'
                        size='small'
                        placeholder={
                          Store.companyDetails?.businessType !== 'STORE'
                            ? 'Store Price'
                            : 'MRP'
                        }
                        variant='outlined'
                        name='storePrice'
                        error={Boolean(errors.storePrice)}
                        helperText={errors.storePrice?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Quantity</Typography>
                      <TextField
                        {...register('quantity', {
                          required: 'Quantity is required',
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: 'Please enter a number',
                          },
                        })}
                        sx={textField}
                        id='quantity'
                        size='small'
                        placeholder='Enter Quantity '
                        variant='outlined'
                        name='quantity'
                        error={Boolean(errors.quantity)}
                        helperText={errors.quantity?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Base Price</Typography>
                      <TextField
                        sx={textField}
                        {...register('basePrice')}
                        id='basePrice'
                        placeholder='Enter Price'
                        size='small'
                        variant='outlined'
                        name='basePrice'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Stock</Typography>
                      <TextField
                        {...register('stock', {
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: 'Please enter a number',
                          },
                        })}
                        sx={textField}
                        id='stock'
                        size='small'
                        placeholder='Enter Stock'
                        variant='outlined'
                        name='stock'
                        error={Boolean(errors.stock)}
                        helperText={errors.stock?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Discount</Typography>
                      <TextField
                        {...register('discount')}
                        sx={textField}
                        id='discount'
                        size='small'
                        placeholder='Enter Discount'
                        variant='outlined'
                        name='discount'
                        error={Boolean(errors.discount)}
                        helperText={errors.discount?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Base Price Discount
                      </Typography>
                      <TextField
                        {...register('basePriceDiscount')}
                        sx={textField}
                        id='basePriceDiscount'
                        size='small'
                        placeholder='Enter Base Price Discount'
                        variant='outlined'
                        name='basePriceDiscount'
                        error={Boolean(errors.basePriceDiscount)}
                        helperText={errors.basePriceDiscount?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Stock Threshold
                      </Typography>
                      <TextField
                        {...register('threshold')}
                        sx={textField}
                        id='threshold'
                        type='number'
                        size='small'
                        placeholder='Enter stock Threshhold'
                        variant='outlined'
                        name='threshold'
                        error={Boolean(errors.threshold)}
                        helperText={errors.threshold?.message}
                      />
                    </Grid>
                    {/* {serverConfig.isUpstoreCloud && (
                      <Grid item xs={6}>
                        <Typography variant='subtitle1'>Package</Typography>
                        <TextField
                          {...register('packing')}
                          sx={textField}
                          id='packing'
                          type='text'
                          size='small'
                          placeholder='Enter package'
                          variant='outlined'
                          name='packing'
                          error={Boolean(errors.packing)}
                          helperText={errors.packing?.message}
                        />
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  {serverConfig?.isUpstoreCloud &&
                  props?.subscriptionEnabled ? (
                    <Box sx={{ pr: { sm: 7 }, pb: { sm: 7 }, pl: { sm: 7 } }}>
                      <ProductItemCarousel
                        productDetailsData={productItemDetailsData}
                        key={productItemDetailsData?.itemNumber}
                        snackbar={props.snackbar}
                      />
                    </Box>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: {
                      md:
                        serverConfig.isUpstoreCloud &&
                        props?.subscriptionEnabled
                          ? -7
                          : 0,
                      sm:
                        serverConfig.isUpstoreCloud &&
                        props?.subscriptionEnabled
                          ? -7
                          : 0,
                    },
                    ml: { sm: 7 },
                    mr: { sm: 7 },
                  }}
                >
                  <Typography variant='subtitle1'>Description</Typography>
                  <TextField
                    sx={textField}
                    {...register('itemDescription')}
                    multiline
                    rows={3}
                    id='itemDescription'
                    size='small'
                    placeholder='Enter Description'
                    variant='outlined'
                    name='itemDescription'
                    inputProps={{ maxLength: 45 }}
                    onChange={event => {
                      setProductItem(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {AllPermissions.getUserAccessiblePermissions('Product') !== undefined &&
        AllPermissions.getUserAccessiblePermissions('Product').write ===
          false ? null : (
          <Box
            display='flex'
            sx={{ mt: 2, mb: 2, mr: 1 }}
            justifyContent='flex-end'
          >
            <Button
              variant='contained'
              disabled={!productItemDetailsData.itemNumber}
              onClick={() => {
                upgradeItemToProduct();
              }}
              sx={{
                width: '140px',
                height: '27px',
                p: 1,
                textTransform: 'capitalize',
                mr: '5px',
              }}
            >
              <Typography sx={{ fontSize: '13px' }} noWrap>
                Upgrade to Product
              </Typography>
            </Button>
            <Button
              variant='contained'
              disabled={props?.submitted || !productItemDetailsData?.itemNumber}
              type='submit'
              sx={{
                width: '126px',
                height: '27px',
                p: 1,
                textTransform: 'capitalize',
                backgroundColor: '#3B78C9',
              }}
            >
              <Typography sx={{ fontSize: '15px' }}>Save Details</Typography>
            </Button>
            {'  '}
            <Button
              variant='contained'
              disabled={!productItemDetailsData.itemNumber}
              onClick={openDialog}
              sx={{
                width: '126px',
                height: '27px',
                p: 1,
                textTransform: 'capitalize',
                marginLeft: '5px',
              }}
            >
              <Typography sx={{ fontSize: '15px' }}>Delete Item</Typography>
            </Button>
          </Box>
        )}
      </form>

      {dialogWarning ? (
        <WarningDialog
          handleAgreeOrDisagreeClick={handleAgreeOrDisagreeClick}
          openDialog={dialogWarning}
          content=' Are you sure you want to delete the product item'
        />
      ) : null}
      {copies ? (
        <PrintBarcode
          handleOpen={handleOpen}
          handleClose={handleClose}
          values={values}
          source={source}
          barcodeValue={productItemDetailsData?.barcode}
          price={productItemDetailsData?.price}
        />
      ) : null}
    </>
  );
};
export default ProductItemDetails;
