import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import EditIcon from '@mui/icons-material/Edit';
import Daybook from '../../modules/Daybook';
import Auth from '../../modules/Auth';
import DatePickerComp from '../../component/datePicker/DatePicker';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import DayBookEntryDialog from '../../component/dayBookEntryDialog.js/dayBookEntryDialog';
import moment from 'moment';
import { Loading } from '../../component/loading/Loading';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../../component/deleteDialog/DeleteDialog';
import Store from '../../modules/Store';
let obj = { cashInHandStart: 0, cashInHandEnd: 0 };
export default function DayBook() {
  const [ledgerData, setLedgerData] = React.useState([]);
  const [entryDialog, setEntryDialog] = React.useState({
    open: false,
    data: {},
  });
  const [addCashInHAnd, setAddCashInHand] = React.useState(obj);
  const [cashBookDate, setCashBookDate] = React.useState(new Date());
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [loader, setLoader] = React.useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    open: false,
    idOfDeletableEntry: '',
  });
  const [bankDetails, setBankDetails] = React.useState([]);

  React.useEffect(() => {
    getAllEntries(cashBookDate);
    getdayEntries();
    getAccountDetails();
  }, []);

  const onDateChange = newValue => {
    setCashBookDate(newValue);
    getAllEntries(newValue);
    getdayEntries(newValue);
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const getdayEntries = cashBookDate => {
    setLoader(true);
    let istDate = moment(cashBookDate).format('YYYY-MM-DD');
    Daybook.getDaybookEntry(Auth.getStoreId(), istDate, response => {
      if (response.status === 'success') {
        setAddCashInHand(response.data);
        setLoader(false);
      } else {
        console.log('error while getting entries');
        setLoader(false);
      }
    });
  };
  const getAccountDetails = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setBankDetails(response.data);
      } else {
        setBankDetails([]);
      }
    });
  };
  const columns = [
    {
      field: 'sno',
      headerName: 'S.No',
      width: 90,

      renderCell: params => <Typography>{params.row.sno} </Typography>,
    },
    {
      field: 'receiptNumber',
      headerName: 'R.NO',
      type: 'number',
      width: 90,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>
          {params.row.receiptNumber ? params.row?.receiptNumber : '-'}
        </Typography>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>{params.row.name ? params.row?.name : '-'}</Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>
          {params.row.description ? params.row?.description : '-'}{' '}
        </Typography>
      ),
    },
    {
      field: 'credit',
      headerName: 'Credit',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>{params.row.credit ? params.row?.credit : '-'} </Typography>
      ),
    },
    {
      field: 'debit',
      headerName: 'Debit',
      type: 'number',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>{params.row.debit ? params.row?.debit : '-'} </Typography>
      ),
    },
    {
      field: 'bank',
      headerName: 'Bank',
      type: 'text',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Typography>{params.row?.bank ? params?.row?.bank : '-'} </Typography>
      ),
    },
    {
      field: 'cashType',
      headerName: 'Cash Type',
      type: 'text',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Typography>{params?.row?.cashType}</Typography>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'action',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              {params?.row?.cashType === 'Manual' &&
              dateCheck(cashBookDate?.toISOString().slice(0, 10)) ? (
                <Tooltip title='Edit Entry' placement='bottom'>
                  <EditIcon
                    onClick={() => {
                      setEntryDialog({
                        ...entryDialog,
                        open: true,
                        data: params.row,
                      });
                    }}
                    sx={{ color: '#3B78C9' }}
                  />
                </Tooltip>
              ) : null}
              {params.row.cashType === 'Manual' &&
              dateCheck(cashBookDate.toISOString().slice(0, 10)) ? (
                <Tooltip title='Edit Entry' placement='bottom'>
                  <DeleteIcon
                    onClick={() => {
                      setOpenDeleteDialog({
                        open: true,
                        idOfDeletableEntry: params.row.id,
                      });
                    }}
                    sx={{ color: '#3B78C9' }}
                  />
                </Tooltip>
              ) : null}
              {params.row.deleted ? (
                <Tooltip title='Deleted Entry' placement='bottom'>
                  <DeleteIcon sx={{ color: 'red', fontSize: '20px' }} />
                </Tooltip>
              ) : null}
            </>
          </Box>
        </>
      ),
    },
  ];
  const addCash = () => {
    if (
      addCashInHAnd.cashInHandStart !== 0 &&
      addCashInHAnd.cashInHandStart !== undefined
    ) {
      let data = {
        cashInHandStart: addCashInHAnd.cashInHandStart,
        storeId: Auth.getStoreId(),
        cashBookDate: new Date(),
      };
      Daybook.addDayBookEntry(data, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('entry added sucessfully', 'success');
          getEntriesByDate();
        } else {
          openUpstoreSnackBar('error while  adding ', 'error');
        }
      });
    } else {
      openUpstoreSnackBar('please fill Cash in hand  ', 'error');
    }
  };
  const getAllEntries = cashBookDate => {
    let istDate = moment(cashBookDate).format('YYYY-MM-DD');
    Daybook.getAllDaybookEntries(Auth.getStoreId(), istDate, response => {
      if (response.status === 'success') {
        let newData = response.data.map((item, index) => ({
          ...item,
          sno: index + 1,
        }));
        setLedgerData(newData);
      } else {
        console.log('error while getting entries');
      }
    });
  };
  const UpdateCashInHand = () => {
    if (
      addCashInHAnd.cashInHandStart !== 0 &&
      addCashInHAnd.cashInHandStart !== ''
    ) {
      Daybook.UpdateDayEntry(addCashInHAnd.id, addCashInHAnd, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar('entry Updated sucessfully', 'success');
          getEntriesByDate();
        } else {
          openUpstoreSnackBar('error while  Updating cash in hand ', 'error');
        }
      });
    } else {
      openUpstoreSnackBar('please fill Cash in hand  ', 'error');
    }
  };
  const getEntriesByDate = () => {
    getAllEntries(cashBookDate);
    getdayEntries(cashBookDate);
  };
  const dateCheck = date => {
    if (date === new Date().toISOString().slice(0, 10)) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteEntryCallback = usersChoice => {
    if (usersChoice === true) {
      Daybook.deleteCashbookEntryByid(
        openDeleteDialog.idOfDeletableEntry,
        response => {
          if (response.status === 'success') {
            openUpstoreSnackBar('Entry was deleted successfully', 'success');
            setOpenDeleteDialog({ ...openDeleteDialog, open: false });
            getEntriesByDate();
          } else {
            openUpstoreSnackBar('There is an issue in deleting Entry', 'error');
          }
        }
      );
    }
  };

  return (
    <Box sx={{ mt: 8, ml: 6 }}>
      <Header />
      {loader ? (
        <Loading />
      ) : (
        <>
          <Box
            item
            md={12}
            xs={12}
            sx={{
              p: 1,
              ml: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <BreadCrumb pageName='Day Book' />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '180px',
              }}
            >
              <Box>
                <Typography variant='subtitle' fontSize='16px' noWrap>
                  Get Entry By Date
                </Typography>
                <DatePickerComp
                  value={cashBookDate}
                  maxDate={new Date()}
                  dateChange={onDateChange}
                  size='small'
                />
              </Box>
              {/* <Box>
                <Button
                  variant='contained'
                  sx={{
                    p: 0,
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    color: 'white',
                    bgcolor: '#3B78C9',
                  }}
                  onClick={() => getEntriesByDate()}
                >
                  <Typography variant='subtitle' fontSize='12px' noWrap>
                    Get Entry By Date
                  </Typography>
                </Button>
              </Box> */}
            </Box>
          </Box>
          <Grid
            item
            container
            md={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ml: 3,
              p: 1,
            }}
          >
            <Box sx={{ display: 'flex' }} md={12} xs={6}>
              <Typography variant='subtitle' sx={{ mr: 1 }}>
                Cash In Hand
              </Typography>{' '}
              :
              <TextField
                variant='standard'
                name='cashInHand'
                size='small'
                type='number'
                value={addCashInHAnd.cashInHandStart}
                onChange={e => {
                  setAddCashInHand({
                    ...addCashInHAnd,
                    cashInHandStart: e.target.value,
                  });
                }}
              />
              {addCashInHAnd?.id &&
              dateCheck(cashBookDate.toISOString().slice(0, 10)) ? (
                <Button
                  variant='contained'
                  sx={{
                    p: 0,
                    ml: 2,
                    textTransform: 'capitalize',
                    width: '150px',
                    height: '27px',
                    color: 'white',
                    bgcolor: '#3B78C9',
                  }}
                  onClick={() => UpdateCashInHand()}
                >
                  <Typography variant='subtitle' fontSize='12px' noWrap>
                    Update Cash In Hand
                  </Typography>
                </Button>
              ) : (
                <Button
                  variant='contained'
                  disabled={
                    dateCheck(cashBookDate.toISOString().slice(0, 10)) === false
                  }
                  sx={{
                    p: 0,
                    ml: 2,
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    color: 'white',
                    bgcolor: '#3B78C9',
                  }}
                  onClick={addCash}
                >
                  <Typography variant='subtitle' fontSize='12px' noWrap>
                    Add Cash In Hand
                  </Typography>
                </Button>
              )}
            </Box>
            <Box sx={{ display: 'flex' }} md={12} xs={6}>
              <Typography variant='subtitle' sx={{ mr: 1 }}>
                Cash In Hand End
              </Typography>{' '}
              :
              <TextField
                variant='standard'
                name='cashInHandEnd'
                size='small'
                value={
                  addCashInHAnd.cashInHandEnd ? addCashInHAnd.cashInHandEnd : 0
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Box>
                {dateCheck(cashBookDate.toISOString().slice(0, 10)) ? (
                  <Button
                    variant='contained'
                    sx={{
                      p: 0,
                      textTransform: 'capitalize',
                      width: '126px',
                      height: '27px',
                      color: 'white',
                      bgcolor: '#3B78C9',
                      mr: 3,
                    }}
                    onClick={() =>
                      setEntryDialog({
                        ...entryDialog,
                        open: true,
                        data: {
                          description: '',
                          credit: '',
                          debit: '',
                          bankId: '',
                          cashType: '',
                        },
                      })
                    }
                  >
                    <Typography variant='subtitle' fontSize='12px' noWrap>
                      {' '}
                      Add New Entry
                    </Typography>
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: 'flex', ml: 2, justifyContent: 'space-between' }}
            >
              <Box sx={{ width: '100%', height: 570 }}>
                <DataGrid
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  sx={{
                    boxShadow: 2,
                    border: 6,
                    backgroundColor: '#fff',
                    borderColor: '#eee',
                    color: 'black',
                    '& .MuiDataGrid-cell': {
                      outline: 'none',
                      fontSize: '16px',
                      fontWeight: '25px',
                    },
                    '& .MuiDataGrid-cell:hover': {
                      outline: 'none',
                      color: '#21211f',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },

                    '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                      color: 'black',
                    },
                  }}
                  density='compact'
                  rows={ledgerData}
                  columns={columns}
                  // components={{
                  //   Footer: CustomFooterStatusComponent,
                  // }}
                  hideFooter
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {upstoreSnackBar.open && (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      )}
      {entryDialog.open ? (
        <DayBookEntryDialog
          entryDialog={entryDialog.open}
          closeDialog={() => setEntryDialog({ ...entryDialog, open: false })}
          data={entryDialog.data}
          bankData={bankDetails}
          snackBar={openUpstoreSnackBar}
          getAllEntries={getEntriesByDate}
        />
      ) : null}
      {openDeleteDialog.open ? (
        <DeleteDialog
          openDialog={openDeleteDialog.open}
          closeDialogCallback={() =>
            setOpenDeleteDialog({
              open: false,
              idOfDeletableEntry: '',
            })
          }
          deleteCallback={handleDeleteEntryCallback}
          dialogContent='Your Entry will be deleted permanently , Are you sure you want to delete it ?'
        />
      ) : null}
    </Box>
  );
}
