import React from 'react';
import { TextField, TableRow, TableCell, Tooltip } from '@mui/material';
import Validation from '../../modules/Validation';
import './style.css';
import Autocomplete from '@mui/material/Autocomplete';
import Auth from '../../modules/Auth';
import PurchaseOrders from '../../modules/PurchaseOrders';
import { Lock } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

function MandiChalanItems(props) {
  const [rowData, setRowData] = React.useState(props?.row);

  React.useEffect(() => {
    amountCalculation(props?.row);
  }, [props.groverFreightDetails]);
  const addVariatiesByStoreId = (type, value) => {
    let data = {
      storeId: Auth.getStoreId(),
      type: type,
      variety: value,
    };

    PurchaseOrders.addVariatyByStoreId(data, response => {
      if (response.status === 'success') {
        props.getAllVariatiesByType(type);
        props.snackbar(`${type} is added successfully`, 'success');
      } else {
        console.log('error while getting varities');
      }
    });
  };

  const amountCalculation = item => {
    const found = props?.groverFreightDetails?.find(
      element => element?.pack === props?.row?.pack
    );

    if (found && item?.itemNumber === props?.row?.itemNumber) {
      if (rowData.actualWeight) {
        let calselfAmount = parseFloat(
          Number(found?.selfAmount) * Number(rowData.actualWeight)
        ).toFixed(1);
        let calTotalFreight = parseFloat(
          Number(found?.freight) * Number(rowData.actualWeight)
        ).toFixed(1);
        let calTotalLabour = parseFloat(
          Number(found?.labour) * Number(rowData.actualWeight)
        ).toFixed(1);
        setRowData({
          ...rowData,
          selfAmount: calselfAmount,
          freight: calTotalFreight,
          labour: calTotalLabour,
        });
      } else if (rowData.actualQuantity && props.type === 'chalan') {
        let calselfAmount = parseFloat(
          Number(found?.selfAmount) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        let calTotalFreight = parseFloat(
          Number(found?.freight) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        let calTotalLabour = parseFloat(
          Number(found?.labour) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        setRowData({
          ...rowData,
          selfAmount: calselfAmount,
          freight: calTotalFreight,
          labour: calTotalLabour,
        });
      } else if (props.type === 'watak' && props.status === 'Drafted') {
        let calselfAmount = parseFloat(
          Number(found?.selfAmount) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        let calTotalFreight = parseFloat(
          Number(found?.freight) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        let calTotalLabour = parseFloat(
          Number(found?.labour) * Number(rowData?.actualQuantity)
        ).toFixed(1);
        setRowData({
          ...rowData,
          selfAmount: calselfAmount,
          freight: calTotalFreight,
          labour: calTotalLabour,
        });
      }
    }
    // } else {
    //   setRowData({ ...rowData, selfAmount: 0, freight: 0, labour: 0 });
    // }
  };
  const handleOnBlur = event => {
    event.preventDefault();
    let updatedData = rowData;
    let { name, value } = event.target;
    if (name === 'price') {
      if (updatedData.actualWeight) {
        let price = value;
        updatedData.amount = parseFloat(price * updatedData?.actualWeight);
      } else {
        let price = value;
        updatedData.amount = parseFloat(price * updatedData?.actualQuantity);
      }
    } else if (name === 'commission') {
      if (updatedData.actualWeight) {
        updatedData.amount = parseFloat(
          updatedData?.price * updatedData?.actualWeight
        );
      } else {
        updatedData.amount = parseFloat(
          updatedData.price * updatedData?.actualQuantity
        );
      }

      let newComm = value === '' ? 0 : value;
      updatedData.commission = newComm;
      // updatedData.amount = commissionCal(newComm, rowData.amount);
    }

    setRowData({
      ...rowData,
      amount: updatedData.amount,
      commission: updatedData.commission,
    });
  };

  React.useEffect(() => {
    props.AddChalanDetailsDataCallBack(rowData, props?.name);
  }, [rowData]);

  //for Quality pack And Variety change
  const handlePackingChange = newValue => {
    setRowData({ ...rowData, pack: newValue });
  };
  const handleQualityChange = newValue => {
    setRowData({ ...rowData, quality: newValue.value });
  };
  const handleVarietyChange = newValue => {
    setRowData({ ...rowData, variety: newValue.value });
  };

  return (
    <TableRow hover tabIndex={-1} sx={{ backgroundColor: '#fff' }}>
      <TableCell
        key={`itemName_${rowData.itemNumber}`}
        align='left'
        width={150}
      >
        <TextField
          id='itemName'
          disabled={props?.confirmChalan}
          sx={{ mt: 2, width: '170px' }}
          value={
            Validation.isBlankObjectKeyNot(rowData, 'itemName')
              ? rowData.itemName
              : null
          }
          onChange={event =>
            setRowData({ ...rowData, itemName: event.target.value })
          }
          placeholder='Item name'
          size='small'
          defaultValue=''
          variant='outlined'
          name='itemName'
        />
      </TableCell>
      <TableCell align={'center'} key={`packing${rowData.itemNumber}`}>
        <Autocomplete
          disabled={props?.confirmChalan}
          id='packing'
          options={props.packingData}
          sx={{ width: 150 }}
          onChange={(event, newValue) => {
            if (newValue === null) {
              handlePackingChange('');
            } else {
              handlePackingChange(newValue.value);
            }
          }}
          getOptionLabel={option =>
            typeof option.value == 'string' && option
              ? `${option.value} `
              : option
          }
          // getOptionLabel={option =>
          //   typeof option == 'string' && option
          //     ? (option && `${option} `) || {}
          //     : ''
          // }
          value={rowData?.pack}
          renderOption={(props, option) => {
            return (
              <>
                <div {...props} key={option.id}>
                  {`${option.value}`}
                </div>
              </>
            );
          }}
          renderInput={params => (
            <TextField
              name='packing'
              id=' packing'
              size='small'
              placeholder='Enter packing'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  let value = e.target.value;
                  addVariatiesByStoreId('packing', value);
                }
              }}
              {...params}
              onClick={() => {
                if (props.packingData.length === 0)
                  props.getAllVariatiesByType('packing');
              }}
              variant='standard'
            />
          )}
        />
      </TableCell>

      {props.type === 'watak' ? (
        <>
          <TableCell align={'center'} key={`quality_${rowData.itemNumber}`}>
            <Autocomplete
              id='quality'
              disabled={props?.confirmChalan}
              options={props.qualityData}
              sx={{ width: 150 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  handleQualityChange('');
                } else {
                  handleQualityChange(newValue);
                }
              }}
              getOptionLabel={option =>
                typeof option.value == 'string' && option
                  ? `${option.value} `
                  : option
              }
              // getOptionLabel={
              //   typeof option == 'string' && option
              //     ? (option && `${option} `) || {}
              //     : ''
              // }
              value={rowData?.quality}
              renderOption={(props, option) => {
                return (
                  <>
                    <div {...props} key={option.id}>
                      {`${option.value}`}
                    </div>
                  </>
                );
              }}
              renderInput={params => (
                <TextField
                  size='small'
                  placeholder='Enter quality'
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      let value = e.target.value;
                      addVariatiesByStoreId('quality', value);
                    }
                  }}
                  {...params}
                  onClick={() => {
                    if (props.qualityData.length === 0)
                      props.getAllVariatiesByType('quality');
                  }}
                  variant='standard'
                />
              )}
            />
          </TableCell>
          <TableCell align={'center'} key={`variety_${rowData.itemNumber}`}>
            <Autocomplete
              disabled={props?.confirmChalan}
              id='variety'
              options={props.varietyData}
              sx={{ width: 150 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  handleVarietyChange('');
                } else {
                  handleVarietyChange(newValue);
                }
              }}
              getOptionLabel={option =>
                typeof option.value == 'string' && option
                  ? `${option.value} `
                  : option
              }
              // getOptionLabel={option =>
              //   typeof option == 'string' && option
              //     ? (option && `${option} `) || {}
              //     : ''
              // }
              value={rowData?.variety}
              renderOption={(props, option) => {
                return (
                  <>
                    <div {...props} key={option.id}>
                      {`${option.value}`}
                    </div>
                  </>
                );
              }}
              renderInput={params => (
                <TextField
                  size='small'
                  placeholder='Enter variety_'
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      let value = e.target.value;
                      addVariatiesByStoreId('variety', value);
                    }
                  }}
                  {...params}
                  variant='standard'
                  onClick={() => {
                    if (props.varietyData.length === 0)
                      props.getAllVariatiesByType('variety');
                  }}
                />
              )}
            />
          </TableCell>
        </>
      ) : null}
      <TableCell align={'left'} key={`quantity${rowData.itemNumber}`}>
        <TextField
          disabled={props?.confirmChalan}
          className='number__input'
          sx={{ width: '100px' }}
          id='quantity'
          size='small'
          defaultValue=''
          type='number'
          placeholder='Enter Quantity'
          type='text'
          value={
            Validation.isBlankObjectKeyNot(rowData, 'quantity')
              ? rowData?.quantity
              : ''
          }
          onChange={event =>
            setRowData({
              ...rowData,
              quantity: event.target.value,
            })
          }
          // onBlur={this.handleOnBlur}
          variant='standard'
          name='quantity'
        />
      </TableCell>
      <TableCell key={`actualQuantity_${rowData.itemNumber}`} align={'left'}>
        <TextField
          disabled={props?.confirmChalan}
          className='number__input'
          sx={{ width: '100px' }}
          id='actualQuantity'
          size='small'
          placeholder=' Enter Actual Quantity'
          defaultValue=''
          type='text'
          value={
            Validation.isBlankObjectKeyNot(rowData, 'actualQuantity')
              ? rowData?.actualQuantity
              : ''
          }
          onChange={event => {
            setRowData({
              ...rowData,
              actualQuantity: event.target.value,
            });
          }}
          onBlur={() => amountCalculation(rowData)}
          variant='standard'
          name='actualQuantity'
        />
      </TableCell>
      <TableCell key={`weight${rowData.itemNumber}`} align={'left'}>
        <TextField
          disabled={props?.confirmChalan}
          className='number__input'
          sx={{ width: '100px' }}
          id='weight'
          size='small'
          placeholder=' Enter  Weight'
          defaultValue=''
          type='text'
          value={
            Validation.isBlankObjectKeyNot(rowData, 'weight')
              ? rowData?.weight
              : ''
          }
          onChange={event => {
            setRowData({
              ...rowData,
              weight: event.target.value,
            });
          }}
          variant='standard'
          name='weight'
        />
      </TableCell>
      <TableCell key={`actualWeight${rowData.itemNumber}`} align={'left'}>
        <TextField
          disabled={props?.confirmChalan}
          className='number__input'
          sx={{ width: '100px' }}
          id='actualWeight'
          size='small'
          placeholder=' Enter Actual Weight'
          defaultValue=''
          type='text'
          value={
            Validation.isBlankObjectKeyNot(rowData, 'actualWeight')
              ? rowData?.actualWeight
              : ''
          }
          onChange={event => {
            setRowData({
              ...rowData,
              actualWeight: event.target.value,
            });
          }}
          variant='standard'
          name='actualWeight'
          onBlur={() => amountCalculation(rowData)}
        />
      </TableCell>

      <TableCell align={'left'} key={`labour_${rowData.itemNumber}`}>
        <TextField
          disabled={props.confirmChalan}
          className='number__input'
          id='labour'
          sx={{ mt: 2, width: '100px' }}
          value={
            Validation.isBlankObjectKeyNot(rowData, 'labour')
              ? rowData?.labour
              : ''
          }
          onChange={event =>
            setRowData({ ...rowData, labour: event.target.value })
          }
          placeholder='Enter labour'
          size='small'
          defaultValue=''
          type='number'
          variant='standard'
          name='freight'
        />
      </TableCell>

      <TableCell align={'left'} key={`freight_${rowData.itemNumber}`}>
        <TextField
          disabled={props.confirmChalan}
          className='number__input'
          id='freight'
          sx={{ mt: 2, width: '100px' }}
          value={
            Validation.isBlankObjectKeyNot(rowData, 'freight')
              ? rowData?.freight
              : ''
          }
          onChange={event =>
            setRowData({ ...rowData, freight: event.target.value })
          }
          placeholder='Enter freight'
          size='small'
          defaultValue=''
          type='number'
          variant='standard'
          name='freight'
        />
      </TableCell>

      <TableCell key={`selfAmount_${rowData.itemNumber}`} align={'left'}>
        <TextField
          disabled={props?.confirmChalan}
          className='number__input'
          sx={{ width: '100px' }}
          id='selfAmount'
          size='small'
          placeholder='Enter Self Amount'
          type='text'
          value={
            Validation.isBlankObjectKeyNot(rowData, 'selfAmount')
              ? rowData?.selfAmount
              : ''
          }
          onChange={event => {
            setRowData({
              ...rowData,
              selfAmount: event.target.value,
            });
          }}
          // onBlur={this.handleOnBlur}
          variant='standard'
          name='selfAmount'
        />
      </TableCell>

      {props.type === 'watak' ? (
        <>
          <TableCell key={`commission${rowData.itemNumber}`} align={'left'}>
            <TextField
              disabled={props?.confirmChalan || rowData.amount === null}
              className='number__input'
              sx={{ width: '100px' }}
              id='commission'
              size='small'
              placeholder=' Enter Commission'
              defaultValue=''
              type='number'
              value={
                Validation.isBlankObjectKeyNot(rowData, 'commission')
                  ? rowData?.commission
                  : ''
              }
              onChange={event => {
                setRowData({
                  ...rowData,
                  commission: event.target.value,
                });
              }}
              onBlur={handleOnBlur}
              variant='standard'
              name='commission'
            />
          </TableCell>
          <TableCell key={`price_${rowData.itemNumber}`} align={'left'}>
            <TextField
              disabled={props?.confirmChalan}
              className='number__input'
              sx={{ width: '100px' }}
              id='price'
              size='small'
              placeholder=' Enter Actual basePrice'
              defaultValue=''
              type='number'
              value={
                Validation.isBlankObjectKeyNot(rowData, 'price')
                  ? rowData?.price
                  : ''
              }
              onChange={event => {
                setRowData({
                  ...rowData,
                  price: event.target.value,
                });
              }}
              onBlur={handleOnBlur}
              variant='standard'
              name='price'
            />
          </TableCell>
          <TableCell key={`amount_${rowData.itemNumber}`} align={'left'}>
            <TextField
              disabled={true}
              className='number__input'
              sx={{ width: '100px' }}
              id='amount'
              size='small'
              placeholder=' Enter Actual amount'
              defaultValue=''
              type='text'
              value={
                Validation.isBlankObjectKeyNot(rowData, 'amount')
                  ? rowData?.amount
                  : ''
              }
              onChange={event => {
                setRowData({
                  ...rowData,
                  amount: event.target.value,
                });
              }}
              variant='standard'
              name='basePrice'
            />
          </TableCell>
        </>
      ) : null}
      {props.type === 'chalan' ? (
        <>
          <TableCell align={'left'} key={`delete_${rowData.itemNumber}`}>
            {props?.confirmChalan ? (
              <Lock sx={{ color: 'lightGray' }} />
            ) : (
              <>
                <Tooltip title='remove item' placement='bottom'>
                  <DeleteIcon
                    onClick={() => {
                      props.removeItemFromSelectionList(props.row, props.name);
                    }}
                    fontSize='small'
                    sx={{
                      color: 'error.main',
                      cursor: 'pointer',
                      ml: -2,
                      mr: 2,
                    }}
                  />
                </Tooltip>
              </>
            )}
          </TableCell>
        </>
      ) : null}
    </TableRow>
  );
}

export default MandiChalanItems;
