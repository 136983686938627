import Auth from '../Auth';
import Logger from '../Logger';
import UpstoreApi from '../UpstoreApi';

class Notifications {
  constructor() {}

  template = (recipient, status, link, subject, message, args) => {
    return {
      recipient,
      status,
      subject,
      link,
      subject,
      message: Logger.format(message, args),
    };
  };

  pullNotifications = (storeId, pageNum, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/notification/store-notification/${storeId}?pageNumber=${pageNum}`,
    })
      .then(response => {
        callback({ status: 'success', data: [...response.data] });
        return response.data;
      })
      .catch(error => {
        console.error('pullNotifications failed ' + error);
      });
  };
  changeNotificationStatus = ({ id, status }) => {
    UpstoreApi({
      method: 'PUT',
      url: `/notification/update/${id}`,
      data: {
        status: status,
      },
    }).catch(error => {
      console.error(error);
    });
  };
  pushNotification = (orderObj, status) => {
    let body = {
      message: status,
      status: 'NEW',
      userId: orderObj.customerId,
      sendTime: new Date(),
      notifyTime: new Date(),
      storeId: Auth.getStoreId(),
    };
    UpstoreApi({
      method: 'POST',
      url: '/notification/add',
      data: body,
    })
      .then(response => {
        window.location.reload(false);
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export default new Notifications();
