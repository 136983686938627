import React from 'react';
import { Typography, Grid, Box, ListItemText } from '@mui/material';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import BillTransaction from '../../modules/BillTransaction';
import { Loading } from '../loading/Loading';
import HTMLRenderer from 'react-html-renderer';

const CustomerOverview = ({ customerId }) => {
  const [transaction, setTransactions] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getBillTransaction();
  }, []);

  const getBillTransaction = () => {
    BillTransaction.getBillTransactionForCustomer(customerId, response => {
      if (response.status === 'success') {
        setTransactions(response.data);
        setLoader(false);
      } else {
        setTransactions([]);
        setLoader(false);
      }
    });
  };

  return (
    <>
      {loader ? (
        <Loading />
      ) : (
        <Grid container sx={{ alignItems: 'center' }}>
          {transaction.length > 0 ? (
            transaction?.map(item => {
              return (
                <>
                  <Grid
                    item
                    md={2}
                    sm={3}
                    xs={12}
                    sx={{
                      backgroundColor: 'secondary.light',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      varient='h5'
                      sx={{
                        fontSize: { md: '15px', sm: '12', xs: '10' },
                        p: 1,
                      }}
                    >
                      {/* {item.creationDate && item.creationDate.substring(0, 10)} */}
                      <ListItemText
                        primary={new Date(item.creationDate + 'Z')
                          .toString()
                          .substring(0, 15)}
                        secondary={new Date(item.creationDate + 'Z')
                          .toString()
                          .substring(16, 25)}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    sm={8}
                    xs={12}
                    sx={{ backgroundColor: '#fff', ml: 2, mt: 1 }}
                  >
                    <Typography
                      varient='h5'
                      sx={{ fontSize: '20px', pl: 2, pt: 1 }}
                    >
                      {item.title && item.title}
                    </Typography>
                    <Typography
                      varient='h5'
                      sx={{ fontSize: '14px', pl: 2, pb: 1 }}
                    >
                      {item.message && (
                        <HTMLRenderer html={item.message}></HTMLRenderer>
                      )}
                    </Typography>
                  </Grid>
                </>
              );
            })
          ) : (
            <Grid item md={12} sx={{ textAlign: 'center', color: '#3B78C9' }}>
              <Box>
                <Typography variant='h5' sx={{ pt: 5 }}>
                  No data found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomerOverview;
