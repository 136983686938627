import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@mui/icons-material/Print';
import { Typography, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Logo from '../../modules/Logo';
import './style.css';
import Validation from '../../modules/Validation';
import Store from '../../modules/Store';
import Auth from '../../modules/Auth';
import { serverConfig } from '../../config/server-config';
import logo from '../../assets/my-logo.png';

const ref = React.createRef();

class OpticalReciept extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerDetails: props.customerInfo,
      PrescData: props.testData,
      options: {
        unit: 'pt',
        format: [900, 300],
      },
      selectedTemplate: {},
      currency: null,
      logoUrl: null,
    };
  }
  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  componentDidMount() {
    this.getCurrency();
    setTimeout(() => {
      let opt = { ...this.state.options };
      let maxHeight = document.getElementById('divToPrint').clientHeight;
      let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
      opt.format = [maxHeight, maxWidth];
      // document.title = `receipt__${this.state.orderDetails.storeOrderNumber}.pdf`;
      this.setState({ options: opt });
    }, 5);
    this.getLogoUrl(Auth.getStoreId());
    this.getStoreById();
  }

  getStoreById = () => {
    Store.getStoreByStoreId(Auth?.getStoreId(), response => {
      if (response.status === 'success') {
        let parsed = JSON.parse(response?.data?.invoice);

        if (parsed) {
          this.setState({ selectedTemplate: parsed });
        }
      } else {
        console.log('error');
      }
    });
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',
                    fontSize: '16px',

                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon sx={{ color: 'white' }} /> Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
        </Typography>
        <br />
        <div className='divToDownload' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100%' }}
          >
            {Validation.isBlankNot(this.state.logoUrl) ? (
              <img
                className='invoice-bg'
                style={{ width: '100%', height: '100%' }}
                src={this.state.logoUrl}
                alt=''
              ></img>
            ) : (
              `Logo`
            )}
            <div className='invoice-content row d-flex justify-content-center'>
              <div className='col-12'>
                <div className='card'>
                  <header
                    style={{
                      backgroundImage: `url(${this.state.selectedTemplate?.bgImageHeader})`,
                      // backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  >
                    <div className='d-flex flex-row p-2'>
                      <div className='d-flex flex-column'>
                        {Validation.isBlankNot(this.state.logoUrl) ? (
                          <img
                            style={{ maxWidth: '100px', maxHeight: '50px' }}
                            src={this.state.logoUrl}
                            alt='Logo'
                          ></img>
                        ) : (
                          `Logo`
                        )}
                      </div>
                    </div>
                  </header>
                  <hr />
                  <div className='table-responsive p-2'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        <tr className='invoice_Hed'>
                          <th
                            style={{
                              color: this.state.selectedTemplate?.bgColor,
                            }}
                          >
                            Details
                          </th>
                        </tr>
                        <tr className='content'>
                          <td className='font-weight-bold'>
                            {`Name:  ${this.state.customerDetails?.customerName}`}
                            <br />
                            <br />
                            {`Address:     ${this.state.customerDetails?.address}`}
                            <br />
                            <br />
                            {`Contact Number:  ${this.state.customerDetails?.contact}`}
                            <br />
                            <br />
                            {`Email Id:     ${this.state.customerDetails?.email}`}
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='table-responsive p-2'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tr className='invoice_Hed'>
                        <th
                          style={{
                            color: this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          EYEGLASSES PRESCRIPTION:{' '}
                        </th>
                      </tr>
                    </table>
                  </div>
                  <hr />
                  <div className='products p-2'>
                    <table
                      className='table table-borderless'
                      style={{ borderRadius: '1px solid black' }}
                    >
                      <tbody>
                        <tr
                          className='add'
                          style={{
                            backgroundColor:
                              this.state.selectedTemplate?.bgColor,
                          }}
                        >
                          <th>Rx</th>
                          <th>SPH</th>
                          <th>CYL</th>
                          <th>AXIS</th>
                          <th>ADD</th>
                          <th>PRISM</th>
                          <th>VISION</th>
                          <th>PD</th>
                        </tr>
                        {this.state.PrescData !== undefined &&
                        this.state.PrescData !== null
                          ? this.state.PrescData.map(item => {
                              return (
                                <tr className='content ' key={item.id}>
                                  <td>{item.rx} </td>
                                  <td>{item.sph === null ? '_' : item.sph}</td>
                                  <td className='content'>
                                    {item.cyl === null ? '_' : item.cyl}
                                  </td>
                                  <td className='content'>
                                    {item.axis === null ? '_' : item.axis}
                                  </td>
                                  <td className='content'>
                                    {item.add === null ? '_' : item.add}
                                  </td>
                                  <td className='content'>
                                    {item.prism === null ? '_' : item.prism}
                                  </td>
                                  <td className='content'>
                                    {item.vision === null ? '_' : item.vision}
                                  </td>
                                  <td className='content'>
                                    {item.pd === null ? '_' : item.pd}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div
                    style={{
                      backgroundImage: `url(${this.state.selectedTemplate?.bgImageFooter})`,
                      backgroundSize: 'cover',
                      border: '2px solid gray',
                      display: 'flex',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className='table-responsive p-2'>
                      <table
                        className='table table-borderless'
                        style={{ flex: 'start' }}
                      >
                        <tr className='content'>
                          <td
                            className='font-weight-bold'
                            style={{ fontSize: '13px', fontWeight: '500' }}
                          >
                            This Prescription Is valid For a Duration of 12
                            Months From The Date Of issuance.
                            <br />
                            It is Recommended to get your eye test done every 12
                            Months
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OpticalReciept;
