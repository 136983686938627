import React from 'react';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Button } from '@mui/material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Customer from '../../modules/Customer';
import Auth from '../../modules/Auth';
import Box from '@mui/material/Box';
import Payments from '../../modules/Payments';
import Order from '../../modules/Order';
import * as moment from 'moment';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { Doughnut, Bar } from 'react-chartjs-2';
import Tooltip from '@mui/material/Tooltip';
import { ArcElement, LinearScale, CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import SalesChart from '../barcharDashboard/SalesChart';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Validation from '../../modules/Validation';
import { Key } from '@mui/icons-material';

Chart.register(ArcElement, CategoryScale, LinearScale);
const date = new Date();

function OutStandingBalance(props) {
  const propsStoreId =
    props.storeId !== undefined ? props.storeId : Auth.getStoreId();
  const [value, setValue] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [dashType, setDashType] = React.useState(
    !props?.userType === ''
      ? props?.userType
      : window.location.hash.endsWith('/customer')
      ? 'customer'
      : 'vendor'
  );
  const [value1, setValue1] = React.useState(new Date());
  const [currency, setCurrency] = React.useState('');
  const [balance, setBalance] = React.useState(null);
  const [totalSales, setTotalSales] = React.useState(null);
  const [payment, setPayments] = React.useState(null);
  const [orders, setOrders] = React.useState(null);
  const [saveddatepicker, setSaveddatepicker] = React.useState(false);
  const [chartDataResponseKey, setChartDataResponseKey] = React.useState();
  const [labelValue, setLabelValue] = React.useState('Weekly');

  const [chartDataResponseValue, setChartDataResponseValue] = React.useState();

  const [buttonSelected, setButtonSelected] = React.useState('Sales');

  const todaysDate = moment(date).format('YYYY-MM-DD');

  let monthlyStartDate = new Date(date.getFullYear(), date.getMonth(), 1);

  monthlyStartDate = moment(monthlyStartDate).format('YYYY-MM-DD');

  let yearsIstDay = new Date(date.getFullYear(), 0);

  yearsIstDay = moment(yearsIstDay).format('YYYY-MM-DD');
  React.useEffect(() => {
    if (window.location.hash.endsWith('/vendor')) {
      setDashType('vendor');
      getTotalVendorSalesDateTimePicker();
      getCustomerTotalBalance('vendor');
      getTotalPaymentsMonthly('vendor');
      getTotalVendorOrdersMonthly();
      getCurrency();
      getTotalSalesChartVendor('Weekly', monthlyStartDate, todaysDate);
    } else {
      setDashType('customer');
      getTotalSalesDateTimePicker();
      getCustomerTotalBalance('customer');
      getTotalPaymentsMonthly('customer');
      getTotalOrdersMonthly();
      getCurrency();
      getTotalSalesChart('Weekly', monthlyStartDate, todaysDate);
    }
  }, []);
  const data = {
    labels: [
      dashType === 'customer' ? 'Total Sales' : 'Purchase',
      ' Balance',
      'Payment',
    ],
    datasets: [
      {
        data: [totalSales, balance, payment],
        backgroundColor: ['blue', 'red', 'green'],
      },
    ],
  };

  let newStartDate = moment(value).format('YYYY-MM-DD');
  let newEndDate = moment(value1).format('YYYY-MM-DD');

  const getCustomerTotalBalance = dashType => {
    Customer.getTotalBalanceAmount(propsStoreId, dashType, response => {
      if (response.status === 'success') {
        setBalance(response.data);
      } else {
        console.log('error');
      }
    });
  };

  const getTotalPaymentsMonthly = dashType => {
    Payments.getTotalPaymentPriceByStoreId(
      propsStoreId,
      newStartDate,
      newEndDate,
      dashType,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setPayments(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };

  const getCurrency = () => {
    setCurrency(Store.getCurrency());
  };
  //get orders using date picker
  const getTotalOrdersMonthly = () => {
    Order.getTotalStoreOrdersByStoreId(
      propsStoreId,
      newStartDate,
      newEndDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setOrders(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };
  //get total purchase orders monthly

  const getTotalVendorOrdersMonthly = () => {
    Order.getTotalStoreVendorOrdersByStoreId(
      propsStoreId,
      newStartDate,
      newEndDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setOrders(response.data);
        } else {
          console.log('error');
        }
      }
    );
  };

  //get orders using date picker

  const getTotalSalesDateTimePicker = () => {
    getTotalOrdersMonthly();
    getTotalPaymentsMonthly(dashType);
    Order.getTotalStorePriceByStoreId(
      propsStoreId,
      newStartDate,
      newEndDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setTotalSales(response.data);
          setSaveddatepicker(false);
        } else {
          console.log('error');
          setSaveddatepicker(false);
        }
      }
    );
  };
  const getTotalVendorSalesDateTimePicker = () => {
    getTotalVendorOrdersMonthly();
    getTotalPaymentsMonthly(dashType);

    Order.getTotalPurchaseByStoreId(
      propsStoreId,
      newStartDate,
      newEndDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          setTotalSales(response.data);
          setSaveddatepicker(false);
        } else {
          console.log('error');
          setSaveddatepicker(false);
        }
      }
    );
  };
  //bargraph colors
  const color1 = 'blue';
  const color2 = 'green';
  const color3 = '#debe00';

  React.useEffect(() => {
    checkConditions(labelValue, buttonSelected);
  }, [labelValue]);
  //for Vendor Sales
  const getTotalSalesChartVendor = (event, monthlyStartDate, todaysDate) => {
    setChartDataResponseKey([]);
    setChartDataResponseValue([]);
    Order.getVendorTotalSalesChartData(
      propsStoreId,
      event,
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            let ArrayKey2 = arrayKeys.sort(function (a, b) {
              a = a.split('-').reverse().join('');
              b = b.split('-').reverse().join('');
              return b > a ? 1 : a < b ? -1 : 0;
              // return a.localeCompare(b);         // <-- alternative
            });

            setChartDataResponseKey(ArrayKey2);
            setChartDataResponseValue(Object.values(response.data));
          }
        } else {
          console.log('error');
        }
      }
    );
  };
  //for vendor Payments
  const getTotalPaymentChartDataVendor = (
    event,
    monthlyStartDate,
    todaysDate
  ) => {
    setChartDataResponseKey([]);
    setChartDataResponseValue([]);
    Order.getTotalVendorPaymentByStoreId(
      propsStoreId,
      event,
      monthlyStartDate,
      todaysDate,

      response => {
        if (
          response.status === 'success' &&
          Validation.isBlankObjectNot(response.data)
        ) {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            let ArrayKey2 = arrayKeys.sort(function (a, b) {
              a = a.split('-').reverse().join('');
              b = b.split('-').reverse().join('');
              return b > a ? 1 : a < b ? -1 : 0;
              // return a.localeCompare(b);         // <-- alternative
            });

            setChartDataResponseKey(ArrayKey2);
            setChartDataResponseValue(Object.values(response.data));
          }
        } else {
          console.log('error');
        }
      }
    );
  };
  //vendor Orders
  const getTotalOrdersChartDataVendor = (
    event,
    monthlyStartDate,
    todaysDate
  ) => {
    setChartDataResponseKey([]);
    setChartDataResponseValue([]);
    Order.getTotalVendorOrdersByStoreId(
      propsStoreId,
      event,
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (
          response.status === 'success' &&
          Validation.isBlankObjectNot(response.data)
        ) {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            let ArrayKey2 = arrayKeys.sort(function (a, b) {
              a = a.split('-').reverse().join('');
              b = b.split('-').reverse().join('');
              return b > a ? 1 : a < b ? -1 : 0;
              // return a.localeCompare(b);         // <-- alternative
            });

            setChartDataResponseKey(ArrayKey2);
            setChartDataResponseValue(Object.values(response.data));
          }
        } else {
          console.log('error');
        }
      }
    );
  };

  const getTotalSalesChart = (event, monthlyStartDate, todaysDate) => {
    Order.getTotalSalesChartData(
      event,
      propsStoreId,
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),

      response => {
        if (response.status === 'success') {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            const padToString = arrayKeys => {
              return String('0' + arrayKeys).slice(-2);
            };
            const sortByDate = (first, second) => {
              const firstPart = first.split('-'),
                secondPart = second.split('-');
              const a = firstPart[1] + padToString(firstPart[0]);
              const b = secondPart[1] + padToString(secondPart[0]);
              return a - b;
            };
            arrayKeys.sort(sortByDate);

            setChartDataResponseKey(arrayKeys);
            let arr = [];
            for (let index = 0; index < arrayKeys?.length; index++) {
              arr?.push(response?.data[arrayKeys[index]]);
            }

            setChartDataResponseValue(Object.values(arr));
          }
        } else {
          console.log('error');
        }
      }
    );
  };
  //get weekly bar chart for
  const getTotalPaymentChartData = (event, monthlyStartDate, todaysDate) => {
    setChartDataResponseKey([]);
    setChartDataResponseValue([]);
    Order.getTotalPaymentsChartData(
      event,
      propsStoreId,
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            const padToString = arrayKeys => {
              return String('0' + arrayKeys).slice(-2);
            };
            const sortByDate = (first, second) => {
              const firstPart = first.split('-'),
                secondPart = second.split('-');
              const a = firstPart[1] + padToString(firstPart[0]);
              const b = secondPart[1] + padToString(secondPart[0]);
              return a - b;
            };
            arrayKeys.sort(sortByDate);

            setChartDataResponseKey(arrayKeys);
            let arr = [];
            for (let index = 0; index < arrayKeys?.length; index++) {
              arr?.push(response?.data[arrayKeys[index]]);
            }

            setChartDataResponseValue(Object.values(arr));
          }
        } else {
          console.log('error');
        }
      }
    );
  };
  const getTotalOrdersChartData = (event, monthlyStartDate, todaysDate) => {
    Order.getTotalOrdersChartData(
      event,
      propsStoreId,
      monthlyStartDate,
      todaysDate,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          for (let x in response.data) {
            let arrayKeys = Object.keys(response.data);

            const padToString = arrayKeys => {
              return String('0' + arrayKeys).slice(-2);
            };
            const sortByDate = (first, second) => {
              const firstPart = first.split('-'),
                secondPart = second.split('-');
              const a = firstPart[1] + padToString(firstPart[0]);
              const b = secondPart[1] + padToString(secondPart[0]);
              return a - b;
            };
            arrayKeys.sort(sortByDate);

            setChartDataResponseKey(arrayKeys);
            let arr = [];
            for (let index = 0; index < arrayKeys?.length; index++) {
              arr?.push(response?.data[arrayKeys[index]]);
            }

            setChartDataResponseValue(Object.values(arr));
          }
        } else {
          console.log('error');
        }
      }
    );
  };
  const Bardata = {
    labels: chartDataResponseKey,
    type: 'bar',

    datasets: [
      {
        label:
          buttonSelected === 'Sales'
            ? dashType === 'customer'
              ? 'Sales'
              : 'Purchase'
            : buttonSelected === 'Payments'
            ? 'Payments'
            : buttonSelected === 'Orders'
            ? dashType === 'customer'
              ? 'Orders'
              : 'Purchase Orders'
            : null,
        barPercentage: 3,
        maxBarThickness: 50,
        minBarLength: 10,
        data: chartDataResponseValue,

        backgroundColor:
          buttonSelected === 'Sales'
            ? color1
            : buttonSelected === 'Payments'
            ? color2
            : buttonSelected === 'Orders'
            ? color3
            : '',
      },
    ],
  };

  const checkConditions = (labelValue, buttonSelected) => {
    if (labelValue === 'Weekly' && buttonSelected === 'Sales') {
      dashType === 'customer'
        ? getTotalSalesChart('Weekly', monthlyStartDate, todaysDate)
        : getTotalSalesChartVendor('Weekly', monthlyStartDate, todaysDate);
    }
    if (labelValue === 'Daily' && buttonSelected === 'Sales') {
      dashType === 'customer'
        ? getTotalSalesChart('Daily', monthlyStartDate, todaysDate)
        : getTotalSalesChartVendor('Daily', monthlyStartDate, todaysDate);
    }
    if (labelValue === 'Monthly' && buttonSelected === 'Sales') {
      dashType === 'customer'
        ? getTotalSalesChart('Monthly', yearsIstDay, todaysDate)
        : getTotalSalesChartVendor('Monthly', yearsIstDay, todaysDate);
    }
    if (labelValue === 'Weekly' && buttonSelected === 'Payments') {
      dashType === 'customer'
        ? getTotalPaymentChartData('Weekly', monthlyStartDate, todaysDate)
        : getTotalPaymentChartDataVendor(
            'Weekly',
            monthlyStartDate,
            todaysDate
          );
    }
    if (labelValue === 'Daily' && buttonSelected === 'Payments') {
      dashType === 'customer'
        ? getTotalPaymentChartData('Daily', monthlyStartDate, todaysDate)
        : getTotalPaymentChartDataVendor('Daily', monthlyStartDate, todaysDate);
    }
    if (labelValue === 'Monthly' && buttonSelected === 'Payments') {
      dashType === 'customer'
        ? getTotalPaymentChartData('Monthly', yearsIstDay, todaysDate)
        : getTotalPaymentChartDataVendor('Monthly', yearsIstDay, todaysDate);
    }
    if (labelValue === 'Weekly' && buttonSelected === 'Orders') {
      dashType === 'customer'
        ? getTotalOrdersChartData('Weekly', monthlyStartDate, todaysDate)
        : getTotalOrdersChartDataVendor('Weekly', monthlyStartDate, todaysDate);
    }
    if (labelValue === 'Daily' && buttonSelected === 'Orders') {
      dashType === 'customer'
        ? getTotalOrdersChartData('Daily', monthlyStartDate, todaysDate)
        : getTotalOrdersChartDataVendor('Daily', monthlyStartDate, todaysDate);
    }
    if (labelValue === 'Monthly' && buttonSelected === 'Orders') {
      dashType === 'customer'
        ? getTotalOrdersChartData('Monthly', yearsIstDay, todaysDate)
        : getTotalOrdersChartDataVendor('Monthly', yearsIstDay, todaysDate);
    }
  };

  //last

  return (
    <>
      <Grid container sx={{ backgroundColor: '#fff', mt: 1 }}>
        <Grid item md={7} sm={12} xs={12}>
          <Box
            item
            md={12}
            xs={12}
            sm={8}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              maxWidth: props.storeId !== undefined ? 900 : '340px',
              minWidth: props.storeId !== undefined ? 700 : '340px',
              maxHeight: '30px',
              backgroundColor: '#3B78C9',
              mt: 1,
              ml: { md: 2, sm: 2, xs: 0 },
              borderRadius: '2px',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                color: '#fff',
                fontSize: { xs: '16px', sm: '20px' },
                pl: 1,
              }}
              noWrap
            >
              Total Outstanding
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: '#fff',
                fontSize: { xs: '16px', sm: '20px' },
                textAlign: 'left',
                pr: 1,
              }}
            >
              {balance === 0 || balance === null ? `0` : balance.toFixed(1)}
              &nbsp;
              {currency ? <HTMLRenderer html={currency} /> : null}
            </Typography>
          </Box>
          <Box sx={{ flexDirection: { md: 'row', sm: 'row', xs: 'column' } }}>
            <Box
              sx={{
                mt: 2,
                ml: { md: 2, sm: 2, xs: 0 },
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={value}
                  inputFormat='dd/MM/yyyy'
                  onChange={newValue => {
                    setValue(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      size='small'
                      sx={{ width: '140px', mr: 1 }}
                      {...params}
                    />
                  )}
                />
                <DatePicker
                  value={value1}
                  minDate={new Date(value)}
                  onChange={newValue => {
                    setValue1(newValue);
                  }}
                  inputFormat='dd/MM/yyyy'
                  disableFuture
                  renderInput={params => (
                    <TextField
                      size='small'
                      sx={{ width: '140px' }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              <Tooltip>
                <Button
                  variant='contained'
                  disabled={saveddatepicker}
                  onClick={() => {
                    dashType === 'customer'
                      ? getTotalSalesDateTimePicker()
                      : getTotalVendorSalesDateTimePicker();
                  }}
                  sx={{
                    ml: 1,
                    textTransform: 'capitalize',
                    backgroundColor: '#3B78C9',
                    width: '80px',
                    height: '27px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                    }}
                  >
                    Apply
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  backgroundColor: '#3B78C9',
                  textAlign: 'center',
                  ml: { md: 2, sm: 2, xs: 0 },
                  mt: 2,
                  pt: 2,
                  pl: 2,

                  height: '190px',
                  minWidth: '340px',
                }}
              >
                <Typography
                  variant='h6'
                  sx={{ color: '#fff', fontSize: '20px' }}
                >
                  <AccountBalanceRoundedIcon sx={{ color: 'white' }} />
                  &nbsp; {dashType === 'customer' ? 'Sales' : 'Purchase'}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography
                      variant='h6'
                      sx={{ pt: 3, color: '#fff', fontSize: '16px' }}
                    >
                      {dashType === 'customer' ? 'Total Sales' : 'Purchase'}
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{ pt: 1, color: '#fff', fontSize: '16px' }}
                    >
                      Total Payments
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        pt: 1,
                        color: '#fff',
                        fontSize: '16px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {dashType === 'customer'
                        ? 'Total Orders'
                        : 'Total Purchase Order'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ textAlign: 'right', pr: { md: 2, xs: 5.5, sm: 3 } }}
                  >
                    <Typography
                      variant='h6'
                      sx={{
                        pt: 3,
                        textAlign: 'right',
                        color: '#fff',
                        fontSize: '16px',
                      }}
                    >
                      {totalSales === 0 ||
                      totalSales === null ||
                      totalSales === ''
                        ? `0`
                        : totalSales.toFixed(1)}
                      &nbsp;
                      {currency ? <HTMLRenderer html={currency} /> : null}
                    </Typography>

                    <Typography
                      variant='h6'
                      sx={{
                        pt: 1,
                        textAlign: 'right',
                        color: '#fff',
                        fontSize: '16px',
                      }}
                    >
                      {payment === 0 || payment === null || payment === ''
                        ? `0`
                        : payment}
                      &nbsp;
                      {currency ? <HTMLRenderer html={currency} /> : null}
                    </Typography>

                    <Typography
                      variant='h6'
                      sx={{
                        pt: 1,
                        textAlign: 'right',
                        color: '#fff',
                        fontSize: '16px',
                      }}
                    >
                      {orders === 0 || orders === null || orders === ''
                        ? `0`
                        : orders}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  marginLeft: '6rem',
                  pt: 'none',
                  mb: 2,
                  display: { md: 'block', sm: 'block', xs: 'none' },
                }}
              >
                <Doughnut data={data} width={230} height={230} />
              </Box>
            </Box>
            <Box
              sx={{
                marginLeft: { md: '6rem', sm: '6rem', xs: 0 },
                pt: 'none',

                mb: 2,
                display: { md: 'none', sm: 'none', xs: 'block' },
              }}
            >
              <Doughnut data={data} width={230} height={230} />
            </Box>

            <Box sx={{ display: 'flex', ml: 2, mt: '50px' }}>
              <Button
                variant='contained'
                sx={{
                  mr: { md: 1, sm: 3, xs: 0.5 },
                  height: '27px',
                  fontSize: { md: '13px', sm: '12px', xs: '10px' },
                  width: { md: '126px', sm: '75px', xs: '16px' },
                  textTransform: 'capitalize',
                  backgroundColor:
                    buttonSelected === 'Sales' ? '#3B78C9' : 'gray',
                }}
                onClick={() => {
                  checkConditions(labelValue, 'Sales');

                  setButtonSelected('Sales');
                }}
              >
                {' '}
                {dashType === 'customer' ? 'Sales' : 'Purchase'}
              </Button>
              <Button
                variant='contained'
                sx={{
                  mr: { md: 1, sm: 3, xs: 0.5 },
                  height: '27px',

                  fontSize: { md: '13px', sm: '12px', xs: '10px' },
                  width: { md: '126px', sm: '75px', xs: '16px' },
                  textTransform: 'capitalize',
                  backgroundColor:
                    buttonSelected === 'Payments' ? '#3B78C9' : 'gray',
                }}
                onClick={() => {
                  checkConditions(labelValue, 'Payments');

                  setButtonSelected('Payments');
                }}
              >
                Payments
              </Button>
              <Button
                variant='contained'
                sx={{
                  mr: { md: 1, sm: 3, xs: 0.5 },
                  height: '27px',

                  width: { md: '129px', sm: '75px', xs: '16px' },
                  textTransform: 'capitalize',
                  backgroundColor:
                    buttonSelected === 'Orders' ? '#3B78C9' : 'gray',
                }}
                onClick={() => {
                  checkConditions(labelValue, 'Orders');
                  setButtonSelected('Orders');
                }}
              >
                <Typography
                  sx={{
                    fontSize:
                      dashType === 'customer'
                        ? { md: '13px', sm: '12px', xs: '10px' }
                        : { md: '12px', sm: '12px', xs: '10px' },
                    display: 'flex',
                    whiteSpace: 'noWrap',
                  }}
                >
                  {dashType === 'customer' ? 'Orders' : 'Purchase Order'}
                </Typography>
              </Button>
              <FormControl>
                <Select
                  sx={{
                    mt: '1.5px',

                    height: '27px',
                    fontSize: { md: '14px', sm: '14px', xs: '10px' },
                    width: { md: '120px', sm: '100px', xs: '100px' },
                    float: 'right',
                  }}
                  labelId='labelValue'
                  id='labelValue'
                  value={labelValue}
                  onChange={event => {
                    setLabelValue(event.target.value);
                  }}
                >
                  <MenuItem value='Daily'>Daily</MenuItem>
                  <MenuItem value='Weekly'>Weekly</MenuItem>
                  <MenuItem value='Monthly'>Monthly</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                overflowX: { md: 'hidden', sm: 'scroll', xs: 'scroll' },
                width: { md: '650px', sm: '600px', xs: '300px' },
                height: '350px',
                mt: 1,
                ml: { md: 2, sm: 2, xs: 0 },
                pb: 6,
              }}
            >
              <SalesChart data={Bardata} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default OutStandingBalance;
