import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Grid, Box, Button } from '@mui/material';
import Header from '../../component/header/Header';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Agent from '../../modules/Agent';

import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Auth from '../../modules/Auth';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';
import Notification from '../../modules/Notification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
// --------------------------------------order Status--------------------------------
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <ShoppingCartIcon />,
    2: <DoneIcon />,
    3: <AssignmentTurnedInIcon />,
    4: <LocalShippingIcon />,
    5: <ShoppingBasketIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = getSteps();
function getSteps() {
  return ['Return Accepted', 'Pickup Initiated', 'Pickup Done', 'Returned'];
}
export default function AgentView() {
  const [expanded, setExpanded] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [returnOrders, setReturnOrders] = React.useState([]);
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [confirmOrderStatusBox, setConfirmOrderStatusBox] = React.useState({
    open: false,
  });
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    getReturnOrderForAgent();
  }, []);
  const getReturnOrderForAgent = () => {
    Agent.getReturnOrdersForAgent(
      Auth.getUserLoginId(),
      Auth.getStoreId(),
      response => {
        setReturnOrders(response.data);
      }
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getActiveStepper = storeOrderStatus => {
    switch (storeOrderStatus) {
      case 'ASSIGNED':
        return 0;
      case 'PICKUP':
        return 1;

      case 'ACCEPTED':
        return 2;
      case 'RETURNED':
        return 3;
    }
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const confirmOrderStatusChange = (labelCount, order) => {
    if (
      (order.returnOrderStatus == 'ASSIGNED' && labelCount == 1) ||
      (order.returnOrderStatus == 'PICKUP' && labelCount == 2) ||
      (order.returnOrderStatus == 'ACCEPTED' && labelCount == 3)
    ) {
      let confirmBoxObj = {
        open: true,
        labelCount: labelCount,
        order: order,
      };
      setConfirmOrderStatusBox(confirmBoxObj);
    }
  };
  const handleConfirmClose = () => {
    let confirmBoxObj = {
      open: false,
      // labelCount: labelCount,
      // order: order,
    };
    setConfirmOrderStatusBox(confirmBoxObj);
  };
  const getOrderStatusObj = (orderObj, status) => {
    let agentAssign = {
      agentId: Auth.getUserLoginId(),
      orderId: orderObj.id,
      orderStatus: status,
      orderDate: orderObj.creationDate,
      storeId: Auth.getStoreId(),
      deliveryDate: orderObj.estimatedDeliveryTime,
      customerId: orderObj.customerId,
      dispatchDate: new Date(),
    };
    return agentAssign;
  };
  const setOrderStatus = async (labelCount, order) => {
    if (labelCount >= 1) {
      if (order.returnOrderStatus == 'ASSIGNED' && labelCount == 1) {
        let status = `Order status has been updated to  PICKUP with return Order Number ${order.returnOrderNumber}.Track your order in orders page `;
        await Notification.pushNotification(order, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(order, 'PICKUP'),
          order.id,
          response => {
            if (response.status === 'success') {
              getReturnOrderForAgent();
              handleConfirmClose();
              openUpstoreSnackBar('status Updated Sucessfully', 'success');
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'error'
              );
              handleConfirmClose();
            }
          }
        );
        handleConfirmClose();
      } else if (order.returnOrderStatus == 'PICKUP' && labelCount == 2) {
        let status = `Order status has been updated to  ACCEPTED with return Order Number  ${order.returnOrderNumber}.Track your order in orders page `;
        await Notification.pushNotification(order, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(order, 'ACCEPTED'),
          order.id,
          response => {
            if (response.status === 'success') {
              getReturnOrderForAgent();
              handleConfirmClose();
              openUpstoreSnackBar('status Updated Sucessfully', 'success');
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'error'
              );
              handleConfirmClose();
            }
          }
        );
        handleConfirmClose();
      } else if (order.returnOrderStatus == 'ACCEPTED' && labelCount == 3) {
        console.log(labelCount, order, '1');
        let status = `Order status has been updated to  RETURNED with return Order Number ${order.returnOrderNumber}.Track your order in orders page `;
        await Notification.pushNotification(order, status);
        await Agent.updateOrderDeliveryStatus(
          getOrderStatusObj(order, 'RETURNED'),
          order.id,
          response => {
            if (response.status === 'success') {
              getReturnOrderForAgent();
              handleConfirmClose();
              openUpstoreSnackBar('status Updated Sucessfully', 'success');
            } else {
              openUpstoreSnackBar(
                'Error occured while updating order status',
                'error'
              );
              handleConfirmClose();
            }
          }
        );
        handleConfirmClose();
      }
    } else {
      handleConfirmClose();
    }
  };
  const handleAccordionChange = id => {
    setOpenAccordion(prevState => (id === prevState ? false : id));
  };
  return (
    <div>
      {/* ------------------------RETURNED ORDER------------------------ */}

      <Grid container>
        <Grid item md={12} sx={{ mt: 2 }}>
          {returnOrders?.map(returnObj => {
            return (
              <Accordion
                key={returnObj.id}
                expanded={openAccordion === returnObj.id}
                // onChange={handleChange1(returnObj.id)}
              >
                <AccordionSummary
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                  expandIcon={
                    <ExpandMoreIcon
                      fontSize='large'
                      onClick={() => {
                        handleAccordionChange(returnObj.id);
                      }}
                    />
                  }
                >
                  <Grid container style={{ paddingLeft: '0px' }}>
                    <Grid
                      Item
                      xs={8}
                      sm={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          color: '#d53030',
                          textAlign: 'left',
                        }}
                      >
                        <span>
                          <ShoppingBasketIcon sx={{ fontSize: '1rem' }} />
                          Return Order Number: {
                            returnObj?.returnOrderNumber
                          }{' '}
                          &nbsp;&nbsp;
                        </span>
                        <span style={{ display: 'inline-block' }}>
                          Order Id : {returnObj?.orderId}
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      item
                      xs={12}
                      sm={2}
                    >
                      <Typography
                      // className={`orderStatus__${orderObj.storeOrderStatus}`}
                      >
                        {returnObj?.returnOrderStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {returnObj.orderDetails.map(item => {
                  return (
                    <AccordionDetails>
                      <Typography style={{ width: '100%' }}>
                        <List>
                          {/* {orderObj.orderDetails.map(item => {
                        return ( */}
                          <ListItem alignItems='flex-start'>
                            <Grid container>
                              <Grid item xs={2} sm={1}>
                                <ListItemAvatar>
                                  <Avatar
                                    variant='rounded'
                                    alt='productItem1'
                                    // src={
                                    //   serverConfig.imageServerUrl +
                                    //   '/product_' +
                                    //   item.productId +
                                    //   '/img_192X192_0.jpg?' +
                                    //   new Date().getTime()
                                    // }
                                  />
                                </ListItemAvatar>
                              </Grid>
                              <Grid item xs={10} md={5}>
                                <ListItemText
                                  primary={
                                    <div style={{ textAlign: 'left' }}>
                                      <Box component='div' display='inline'>
                                        <b>{item?.itemName}</b>
                                      </Box>
                                      <Box component='div' display='inline'>
                                        &nbsp;&nbsp;&nbsp;
                                        {item.quantity}
                                        {item.unit}
                                      </Box>
                                    </div>
                                  }
                                  secondary={
                                    <div style={{ textAlign: 'left' }}>
                                      <Box component='div'>
                                        {item.itemDescription}
                                      </Box>
                                      <Box component='div' display='inline'>
                                        MRP: <s>{item.mrp}</s>
                                      </Box>
                                      <Box component='div' display='inline'>
                                        &nbsp;&nbsp;&nbsp; Upstore price:{' '}
                                        <b>{item.storePrice}</b>
                                      </Box>
                                    </div>
                                  }
                                />
                              </Grid>
                              <Grid className='take-right' item xs={4} sm={2}>
                                Qty: <Chip label={item.noOfItems} />
                              </Grid>
                              <Grid className='take-right' item xs={4} sm={2}>
                                Price:{' '}
                                <Chip
                                  icon={<h6>&#8377;</h6>}
                                  label={item.storePrice * item.noOfItems}
                                />
                              </Grid>
                              <Grid item xs={4} sm={2}>
                                {/* {item.notDeliverable != undefined &&
                                item.notDeliverable ? (
                                  <Typography color='secondary'>
                                    <b>Not deliverable</b>
                                  </Typography>
                                ) : null} */}
                                {/* {item.cancelled != undefined &&
                                item.cancelled ? ( */}
                                <Typography color='secondary'>
                                  <b>Returned</b>
                                </Typography>
                                {/* ) : null} */}
                                {/* {item.isReturned != undefined &&
                                item.isReturned ? (
                                  <Typography color='secondary'>
                                    <b>Returned</b>
                                  </Typography>
                                ) : null} */}
                              </Grid>
                            </Grid>
                            <Divider />
                          </ListItem>
                        </List>
                      </Typography>
                    </AccordionDetails>
                  );
                })}
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                      textAlign: 'left',
                      padding: '15px',
                    }}
                  >
                    <b>Delivered on </b>
                    <span style={{ color: 'green' }}>
                      {/* {Utils.finalDateString(new Date(orderObj.eta))} */}
                    </span>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                          <OrderSummary orderSummary={orderObj} />
                        </Grid> */}
                  {/* {orderObj.deliveringTo != undefined ? (
                          <Grid item xs={12}>
                            <DeliveryDetails
                              deliveringTo={orderObj.deliveringTo}
                            />
                          </Grid>
                        ) : null} */}
                </Grid>
                <Divider />
                <br />
                <Stack sx={{ width: '100%' }} spacing={4}>
                  <Stepper
                    alternativeLabel
                    activeStep={getActiveStepper(returnObj.returnOrderStatus)}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          StepIconComponent={ColorlibStepIcon}
                          onClick={() =>
                            confirmOrderStatusChange(index, returnObj)
                          }
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>

      {/* -----------------------------COMPLATED ORDER--------------------------------- */}

      <Dialog
        maxWidth='xs'
        onClose={handleConfirmClose}
        aria-labelledby='confirmation-dialog-title'
        open={confirmOrderStatusBox.open}
      >
        <DialogTitle id='confirmation-dialog-title'>
          Are you sure to update order status?
        </DialogTitle>

        <DialogContent dividers>
          <b style={{ color: '#d53030' }}>
            Are you sure to update order status? <br />
            Once updated, it can't be reverted.
          </b>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            color='primary'
            onClick={() => handleConfirmClose()}
          >
            Cancel
          </Button>

          <Button
            color='primary'
            onClick={event =>
              setOrderStatus(
                confirmOrderStatusBox.labelCount,
                confirmOrderStatusBox.order
              )
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}
