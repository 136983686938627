import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Button,
  Tabs,
  Tab,
  ListItemText,
  ListItemButton,
  AppBar,
  Backdrop,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Loading } from '../../component/loading/Loading';
import Header from '../../component/header/Header';
import Add from '@mui/icons-material/Add';

import Edit from '@mui/icons-material/Edit';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import VendorPaymentHistory from '../../component/vendorPaymentHistory/VendorPaymentHistory';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import '../customerDetails/style.css';
import Vendor from '../../modules/Vendor';
import VendorDialog from '../../component/vendorDialog/VendorDialog';
import VendorStatement from '../../component/vendorStatement/VendorStatement';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentSection from '../../component/paymentSection/PaymentSection';
import { ArrowLeft } from '@mui/icons-material';
import { debounce } from '../../utils';

const page_size = 18;
function VendorDetails() {
  let navigate = useNavigate();
  const listRef = React.useRef();
  const location = useLocation();
  const [VendorList, setVendorList] = React.useState([]);
  const [VendorDetails, setVendorDetails] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [addVendorDialog, setaddVendorDialog] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState('');
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(
    location?.state?.id || location?.state
  );
  const [hideGrid, setHideGrid] = React.useState(false);
  const [selectedVendorData, setSelectedVendorData] = React.useState(false);
  const [getUpdatedLedger, setGetUpdatedLedger] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  React.useEffect(() => {
    getAllVendorList(pageNo);
    getVendorByVendorId(location.state.id || location.state);
  }, []);

  const getAllVendorList = pageNum => {
    Vendor.getAllVendorByStoreId(Auth.getStoreId(), pageNum, true, response => {
      if (response.status === 'success') {
        setLoader(false);
        if (response.data.length < page_size) {
          setHasMoreData(false);
        }
        setVendorList([...VendorList, ...response.data]);
      } else {
        console.log(Error);
      }
      setSkeletonLoading(false);
    });
  };

  //getVendorBy id
  const getVendorByVendorId = vendorId => {
    Vendor.getVendorByVendorId(vendorId, response => {
      if (response.status === 'success') {
        setVendorDetails(response.data);
      } else {
        setVendorDetails(null);
        console.log('err');
      }
    });
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const handleListItemButtonClick = id => {
    setSelectedItemIndex(id);
    getVendorByVendorId(id);
  };
  const hideVendorGrid = () => {
    setHideGrid(!hideGrid);
  };

  // const handleFilterVendorChange = event => {
  //   let search = event.target.value;
  //   let filteredArray = VendorList.filter(Vendor => {
  //     return Vendor.name?.toUpperCase().includes(search.toUpperCase());
  //   });
  //   if (filteredArray.length <= 0 || event.target.value === '') {
  //     getAllVendorList();
  //   } else {
  //     setVendorList(filteredArray);
  //   }

  //   if (event.target.value === '') {
  //     getAllVendorList();
  //   } else {
  //     setVendorList(filteredArray);
  //   }
  // };
  const handleVendorUpdateCallback = data => {
    setVendorDetails(data);
  };

  const editDialogCallBack = () => {
    setaddVendorDialog(true);
    setButtonClicked('Edit');
  };

  const handleGetUpdatedLedger = param => {
    if (param === true) setGetUpdatedLedger(true);
    else setGetUpdatedLedger(false);
  };

  const handleSearchVendor = event => {
    let search = event.target.value;
    Vendor.searchVendorByStoreId(Auth.getStoreId(), search, response => {
      if (response.status === 'success') {
        let filteredSearchActive = response.data.filter(item => {
          return item.active === true;
        });

        setVendorList(filteredSearchActive);
      }
      if (response.status === 'error') {
        getAllVendorList(0);
      }
    });
  };
  const handleVendorChange = React.useCallback(
    debounce(handleSearchVendor, 300),
    []
  );
  const handleScroll = () => {
    const list = listRef.current;

    if (
      Math.abs(
        list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1 &&
          hasMoreData
      )
    ) {
      let pageNum = pageNo === 0 ? 1 : pageNo + 1;
      setSkeletonLoading(true);
      setPageNo(pageNum);
      getAllVendorList(pageNum);
    }
  };

  return (
    <>
      <Header pageName='Vendor Details' />

      <Box
        sx={{
          paddingLeft: '3.5rem',
          paddingRight: '.5rem',
          mt: 6,
        }}
      >
        {loader ? (
          <Loading />
        ) : (
          <>
            <Grid container sx={{ padding: '5px' }}>
              <Grid item md={12} sm={12} xs={12}>
                <BreadCrumb pageName='Vendor-details' />
              </Grid>
              <Grid item md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                <Grid item md={6} sm={6} xs={6}>
                  <Tooltip title='Go to Vendors' placement='bottom'>
                    <Button
                      startIcon={<ArrowLeft />}
                      variant='text'
                      underline='none'
                      sx={{
                        mb: 1,
                        pl: 0,

                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                      }}
                      onClick={() => navigate('/vendors')}
                    >
                      Vendor
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item md={6} sm={6} xs={6}>
                  <Tooltip title='Add vendor' placement='bottom'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setaddVendorDialog(true);
                        setButtonClicked('Add');
                        setSelectedVendorData('');
                      }}
                      sx={{
                        p: 0,
                        textTransform: 'capitalize',
                        color: 'white',
                        bgcolor: '#3B78C9',
                        float: 'right',
                        width: '126px',
                        height: '27px',
                      }}
                    >
                      <Add
                        sx={{ color: 'white', mr: '2px', fontSize: 'small' }}
                      />
                      <Typography
                        sx={{
                          fontSize: '12px',
                        }}
                      >
                        New Vendor
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>

              {hideGrid === true ? (
                <Grid sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
                  <ArrowRightSharpIcon
                    sx={{
                      ml: '6px',
                      color: ' #3B78C9',
                      width: '35px',
                      height: '35px',
                    }}
                    onClick={hideVendorGrid}
                  />
                </Grid>
              ) : (
                <Grid
                  // bgcolor="#AFC9F2"
                  // bgcolor="#fafbfc"
                  className='Vendor-list'
                  item
                  xs={12}
                  md={2}
                  lg={2}
                  onScroll={handleScroll}
                  ref={listRef}
                  sx={{
                    height: { md: '800px', sm: '550', xs: '60px' },
                    overflowY: { md: 'scroll', sm: 'hidden', xs: 'hidden' },
                    overflowX: 'hidden',
                  }}
                  borderRight='3px solid lightGray'

                  // className={`${hideGrid ? 'hide-grid' : 'show-grid'}`}
                >
                  <Box
                    bgcolor='#AFC9F2'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      p: 2,
                      position: 'sticky',
                      zIndex: '1',
                      top: '0px',
                      display: {
                        lg: 'flex',
                        md: 'none',
                        xs: 'none',
                        sm: 'none',
                      },
                      width: '100%',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='search Vendor'
                      style={{
                        borderRadius: '50px',
                        // borderBottomLeftRadius: '50px',
                        minWidth: '145px',
                        height: '30px',
                        paddingLeft: '6px',
                        fontSize: '14px',
                        border: 'none',
                      }}
                      onChange={handleVendorChange}
                    />
                    {/* <SearchIcon
                      sx={{
                        backgroundColor: 'white',
                        color: '#3B78C9',
                        height: '30px',
                        borderTopRightRadius: '50px',
                        borderBottomRightRadius: '50px',
                        paddingRight: '2px',
                      }}
                    /> */}

                    <ArrowLeftSharpIcon
                      sx={{
                        ml: '1px',
                        color: ' #3B78C9',
                        width: '40px',
                        height: '35px',
                      }}
                      onClick={hideVendorGrid}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 0.5,

                      overflowX: { xs: 'scroll', md: 'hidden' },
                      overflowY: 'scroll',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'column', sm: 'row' },
                    }}
                  >
                    {VendorList.length > 0
                      ? VendorList.map((vendor, index) => {
                          return (
                            <>
                              <ListItemButton
                                selected={selectedItemIndex === vendor.id}
                                key={vendor.id}
                                className='customerBtn'
                                sx={{
                                  height: '60px',
                                  color: '#3B78C9',
                                  ml: { md: 0, sm: 8, xs: 8 },
                                  mr: { md: 0, sm: 8, xs: 8 },
                                }}
                                onClick={() => {
                                  handleListItemButtonClick(vendor.id);
                                }}
                              >
                                <ListItemText>
                                  <Typography
                                    color='black'
                                    sx={{
                                      fontSize: {
                                        md: '18px',
                                        sm: '14px',
                                        xs: '14px',
                                      },
                                    }}
                                  >{`${vendor.name}`}</Typography>
                                </ListItemText>
                              </ListItemButton>
                            </>
                          );
                        })
                      : null}
                    {hasMoreData ? (
                      <Box>
                        <Skeleton
                          width='100%'
                          height={50}
                          style={{ textAlign: 'center' }}
                        >
                          Scroll down to Load more ...
                        </Skeleton>
                      </Box>
                    ) : (
                      <Box>
                        <Skeleton
                          width='100%'
                          height={50}
                          style={{ textAlign: 'center' }}
                        >
                          No More Vendors
                        </Skeleton>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={`${hideGrid === true ? 11 : 10}`}>
                {VendorDetails !== null && (
                  <Box
                    bgcolor='#ecf6fb'
                    sx={{
                      overflowX: { xs: 'scroll', md: 'hidden' },
                      overflowY: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      flexDirection: { xs: 'row', md: 'row', sm: 'row' },
                      py: 2,
                      px: 2,
                      justifyContent: 'space-between',
                      mb: { xs: 2 },
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='#6a769c'
                      sx={{ fontSize: { md: '15px', sm: '14px', xs: '12px' } }}
                    >
                      {`${VendorDetails.name}`}
                      <Tooltip title='Edit vendor' placement='bottom'>
                        <Edit
                          fontSize='small'
                          onClick={() => {
                            setSelectedVendorData(VendorDetails);

                            editDialogCallBack();
                          }}
                          sx={{
                            color: '#3B78C9',
                            fontSize: { md: '15px', sm: '14px', xs: '12px' },
                            cursor: 'pointer',

                            ml: 1,
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    <Tooltip
                      title={`${VendorDetails?.address}`}
                      placement='bottom'
                    >
                      <Typography
                        variant='h5'
                        color='#6a769c'
                        sx={{
                          width: '60%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                          display: {
                            xs: 'none',
                            md: 'inline-block',
                            fontSize: '18px',
                          },
                          fontSize: { md: '15px', sm: '14px', xs: '12px' },
                        }}
                      >
                        {VendorDetails?.address}
                      </Typography>
                    </Tooltip>
                    <Typography
                      variant='h5'
                      color='#6a769c'
                      sx={{
                        fontSize: { md: '15px', sm: '14px', xs: '12px' },
                        pl: { md: 0, sm: 0, xs: 2 },
                      }}
                    >
                      {VendorDetails?.contactNo}
                    </Typography>

                    {VendorDetails?.active === false ? (
                      <Tooltip
                        title='You cannot create order for inActive Vendor'
                        placement='left-start'
                      >
                        <Button
                          variant='outlined'
                          onClick={() =>
                            openUpstoreSnackBar(
                              'You cannot create order for inActive Vendor',
                              'error'
                            )
                          }
                          sx={{
                            p: 0,
                            textTransform: 'capitalize',
                            backgroundColor: 'lightgray',
                            color: 'gray',
                            width: { md: '126px', sm: '126px', xs: '100px' },
                            height: '27px',
                            ml: { md: 0, sm: 0, xs: 1 },
                          }}
                        >
                          Create purchase bill
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        variant='outlined'
                        sx={{
                          p: 0,
                          textTransform: 'capitalize',
                          color: '#3B78C9',
                          width: { md: '126px', sm: '126px', xs: '100px' },
                          height: '27px',
                          ml: { md: 0, sm: 0, xs: 1 },
                        }}
                        onClick={() =>
                          navigate('/purchase-bill', { state: VendorDetails })
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: { md: '12px', sm: '12px', xs: '10px' },
                          }}
                          noWrap
                        >
                          Create purchase bill
                        </Typography>
                      </Button>
                    )}
                  </Box>
                )}
                {VendorDetails !== null && (
                  <VendorTabsComponent
                    snackbar={openUpstoreSnackBar}
                    vendorId={VendorDetails?.id}
                    name={VendorDetails?.name}
                    vendorList={VendorList}
                    vendorDetails={VendorDetails}
                    getUpdatedLedger={getUpdatedLedger}
                    handleVendorUpdateCallback={handleVendorUpdateCallback}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {addVendorDialog ? (
        <VendorDialog
          openDialog={addVendorDialog}
          closeDialog={() => {
            setaddVendorDialog(false);
          }}
          buttonClicked={buttonClicked}
          editVendorData={selectedVendorData && selectedVendorData}
          reloadVendorList={getAllVendorList && getAllVendorList}
          forReloadVendor={getVendorByVendorId && getVendorByVendorId}
          snackbar={openUpstoreSnackBar}
          getUpdatedLedger={handleGetUpdatedLedger}
          type={'statement'}
        />
      ) : null}

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: 3, sm: 2, xs: 0 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VendorTabsComponent = ({
  snackbar,
  vendorId,
  vendorDetails,
  VendorList,
  name,
  getUpdatedLedger,
  handleVendorUpdateCallback,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 1, bgcolor: '#F1F4F5' }}>
      <Box
        sx={{
          overflowX: { xs: 'scroll', md: 'hidden' },
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: { xs: 'row', md: 'row', sm: 'row' },
        }}
      >
        <AppBar
          position='static'
          sx={{
            bgcolor: 'secondary.light',
            overflowX: { xs: 'scroll', md: 'hidden' },
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: { xs: 'row', md: 'row', sm: 'row' },
          }}
        >
          <Tabs
            variant='scrollable'
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            {' '}
            <Tab label='Statements' {...a11yProps(0)} />
            <Tab label='Payment History' {...a11yProps(1)} />
            <Tab label='Make Payment' {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Box>

      <TabPanel value={value} index={0}>
        <VendorStatement
          getUpdatedLedger={getUpdatedLedger}
          key={vendorId}
          vendorObj={vendorId}
          VendorList={VendorList !== null && VendorList}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <VendorPaymentHistory
          key={vendorId}
          vendorId={vendorId}
          snackbar={snackbar}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PaymentSection
          key={vendorId}
          customerId={vendorId}
          snackbar={snackbar}
          type='Vendor'
          customerDetails={vendorDetails}
          name={name}
          handleVendorUpdateCallback={handleVendorUpdateCallback}
        />
      </TabPanel>
    </Box>
  );
};

export default VendorDetails;
