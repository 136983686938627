import Auth from '../Auth';
import RolesAndPermissions from '../RolesAndPermissions';
class AllPermissions {
  constructor() {
    this.userAccessiblePermissions =
      JSON.parse(localStorage.getItem('UsersPermissionsResponse')) || [];
    this.allFeatures =
      JSON.parse(localStorage.getItem('allUpstoreFeatures')) || [];
  }

  setLocalStorage(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  setUserAccessiblePermissions(userId) {
    RolesAndPermissions.getPermissionByRoleId(userId, response => {
      if (response.status === 'success') {
        this.userAccessiblePermissions = [...response.data];
        this.setLocalStorage('UsersPermissionsResponse', response.data);
        this.setAllUpstoreFeatures();
      }
    });
  }

  setAllUpstoreFeatures() {
    RolesAndPermissions.getAllFeatures(response => {
      if (response.status === 'success') {
        this.allFeatures = [...response.data];
        this.setLocalStorage('allUpstoreFeatures', response.data);
      }
    });
  }

  getReturnablePermission(permissionName, permissionData, featuresData) {
    let featureObj = featuresData?.filter(
      feature => feature?.featureName === permissionName
    );
    let permissionObj = permissionData.filter(
      permission => permission.featuresId === featureObj[0]?.id
    );
    return permissionObj[0];
  }

  getUserAccessiblePermissions(permissionName) {
    if (Auth.getUserRoleId() == -1) {
      return { read: true, write: true };
    } else {
      if (
        !this.userAccessiblePermissions ||
        this.userAccessiblePermissions.length === 0 ||
        !this.allFeatures ||
        this.allFeatures.length === 0
      ) {
        this.userAccessiblePermissions =
          JSON.parse(localStorage.getItem('UsersPermissionsResponse')) || [];
        this.allFeatures =
          JSON.parse(localStorage.getItem('allUpstoreFeatures')) || [];
        let response = this.getReturnablePermission(
          permissionName,
          this.userAccessiblePermissions,
          this.allFeatures
        );

        return response;
      } else {
        let response = this.getReturnablePermission(
          permissionName,
          this.userAccessiblePermissions,
          this.allFeatures
        );

        return response;
      }
    }
  }
}

export default new AllPermissions();
