import { serverConfig } from '../../config/server-config';
import UpstoreApi from '../UpstoreApi';
import UpstoreProperties from '../UpstoreProperties';

class Auth {
  constructor() {
    this.user_token = JSON.parse(localStorage.getItem('Auth')) || {};
  }
  getToken() {
    return this.user_token.token;
  }

  getRefreshToken() {
    return this.user_token.refreshToken;
  }
  getUserName() {
    return this.user_token.userDetails.username;
  }

  // For Desktop App //
  getNodeUserName() {
    return this.user_token.username;
  }
  getNodePassword() {
    return this.user_token.password;
  }
  // ============== //

  getUserLoginId() {
    return this.user_token.userDetails.id;
  }

  getUserRoleId() {
    return this.user_token.userDetails.userRoleId;
  }

  getStoreId() {
    return this.user_token.userDetails.storeId;
  }

  getRegistrationKey() {
    return this.user_token.userDetails.registrationKey;
  }

  setUserToken(new_token, reload = true) {
    this.user_token = new_token;

    UpstoreProperties.getUpstoreProperties(
      this.user_token.userDetails.storeId,
      false,
      response => {
        let refreshTokenTime = 3600000;
        if (response.status === 'success') {
          if (response.data.length > 0) {
            let data = response.data?.filter(
              data => data.property === 'REFRESH_TOKEN_TIMEOUT'
            )[0];
            if (data) {
              refreshTokenTime = data.propertyValue;
            }
          }
          let reloadTime =
            new Date().getTime() + parseInt(refreshTokenTime / 2 - 300000); // - 5 minutes

          localStorage.setItem(
            'Auth',
            JSON.stringify({
              ...new_token,
              reloadTime: reloadTime,
              refreshTokenTime: refreshTokenTime,
            })
          );

          localStorage.setItem('ReloadTime', reloadTime);
          localStorage.setItem('refreshTokenTime', refreshTokenTime);

          // reloading only at login time
          if (reload) {
            window.location.reload(false);
          }
        }
      }
    );
  }

  changePassword = (id, data, callback) => {
    return UpstoreApi({
      method: 'PUT',
      url: `/user/${id}/change-password`,
      data: data,
    })
      .then(response => {
        callback({ data: response.data, status: 'success' });
        return response.data;
      })
      .catch(err => {
        console.log('Error occured setting New Password', err.message);
        return false;
      });
  };

  // for registering user
  register = (formData, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/register',
      data: formData,
    })
      .then(response => {
        callback({ status: 'success' });
      })
      .catch(error => {
        console.log('Authentication failed', error.message);
        callback({ status: 'error' });
      });
  };

  // for authentication
  authenticate = (loginData, callback) => {
    this.removeLoginDetails();
    UpstoreApi({
      method: 'POST',
      url: '/authenticate',
      data: loginData,
    })
      .then(response => {
        this.setUserToken(response.data);

        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Authentication failed', error.message);
        callback({ status: 'error', data: error.response.status });
      });
  };

  refreshToken = callback => {
    let newData = {
      refreshToken: this.getRefreshToken(),
    };
    UpstoreApi({
      method: 'POST',
      url: '/public/refresh-token',
      data: newData,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Error in refresh token', error.message);
        callback({ status: 'error' });
      });
  };

  removeLoginDetails() {
    this.user_token.token = null;
    localStorage.removeItem('Auth');
  }
  isAuthenticated() {
    if (
      Object.keys(this.user_token)?.length > 0 &&
      this.user_token.userDetails
    ) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.user_token.token = null;
    localStorage.removeItem('Auth');
    localStorage.removeItem('storeCategoryList');
    localStorage.removeItem('storeCustomersList');
    localStorage.removeItem('storeCompanyDetails');
    localStorage.removeItem('storeVendorList');
    localStorage.removeItem('allUnitsList');
    localStorage.removeItem('upstoreProperties');
    localStorage.removeItem('UsersPermissionsResponse');
    localStorage.removeItem('allUpstoreFeatures');
    localStorage.removeItem('defaultConfiguration');
    localStorage.removeItem('storeSubscriptionPlans');
    window.location.reload(false);

    serverConfig.isUpstoreCloud
      ? (window.location.href = '/')
      : (window.location.hash = '/login');
  }

  // For Node Login //
  nodeLogin = (loginData, callback) => {
    this.removeLoginDetails();
    UpstoreApi({
      method: 'POST',
      url: '/user/getByUsernameAndPassword',
      data: loginData,
    })
      .then(response => {
        this.setUserToken(response.data);
        callback({ status: 'success', data: response.data });
        window.location.reload(false);
      })
      .catch(error => {
        console.log(error);
        callback({ status: 'error' });
      });
  };
}
export default new Auth();
