import UpstoreApi from '../UpstoreApi';

class User {
  //for getting all users by userId
  getAllUsers = (storeId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/user/all/' + storeId,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting  all the users', err.message);
        callback({ status: 'error' });
      });
  };
  //for getting user by userId
  getByUserId = (UserId, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/user/' + UserId,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting the userId', err.message);
        callback({ status: 'error' });
      });
  };

  //for Business type
  AllBusinessType = callback => {
    UpstoreApi({
      method: 'GET',
      url: '/getAllBusinessType',
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting the business Type',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  addChangePassword = (userId, data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `user/change-password-by-userId/${userId}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'invalid' });
        }
      })
      .catch(err => {
        console.log('Error occured in adding New user', err.message);
        callback({ status: 'error' });
      });
  };
  //For adding newuser
  addNewUser = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/user',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'invalid' });
        }
      })
      .catch(err => {
        console.log('Error occured in adding New user', err.message);
        callback({ status: 'error', data: err.response.status });
      });
  };
  // for forgot password
  forgotPassword = (email, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/user/public/forgotPassword',
      data: { email: email },
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success' });
        } else {
          callback({ status: 'invalid' });
        }
      })
      .catch(err => {
        console.log('Error occured in forgot password', err.message);
        callback({ status: 'error' });
      })
      .finally(() => {
        callback({ status: 'finally' });
      });
  };

  // for resetPassword
  resetPassword = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: '/user/public/resetPassword',
      data: data,
    })
      .then(response => {
        if (response.data) {
          console.log(response.data, 'data response');
          callback({ status: 'success' });
        } else {
          callback({ status: 'error' });
        }
      })
      .catch(err => {
        console.log('Error occured while resetting password', err.message);
        callback({ status: 'server error' });
      });
  };

  //user Name already registered
  isDistinctUsername = (userName, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/user/public/distinct-username/' + userName,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting the distinctUsername',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  //email already registered
  isDistinctEmail = (email, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/user/public/distinct-email/' + email,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting the distinctEmail',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  //phone already registered
  isDistinctPhone = (phone, callback) => {
    UpstoreApi({
      method: 'GET',
      url: '/user/public/distinct-phone/' + phone,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(
          'Error occurred while getting the distinctphone',
          err.message
        );
        callback({ status: 'error' });
      });
  };
  //update user details
  updateUserDetails = (UserId, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: '/user/update/' + UserId,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating user details', error.message);
        callBack({ status: 'error' });
      });
  };
  //for deleting
  deleteUser = (id, callback) => {
    UpstoreApi({
      method: 'DELETE',
      url: '/user/delete-user/' + id,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('Deleting user failed', error.message);
        callback({ status: 'error' });
      });
  };
}

export default new User();
