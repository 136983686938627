import React from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

function BarCodeReader(props) {
  const [data, setData] = React.useState('Not Found');
  const [stopStream, setStopStream] = React.useState(false);

  const onUpdate = (err, result) => {
    if (result) {
      setData(result.text);
      setStopStream(true);
      setTimeout(() => {
        props.scanner(result.text);
      }, 500);
      props.snackbar('Scanned Successfully', 'success');
    } else if (err) {
      setData('');
      // setTimeout(() => {
      //   props.snackbar(' Error while Scanning', 'error');
      // }, 500);
    }
  };
  return (
    <>
      <BarcodeScannerComponent
        width={500}
        height={500}
        facingMode={'user'}
        stopStream={stopStream}
        onUpdate={onUpdate}
      />
      <p>{data}</p>
    </>
  );
}

export default BarCodeReader;
