import * as React from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Ledger from '../../modules/Ledger';
import Auth from '../../modules/Auth';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as moment from 'moment';
import { serverConfig } from '../../config/server-config';
import Expense from '../../modules/Expense';
import Store from '../../modules/Store';

export default function ExportDialog({
  // snackbar,
  openDialog,
  closeDialog,
  type,
}) {
  const date = new Date();
  const [value, setValue] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [value1, setValue1] = React.useState(new Date());
  const [Export, setExport] = React.useState({});

  const getExportData = () => {
    let startDate = moment(value).format('YYYY-MM-DD');
    let endDate = moment(value1).format('YYYY-MM-DD');
    if (type === 'customer') {
      Ledger.getExportLedgerStatementByStoreId(
        Auth.getStoreId(),
        startDate,
        endDate,
        type,
        Store.getStoreTimeZone(),
        response => {
          if (response.status === 'success') {
            // snackbar('statement is generated successfully', 'success');
            setExport(response.data);

            let ledgerFileUrl = `${
              serverConfig.cmsSource
            }store_${Auth.getStoreId()}/ledgers/${response.data}`;
            window.open(ledgerFileUrl, '_blank');

            closeDialog();
          } else {
            console.log('error');
          }
        }
      );
    } else {
      Expense.getExpenseStatementByStoreId(
        Auth.getStoreId(),
        startDate,
        endDate,
        Store.getStoreTimeZone(),
        response => {
          if (response.status === 'success') {
            setExport(response.data);

            let expenseFileUrl = `${
              serverConfig.cmsSource
            }store_${Auth.getStoreId()}/Expenses/${response.data}`;
            window.open(expenseFileUrl, '_blank');

            closeDialog();
          } else {
            console.log('error');
          }
        }
      );
    }
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} maxWidth='sm' fullWidth>
        <DialogTitle textAlign='center'>
          <Typography variant='subtitle'>
            {type === 'customer' ? 'Export Ledger' : 'Export Expense Report'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={value}
                inputFormat='dd/MM/yyyy'
                onChange={newValue => {
                  setValue(newValue);
                }}
                renderInput={params => (
                  <TextField
                    size='small'
                    sx={{ width: '140px', height: '40px', mr: 2 }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                value={value1}
                onChange={newValue => {
                  setValue1(newValue);
                }}
                inputFormat='dd/MM/yyyy'
                renderInput={params => (
                  <TextField
                    size='small'
                    sx={{ width: '140px', height: '40px' }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1,
            }}
          >
            <Button
              variant='contained'
              sx={{
                ml: 1,
                backgroundColor: '#3B78C9',
                width: '126px',
                height: '27px',
                mt: 2,
              }}
              onClick={getExportData}
            >
              <DownloadIcon
                sx={{ color: '#fff', fontSize: '18px', mr: '2px' }}
              />
              <Typography
                sx={{ fontSize: '15px', textTransform: 'capitalize' }}
              >
                Download
              </Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontSize: '15px',
              width: '126px',
              height: '27px',
            }}
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
