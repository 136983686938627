import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import { useEffect } from 'react';
import RolesAndPermissions from '../../modules/RolesAndPermissions';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserRoles from '../../modules/UserRoles';
import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TableForUserRole({ features, permessionData }) {
  const getUserPermessionsById = id => {
    let newPr = permessionData.find(item => {
      return item.featuresId === id;
    });

    return newPr?.read;
  };
  const getUserPermessionsByWriteId = id => {
    let newPr = permessionData.find(item => {
      return item.featuresId === id;
    });
    return newPr?.write;
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ mt: 0, minWidth: 650 }}
        size='small'
        aria-label='a dense table'
      >
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            {/* <TableCell align='center' colSpan={3}>
                    User Permessions
                  </TableCell> */}
            <TableCell align='center'>readOnly</TableCell>
            <TableCell align='center'>writeOnly</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.length > 0 &&
            features.map(row => {
              return (
                <TableRow
                  key={row.roleId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.featureName}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {getUserPermessionsById(row.id) &&
                    getUserPermessionsById(row.id) ? (
                      <CheckCircleOutlineIcon style={{ fill: 'green' }} />
                    ) : (
                      <CloseIcon style={{ fill: 'red' }} />
                    )}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {getUserPermessionsByWriteId(row.id) &&
                    getUserPermessionsByWriteId(row.id) ? (
                      <CheckCircleOutlineIcon style={{ fill: 'green' }} />
                    ) : (
                      <CloseIcon style={{ fill: 'red' }} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function UserRoleDialog({ open, handleClose }) {
  const [userRoles, setUserRoles] = useState([]);
  const [value, setValue] = React.useState(0);
  const [features, setFeatures] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [permessionData, setPermessionData] = useState([]);
  const [permessionDataFiltered, setPermessionDataFiltered] = useState([]);
  useEffect(() => {
    UserRoles.getAllUserRoles(response => {
      setUserRoles(response.data);
    });
    RolesAndPermissions.getAllFeatures(response => {
      setFeaturesData(response.data);
      setFeatures(response.data);
    });
    RolesAndPermissions.getAllPermissions(response => {
      setPermessionDataFiltered(response.data);
      setPermessionData(response.data);
    });
  }, []);

  const handleChange = (event, tabValue, newValue) => {
    setValue(tabValue);
    let filterTabPermessions = permessionData.filter(item => {
      return item.roleId === newValue.id;
    });
    setPermessionDataFiltered(filterTabPermessions);

    setFeatures(featuresData);
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle className='d-flex justify-content-center'>
          <h3> User Role Feature And Permission</h3>
        </DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            // onChange={handleChange}
          >
            {userRoles.map((item, index) => {
              return (
                <Tab
                  key={index}
                  onClick={e => handleChange(e, index, item)}
                  label={item.name}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box className='d-flex mt-2 mb-0 justify-content-around'>
          <h6>User Features</h6>
          <h6>User Permission</h6>
        </Box>

        <TabPanel value={value} index={0}>
          <TableForUserRole
            permessionData={permessionDataFiltered}
            features={features}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableForUserRole
            permessionData={permessionDataFiltered}
            features={features}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableForUserRole
            permessionData={permessionDataFiltered}
            features={features}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TableForUserRole
            permessionData={permessionDataFiltered}
            features={features}
          />
        </TabPanel>
      </Dialog>
    </div>
  );
}
