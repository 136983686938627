import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import Customer from '../../modules/Customer';
import { Typography, Button } from '@mui/material';
import ReactToPdf from 'react-to-pdf';
import ReactToPrint from 'react-to-print';
import Bills from '../../modules/Bills';
import Auth from '../../modules/Auth';
import Logo from '../../modules/Logo';
import Store from '../../modules/Store';
import HTMLRenderer from 'react-html-renderer';
import Validation from '../../modules/Validation';
import logo from '../../assets/my-logo.png';
import signature from '../../assets/my-signature.png';
import { serverConfig } from '../../config/server-config';

const ref = React.createRef();

let sum = 0;
let paymentSum = 0;

class CustomerBillDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: null,
      signatureUrl: null,
      currency: null,
      options: {
        unit: 'pt',
        format: [900, 300],
      },
      customerId: props.customerId,
      billData: {},
      customerBalance: {},
      storeAccount: {},
      storeDetails: {},
    };
  }

  componentDidMount() {
    sum = 0;
    paymentSum = 0;

    Bills.getBillById(
      this.props.billData.id,
      Store.getStoreTimeZone(),
      response => {
        if (response.status === 'success') {
          this.setState(
            {
              billData: {
                ...response.data,
                startDate: this.props.billData.startDate,
                endDate: this.props.billData.endDate,
                dueDate: this.props.billData.dueDate,
              },
            },
            () => {
              {
              }
            }
          );
        } else {
          console.log('error');
        }
      }
    );
    this.customerBalance();
    this.storeAccount();
    this.getLogoUrl(Auth.getStoreId());
    this.getSignatureUrl();

    Store.getStoreByStoreId(Auth.getStoreId(), response => {
      this.setState({ storeDetails: { ...response.data } });

      this.getCurrency();

      setTimeout(() => {
        let opt = { ...this.state.options };
        let maxHeight = document.getElementById('divToPrint').clientHeight;
        let maxWidth = document.getElementById('divToPrint').clientWidth - 80;
        opt.format = [maxHeight, maxWidth];
        this.setState({ options: opt });
      }, 5);
    });
  }

  getCurrency = () => {
    this.setState({ currency: Store.getCurrency() });
  };

  getLogoUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        logoUrl: Logo.getLogoUrl(Auth.getStoreId()),
      });
    } else {
      this.setState({
        logoUrl: logo,
      });
    }
  };

  getSignatureUrl = () => {
    if (serverConfig.isUpstoreCloud) {
      this.setState({
        signatureUrl: Logo.getSignatureUrl(),
      });
    } else {
      this.setState({
        signatureUrl: signature,
      });
    }
  };
  customerBalance = () => {
    let customerId = { customerIds: [this.state.customerId] };
    Customer.getCustomerBalance(customerId, response => {
      if (response.status === 'success') {
        this.setState({ customerBalance: { ...response.data[0] } });
      } else {
        this.setState({ customerBalance: {} });
      }
    });
  };
  storeAccount = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        let selctedBank = response.data.find(item => item.active);
        this.setState({ storeAccount: selctedBank });
      } else {
        this.setState({ storeAccount: [] });
      }
    });
  };

  render() {
    return (
      <div className='App container invoice-wrap'>
        <Typography>
          <ReactToPrint
            pageStyle={`@page { size: ${this.state.options.format[1]}pt ${this.state.options.format[0]}pt }`}
            trigger={() => {
              return (
                <Button
                  variant='contained'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    bgcolor: '#3B78C9',

                    width: '126px',
                    height: '27px',
                  }}
                >
                  <PrintIcon sx={{ color: '#fff', mr: 1 }} /> Print
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
          &nbsp;&nbsp;
          <ReactToPdf
            targetRef={ref}
            options={this.state.options}
            // filename={`receipt__${this.state.orderDetails.storeOrderNumber}.pdf`}
          >
            {({ toPdf }) => (
              <Button
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  bgcolor: '#3B78C9',

                  width: '126px',
                  height: '27px',
                }}
                onClick={toPdf}
              >
                <DownloadIcon sx={{ color: '#fff', mr: 1 }} /> Download
              </Button>
            )}
          </ReactToPdf>
        </Typography>
        <br />
        <div className='divToDownload' ref={ref}>
          <div
            id='divToPrint'
            ref={el => (this.componentRef = el)}
            style={{ height: '100%' }}
          >
            {Validation.isBlankNot(this.state.logoUrl) ? (
              <img
                className='invoice-bg'
                style={{ width: '100%', height: '100%' }}
                src={this.state.logoUrl}
                alt='Logo'
              ></img>
            ) : (
              `Logo`
            )}
            <div className='invoice-content row d-flex justify-content-center'>
              <div className='col-12'>
                <div className='card'>
                  <div className='d-flex flex-row p-2'>
                    <div className='d-flex flex-column'>
                      {/* <img
                        style={{ maxWidth: '100px', maxHeight: '50px' }}
                        src={Logo}
                      /> */}
                      {Validation.isBlankNot(this.state.logoUrl) ? (
                        <img
                          style={{ maxWidth: '100px', maxHeight: '50px' }}
                          src={this.state.logoUrl}
                          alt='Logo'
                        ></img>
                      ) : (
                        `Logo`
                      )}
                    </div>
                  </div>

                  <hr />
                  <div className='table-responsive p-2'>
                    <table
                      className='table table-borderless'
                      style={{ flex: 'start' }}
                    >
                      <tbody>
                        <tr className='invoice_Hed'>
                          <th></th>
                          <th>Customer Details </th>
                        </tr>
                        <tr className='content'>
                          <td>
                            {' '}
                            {`${this.state.storeDetails.name}`}
                            <br />
                            {`${this.state.storeDetails.address}`}
                          </td>
                          <td className='font-weight-bold'>
                            {`${
                              Customer.getCustomerById(
                                this.state.customerId,
                                () => {}
                              )?.customerName
                            }`}

                            <br />
                            {Customer.getCustomerById(
                              this.state.customerId,
                              () => {}
                            ) &&
                              `${
                                Customer.getCustomerById(
                                  this.state.customerId,
                                  () => {}
                                ).address
                              }`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div>
                    <div>
                      <h4 style={{ fontSize: '14px', paddingLeft: '4px' }}>
                        Bill Id :-
                        <span>
                          {this.state.billData && this.state.billData.billNo}
                        </span>
                      </h4>
                      <h4 style={{ fontSize: '14px', paddingLeft: '4px' }}>
                        Date :- &nbsp;
                        <span>
                          {this.state.billData.startDate &&
                            this.state.billData.startDate.substring(0, 10)}
                        </span>
                        &nbsp;&nbsp;
                        <span>To</span>&nbsp;
                        <span>
                          {' '}
                          {this.state.billData.startDate &&
                            this.state.billData.endDate.substring(0, 10)}
                        </span>
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className='products p-2'>
                    <table
                      className='table'
                      style={{ borderRadius: '1px solid black' }}
                    >
                      <tbody>
                        <tr className='add'>
                          <th>Opening Account Date</th>
                          <th>Current Balance </th>
                          <th>Due Date</th>
                        </tr>
                        <tr>
                          <td>
                            {this.state.billData.startDate &&
                              this.state.billData.startDate.substring(0, 10)}
                          </td>
                          <td>
                            {this.state.customerBalance.balanceAmount &&
                              this.state.customerBalance.balanceAmount}{' '}
                            {this.state.currency ? (
                              <HTMLRenderer html={this.state.currency} />
                            ) : null}
                          </td>
                          <td>
                            {this.state.billData.dueDate &&
                              this.state.billData.dueDate.substring(0, 10)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='products p-2'>
                    <table
                      className='table'
                      style={{ borderRadius: '1px solid black' }}
                    >
                      <tbody>
                        <tr className='add'>
                          <th>S.No</th>
                          <th>Order </th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                        {this.state.billData.orders !== undefined
                          ? this.state.billData.orders.map((order, index) => {
                              sum = sum += order.totalAmount;
                              return (
                                <tr className='content '>
                                  <td>{index + 1}</td>
                                  <td>{order.storeOrderNumber} </td>
                                  <td>{order.invoiceDate.substring(0, 10)} </td>
                                  <td>{order.totalAmount} </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                    <div
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <h4
                        style={{
                          fontSize: '15px',
                          textAlign: 'right',
                          paddingRight: '3px',
                        }}
                      >
                        Sub Total :&nbsp; {sum.toFixed(3)} &nbsp;
                        {this.state.currency ? (
                          <HTMLRenderer html={this.state.currency} />
                        ) : null}
                      </h4>
                    </div>
                    <table
                      className='table'
                      style={{ borderRadius: '1px solid black' }}
                    >
                      <tbody>
                        <tr className='add'>
                          <th>S.No</th>
                          <th>Payment Remark</th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                        {this.state.billData.payments !== undefined
                          ? this.state.billData.payments.map(
                              (payment, index) => {
                                paymentSum = paymentSum += payment.amount;

                                return (
                                  <tr className='content '>
                                    <td>{index + 1}</td>
                                    <td>{payment.remark} </td>
                                    <td>
                                      {payment.creationDate.substring(0, 10)}{' '}
                                    </td>
                                    <td>
                                      {payment.amount}{' '}
                                      {this.state.currency ? (
                                        <HTMLRenderer
                                          html={this.state.currency}
                                        />
                                      ) : null}{' '}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <h4
                        style={{
                          fontSize: '15px',
                          textAlign: 'right',
                          paddingRight: '3px',
                          float: 'right',
                        }}
                      >
                        Sub Total :&nbsp; {paymentSum.toFixed(3)} &nbsp;
                        {this.state.currency ? (
                          <HTMLRenderer html={this.state.currency} />
                        ) : null}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <h4
                    style={{
                      fontSize: '15px',
                      textAlign: 'right',
                      paddingRight: '3px',
                      float: 'right',
                    }}
                  >
                    Bill Balance :&nbsp;
                    {(sum - paymentSum).toFixed(3)}
                    &nbsp;
                    {this.state.currency ? (
                      <HTMLRenderer html={this.state.currency} />
                    ) : null}
                  </h4>
                  <hr />
                  <div className='address p-2' style={{ display: 'flex' }}>
                    <div
                      style={{
                        flex: 'start',
                      }}
                    >
                      <table className='table table-borderless '>
                        <tbody>
                          <tr className='invoice_Hed'>
                            <th>Bank Details</th>
                          </tr>
                          <tr className='content'>
                            <td>
                              Bank Name :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.storeAccount,
                                'bankName'
                              )
                                ? this.state.storeAccount.bankName
                                : 'N/A'}
                              <br /> IFSC-Code :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.storeAccount,
                                'ifscNumber'
                              )
                                ? this.state.storeAccount.ifscNumber
                                : 'N/A'}
                              <br /> Branch Address :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.storeAccount,
                                'branchDetails'
                              )
                                ? this.state.storeAccount.branchDetails
                                : 'N/A'}
                              <br />
                              Account Number :{' '}
                              {Validation.isBlankObjectKeyNot(
                                this.state.storeAccount,
                                'accountNo'
                              )
                                ? this.state.storeAccount.accountNo
                                : 'N/A'}
                              <br />{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      className='d-flex flex-column'
                      style={{ paddingTop: '2rem ', paddingLeft: '12rem' }}
                    >
                      <h6>Signature</h6>
                      <img
                        style={{ maxWidth: '100px', maxHeight: '50px' }}
                        src={this.state.signatureUrl}
                        Alt='Signature'
                      />

                      {/* {Validation.isBlankNot(this.state.logoUrl) ? (
                        <img
                          className="invoice-bg"
                          style={{ maxWidth: "100px", maxHeight: "50px" }}
                          src={this.state.logoUrl}
                          alt="Signature"
                        ></img>
                      ) : (
                        `Logo`
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomerBillDialog;
