// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { TextField } from '@mui/material';
const textField = {
  width: '90%',
  marginTop: '6px',
};
class DatePickerComp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={this.props.disabled}
            label={this.props.label}
            value={this.props.value}
            inputFormat='dd/MM/yyyy'
            maxDate={this.props.maxDate}
            onChange={newValue => {
              if (this.props.type === 'invoice') {
                this.props.inovoiceDateChange(newValue);
              } else {
                this.props.dateChange(newValue);
              }
            }}
            minDate={this.props.minDate}
            renderInput={params => (
              <TextField size='small' sx={textField} {...params} />
            )}
          />
        </LocalizationProvider>
      </>
    );
  }
}

export default DatePickerComp;
