import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Link,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './styles.css';
import Utils from '../../modules/Utils';
import Validation from '../../modules/Validation';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import User from '../../modules/User';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });

  // for navigation through routes
  let navigate = useNavigate();
  // for getting url info
  const location = useLocation();
  console.log(location, 'location');
  useEffect(() => {
    const resetCode = Utils.queryParams(location.search, 'resetcode');
    resetCode ? setFormSubmitted(true) : setFormSubmitted(false);
    setResetCode(resetCode);
    console.log(resetCode, 'resetCode');
  }, []);

  const callResetPasswordApi = () => {
    if (Validation.validateEmail(email)) {
      User.forgotPassword(email, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar(
            'Reset password link has been sent to your email address',
            'success'
          );
          setFormSubmitted(false);
        } else if (response.status === 'invalid') {
          openUpstoreSnackBar(
            'Invalid email ! please provide correct email',
            'error'
          );
        } else if (response.status === 'error') {
          openUpstoreSnackBar('Server Error , please try again later', 'error');
        } else if (response.status === 'finally') {
          setFormSubmitted(false);
        }
      });
    } else {
      openUpstoreSnackBar(
        'Email format is not correct , please provide a valid email',
        'error'
      );
      setFormSubmitted(false);
    }
  };

  // for snackbar
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const ResetPassword = event => {
    event.preventDefault();
    callResetPasswordApi();
  };

  const validatePassword = event => {
    event.preventDefault();
    if (!Validation.validatePassword(event.target.value)) {
      setValidPassword(false);
    } else {
      setValidPassword(true);
    }
  };

  const setNewPassword = event => {
    event.preventDefault();
    let data = {
      resetcode: resetCode,
      password: confirmPassword,
    };
    if (validPassword && password === confirmPassword) {
      setFormSubmitted(true);
      User.resetPassword(data, response => {
        if (response.status === 'success') {
          openUpstoreSnackBar(
            'Your password has been changed successfully, redirecting to login',
            'success'
          );
          setTimeout(() => {
            loginInstead();
          }, 2000);
        } else if (response.status === 'error') {
          openUpstoreSnackBar(
            'Sorry! there was an error in resetting your password',
            'error'
          );
        } else {
          openUpstoreSnackBar('Server Error', 'error');
        }
      });
    }
  };

  const loginInstead = () => {
    navigate('/');
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#e1ebfa',
          minHeight: '100vh',
          minWidth: '100%',
        }}
      >
        <Container
          component='main'
          maxWidth='xs'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '8rem',
          }}
        >
          <Typography>
            <img
              src={'./logo512.png'}
              style={{
                height: '200px',
                width: '300px',
                margin: '10px',
                objectFit: 'contain',
              }}
              alt={'ROI'}
              // onClick={GO TO Website}
              sx={{ mt: 2, mb: '10px' }}
            />
          </Typography>

          {!resetCode ? (
            <form style={{ width: '100%', mt: 8 }} noValidate>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    variant='outlined'
                    required
                    fullWidth={true}
                    id='email'
                    label='Enter Registered email'
                    name='email'
                    autoComplete='off'
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Box textAlign='center'>
                <Button
                  sx={{
                    p: 0,
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                  }}
                  type='submit'
                  fullWidth={true}
                  variant='contained'
                  color='primary'
                  className='reset__button'
                  disabled={formSubmitted}
                  onClick={event => {
                    setFormSubmitted(true);
                    ResetPassword(event);
                  }}
                >
                  Reset Password
                </Button>
              </Box>
            </form>
          ) : (
            // Set Password View
            <form sx={{ width: '100%', mt: 3 }} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth={true}
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    onBlur={validatePassword}
                  />
                  {validPassword ? (
                    <CheckCircleIcon
                      className='Signup-svgPos Signup-svgPos--success'
                      color='primary'
                    />
                  ) : null}
                  {!validPassword && password ? (
                    <CancelIcon className='Signup-svgPos' color='error' />
                  ) : null}
                  {!validPassword && password ? (
                    <Link shrink>
                      {
                        'Password not valid. Select an alpha numeric password (should contain atleast one capital letter) , should be least 6 letters.'
                      }
                    </Link>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth={true}
                    name='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    id='confirmPassword'
                    autoComplete='current-password'
                    value={confirmPassword}
                    onChange={event => setConfirmPassword(event.target.value)}
                  />
                  {password === confirmPassword && confirmPassword.length ? (
                    <CheckCircleIcon
                      className='Signup-svgPos Signup-svgPos--success'
                      color='primary'
                    />
                  ) : null}
                  {password !== confirmPassword ? (
                    <CancelIcon className='Signup-svgPos' color='error' />
                  ) : null}
                  {password !== confirmPassword ? (
                    <Link shrink>
                      {'Confirm Password should be same as above password'}
                    </Link>
                  ) : null}
                </Grid>
              </Grid>
              <Box textAlign='center'>
                <Button
                  sx={{
                    p: 0,
                    textTransform: 'capitalize',
                    width: '126px',
                    height: '27px',
                    mt: 3,
                    mr: 0,
                    mb: 2,
                  }}
                  type='submit'
                  fullWidth={true}
                  variant='contained'
                  color='primary'
                  className='reset__button'
                  onClick={event => {
                    setNewPassword(event);
                    setFormSubmitted(true);
                  }}
                >
                  Set Password
                </Button>
              </Box>
            </form>
          )}
          <br />
          <br />
          <Typography>
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={loginInstead}
            >
              Already a user, Login!
            </Link>
          </Typography>
        </Container>
      </Box>
      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </>
  );
};

export default ForgotPassword;
