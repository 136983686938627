import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Prescription from '../../modules/Prescription';
import Validation from '../../modules/Validation';
import { TextField, Typography } from '@mui/material';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import OpticalReciept from '../opticalReciept/OpticalReciept';
import { Close } from '@mui/icons-material';
import PrescriptionHistory from '../prescriptionHistory/PrescriptionHistory';

export default function OpticalPrescription(props) {
  const [prescriptionData, setPrescriptionData] = React.useState([]);
  const [prescriptionHistory, setPrescriptionHistory] = React.useState([]);
  const [disableShowButton, setDisableShowButton] = React.useState(false);
  const [opticalInvDialog, setOpticalInvDialog] = React.useState(false);
  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({
    open: false,
  });
  const [showHistoryButton, setShowHistoryButton] = React.useState(false);

  const rows = [{ rx: 'RE' }, { rx: 'LE' }];
  React.useEffect(() => {
    getPrescription();
  }, []);

  const getPrescription = () => {
    Prescription.getPrescriptionByCustomerId(props?.customerId, response => {
      if (response.status === 'success') {
        if (Validation.isBlankObjectNot(response.data)) {
          let HistoryTests = response.data.filter((item, idx) => idx >= 2);
          setPrescriptionHistory(HistoryTests);
          let NewTests = response.data.filter((item, idx) => idx < 2);

          setPrescriptionData(NewTests);
          setDisableShowButton(true);
        } else {
          setPrescriptionData(rows);
        }
      } else {
        console.log('err');
      }
    });
  };

  const addNewPrescription = () => {
    let data = prescriptionData.map(item => {
      item.customerId = props?.customerId;
      item.storeId = Auth?.getStoreId();
      return item;
    });

    Prescription.addPrescription(data, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('  Prescription Added successfully', 'success');
        props?.close();
      } else {
        openUpstoreSnackBar(
          'Error Occured while adding  Prescription ',
          'error'
        );
      }
    });
  };

  const handleInputchange = (name, value, row, index) => {
    let updatedState = [...prescriptionData];
    updatedState[index][name] = value;

    setPrescriptionData([...updatedState]);
  };

  const updatePrescrition = row => {
    Prescription.updatePrescription(row, row.id, response => {
      if (response.status === 'success') {
        openUpstoreSnackBar('Prescription updated successfully', 'success');
        getPrescription();
      } else {
        openUpstoreSnackBar(
          'Error Occured while Updating  Prescription ',
          'error'
        );
      }
    });
  };
  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };

  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };

    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={props.close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='lg'
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant='h6'>EYE PRESCRIPTION</Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',

                backgroundColor: '#3B78C9',
                color: 'white',
                p: 0,
                width: '126px',
                height: '27px',
                mb: 2,
                mr: 2,
              }}
              onClick={() => {
                setPrescriptionData(rows);
                setDisableShowButton(false);
              }}
            >
              New Prescription
            </Button>
            <Button
              variant='contained'
              sx={{
                textTransform: 'capitalize',

                backgroundColor: '#3B78C9',
                color: 'white',
                p: 0,
                width: '126px',
                height: '27px',
                mb: 2,
              }}
              onClick={() => {
                setOpticalInvDialog(true);
              }}
            >
              Prescription
            </Button>
          </Box>

          <Box sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }}>
                <TableHead sx={{ backgroundColor: 'secondary.light' }}>
                  <TableRow>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      RX
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      SPH
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      CYL
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      AXIS
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      ADD
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      PRISM
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      VISION
                    </TableCell>

                    <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                      PD
                    </TableCell>

                    {disableShowButton ? (
                      <TableCell align='center'>Update</TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prescriptionData.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        <TextField
                          variant='standard'
                          type='text'
                          disabled={true}
                          value={row.rx}
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='rx'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <TextField
                          variant='standard'
                          type='text'
                          placeholder='Enter sph'
                          value={row.sph}
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='sph'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <TextField
                          variant='standard'
                          value={row?.cyl}
                          type='text'
                          placeholder='Enter cyl'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='cyl'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>

                      <TableCell align='center'>
                        <TextField
                          variant='standard'
                          type='number'
                          value={row?.axis}
                          placeholder='Enter axis'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='axis'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <TextField
                          variant='standard'
                          type='number'
                          value={row?.add}
                          placeholder='Enter add'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='add'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <TextField
                          variant='standard'
                          type='number'
                          value={row?.prism}
                          placeholder='Enter prism'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='prism'
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>

                      <TableCell align='center'>
                        <TextField
                          type='text'
                          size='small'
                          variant='standard'
                          placeholder='Enter vision'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='vision'
                          value={row?.vision}
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <TextField
                          type='text'
                          size='small'
                          variant='standard'
                          placeholder='Enter pd'
                          style={{ height: '30px', width: '90px', p: 2 }}
                          name='pd'
                          value={row?.pd}
                          onChange={event =>
                            handleInputchange(
                              event.target.name,
                              event.target.value,
                              row,
                              index
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {row.id ? (
                          <Button
                            variant='outlined'
                            sx={{
                              textTransform: 'capitalize',

                              color: '#3B78C9',
                              p: 0,
                              width: '80px',
                              height: '27px',
                            }}
                            onClick={() => updatePrescrition(row)}
                          >
                            Update
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'capitalize',

                  color: '#3B78C9',
                  p: 0,
                  width: '126px',
                  mt: 2,
                  mb: 2,
                  height: '27px',
                }}
                onClick={() => setShowHistoryButton(!showHistoryButton)}
              >
                <Typography sx={{ fontSize: '13px' }} noWrap>
                  Prescription History
                </Typography>
              </Button>
            </Box>
            {showHistoryButton ? (
              <Box>
                <PrescriptionHistory historyData={prescriptionHistory} />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            disabled={disableShowButton}
            sx={{
              textTransform: 'capitalize',

              color: '#3B78C9',
              p: 0,
              width: '126px',
              height: '27px',
            }}
            onClick={addNewPrescription}
          >
            Save
          </Button>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'capitalize',

              color: '#3B78C9',
              p: 0,
              width: '126px',
              height: '27px',
            }}
            onClick={props.close}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth='xl'
        open={opticalInvDialog}
        close={() => setOpticalInvDialog(false)}
      >
        <Box display='flex' alignItems='center' justifyContent='end'>
          <Close
            onClick={() => setOpticalInvDialog(false)}
            sx={{ color: '#3B78C9', cursor: 'pointer' }}
          />
        </Box>
        <DialogContent>
          <OpticalReciept
            customerInfo={props?.customerInfo}
            testData={prescriptionData}
          />
        </DialogContent>
      </Dialog>

      {upstoreSnackBar.open ? (
        <UpStoreSnackBar
          upstoreSnackBar={upstoreSnackBar}
          onSnackbarClose={onSnackbarClose}
        />
      ) : null}
    </div>
  );
}
