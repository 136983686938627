import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import UserSubscription from '../../modules/UserSubscription';

export default function DeleteAccountDialog({
  openDailog,
  close,
  storeId,
  snackbar,
  ButtonClicked,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSoftDeleteStore = data => {
    let otp = data.otp;
    if (ButtonClicked === 'hardDelete') {
      UserSubscription.hardDeleteAccount(storeId, otp, response => {
        if (response.status === 'success') {
          if (response.data === 'true') {
            snackbar('Account Has Been Deleted Permanently', 'success');
            close();
          } else {
            snackbar('Incorrect Otp For Hard Delete', 'error');
          }
        } else {
          console.log('err');
        }
      });
    } else {
      UserSubscription.softDeleteAccount(storeId, otp, response => {
        if (response.status === 'success') {
          if (response.data === 'true') {
            snackbar('Account Has Been Deleted Successsfully', 'success');
            close();
          } else {
            snackbar('Incorrect Otp For Soft Delete', 'error');
          }
        } else {
          console.log('err');
        }
      });
    }
  };
  return (
    <div>
      <Dialog open={openDailog} onClose={close}>
        <DialogTitle textAlign={'center'}>Delete Account</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <form
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit(handleSoftDeleteStore)}
            >
              <Typography variant='subtitle1 '>Enter Otp </Typography>
              <TextField
                {...register('otp', {
                  required: 'otp is required',
                  maxLength: {
                    value: 6,
                    message: 'Otp must be 6 digits',
                  },
                  pattern: {
                    value: /^[+-]?\d*(?:[.,]\d*)?$/,
                    message: 'Otp must be only digits',
                  },
                })}
                sx={{ width: '100%', mt: 1 }}
                size='small'
                type='number'
                placeholder='Enter Otp Here'
                variant='outlined'
                error={Boolean(errors.otp)}
                helperText={errors.otp?.message}
              />
              <Button
                variant='contained'
                type='submit'
                sx={{
                  p: 0,
                  mt: 1,
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  width: '126px',
                  height: '27px',
                  color: '#ffff',
                  backgroundColor: '#3B78C9',
                }}
              >
                Delete Account
              </Button>
            </form>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
