import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import UpStoreSnackBar from '../../component/upStoreSnackBar/UpStoreSnackBar';
import Auth from '../../modules/Auth';
import Validation from '../../modules/Validation';
import Store from '../../modules/Store';
import { Loading } from '../../component/loading/Loading';
import Header from '../../component/header/Header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BreadCrumb from '../../component/breadcrumd/BreadCrumb';
import EditIcon from '@mui/icons-material/Edit';
import BankStatementDialog from '../../component/bankStatementDialog/BankStatementDialog';

const AccountsSection = () => {
  const [bankDetail, setBankDetail] = React.useState([]);

  const [upstoreSnackBar, setUpstoreSnackBar] = React.useState({ open: false });
  const [loader, setLoader] = React.useState(true);
  const [addBank, setAddBank] = React.useState({ open: false, data: {} });
  const [openStatementDailog, setOpenStatementDialog] = React.useState({
    open: false,
    data: {},
  });
  const [bankdropDown, setBankdropdown] = React.useState({});
  React.useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = () => {
    Store.getStoreAccountsByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        setBankDetail(response.data);
        let foundData = response.data.find(item => item.active);
        setBankdropdown(foundData);
        setLoader(false);
      } else {
        setBankDetail({});
      }
    });
  };

  const onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    setUpstoreSnackBar(upstoreSnackbarObject);
  };
  const openAddBankDialog = bankData => {
    setAddBank({ ...addBank, open: true, data: bankData });
  };
  const statementDialog = dat => {
    setOpenStatementDialog({ ...openStatementDailog, open: true, data: dat });
  };
  const handleSelectBankOnInvoice = item => {
    setLoader(true);
    // let id = e.target.value;
    // let bankdd = bankDetail.find(item => item.id === id);
    let data = {
      ...item,
      active: true,
      storeId: Auth.getStoreId(),
    };
    Store.updateStoreAccDetails(data.id, data, response => {
      if (response.status == 'success') {
        setLoader(false);
        openUpstoreSnackBar('Account details Updated successfully', 'success');

        getAccountDetails();
      } else {
        openUpstoreSnackBar('error while updating  Account details', 'success');
        setLoader(false);
      }
    });
  };
  const handleCloseStatementDialog = () => {
    setOpenStatementDialog({ ...openStatementDailog, open: false });
  };
  return (
    <>
      <Header pageName='Accounts Section' />
      <Box
        sx={{
          paddingLeft: '3.5rem',
          paddingRight: '.s2rem',
          mt: 6,
        }}
      >
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2, ml: 2 }}>
          <BreadCrumb pageName='Accounts' />
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{ float: 'right', mr: 2, mb: 2 }}
          >
            <Button
              variant='contained'
              sx={{
                p: 0,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
                color: 'white',
                bgcolor: '#3B78C9',
              }}
              onClick={() =>
                openAddBankDialog({
                  accountNo: '',
                  ifscNumber: '',
                  bankName: '',
                  branchDetails: '',
                  balance: '',
                })
              }
            >
              <Typography variant='h5' sx={{ fontSize: '13px' }} noWrap>
                Add New Bank
              </Typography>
            </Button>
          </Box>
        </Grid>
        {loader ? (
          <Loading />
        ) : (
          <Grid container sx={{ pl: 2, pr: 2, backgroundColor: '#fff' }}>
            {bankDetail?.map((bankDetail, index) => {
              return (
                <Accordion
                  key={bankDetail.id}
                  defaultExpanded={index === 0 ? true : false}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ backgroundColor: '#E1EEF5' }}
                  >
                    <Typography variant='subtitle'>
                      {`Bank Name : ${bankDetail.bankName}(
                      ${bankDetail.accountNo?.slice(-4)})`}
                    </Typography>
                    &nbsp; &nbsp;
                    <Tooltip title='Edit Bank Detail' placement='bottom'>
                      <Button
                        variant='contained'
                        sx={{
                          p: 0,
                          textTransform: 'capitalize',
                          width: '126px',
                          height: '27px',
                          color: 'white',
                          bgcolor: '#3B78C9',
                        }}
                        onClick={() => openAddBankDialog(bankDetail)}
                        endIcon={
                          <EditIcon sx={{ color: 'white', fontSize: '9px' }} />
                        }
                      >
                        Edit Detail
                      </Button>
                    </Tooltip>
                    <Button
                      variant='contained'
                      sx={{
                        p: 0,
                        ml: 3,
                        textTransform: 'capitalize',
                        width: '126px',
                        height: '27px',
                        color: 'white',
                        bgcolor: '#3B78C9',
                      }}
                      onClick={() => statementDialog(bankDetail)}
                    >
                      <Typography>View Statement</Typography>
                    </Button>
                    <Box sx={{ ml: 'auto' }}>
                      {!bankDetail.active ? (
                        <Button
                          variant='contained'
                          sx={{
                            p: 0,
                            textTransform: 'capitalize',
                            width: '126px',
                            height: '27px',
                            color: 'white',
                            bgcolor: '#3B78C9',
                          }}
                          onClick={() => handleSelectBankOnInvoice(bankDetail)}
                        >
                          set Default bank
                        </Button>
                      ) : (
                        <Typography variant='subtitle'>
                          Default Selected Bank
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={5}>
                      {/* <Grid display='flex' item md={12} sm={12} xs={12}>
                              <Grid>
                                <Typography
                                  variant='h5'
                                  sx={{
                                    color: '#3B78C9',
                                    pl: 1,
                                    pt: 1,
                                    pb: 1,
                                    width: '100%',
                                    backgroundColor: '#E1EEF5',
                                  }}
                                  noWrap
                                >
                                  Bank Details
                                </Typography>
                              </Grid>
                              <Grid
                                display='flex'
                                justifyContent='flex-end'
                                alignItems='center'
                                sx={{
                                  width: '100%',
                                  float: 'right',
                                  color: '#3B78C9',
                                  backgroundColor: '#E1EEF5',
                                }}
                              >
                                <Typography variant='h5'>Edit</Typography>
                                <Switch onClick={handleBankDetail} />
                              </Grid>
                            </Grid> */}

                      <Grid item md={6} sm={6} xs={12}>
                        <Typography variant='BUTTON TEXT'>
                          Account Number
                        </Typography>
                        <TextField
                          sx={{ width: '100%', mt: '10px' }}
                          id='brand'
                          size='small'
                          disabled
                          placeholder='Enter Description'
                          variant='outlined'
                          name='accountNo'
                          autoCompelete='off'
                          value={
                            Validation.isBlankObjectKeyNot(
                              bankDetail,
                              'accountNo'
                            )
                              ? bankDetail.accountNo
                              : ''
                          }

                          //   disabled={bank === false}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <Typography variant='BUTTON TEXT'>IFSC Code</Typography>
                        <TextField
                          sx={{ width: '100%', mt: '10px' }}
                          id='brand'
                          size='small'
                          disabled
                          placeholder='Enter Description'
                          variant='outlined'
                          autoCompelete='off'
                          name='ifscNumber'
                          value={bankDetail.ifscNumber}

                          //   disabled={bank === false}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <Typography variant='BUTTON TEXT'>Bank Name</Typography>
                        <TextField
                          sx={{ width: '100%', mt: '10px' }}
                          id='brand'
                          size='small'
                          placeholder='Enter Description'
                          variant='outlined'
                          disabled
                          name='bankName'
                          autoCompelete='off'
                          value={bankDetail.bankName}

                          //   disabled={bank === false}
                        />
                      </Grid>
                      {Store?.companyDetails?.businessType === 'MANDI' ? (
                        <Grid item md={6} sm={6} xs={12}>
                          <Typography variant='BUTTON TEXT'>
                            {' '}
                            Balance
                          </Typography>
                          <TextField
                            sx={{ width: '100%', mt: '10px' }}
                            id='brand'
                            size='small'
                            disabled
                            placeholder='Enter Balance'
                            variant='outlined'
                            name='balance'
                            autoCompelete='off'
                            value={bankDetail.balance}
                          />
                        </Grid>
                      ) : null}
                      <Grid item md={6} sm={6} xs={12}>
                        <Typography variant='BUTTON TEXT'>Address</Typography>
                        <TextField
                          sx={{ width: '100%', mt: '10px' }}
                          id='brand'
                          size='small'
                          placeholder='Enter Description'
                          variant='outlined'
                          name='branchDetails'
                          disabled
                          autoCompelete='off'
                          value={bankDetail.branchDetails}

                          //   disabled={bank === false}
                        />
                      </Grid>
                      {/* <Grid item md={12} xs={12} sm={12}>
                        <Box
                          display='flex'
                          justifyContent='flex-end'
                          sx={{ float: 'right' }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              p: 0,
                              textTransform: 'capitalize',
                              width: '126px',
                              height: '27px',
                              color: 'white',
                              bgcolor: '#3B78C9',
                            }}
                            onClick={saveBankDetails}
                            // disabled={bank === false}
                          >
                            <Typography
                              variant='h5'
                              sx={{ fontSize: '13px' }}
                              noWrap
                            >
                              Save Bank Details
                            </Typography>
                          </Button>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}

            {upstoreSnackBar.open ? (
              <UpStoreSnackBar
                upstoreSnackBar={upstoreSnackBar}
                onSnackbarClose={onSnackbarClose}
              />
            ) : null}
            {addBank.open ? (
              <AddNewBank
                openPopup={addBank.open}
                onClose={() => setAddBank({ ...addBank, open: false })}
                accData={addBank.data}
                getAccountDetails={getAccountDetails}
                snackBar={openUpstoreSnackBar}
              />
            ) : null}
            {openStatementDailog.open ? (
              <BankStatementDialog
                openPopup={openStatementDailog.open}
                closePopup={handleCloseStatementDialog}
                bankId={openStatementDailog?.data?.id}
              />
            ) : null}
          </Grid>
        )}
      </Box>
    </>
  );
};
const AddNewBank = props => {
  const [accDetail, setAccDetail] = React.useState(props.accData);
  const saveBankDetails = () => {
    if (checkFieldsVal()) {
      let data = { ...accDetail, storeId: Auth.getStoreId() };
      Store.saveStoreAccounts(data, response => {
        if (response.status == 'success') {
          props.snackBar('Account details added successfully', 'success');
          props.onClose();
          props.getAccountDetails();
        } else {
          props.snackBar('Account details  not added successfully', 'error');
        }
      });
    } else {
      props.snackBar('fill all details ', 'error');
    }
  };
  const updateBankDetails = () => {
    let data = { ...accDetail, storeId: Auth.getStoreId() };
    Store.updateStoreAccDetails(data.id, data, response => {
      if (response.status == 'success') {
        props.snackBar('Account details Updated successfully', 'success');
        props.onClose();
        props.getAccountDetails();
      } else {
        props.snackBar('error while updating  Account Details', 'error');
      }
    });
  };

  const handleBankinput = event => {
    let { name, value } = event.target;
    setAccDetail({ ...accDetail, [name]: value });
  };

  const checkFieldsVal = () => {
    if (
      Store?.companyDetails?.businessType !== 'MANDI' &&
      accDetail.accountNo !== '' &&
      accDetail.ifscNumber !== '' &&
      accDetail.bankName !== '' &&
      accDetail.branchDetails !== ''
    ) {
      return true;
    } else if (
      Store?.companyDetails?.businessType === 'MANDI' &&
      accDetail.accountNo !== '' &&
      accDetail.ifscNumber !== '' &&
      accDetail.bankName !== '' &&
      accDetail.branchDetails !== '' &&
      accDetail.balance !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog open={props.openPopup} fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>Add New Bank</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>Account Number</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Account No'
              variant='outlined'
              name='accountNo'
              autoCompelete='off'
              value={accDetail.accountNo}
              onChange={handleBankinput}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>IFSC Code</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Ifsc Number'
              variant='outlined'
              autoCompelete='off'
              name='ifscNumber'
              value={accDetail.ifscNumber}
              onChange={handleBankinput}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>Bank Name</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Bank Name'
              variant='outlined'
              name='bankName'
              autoCompelete='off'
              value={accDetail.bankName}
              onChange={handleBankinput}
            />
          </Grid>
          {Store?.companyDetails?.businessType === 'MANDI' ? (
            <Grid item md={6} sm={6} xs={12}>
              <Typography variant='BUTTON TEXT'> Balance</Typography>
              <TextField
                sx={{ width: '100%', mt: '10px' }}
                id='brand'
                size='small'
                placeholder='Enter Balance'
                variant='outlined'
                name='balance'
                autoCompelete='off'
                value={accDetail.balance}
                onChange={handleBankinput}
              />
            </Grid>
          ) : null}
          <Grid item md={6} sm={6} xs={12}>
            <Typography variant='BUTTON TEXT'>Address</Typography>
            <TextField
              sx={{ width: '100%', mt: '10px' }}
              id='brand'
              size='small'
              placeholder='Enter Branch details'
              variant='outlined'
              name='branchDetails'
              autoCompelete='off'
              value={accDetail.branchDetails}
              onChange={handleBankinput}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'capitalize',
              width: '126px',
              height: '27px',
            }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          {props.accData.id ? (
            <Button
              variant='contained'
              // disabled={props?.confirmedOrder}
              sx={{
                padding: 0,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
              onClick={() => updateBankDetails()}
            >
              Update Bank
            </Button>
          ) : (
            <Button
              variant='contained'
              // disabled={props?.confirmedOrder}
              sx={{
                padding: 0,
                textTransform: 'capitalize',
                width: '126px',
                height: '27px',
              }}
              onClick={() => saveBankDetails()}
            >
              Add Bank
            </Button>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default AccountsSection;
