import Logger from '../Logger';
import UpstoreApi from '../UpstoreApi';

class Transaction {
  constructor() {}

  template = (storeId, customerId, logMessage, logTitle, args) => {
    return {
      storeId: storeId,
      customerId: customerId,
      message: Logger.format(logMessage, args),
      title: logTitle,
    };
  };

  log = (storeId, customerId, logMessage, logTitle, args) => {
    UpstoreApi({
      method: 'POST',
      url: '/transaction',
      data: this.template(storeId, customerId, logMessage, logTitle, args),
    })
      .then(response => {})
      .catch(error => {
        console.log('error in transaction log api', error.message);
      });
  };
}
export default new Transaction();
