import React, { useRef } from 'react';
import {
  TextField,
  Tooltip,
  TableRow,
  TableCell,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { Add, Close, Lock, Remove } from '@mui/icons-material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Validation from '../../modules/Validation';
import Product from '../../modules/Product';
import Edit from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Store from '../../modules/Store';
import './style.css';
import Auth from '../../modules/Auth';
import Order from '../../modules/Order';
import TaxCalculation from '../../modules/TaxCalculation';
import Autocomplete from '@mui/material/Autocomplete';
import HTMLRenderer from 'react-html-renderer';
import PurchaseOrders from '../../modules/PurchaseOrders';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};
const styles = {
  paddingLeft: 10,
  fontSize: '11px',
};

class ListOrderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {
        ...this.props.row,
        taxRate: {},
      },
      productItemsData: this.props.productItems
        ? [...this.props?.productItems]
        : [],
      // sowManualEditMode: false,
      unitsData: [],

      itemUnitQuantityStorePrize: {
        quantity: null,
        unit: null,
        storePrice: null,
        // packageData: [],
        packageValue: '',
      },
      orderVariety: '',
    };
  }

  componentDidMount() {
    if (
      !this.props.confirmedOrder &&
      Store.companyDetails.businessType === 'MANDI'
    ) {
      TaxCalculation.getCommissionForProduct(
        this.props?.row?.productId,
        this.props?.row?.categoryId,
        response => {
          if (response.status === 'success') {
            let rowDataObjt = {
              ...this.state.rowData,
              commission: response.data,
            };
            this.setState(
              {
                rowData: {
                  ...rowDataObjt,
                },
              },
              () => this.getTexAndUnit()
            );
          }
        }
      );
    } else {
      let rowDataObjt = {
        ...this.state.rowData,
        commission: this.state.rowData?.commission,
      };
      this.setState(
        {
          rowData: {
            ...rowDataObjt,
          },
        },
        () => this.getTexAndUnit()
      );
    }
    this.getTexAndUnit();
    // this.getDefaultPackage();
  }
  // getDefaultPackage = () => {
  //   Product.getDefaultPackages(Auth.getStoreId(), response => {
  //     if (response.status === 'success') {
  //       let filterPack = response.data.filter(item => {
  //         return item !== null;
  //       });

  //       this.setState({ packageData: filterPack });
  //     }
  //   });
  // };

  getTexAndUnit = () => {
    if (this.props?.productItems) {
      this.setState({ productItemsData: [...this.props?.productItems] });
    } else {
      this.handleGetProductByProductId(this.props?.row?.productId);
    }

    if (this.props?.returnOrderMode === true) {
      return;
    } else {
      this.getTaxDataForItem();
    }
    Product.getAllUnits(response => {
      if (response.status === 'success') {
        this.setState({ unitsData: response.data });
      } else {
        this.setState({ unitsData: [] });
      }
    });
  };

  getTaxDataForItem = () => {
    if (Store.getSelectedTaxType() === 'GST') {
      TaxCalculation.getGstByProductIdAndCategoryId(
        this.props?.row?.productId,
        this.props?.row?.categoryId,
        response => {
          if (response.status === 'success' && response.data) {
            if (this.props?.customerDet?.country !== 'IN' && Store.getLutNo()) {
              let rowDataObj = {
                ...this.state.rowData,
                taxRate: {},
              };
              this.updateAmountAndTaxCal(rowDataObj);
            } else {
              let rowDataObj = {
                ...this.state.rowData,
                taxRate: response.data,
                commission: this.state.rowData?.commission,
              };
              this.updateAmountAndTaxCal(rowDataObj);
            }
          } else {
            let rowDataObj = {
              ...this.state.rowData,
              taxRate: {},
            };
            this.updateAmountAndTaxCal(rowDataObj);
          }
        }
      );
    } else if (Store.getSelectedTaxType() === 'VAT') {
      TaxCalculation.getVatByProductIdAndCategoryId(
        this.props?.row?.productId,
        this.props?.row?.categoryId,
        response => {
          if (response.status === 'success' && response.data) {
            let rowDataObj = {
              ...this.state.rowData,
              taxRate: response.data,
              commission: this.state.rowData.commission,
            };
            this.updateAmountAndTaxCal(rowDataObj);
          } else {
            let rowDataObj = {
              ...this.state.rowData,
              taxRate: {},
            };
            this.updateAmountAndTaxCal(rowDataObj);
          }
        }
      );
    } else {
      let rowDataObj = {
        ...this.state?.rowData,
        taxRate: {},
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
  };

  updateAmountAndTaxCal = rowDataObj => {
    let totalStorePrice = parseFloat(
      rowDataObj?.storePrice * rowDataObj?.noOfItems
    );
    let taxValue = null;
    if (this.props.orderObj.storeOrderStatus === 'CONFIRMED') {
      if (this.state.rowData.id == undefined) {
        taxValue = Validation.getGstSumOrVatSum(rowDataObj?.taxRate);
      } else {
        taxValue = this.state.rowData?.taxValue
          ? this.state.rowData?.taxValue
          : 0;
      }
    } else {
      taxValue = this.state.rowData?.taxValue
        ? this.state.rowData?.taxValue
        : Validation.getGstSumOrVatSum(rowDataObj?.taxRate);
    }

    let data = Order.getCalculatedAmount(
      rowDataObj?.discount === '' || rowDataObj?.discount === null
        ? 0
        : rowDataObj?.discount,
      totalStorePrice,
      taxValue,
      this.state.rowData?.commission,
      rowDataObj?.cess === '' || rowDataObj?.cess === null
        ? 0
        : rowDataObj?.cess,
      rowDataObj?.otherCharges
    );

    if (
      this.props.orderObj?.orderDetails?.length > 0 &&
      this.props.orderObj?.storeOrderStatus === 'CONFIRMED'
    ) {
      if (this.state.rowData.id !== undefined) {
        // if (Store.getSelectedTaxType() == 'N/A') {
        //   data.taxAmount = this.state?.rowData?.taxAmount;
        // }
        if (!data.amount) {
          data.amount = this.state?.rowData?.amount;
        }
      }
    }

    this.setState(
      {
        rowData: {
          ...rowDataObj,
          taxRate: taxValue === 0 ? 0 : rowDataObj?.taxRate,
          taxAmount: data?.taxAmount,
          amount: data?.amount,
          taxValue: taxValue,
          taxableAmt: data.taxableAmt,
        },
      },
      () => {
        this.props.updatedRowData(this.state?.rowData);
      }
    );
  };

  handleGetProductByProductId = id => {
    Product.getProductByProductId(id, response => {
      if (response.status === 'success') {
        this.setState({ productItemsData: response.data.productItems });
      } else {
        this.setState({ productItemsData: [] });
      }
    });
  };

  handleQuantityUnitChange = (prevItem, newItem) => {
    this.setState({
      rowData: {
        ...this.state.rowData,
        quantity: newItem.quantity,
        unit: newItem.unit,
      },
    });
    this.props.swipeProductItemCallback(
      prevItem,
      newItem,
      1,
      this.state.productItemsData
    );
  };

  handleOnBlur = event => {
    event.preventDefault();

    let updatedRowData = { ...this.state.rowData };
    let { name, value } = event.target;
    if (name === 'noOfItems') {
      if (value <= 0) {
        updatedRowData.noOfItems = 1;
        let num = parseFloat(
          updatedRowData.storePrice * updatedRowData.noOfItems
        );

        let data = Order.getCalculatedAmount(
          updatedRowData.discount,
          num,
          Validation.getGstSumOrVatSum(this.state.rowData?.taxRate),
          this.state.rowData?.commission,
          this.state.rowData.cess,
          this.state.rowData.otherCharges
        );
        updatedRowData.amount = data?.amount;
        updatedRowData.taxAmount = data?.taxAmount;

        this.props.updatedRowData(updatedRowData);
      }
    } else if (name === 'discount') {
      if (value <= 0) {
        updatedRowData.discount = 0;
        let num = parseFloat(
          updatedRowData.storePrice * updatedRowData.noOfItems
        );

        let data = Order.getCalculatedAmount(
          updatedRowData.discount,
          num,
          Validation.getGstSumOrVatSum(this.state.rowData?.taxRate),
          this.state.rowData?.commission,
          this.state.rowData.cess,
          this.state.rowData.otherCharges
        );
        updatedRowData.amount = data?.amount;
        updatedRowData.taxAmount = data?.taxAmount;

        this.props.updatedRowData(updatedRowData);
      }
    } else {
      if (name === 'commission') {
        // window.removeEventListener('click', props.handlePropCilck());

        if (value >= 0) {
          let num = parseFloat(
            updatedRowData.storePrice * updatedRowData.noOfItems
          );
          let data = Order.getCalculatedAmount(
            this.state.rowData.discount,
            num,
            Validation.getGstSumOrVatSum(this.state.rowData?.taxRate),
            this.state.rowData?.commission,
            this.state.rowData.cess,
            this.state.rowData.otherCharges
          );
          updatedRowData.amount = data?.amount;
          updatedRowData.taxAmount = data?.taxAmount;
          this.props.updatedRowData(updatedRowData);
        }
      }
    }
    this.setState({
      rowData: updatedRowData,
    });
    // window.addEventListener('click', props.handlePropCilck());
  };
  handlePackage = inputVal => {
    this.setState(
      {
        rowData: {
          ...this.state.rowData,
          packing: inputVal.value,
        },
      },
      () => this.props.updatedRowData(this.state.rowData)
    );

    // let rowDataObj = {
    //   ...this.state.rowData,
    //   packing: inputVal,
    // };
    // this.updateAmountAndTaxCal(rowDataObj);
  };
  handleQuality = inputVal => {
    // e.preventDefault();
    this.setState(
      {
        rowData: {
          ...this.state.rowData,
          quality: inputVal.value,
        },
      },
      () => this.props.updatedRowData(this.state.rowData)
    );

    // let rowDataObj = {
    //   ...this.state.rowData,
    //   quality: inputVal,
    // };
    // this.updateAmountAndTaxCal(rowDataObj);
  };
  // onchange func for noOfItems and discount input
  handleInputChange = event => {
    event.preventDefault();
    let { name, value } = event.target;

    if (name === 'storePrice') {
      let rowDataObj = {
        ...this.state.rowData,
        storePrice: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }

    if (name === 'noOfItems') {
      let rowDataObj = {
        ...this.state.rowData,
        noOfItems: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
    if (name === 'commission') {
      let rowDataObj = {
        ...this.state.rowData,
        commission: value,
      };

      this.updateAmountAndTaxCal(rowDataObj);
    }
    if (name === 'discount') {
      let rowDataObj = {
        ...this.state.rowData,
        discount: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
    if (name === 'mrp') {
      let rowDataObj = {
        ...this.state.rowData,
        mrp: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
    if (name === 'otherCharges') {
      let rowDataObj = {
        ...this.state.rowData,
        otherCharges: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
    if (name === 'cess') {
      let rowDataObj = {
        ...this.state.rowData,
        cess: value,
      };
      this.updateAmountAndTaxCal(rowDataObj);
    }
  };

  handleNoOfItemsIncrement = () => {
    let updatedRowData = { ...this.state.rowData };
    updatedRowData.noOfItems = parseFloat(updatedRowData.noOfItems + 1);

    this.updateAmountAndTaxCal(updatedRowData);
  };

  handleNoOfItemsDecrement = () => {
    if (this.state.rowData.noOfItems < 2) {
      return;
    }
    let updatedRowData = { ...this.state.rowData };

    updatedRowData.noOfItems = parseFloat(updatedRowData.noOfItems - 1).toFixed(
      1
    );

    this.updateAmountAndTaxCal(updatedRowData);
  };

  handleManualEditQuantityUnitClick = () => {
    let previousItem = { ...this.state.rowData };

    let rowDataObj = {
      ...previousItem,
      storePrice: this.state.itemUnitQuantityStorePrize?.storePrice,
      quantity: parseInt(this.state.itemUnitQuantityStorePrize?.quantity),
      unit: this.state.itemUnitQuantityStorePrize?.unit,
      createdBy: Auth.getUserLoginId(),
      storeId: Auth.getStoreId(),
    };
    let filterData =
      this.state.productItemsData.filter(
        item =>
          item.itemName === rowDataObj.itemName &&
          item.quantity === rowDataObj.quantity &&
          item.unit === rowDataObj.unit
      ).length > 0;
    if (filterData) {
      this.props.closeEditIcon();
      this.setState(
        {
          rowData: rowDataObj,

          itemUnitQuantityStorePrize: { unit: null, quantity: null },
        },

        () => this.updateAmountAndTaxCal(rowDataObj)
      );
    } else {
      rowDataObj.id = null;
      rowDataObj.itemNumber = null;
      rowDataObj.stock = 5; //TODO add default stock value
      Product.addNewProductItem(rowDataObj, response => {
        if (response.status === 'success') {
          this?.props?.closeEditIcon();
          let productItemsDataList = [
            ...this.state.productItemsData,
            rowDataObj,
          ];
          let newDataObj = {
            ...response.data,
            taxRate: rowDataObj.taxRate,
            taxAmount: rowDataObj.taxAmount,
            noOfItems: previousItem.noOfItems,
            commission: this.state.rowData.commission,
          };
          this.setState(
            {
              rowData: newDataObj,
              productItemsData: productItemsDataList,

              itemUnitQuantityStorePrize: { unit: null, quantity: null },
            },
            () =>
              this.props.swipeProductItemCallback(
                previousItem,
                newDataObj,
                previousItem.noOfItems,
                productItemsDataList
              )
          );
        } else if ((response.data = 416)) {
          this.props.snackbar(
            `Limit exceeded please upgrade your plan`,
            'error'
          );
        } else {
          this.props.snackbar('Item not added successfully', 'error');
        }
      });
    }
  };
  addVariatiesByStoreId = type => {
    // this.setState({ purchaseVariety: value });

    let data = {
      storeId: Auth.getStoreId(),
      type: type,
      variety: this.state.purchaseVariety,
    };

    PurchaseOrders.addVariatyByStoreId(data, response => {
      if (response.status === 'success') {
        this.props.getAllVariatiesByType(type);
        this.props.snackbar(`${type} is added successfully`, 'success');
      }
    });
  };
  selectText = e => {
    e.target.select();
  };

  render() {
    return (
      <TableRow hover tabIndex={-1} sx={{ backgroundColor: '#fff' }}>
        <TableCell
          key={this.state.rowData.id}
          align='left'
          style={{ color: 'blue' }}
        >
          {this.props?.sowManualEditMode?.showEditProd &&
          this.props?.sowManualEditMode?.editRowData?.itemNumber ===
            this.state.rowData.itemNumber ? (
            <Tooltip placement='bottom' title='Close Edit Mode'>
              <Close
                sx={{ color: 'error.main', mr: 1, cursor: 'pointer' }}
                onClick={() => this.props.closeEditIcon()}
              />
            </Tooltip>
          ) : (
            <>
              {this.props.row.returned === true ? (
                <Tooltip title='Return Order is Present'>
                  <KeyboardReturnIcon
                    sx={{ fontSize: '25px', color: 'lightGray', pr: 1 }}
                  />
                </Tooltip>
              ) : null}
              {this.props.confirmedOrder ? (
                <Edit fontSize='small' sx={{ color: 'lightGray' }} />
              ) : (
                <Tooltip placement='bottom' title='Type Manually'>
                  <Edit
                    fontSize='small'
                    sx={{
                      color: '#3B78C9',
                      cursor: 'pointer',
                      display:
                        this.props.returnOrderMode === true ? 'none' : 'block',
                    }}
                    onClick={() => {
                      this.props.handleProductEditMode(this.state.rowData);
                      this.setState({
                        itemUnitQuantityStorePrize: {
                          ...this.state.itemUnitQuantityStorePrize,
                          quantity: this.state.rowData.quantity,
                          unit: this.state.rowData.unit,
                        },
                      });
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
          {this.props?.sowManualEditMode?.showEditProd &&
          this.props?.sowManualEditMode?.editRowData?.itemNumber ===
            this.state.rowData.itemNumber ? (
            <Tooltip title='Add Item' placement='bottom'>
              <CheckCircleOutlineIcon
                sx={{ color: 'green', cursor: 'pointer' }}
                onClick={() => {
                  !this.state.itemUnitQuantityStorePrize.quantity ||
                  !this.state.itemUnitQuantityStorePrize.unit ||
                  !this.state.itemUnitQuantityStorePrize.storePrice
                    ? this.props.snackbar(
                        'Please fill quantity unit and Store price',
                        'error'
                      )
                    : this.handleManualEditQuantityUnitClick();
                }}
              />
            </Tooltip>
          ) : null}
        </TableCell>

        <TableCell
          key={`itemName_${this.state.rowData.itemNumber}`}
          align='left'
          width={170}
        >
          {this.props?.sowManualEditMode?.showEditProd &&
          this.props?.sowManualEditMode?.editRowData?.itemNumber ===
            this.state.rowData.itemNumber ? (
            <TextField
              id='itemName'
              disabled={this.props.returnOrderMode === true}
              sx={{ display: 'block', mt: 2, width: '170px' }}
              value={
                Validation.isBlankObjectKeyNot(this.state.rowData, 'itemName')
                  ? this.state.rowData.itemName
                  : null
              }
              onChange={event =>
                this.setState({
                  rowData: {
                    ...this.state.rowData,
                    itemName: event.target.value,
                  },
                })
              }
              placeholder='Item name'
              size='small'
              defaultValue=''
              variant='outlined'
              name='itemName'
            />
          ) : (
            <Tooltip title={this.state.rowData.itemName} placement='top'>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '120px',
                  height: '1.2em',
                  whiteSpace: 'nowrap',
                  fontSize: '10px',
                }}
              >
                {this.state.rowData.itemName}
              </Typography>
            </Tooltip>
          )}
        </TableCell>

        <TableCell
          key={`quantity_${this.state.rowData.itemNumber}`}
          align={'left'}
        >
          {this.props?.sowManualEditMode?.showEditProd &&
          this.props?.sowManualEditMode?.editRowData?.itemNumber ===
            this.state.rowData.itemNumber ? (
            <>
              <TextField
                size='small'
                disabled={this.props.returnOrderMode === true}
                variant='outlined'
                type='number'
                value={this.state.itemUnitQuantityStorePrize.quantity}
                placeholder='Quantity'
                onChange={event =>
                  this.setState({
                    itemUnitQuantityStorePrize: {
                      ...this.state.itemUnitQuantityStorePrize,
                      quantity: event.target.value,
                    },
                  })
                }
                sx={{ display: 'block', mt: 2, width: '50px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <FormControl>
                        <Select
                          placeholder='U'
                          size='small'
                          sx={{ mr: -1.6 }}
                          value={this.state.itemUnitQuantityStorePrize.unit}
                          onChange={event =>
                            this.setState({
                              itemUnitQuantityStorePrize: {
                                ...this.state.itemUnitQuantityStorePrize,
                                unit: event.target.value,
                              },
                            })
                          }
                          displayEmpty
                          name='dropdown'
                          MenuProps={MenuProps}
                        >
                          <MenuItem disabled value={undefined}>
                            <em style={{ color: 'gray' }}>Select</em>
                          </MenuItem>
                          {this.state.unitsData?.map(item => (
                            <MenuItem key={item.unit} value={item.unit}>
                              {item.unit}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <FormControl>
              <Select
                size='small'
                value={
                  Validation.isBlankObjectKeyNot(this.state.rowData, 'quantity')
                    ? `${this.state.rowData.quantity} ${this.state.rowData.unit}`
                    : null
                }
                disabled={
                  this.props.confirmedOrder ||
                  this.props.returnOrderMode === true
                }
                name='qty/unit'
                placeholder='Select'
                MenuProps={MenuProps}
              >
                <MenuItem disabled value={undefined}>
                  <em style={{ color: 'gray' }}>Select</em>
                </MenuItem>
                {React.Children.toArray(
                  this.state.productItemsData?.map(item => {
                    return (
                      <MenuItem
                        onClick={() =>
                          this.handleQuantityUnitChange(
                            this.state.rowData,
                            item
                          )
                        }
                        key={item.itemNumber}
                        value={`${item.quantity} ${item.unit}`}
                      >
                        <Tooltip title={item.itemName} placement='left-start'>
                          <Typography variant='body2' fontSize={'11px'}>
                            {`${item.quantity} ${item.unit}`}{' '}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell
          align='left'
          key={`noOfItems_${this.state.rowData.itemNumber}`}
        >
          <TextField
            className='number__input'
            id='noOfItems'
            sx={{ width: '79px' }}
            size='small'
            defaultValue=''
            type='number'
            disabled={
              this.props.confirmedOrder || this.props.returnOrderMode === true
            }
            InputProps={{
              style: {
                paddingLeft: 5,
                fontSize: '11px',
              },
              startAdornment: (
                <InputAdornment
                  position='start'
                  sx={{ mr: 1 }}
                  disablePointerEvents={
                    this.props.confirmedOrder ||
                    this.props.returnOrderMode === true
                  }
                >
                  <Remove
                    sx={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color:
                        this.props.returnOrderMode === true
                          ? 'lightgrey'
                          : 'blue',
                    }}
                    fontSize='small'
                    onClick={this.handleNoOfItemsDecrement}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position='end'
                  disablePointerEvents={
                    this.props.confirmedOrder ||
                    this.props.returnOrderMode === true
                  }
                >
                  <Add
                    sx={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color:
                        this.props.returnOrderMode === true
                          ? 'lightgrey'
                          : 'blue',
                    }}
                    fontSize='small'
                    onClick={this.handleNoOfItemsIncrement}
                  />
                </InputAdornment>
              ),
            }}
            value={
              Validation.isBlankObjectKeyNot(this.state.rowData, 'noOfItems')
                ? this.state.rowData.noOfItems
                : ''
            }
            onChange={this.handleInputChange}
            onBlur={this.handleOnBlur}
            variant='standard'
            name='noOfItems'
          />
        </TableCell>
        {Store.companyDetails.businessType === 'MANDI' ||
        Store.companyDetails.businessType === 'STORE' ? null : (
          <TableCell
            align={'left'}
            key={`mrp_${this.state.rowData.itemNumber}`}
          >
            <TextField
              className='number__input'
              sx={{ width: '50px' }}
              id='mrp'
              size='small'
              InputProps={{
                style: styles,
              }}
              defaultValue=''
              type='text'
              disabled={
                this.props.confirmedOrder ||
                this.props.returnOrderMode ||
                !Store.isEditableStorePrice().storePriceEditable
              }
              value={
                Validation.isBlankObjectKeyNot(this.state.rowData, 'mrp')
                  ? this.state?.rowData?.mrp
                  : ''
              }
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              variant='standard'
              name='mrp'
            />
          </TableCell>
        )}

        <TableCell
          align={'left'}
          key={`storePrice_${this.state.rowData.itemNumber}`}
        >
          {this?.props?.sowManualEditMode?.showEditProd &&
          this.props?.sowManualEditMode?.editRowData?.itemNumber ===
            this.state.rowData.itemNumber ? (
            <TextField
              className='number__input'
              disabled={this.props.returnOrderMode === true}
              id='store-price'
              sx={{ display: 'block', mt: 2, width: '60px' }}
              value={this.state.itemUnitQuantityStorePrize.storePrice}
              onChange={event => {
                this.setState({
                  itemUnitQuantityStorePrize: {
                    ...this.state.itemUnitQuantityStorePrize,
                    storePrice: event.target.value,
                  },
                });
              }}
              placeholder='Store Price'
              size='small'
              defaultValue=''
              type='number'
              variant='outlined'
              name='store-price'
            />
          ) : (
            <TextField
              className='number__input'
              sx={{ width: '60px' }}
              InputProps={{
                style: styles,
              }}
              id='storePrice'
              value={
                Validation.isBlankObjectKeyNot(this.state.rowData, 'storePrice')
                  ? this.state.rowData.storePrice
                  : ''
              }
              onChange={event => {
                event.preventDefault();
                this.handleInputChange(event);
              }}
              onClick={this.selectText}
              onBlur={this.handleOnBlur}
              disabled={
                this.props.confirmedOrder ||
                this.props.returnOrderMode ||
                !Store.isEditableStorePrice().storePriceEditable
                // (!this.props.confirmedOrder &&
                //   !Store.isEditableStorePrice().storePriceEditable)
              }
              size='small'
              defaultValue=''
              type='number'
              variant='standard'
              name='storePrice'
            />
          )}
        </TableCell>
        <TableCell
          align={'left'}
          key={`discount_${this.state.rowData.itemNumber}`}
        >
          <TextField
            className='number__input'
            sx={{ width: '60px' }}
            id='discount'
            size='small'
            InputProps={{
              style: styles,
            }}
            defaultValue=''
            type='text'
            disabled={
              this.props.confirmedOrder ||
              this.props.returnOrderMode ||
              !Store.isEditableDiscount().discountEditable
            }
            value={
              Validation.isBlankObjectKeyNot(this.state.rowData, 'discount')
                ? this.state?.rowData?.discount
                : ''
            }
            onChange={this.handleInputChange}
            onBlur={this.handleOnBlur}
            variant='standard'
            name='discount'
          />
        </TableCell>
        {Store?.companyDetails?.businessType !== 'OPTICAL' && (
          <TableCell
            align={'left'}
            key={`otherCharges_${this.state.rowData.itemNumber}`}
          >
            <TextField
              className='number__input'
              sx={{ width: '60px' }}
              id='otherCharges_'
              size='small'
              InputProps={{
                style: styles,
              }}
              defaultValue=''
              type='number'
              disabled={this.props.confirmedOrder || this.props.returnOrderMode}
              value={
                Validation.isBlankObjectKeyNot(
                  this.state.rowData,
                  'otherCharges'
                )
                  ? this.state?.rowData?.otherCharges
                  : ''
              }
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              variant='standard'
              name='otherCharges'
            />
          </TableCell>
        )}

        {Store.companyDetails.businessType !== 'MANDI' ||
        Store.companyDetails.businessType !== 'OPTICAL' ? (
          <TableCell align={'center'} key={`taxableAmt`}>
            <TextField
              id='taxableAmt'
              InputProps={{
                style: styles,
              }}
              sx={{ width: '60px' }}
              size='small'
              variant='standard'
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              value={
                this.state.rowData?.taxableAmt
                  ? this.state.rowData?.taxableAmt?.toFixed(1)
                  : 0
              }
              disabled='true'
            />
          </TableCell>
        ) : null}

        {Store.getSelectedTaxType() === null ? null : (
          <>
            {Store.companyDetails.businessType !== 'MANDI' && (
              <>
                <TableCell align={'center'} key={`taxamt`}>
                  <TextField
                    id='taxamt'
                    InputProps={{
                      style: styles,
                    }}
                    sx={{ width: '80px' }}
                    size='small'
                    variant='standard'
                    value={
                      this.state.rowData?.taxAmount
                        ? this.state.rowData?.taxAmount?.toFixed(1)
                        : 0
                    }
                    disabled='true'
                  />
                </TableCell>
              </>
            )}
          </>
        )}

        {Store?.companyDetails?.businessType === 'MANDI' ? (
          <>
            <TableCell
              align={'center'}
              key={`packing${this.state.rowData.itemNumber}`}
            >
              <Autocomplete
                id='packing'
                sx={{ width: 100 }}
                freeSolo
                disabled={
                  this.props.confirmedOrder ||
                  (!this.props.confirmedOrder && !Store.isEditableDiscount()) ||
                  this.props.returnOrderMode === true
                }
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    this.handlePackage('');
                  } else {
                    this.handlePackage(newValue);
                  }
                }}
                autoSelect
                getOptionLabel={option =>
                  typeof option === 'string' || option instanceof String
                    ? option
                    : ''
                }
                value={
                  Validation.isBlankObjectKeyNot(this.state.rowData, 'packing')
                    ? this.state?.rowData?.packing
                    : ''
                }
                renderOption={(props, option) => {
                  return (
                    <>
                      <div {...props} key={option.id}>
                        {`${option.value}`}
                      </div>
                    </>
                  );
                }}
                options={this.props?.packingData && this.props?.packingData}
                renderInput={params => (
                  <TextField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='packing'
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.setState(
                          { purchaseVariety: e.target.value },
                          () => {
                            this.addVariatiesByStoreId('packing');
                          }
                        );
                      }
                    }}
                    onClick={() => this.props.getAllVariatiesByType('packing')}
                  />
                )}
              />
            </TableCell>
            <TableCell
              align={'center'}
              key={`quality${this.state.rowData.itemNumber}`}
            >
              <Autocomplete
                id='quality'
                freeSolo
                sx={{ width: 100 }}
                disabled={
                  this.props.confirmedOrder ||
                  (!this.props.confirmedOrder && !Store.isEditableDiscount()) ||
                  this.props.returnOrderMode === true
                }
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    this.handleQuality('');
                  } else {
                    this.handleQuality(newValue);
                  }
                }}
                autoSelect
                getOptionLabel={option =>
                  typeof option === 'string' || option instanceof String
                    ? option
                    : ''
                }
                value={
                  Validation.isBlankObjectKeyNot(this.state.rowData, 'quality')
                    ? this.state?.rowData?.quality
                    : ''
                }
                options={this.props?.qualityData && this.props?.qualityData}
                renderOption={(props, option) => {
                  return (
                    <>
                      <div {...props} key={option.id}>
                        {`${option.value}`}
                      </div>
                    </>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='quality'
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.setState(
                          { purchaseVariety: e.target.value },
                          () => {
                            this.addVariatiesByStoreId('quality');
                          }
                        );
                      }
                    }}
                    onClick={() => this.props.getAllVariatiesByType('quality')}
                  />
                )}
              />
            </TableCell>

            <TableCell
              align={'center'}
              key={`commission${this.state.rowData.itemNumber}`}
            >
              <TextField
                className='number__input'
                sx={{ width: '100px' }}
                id='commission'
                size='small'
                InputProps={{
                  style: styles,
                }}
                defaultValue=''
                disabled={
                  this.props.confirmedOrder ||
                  (!this.props.confirmedOrder &&
                    !Store.isEditableDiscount().discountEditable) ||
                  this.props.returnOrderMode === true
                }
                value={
                  Validation.isBlankObjectKeyNot(
                    this.state.rowData,
                    'commission'
                  )
                    ? this.state?.rowData?.commission
                    : ''
                }
                onChange={this.handleInputChange}
                onBlur={this.handleOnBlur}
                variant='standard'
                name='commission'
                type='number'
              />
            </TableCell>
          </>
        ) : null}
        {Store?.companyDetails?.businessType !== 'MANDI' ||
        Store?.companyDetails?.businessType !== 'OPTICAL' ? (
          <TableCell
            align={'left'}
            key={`cess_${this.state.rowData.itemNumber}`}
          >
            <TextField
              className='number__input'
              sx={{ width: '50px' }}
              id='cess'
              size='small'
              InputProps={{
                style: styles,
              }}
              defaultValue=''
              type='text'
              disabled={this.props.confirmedOrder || this.props.returnOrderMode}
              value={
                Validation.isBlankObjectKeyNot(this.state.rowData, 'cess')
                  ? this.state?.rowData?.cess
                  : 0
              }
              // onInput={e => {
              //   e.target.value = Math.max(0, parseInt(e.target.value))
              //     .toString()
              //     .slice(0, 2);
              // }}
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              variant='standard'
              name='cess'
            />
          </TableCell>
        ) : null}

        <TableCell align={'left'} sx={{ whiteSpace: 'nowrap' }}>
          {Validation.isBlankObjectKeyNot(this.state.rowData, 'amount') ? (
            <Typography fontSize='12px' fontWeight={700}>
              <>
                {this.props.currency ? (
                  <HTMLRenderer html={this.props.currency} />
                ) : null}
                &nbsp;{this.state?.rowData?.amount?.toFixed(2)}
              </>
            </Typography>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell
          align={'left'}
          key={`delete_${this.state.rowData.itemNumber}`}
        >
          {this.props.confirmedOrder ? (
            <Lock sx={{ color: 'lightGray' }} />
          ) : (
            <>
              <Tooltip title='remove item' placement='bottom'>
                <DeleteIcon
                  onClick={() => {
                    if (this.props.row.returned === true) {
                      this.props.snackbar(
                        'returned items cannot be deleted',
                        'error'
                      );
                    } else {
                      this.props.removeItemFromSelectionList(this.props.row);
                    }
                  }}
                  fontSize='small'
                  sx={{
                    color: 'error.main',
                    cursor: 'pointer',
                    ml: -2,
                    mr: 2,
                    display:
                      this.props.returnOrderMode === true ? 'none' : 'block',
                  }}
                />
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export default ListOrderItem;
