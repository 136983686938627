import React from 'react';
import { FormGroup, Button, InputLabel, Typography } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import imageCompression from 'browser-image-compression';
import Auth from '../../modules/Auth';
import Store from '../../modules/Store';
import './style.css';
import { serverConfig } from '../../config/server-config';

class BannerUpload extends React.Component {
  constructor(props) {
    super(props);
  }

  handleCapture = target => {
    if (target.files) {
      if (target.files.length !== 0) {
        this.uploadImages(target.files);
      }
    }
  };

  uploadImages = images => {
    Array.from(images).map((imageFile, index) => {
      if (index < 3) {
        this.handleImageCompression(imageFile, index);
      } else {
        return 'error';
      }
    });
  };

  handleImageCompression = async (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB
    if (imageFile.size > maxFileSize) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await this.uploadToServer(compressedFile, index);
      } catch (error) {
        console.log('Image upload failed ' + error);
      }
    } else {
      await this.uploadToServer(imageFile, index);
    }
  };
  uploadToServer = (imageFile, index) => {
    const maxFileSize = 1 * 1024 * 1024; // 1 MB

    if (imageFile.size <= maxFileSize) {
      const fileName = 'banner';
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('name', fileName);
      Store.addCompanyImages(formData, Auth.getStoreId(), response => {
        if (response.status == 'success') {
          this.props.snackbar('Banner Uploaded successfully', 'success');
          this.props.getBannerData(fileName);
        } else {
          console.log('error');
        }
      });
    }
  };

  render() {
    return (
      <>
        {serverConfig.isUpstoreCloud ? (
          <>
            <input
              accept='image/*'
              // className={classes.input}
              style={{ display: 'none' }}
              id='raised-button-file'
              type='file'
              onChange={e => this.handleCapture(e.target)}
            />
            <label htmlFor='raised-button-file'>
              <Button
                variant='contained'
                component='span'
                sx={{
                  p: 0,
                  width: { sm: '126px', md: '126px', xs: '90px' },
                  height: '27px',
                  backgroundColor: '#FFF',
                  color: '#000',
                  textTransform: 'capitalize',
                }}
                startIcon={
                  <PhotoCameraIcon
                    htmlFor='banner-button-file'
                    color='primary'
                    sx={{ pl: { xs: 1, md: 0 } }}
                  />
                }
              >
                <Typography
                  className='bannerHover'
                  sx={{ fontSize: { md: '12px', sm: '11px', xs: '8px' } }}
                  noWrap
                >
                  Edit Cover Photo
                </Typography>
              </Button>
            </label>
          </>
        ) : null}
      </>
    );
  }
}

export default BannerUpload;
