import UpstoreApi from '../UpstoreApi';

class DayBook {
  //for getting all users by userId
  getAllDaybookEntries = (storeId, date, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/cash-book/get-all-entry/${storeId}?date=${date}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting  all entries', err.message);
        callback({ status: 'error' });
      });
  };

  getDaybookEntry = (storeId, date, callback) => {
    UpstoreApi({
      method: 'GET',
      url: `/day-book/get-entry-by-storeId/${storeId}?date=${date}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log('Error occurred while getting  all entries', err.message);
        callback({ status: 'error' });
      });
  };
  addDayBookEntry = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `/day-book/`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'invalid' });
        }
      })
      .catch(err => {
        console.log('Error occured in adding New user', err.message);
        callback({ status: 'error' });
      });
  };
  //update daybook details
  UpdateDayEntry = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/day-book/update/${id}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating Daybook Entry', error.message);
        callBack({ status: 'error' });
      });
  };
  //add cashBook entry
  addCashBookEntry = (data, callback) => {
    UpstoreApi({
      method: 'POST',
      url: `/cash-book/`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'invalid' });
        }
      })
      .catch(err => {
        console.log('Error occured in adding New user', err.message);
        callback({ status: 'error' });
      });
  };
  //update Cashbook details
  UpdateCashBookEntry = (id, data, callBack) => {
    UpstoreApi({
      method: 'PUT',
      url: `/cash-book/update/${id}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating Daybook Entry', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteCashbookEntryByid = (id, callBack) => {
    UpstoreApi({
      method: 'DELETE',
      url: `/cash-book/delete-entry/${id}`,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success' });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while deleting cashbook Entry',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new DayBook();
