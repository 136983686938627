import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Payments from '../../modules/Payments';
import Auth from '../../modules/Auth';
import * as moment from 'moment';
import { TextField, Box, Button, Typography, Grid } from '@mui/material';
// for date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function PaymentChart({ type }) {
  const date = new Date();
  const [cash, setCash] = React.useState([]);
  const [value1, setValue1] = React.useState(new Date());
  const [value2, setValue2] = React.useState(new Date());

  React.useEffect(() => {
    getAllPayments();
  }, []);

  const getAllPayments = () => {
    let startDate = moment(value1).format('YYYY-MM-DD');
    let endDate = moment(value2).format('YYYY-MM-DD');

    Payments.getAllPaymentsByType(
      Auth.getStoreId(),
      startDate,
      endDate,
      type,
      response => {
        if (response.status === 'success') {
          let filteredPay = response.data.filter(item => {
            return (
              item.type !== null &&
              item.amount !== null &&
              item.type !== 'Adjustment'
            );
          });

          setCash(filteredPay);
        } else {
          console.log('error');
        }
      }
    );
  };

  let result = 0;

  cash.forEach(number => {
    return (result += number.amount);
  });

  const data = {
    labels: [
      cash[0]?.type !== undefined ? cash[0]?.type : '',
      cash[1]?.type !== undefined ? cash[1]?.type : '',
      cash[2]?.type !== undefined ? cash[2]?.type : '',
      cash[3]?.type !== undefined ? cash[3]?.type : '',
      cash[4]?.type !== undefined ? cash[4]?.type : '',
      cash[5]?.type !== undefined ? cash[5]?.type : '',
      cash[6]?.type !== undefined ? cash[6]?.type : '',
    ],
    datasets: [
      {
        data: [
          cash[0]?.amount !== undefined ? cash[0]?.amount : 0,
          cash[1]?.amount !== undefined ? cash[1]?.amount : 0,
          cash[2]?.amount !== undefined ? cash[2]?.amount : 0,
          cash[3]?.amount !== undefined ? cash[3]?.amount : 0,
          cash[4]?.amount !== undefined ? cash[4]?.amount : 0,
          cash[5]?.amount !== undefined ? cash[5]?.amount : 0,
          cash[6]?.amount !== undefined ? cash[6]?.amount : 0,
        ],
        backgroundColor: [
          '#f12711',
          '#f5af19',
          '#F37335',
          '#659999',
          '#6be585',
          '#544a7d',
          '#78ffd6',
        ],
      },
    ],
  };
  return (
    <div>
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box item xs={12} sx={{ display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value1}
              inputFormat='dd/MM/yyyy'
              onChange={newValue => {
                setValue1(newValue);
              }}
              renderInput={params => (
                <TextField
                  size='small'
                  sx={{
                    width: '140px',
                    mr: 2,
                    mt: 1,
                  }}
                  {...params}
                />
              )}
            />
            <DatePicker
              value={value2}
              onChange={newValue => {
                setValue2(newValue);
              }}
              inputFormat='dd/MM/yyyy'
              renderInput={params => (
                <TextField
                  size='small'
                  sx={{
                    width: '140px',

                    mt: 1,
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mt: { xs: 2 } }}>
          <Button
            variant='contained'
            sx={{
              ml: '8px',
              textTransform: 'capitalize',

              width: '126px',
              height: '27px',
              backgroundColor: '#3B78C9',
            }}
            onClick={getAllPayments}
          >
            <Typography variant='h5' sx={{ fontSize: '13px' }} no>
              See payments
            </Typography>
          </Button>
        </Box>
      </Grid>
      {cash.length > 0 ? (
        <Grid container>
          <Grid item md={6} sm={6} xs={12}>
            <Box sx={{ mr: 1, mt: 3 }}>
              <Doughnut data={data} width={200} height={200} />
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{ pt: { md: 8, sm: 8, xs: 2 }, pr: 5 }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#3b78c9',
                opacity: 8,
                width: '280px',
                height: '200px',
                pl: 1.5,

                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                  pt: 2,
                }}
              >
                {cash[0]?.type !== undefined ? cash[0]?.type : ''}
                <br />
                {cash[1]?.type !== undefined ? cash[1]?.type : ''}
                <br />
                {cash[2]?.type !== undefined ? cash[2]?.type : ''}
                <br />
                {cash[3]?.type !== undefined ? cash[3]?.type : ''}
                <br />
                {cash[4]?.type !== undefined ? cash[4]?.type : ''}
                <br />
                {cash[5]?.type !== undefined ? cash[5]?.type : ''}
                <br />
                {cash[6]?.type !== undefined ? cash[6]?.type : ''}
                <br />
              </Typography>

              <Typography
                variant='h6'
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                  pt: 2,
                  pr: 2,
                }}
              >
                {cash[0]?.amount !== undefined ? cash[0]?.amount : ''}
                <br />
                {cash[1]?.amount !== undefined ? cash[1]?.amount : ''}
                <br />
                {cash[2]?.amount !== undefined ? cash[2]?.amount : ''}
                <br />
                {cash[3]?.amount !== undefined ? cash[3]?.amount : ''}
                <br />
                {cash[4]?.amount !== undefined ? cash[4]?.amount : ''}
                <br />
                {cash[5]?.amount !== undefined ? cash[5]?.amount : ''}
                <br />
                {cash[6]?.amount !== undefined ? cash[6]?.amount : ''}
                <br />
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                backgroundColor: '#3b78c9',
                opacity: 8,
                pb: 1,
                width: '280px',
                pl: 1.5,
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                Total Payments
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                  pr: 2,
                }}
              >
                {result.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography
          sx={{
            mt: 10,
            fontSize: 30,
            color: 'transparent',
            textShadow: '0 0 3px #000',
            textAlign: 'center',
          }}
        >
          No Payments
        </Typography>
      )}
    </div>
  );
}

export default PaymentChart;
