import React, { Component } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

import { Loading } from '../loading/Loading';
import { Link } from 'react-router-dom';
import { State } from 'country-state-city';
import { Box } from '@mui/system';
import VendorDialog from '../vendorDialog/VendorDialog';
import Vendor from '../../modules/Vendor';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import UpStoreSnackBar from '../upStoreSnackBar/UpStoreSnackBar';
import DeleteIcon from '@mui/icons-material/Delete';
import ActivationCustomerDialog from '../activationDialog/ActivationCustomerDialog';

class AllVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upstoreSnackBar: { open: false },
      OpenVendorDialog: false,
      vendorId: '',
      selectedVendorData: '',
      openDeleteDialog: false,
      deleteId: '',
    };
  }

  handleClose = () => {
    this.setState({ openDeleteDialog: false });
  };
  handleActive = (e, id) => {
    let data = {
      active: e.target.value,
    };

    Vendor.updateVendor(id, data, response => {
      if (response.status === 'success') {
        this.props.reloadVendorList(0);
        this.openUpstoreSnackBar('Vendor edited  successfully', 'success');
      } else {
        console.log('Error occurred while updating  Vendor');
      }
    });
  };
  handleGetState = (iso, cc) => {
    let state = State?.getStateByCodeAndCountry(iso, cc);
    return state?.name ? state?.name : '_';
  };
  onSnackbarClose = () => {
    const upstoreSnackbarObject = {
      open: false,
    };
    this.setState({ upstoreSnackBar: upstoreSnackbarObject });
  };
  openUpstoreSnackBar = (message, type) => {
    const upstoreSnackbarObject = {
      open: true,
      message: message,
      type: type,
    };
    this.setState({ upstoreSnackBar: upstoreSnackbarObject });
  };
  closeVendorDialog = () => {
    this.setState({
      OpenVendorDialog: false,
      buttonClicked: '',
      selectedVendorData: '',
    });
  };

  handleDeleteVendor = () => {
    Vendor.deleteVendor(this.state.deleteId, response => {
      if (response.status === 'success') {
        this.props.reloadVendorList(0);
        this.handleClose();
      } else if (response.data === 403) {
        this.openUpstoreSnackBar(
          `Can't delete this vendor , contains transactions`,
          'error'
        );
        this.handleClose();
      }
    });
  };

  handleDeleteDialog = id => {
    this.setState({ openDeleteDialog: true });
    this.setState({ deleteId: id });
  };
  columns = [
    {
      field: 'name',
      headerName: 'Vendor Name',
      type: 'string',
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={'/vendor-details'}
            state={params.row}
          >
            <Tooltip title={params.row?.name}>
              <Typography
                variant='subtitle1'
                sx={{
                  cursor: 'pointer',
                  color: '#3B78C9',
                }}
              >
                {`${params.row?.name}`}
              </Typography>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'GstNo',
      headerName: 'GST NO',
      width: 180,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => (
        <Typography variant='h6' fontSize='15px'>
          {params.row.gstNo === null || params.row.gstNo === ''
            ? '-'
            : params.row.gstNo}
        </Typography>
      ),
    },

    {
      field: 'address',
      headerName: 'Address',
      type: 'string',
      width: 300,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return (
          <Tooltip
            title={
              params.row.state && params.row.city
                ? `${params.row?.state},${params.row.city}`
                : params.row.address
            }
          >
            <Typography
              variant='subtitle1'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {params.row.state && params.row.city
                ? `${this.handleGetState(
                    params?.row?.state,
                    params.row.country
                  )},${params.row.city ? params.row.city : '_'}`
                : params.row.address}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'contactNo',
      headerName: 'Contact No',
      type: 'string',
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      width: 180,
      type: 'date',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
      renderCell: params => (
        <Typography variant='h6' fontSize='15px'>
          {params.row.creationDate === null
            ? '-'
            : params.row.creationDate.substring(0, 10)}
        </Typography>
      ),
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'number',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <FormControl fullWidth>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={params.row.active !== null ? params.row.active : true}
              sx={{
                color: '#fff',
                backgroundColor: params.row.active ? '#20CCA1' : '#F85D59',
              }}
              onChange={event => {
                this.handleActive(event, params.row.id);
              }}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In-Active</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'action',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <>
          <Box
            width='100%'
            component='div'
            display='flex'
            justifyContent='space-around'
            alignItems='baseline'
          >
            <>
              <Tooltip title='Edit Vendor' placement='bottom'>
                <EditIcon
                  onClick={() => {
                    this.setState({
                      // OpenVendorDialog: true,
                      selectedVendorData: params.row,
                      // buttonClicked: 'Edit',
                      vendorId: params.row.id,
                    });
                    this.props.editDialogCallBack();
                  }}
                  sx={{ color: '#3B78C9' }}
                />
              </Tooltip>
              {this.props.index === 1 ? (
                <Tooltip title='Delete Vendor' placement='bottom'>
                  <DeleteIcon
                    onClick={() => this.handleDeleteDialog(params.row.id)}
                  >
                    Delete
                  </DeleteIcon>
                </Tooltip>
              ) : null}
            </>
          </Box>
        </>
      ),
    },
  ];
  render() {
    return (
      <>
        <Box
          sx={{
            padding: 0,
            width: '100%',
            height: 600,

            '& .super-app-theme--header': {
              backgroundColor: '#EFFAFF',

              fontSize: '18px',
            },
          }}
        >
          {this.props.loader ? (
            <Loading />
          ) : (
            <>
              <Grid container columnSpacing={2}></Grid>
              {this.props.vendorList.length > 0 ? (
                <DataGrid
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  sx={{
                    boxShadow: 2,
                    border: 6,
                    backgroundColor: '#fff',
                    borderColor: '#eee',
                    color: 'black',
                    '& .MuiDataGrid-cell': {
                      outline: 'none',
                      fontSize: '16px',
                      fontWeight: '25px',
                    },
                    '& .MuiDataGrid-cell:hover': {
                      outline: 'none',
                      color: '#21211f',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                    '& svg': {
                      color: '#3b78c9',
                    },
                    '& .css-9h4z0x-MuiButtonBase-root-MuiButton-root': {
                      color: 'black',
                    },
                  }}
                  density='compact'
                  rows={this.props.vendorList}
                  columns={this.columns}
                  pageSize={20}
                  aria-label='Vendors List'
                />
              ) : (
                <Typography>No Vendors Found</Typography>
              )}
            </>
          )}
        </Box>
        {this.state.openDeleteDialog ? (
          <ActivationCustomerDialog
            openDialog={this.state.openDeleteDialog}
            deleteVendor={this.handleDeleteVendor}
            dialogContent={'Are you sure you want delete this Vendor ?'}
            buttonAlert={'Delete'}
            closeDialogCallback={this.handleClose}
            reloadVendorList={this.props.reloadVendorList}
            vendor={true}
          />
        ) : null}

        {this.props.openDialog ? (
          <VendorDialog
            openDialog={this.props.openDialog}
            closeDialog={this.props.close}
            buttonClicked={this.props.buttonClick}
            editVendorData={
              this.state.selectedVendorData && this.state.selectedVendorData
            }
            reloadVendorList={this.props.reloadVendorList}
            snackbar={this.openUpstoreSnackBar}
          />
        ) : null}

        {this.state.upstoreSnackBar.open ? (
          <UpStoreSnackBar
            upstoreSnackBar={this.state.upstoreSnackBar}
            onSnackbarClose={this.onSnackbarClose}
          />
        ) : null}
      </>
    );
  }
}

export default AllVendors;
