import React, { forwardRef, useImperativeHandle } from 'react';
import Drawer from '@mui/material/Drawer';
import { Add, Close, DoneOutline } from '@mui/icons-material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {
  Box,
  Paper,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Product from '../../modules/Product';
import Auth from '../../modules/Auth';
import { Loading } from '../loading/Loading';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import AddNewProduct from '../addNewProduct/AddNewProduct';
import Category from '../../modules/Categories';
import AddNewProductItem from '../addNewProductItem/AddNewProductItem';
import Validation from '../../modules/Validation';
import BarCodeReader from '../barCodeReader/BarCodeReader';
import Store from '../../modules/Store';
import './style.css';
import ProductItemAccordionDetails from '../productItemAccordionDetails/ProductItemAccordionDetails';

const ProductItemDrawer = forwardRef((props, ref) => {
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [openAddProductDialog, setOpenAddProductDialog] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  const [clickedItem, setClickedItem] = React.useState(-1);
  const [clickedUnitChip, setClickedUnitChip] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState('');
  const [showScanner, setShowScanner] = React.useState(false);
  const [barCodeData, setBarCodeData] = React.useState(null);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [showFilteredProducts, setShowFilteredProducts] = React.useState(false);
  const [openAddProductItemDialog, setOpenAddProductItemDialog] =
    React.useState({ open: false, itemCategoryId: '', itemProductId: '' });
  const listRef = React.useRef(null);
  const inputRef = React.useRef(null);
  let [hasMoreData, setHasMoreData] = React.useState(true);
  let page_size = 20;

  React.useEffect(() => {
    if (
      Validation.isBlankObjectNot(props) &&
      Validation.isBlankObjectNot(props.productItemList) &&
      props.productItemList.length != clickedUnitChip.length &&
      clickedUnitChip.length > 0
    ) {
      let checkedUnitChips = [];

      props.productItemList.forEach(item => {
        checkedUnitChips.push(item.itemNumber);
      });
      setClickedUnitChip(checkedUnitChips);
    }
  });
  React.useEffect(() => {
    getProductByStoreId(pageNumber);
    getCategoriesList();
    // montoringEvents();
  }, []);

  const getCategoriesList = () => {
    Category.getCategoryByStoreId(Auth.getStoreId(), response => {
      if (response.status === 'success') {
        let filteredArray = response.data.filter(item => {
          return item.available;
        });
        setCategoryList(filteredArray);
      } else {
        console.log('error');
        setCategoryList([]);
      }
    });
  };

  const handleBarcodeInput = e => {
    if (e.key === 'Enter') {
      // setBarCodeData(event.target.value);
      barCode(e.target.value);
      e.target.value = '';
    }
  };
  const getProductByStoreId = pageNum => {
    Product.getProductByStoreId(Auth.getStoreId(), pageNum, response => {
      if (response.status === 'success') {
        if (response.data.length < page_size) {
          setHasMoreData(false);
        }
        if (pageNum > 0) {
          setSkeletonLoading(false);
          let arr = productList;
          arr = arr.concat([...response.data]);
          let filteredArray = arr.filter(product => {
            return product.available && !product.deleted;
          });
          setProductList(filteredArray);
          setLoader(false);
        } else {
          setSkeletonLoading(false);
          let filteredArray = response.data.filter(product => {
            return product.available && !product.deleted;
          });
          setProductList(filteredArray);
          setLoader(false);
        }
      } else {
        setSkeletonLoading(false);
        setProductList([]);
        setLoader(false);
      }
    });
  };

  const loadMoreProducts = () => {
    const list = listRef.current;
    if (
      Math.abs(list.scrollHeight - (list.scrollTop + list.clientHeight) <= 1) &&
      hasMoreData
    ) {
      setSkeletonLoading(true);
      let loadCount = pageNumber === 0 ? 1 : pageNumber + 1;
      if (productList.length <= 20 * loadCount) {
        setPageNumber(loadCount);
        getProductByStoreId(loadCount);
      }
    }
  };

  const barCode = text => {
    setShowScanner(false);
    setBarCodeData(text);
    getDataByBarCode(text);
  };

  const handleGetProductsByCategoryId = categoryId => {
    setLoader(true);
    Product.getProductByCategoryId(categoryId, 0, 10, response => {
      if (response.status === 'success') {
        let filteredArray = response.data.filter(product => {
          return product.available && !product.deleted;
        });
        setProductList(filteredArray);
        setLoader(false);
      } else {
        console.log('error');
      }
    });
  };

  // const handleAccordionChange = panel => (event, isExpanded) => {
  //   setOpenAccordion(isExpanded ? panel : false);
  // };

  const handleAccordionChange = id => {
    setOpenAccordion(prevState => (id === prevState ? false : id));
  };

  const handleSearch = value => {
    if (value !== '') {
      if (!value.includes('/')) {
        Product.searchProductsByName(value, Auth.getStoreId(), response => {
          if (response.status === 'success') {
            let filteredArray = response.data.filter(product => {
              return product.available && !product.deleted;
            });

            setProductList(filteredArray);
          } else {
            props.snackbar('error occured while searching', 'error');
          }
        });
      } else {
        const searchString = value.slice(value.indexOf('/') + 1).toLowerCase();
        if (searchString.length > 0) {
          setShowFilteredProducts(true);
          const filteredItemData = productList?.flatMap(product => {
            const filteredItems = product.productItems.filter(item => {
              return (
                item?.itemName?.toLowerCase().includes(searchString) ||
                item?.storePrice
                  ?.toString()
                  .toLowerCase()
                  .includes(searchString)
              );
            });
            return filteredItems.length > 0 ? filteredItems : [];
          });
          setFilteredItems(filteredItemData);
        } else {
          setShowFilteredProducts(false);
          setFilteredItems([]);
        }
      }
    } else {
      // checking if any chip is selected or not (-1 means none is slected)
      if (clickedItem == -1) getProductByStoreId(0);
      else handleGetProductsByCategoryId(selectedCategoryId);
      setShowFilteredProducts(false);
    }
  };

  //for preventing frequent api calls on customer search
  const debounce = (func, timeout = 350) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const handleSearchFieldByStoreId = debounce(e => handleSearch(e));

  const closeOpenAddProductDialog = () => {
    setOpenAddProductDialog(false);
  };

  const closeAddProductItemDialog = () => {
    setOpenAddProductItemDialog({
      open: false,
      itemCategoryId: '',
      itemProductId: '',
    });
  };
  const handleProduct = () => {
    // productList = [];
    setLoader(true);
    setProductList([]);
    setTimeout(() => {
      getProductByStoreId(0);
    }, 0);
  };
  const handleClick = event => {
    if (event.target.name == 'search-input') {
      return;
    }
    inputRef?.current?.focus();
  };

  // func for handling and appending newly added item in an existing product list
  const handlegetNewlyaddedProductItem = item => {
    // calling function for also keeping selected newly added item
    //
    item.itemAddedOnDialog = true; // adding dynamic property for converting add buton to tick icon
    let parentProduct = null;
    let updatedProductList = productList.map(product => {
      if (product.productId == item.productId) {
        product.productItems = [...product.productItems, item];
        parentProduct = product;
      }
      return product;
    });
    setProductList(updatedProductList);
    handleChipSelection(item, parentProduct);
  };

  const handleMainChipSelection = categoryId => {
    handleGetProductsByCategoryId(categoryId);
    setClickedItem(categoryId);
    setSelectedCategoryId(categoryId);
  };
  const handleChipSelection = (item, product) => {
    // document.getElementById('chip').removeEventListener('click', handleClick);
    document.addEventListener('click', handleClick);
    props.selectedProductItemCallback(item, product);
    setClickedUnitChip(prevArray => [...prevArray, item.itemNumber]);
  };

  const cleanValue = () => {
    setClickedUnitChip([]);
  };

  useImperativeHandle(ref, () => {
    return {
      cleanValue: cleanValue,
    };
  });

  // const getDataByBarCode = value => {
  //   let abc = value.substring(0, value.length - 1);
  //   let newProd;
  //   productList.forEach(product => {
  //     product?.productItems?.filter(item => {
  //       if (item?.barcode == abc) {
  //         newProd = productList.some(
  //           product1 => product.productId == product1.productId
  //         );
  //         props.selectedProductItemCallback(item, newProd);
  //       }
  //     });
  //   });
  // };

  const getDataByBarCode = value => {
    // let barcode = value.substring(0, value.length - 1);

    Product.getItemByStoreIdAndBarcode(Auth.getStoreId(), value, response => {
      if (response.status === 'success') {
        let newProd = productList.find(
          product1 => product1.productId === response.data.productId
        );
        // Product.getProductByProductId(response.data.productId, response1 => {
        //   if (response.status === 'success') {
        handleChipSelection(response.data, newProd);
        props.snackbar(`Item Added Successfully`, 'success');
        //   }
        // });
        // props.selectedProductItemCallback(response.data, newProd);
        // setClickedUnitChip(prevArray => [
        //   ...prevArray,
        //   response.data.itemNumber,
        // ]);
      } else {
        props.snackbar(`Error Try Again`, 'error');
      }
    });
  };

  return (
    <div>
      <Drawer
        variant='temporary'
        open={props.drawerOpened}
        anchor='right'
        onClose={props.closeDrawer}
        sx={{ height: '300px', float: 'right' }}
      >
        <Box
          component='div'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pt: 2, pl: 1 }}
        >
          {/* {productList.length > 0 ? ( */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              size='large'
              sx={{
                mt: 0.8,
                bgcolor: '#3B78C9',
                width: '126px',
                // height: '27px',
                fontSize: '12px',
              }}
              onClick={() => {
                // props.closePopup();
                props.closeDrawer();
              }}
            >
              Done
            </Button>
          </Box>
          {/* ) : null} */}
          {/* <Typography className='hide' variant='h4' fontSize={23}>
            Select Product Item
          </Typography> */}

          <TextField
            type='text'
            // value={state}/
            name='search-input'
            className='visible-search-input'
            placeholder='search product...'
            // style={{
            //   paddingLeft: '6px',
            //   fontSize: '14px',
            //   minWidth: '300px',
            //   minHeight: '30px',
            //   borderRadius: '50px',
            //   // marginLeft: '2rem',
            //   border: '1px solid gray',
            // }}

            sx={{ mx: 3, minHeight: '30px' }}
            fullWidth
            size='small'
            onChange={event => {
              handleSearchFieldByStoreId(event.target.value);
            }}
          />
          {/* <Box className='hide'>
            <PhotoCameraIcon
              color='primary'
              sx={{ pl: { xs: 1, md: 0 } }}
              onClick={() => {
                setShowScanner(true);
              }}
            />
          </Box> */}
          <input
            ref={inputRef}
            style={{ position: 'absolute', top: '-100px' }}
            type='text'
            id='barcode-input'
            autoFocus
            onKeyDown={handleBarcodeInput}
          />

          {/* <form onSubmit={e => handleBarcodeInput(e)}>
            <input id='barcode-input' />
            <button type='submit' className='hidden' />
          </form> */}

          {showScanner && (
            <Dialog
              open={true}
              onClose={() => {
                setShowScanner(false);
              }}
            >
              <DialogTitle>
                <Tooltip title='Close dialog' placment='right'>
                  <Close
                    onClick={() => setShowScanner(false)}
                    sx={{ color: '#3B78C9', cursor: 'pointer' }}
                  />
                </Tooltip>
              </DialogTitle>
              <DialogContent>
                {/* <BarCodeReader
                  scanner={barCode}
                  snackbar={props.snackbar}
                  barcodeFun={handleBarcodeInput}
                /> */}
                {/* <input
                  type='text'
                  id='barcode-input'
                  onKeyDown={handleBarcodeInput}
                /> */}
              </DialogContent>
            </Dialog>
          )}
          <Box className='hide'>
            <Tooltip title='Add Product' placement='bottom'>
              <Button
                sx={{
                  p: 0,
                  mr: 1,
                  bgcolor: '#3B78C9',
                  width: '130px',
                  height: '35px',
                }}
                variant='contained'
                onClick={() => setOpenAddProductDialog(true)}
                startIcon={<Add fontSize='small' />}
                size='large'
              >
                <Typography sx={{ fontSize: '12px' }} noWrap>
                  Add Product
                </Typography>
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            pl: 1,
            maxWidth: '840px',
          }}
        >
          <Box>
            <Stack
              direction='row'
              sx={{
                overflowX: 'scroll',
                pb: 1,
                scrollBehavior: 'smooth',
                overflowY: 'hidden',
                whiteSpace: 'nowrap',
              }}
              spacing={2}
            >
              <Chip
                color={clickedItem === -1 ? 'primary' : 'secondary'}
                label='All'
                onClick={event => {
                  handleProduct();
                  setClickedItem(-1);
                }}
              />
              {categoryList.map(item => {
                return (
                  <Chip
                    color={
                      clickedItem === item.categoryId ? 'primary' : 'secondary'
                    }
                    key={item.categoryId}
                    label={item.name}
                    onClick={event => {
                      handleMainChipSelection(item.categoryId);
                    }}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
        {showFilteredProducts ? (
          filteredItems.length > 0 ? (
            <ProductItemAccordionDetails
              itemData={filteredItems}
              selectedProductItemCallback={props.selectedProductItemCallback}
              snackbar={props.snackbar}
            />
          ) : (
            <Typography
              variant='h5'
              sx={{
                textAlign: 'center',
                fontSize: '40px',
                color: '#3B78C9',
                pt: '7rem',
              }}
            >
              No Items Found
            </Typography>
          )
        ) : (
          <>
            {loader ? (
              <Loading />
            ) : (
              <>
                <Box
                  ref={listRef}
                  onScroll={loadMoreProducts}
                  className='details'
                  sx={{
                    height: '800px',
                    overflowY: 'scroll',
                  }}
                >
                  {productList !== undefined && productList.length > 0 ? (
                    productList.map(product => {
                      return (
                        <Accordion
                          key={product.productId}
                          expanded={openAccordion === product.productId}
                          sx={{ justifyContent: 'center' }}
                        >
                          <AccordionSummary
                            sx={{
                              bgcolor: '#E1EEF5',
                              alignItems: 'center',
                              height: '60px',
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                fontSize='large'
                                onClick={() =>
                                  handleAccordionChange(product.productId)
                                }
                              />
                            }
                            aria-controls='panel1bh-content'
                            id='panel1bh-header'
                            key={product.productId}
                          >
                            <List
                              style={{ width: '30%' }}
                              key={product.itemNumber}
                            >
                              <ListItem>
                                <ListItemText
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                  primary={
                                    <Tooltip
                                      title={product.name}
                                      placment='top'
                                    >
                                      <Typography
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          width: '260px',
                                          height: '1.6em',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {product.name}
                                      </Typography>
                                    </Tooltip>
                                  }
                                  secondary={
                                    <Tooltip
                                      title={product.description}
                                      placment='top'
                                    >
                                      <Box>{product.description}</Box>
                                    </Tooltip>
                                  }
                                />
                              </ListItem>
                            </List>
                            <Stack
                              direction='row'
                              alignItems='center'
                              sx={{
                                overflowX: 'scroll',
                                whiteSpace: 'nowrap',
                                scrollBehavior: 'smooth',
                                width: '450px',
                              }}
                              spacing={1}
                            >
                              {product.productItems &&
                              product.productItems.length > 0
                                ? product.productItems
                                    .filter(item => !item.deleted)
                                    .map(item => (
                                      <Tooltip
                                        title={item.itemName}
                                        placement='bottom'
                                      >
                                        <Chip
                                          id='chip'
                                          key={item.productId}
                                          color={
                                            clickedUnitChip &&
                                            clickedUnitChip.includes(
                                              item.itemNumber
                                            )
                                              ? 'info'
                                              : 'default'
                                          }
                                          disabled={
                                            clickedUnitChip &&
                                            clickedUnitChip.includes(
                                              item.itemNumber
                                            )
                                              ? true
                                              : false
                                          }
                                          label={`${item.quantity} ${
                                            item.unit
                                          } Rs:  ${
                                            window?.location?.hash?.endsWith(
                                              '/neworders'
                                            )
                                              ? item?.storePrice
                                              : item?.basePrice === null
                                              ? 0
                                              : item?.basePrice
                                          }`}
                                          sx={{ cursor: 'pointer' }}
                                          onClick={() =>
                                            handleChipSelection(item, product)
                                          }
                                        />
                                      </Tooltip>
                                    ))
                                : null}
                            </Stack>
                          </AccordionSummary>

                          <AccordionDetails>
                            {product.productItems &&
                            product.productItems.length > 0 ? (
                              <ProductItemAccordionDetails
                                itemData={product.productItems.filter(
                                  item => !item.deleted
                                )}
                                product={product}
                                selectedProductItemCallback={
                                  props.selectedProductItemCallback
                                }
                                snackbar={props.snackbar}
                              />
                            ) : (
                              <Typography textAlign='center' variant='h5'>
                                No Item Present
                              </Typography>
                            )}

                            <Tooltip
                              title='Add Product Item'
                              placement='bottom'
                            >
                              <Button
                                sx={{
                                  p: 0,
                                  mt: 1,
                                  textTransform: 'capitalize',
                                  width: '126px',
                                  height: '27px',
                                }}
                                variant='outlined'
                                endIcon={<Add />}
                                onClick={() =>
                                  setOpenAddProductItemDialog({
                                    open: true,
                                    itemCategoryId: product.categoryId,
                                    itemProductId: product.productId,
                                  })
                                }
                              >
                                <Typography noWrap> Add New Item</Typography>
                              </Button>
                            </Tooltip>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                  ) : (
                    <div>
                      <Typography
                        variant='h5'
                        sx={{
                          textAlign: 'center',
                          fontSize: '40px',
                          color: '#3B78C9',
                          pt: '7rem',
                        }}
                      >
                        No Item Found
                      </Typography>
                    </div>
                  )}
                  {skeletonLoading ? (
                    <Box>
                      <Skeleton
                        width='100%'
                        height={80}
                        sx={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: '#3B78C9',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        Scroll down to Load more ...
                      </Skeleton>
                    </Box>
                  ) : null}
                </Box>
              </>
            )}
          </>
        )}
        {openAddProductDialog ? (
          <AddNewProduct
            openDialog={openAddProductDialog}
            closeDialog={closeOpenAddProductDialog}
            snackbar={props?.snackbar}
            GetUpdatedProductsList={handleMainChipSelection}
          />
        ) : null}

        {openAddProductItemDialog.open ? (
          <AddNewProductItem
            categoryId={openAddProductItemDialog.itemCategoryId}
            productId={openAddProductItemDialog.itemProductId}
            openDialog={openAddProductItemDialog.open}
            closePopup={closeAddProductItemDialog}
            getNewlyAddedItemCallback={handlegetNewlyaddedProductItem}
            ShouldPageReload={false}
            snackbar={props.snackbar}
          />
        ) : null}
      </Drawer>
    </div>
  );
});

export default ProductItemDrawer;
